import React, { Component } from 'react'
import {Card, Row, Container, Button} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Loader from 'react-loader-spinner'
import 'react-toastify/dist/ReactToastify.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import axios from 'axios';

export default class PasswordChange extends Component{
    constructor(props){
    super(props)
      this.state = {
        user_name: "",
        password: "",
        loading: false,
        spec_location: 'MDU',
        newpwd :'',
        confirmpwd:''
      };
    }

    componentDidMount(){
      this.redirectLogin()
      localStorage.setItem("screen_zoom", "80%")
      document.body.style.zoom = localStorage.getItem("screen_zoom");
    }

    redirectLogin = () => {
      if(localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined || localStorage.getItem("token") == null){
          localStorage.setItem('token', '')
          window.location.href = "/login"
      } 
    }
    

    handleUserName = (event) => {
      this.setState({
        user_name: event.target.value
      })
    }

    handlePassword = (event) => {
      this.setState({
        password: event.target.value
      })
    }

    handlenewpassword = (event) => {
      this.setState({
        newpwd: event.target.value
      })
    }

    handleconfirmPassword = (event) => {
      this.setState({
        confirmpwd: event.target.value
      })

      console.log("pass", event.target.value)
    }

    handleLocationChanged = (event) => {
      this.setState({
        spec_location: event.target.value
      })
    }

    handleSubmit = () => {

      this.setState({
        loading: true
      })
      axios({
        method: "POST",
        url: process.env.REACT_APP_API_URL_COMMON +'changepassword' ,//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
      },
        data: { 
          id : localStorage.getItem('user_id'),
          user_name: localStorage.getItem('username'),
          password : this.state.confirmpwd
        }
      }).then((response) => {
        console.log("Manites", response.data.data)
        this.setState({
          user: response.data.data, // you have it as this.data not response
        });
        
        if ( response.data.status == "Success") {

          this.notify(response.data.message)
          this.setState({
                loading: false
            })
          window.location.href = "/login"
          localStorage.setItem('user_id', '')
          localStorage.setItem('usertype', '')
          localStorage.setItem('usertypecode', '')
          localStorage.setItem('empcode', '')
          localStorage.setItem('usertypeid', '')
          localStorage.setItem('username', '')
          localStorage.setItem('segment', '')

        }else{
          this.errorNotify( response.data.message)
          this.setState({
            loading: false
        })
        }
      });
      
    }


    notify = (getres) => {
      toast.success(getres, {
          position: toast.POSITION.TOP_CENTER
      });
  };
    errorNotify = (message) => {
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER
      });
    }


    render(){
        return(
          <div>
           
              <Container>
                <Row>
                  <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <Card className="card my-5" style={{marginTop: "10%", border: "0", borderRadius: "1rem", boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1)" }}>
                      <Card.Body style={{padding: "2rem"}}>
                        <Card.Title><h5 className="text-center">Change Password </h5></Card.Title>
                        {/* <Form> */}
                          <div className="form-label-group">
                            <label htmlFor="emp_code" style={{fontWeight: "600"}}>User Name</label>
                            <input type="emp_code" id="emp_code" className="form-control emp_code" 
                            readOnly={true}
                            value ={ localStorage.getItem('username')} />
                          </div>

                          <div className="form-label-group">
                            <label htmlFor="inputPassword" style={{fontWeight: "600"}}>Current Password</label>
                            <input type="password" id="inputPassword" className="form-control password" placeholder="Password" 
                            onChange={this.handlePassword} />
                          </div>
                          
                          <div className="form-label-group">
                            <label htmlFor="inputPassword" style={{fontWeight: "600"}}>New Password</label>
                            <input type="password" id="inputPassword" className="form-control password" placeholder="Password" onChange={this.handlenewpassword} />
                          </div>

                          <div className="form-label-group">
                            <label htmlFor="inputPassword" style={{fontWeight: "600"}}>Confirm Password</label>
                            <input type="password" id="inputPassword" className="form-control password" placeholder="Password" onChange={this.handleconfirmPassword} />
                          </div>
                        
                          
                          <div className="text-center">
                            <Button variant="primary" className="text-center" onClick={this.handleSubmit}>Submit</Button>
                          </div>

                          { this.state.loading &&
                            <Loader
                                type="Circles"
                                color="#00BFFF"
                                height={100}
                                width={100}
                                style={{
                                    width: "100%",
                                    height: "100",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            />
                        }
                      </Card.Body>
                      <p className="text-center" style={{ fontFamily: "fantasy", fontSize: "12px"}}>Version 1.3</p>
                    </Card>
                  </div>
                </Row>
                <ToastContainer/>
              </Container>
            {/* </div> */} 
          </div>
        )
    }
}