import React from 'react';
import { Component} from 'react'
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import TwExportReport2 from './Views/TwExportReport2';
export class TwExportReport2Component extends Component{
  render(){
    return(
      <div className="Setup" style={{backgroundColor: "rgb(93, 188, 210)",margin:'0%'}}>
      
      <div className="root" ></div>
        <div>       
          <TwExportReport2/>
        </div>
      </div>
    )
  }
}

export default TwExportReport2Component