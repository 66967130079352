import React from 'react';
import { Component} from 'react'
import { Table, Form } from 'react-bootstrap';
import {Button,Card, ProgressBar} from 'react-bootstrap';
import {Container } from 'react-bootstrap';
import {Row,Col } from 'react-bootstrap';
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import Fab from '@material-ui/core/Fab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-select2-wrapper/css/select2.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import axios from 'axios';
import moment from 'moment'
import { Typeahead, TypeaheadInputMulti } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

var pageNumbers = []
export default class TwExportReport1 extends Component {
  constructor(props){
    super(props)
    this.state = ({
      cust_list :[],
      order_add :[],
      get_cu_details :[],
      month_year :"",
      po_number :"",
      customer_name :"",
      customer_code :"",
      selectedFile:'',
      order_type :'',

      modal: false,
      from_date :'',
      to_date :"",
      get_cust_name :'',
      get_cust_code : '',
      baseurl :  process.env['REACT_APP_API_URL_'+localStorage.getItem('segment') ],
      total: null,
      per_page: null,
      current_page: 1,
     
    })

    this.handle_cust = this.handle_cust.bind(this);
   
  }

  componentDidMount(){
         this.redirectLogin();
        localStorage.setItem("screen_zoom", "80%")
        document.body.style.zoom = localStorage.getItem("screen_zoom");
        
        const date = new Date();
        const futureDate = date.getDate() ;
        date.setDate(futureDate);
        const defaultValue = date.toLocaleDateString('en-CA');

        this.state.from_date = defaultValue
        this.state.to_date = defaultValue

        
   
        if(localStorage.getItem('usertypecode') == 'CUST'){
          axios({
            method: "POST",
            url: this.state.baseurl +'custdetails' ,//"http://localhost:3300/user_login",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
          },
            data: { 
              user_code :  localStorage.getItem('empcode'),
              user_type_code :localStorage.getItem('usertypecode')
            }
          }).then((response) => {
            console.log("Test", response.data.data)
            this.setState({
              cust_list: response.data.data, // you have it as this.data not response
              get_cust_name :response.data.data[0].customer_name,
              get_cust_code : response.data.data[0].customer_sap_code 
            });

            axios({
              method: "POST",
              url: this.state.baseurl + 'getcustomerpiinvoice',//"http://localhost:3300/custdetails",
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            },
              data: { 
                "from_date":this.state.from_date,
                "to_date":this.state.to_date,
                "customer_sap_code":response.data.data[0].customer_sap_code,
                "emp_code":localStorage.getItem('emp_code')
              }
            }).then((response) => {
              console.log("MANI", response.data.data)
              this.setState({
                get_cu_details: response.data.data,
              });
            });
          });
        }else{
          axios({
            method: "POST",
            url: this.state.baseurl +'custdetails' ,//"http://localhost:3300/user_login",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
            },
            data: { 
              user_code :  localStorage.getItem('empcode'),
              user_type_code :localStorage.getItem('usertypecode')
            }
          }).then((response) => {
            console.log("Test", response.data.data)
            this.setState({
              cust_list: response.data.data, // you have it as this.data not response
            });
          });
        }
  }
 
  redirectLogin = () => {
    if(localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined || localStorage.getItem("token") == null){
        localStorage.setItem('token', '')
        window.location.href = "/login"
    } 
  }

    // handle customer name 
    
    handle_cust =(selected)=>{
      var cuname = "";
      var cuscode ='';
      for (var i = 0; i < selected.length; i++) {
        cuname = selected[i].customer_name;
        cuscode =  selected[i].customer_sap_code
      }
      this.setState({
        customer_name:cuname,
        customer_code :cuscode
      })
      
      // axios({
      //   method: "POST",
      //   url: this.state.baseurl + 'getcustomerpiinvoice',//"http://localhost:3300/custdetails",
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'token': localStorage.getItem('token')
      //   },
      //   data: { 
      //     "from_date":this.state.from_date,
      //     "to_date":this.state.to_date,
      //     "customer_sap_code":cuscode
      //   }
      // }).then((response) => {
      //   console.log("MANIcut", response.data.data)
      //   this.setState({
      //     get_cu_details: response.data.data,
      //   });
      // });
     
    }



  
       // handle Month and year  
    handle_fromdate =(e)=>{
  
      this.setState({
        from_date : e.target.value
      })
      let custcode = ''
      if(localStorage.getItem('usertypecode') == 'CUST'){
        custcode = this.state.get_cust_code
      }else{
        custcode = this.state.customer_code

      }
      // axios({
      //   method: "POST",
      //   url: this.state.baseurl + 'getcustomerpiinvoice',//"http://localhost:3300/custdetails",
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'token': localStorage.getItem('token')
      //   },
      //   data: { 
      //     "from_date": e.target.value,
      //     "to_date": this.state.to_date,
      //     "customer_sap_code":custcode
      //   }
      // }).then((response) => {
      //   console.log("MANI", response.data.data)
      //   this.setState({
      //     get_cu_details: response.data.data,
      //   });
      // });

    }

      handle_todate =(e)=>{
      this.setState({
              to_date : e.target.value
            })
            let custcode = ''
            if(localStorage.getItem('usertypecode') == 'CUST'){
              custcode = this.state.get_cust_code
            }else{
              custcode = this.state.customer_code
      
            }
     
    
    }

    

    handle_show_invoice = ( e) => {

      this.state.get_cu_details =[]
  
      let custcode = ''
      if(localStorage.getItem('usertypecode') == 'CUST'){
        custcode = this.state.get_cust_code
      }else{
        custcode = this.state.customer_code

      }
    
      axios({
        method: "POST",
        url: this.state.baseurl + 'getcustomerpiinvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
          },
        data: { 
          "from_date":this.state.from_date,
          "to_date": this.state.to_date,
          "customer_sap_code":custcode,
          "emp_code":localStorage.getItem('empcode'),
          "off_set":1,
          "page":1
        }
      }).then((response) => {
          console.log("MANI", response.data.data)
          console.log("MANI12", response.data.current_page)
        this.setState({
          get_cu_details: response.data.data,
          total: response.data.total_page,
          current_page: response.data.current_page,
          per_page: response.data.per_page,
        });
      });
    }

    handle_show_invoice1 = ( page_number) => {

      this.state.get_cu_details =[]

     
      let get_page 

      if(page_number != '1'){
         get_page = page_number+"1"
      }else{
         get_page = page_number
      }
     
      alert("GET"+ get_page)

  
      let custcode = ''
      if(localStorage.getItem('usertypecode') == 'CUST'){
        custcode = this.state.get_cust_code
      }else{
        custcode = this.state.customer_code

      }
    
      axios({
        method: "POST",
        url: this.state.baseurl + 'getcustomerpiinvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
          },
        data: { 
          "from_date":this.state.from_date,
          "to_date": this.state.to_date,
          "customer_sap_code":custcode,
          "emp_code":localStorage.getItem('empcode'),
          "off_set":get_page,
          "page":page_number
        }
      }).then((response) => {
          console.log("MANIpagese", response.data.data)
          console.log("MANI12aer", response.data.current_page)
        this.setState({
          get_cu_details: response.data.data,
          total: response.data.total_page,
          current_page: response.data.current_page,
          per_page: response.data.per_page,
        });
      });
    }
 

    render(){
      const {  get_cu_details =[]}= this.state

      pageNumbers = []
      if (this.state.total !== null) {
        for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
          pageNumbers.push(i);
        }
      }
    
      const renderPageNumbers = pageNumbers.map(number => {
        let classes = this.state.current_page === number ? 'pagination-active' : '';
      
        if (number == 1 || number == this.state.total || (number >= this.state.current_page - 2 && number <= this.state.current_page + 5)) {
          if(this.state.searching){
            return (
              <span key={number} className={classes} onClick={() => this.fetchSearchResult(number)}>{number}</span>
            );
          } else {
            return (
              <span key={number} className={classes} onClick={() => this.handle_show_invoice1(number)}>{number}</span>
            );
          }
          
        }
      });
     
     
    
      return(
        <div>
          
 
          <Container fluid>
              <Card style={{margin: "5px"}}>
                <Card.Body>
                <h4><b>PFI Wise Status </b></h4>
                  <Row style={{marginTop:"2px", marginBottom: "7px"}}>

                    
                     <Col className="text-left">
                      <Form.Label className="name-label">From  Date   </Form.Label>
                      <input  className="form-control" required="required" 
                      type="date" name="setup_name" onChange={this.handle_fromdate} 
                      value={this.state.from_date}/> 
                         
                    </Col>             

                    <Col className="text-left">
                      <Form.Label className="name-label">To Date   </Form.Label>
                      <input  className="form-control" required="required"   
                      type="date" name="setup_name" onChange={this.handle_todate} 
                      value={this.state.to_date}/>    
                    </Col> 
                    
                    <Col >
                    {localStorage.getItem('usertypecode') != 'CUST'?(<>
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                      <div>
                      <Typeahead
                         id="exampleSelect" 
                         style={{width: "490px"}}
                         selected={this.selected}
                         onChange={this.handle_cust.bind(this)}
                         value={this.state.customer_name}
                         labelKey={option => `${option.customer_name} `}
                         options={this.state.cust_list}
                         placeholder="Select a customer..."
                      />        
                      </div>
                  </>):(<>
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                      <div>

                      <input  className="form-control" required="required" 
                          placeholder=''
                          style={{width: "490px"}}
                          type="text" name="setup_name" readOnly ={true} value={this.state.get_cust_name}/> 
                      </div>
                  </>)}
                    

                  </Col>               

                    
                     <Col className="text-left">

                    <Button style={{ marginTop: 30 }} onClick={this.handle_show_invoice} color="primary"  >Display</Button>{' '}
                    </Col>
                        

                  </Row>
                </Card.Body>
              </Card>
          </Container>

          <div className="table" style={{width:"100%",display:"inline-table", margin: "5px", backgroundColor: "#fff"}}>
                    <Table striped bordered  responsive="sm">
                      <thead style={{backgroundColor:"#413c69",color:"#ffffff"}}>
                        <tr>
                          <th style={{width: "20px"}}>#</th>
                          <th style={{width: "60px"}}>Order Reference</th>
                          <th style={{width: "60px"}}>PI No</th>
                          <th style={{width: "160px"}}>PI Date</th>
                          <th style={{width: "80px"}}>PI Status</th>
                          <th style={{width: "60px"}}>SO No</th>
                          <th style={{width: "100px"}}>SO Date</th> 
                          <th style={{width: "100px"}}>Invoice No</th>     
                          <th style={{width: "100px"}}>Invoice Date</th>                        
                          <th style={{width: "170px"}}>Container Name</th>                          
                           <th style={{width: "80px"}}>Container Available</th>
                          <th style={{width: "200px"}}>Port of Shipment</th>
                          <th style={{width: "150px"}}>Port of Discharge</th>                         
                          <th style={{width: "80px"}}>Shipment Agent</th> 
                          <th style={{width: "100px"}}>Shipment from Factory</th>
                          <th style={{width: "100px"}}>Date of Sailing</th>
                         
                        </tr>
                      </thead>
                      <tbody>
                      {get_cu_details.length ? 
                        get_cu_details.map((todo , id)=> (
                        <tr key ={id} >
                          <th>{id +1}</th>
                          {todo.order_ref != null ?( <th style={{width: "60px"}}>{todo.order_ref.trim()}</th>):(<th>-</th>)}
                         
                           {todo.pi_number != null?( <th style={{width: "60px"}}>{todo.pi_number.trim()}</th>):(<th>-</th>)}
                           {todo.pi_date != null?(<th style={{width: "160px"}}>{moment(todo.pi_date).format('DD-MM-YYYY')}</th>):(<th>-</th>)} 
                           {todo.pi_status!= null?(<th style={{width: "80px"}}>{todo.pi_status.trim()}</th>):(<th>-</th>)}
                           {todo.so_number!= null?(<th style={{width: "100px"}}>{todo.so_number.trim()}</th>):(<th>-</th>)}
                          {todo.so_date != null?(<th style={{width: "130px"}}>{moment(todo.so_date).format('DD-MM-YYYY')}</th>):(<th>-</th>)}
                          {todo.invoice_doc_no != null?(<th style={{width: "130px"}}>{(todo.invoice_doc_no)}</th>):(<th>-</th>)}
                          {todo.invoice_date != null?(<th style={{width: "130px"}}>{moment(todo.invoice_date).format('DD-MM-YYYY')}</th>):(<th>-</th>)}
                          {todo.container_name  !=null?(<th style={{width: "170px"}}>{todo.container_name}</th>):(<th></th>)}
                          {todo.container_available_date != null?(<th style={{width: "130px"}}>{moment(todo.container_available_date).format('DD-MM-YYYY')}</th>):(<th>-</th>)}
                          {todo.port_of_shipment !=null?(<th style={{width: "250px"}}>{todo.port_of_shipment}</th>):(<th>-</th>)}
                          {todo.port_of_discharge != null ?(<th>{todo.port_of_discharge}</th>):(<th>-</th>)}
                          {todo.shipping_agent != null ?(<th>{todo.shipping_agent}</th>):(<th>-</th>)}
                          {todo.date_of_shipment_from_factory != null?(<th style={{width: "130px"}}>{moment(todo.date_of_shipment_from_factory).format('DD-MM-YYYY')}</th>):(<th>-</th>)}
                          {todo.bl_doc_date != null?(<th style={{width: "130px"}}>{moment(todo.bl_doc_date).format('DD-MM-YYYY')}</th>):(<th>-</th>)}
                          
                         
                        </tr>
                        ))
                        : 
                        (<tr>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                        </tr>)
                      } 
                       
                      </tbody>
                    </Table>
                    {/* <div className='pagination' style={{backgroundColor: "white" , justifyContent: 'right'}}>
                      <span onClick={() => this.fetchMasterData(1)}>&laquo;</span>
                        {renderPageNumbers}
                      <span onClick={() => this.handle_show_invoice1(this.state.current_page)}>&raquo;</span>
                    </div>   */}
                  </div>

                
                    <div className="text-center" style={{marginBottom: "50px"}}>
                      <Button variant = "success" onClick={() => {window.location.href="/home"}}>Exit</Button>      
                    </div>
                 
                    
                  <div><ToastContainer/></div>

          </div>
      )        
    }
  
  }
