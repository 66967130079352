import React from 'react';
import { Component } from 'react'
import { Form ,Table } from 'react-bootstrap';
import { Button, Card, ProgressBar } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { Grid } from 'semantic-ui-react';
import { Container } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Fab from '@material-ui/core/Fab';
import { ToastContainer, toast } from 'react-toastify';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteIcon from '@material-ui/icons/Delete';
import 'react-toastify/dist/ReactToastify.css';
import 'react-select2-wrapper/css/select2.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import axios from 'axios';
import moment from 'moment'
import { Typeahead } from 'react-bootstrap-typeahead';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 





export default class OrderStatus extends Component {
  constructor(props) {
    super(props)

    this.state = ({
      // user_type:localstorage.getItem('usertype'),
      user_type: localStorage.getItem('usertypecode'), //'SCM',
      cust_list: [],
      order_add: [],
      get_cu_details: [],
      get_invoice_dtls: [],
      month_year: "",
      po_number: "",
      customer_name: "",
      customer_code: "",
      selectedFile: '',
      order_type: '',
      numPages: null,

      invoice_view: false,
      view :false,
      from_date: '',
      to_date: "",
     
      baseurl: process.env['REACT_APP_API_URL_' + localStorage.getItem('segment')],

  

    })

    this.handle_cust = this.handle_cust.bind(this);
  

    this.invoice = this.invoice.bind(this);
    this.invoice_close = this.invoice_close.bind(this);

    this.toggleview = this.toggleview.bind(this);
    this.toggleeview = this.toggleeview.bind(this);


  }

  componentDidMount() {

    console.log("SCMMANI", localStorage.getItem('usertypecode'))
    this.redirectLogin()
    localStorage.setItem("screen_zoom", "80%")
    document.body.style.zoom = localStorage.getItem("screen_zoom");

    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    this.state.from_date = defaultValue
    this.state.to_date = defaultValue


    if (localStorage.getItem('usertypecode') == 'CUST') {
      axios({
        method: "POST",
        url: this.state.baseurl + 'custdetails',//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          user_code: localStorage.getItem('empcode'),
          user_type_code: localStorage.getItem('usertypecode')
        }
      }).then((response) => {
        console.log("Test", response.data.data)
        this.setState({
          cust_list: response.data.data, // you have it as this.data not response
        });
      });
    } else {
      axios({
        method: "POST",
        url: this.state.baseurl + 'custdetails',//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          user_code: localStorage.getItem('empcode'),
          user_type_code: localStorage.getItem('usertypecode')
        }
      }).then((response) => {
        console.log("Test", response.data.data)
        this.setState({
          cust_list: response.data.data, // you have it as this.data not response
        });
      });
    }

 
   
  //   $(document).ready(function () {
  //     setTimeout(function(){
  //     $('#example').DataTable(
         
  //     );
  //     } ,
  //     1000
  //     );
  // });

 

  }

  redirectLogin = () => {
    if (localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined || localStorage.getItem("token") == null) {
      localStorage.setItem('token', '')
      window.location.href = "/login"
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  nextPage = () => {

    const currentPageNumber = this.state.pageNumber;
    let nextPageNumber;

    if (currentPageNumber + 1 > this.state.numPages) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = currentPageNumber + 1;
    }

    this.setState({
      pageNumber: nextPageNumber
    });
  }

  goToPrevPage = () => this.setState(state => ({ pageNumber: state.pageNumber - 1 }));

  goToNextPage = () => this.setState(state => ({ pageNumber: state.pageNumber + 1 }));

  // handle customer name 

  handle_cust = (selected) => {
    var cuname = "";
    var cuscode = '';
    for (var i = 0; i < selected.length; i++) {
      cuname = selected[i].customer_name;
      cuscode = selected[i].customer_sap_code
    }
    this.setState({
      customer_name: cuname,
      customer_code: cuscode,
    
    })

    

  }


  // handle Month and year  
  handle_fromdate = (e) => {

    this.setState({
      from_date: e.target.value,
      
    })
    

  }

  handle_todate = (e) => {
    this.setState({
      to_date: e.target.value,
     
    })

  
  }

  handle_invoice_no = (e) => {

    this.setState({
      get_invoice_no: e.target.value,
     
    })

    console.log("Invoiceno", e.target.value)
  }

  handle_show_invoice = (e) => {

    this.state.get_cu_details =[]

    
  
    axios({
      method: "POST",
      url: this.state.baseurl + 'getorder_status',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "from_date": this.state.from_date,
        "to_date": this.state.to_date,
        "customer_sap_code": this.state.customer_code,
      }
    }).then((response) => {
      console.log("Order Status", response.data.data)
      this.setState({
        get_cu_details: response.data.data,
      }); 
    });
  }

  invoice = todo => {
    axios({
      method: "POST",
      url: this.state.baseurl + 'getorder_view',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "customer_sap_code": todo.customer_sap_code,
        "order_no": todo.order_no,
        "order_version": todo.order_version,
        "order_mmyyyy": todo.order_mmyyyy
      }
    }).then((response) => {
      console.log("Invoicedtls", response.data.data)
      this.setState({
        get_invoice_dtls: response.data.data,
      });
    });

    this.setState(prevState => ({
      invoice_view: !prevState.invoice_view,
    }));
  }

  invoice_close = () => {
    this.setState(prevState => ({
      invoice_view: !prevState.invoice_view
    }));
  }
  

  toggleview = (file) => {
    this.setState(prevState => ({
      view: !prevState.view,
      file_title: file.pi_sap_transfer_no
    }));

  }


  toggleeview = () => {
    this.setState(prevState => ({
      view: !prevState.view
    }));
  }



  render() {
    const { get_cu_details = [], get_invoice_dtls = [] } = this.state
    const { fileInfos1, fileInfos2, fileInfos3, fileInfos4, fileInfos5, file_title, fileInfosrate } = this.state;
    const { fileInfos11, fileInfos12, fileInfos13, fileInfos14, fileInfos15 } = this.state;

    const { pageNumber, numPages } = this.state;
    var pdf_urlpath = process.env.REACT_APP_API_URL_FILE + "download_documents?filename=assets/uploads/PIPDF/";
    var urlpath = pdf_urlpath + file_title;
    console.log("Tet", pdf_urlpath + file_title)

   


    return (
      <div>

        <Modal style={{ maxWidth: '1000px', width: '100%' }} isOpen={this.state.view} toggle={this.toggleview}>
          <ModalHeader toggle={this.toggleview}>Document View </ModalHeader>
          <ModalBody>


            <Grid centered columns={2}>
              <Grid.Column textAlign="center" onClick={this.nextPage}>
                <Document
                  file={pdf_urlpath + file_title + ".pdf" }
                  onLoadSuccess={this.onDocumentLoadSuccess} noData={<h4></h4>}

                >
                  <Page pageNumber={pageNumber} />
                </Document>

                <p>
                  Page {pageNumber} of {numPages}
                </p>
                <button onClick={this.nextPage}>
                  Next page
                </button>
              </Grid.Column>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleeview} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>

        <Modal style={{ maxWidth: '1000px', width: '100%' }} isOpen={this.state.invoice_view} toggle={this.invoice}>
          <ModalHeader toggle={this.invoice}>Order Details  View </ModalHeader>
          <ModalBody>
            <div className="table" style={{ width: "100%", display: "inline-table", margin: "5px", backgroundColor: "#fff" }}>
              <Table striped bordered responsive="sm" >
                <thead style={{ backgroundColor: "#413c69", color: "#ffffff" }}>
                  <tr>
                    <th style={{ width: "20px" }}>#</th>
                    <th style={{ width: "100px" }}>Product Code Name</th>
                    <th style={{ width: "60px" }}>Product Desc</th>
                    <th style={{ width: "120px" }}>Qty</th>
                    <th style={{ width: "120px" }}>Price</th>
                    <th style={{ width: "100px" }}>PFI Value</th>


                  </tr>
                </thead>
                <tbody>
                  {get_invoice_dtls.length ?
                    get_invoice_dtls.map((todo, id) => (
                      <tr key={id} >
                        <th>{id + 1}</th>
                       
                        {todo.product_code != null ? (<td style={{ width: "100px" }}> {todo.product_code}</td>) : (<th>-</th>)}
                        {todo.product_desc != null ? (<td style={{ width: "100px" }}>{todo.product_desc}</td>) : (<th>-</th>)}
                        {todo.pi_qty != null ? (<td style={{ width: "100px" }}>{todo.pi_qty}</td>) : (<th>-</th>)}
                        {todo.price != null ? (<td style={{ width: "100px" }}>{todo.price}</td>) : (<th>-</th>)}
                        {todo.pi_value != null ? (<td style={{ width: "100px" }}>{todo.pi_value}</td>) : (<th>-</th>)}



                      </tr>
                    ))
                    :
                    (<tr>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>


                    </tr>)
                  }

                </tbody>
              </Table>
            </div>



          </ModalBody>
          <ModalFooter>
            <Button onClick={this.invoice_close} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>

        <Container fluid>
          <Card style={{ margin: "5px" }}>
            <Card.Body>
              <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
                

                <Col className="text-left">
                  <Form.Label className="name-label">From  Date   </Form.Label>
                  <input className="form-control" required="required"
                    type="date" name="setup_name" onChange={this.handle_fromdate}
                    value={this.state.from_date} />

                </Col>

                <Col className="text-left">
                  <Form.Label className="name-label">To Date   </Form.Label>
                  <input className="form-control" required="required"
                    type="date" name="setup_name" onChange={this.handle_todate}
                    value={this.state.to_date} />
                </Col>

                <Col >
                  <div className="text-left">
                    <Form.Label className="name-label" >Customer Name</Form.Label>
                  </div>
                  <div>
                    <Typeahead
                      id="exampleSelect"
                      style={{ width: "490px" }}
                      selected={this.selected}
                      onChange={this.handle_cust.bind(this)}
                      value={this.state.customer_name}
                      labelKey={option => `${option.customer_name} `}
                      options={this.state.cust_list}
                      placeholder="Select a customer..."
                    />
                  </div>


                </Col>

                {/* <Col className="text-left">
                  <Form.Label className="name-label">Invoice No</Form.Label>
                  <input className="form-control" required="required"
                    type="number" name="setup_name" onChange={this.handle_invoice_no}
                    maxlength="7"
                    value={this.state.get_invoice_no} />
                </Col> */}
                <Col className="text-left">

                  <Button style={{ marginTop: 30 }} onClick={this.handle_show_invoice} color="primary"  >Display</Button>{' '}
                </Col>


              </Row>
            </Card.Body>
          </Card>
        </Container>
        {/* <div className="table" style={{ width: "110%", display: "inline-table", margin: "5px", backgroundColor: "#fff" }}>
        <BootstrapTable keyField='id' data={products} columns={columns} />
        </div> */}

        <div className="table p-2" style={{ width: "99%", display: "inline-table", margin: "5px", backgroundColor: "#fff" }}>

       
          <Table  class="table table-hover table-bordered"  striped bordered responsive="sm">
          <thead style={{ backgroundColor: "#413c69", color: "#ffffff" }}>
            <tr>
                <th style={{ width: "20px" }}>#</th>
                <th style={{ width: "100px" }}>Order No</th>
                <th style={{ width: "100px" }}>Order Version</th>
                <th style={{ width: "100px" }}>Order Date</th>
                <th style={{ width: "100px" }}>Customer Sap Code</th>
                <th style={{ width: "100px" }}>PFI Number</th>
                <th style={{ width: "100px" }}>Open</th>
                <th style={{ width: "100px" }}>PFI Processing</th>
                <th style={{ width: "130px" }}>PFI Received</th>
                <th style={{ width: "130px" }}>PFI Error</th>
                <th style={{ width: "100px" }}>SO Received</th> 
                <th style={{ width: "100px" }}>Document </th> 
            </tr>
          </thead>
          <tbody>
          {get_cu_details.length ?
                get_cu_details.map((todo, id) => (
                  <tr key={id} >
                    <td>{id + 1}</td>
                    {todo.order_no != null ? (<td style={{ width: "60px", height: "10px" }}>
                      <Form.Label onClick={() => this.invoice(todo)}>{todo.order_no.trim()}</Form.Label>
                    </td>) : (<th>-</th>)}
                    {todo.order_version != null ? (<td style={{ width: "60px" }}>{todo.order_version}</td>) : (<th>-</th>)}
                    {todo.created_at != null ? (<td style={{ width: "150px" }}>{moment(todo.created_at).format('DD-MM-YYYY')}</td>) : (<th>-</th>)}
                    {todo.customer_sap_code != null ? (<td style={{ width: "60px" }}>{todo.customer_sap_code.trim()}</td>) : (<th>-</th>)}
                    {todo.pi_number != null ? (<td style={{ width: "60px" }}>{todo.pi_number}</td>) : (<th>-</th>)}
                    {todo.action_status == 'save' ? (<td style={{ width: "150px" }}><Button   variant="warning" style={{ marginRight: "2%" }}>{'Open'}</Button></td>) : (<th>-</th>)}
                    {todo.action_status == 'PI Processing' ? (<td style={{ width: "150px" }}> <Button   variant="primary" style={{ marginRight: "2%" }}>{todo.action_status}</Button></td>) : (<th>-</th>)}
                    {todo.action_status == 'PI Received' ? (<td style={{ width: "150px" }}><Button   variant="success" style={{ marginRight: "2%" }}>{todo.action_status}</Button></td>) : (<th>-</th>)}
                    {todo.action_status == 'PI Error' ? (<td style={{ width: "150px" }}><Button   variant="danger" style={{ marginRight: "2%" }}>{todo.action_status}</Button></td>) : (<th>-</th>)}
                    {todo.action_status == 'SOReceived' ? (<td style={{ width: "150px" }}><Button   variant="info" style={{ marginRight: "2%" }}>{todo.action_status}</Button></td>) : (<th>-</th>)}
                    {todo.pi_number != null ? (<td style={{ width: "150px" }}>
                    
                      <a href={pdf_urlpath + todo.pi_sap_transfer_no + ".pdf"} download={this.state.order_no}> Click to download PDF</a>
                          {/* <Fab size="small" color="primary" style={{ marginLeft: 10 }}>
                            <VisibilityIcon onClick={() => this.toggleview(todo)} size="small" />
                          </Fab> */}
                          {/* <Button style={{marginRight:"2%"}} onClick={() => this.toggle1(todo)}>Upload </Button>
                                <Button style={{marginRight:"2%"}} onClick={() => this.toggle2(todo)}>View </Button> */}
                      
                      </td>) : (<th>-</th>)}
                  </tr>
                ))
                :
                (<tr>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                </tr>)
              }
           
            
          </tbody>
         </Table>
       
          
         
        </div>


        <div className="text-center" style={{ marginBottom: "50px" }}>
          <Button variant="success" onClick={() => { window.location.href = "/home" }}>Exit</Button>
        </div>


        <div><ToastContainer /></div>

      </div>
    )
  }

}
