import React from 'react';
import { Component} from 'react'
import { Table, Form } from 'react-bootstrap';
import {Button,Card} from 'react-bootstrap';
import {Container } from 'react-bootstrap';
import {Row,Col } from 'react-bootstrap';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import Fab from '@material-ui/core/Fab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-select2-wrapper/css/select2.css';
import moment from 'moment'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";




export default class MonthlyOrder extends Component {
  constructor(props){
    super(props)
    this.state = ({
      cust_list :[],
      order_add :[],
      get_cu_details :[],
      month_year :"",
      po_number :"",
      customer_name :"",
      customer_code :"",
      customer_id :"",
      pay_term :"",
      po_date :"",
      curreny :"",
      incotern :"",
      port_delivery :"",
      container_size :"",
      notify :"",
      order_type :"",
      rows :[],
      orderadd:[],
      nofify_code :'',

      segment :[],
      product :[],
      product_name:[],

      add_segment :'',
      add_size :'',
      add_pname :'',
      add_pcode :'',
      add_price :'',
      add_oqty :'',
      add_tqty :'',
      add_bqty :'',
      add_value :'',
      container :[],
      order_insert :[],
      showHide : false,
      modaladd :false,
      modalwish :false,
      modalwishview :false,
      get_wishlist:[],
      get_wishlistview:[],
      getwish :[],
      selwish :'',
      getwishid :[],
      getwishstatus :'Y',
      get_month_order :[],
      get_status :'',
      get_wishdesc :[],

      get_wish_order :[],
     
      get_wishitem :[],
      find_search :'',
      find_search_code :'',
      checked: false,
      deleted_ids: [],
      viewlist :[],
      vichecked :false, 

      get_cust_name :'',
      get_cust_code : '',
      notychecked: false,

      baseurl :  process.env['REACT_APP_API_URL_'+localStorage.getItem('segment') ],
    })

    this.handle_cust = this.handle_cust.bind(this);
    this.handle_segment = this.handle_segment.bind(this);
    this.handle_productsize = this.handle_productsize.bind(this);
    this.handle_productname = this.handle_productname.bind(this);
    this.handle_qty = this.handle_qty.bind(this);
    this.handle_tqty = this.handle_tqty.bind(this);
    this.handle_bqty = this.handle_bqty.bind(this);
    this.toggleadd = this.toggleadd.bind(this);
    this.toggleeadd = this.toggleeadd.bind(this);
    this.toggleewish = this.toggleewish.bind(this);
    this.togglewish = this.togglewish.bind(this);
    this.handle_find = this.handle_find.bind(this);
    this.toggleewishview = this.toggleewishview.bind(this);
    this.togglewishview = this.togglewishview.bind(this);
    this.toggleewishviewclose = this.toggleewishviewclose.bind(this)
 
  }

  componentDidMount(){
    this.redirectLogin();
    localStorage.setItem("screen_zoom", "80%")
    document.body.style.zoom = localStorage.getItem("screen_zoom");
    
    const date = new Date();
    const futureDate = date.getDate() ;
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');
    this.state.po_date = defaultValue

    const options1 = {month: 'numeric',   year: 'numeric' };
    const date1 = new Date()
    const dateTimeFormat3 = new Intl.DateTimeFormat('en-US', options1);
   
    var  months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    var d = new Date();
    var monthName=months[d.getMonth()]; 
    var year = date.getUTCFullYear();
    const  newdate = year + "-" + monthName ;
    this.state.month_year = newdate

    //var  datePickerId.max = new Date().toISOString().split("T")[0];

    axios.get(this.state.baseurl +'listproductdescview', { 'headers': {
      'token':localStorage.getItem('token')} }).then(response => {
      this.setState({
        segment: response.data.data, // you have it as this.data not response
      });
      });

      axios.get(this.state.baseurl +'containermasters',{ 'headers': {
        'token':localStorage.getItem('token')} }).then(response => {
        console.log("Mani", response.data.data)
        this.setState({
          container: response.data.data, // you have it as this.data not response
        });
        });
  

       
   
        if(localStorage.getItem('usertypecode') == 'CUST'){
          axios({
            method: "POST",
            url: this.state.baseurl +'custdetails' ,//"http://localhost:3300/user_login",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
          },
            data: { 
              
              user_code :  localStorage.getItem('empcode'),
              user_type_code :localStorage.getItem('usertypecode'),
              segment :localStorage.getItem('segment')
            }
          }).then((response) => {
            console.log("custmani", response.data.data[0] )
            this.setState({
              get_cust_name :response.data.data[0].customer_name,
              get_cust_code : response.data.data[0].customer_sap_code 
            });

            let cust_code  = response.data.data[0].customer_sap_code 
           // alert(cust_code)

            axios({
              method: "POST",
              url: this.state.baseurl + 'getmonthlydtls',//"http://localhost:3300/custdetails",
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            },
              data: { 
                customer_sap_code:  cust_code,
                order_mmyyyy: this.state.month_year,
              }
            }).then((response) => {
             
             
              if(response.data.status == 'Success'){
                 
                this.setState({
                  get_month_order :response.data.data,
                  get_status :response.data.status,
                });
               
               var  get_cust  = response.data.hdrs
                for (var j=0; j< get_cust.length;j++){
                  this.state.curreny = get_cust[j].order_category
                  this.state.pay_term = get_cust[j].payment_term;
                  this.state.incotern = get_cust[j].inco_term;
                  this.state.port_delivery = get_cust[j].port_of_discharge;
                  this.state.customer_code = get_cust[j].customer_sap_code;
                  this.state.customer_id = get_cust[j].customer_id
                  this.state.po_number = get_cust[j].po_number
                  this.state.order_type = get_cust[j].order_type
                  this.state.po_date = moment(get_cust[j].po_date).format('YYYY-MM-DD')
                  this.state.notes = get_cust[j].notes
                  this.state.priority_item =get_cust[j].priority_item
                  this.state.final_desc = get_cust[j].final_destination
                  this.state.container_size = get_cust[j].container_type
                }
                const getor =[];
                const get_order = this.state.get_month_order;
                console.log("CUSTMANI", get_order)
          
                for( var  i=0; i< get_order.length;i++){
                  getor.push({
                    id: this.state.rows.length + 1,
                    order_mmyyyy:this.state.month_year,
                    customer_id :this.state.customer_id,
                    customer_sap_code :this.state.customer_code,
                    notifyparty_sap_code:get_order[i].notifyparty_sap_code,
                    segment_code:get_order[i].segment_code,
                    segment_desc:get_order[i].segment_desc,
                    product_group:get_order[i].product_group,
                    product_code:get_order[i].product_code,
                    product_desc:get_order[i].product_desc,
                    uom:"EA",
                    order_qty:get_order[i].order_qty,
                    price:get_order[i].price,
                    order_value:get_order[i].order_value,
                    total_pi_qty:get_order[i].total_pi_qty,
                    balance_order_qty:get_order[i].balance_order_qty
                  })
                  console.log("Testsri", getor)
          
                  this.setState({
                      rows : getor,
                      segment_desc: get_order[i].segment_desc,
                  
                      orderadd: getor.length? getor.map((order , id )=>({
                      id:   (id + 1),
                      order_mmyyyy:order.order_mmyyyy,
                      customer_id :order.customer_id,
                      customer_sap_code :order.customer_sap_code,
                      notifyparty_sap_code:order.notifyparty_sap_code,
                      segment_code:order.segment_code,
                      segment_desc:order.segment_desc,
                      product_group:order.product_group,
                      product_code:order.product_code,
                      product_desc:order.product_desc,
                      uom:order.uom,
                      order_qty:order.order_qty,
                      price:order.price,
                      order_value:order.order_value,
                      total_pi_qty:order.total_pi_qty,
                      balance_order_qty:order.balance_order_qty
          
                    })):("")
          
                  
                  })
                }
              }else{
                axios({
                  method: "POST",
                  url: this.state.baseurl + 'custdetails',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                },
                  data: { 
                    user_code :  localStorage.getItem('empcode'),
                    user_type_code :localStorage.getItem('usertypecode')
                    //order_mmyyyy: e.target.value, 
                  }
                }).then((response) => {
                  console.log("Test123", response.data.data)
          
                 
                  var get_cust1 = response.data.data
                  
                  for (var j=0; j< get_cust1.length;j++){
                    
                    this.state.curreny = get_cust1[j].currency
                    this.state.pay_term = get_cust1[j].payment_term;
                    this.state.incotern = get_cust1[j].inco_term;
                    this.state.port_delivery = get_cust1[j].port;
                    this.state.customer_code = get_cust1[j].customer_sap_code;
                    this.state.customer_id = get_cust1[j].customer_id
                    this.state.po_number = get_cust1[j].po_number
            
                  }
      
                  this.setState({
                    get_month_order : ''
                  });
             
                  
                });
          
               
              }

            });

          });
        }else{
          axios({
            method: "POST",
            url: this.state.baseurl +'custdetails' ,//"http://localhost:3300/user_login",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
          },
            data: { 
              user_code :  localStorage.getItem('empcode'),
              user_type_code :localStorage.getItem('usertypecode'),
              segment :localStorage.getItem('segment')
            }
          }).then((response) => {
            console.log("Test", localStorage.getItem('empcode'))
            this.setState({
              cust_list: response.data.data, // you have it as this.data not response
            });
          });
        }

    
  
  }

  redirectLogin = () => {
    if(localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined || localStorage.getItem("token") == null){
        localStorage.setItem('token', '')
        window.location.href = "/login"
    } 
  }
 

    // handle customer name 
    handle_cust =(selected)=>{
     

      var cuname = "";
      var ccode ='';
      for (var i = 0; i < selected.length; i++) {
        cuname = selected[i].customer_name
        ccode = selected[i].customer_sap_code
      }

      this.setState({
        customer_name:cuname,
        customer_code:ccode
      })

      console.log("Testmanicode",ccode )
      this.state.get_month_order =[]
      this.state.orderadd = []
    
      axios({
        method: "POST",

        url: this.state.baseurl + 'getmonthlydtls',//"http://localhost:3300/custdetails",
        data: { 
          customer_sap_code: ccode,
          order_mmyyyy: this.state.month_year,
        }
      }).then((response) => {
        console.log("Test123month", response.data.status)
       
        if(response.data.status == 'Success'){
           
          this.setState({
            get_month_order :response.data.data,
            get_status :response.data.status,
          });
         
         var  get_cust  = response.data.hdrs
          for (var j=0; j< get_cust.length;j++){
            this.state.curreny = get_cust[j].order_category
            this.state.pay_term = get_cust[j].payment_term;
            this.state.incotern = get_cust[j].inco_term;
            this.state.port_delivery = get_cust[j].port_of_discharge;
            this.state.customer_code = get_cust[j].customer_sap_code;
            this.state.customer_id = get_cust[j].customer_id
            this.state.po_number = get_cust[j].po_number
            this.state.order_type = get_cust[j].order_type
            this.state.po_date = moment(get_cust[j].po_date).format('YYYY-MM-DD')
            this.state.notes = get_cust[j].notes
            this.state.priority_item =get_cust[j].priority_item
            this.state.final_desc = get_cust[j].final_destination
            this.state.container_size = get_cust[j].container_type
          }
          const getor =[];
          const get_order = this.state.get_month_order;
          console.log("CUSTMANI", get_order)
    
          for( var  i=0; i< get_order.length;i++){
            getor.push({
              id: this.state.rows.length + 1,
              order_mmyyyy:this.state.month_year,
              customer_id :this.state.customer_id,
              customer_sap_code :this.state.customer_code,
              notifyparty_sap_code:get_order[i].notifyparty_sap_code,
              segment_code:get_order[i].segment_code,
              segment_desc:get_order[i].segment_desc,
              product_group:get_order[i].product_group,
              product_code:get_order[i].product_code,
              product_desc:get_order[i].product_desc,
              uom:"EA",
              order_qty:get_order[i].order_qty,
              price:get_order[i].price,
              order_value:get_order[i].order_value,
              total_pi_qty:get_order[i].total_pi_qty,
              balance_order_qty:get_order[i].balance_order_qty
            })
            console.log("Testsri", getor)
    
            this.setState({
                rows : getor,
                segment_desc: get_order[i].segment_desc,
            
                orderadd: getor.length? getor.map((order , id )=>({
                id:   (id + 1),
                order_mmyyyy:order.order_mmyyyy,
                customer_id :order.customer_id,
                customer_sap_code :order.customer_sap_code,
                notifyparty_sap_code:order.notifyparty_sap_code,
                segment_code:order.segment_code,
                segment_desc:order.segment_desc,
                product_group:order.product_group,
                product_code:order.product_code,
                product_desc:order.product_desc,
                uom:order.uom,
                order_qty:order.order_qty,
                price:order.price,
                order_value:order.order_value,
                total_pi_qty:order.total_pi_qty,
                balance_order_qty:order.balance_order_qty
    
              })):("")
    
            
            })
          }
        }else{
          axios({
            method: "POST",
            url: this.state.baseurl + 'customer_details',//"http://localhost:3300/custdetails",
            data: { 
              customer_sap_code: ccode,
             
              //order_mmyyyy: e.target.value, 
            }
          }).then((response) => {
            console.log("Test123", response.data.data)
    
           
            var get_cust1 = response.data.data
            
            for (var j=0; j< get_cust1.length;j++){
              
              this.state.curreny = get_cust1[j].currency
              this.state.pay_term = get_cust1[j].payment_term;
              this.state.incotern = get_cust1[j].inco_term;
              this.state.port_delivery = get_cust1[j].port;
              this.state.customer_code = get_cust1[j].customer_sap_code;
              this.state.customer_id = get_cust1[j].customer_id
              this.state.po_number = get_cust1[j].po_number
      
            }

            this.setState({
              get_month_order : ''
            });
       
            
          });
    
         
        }
      });
    }
  
    handle_month_year =(e)=>{
      this.setState({
        month_year : e.target.value
      })

    

      
    }

    handle_ponumber=(e)=>{
      
      this.setState({
        po_number : e.target.value
      })
    
    }

    handle_podate =(e)=>{
      this.setState({
        po_date : e.target.value
      })
    }

    handle_ordertype=(e)=>{
      
      this.setState({
        order_type : e.target.value
      })
    }

    handle_port_delivery=(e)=>{
      
      this.setState({
        port_delivery : e.target.value
      })
    }


    // handle_containter =(selected)=>{
    //   var contain = "";
    //   for (var i = 0; i < selected.length; i++) {
    //     contain = selected[i].container_type
    //   }
    //   this.setState({
    //     container_size:contain
    //   })
     
    // }
    handle_containter =(e)=>{
      //var contain = "";
      // for (var i = 0; i < selected.length; i++) {
      //   contain = selected[i].container_type
      // }
      this.setState({
        container_size: e.target.value
      })
     
    }

    handle_payterm=(e)=>{
      this.setState({
        pay_term : e.target.value
      })
    } 

    handle_incotern =(e)=>{
      this.setState({
        inco_term : e.target.value
      })
    } 

    handle_curreny=(e)=>{
      this.setState({
        currency : e.target.value
      })
    } 

    notichecked =(e)=>{
      const { checked } = e.target

      let getchek = checked ;

      console.log("Manichec", getchek)

      this.setState({
        notychecked : getchek
      })
      
    }
      
    


    handle_notes=(e)=>{
      this.setState({
        notes : e.target.value
      })
    } 

    handle_priority=(e)=>{
      this.setState({
        priority_item : e.target.value
      })
    } 

    handle_final=(e)=>{
      this.setState({
        final_desc : e.target.value
      })
    } 

    //Tabel add and remove 
    handle_segment = (ix, selected) => {

      this.state.segment_desc = '';
      this.state.segment_code ='';
      var see = "";
      var code ='';
      for (var i = 0; i < selected.length; i++) {
        see = selected[i].segment_desc
        code =selected[i].segment_code
      }
      this.setState({ add_segment: see });
      var items = this.state.orderadd;
      
      console.log("Test", see)
  
      items[ix].segment_desc = see;
      items[ix].segment_code = code;
  
      this.setState({
        orderadd: items
      });
      
      axios({
        method: "POST",
        url: this.state.baseurl + 'listproduct',//"http://localhost:3300/listproduct",
        
        data: { 
          segment_desc: see
        }
      }).then((response) => {
        console.log("Seg",  response.data.data)
        this.setState({
          product: response.data.data, // you have it as this.data not response
        });
      });
      
     
    }
  
  
    handle_productsize = (ix, selected) => {
      var desc = "";
      for (var i = 0; i < selected.length; i++) {
        desc = selected[i].product_group
      }
      console.log("Test",selected)
      this.setState({ add_size: desc });
      
      var items = this.state.orderadd;
  
      items[ix].product_group = desc;
  
      this.setState({
        orderadd: items
      });
  
      axios({
        method: "POST",
        url: this.state.baseurl + 'listproductdesc', //"http://localhost:3300/listproductdesc",
        data: { 
          product_group: desc
        }
      }).then((response) => {
        console.log("Test",response.data.data )
        this.setState({
          prouct_name: response.data.data, // you have it as this.data not response
        });
      });
      
     
    }
  
    handle_productname = (ix, selected) => {
  
    var pname = "";
    var pcode ='';
      for (var i = 0; i < selected.length; i++) {
        pname = selected[i].product_desc
        
      }
      
      this.setState({ add_pname: pname });
  
      var items = this.state.orderadd;
  
      items[ix].product_desc = pname;
  
      this.setState({
        orderadd: items
      });
  
      
     
      this.set_pcode(ix, pname)
     
    }
  
    set_pcode(ix, pname){
      let getcode  =''
      let gpcode =''
      let gprcode =''
      axios({
        method: "POST",
        url: this.state.baseurl + 'listproductcode' ,//"http://localhost:3300/listproductcode",
        data: { 
          product_desc: pname
        }
      }).then((response) => {
        getcode  = response.data.data
        gprcode = response.data.data[0].product_code
        console.log("Pricemani", gprcode)
        for (var i = 0; i < getcode.length; i++) {
          gpcode = getcode[i].product_code
         
        }
        this.setState({ add_pcode: gpcode });
        
        var items = this.state.orderadd;
  
        items[ix].product_code = gpcode;
  
        this.setState({
          orderadd: items
        });

        let getprice  =''
        let gprice =''
        axios({
          method: "POST",
          url: this.state.baseurl + 'listproductprice', //"http://localhost:3300/listproductprice",
          data: { 
            product_code: gprcode,
            customer_sap_code : this.state.customer_code
          }
        }).then((response) => {
          getprice  = response.data.data
          for (var i = 0; i < getprice.length; i++) {
            gprice = getprice[i].price
           
          }
          this.setState({ add_price: gprice });
          
        //  var items = this.state.orderadd;
    
      //    items[ix].price = gprice;
    
          // this.setState({
          //   orderadd: items
          // });
        }); 
      });   

    //  this.set_price(ix, gprcode)
    }
  
  
    set_price(ix, gprcode){
    
       
    }
  
    handle_qty =(ix,e)=>{
  
      var items = this.state.orderadd;
      console.log("Mani", items);
      let geprice = '';
      for (var i = 0; i < items.length; i++) {
        geprice = items[i].price
        }
  

          const re = /^[0-9\b]+$/;
      let getqtyvalue = ''
      if (e.target.value === '' || re.test(e.target.value)) {
       
        getqtyvalue = e.target.value
      }

        let getvalue = geprice * getqtyvalue;
        console.log("Testprice", geprice);



      this.setState({
        add_oqty : getqtyvalue
      })
      var items = this.state.orderadd;
  
      items[ix].order_qty = getqtyvalue;
  
      this.setState({
        orderadd: items
      });
  
  
      var items = this.state.orderadd;
  
      items[ix].order_value = getvalue;
  
      this.setState({
        orderadd: items
      });
    }
  
    handle_tqty =(ix, e)=>{
      this.setState({
        add_tqty : e.target.value
      })
  
      var items = this.state.orderadd;
  
      items[ix].total_pi_qty = e.target.value;
  
      this.setState({
        orderadd: items
      });
    }
  
    handle_bqty =(ix, e)=>{
      this.setState({
        add_bqty : e.target.value
      })
  
      var items = this.state.orderadd;
  
      items[ix].balance_order_qty = e.target.value;
  
      this.setState({
        orderadd: items
      });
    }

    addwish = (todo, e) =>{

     
      let getchek =''
      let getid =''
      
      const { checked } = e.target;
      const { id} = todo.id;

      getchek = checked ;
      getid = id;
  
    
      this.setState({
        checked: checked
      })
      let wish =''
     
        
        if(getchek == true){
          wish  = 'Y'
        }else{
          wish  ='N'
        }
      
     
        let custcode = ''
        if(localStorage.getItem('usertypecode') == 'CUST'){
          custcode = this.state.get_cust_code
        }else{
          custcode = this.state.customer_code
  
        }
        axios({
        method: "POST",
        url: this.state.baseurl + 'wishlistupdate',//"http://localhost:3300/custdetails",
        data: { 
          "id": todo.id,
          "wish_list":wish,
          "customer_sap_code":custcode

        }
      }).then((response) => {
        console.log("Testwish", response.data.data)
        this.setState({
          //get_wishlist: response.data.data, // you have it as this.data not response
        });
      });

    }


    addwishview = (id, e) =>{

      let getchek =''
      var items =  this.state.viewlist;
     
      let remove = ''
     

      const { vichecked } = e.target;
     

      getchek = vichecked ;
    
        
      let wish =''
        if(getchek == true){
          wish  = 'Y'
        }else{
          wish  ='N'

          items[id].remove_item = "Y"

          this.setState({
            viewlist: items,
          });
        }
   
      

    }


    submitLists =(event) => {
      event.preventDefault();

      let ot = this.state.order_type;
      let po  = this.state.po_number;
      let size = this.state.container_size;

      this.redirectLogin();


      // if(ot == "" || po == "" || size == ""){

      //   if(po == ""){
      //     alert("Please Enter po Number ")
      //   }

      //   if(ot == ""){
      //     alert("Please Select Order Type ")
      //   }

      //   if(size == ""){
      //     alert("Please Select Containter Size ")
      //   }

       
       
      // }else{

      //   let custcode = ''
      //   if(localStorage.getItem('usertypecode') == 'CUST'){
      //     custcode = this.state.get_cust_code
      //   }else{
      //     custcode = this.state.customer_code
  
      //   }
  
      //   const insertdate =  JSON.stringify({ 
      //     "order_mmyyyy":this.state.month_year,
      //     "order_category":"",
      //     "customer_id":this.state.customer_id,
      //     "customer_sap_code":custcode,
      //     "notifyparty_sap_code":"",
      //     "po_number":this.state.po_number,
      //     "po_date":this.state.po_date,
      //     "container_type":this.state.container_size,
      //     "container_capacity":"0",
      //     "total_order_qty":"0",
      //     "total_order_value":"0",
      //     "se_code":"0",
      //     "final_destination":"",
      //     "port_of_discharge":this.state.port_delivery,
      //     "payment_term":this.state.pay_term,
      //     "inco_term":this.state.incotern,
      //     "order_type":this.state.order_type,
      //     "notes":"",
      //     "filler_item":"",
      //     "priority_item":"",
      //     "notes":"",
      //    }) 
  
      //    console.log("Header", insertdate)
  
      //   console.log("details ", this.state.orderadd)
         
      //   let getorderqty = this.state.orderadd

      //    let monqty =''
      //   for (var m  =0; m<getorderqty.length; m++){
      //     monqty = getorderqty[m].order_qty
      //   }


      //   if(getorderqty != ''){
      //     if(monqty ==""|| monqty =="null"){
      //       alert("Please enter the Order  qty")
      //     }
      //     else{
      //       axios({
      //         method: "POST",
      //         url: this.state.baseurl + 'update_order',//"http://localhost:3300/custdetails",
      //         data: { 
      //           p_order_details: JSON.stringify({ 
      //             "order_mmyyyy":this.state.month_year,
      //             "order_category":this.state.curreny,
      //             "customer_id":this.state.customer_id,
      //             "customer_sap_code":this.state.customer_code,
      //             "notifyparty_sap_code":"",
      //             "po_number":this.state.po_number,
      //             "po_date":this.state.po_date,
      //             "container_type":this.state.container_size,
      //             "container_capacity":"0",
      //             "total_order_qty":"0",
      //             "total_order_value":"0",
      //             "se_code":"0",
      //             "final_destination":this.state.final_desc,
      //             "port_of_discharge":this.state.port_delivery,
      //             "payment_term":this.state.pay_term,
      //             "inco_term":this.state.incotern,
      //             "order_type":this.state.order_type,
      //             "notes":this.state.notes,
      //             "filler_item":this.state.filler_item,
      //             "priority_item":this.state.priority_item,
                  
      //            }) ,
      
      //            p_order_details_item: JSON.stringify({"items" : this.state.orderadd})
               
      //         }
      //       }).then((response) => {
      //         let res = response.data.message
      //         this.setState({
      //           order_type: response.data.data, // you have it as this.data not response
      //         });
      //         var getres =''
      //         for (var i =0; i< res.length ;i++){
      //           getres = res[i].fi01_monthly_order
      //         }
      //         var gtres = getres.substring(0,1)
      //         if(gtres == "R"){
      //           this.notify(getres)
      //         //  window.location.reload(true);
      //         }else{
      //           window.confirm(getres)
      //         }
      //       });
    
      //     }
      //   }else{
      //     axios({
      //       method: "POST",
      //       url: this.state.baseurl + 'update_order',//"http://localhost:3300/custdetails",
      //       data: { 
      //         p_order_details: JSON.stringify({ 
      //           "order_mmyyyy":this.state.month_year,
      //           "order_category":this.state.curreny,
      //           "customer_id":this.state.customer_id,
      //           "customer_sap_code":this.state.customer_code,
      //           "notifyparty_sap_code":"",
      //           "po_number":this.state.po_number,
      //           "po_date":this.state.po_date,
      //           "container_type":this.state.container_size,
      //           "container_capacity":"0",
      //           "total_order_qty":"0",
      //           "total_order_value":"0",
      //           "se_code":"0",
      //           "final_destination":this.state.final_desc,
      //           "port_of_discharge":this.state.port_delivery,
      //           "payment_term":this.state.pay_term,
      //           "inco_term":this.state.incotern,
      //           "order_type":this.state.order_type,
      //           "notes":this.state.notes,
      //           "filler_item":this.state.filler_item,
      //           "priority_item":this.state.priority_item,
                
      //          }) ,
    
      //          p_order_details_item: JSON.stringify({"items" : this.state.orderadd})
             
      //       }
      //     }).then((response) => {
      //       let res = response.data.message
      //       this.setState({
      //         order_type: response.data.data, // you have it as this.data not response
      //       });
      //       var getres =''
      //       for (var i =0; i< res.length ;i++){
      //         getres = res[i].fi01_monthly_order
      //       }
      //       var gtres = getres.substring(0,1)
      //       if(gtres == "R"){
      //         this.notify(getres)
      //       //  window.location.reload(true);
      //       }else{
      //         window.confirm(getres)
      //       }
      //     });
  

      //   }

      
       
        
     
      // }

   

    }

   notify = (getres) => {
        toast.success(getres, {
            position: toast.POSITION.TOP_CENTER
        });
    };
    
    errorNotify = (message) => {
        toast.error(message, {
            position: toast.POSITION.TOP_CENTER
        });
    }


    handleModalShowHide() {
      this.setState({ showHide: !this.state.showHide })
   }

   toggleadd =() => {
      this.setState(prevState => ({
        modaladd: !prevState.modaladd
      }));    
    }
    toggleeadd =() => {
      this.setState(prevState => ({
        modaladd: !prevState.modaladd
    }));   
    }

    togglewish =() => {
      this.state.get_wishdesc =[];
      this.state.get_wishlist =[];
      this.state.checked ='';

      if(this.state.customer_code ==''){
        alert("Please Select Customer Name")
      }else{

        this.state.get_wishdesc =[];
        this.state.get_wishlist =[];

        this.setState(prevState => ({
          modalwish: !prevState.modalwish
        })); 
      
       
  
        console.log("Mani", this.state.get_wishlist)
  
        axios({
          method: "POST",
          url: this.state.baseurl + 'wishlistdesc',//"http://localhost:3300/custdetails",
          data: { 
            customer_sap_code: this.state.customer_code
          }
        }).then((response) => {
          console.log("Testwsishlist", response.data.data)
          this.setState({
            get_wishdesc: response.data.data, // you have it as this.data not response
          });
        });
       
        axios({
          method: "POST",
          url: this.state.baseurl + 'wishlist',//"http://localhost:3300/custdetails",
          data: { 
            customer_sap_code: this.state.customer_code
          
          }
        }).then((response) => {
          console.log("Testwsishlist", response.data.data)
          this.setState({
            get_wishlist: response.data.data, // you have it as this.data not response
          });

         // this.state.viewlist = response.data.data

         


        });
      }
    

    }
    toggleewish =() => {

      this.state.get_wishlist =[];
      this.setState(prevState => ({
        modalwish: !prevState.modalwish,
        
    }));   
    }


    togglewishview =() => {

      if(this.state.customer_code ==''){
        alert("Please Select Customer Name")
      }else{
        this.setState(prevState => ({
          modalwishview: !prevState.modalwishview
        }));    
  
        axios({
          method: "POST",
          url: this.state.baseurl + 'wishlistview',//"http://localhost:3300/custdetails",
          data: { 
            customer_sap_code: this.state.customer_code
          
          }
        }).then((response) => {
          console.log("Testwsishlist", response.data.data)
          this.setState({
            get_wishlistview: response.data.data, // you have it as this.data not response
          });

          const getwish = response.data.data;
          const list =[];
      
          for(var i =0; i<getwish.length; i++){
            list.push({
              id: getwish[i].id,
              product_code:getwish[i].product_code,
              product_desc:getwish[i].product_desc,
              currency:getwish[i].currency,
              price:getwish[i].price,
              valid_from:getwish[i].valid_from,
              valid_to:getwish[i].valid_to,
              status:getwish[i].status,
              wish_list:getwish[i].wish_list,
              incoterm:getwish[i].incoterm,
              remove_item : ""
            })
          }

          console.log("Maniwishvani", list);

          this.setState({
            viewlist: list, // you have it as this.data not response
          });

        });
      }
      

    }

    toggleewishview =() => {

     

      this.state.get_wish_order =[]
      this.state.rows =[]
      this.state.segment_desc = '';
      this.state.segment_code ='';
     
      const getrows = this.state.rows
      let  gtrowpcode  =''
    

      
     
     
       this.setState(prevState => ({
        modalwishview: !prevState.modalwishview, 
        })); 

        this.state.get_month_order =[]
        this.state.orderadd = []

        const gtwish  = this.state.viewlist
        const   wish =[];
        for (var J=0; J< gtwish.length; J++){
          let rewish = gtwish[J].remove_item;
         
         
          if(rewish == ''){
            wish.push({
              "Code":gtwish[J].product_code
            })
          }
        

        }

        console.log("Manitest", wish)

        axios({
          method: "POST",
          url: this.state.baseurl + 'listofwish',//"http://localhost:3300/custdetails",
          data: {
            "cus_code" : this.state.customer_code,
             desc : wish
           
          }
          
        }).then((response) => {
          this.setState({
            get_month_order: response.data.data, // you have it as this.data not response
          });


          const getorr =[];
          const get_orderr = this.state.get_month_order
       
          for( var  k=0; k< get_orderr.length;k++){
            

            getorr.push({
              id: this.state.rows.length + 1,
              order_mmyyyy:this.state.month_year,
              customer_id :"",
              customer_sap_code: this.state.customer_code,
              notifyparty_sap_code:"",
              segment_code:get_orderr[k].segment_code,
              segment_desc:get_orderr[k].segment_desc,
              product_group:get_orderr[k].product_group,
              product_code:get_orderr[k].product_code,
              product_desc:get_orderr[k].product_desc,
              uom:"EA",
              order_qty:get_orderr[k].order_qty,
              price:get_orderr[k].price,
              order_value:"0",
              total_pi_qty:"0",
              balance_order_qty:"0"
            })

            console.log("Manienada", get_orderr[k].segment_desc)
         
            this.setState({
                rows : getorr,
                segment_desc: get_orderr[k].segment_desc,
               
                orderadd: getorr.length? getorr.map((wish , id )=>({
                id:   (id + 1),
                order_mmyyyy:wish.order_mmyyyy,
                customer_id :"",
                customer_sap_code :wish.customer_sap_code,
                notifyparty_sap_code:"",
                segment_code:wish.segment_code,
                segment_desc:wish.segment_desc,
                product_group:wish.product_group,
                product_code:wish.product_code,
                product_desc:wish.product_desc,
                uom:wish.uom,
                order_qty:wish.order_qty,
                price:wish.price,
                order_value:wish.order_value,
                total_pi_qty:wish.total_pi_qty,
                balance_order_qty:wish.balance_order_qty
    
              })):("")
            })
          }
        });
  
    }


    toggleewishviewclose =()=>{
      this.setState(prevState => ({
        modalwishview: !prevState.modalwishview, 
        })); 
    }

    handle_find =(e)=>{

      console.log("Manidesc", e.target.value)

       this.setState({
        find_search:e.target.value
      })
     
   
      axios({
        method: "POST",
        url: this.state.baseurl + 'wishlistfind',//"http://localhost:3300/custdetails",
        data: { 
          "customer_sap_code": this.state.customer_code,
          "wish_list" :this.state.selwish,
          "product_desc":e.target.value
        }
      }).then((response) => {
        console.log("Testwsishlistfind", response.data.data)
        this.setState({
          get_wishlist: response.data.data, // you have it as this.data not response
        });
      });

      
    }

    handle_find_code =(e)=>{

      console.log("Manidesc", e.target.value)

       this.setState({
        find_search_code:e.target.value
      })
     
   
      axios({
        method: "POST",
        url: this.state.baseurl + 'wishlistfind',//"http://localhost:3300/custdetails",
        data: { 
          "customer_sap_code": this.state.customer_code,
          "wish_list" :this.state.selwish,
          "product_code":e.target.value
        }
      }).then((response) => {
        console.log("Testwsishlistfind", response.data.data)
        this.setState({
          get_wishlist: response.data.data, // you have it as this.data not response
        });
      });

      
    }


    // handle_find =(selected)=>{
     
    //   var desc = "";
    //   for (var i = 0; i < selected.length; i++) {
    //     desc = selected[i].product_desc
    //   }
    //   this.setState({
    //     find_search:desc
    //   })
    //   axios({
    //     method: "POST",
    //     url: this.state.baseurl + 'wishlistfind',//"http://localhost:3300/custdetails",
    //     data: { 
    //       "customer_sap_code": this.state.customer_code,
    //       "wish_list" :this.state.selwish,
    //       "product_desc":desc
    //     }
    //   }).then((response) => {
    //     console.log("Testwsishlistfind", response.data.data)
    //     this.setState({
    //       get_wishlist: response.data.data, // you have it as this.data not response
    //     });
    //   });

      
    // }

    onRadioChange = (e) => {

      if (e.target.value === "Y") {
        this.setState({
          selwish: e.target.value
        });
      } else {
        this.setState({
          selwish: e.target.value
        });
      }

     

      console.log("Testradio", e.target.value)
    }
  

    render(){
      const {  rows =[], get_wishlist =[],get_wishlistview =[],  segment =[]}= this.state

  
      console.log("Maniorder", get_wishlistview);
      const today = new Date().toISOString();

      
      
   
      return(
        <div>
           <Modal style={{maxWidth: '450px', width: '100%' , }} isOpen={this.state.modaladd} toggle={this.toggleadd}>
                <ModalHeader toggle={this.toggleadd}>Add Info   </ModalHeader>
                <ModalBody>
                <Row style={{marginTop:"2px", marginBottom: "7px"}}>
                
                    <div  style={{width: "390px", marginLeft :20}} className="text-left">
                      <Form.Label className="name-label" >Notes</Form.Label>
                      <input  
                     
                      className="form-control" required="required" type="text" name="setup_name" 
                      onChange={this.handle_notes} value={this.state.notes}/>
                    </div>
                      
            
                  </Row>
                  <Row style={{marginTop:"2px", marginBottom: "7px"}}>
                  <div  style={{width: "390px", marginLeft :20}} className="text-left">
                  <Form.Label className="name-label">   Priority Item</Form.Label>
                      <input 
                       
                        className="form-control" required="required" type="text" name="setup_name" 
                        onChange ={this.handle_priority}
                        value={this.state.priority_item}/>
                        </div>
                    </Row>
                    <Row style={{marginTop:"2px", marginBottom: "7px"}}>
                    <div  style={{width: "390px", marginLeft :20}} className="text-left">
                    <Form.Label className="name-label">   Final Description</Form.Label>
                      <input  
                     
                        className="form-control" required="required" type="text" name="setup_name" 
                        onChange ={this.handle_final}
                        value ={this.state.final_desc}/>
                        </div>
                    </Row>
        
                </ModalBody>  
                <ModalFooter>
                    <Button   onClick={this.toggleeadd } color="primary"  >Add</Button>{' '}
                    <Button  onClick={this.toggleeadd } color="secondary" >Close</Button>
                </ModalFooter>         
            </Modal>  
         
            <Modal style={{maxWidth: '1200px', width: '100%', height: '700px'}} 
                isOpen={this.state.modalwish} toggle={this.togglewish}>
                <ModalHeader toggle={this.togglewish}>Wish List

               
                 </ModalHeader>
                <ModalBody>
                <Row style={{marginTop:"2px", marginBottom: "7px"}}>
                  <Col >
                  <Form.Label className="name-label">Product Desc   </Form.Label>
                  <input  className="form-control" required="required"   
                      type="text" name="setup_name" onChange={this.handle_find.bind(this)} 
                      value={this.state.find_search}/>    

                 
                  </Col>
                  <Col >
                  <Form.Label className="name-label">Product Code   </Form.Label>
                  <input  className="form-control" required="required"   
                      type="text" name="setup_name" onChange={this.handle_find_code.bind(this)} 
                      value={this.state.find_search_code}/>    

                 
                  </Col>
                </Row>

                <div  className="table"  style={{maxWidth: '1200px', width: '100%', height: '600px', overflow: 'auto'}}>
                    <Table  striped bordered  responsive="sm">
                      <thead style={{backgroundColor:"#413c69",color:"#ffffff", }}>
                        <tr>
                          <th style={{width: "20px"}}>#</th>
                          <th style={{fontSize:"20px"}}>Product Desc </th>
                          <th style={{fontSize:"20px"}} >Product Code</th>
                          <th style={{width: "50px"}}></th>
                        </tr>
                      </thead>
                      <tbody >
                      {get_wishlist.length ? 
                        get_wishlist.map((todo,id)=> (
                        <tr key={id}>
                          <th>{id +1 }</th>
                          <th style={{fontSize:"20px"}}>{todo.product_desc}</th>
                          <th style={{fontSize:"20px"}}>{todo.product_code}</th>
                          {todo.wish_list == 'Y'?(<>
                            <th><input  
                              defaultChecked={true}
                               id="rowcheck{todo.id}"
                               onChange={this.addwish.bind(this, todo)}
                              style={{marginLeft :15, marginTop:25}} type="checkcbox" /></th>
                          </>):(<>
                            <th><input   
                              onChange={this.addwish.bind(this, todo)}
                              id="rowcheck{todo.id}"
                              defaultChecked={this.state.checked}
                              style={{ fontSize:20,  marginLeft :15, marginTop:25}} type="checkbox" /></th>
                          </>)}
                        </tr>
                        ))
                        : 
                        (<tr>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                        
                        </tr>)
                      } 
                     
                      </tbody>
                    </Table>
                  </div>

                      
                </ModalBody>  
                <ModalFooter>
              
                  <Button  onClick={()=>this.toggleewish() } color="primary"  >Save</Button>{' '}
                  <Button  onClick={this.toggleewish } color="secondary" >Close</Button>
                </ModalFooter>         
            </Modal> 

             <Modal style={{maxWidth: '1200px', width: '100%', height: '700px'}} 
                isOpen={this.state.modalwishview} toggle={this.togglewishview}>
                <ModalHeader toggle={this.togglewishview}>Wish List view

                 </ModalHeader>
                <ModalBody>
               

                <div  className="table"  style={{maxWidth: '1200px', width: '100%', height: '600px', overflow: 'auto'}}>
                    <Table  striped bordered  responsive="sm">
                      <thead style={{backgroundColor:"#413c69",color:"#ffffff", }}>
                        <tr>
                          <th style={{width: "20px"}}>#</th>
                          <th style={{fontSize:"20px"}}>Product Desc </th>
                          <th style={{fontSize:"20px"}} >Product Code</th>
                          <th style={{width: "50px"}}></th>
                        </tr>
                      </thead>
                      <tbody >
                      { this.state.viewlist.length ? 
                         this.state.viewlist.map((todo,id)=> (
                        <tr key={id}>
                          <th>{id +1 }</th>
                          <th style={{fontSize:"20px"}}>{todo.product_desc}</th>
                          <th style={{fontSize:"20px"}}>{todo.product_code}</th>
                          {todo.wish_list == 'Y'?(<>
                            <th><input  
                              defaultChecked={true}
                               id="rowcheck{todo.id}"
                               onChange={this.addwishview.bind(this, id)}
                              style={{marginLeft :15, marginTop:25}} type="checkbox" /></th>
                          </>):(<>
                            <th><input   
                              onChange={this.addwishview.bind(this, todo)}
                              id="rowcheck{todo.id}"
                              defaultChecked={this.state.checked}
                              style={{ fontSize:20,  marginLeft :15, marginTop:25}} type="checkbox" /></th>
                          </>)}
                        </tr>
                        ))
                        : 
                        (<tr>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                        
                        </tr>)
                      } 
                     
                      </tbody>
                    </Table>
                  </div>

                      
                </ModalBody>  
                <ModalFooter>
              
                  <Button   onClick={()=>this.toggleewishview() } color="primary"  >Create Order</Button>{' '}
                    <Button  onClick={this.toggleewishviewclose } color="secondary" >Close</Button>
                </ModalFooter>         
            </Modal>  
          <Container fluid>
              <Card style={{margin: "16px"}}>
                <Card.Body>

             
                
                  <Row style={{marginTop:"2px", marginBottom: "7px"}}>
                  <Col >
                  {localStorage.getItem('usertypecode') != 'CUST'?(
                    <>
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                      <div>
                      <Typeahead
                         id="exampleSelect" 
                         style={{width: "490px"}}
                         selected={this.selected}
                         onChange={this.handle_cust.bind(this)}
                         value={this.state.customer_name}
                         labelKey={option => `${option.customer_name} `}
                         options={this.state.cust_list}
                         placeholder="Select a customer..."
                       />     
                      </div>
                    </>
                  ):(<>
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                      <div>

                      <input  className="form-control" required="required" 
                          placeholder=''
                          style={{width: "490px"}}
                          type="text" name="setup_name" readOnly ={true} value={this.state.get_cust_name}/> 
                      {/* <Typeahead
                         id="exampleSelect" 
                          style={{width: "490px"}}
                          selected={this.selected}
                          onChange={this.handle_cust.bind(this)}
                          value={this.state.customer_name}
                          labelKey={option => `${option.customer_name} `}
                          options={this.state.cust_list}
                          placeholder="Select a customer..."
                        />         */}
                      </div>
                  </>)}
                    
                  </Col>
                    <Col className="text-left">
                      <Form.Label className="name-label">Month & Year</Form.Label>
                      <input  className="form-control" required="required" 
                       type="month" name="month" 
                       onChange={this.handle_month_year}
                       value={this.state.month_year}/>    
                      
                    </Col>
                    
                    <Col className="text-left">
                      <Form.Label className="name-label">PO Number   </Form.Label>
                      <input  className="form-control" required="required" 
                      placeholder='Enter PO Number'
                      maxLength={10}
                      type='number'
                      onChange={this.handle_ponumber} value={this.state.po_number}/>    
                    </Col>   

                      <Col className="text-left">
                      <Form.Label className="name-label">PO Date   </Form.Label>
                      <input  max ={new Date()}   className="form-control" required="required"   
                      type="date" name="setup_name" onChange={this.handle_podate} 
                      value={this.state.po_date}/>    
                    </Col> 
                    <Col className="text-left">
                    <div className="text-left">
                      <Form.Label className="name-label" >Order Type</Form.Label>
                    </div>
                    
                  
                      <div>
                        <select  className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`}
                          value={this.state.order_type} onChange={this.handle_ordertype}>
                          <option>Select Type</option>
                          <option value="lc">LC</option>
                          <option value="non lc">NON LC</option>
                        </select>
                      </div>  
                    </Col> 
                        

                  </Row>

                  <Row style={{marginTop:"2px", marginBottom: "7px"}}>
                  <Col >
                    <div className="text-left">
                      <Form.Label className="name-label" >Port of Delivery</Form.Label>
                      <input  style={{width: "490px"}}  
                      className="form-control" required="required" type="text" name="setup_name" 
                      onChange={this.handle_port_delivery} value={this.state.port_delivery}/>
                    </div>
                      
                  </Col>
                    
                  
                    <Col className="text-left">
                      <Form.Label className="name-label">Containter Size</Form.Label>

                      <select  className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`}
                         value={this.state.container_size} onChange={this.handle_containter}>
                           <option>Select</option>
                         {this.state.container.length?
                         this.state.container.map((todo, id) => (
                          <option value={todo.container_type}>{todo.container_type}</option>
                        ))
                        :
                        (<option></option>)}
                        </select>
                    
                      {/* <Typeahead
                         id="exampleSelect" 
                         selected={this.selected}
                         onChange={this.handle_containter.bind(this)}
                         defaultInputValue={this.state.container_size}
                         inputProps={this.state.container_size}
                         value={this.state.container_size}
                         labelKey={option => `${option.container_type} `}
                         options={this.state.container}
                         placeholder="Select a Container..."
                       />         */}
                      
                    </Col>
                    
                    <Col className="text-left">
                      <Form.Label className="name-label">Payment Term   </Form.Label>
                      <input  className="form-control" 
                         readOnly={true}
                        required="required" type="text" name="setup_name" 
                        onChange={this.handle_payterm} value={this.state.pay_term}/>
                    </Col>   

                      <Col className="text-left">
                      <Form.Label className="name-label">Incoterm   </Form.Label>
                      <input  className="form-control" required="required" 
                         readOnly={true}
                      type="text" name="setup_name" onChange={this.handle_incotern} value={this.state.incotern}/>
                    </Col> 
                    <Col className="text-left">
                      <Form.Label className="name-label">Currency   </Form.Label>
                      <input  className="form-control" required="required" 
                         readOnly={true}
                      type="text" name="setup_name" onChange={this.handle_curreny} value={this.state.curreny}/>
                    </Col> 
                        

                  </Row>
                
                  <Row style={{marginTop:"2px", marginBottom: "7px"}}>
                  <Col >

                  <Form.Label className="name-label">Customer Code </Form.Label>
                      <input  className="form-control" 
                      required="required" type="text" 
                      name="setup_name" 
                      style={{width: "490px"}}
                      readOnly={true}
                      onChange={this.handleChangeMaster} 
                      value={this.state.customer_code }/>
                  
                   

                  </Col>
                    
                   
                    <Col className="text-left">
                      <Form.Label className="name-label">Notify Party </Form.Label>
                      <input  
                        style={{marginLeft :15, marginTop:45}}
                        defaultChecked={false}
                        onChange ={this.notichecked}
                        type="checkbox" />
                    </Col>
                    {this.state.notychecked == true?(<>
                      <Col className="text-left">
                    <div className="text-left">
                      <Form.Label className="name-label" >Notify Party Name</Form.Label>
                    </div>
                    
                  
                      <div>
                        <select className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`} defaultChecked={this.state.master_type} value={this.state.master_type} onChange={this.handleMasterType}>
                          <option>Select Type</option>
                          <option key="Setup">Setup</option>
                          <option key="Process">Process</option>
                        </select>
                      </div>
                    </Col>   

                    <Col className="text-left">
                    <Form.Label className="name-label">Notify Party Code </Form.Label>
                      <input  className="form-control" required="required" type="text" name="setup_name" onChange={this.handleChangeMaster} value={this.state.setup_name}/>
                    </Col> 
                    </>):(<>
                      <Col className="text-left"></Col>
                      <Col className="text-left"></Col>
                    </>)}
                    
                    <Col className="text-left">
                      {/* <Fab style={{marginTop:25}}   size="small" color="primary" aria-label="add">
                        <AddIcon onClick={() => this.handleAddRow()}/>
                      </Fab> */}
                      <Fab style={{marginTop:25, marginLeft: 10}}   size="small" color="primary" aria-label="info">
                        <InfoIcon onClick={() => this.toggleadd()}/>
                      </Fab>
                      <Fab style={{marginTop:25, marginLeft: 10}}   size="small" color="primary" aria-label="FavoriteBorderOutlined">
                        < FavoriteBorderOutlinedIcon onClick={() => this.togglewish()}/>
                      </Fab>

                      <Fab style={{marginTop:25, marginLeft: 10}}   size="small" color="primary" aria-label="FavoriteBorderOutlined">
                        < AddShoppingCartIcon onClick={() => this.togglewishview()}/>
                      </Fab>
                      
                      {/* <Button style={{marginLeft:15, marginTop:25}}  onClick={() => this.handleModalShowHide()} variant = "info" >ADDL INFO</Button> */}
                   
                    </Col> 
                  </Row>
                </Card.Body>
              </Card>
          </Container>

                <div className="table" style={{width:"100%",display:"inline-table", margin: "15px", backgroundColor: "#fff"}}>
                    <Table striped bordered  responsive="sm">
                      <thead style={{backgroundColor:"#413c69",color:"#ffffff"}}>
                        <tr>
                          <th style={{width: "20px", color:'white'}}>#</th>
                          <th style={{width: "200px",color:'white'}}>Segment</th>
                          <th style={{width: "100px",color:'white'}}>Size</th>
                          <th style={{width: "400px",color:'white'}}>Product Name</th>
                          <th style={{width: "200px", color:'white'}}>Product Code</th>
                          {/* <th style={{width: "150px", color:'white'}}>Price</th> */}
                          <th style={{width: "150px", color:'white'}}>Order Qty</th>
                          <th style={{width: "100px",color:'white'}}>Total PI Qty</th>
                          <th style={{width: "100px",color:'white'}}>Balance Qty</th>
                          {/* <th style={{width: "150px",color:'white'}}>Value</th> */}
                          <th style={{width: "70px",color:'white'}}>
                            <Fab  size="small"  aria-label="add">
                              <AddIcon onClick={() => this.handleAddRow()}/>
                            </Fab></th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((item, idx) => ( 
                           <tr key={idx}>
                           <td>{idx + 1}</td>
                           <td style={{width: 200}}>
                                <Typeahead
                                      id={"exampleSelect" + idx}
                                      selected={this.selected}
                                      defaultInputValue={this.state.orderadd[idx].segment_desc}
                                      onChange={this.handle_segment.bind(this, idx)}
                                      value={this.state.orderadd[idx].segment_desc}
                                      labelKey={option => `${option.segment_desc} `}
                                      options={this.state.segment}
                                      placeholder="Select a Segment..."
                                  />
                             </td>
                             <td style={{width: 200}} >
                                  <Typeahead
                                          id={"exampleSelect" + idx}
                                          selected={this.selected}
                                          defaultInputValue={this.state.orderadd[idx].product_group}
                                          onChange={this.handle_productsize.bind(this, idx)}
                                          value={this.state.orderadd[idx].product_group}
                                          labelKey={option => `${option.product_group} `}
                                          options={this.state.product}
                                          placeholder="Select a product"
                                        />        
                              </td>
                              <td style={{width: 230}}>
                                  <Typeahead
                                          id={"exampleSelect" + idx}
                                          selected={this.selected}
                                          defaultInputValue={this.state.orderadd[idx].product_desc}
                                          onChange={this.handle_productname.bind(this, idx)}
                                          value={this.state.orderadd[idx].product_desc}
                                          labelKey={option => `${option.product_desc} `}
                                          options={this.state.product}
                                          placeholder="Select a product"
                                        />        
                                </td>
                                <td style={{width: 120}}>
                                   <input
                                             className="form-control"
                                             id={"exampleSelect" + idx}
                                             value ={this.state.orderadd[idx].product_code}
                                             placeholder=""
                                             readOnly={true}
                                             type="text">
                                             </input>
                                           </td>
                                            <td style={{width: 120}}><input
                                               className="form-control"
                                             id={"exampleSelect" + idx}
                                             onChange ={this.handle_qty.bind(this,idx)}
                                             value ={this.state.orderadd[idx].order_qty}
                                             placeholder=""
                                             type="text"
                                           /></td>
                                            <td style={{width: 100}}><input
                                             className="form-control"
                                             id={"exampleSelect" + idx}
                                             value ={this.state.orderadd[idx].total_pi_qty}
                                             onChange ={this.handle_tqty.bind(this,idx)}
                                             readOnly={true}
                                             placeholder=""
                                             type="text"
                                           /></td>
                                            <td style={{width: 100}}><input
                                            className="form-control"
                                             id={"exampleSelect" + idx}
                                             onChange ={this.handle_bqty.bind(this,idx)}
                                             readOnly={true}
                                             value ={this.state.orderadd[idx].balance_order_qty}
                                             placeholder=""
                                             type="text"
                                           /></td>             
                                           <td style={{width: 70}}>
                                           <Fab  size="small" color="primary" aria-label="add">
                                             <DeleteIcon  onClick={()=>this.handleDeleteIcon(idx)} size="small"/>
                                           </Fab>  
                                           </td>                   
                         </tr>
                         ))}


                      </tbody>
                    </Table>
                  </div>

                
                    <div className="text-center" style={{marginBottom: "50px"}}>
                      <Button variant = "success" 
                          style={{marginRight:"2%"}} 
                          onClick={(e) => { if (window.confirm("Are you sure submit?")) this.submitLists(e) }}>Submit</Button>
                      <Button variant = "success" onClick={() => {window.location.href="/home"}}>Exit</Button>      
                    </div>
                 
                    
                  <div><ToastContainer/></div>
                

            {/* </Container> */}
          </div>
      )        
    }



    handleAddRow = () => {

      let custcode = ''
      if(localStorage.getItem('usertypecode') == 'CUST'){
        custcode = this.state.get_cust_code
      }else{
        custcode = this.state.customer_code

      }


      if(this.state.customer_code ==''){
        alert("Please Select Customer Name")
      }else{

        const item = {
          add_segment:this.state.add_segment,
          add_size:this.state.add_size,
          add_pname:this.state.add_pname,
          add_pcode:this.state.add_pcode,
          //add_price:this.state.add_price,
          add_oqty:this.state.add_oqty,
          add_tqty:this.state.add_tqty,
          add_bqty:this.state.add_bqty,
         // add_value :this.state.add_value,
        };
        this.setState({
          rows: [...this.state.rows, item]
        });
    
        this.state.orderadd.push({
          id: this.state.rows.length + 1,
          order_mmyyyy:this.state.month_year,
          customer_id :this.state.customer_id,
          customer_sap_code :custcode,
          notifyparty_sap_code:"",
          segment_code:"",
          segment_desc:"",
          product_group:"",
          product_desc:"",
          product_code:"",
          uom:"EA",
          order_qty:"",
          price:"0",
          order_value:"",
          total_pi_qty:"0",
          balance_order_qty:"0"
        })
      }
     
    
    };

    
 
   

    handleDeleteIcon = (idx) => {
  

      var currentSetup = this.state.get_month_order  

     console.log("mANIDELTE", currentSetup[idx].id)
        
      if(currentSetup[idx].id != 0){
          var current_deleted_ids = this.state.deleted_ids;
          var deleted_ids = current_deleted_ids.push(currentSetup[idx].id);
          this.setState({deleted_ids: current_deleted_ids})

          console.log("DELETE", current_deleted_ids.push(currentSetup[idx].id))
      }

      if(currentSetup[idx].id != null){
       axios({
        method: "POST",
        url: this.state.baseurl +'delete_record' ,//"http://localhost:3300/user_login",
        data: { 
          deleted_ids :  currentSetup[idx].id
         
        }
      }).then((response) => {
        this.state.rows = []

        if(response.data.status == "Success"){
          axios({
            method: "POST",
            url: this.state.baseurl + 'getmonthlydtls',//"http://localhost:3300/custdetails",
            data: { 
              customer_sap_code: this.state.customer_code,
              order_mmyyyy: this.state.month_year,
            }
          }).then((response) => {
            console.log("Test123month", response.data.status)
           
            if(response.data.status == 'Success'){
               
              this.setState({
                get_month_order :response.data.data,
                get_status :response.data.status,
              });
             
             var  get_cust  = response.data.hdrs
              for (var j=0; j< get_cust.length;j++){
                this.state.curreny = get_cust[j].order_category
                this.state.pay_term = get_cust[j].payment_term;
                this.state.incotern = get_cust[j].inco_term;
                this.state.port_delivery = get_cust[j].port_of_discharge;
                this.state.customer_code = get_cust[j].customer_sap_code;
                this.state.customer_id = get_cust[j].customer_id
                this.state.po_number = get_cust[j].po_number
                this.state.order_type = get_cust[j].order_type
                this.state.po_date = moment(get_cust[j].po_date).format('YYYY-MM-DD')
                this.state.notes = get_cust[j].notes
                this.state.priority_item =get_cust[j].priority_item
                this.state.final_desc = get_cust[j].final_destination
                this.state.container_size = get_cust[j].container_type
              }
              const getor =[];
              const get_order = this.state.get_month_order;
              console.log("CUSTMANI", get_order)
        
              for( var  i=0; i< get_order.length;i++){
                getor.push({
                  id: this.state.rows.length + 1,
                  order_mmyyyy:this.state.month_year,
                  customer_id :this.state.customer_id,
                  customer_sap_code :this.state.customer_code,
                  notifyparty_sap_code:get_order[i].notifyparty_sap_code,
                  segment_code:get_order[i].segment_code,
                  segment_desc:get_order[i].segment_desc,
                  product_group:get_order[i].product_group,
                  product_code:get_order[i].product_code,
                  product_desc:get_order[i].product_desc,
                  uom:"EA",
                  order_qty:get_order[i].order_qty,
                  price:get_order[i].price,
                  order_value:get_order[i].order_value,
                  total_pi_qty:get_order[i].total_pi_qty,
                  balance_order_qty:get_order[i].balance_order_qty
                })
                console.log("Testsri", getor)
        
                this.setState({
                    rows : getor,
                    segment_desc: get_order[i].segment_desc,
                
                    orderadd: getor.length? getor.map((order , id )=>({
                    id:   (id + 1),
                    order_mmyyyy:order.order_mmyyyy,
                    customer_id :order.customer_id,
                    customer_sap_code :order.customer_sap_code,
                    notifyparty_sap_code:order.notifyparty_sap_code,
                    segment_code:order.segment_code,
                    segment_desc:order.segment_desc,
                    product_group:order.product_group,
                    product_code:order.product_code,
                    product_desc:order.product_desc,
                    uom:order.uom,
                    order_qty:order.order_qty,
                    price:order.price,
                    order_value:order.order_value,
                    total_pi_qty:order.total_pi_qty,
                    balance_order_qty:order.balance_order_qty
        
                  })):("")
        
                
                })
              }
            }
          });
        }
      });
      }
      
      currentSetup.splice(idx, 1)
      this.setState({rows: currentSetup})
    
    }
    

  
  }


 