import React from 'react';
import { Component} from 'react'
import { Table, Form } from 'react-bootstrap';
import {Button,Card, ProgressBar} from 'react-bootstrap';
import {Container } from 'react-bootstrap';
import {Row,Col } from 'react-bootstrap';
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import Fab from '@material-ui/core/Fab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-select2-wrapper/css/select2.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import axios from 'axios';
import moment from 'moment'
import { Typeahead, TypeaheadInputMulti } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';


export default class TwExportGrnAck extends Component {
  constructor(props){
    super(props)
    this.state = ({
      cust_list :[],
      invoicedocno_list :[],
      invoice_ack_status_list :[],
      order_add :[],
      get_cu_details :[],
      month_year :"",
      po_number :"",
      customer_name :"",
      customer_code :"",
      selectedFile:'',
      order_type :'',
      invoiceackstatus :'All',
      cust_feedback :'',
      ship_start_date :'',
      expected_arrival:'',
  
      modal: false,
      from_date :'',
      to_date :"",

      get_cust_name :'',
      get_cust_code : '',
      baseurl :  process.env['REACT_APP_API_URL_'+localStorage.getItem('segment') ],
     
    })

    this.handle_cust = this.handle_cust.bind(this);
    this.handle_document = this.handle_document.bind(this);
    this.handle_invoicedocno = this.handle_invoicedocno.bind(this);
    this.handle_invoiceackstatus = this.handle_invoiceackstatus.bind(this);
    this.handle_updatereceipt = this.handle_updatereceipt.bind(this);
    
  }

  componentDidMount(){
        this.redirectLogin()
        localStorage.setItem("screen_zoom", "80%")
        document.body.style.zoom = localStorage.getItem("screen_zoom");
        
        const date = new Date();
        const futureDate = date.getDate() ;
        date.setDate(futureDate);
        const defaultValue = date.toLocaleDateString('en-CA');

        this.state.from_date = defaultValue
        this.state.to_date = defaultValue
    
        if(localStorage.getItem('usertypecode') == 'CUST'){
          axios({
            method: "POST",
            url: this.state.baseurl +'custdetails' ,//"http://localhost:3300/user_login",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
          },
            data: { 
              user_code :  localStorage.getItem('empcode'),
              user_type_code :localStorage.getItem('usertypecode')
            }
          }).then((response) => {
            console.log("Test", response.data.data)
            this.setState({
              cust_list: response.data.data, // you have it as this.data not response
              get_cust_name :response.data.data[0].customer_name,
              get_cust_code : response.data.data[0].customer_sap_code 
            });

            axios({
              method: "POST",
              url: this.state.baseurl + 'getcustomerinvoicelist',//"http://localhost:3300/custdetails",
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            },
              data: { 
                "from_date":this.state.from_date,
                "to_date":this.state.to_date,
                "customer_sap_code":response.data.data[0].customer_sap_code ,
                "invoice_ack_status":this.state.invoiceackstatus
              }
            }).then((response) => {
      
              this.setState({
                invoicedocno_list:[]
              });
      
         
            });
          });
        }else{
          axios({
            method: "POST",
            url: this.state.baseurl +'custdetails' ,//"http://localhost:3300/user_login",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
          },
            data: { 
              user_code :  localStorage.getItem('empcode'),
              user_type_code :localStorage.getItem('usertypecode')
            }
          }).then((response) => {
            console.log("Test", response.data.data)
            this.setState({
              cust_list: response.data.data, // you have it as this.data not response
            });
          });
        }
  }
 
  redirectLogin = () => {
    if(localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined || localStorage.getItem("token") == null){
        localStorage.setItem('token', '')
        window.location.href = "/login"
    } 
  }
 

    // handle customer name 
    
    handle_cust =(selected)=>{
      var cuname = "";
      var cuscode ='';
      this.state.ship_start_date  =""
      this.state.expected_arrival = ""
      for (var i = 0; i < selected.length; i++) {
        cuname = selected[i].customer_name;
        cuscode =  selected[i].customer_sap_code
      }
      this.setState({
        customer_name:cuname,
        customer_code :cuscode
      })
      console.log('status',this.state.invoiceackstatus)
      axios({
        method: "POST",
        url: this.state.baseurl + 'getcustomerinvoicelist',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
      },
        data: { 
          "from_date":this.state.from_date,
          "to_date":this.state.to_date,
          "customer_sap_code":cuscode,
          "invoice_ack_status":this.state.invoiceackstatus
        }
      }).then((response) => {

        this.setState({
          invoicedocno_list:[]
        });

   
      });
     
    }

    handle_invoiceackstatus =(e)=>{

    var invoicestatus = e.target.value;
    this.state.invoicedocno_list=[];
    this.state.get_cu_details=[];
    this.state.invoice_doc_no='';
    this.state.curreny ='';
    this.state.ship_start_date  =""
    this.state.expected_arrival = ""
   
    
     this.setState({
        invoiceackstatus:invoicestatus,
        invoice_doc_no: '',
        invoicedocno_list:[]
     })

     let custcode = ''
     if(localStorage.getItem('usertypecode') == 'CUST'){
       custcode = this.state.get_cust_code
     }else{
       custcode = this.state.customer_code

     }

      axios({
        method: "POST",
        url: this.state.baseurl + 'getcustomerinvoicelist',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
      },
        data: { 
          "from_date":this.state.from_date,
          "to_date":this.state.to_date,
          "customer_sap_code": custcode,
          "invoice_ack_status" : invoicestatus,   
        }
      }).then((response) => {
         
          console.log("tESTNUMBER", response.data.data[0].invoice_doc_no)
          this.setState({
            invoicedocno_list: response.data.data,
            });

          axios({
            method: "POST",
            url: this.state.baseurl + 'getcustomeriteminvoice',//"http://localhost:3300/custdetails",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
          },
            data: { 
              "from_date":this.state.from_date,
              "to_date":this.state.to_date,
              "customer_sap_code":custcode,
              "invoice_ack_status":invoicestatus,
              "invoice_doc_no":response.data.data[0].invoice_doc_no,
            }
          }).then((response) => {
            this.setState({
              get_cu_details: response.data.data,
              invoice_doc_no:response.data.data[0].invoice_doc_no

            });
            
            var  get_cust  = response.data.data
            var containername = ''
            var blnumber = ''
            var bldate = ''        
            var invoicevalue=0
            var invoicedate = null
            var currency =""
            for (var j=0; j< get_cust.length;j++){
              containername = get_cust[j].container_name
              blnumber = get_cust[j].bl_number
              bldate = get_cust[j].bl_doc_date
              invoicevalue  = get_cust[j].invoice_net_value
              invoicedate = get_cust[j].invoice_date 
              currency = get_cust[j].currency
            }
    
            this.setState({
              get_cu_details: response.data.data,
              container_name: containername,
              bl_number: blnumber,
              bl_doc_date: bldate,
              invoice_net_value: invoicevalue,
              invoice_date : invoicedate,
              currency : currency
            });


          });

   
      });
     
    }

  
    handle_invoicedocno =(e)=>{
      var invoiceno = e.target.value;
      //  for (var i = 0; i < selected.length; i++) {
      //   invoiceno = selected[i].invoice_doc_no;
      // }
      this.state.currency =''
      this.state.ship_start_date  = ""
      this.state.expected_arrival = ""
     
      this.setState({
        invoice_doc_no:invoiceno,
        get_cu_details:[],
      })

      let custcode = ''
      if(localStorage.getItem('usertypecode') == 'CUST'){
        custcode = this.state.get_cust_code
      }else{
        custcode = this.state.customer_code
 
      }
      
      axios({
        method: "POST",
        url: this.state.baseurl + 'getcustomeriteminvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
      },
        data: { 
          "from_date":this.state.from_date,
          "to_date":this.state.to_date,
          "customer_sap_code":custcode,
          "invoice_ack_status":this.state.invoiceackstatus,
          "invoice_doc_no":invoiceno,
        }
      }).then((response) => {
        console.log("MANI", response.data.data)
 

        var  get_cust  = response.data.data
        var containername = ''
        var blnumber = ''
        var bldate = ''        
        var invoicevalue=0
        var invoicedate = null
        var curreny =""
        var ship_start = ""
        var expected_arr =""
        for (var j=0; j< get_cust.length;j++){
          containername = get_cust[j].container_name
          blnumber = get_cust[j].bl_number
          bldate = get_cust[j].bl_doc_date
          invoicevalue  = get_cust[j].invoice_net_value
          invoicedate = get_cust[j].invoice_date 
          curreny = get_cust[j].currency
          ship_start =get_cust[j].ship_start_date
          expected_arr =get_cust[j].expected_time_arrival
        }

        this.setState({
          get_cu_details: response.data.data,
          container_name: containername,
          bl_number: blnumber,
          bl_doc_date: bldate,
          invoice_net_value: invoicevalue,
          invoice_date : invoicedate,
          currency :curreny,
          ship_start_date :ship_start,
          expected_arrival:expected_arr,
        });
          
   
      });
     
    }

  
       // handle Month and year  
       handle_fromdate =(e)=>{
  
      this.setState({
        from_date : e.target.value
      })

      let custcode = ''
      if(localStorage.getItem('usertypecode') == 'CUST'){
        custcode = this.state.get_cust_code
      }else{
        custcode = this.state.customer_code
 
      }
      axios({
        method: "POST",
        url: this.state.baseurl + 'getcustomeriteminvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
      },
        data: { 
          "from_date": e.target.value,
          "to_date": this.state.to_date,
          "customer_sap_code":custcode
        }
      }).then((response) => {
        console.log("MANI", response.data.data)
        this.setState({
          get_cu_details: response.data.data,
        });
      });

    }

      handle_todate =(e)=>{
      this.setState({
              to_date : e.target.value
            })

            let custcode = ''
            if(localStorage.getItem('usertypecode') == 'CUST'){
              custcode = this.state.get_cust_code
            }else{
              custcode = this.state.customer_code
       
            }
      axios({
        method: "POST",
        url: this.state.baseurl + 'getcustomeriteminvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
      },
        data: { 
          "from_date":this.state.from_date,
          "to_date":e.target.value,
          "customer_sap_code":custcode
        }
      }).then((response) => {
        console.log("MANI", response.data.data)
        this.setState({
          get_cu_details: response.data.data,
        });
      });
    
    }

 
     handle_document =(id,e)=>{

      var items = this.state.get_cu_details;

      this.setState({
        invoice_received_qty : e.target.value
      })

      console.log("Test",e.target.value);

      items[id].invoice_received_qty = e.target.value;
      items[id].excess_shortage_qty = e.target.value -  items[id].invoice_qty;

      this.setState({
        get_cu_details: items
      });
    }


    


    submitsave =(event) =>{

      let custcode = ''
      if(localStorage.getItem('usertypecode') == 'CUST'){
        custcode = this.state.get_cust_code
      }else{
        custcode = this.state.customer_code
 
      }
      event.preventDefault();
      axios({
        method: "POST",
        url: this.state.baseurl + 'updateiteminvoice',//"http://localhost:3300/invoiceupdate",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
      },
        data: { 
          p_invoice_details: JSON.stringify({"items" : this.state.get_cu_details})
         
        }
      }).then((response) => {
        let res = response.data.message
        var getres =''
       
        for (var i =0; i< res.length ;i++){
          getres = res[i].fi01_invoice_update_detail
        }
        console.log('output',getres)
        var gtres = getres.substring(0,1)
        if(gtres == "R"){
          this.notify(getres)
        }else{
          window.confirm(getres)
        }
      });

    }


    submitconfirm =(event) =>{
      event.preventDefault();
      var items = this.state.get_cu_details;
       for (var j=0; j< items.length;j++){
         items[j].invoice_ack_status = 'Completed';
      }  
      this.setState({
        get_cu_details: items
      });


      axios({
        method: "POST",
        url: this.state.baseurl + 'updateiteminvoice',//"http://localhost:3300/invoiceupdate",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
      },
        data: { 
          p_invoice_details: JSON.stringify({"items" : this.state.get_cu_details})
         
        }
      }).then((response) => {
        let res = response.data.message
        var getres =''
       
        for (var i =0; i< res.length ;i++){
          getres = res[i].fi01_invoice_update_detail
        }
       
        var gtres = getres.substring(0,1)
        if(gtres == "R"){
          this.notify(getres)
        }else{
          window.confirm(getres)
        }
      });

    }
    handle_cust_feedback=(e)=>{
      this.setState({
        cust_feedback : e.target.value
      })
    }

    handle_updatereceipt=(e)=>{
      var items = this.state.get_cu_details;
      for (var j=0; j< items.length;j++){
        items[j].invoice_received_qty = items[j].invoice_qty;
        items[j].excess_shortage_qty =  0;        
     }  
      this.setState({
        get_cu_details: items
      });

    }
    

  notify = (getres) => {
      toast.success(getres, {
          position: toast.POSITION.TOP_CENTER
      });
  };
  
  errorNotify = (message) => {
      toast.error(message, {
          position: toast.POSITION.TOP_CENTER
      });
  }


    render(){
      const {  get_cu_details =[], invoicedocno_list =[]}= this.state

     const invoice_list =[];
     console.log("invoice_list",invoicedocno_list )
     for (var i=0; i< invoicedocno_list.length;i++){

      invoice_list.push({id: i+1, invoice_no: invoicedocno_list[i].invoice_doc_no})
    }
    console.log("invoice_list",invoice_list )
    
      return(
        <div>
          
 
          <Container fluid>
              <Card style={{margin: "5px"}}>
                <Card.Body>
                <h4><b>GRN Acknowlegment </b></h4>
                  <Row style={{marginTop:"2px", marginBottom: "7px"}}>

                    
                     <Col className="text-left">
                      <Form.Label className="name-label">From  Date   </Form.Label>
                      <input  className="form-control" required="required" 
                      type="date" name="setup_name" onChange={this.handle_fromdate} 
                      value={this.state.from_date}/> 
                         
                    </Col>             

                    <Col className="text-left">
                      <Form.Label className="name-label">To Date   </Form.Label>
                      <input  className="form-control" required="required"   
                      type="date" name="setup_name" onChange={this.handle_todate} 
                      value={this.state.to_date}/>    
                    </Col> 
                    
                    <Col >

                     {localStorage.getItem('usertypecode') != 'CUST'?(<>
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                      <div>
                      <Typeahead
                         id="exampleSelect" 
                         style={{width: "490px"}}
                         selected={this.selected}
                         onChange={this.handle_cust.bind(this)}
                         value={this.state.customer_name}
                         labelKey={option => `${option.customer_name} `}
                         options={this.state.cust_list}
                         placeholder="Select a customer..."
                      />        
                      </div>
                  </>):(<>
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                      <div>

                      <input  className="form-control" required="required" 
                          placeholder=''
                          style={{width: "490px"}}
                          type="text" name="setup_name" readOnly ={true} value={this.state.get_cust_name}/> 
                      </div>
                  </>)}
                     </Col>     

                     <Col >
                    <div className="text-left">
                      <Form.Label className="name-label" >Invoice Status</Form.Label>
                    </div>
                      <div>
                      <select  className={`form-control `}
                          value={this.state.invoiceackstatus} onChange={this.handle_invoiceackstatus}>
                          <option value="All">All</option>
                          <option value="Pending">Pending</option>
                          <option value="Completed">Completed</option>
                        </select>
                      </div>
                     </Col>                    

                       <Col >
                    <div className="text-left">
                      <Form.Label className="name-label" >Invoice List</Form.Label>
                    </div>
                      <div>
                        <select  className={`form-control`}
                          defaultValue={invoice_list[1]}
                         
                          value={this.state.invoice_doc_no}
                          options ={invoice_list}
                          onChange={this.handle_invoicedocno.bind(this)}>
                            {invoice_list.length ?
                                  invoice_list.map((todo, id) => (

                                    <option key={id} value={todo.invoice_no} >{todo.invoice_no}</option>
                                  ))
                                  :
                                  (<option></option>)
                                }
                          </select>
                      </div>
                  </Col>       

                    <Col className="text-left">
                    </Col>                    



                  </Row>

                </Card.Body>
              </Card>
          </Container>

          <Container fluid>
              <Card style={{margin: "5px"}}>
                <Card.Body>
            

                  <Row style={{marginTop:"2px", marginBottom: "7px"}}>


                  <Col className="text-left">
                        <Form.Label className="name-label">Invoice Date</Form.Label>
                        <input  className="form-control"    
                        type="text" name="invoice_date"   
                        readOnly={true}                         
                        value={moment(this.state.invoice_date).format('DD-MM-YYYY')} />    
                      </Col> 
                        
                    <Col className="text-left">
                        <Form.Label className="name-label">Invoice Value</Form.Label>
                        <input  className="form-control"    
                        type="text" name="invoice_net_value"  
                        readOnly={true} 
                        value={this.state.invoice_net_value}/>     
                      </Col> 
                  
                      <Col className="text-left">
                        <Form.Label className="name-label">Currency</Form.Label>
                        <input  className="form-control"    
                        type="text" name="invoice_net_value"  
                        readOnly={true} 
                        value={this.state.currency}/>     
                      </Col> 

                    <Col className="text-left">
                        <Form.Label className="name-label">BL Number</Form.Label>
                        <input  className="form-control"    
                        type="text" name="bl_number"  
                        readOnly={true} 
                        value={this.state.bl_number}/>    
                      </Col> 
                      <Col className="text-left">
                        <Form.Label className="name-label">BL Date</Form.Label>
                        <input  className="form-control"    
                        type="text" name="bl_date"  
                        readOnly={true} 
                        value={moment(this.state.bl_doc_date).format('DD-MM-YYYY')} />    
                      </Col>  
                      <Col className="text-left">
                        <Form.Label className="name-label">Container Name</Form.Label>
                        <input  className="form-control"    
                        type="text" name="container_name"  
                        readOnly={true} 
                        value={this.state.container_name}/>    
                      </Col>
                      {this.state.ship_start_date !=null?(<>
                        <Col className="text-left">
                        <Form.Label className="name-label">Ship Start Date</Form.Label>
                        <input  className="form-control"    
                        type="text" name="container_name"  
                        readOnly={true} 
                        value={moment(this.state. ship_start_date).format('DD-MM-YYYY')}/>    
                      </Col>
                      </>):(<>
                        <Col className="text-left">
                        <Form.Label className="name-label">Ship Start Date</Form.Label>
                        <input  className="form-control"    
                        type="text" name="container_name"  
                        readOnly={true} 
                        value=""/>    
                      </Col>
                      </>)}
                      {this.state.expected_arrival != null?(<>
                        <Col className="text-left">
                        <Form.Label className="name-label">Expected Arrival</Form.Label>
                        <input  className="form-control"    
                        type="text" name="container_name"
                        readOnly={true}   
                        value={this.state.expected_arrival}/>    
                      </Col>
                      </>):(<>
                       <Col className="text-left">
                        <Form.Label className="name-label">Expected Arrival</Form.Label>
                        <input  className="form-control"    
                        type="text" name="container_name"
                        readOnly={true}   
                        value=""/>    
                      </Col>
                      </>)}
                     
                      

                    <Col className="text-left">
                    </Col> 

                  </Row> 

                  <Row style={{marginTop:"2px", marginBottom: "7px"}}>

          
                      <Col className="text-left" >
                      <input  className="form-control" required="required"  style={{width: "1180px",marginleft:100}}
                      placeholder='Enter Feedback'
                      type="text" name="setup_name" onChange={this.handle_cust_feedback} value={this.state.cust_feedback}/>    
                    </Col>          
                    <Col className="text-left" >        
                          <Button style={{width: "100px"}} variant = "success"   onClick={() => {window.location.href="/home"}}>Send Mail</Button> 

                    </Col>
                    
                 
                    <Col>
                    </Col>
     
                   
                   
                    
                   

    
                  </Row> 
                </Card.Body>
              </Card>
          </Container>



          <div className="table" style={{width:"99%",display:"inline-table", margin: "5px", backgroundColor: "#fff"}}>
                    <Table striped bordered  responsive="sm">
                      <thead style={{backgroundColor:"#413c69",color:"#ffffff"}}>
                        <tr>
                          <th style={{width: "20px"}}>#</th>
                          <th style={{width: "120px"}}>Partno</th>
                          <th style={{width: "180px"}}>Description</th>
                          <th style={{width: "30px"}}>Invoice Qty</th>                         
                          <th style={{width: "10px"}}>Received Qty
                          <Button style={{width: "150px",backgroundColor:"#413c69",color:"#ffffff"}} variant = "success"   onClick={this.handle_updatereceipt.bind(this)}>(Received_All_Qty)</Button> 

                           </th>
                          <th style={{width: "20px"}}>Excess/Shortage</th>           
                        </tr>
                      </thead>
                      <tbody>
                      {get_cu_details.length ? 
                        get_cu_details.map((todo , idx)=> (
                        <tr key ={idx} >
                            <td>{idx + 1}</td>
                            <td style={{width: 120}}><input
                                             className="form-control"
                                             id={"exampleSelect" + idx}
                                             value ={todo.product_code.trim()}
                                             placeholder=""
                                             readOnly={true}
                                             type="text">
                                             </input>
                                             </td>
                            <td style={{width: 180}}><input
                                             className="form-control"
                                             id={"exampleSelect" + idx}
                                             value ={todo.product_desc.trim()}
                                             placeholder=""
                                             readOnly={true}
                                             type="text">
                                             </input>
                                             </td>
                            <td style={{width: 150}}><input
                                             className="form-control"
                                             id={"exampleSelect" + idx}
                                             value ={todo.invoice_qty}
                                             placeholder=""
                                             readOnly={true}
                                             type="text">
                                             </input>
                                             </td>                                          
                                             {this.state.get_cu_details[idx].invoice_ack_status == 'Completed'?(
                                                <td style={{width: 70}}>
                                                <input
                                                className="form-control"
                                                id={"exampleSelect" }
                                                placeholder=""
                                                readOnly={true}
                                                onChange={this.handle_document.bind(this, idx)}
                                                value={this.state.get_cu_details[idx].invoice_received_qty}
                                                type="text">
                                                </input>  
                                                </td>
                                             ):(
                                              <td style={{width: 70}}>
                                              <input
                                              className="form-control"
                                              id={"exampleSelect" }
                                              placeholder=""
                                              readOnly={false}
                                              onChange={this.handle_document.bind(this, idx)}
                                              value={this.state.get_cu_details[idx].invoice_received_qty}
                                              type="number">
                                              </input>  
                                              </td>
                                             )}  
                             <td style={{width: 50}}><input
                                             className="form-control"
                                             id={"exampleSelect" + idx}
                                             value ={todo.excess_shortage_qty}
                                             placeholder=""
                                             readOnly={true}
                                             type="text">
                                             </input>
                                             </td>                                                                                     
   
                      
                        </tr>
                        ))
                        : 
                        (<tr>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                        </tr>)
                      } 
                       
                      </tbody>
                    </Table>
                  </div>

                
                  <div className="text-center" style={{marginBottom: "50px"}}>

                  {this.state.invoiceackstatus != 'Completed' && this.state.invoice_doc_no != ''?  (<>
                        <Button variant = "success" 
                        style={{marginRight:"2%"}} 
                        onClick={(e) => { if (window.confirm("Are you sure save?")) this.submitsave(e) }}>Save</Button>

                <Button variant = "success" 
                        style={{marginRight:"2%"}} 
                        onClick={(e) => { if (window.confirm("Are you sure Submit?")) this.submitconfirm(e) }}>Submit</Button>
                      
                    <Button variant = "success" onClick={() => {window.location.href="/home"}}>Exit</Button>      
                    </>
                  ):(
                    <>
                <Button variant = "success" onClick={() => {window.location.href="/home"}}>Exit</Button> 
                </> )}
                  </div>
                 
                    
                  <div><ToastContainer/></div>

          </div>
      )        
    }
  
  }
