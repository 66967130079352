import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Switch,Redirect,Link as Link1 } from 'react-router-dom';
import {NavMenu} from './NavMenu'
import {DashboardComponent} from './Components/DashboardComponent';
import MonthlyOrderComponent from './Components/MonthlyOrderComponent';
import PrePIOrderComponent from './Components/PrePIOrderComponent';
import LcadvPaymentComponent  from './Components/LcadvPaymentCompound';
import SalesMangerComponent from'./Components/SalesManagerComponent';
import FinanceMangerComponent from'./Components/FinanceManagerComponent';
import TwExportGrnAckComponent from'./Components/TwExportGrnAckComponent';
import TwExportpostinvoiceDmsCompound from'./Components/TwExportpostinvoiceDmsCompound';
import TwExportReport1Component from './Components/TwExportReport1Component';
import TwExportReport2Component from './Components/TwExportReport2Component';
import ContainertrackingComponent from './Components/ContainertrackingComponent';
import HomeComponent from './Components/HomeComponent';
import Login from './Components/Login';
import ChangepasswordComponent from './Components/ChangepasswordComponent';
import OrderStatusComponent from './Components/OrderStatusComponent';


function App() {
  return (
    <div>
      {/* <div > */}
        <BrowserRouter >
          <div >
            <NavMenu />
            
            <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>

              <Route exact path={["/home", "/"]} component={HomeComponent} />
              <Route path="/dashboard" render={(routeProps) => <DashboardComponent {...routeProps}/>} />
              <Route path="/monthly_order" component ={MonthlyOrderComponent}/>
              <Route path="/prepiorder_master" component ={PrePIOrderComponent}/>
              <Route path="/lcadvpayment" component ={LcadvPaymentComponent}/>
              <Route path="/saleshmanager" component ={SalesMangerComponent}/>
              <Route path="/financemanager" component ={FinanceMangerComponent}/>
              <Route path="/twexportgrnack" component ={TwExportGrnAckComponent}/>
              <Route path="/twexportpostinvoicedms" component ={TwExportpostinvoiceDmsCompound}/>
              <Route path="/TwExportReport1" component={TwExportReport1Component}/>
              <Route path="/TwExportReport2" component={TwExportReport2Component}/>
              <Route path="/containertracking" component={ContainertrackingComponent}/>
              <Route path = "/login" component = {Login}/>
              <Route path = "/password" component = {ChangepasswordComponent}/>
              <Route path = "/orderstatus" component = {OrderStatusComponent}/>
              <Route />
            </Switch>
          </div> 
        </BrowserRouter>
      {/* </div> */}
    </div>
  );
}

export default App;
