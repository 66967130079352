import React, { Component } from 'react'
import { Card, Row, Col, Container, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loader from 'react-loader-spinner'
import 'react-toastify/dist/ReactToastify.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import axios from 'axios';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import JWT from 'expo-jwt';

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user_name: "",
      password: "",
      loading: false,
      spec_location: 'MDU',
      segment_type: '',
      resetpwd: false,
      selectsegment: false,
      segmentlist: [],
      getpassword: '',
      currnet_pwd: '',
      newpwd: '',
      confirmpwd: '',
      getsegmentcount: '',
      getsegment: '',
      enteredUserCaptcha: ''
    };

    this.togglepwd = this.togglepwd.bind(this);
    this.toggleepwd = this.toggleepwd.bind(this);
    this.togglesegment = this.togglesegment.bind(this);
    this.toggleesegment = this.toggleesegment.bind(this);
    this.toggleecsegment = this.toggleecsegment.bind(this);
  }

  componentDidMount() {
    localStorage.setItem("screen_zoom", "80%")
    document.body.style.zoom = localStorage.getItem("screen_zoom");
    this.redirectLogin()
    loadCaptchaEnginge(6);
    localStorage.setItem('usertypeid', '')
  }

  redirectLogin = () => {
    if (localStorage.getItem("token") !== "" && localStorage.getItem("token") !== undefined && localStorage.getItem("token") !== null) {
      localStorage.setItem('token', '')
      localStorage.setItem('usertypeid', '')
      window.location.href = "/login"
    }

  }


  handleUserName = (event) => {
    this.setState({
      user_name: event.target.value
    })
  }

  handlePassword = (event) => {
    this.setState({
      password: event.target.value
    })
  }

  handleLocationChanged = (event) => {
    this.setState({
      spec_location: event.target.value
    })
  }

  handlecurrnetpwd = (e) => {
    this.setState({
      currnet_pwd: e.target.value
    })
  }
  handlenewpwd = (e) => {
    this.setState({
      new_pwd: e.target.value
    })
  }
  handleconfirmpwd = (e) => {
    this.setState({
      confirm_pwd: e.target.value
    })
  }

  handleCapchaChanged = (event) => {
    this.setState({ enteredUserCaptcha: event.target.value })
  }


  handleSubmit = () => {

    let username = this.state.user_name;
    let password = this.state.password;
    // let segment = this.state.segment_type;

    if (validateCaptcha(this.state.enteredUserCaptcha) == true) {

      if (username != "" && password != "") {
        this.setState({
          loading: true
        })
  
        let userData = { "username": username, "password": password }
        let encodedToken = JWT.encode(userData, process.env.REACT_APP_KEY)
  
        axios({
          method: "POST",
          url: process.env.REACT_APP_API_URL_COMMON + 'user_login',//"http://localhost:3300/user_login",
          data: { data: encodedToken }
        }).then((response) => {
          this.setState({
            user: response.data.data, // you have it as this.data not response
          });
  
          if (response.data.status == "Success") {
            console.log("Manitoken", response.data.token)
            this.setState({
              loading: false,
              getsegmentcount: response.data.segement,
              segmentlist: response.data.segementlist
            })
  
            console.log("segment", response.data.segementlist)
  
  
            let items = this.state.user;
            console.log("code", items[0].user_type_code)
            localStorage.setItem('user_id', items[0].id)
            localStorage.setItem('usertype', items[0].user_type)
            localStorage.setItem('usertypecode', items[0].user_type_code)
            localStorage.setItem('empcode', items[0].user_name)
            localStorage.setItem('usertypeid', items[0].user_type_id)
            localStorage.setItem('username', items[0].user_name)
            localStorage.setItem('pwdrest', items[0].pwd_reset)
            localStorage.setItem('token', response.data.token)
            this.setState({
              getpassword: items[0].password
            })
  
  
  
            if (items[0].user_type_code == 'CUST') {
              if (items[0].pwd_reset != 0) {
                if (response.data.segement != 1) {
                  this.setState(prevState => ({
                    selectsegment: !prevState.selectsegment
  
                  }));
  
                } else {
                  localStorage.setItem('segment', items[0].segment)
                  window.location.href = "/home"
                }
  
              } else {
                this.setState(prevState => ({
                  resetpwd: !prevState.resetpwd
                }));
              }
            } else {
  
              if (response.data.segement != 1) {
                this.setState(prevState => ({
                  selectsegment: !prevState.selectsegment
                }));
  
              } else {
                localStorage.setItem('segment', items[0].segment)
                window.location.href = "/home"
              }
  
  
            }
  
  
          } else {
            this.errorNotify(response.data.message)
            this.setState({
              loading: false
            })
          }
        });
      } else {
  
        if (username == "") {
          alert("Please Enter emp_code")
        } else if (password == "") {
          alert("Please Enter password")
        }
        else {
          alert("Please Fill all data")
        }
  
      }
  
    }else{
      this.setState({
        enteredUserCaptcha :''
      })
        alert("Invalid Cpatcha!")
    }


   


  }

  errorNotify = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER
    });
  }

  onChangeValue = (e) => {
    console.log("demo", e.target.value)
    this.setState({
      segment_type: e.target.value
    })
  }

  togglepwd = () => {
    this.setState(prevState => ({
      resetpwd: !prevState.resetpwd
    }));
  }


  togglesegment = () => {
    this.setState(prevState => ({
      selectsegment: !prevState.selectsegment
    }));

  }
  toggleesegment = () => {

    console.log("mani", this.state.getsegment)

    if (this.state.getsegment == '') {
      alert("select segment")
    } else {
      this.setState(prevState => ({
        selectsegment: !prevState.selectsegment,
      }));
      localStorage.setItem("segment", this.state.getsegment)
      window.location.href = "/home"
    }
  }

  toggleecsegment = () => {
    this.setState(prevState => ({
      selectsegment: !prevState.selectsegment
    }));
  }

  handle_segment = (e) => {

    this.setState({
      getsegment: e.target.value
    })
  }

  toggleepwd = () => {


    //if(this.state.getpassword == this.state.currnet_pwd){

    if (this.state.new_pwd == this.state.confirm_pwd) {
      axios({
        method: "POST",
        url: process.env.REACT_APP_API_URL_COMMON + 'changepassword',//"http://localhost:3300/user_login",
        data: {
          id: localStorage.getItem('user_id'),
          user_name: this.state.user_name,
          password: this.state.confirm_pwd,
          pwd_reset: 1
        }
      }).then((response) => {
        this.setState({
          user: response.data.data, // you have it as this.data not response
        });

        if (response.data.status == "Success") {
          this.setState(prevState => ({
            resetpwd: !prevState.resetpwd,
          }));

          localStorage.setItem('user_id', '')
          localStorage.setItem('usertype', '')
          localStorage.setItem('usertypecode', '')
          localStorage.setItem('empcode', '')
          localStorage.setItem('usertypeid', '')
          localStorage.setItem('username', '')
          localStorage.setItem('segment', '')
          localStorage.setItem('pwdrest', '')
          window.location.reload(true);
        } else {
          this.errorNotify(response.data.message)
          this.setState({
            loading: false
          })
        }
      });
    } else {
      alert(" new password and confirm password not  maching")
    }
    //  }

  }


  render() {
    return (
      <div>

        <Modal style={{ maxWidth: '450px', width: '100%', }} isOpen={this.state.selectsegment} toggle={this.togglesegment}>
          <ModalHeader toggle={this.togglesegment}></ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <select className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`}
                  value={this.state.getsegment} onChange={this.handle_segment}>
                  <option>select segment</option>
                  {this.state.segmentlist.length ?
                    this.state.segmentlist.map((todo, id) => (
                      <option value={todo.segment}>{todo.segment}</option>
                    ))
                    :
                    (<option></option>)}
                </select>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleesegment} color="primary"  >GO</Button>{' '}
            <Button onClick={this.toggleecsegment} color="primary"  >Back</Button>{' '}
          </ModalFooter>
        </Modal>


        <Modal style={{ maxWidth: '450px', width: '100%', }} isOpen={this.state.resetpwd} toggle={this.togglepwd}>
          <ModalHeader toggle={this.togglepwd}>Password Reset  </ModalHeader>
          <ModalBody>
            {/* <Row>
                    <Col className="text-left">
                    <label htmlFor="emp_code" style={{fontWeight: "600"}}>Current Password</label>
                      <input type="password" 
                            id="emp_code" 
                      className="form-control emp_code" onChange={this.handlecurrnetpwd} placeholder="Current password" />
                    </Col>
                   
                  </Row> */}
            <Row>
              <Col className="text-left">
                <label htmlFor="emp_code" style={{ fontWeight: "600" }}>New Password</label>
                <input type="password"
                  id="emp_code"
                  className="form-control emp_code" onChange={this.handlenewpwd} placeholder="New password" />
              </Col>
            </Row>
            <Row>
              <Col className="text-left">
                <label htmlFor="emp_code" style={{ fontWeight: "600" }}>Confirm Password</label>
                <input type="password"
                  id="emp_code"
                  className="form-control emp_code" onChange={this.handleconfirmpwd} placeholder="Confirm password" />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleepwd} color="primary"  >Reset</Button>{' '}
            <Button onClick={this.toggleepwd} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>

        <Container>
          <Row>
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <Card className="card my-5" style={{ marginTop: "10%", border: "0", borderRadius: "1rem", boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1)" }}>
                <Card.Body style={{ padding: "2rem" }}>
                  <Card.Title><h5 className="text-center">Sign In</h5></Card.Title>
                  {/* <Form> */}
                  <div className="form-label-group">
                    <label htmlFor="emp_code" style={{ fontWeight: "600" }}>User Name</label>
                    <input type="emp_code" id="emp_code" className="form-control emp_code" onChange={this.handleUserName}
                      placeholder="Emp Code / Customer ID" />
                  </div>

                  <div className="form-label-group">
                    <label htmlFor="inputPassword" style={{ fontWeight: "600" }}>Password</label>
                    <input type="password" id="inputPassword"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          this.handleSubmit()
                        }
                      }}
                      className="form-control password"
                      placeholder="Password" onChange={this.handlePassword} />
                  </div>
                  <div className="form-label-group">
                    <LoadCanvasTemplate />
                  </div>
                  <div className="form-label-group">
                    <input type="text" id="inputcaptcha"
                      className="form-control password"
                      value={this.state.enteredUserCaptcha}
                      placeholder="Enter Captcha here"
                      onChange={this.handleCapchaChanged} />
                  </div>



                  {/* <div className="form-label-group" onChange={this.onChangeValue}>
                           <Row>
                           <Col className="text-left">
                           <input type="radio" value="2WEXPORT" name="gender" checked={this.state.segment_type === '2WEXPORT'} /> 2WEXPORT
                           </Col>
                           <Col className="text-left">
                           <input type="radio" value="OHT" name="gender" checked={this.state.segment_type === 'OHT'}  /> OHT
                           </Col>

                           <Col className="text-left">
                           <input type="radio" value="EURO" name="gender" checked={this.state.segment_type === 'EURO'} /> EURO
                           </Col>
                           </Row>
                          </div> */}

                  <div className="text-center">
                    <Button variant="primary" className="text-center" onClick={this.handleSubmit}>Sign In</Button>
                  </div>

                  {this.state.loading &&
                    <Loader
                      type="Circles"
                      color="#00BFFF"
                      height={100}
                      width={100}
                      style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    />
                  }
                </Card.Body>
                <p className="text-center" style={{ fontFamily: "fantasy", fontSize: "12px" }}>Version 1.12</p>
              </Card>
            </div>
          </Row>
          <ToastContainer />
        </Container>
        {/* </div> */}
      </div>
    )
  }
}