import React from 'react';
import { Component } from 'react'
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { Table,Card,Button, Form, Modal } from 'react-bootstrap';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner'
// import Select2 from 'react-select2-wrapper';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select'
import {show_mixing_general, show_mixing_setup, show_mixing_process, show_mixing_bom, show_mixing_report, show_mixing_output, show_activate_sap} from './role'

var pageNumbers = []
export class DashboardComponent extends Component{
 
    constructor(props){
	    super(props)
		  this.state = {
        dashboardMaster: [],
        filter: "",
        checked: false,
        loading: false,
        plant_master: [],
        plant: "",
        machine_filter: "",
        project: "",
        status: "Select Status",
        spec_status: ["Select Status", "All", "Inprogress", "Upcoming", "Completed", "SAP"],
        total: null,
        per_page: null,
        current_page: 1,
        searching: false,
        modal_show: false,
        available_specs: [],
        copy_sfg_code: "",
        copy_sfg_desc: "",
        copy_spec_stage: "",
        copy_material_version: "",
        copy_bom_no: "",
        selected_sfg_code: {}
      }   
    }

    componentDidMount(){
      if(localStorage.getItem("selectedId") === undefined || localStorage.getItem("selectedId") == "" || localStorage.getItem("selectedId") == null){
        this.fetchMasterData(1);
      } else {
        this.getSelectedSpec()
      }
      
      this.fetchPlantNames();
    }

  
    fetchPlantNames = () =>{
      fetch(process.env.REACT_APP_API_URL + 'mst_vendor_temps/fetch_vendor_details?process_type=' + localStorage.getItem('selected_home_page'), {
        headers: {
          'content-type': 'application/json',
          'token': localStorage.getItem('token')
        }
      })
      .then(res => res.json())
      .then(data =>{
        if(data.status == 401){
          window.location.href = "/login"
        } else {
          this.setState({
            plant_master: data.plant_master
          })
        }
      }).catch(console.log)
    }

    fetchMasterData = (page_number) =>{
      // var plant = (this.state.plant == "" || this.state.plant == undefined) ?  localStorage.getItem('plant') : this.state.plant
      this.setState({
        loading: true,
        // plant: plant
      })
      
      var requestOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('token') },
        body: JSON.stringify({ page: page_number, plant: this.state.plant, process_type: localStorage.getItem('selected_home_page') })
      }

			fetch(process.env.REACT_APP_API_URL + 'spec_headers/fetch_details', requestOption)
			.then(res => res.json())
			.then((data) => {
        if(data.status == 401){
          window.location.href = "/login"
        } else {
          this.updateSearch(data)
        }
        
			})
			.catch(console.log)
    }

    handleSearch = (event) => {
      var value = event.target.value;
      this.setState({
        filter: value,
        searching: true
      }, function(){
        this.fetchSearchResult(1)
      });
    }

    handleMachineSearch = (event) => {
      var value = event.target.value;
      this.setState({
        machine_filter: value,
        searching: true
      }, function(){
        this.fetchSearchResult(1)
      });
    }

    fetchSearchResult = (page) => {
      var requestOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('token') },
        body: JSON.stringify({ sfg_code: this.state.filter, machine_line: this.state.machine_filter, all: this.state.checked, plant: this.state.plant, project: this.state.project, status: this.state.status, process_type: localStorage.getItem('selected_home_page') })
      }

      fetch(process.env.REACT_APP_API_URL + 'spec_headers/search?page=' + page, requestOption)
      .then(res => res.json())
			.then((data) => {
        if(data.status == 401){
          window.location.href = "/login"
        } else {
          this.updateSearch(data)
        }
			})
			.catch(console.log)
    }

    updateSearch = (data) => {
      this.setState({
        dashboardMaster: data.data,
        total: data.total_page,
        current_page: data.current_page,
        per_page: data.per_page,
        loading: false,
        show_modal: false
      });
    }

    fetchMachineSearchResult = (search) => {
      var requestOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('token') },
        body: JSON.stringify({ machine: search, all: this.state.checked, plant: this.state.plant, sfg_code: this.state.filter })
      }

      fetch(process.env.REACT_APP_API_URL + 'spec_headers/search', requestOption)
      .then(res => res.json())
			.then((data) => {
        if(data.status == 401){
          window.location.href = "/login"
        } else {
          this.updateSearch(data)
        }
			})
			.catch(console.log)
    }

    handleAll = (event) =>{
      this.setState({checked: !this.state.checked, searching: true}, function(){
        this.fetchSearchResult(1);
      });
    }

    handleSelectedSpec = (id, event) => {
      if(event.target.checked){
        localStorage.setItem('selectedId', id)
        this.getSelectedSpec()
      } else {
        localStorage.setItem('selectedId', "")
        this.fetchMasterData(1)
      }
      
    }

    getSelectedSpec = () => {
      var requestOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('token') },
        body: JSON.stringify({ id: localStorage.getItem('selectedId') })
      }

      fetch(process.env.REACT_APP_API_URL + 'spec_headers/get_spec', requestOption)
      .then(res => res.json())
			.then((data) => {
        if(data.status == 401){
          window.location.href = "/login"
        } else {
          this.updateSearch(data)
        }
			})
			.catch(console.log)
    }

    fetchAll = () => {
      this.setState({
        loading: true
      })

      var requestOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('token') },
        body: JSON.stringify({ all: this.state.checked, plant: this.state.plant, process_type: localStorage.getItem('selected_home_page') })
      }

      // var url = process.env.REACT_APP_API_URL + 'spec_headers/fetch_details' + "?" + "all=" + this.state.checked + "&plant=" + this.state.plant
      fetch(process.env.REACT_APP_API_URL + 'spec_headers/fetch_details', requestOption)
      .then(res => res.json())
			.then((data) => {
        if(data.status == 401){
          window.location.href = "/login"
        } else {
          this.setState({
            dashboardMaster: data,
            loading: false
          });
  
        }
      })
			.catch(console.log)
    }

    handlePlantChanged = (event) =>{
      var value = event.target.value
      
      if(event.target.value == "Select Plant"){
        value = ""
      }

      this.setState({plant: value, searching: true}, function(){
        this.fetchSearchResult(1);
      }); 
    }

    handleProjectChanged = (event) =>{
      this.setState({project: event.target.value, searching: true}, function(){
        this.fetchSearchResult(1);
      }); 
    }

    handleStatusChanges = (event) => {
      if(event.target.value != "Select Status"){
        this.setState({status: event.target.value, searching: true}, function(){
          this.fetchSearchResult(1);
        });
      }
    }

    handleNextPage = (current_page) => {
      var last_page = pageNumbers[pageNumbers.length - 1]
      if(last_page == current_page){
        if(this.state.searching){
          this.fetchSearchResult(1);
        } else {
          this.fetchMasterData(1)
        }
        
      } else {
        if(this.state.searching){
          this.fetchSearchResult(current_page + 1);
        } else {
          this.fetchMasterData(current_page + 1)
        }
        
      }
    }

    handleClose = () => {
      this.setState({
        show_modal: false
      })
    }

    fetchMaterialMaster = () => {
      var url = process.env.REACT_APP_API_URL + "material_masters/get";
      var material_group = localStorage.getItem('material_group')
      
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "token": localStorage.getItem("token") },
        body: JSON.stringify({ material_group: material_group})
      };
      
      fetch(url, requestOptions)
      .then(res => res.json())
      .then(data => {
        if(data.status == 401){
          window.location.href = "/login"
        }
        if(data.status == 'error'){
          this.error_notify(data.message);
        }else{
          this.setState({
            sfg_codes: data.results
          })
        }
      })
    }

    fetchProcessSpecification = () => {
      var url = process.env.REACT_APP_API_URL + "spec_headers/process_specifications";
      
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "token": localStorage.getItem("token") },
        body: JSON.stringify({ process_type: localStorage.getItem('selected_home_page')})
      };
      
      fetch(url, requestOptions)
      .then(res => res.json())
      .then(data => {
        if(data.status == 401){
          window.location.href = "/login"
        }
        if(data.status == 'success'){
          this.setState({
            available_specs: data.results
          })
        }
      })
    }

    handleSubmitCopySpecification = () => {
      var url = process.env.REACT_APP_API_URL + "copy_spec_headers";

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "token": localStorage.getItem("token") },
        body: JSON.stringify({ 
          process_type: localStorage.getItem('selected_home_page'),
          copy_bom_no: this.state.copy_bom_no,
          copy_plant: this.state.copy_plant,
          copy_sfg_code: this.state.copy_sfg_code,
          copy_from_sfg_code: this.state.copy_from_sfg_code,
          copy_from_spec_type: this.state.copy_from_spec_type,
          copy_material_version: this.state.copy_material_version,
          copy_sfg_desc: this.state.copy_sfg_desc,
          copy_spec_stage: this.state.copy_spec_stage
        })
      };
      
      fetch(url, requestOptions)
      .then(res => res.json())
      .then(data => {
        if(data.status == 401){
          window.location.href = "/login"
        }
        if(data.status == 'error'){
          this.error_notify(data.message);
        } else {
          this.fetchSearchResult(1)
        }
      })
    }

    error_notify = (message) => {
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER
      });
    }

    handleOpen = async () => {
      await this.fetchProcessSpecification()
      await this.fetchMaterialMaster()

      await this.setState({
        show_modal: true
      })
    }

    handleSfgCodes = (event) => {
      this.setState({
        copy_sfg_code: event.value,
        copy_sfg_desc: event.desc
      })
    }

    handleSelectedSfgCodes = (event) => {
      this.setState({
        copy_from_sfg_code: event.sfg_code,
        copy_from_spec_type: event.spec_type,
        selected_sfg_code: event
      })
    }

    handleCopyPlantChanged = (event) => {
      this.setState({
        copy_plant: event.target.value
      })
    }

    handle_copy_material_version = (event) => {
      this.setState({
        copy_material_version: event.target.value
      })
    }

    
    handle_copy_bom_no = (event) => {
      this.setState({
        copy_bom_no: event.target.value
      })
    }

    handle_copy_spec_stage = (event) => {
      this.setState({
        copy_spec_stage: event.target.value
      })
    }

    renderTableBody = () =>{

    console.log(localStorage.getItem('emp_code'))  
        return this.state.dashboardMaster.map((master,idx) =>{
          return (
                  <tr key={idx} data-item={master.id}  >
                    <td><Form.Check type="checkbox" onChange={(event) => this.handleSelectedSpec(master.id, event)} checked={localStorage.getItem('selectedId') == master.id.toString()}/></td>
                    <td>{master.plant}</td>
                    <td style={{whiteSpace: "nowrap"}}>{master.project_name}</td>
                    <td style={{whiteSpace: "nowrap"}}>{master.machine_line}</td>
                    <td style={{whiteSpace: "nowrap"}}>{master.fg_code}</td>
                    <td style={{whiteSpace: "nowrap"}}>{master.sfg_code}</td>
                    <td style={{whiteSpace: "nowrap"}}>{master.sfg_desc}</td>
                    <td>{master.bom_no}</td>
                    <td>{master.material_version}</td>

                    
                       
                    { show_mixing_general() ? (
                      <td>
                        {localStorage.getItem('emp_code')=='R04112'?(""):( 
                        <Link to={"/general/" + master.id} className="badge badge-warning" type="button" >Edit</Link>)}   
                      </td>) : (<td></td>)
                    }

                    { show_mixing_setup() ? (
                      <td onClick={() => this.handleSetupSelected(master)}>
                        {localStorage.getItem('emp_code')=='R04112'?(""):
                        (<Button size="sm" style={{width:"100%"}} className="badge badge-warning">Edit </Button>)}
                        
                      </td>) : (<td></td>)
                    }

                    { ['Mixing', "Calendar"].includes(localStorage.getItem('selected_home_page')) ?
                      (
                        <td>
                          
                          { show_mixing_bom() &&
                          <>
                           {localStorage.getItem('emp_code')=='R04112'?(""):( <Button size="sm" style={{width:"100%"}} className="badge badge-warning" onClick={() => this.handleInputSelected(master)}>Edit </Button>)}
                           </>
                          }
                        </td>
                      ) : (
                        <td>
                          { show_mixing_bom() &&
                          <>  {localStorage.getItem('emp_code')=='R04112'?(""):( <Button size="sm" style={{width:"100%"}} className="badge badge-warning" onClick={() => this.handleILBomSelected(master)}>Edit </Button>)}</>
                           
                          }
                        </td>
                      ) 
                    }
                    
                    <td>
                      { show_mixing_process() &&
                        <>{localStorage.getItem('emp_code')=='R04112'?(""):( <Button size="sm" style={{width:"100%"}} className="badge badge-warning" onClick={() => this.handleProcessSelected(master)}>Edit </Button>)}</>
                       
                      }
                    </td>
                    
                    <td>
                      { show_mixing_output() &&
                      <>{localStorage.getItem('emp_code')=='R04112'?(""):( <Link to={`/output/${master.doc_no}/${master.material_version}/${master.id}`} className="badge badge-warning" type="button">Edit</Link>)}</>
                       
                      }
                    </td>

                    <td>
                      { show_mixing_report() &&
                        <Button size="sm" style={{width:"100%"}} className="badge badge-warning" onClick={() => this.handleReportSelected(master)}>Show</Button>
                      }
                    </td>

                    <td>{master.doc_type}</td>
                    <td >{master.doc_no}</td>
                    <td style={{whiteSpace: "nowrap"}}>{master.machine_code}</td>
                    <td style={{whiteSpace: "nowrap"}}>{master.extend_plant}</td>
                    <td>{master.status}</td>
                    
                    <td style={{whiteSpace: "nowrap"}}>{master.preparead_by}</td>
                    { master.status == "Completed" ?
                      (<><td style={{whiteSpace: "nowrap"}}>{master.reviewed_by}</td>
                      <td style={{whiteSpace: "nowrap"}}>{master.approved_by}</td></>) : (
                        <>
                          <td></td>
                          <td></td>
                        </>
                      )
                    }
                    
                    { master.effective_date == null &&
                      <td style={{whiteSpace: "nowrap"}}>{""}</td>
                    }
                    { master.effective_date != null &&
                      <td style={{whiteSpace: "nowrap"}}>{<Moment format="DD-MM-YYYY">{master.effective_date}</Moment>}</td>
                    }
                    
                    <td>
                      { show_activate_sap() &&
                        <>{localStorage.getItem('emp_code')=='R04112'?(""):(<Button size="sm" style={{width:"100%"}} className="badge badge-warning" onClick={() => this.submitSap(master.material_version, master.doc_no)}>SAP</Button>)}</>
                        
                      }
                    </td>

                    <td style={{whiteSpace: "nowrap"}}>{master.sap_status == 'Y' ? "Done" : "No"}</td>
                    <td style={{whiteSpace: "nowrap"}}>{master.activate_bom ? "Yes" : "No"}</td>
                  </tr>      
                )
        });

   }

    render(){
      let optionsPlantMaster = this.state.plant_master.map((data) =>
        <option 
        key={data.plant}
        value={data.plant}>
        {data.plant}
      </option>);

      let optionsStatus = this.state.spec_status.map((data) => 
        <option 
        key={data}
        value={data}>
        {data}
      </option>);
      pageNumbers = []
      if (this.state.total !== null) {
        for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
          pageNumbers.push(i);
        }
      }

      const renderPageNumbers = pageNumbers.map(number => {
        let classes = this.state.current_page === number ? 'pagination-active' : '';
      
        if (number == 1 || number == this.state.total || (number >= this.state.current_page - 2 && number <= this.state.current_page + 5)) {
          if(this.state.searching){
            return (
              <span key={number} className={classes} onClick={() => this.fetchSearchResult(number)}>{number}</span>
            );
          } else {
            return (
              <span key={number} className={classes} onClick={() => this.fetchMasterData(number)}>{number}</span>
            );
          }
          
        }
      });

        return(
          <div className="App" style={{backgroundColor: "white", margin:'0%'}}>
            <div><ToastContainer/></div>

            <Form style={{margin: "10px"}}>
              <Form.Row>
                {localStorage.getItem('emp_code')=='R04112'?(""):(<Button variant="success" style={{marginRight:"2%", marginLeft: "10px", marginTop: "5px", padding: "inherit", width: "100px"}} onClick={() => window.location.href="/general"}>Add New </Button>)}
                
                <div style={{marginLeft: "0px", marginTop: "5px"}}>
                  <select className="form-control" value={this.state.plant} onChange={this.handlePlantChanged}>
                    <option>Select Plant</option>
                    {optionsPlantMaster}
                  </select>
                </div>
                
                <div style={{marginLeft: "20px", marginTop: "5px"}}>
                  <input className="form-control" value={this.state.filter} placeholder="Type SFG code or Doc No" onChange={(event) => this.handleSearch(event)}/>
                </div>

                <div style={{marginLeft: "20px", marginTop: "5px"}}>
                  <input className="form-control" value={this.state.machine_filter} placeholder="Type Machine Line" onChange={(event) => this.handleMachineSearch(event)}/>
                </div>

                <div style={{marginLeft: "20px", marginTop: "5px"}}>
                  <input className="form-control" value={this.state.project} placeholder="Type Project Name" onChange={(event) => this.handleProjectChanged(event)}/>
                </div>

                <div style={{marginLeft: "20px", marginTop: "5px"}}>
                  <select className="form-control" value={this.state.status} onChange={this.handleStatusChanges}>
                    {optionsStatus}
                  </select>
                </div>

                <div style={{marginLeft: "20px", marginTop: "10px"}}>
                  <Form.Check type="checkbox" label="Show All" onChange={(event) => this.handleAll(event)} defaultChecked={this.state.checked}/>
                </div>

                {/* <div style={{marginLeft: "20px", marginTop: "10px"}}> */}
                  <Button variant="success" style={{marginRight:"2%", marginLeft: "10px", marginTop: "5px", padding: "inherit", width: "140px"}} onClick={() => this.handleOpen() }>Copy Specification</Button>
                {/* </div> */}
                 
              </Form.Row> 
            </Form>
          <div className="root" ></div>
            <div className="body">
            { this.state.loading &&
                <Loader
                  type="Circles"
                  color="#00BFFF"
                  height={100}
                  width={100}
                  style={{
                    width: "32px",
                    height: "32px",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    zIndex: "101",
                    marginLeft: "-16px",
                    marginTop: "-16px"
                  }}
                />
              }
            <div className="table" style={{width:"100%",display:"inline-table", backgroundColor: "rgb(93, 188, 210)"}}>
              <Card style={{display:"inline-table",marginRight:"10px"}}>

              <Table striped bordered  responsive="sm">
                <thead style={{backgroundColor: "rgb(93, 188, 210)"}}>
                  <tr>
                    <th className="text-left">Select</th>
                    <th>Plant</th>
                    <th>Project </th>
                    <th style={{whiteSpace: "nowrap"}}>Machine Line</th>
                    <th style={{whiteSpace: "nowrap"}}>FG Code</th>
                    <th style={{whiteSpace: "nowrap"}}>SFG Code</th>
                    <th style={{whiteSpace: "nowrap"}}>SFG Description</th>
                    <th>Bom</th>
                    <th>Version</th>
                    <th>General</th>
                    <th>Setup</th>
                    <th>BOM</th>        
                    <th>Process</th>
                    <th>Output</th>
                    <th>Report</th>
                    <th>Type</th>
                    <th>Doc No.</th>
                    <th style={{whiteSpace: "nowrap"}}>Machine Code</th>
                    <th style={{whiteSpace: "nowrap"}}>Extend Plant</th>
                    <th>Status</th>
                    <th>Prepared</th>
                    <th>Reviewed</th>
                    <th>Approved</th>
                    <th style={{whiteSpace: "nowrap"}}>Effective Date</th>
                    <th style={{whiteSpace: "nowrap"}}>SAP</th>
                    <th style={{whiteSpace: "nowrap"}}>SAP Status</th>
                    <th style={{whiteSpace: "nowrap"}}>Activate BOM</th>
                  </tr>
                </thead>
                <tbody>
                  { this.state.dashboardMaster && this.state.dashboardMaster.length > 0 &&
                    this.renderTableBody()
                  }
                </tbody>                        
             </Table>
           </Card>

            <div className='pagination' style={{backgroundColor: "white"}}>
              <span onClick={() => this.fetchMasterData(1)}>&laquo;</span>
                {renderPageNumbers}
              <span onClick={() => this.handleNextPage(this.state.current_page)}>&raquo;</span>
            </div>  
            </div>
          </div>

          <Modal show={this.state.show_modal} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Copy SFG Specification</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row">
                  <div className="col-4">
                    <label>Copy From</label>
                  </div>

                  <div className="col-6" style={{marginLeft: "0px", marginTop: "5px"}}>
                    <Select
                      value={ this.state.selected_sfg_code }
                      options={this.state.available_specs}
                      onChange={this.handleSelectedSfgCodes}
                      placeholder="Select SFG Codes"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-4">
                      <label>To Plant</label>
                  </div>

                  <div className="col-6" style={{marginLeft: "0px", marginTop: "5px"}}>
                    <select className="form-control" value={this.state.copy_plant} onChange={this.handleCopyPlantChanged}>
                        <option>Select Plant</option>
                        {optionsPlantMaster}
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-4">
                      <label>To Issue No</label>
                  </div>

                  <div className="col-6" style={{marginLeft: "0px", marginTop: "5px"}}>
                    <input type="text" 
                      className="form-control"
                      onChange={(event) => {this.handle_copy_material_version(event)}} 
                      value={this.state.copy_material_version} 
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-4">
                      <label>To Bom No</label>
                  </div>

                  <div className="col-6" style={{marginLeft: "0px", marginTop: "5px"}}>
                    <input type="text" 
                      className="form-control"
                      onChange={(event) => {this.handle_copy_bom_no(event)}} 
                      value={this.state.copy_bom_no} 
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-4">
                      <label>To Spec Stage</label>
                  </div>

                  <div className="col-6" style={{marginLeft: "0px", marginTop: "5px"}}>
                    <select className="form-control" value={this.state.copy_spec_stage} onChange={(event) => {this.handle_copy_spec_stage(event)}}>
                      <option>Select Spec stage</option>
                      <option title="Green" key="Green" value="Green">Green</option>
                      <option title="Yello" key="Yellow" value="Yellow">Yellow</option>
                      <option title="White" key="White" value="White">White</option>
                      <option title="Blue" key="Blue" value="Blue">Blue</option>
                    </select>
                  </div>
                </div>

                
                <div className="row">
                  <div className="col-4">
                    <label>Copy To</label>
                  </div>

                  <div className="col-6" style={{marginLeft: "0px", marginTop: "5px"}}>
                    <Select
                      value={{label: this.state.copy_sfg_code, value: this.state.copy_sfg_code + "  /  " + this.state.copy_sfg_desc }}
                      options={this.state.sfg_codes}
                      onChange={this.handleSfgCodes}
                      placeholder="Select SFG Code"
                    />
                  </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="success"  onClick={(event) => {this.handleSubmitCopySpecification(event)}}>
                    Submit
                </Button>

                <Button variant="secondary"  onClick={this.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

           
        </div>
        )
    }

    handleSetupSelected = (data) =>{
      // var url = localStorage.getItem('selected_home_page') == 'Calendar' ? '/machine_setup' : '/setup'
      this.props.history.push({
        pathname: '/setup',
        data: {data: data} // your data array of objects
      })
    }
    handleGeneralSelected = (data) =>{
      this.props.history.push({
        pathname: '/general',
        data: {data:data} // your data array of objects
      })
    }
    handleInputSelected = (data) =>{
      this.props.history.push({
        pathname: '/input',
        data: {data: data} // your data array of objects
      })
    }

    handleILBomSelected = (data) =>{
      this.props.history.push({
        pathname: '/bom',
        data: {data: data} // your data array of objects
      })
    }

    handleTrtSelected = (data) => {
      this.props.history.push({
        pathname: '/trt_master',
        data: {data: data}
      })
    }

    handleProcessSelected = (data) =>{
      // var url = localStorage.getItem('selected_home_page') == 'Calendar' ? '/machine_process' : '/process'

      this.props.history.push({
        pathname: '/process',
        data: {data:data} // your data array of objects
      })
    }
    handleReportSelected = (data) =>{
      if(['Extruder', 'Calendar', 'Mixing', 'Curing'].includes(localStorage.getItem('selected_home_page')) ){
        console.log("Testx", data)
        this.props.history.push({
          pathname: '/report',
          data: {data:data} // your data array of objects
        })
      } else {
        this.props.history.push({
          pathname: '/other_process_report',
          data: {data:data}
        })
      }
    }

    handleAckReportSelected = (data) =>{
      this.props.history.push({
        pathname: '/ack_report',
        data: {data:data} // your data array of objects
      })
    }

    handleMasterListReportSelected = (data) =>{
      this.props.history.push({
        pathname: '/master_list',
        data: {data:data} // your data array of objects
      })
    }

    handleOutputSelected = (data) =>{
      this.props.history.push({
        pathname: '/output',
        data: {data:data} // your data array of objects
      })
    }

    handleProject = (event) => {
      // this.setState({
      //   selected_project: event.target.value
      // })
    }

    submitSap = (material_version, doc_no) => {
      var requestOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('token') },
        body: JSON.stringify({ material_version: material_version, doc_no: doc_no })
      }
      fetch(process.env.REACT_APP_API_URL + 'spec_headers/update_sap', requestOption)
      //fetch(process.env.REACT_APP_API_URL + 'spec_headers/update_sap', requestOption)
      .then(res => res.json())
			.then((data) => {
        if(data.status == 401){
          window.location.href = "/login"
        } else {
          if(data.status == 'success'){
            toast.success(data.message, {
              position: toast.POSITION.TOP_CENTER
            });
          } else {
            toast.error(data.error, {
              position: toast.POSITION.TOP_CENTER
            });
          }
        }
			})
			.catch(console.log)
    }
}

export default DashboardComponent