export const is_has_access = function(role){
    if(localStorage.getItem('user_roles') == null) return false

    return localStorage.getItem('user_roles').includes(role)
}

export const home_pages = [{home: 'Mixing', role: 'Mixing'}, {home: 'Calendar', role: 'Calendar'}, {home: 'Extruder', role: 'Extruder'}, {home: 'Tread', role: 'Tread'},
    {home: 'Bead', role: 'Bead'}, {home: 'Bias', role: 'Bias'}, {home: 'UT', role: 'UT'}, {home: 'IL', role: 'IL'},  
    // {home: 'Carcass', role: 'Green Tyre'}, 
    {home: 'Green Tyre', role: 'Green Tyre'}, {home: 'Curing', role: 'Curing'}                   
]

export const show_mixing_general = function(){
    if(localStorage.getItem('user_roles') == null){
        return false
    }

    if(localStorage.getItem('selected_home_page') == 'Mixing'){
        return localStorage.getItem('user_roles').includes('Mixing-General')
    }
    return true
}

export const show_mixing_setup = function(){
    if(localStorage.getItem('user_roles') == null){
        return false
    }

    if(localStorage.getItem('selected_home_page') == 'Mixing'){
        return localStorage.getItem('user_roles').includes('Mixing-Setup')
    }
    return true
}

export const show_mixing_process = function(){
    if(localStorage.getItem('user_roles') == null){
        return false
    }

    if(localStorage.getItem('selected_home_page') == 'Mixing'){
        return localStorage.getItem('user_roles').includes('Mixing-Process')
    }
    return true
}

export const show_mixing_bom = function(){
    if(localStorage.getItem('user_roles') == null){
        return false
    }

    if(localStorage.getItem('selected_home_page') == 'Mixing'){
        return localStorage.getItem('user_roles').includes('Mixing-Bom')
    }
    return true
}

export const show_mixing_report = function(){
    if(localStorage.getItem('user_roles') == null){
        return false
    }

    if(localStorage.getItem('selected_home_page') == 'Mixing'){
        return localStorage.getItem('user_roles').includes('Mixing-Report')
    }
    return true
}

export const show_mixing_output = function(){
    if(localStorage.getItem('user_roles') == null){
        return false
    }

    if(localStorage.getItem('selected_home_page') == 'Mixing'){
        return localStorage.getItem('user_roles').includes('Mixing-Output')
    }
    return true
}

export const show_activate_sap = function(){
    if(localStorage.getItem('user_roles') == null){
        return false
    }

    if(localStorage.getItem('selected_home_page') == 'Mixing'){
        return localStorage.getItem('user_roles').includes('Mixing-SAP')
    }
    return true
}

