import React from 'react';
import { Component} from 'react'
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import TwExportpostinvoiceDms from './Views/TwExportpostinvoiceDms';
//import TwExportpostinvoiceDms from './Views/Sampletable';
export class TwExportpostinvoiceDmsCompound extends Component{
  render(){
    return(
      <div className="Setup" style={{backgroundColor: "rgb(93, 188, 210)",margin:'0%'}}>
      
      <div className="root" ></div>
        <div>       
          <TwExportpostinvoiceDms/>
        </div>
      </div>
    )
  }
}

export default TwExportpostinvoiceDmsCompound