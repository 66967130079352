import React from 'react';
import { Component } from 'react'
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logo from './images/eurogrip.jpg'

export class NavMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
      list: '',
      master: [],
      report: [],
    };

  }



  componentDidMount() {


    if (localStorage.getItem('token') != "") {
      axios({
        method: "POST",
        url: process.env.REACT_APP_API_URL_COMMON + 'login_type',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          user_type_id: localStorage.getItem('usertypeid')
        }
      }).then((response) => {
        if (response.data.status == "Success") {
          this.setState({
            menu: {}
          });
          console.log("Manimanu", response.data.data)
          this.setState({
            menu: response.data.data, // you have it as this.data not response
          });
        }
      });
    }

  }




  render() {

    console.log("usertype", localStorage.getItem("usertype"))

    console.log("Menus", this.state.menu)

    const { menu = [], top = [] } = this.state;



    this.state.menu = {}
    this.state.list_manu = {}

    for (var i = 0; i < menu.length; i++) {
      var par_id = menu[i].parent_id

      console.log("Mani", par_id);

      if (par_id != "0") {
        if (par_id == "5") {
          this.state.master.push({
            name: menu[i].submenu_name,
            url: menu[i].url_name,
          })
        } else {

        }

        if (par_id == "18") {
          this.state.report.push({
            name: menu[i].submenu_name,
            url: menu[i].url_name,
          })
        } else {

        }

      }
    }




    return (
      <div>
        <Navbar collapseOnSelect="true" expand="lg" style={{ backgroundImage: 'linear-gradient(270deg,#1638aa,#005cc0,#007aca,#0096cb,#2bafca)', Height: '70%' }}>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" style={{ marginLeft: "0px" }}>
            <div>
              <img src={logo}></img>
            </div>

            {(localStorage.getItem("token") !== "" && localStorage.getItem("token") !== undefined && localStorage.getItem("token") !== null) &&
              <Nav className="container-fluid">
                <Nav.Link as={NavLink} to="/home">Home</Nav.Link>

                {/* {this.state.master.map((link, index) => {
                        return (
                            <Nav.Link as={NavLink} to={link.url}>
                              {link.name}
                            </Nav.Link>  
                        );
                      })} */}


                <NavDropdown title="Transaction" id="basic-nav-dropdown">
                  {this.state.master.map((link, index) => {
                    return (<>
                      <NavDropdown.Item as={NavLink} to={link.url}>
                        {link.name}
                      </NavDropdown.Item>
                      <NavDropdown.Divider /></>
                    );
                  })}
                </NavDropdown>

                {/* <Nav.Link as={NavLink} to="/orderstatus">Order Tracking Status</Nav.Link> */}

                <NavDropdown title="Reports" id="basic-nav-dropdown">
                  {this.state.report.map((link, index) => {
                    return (<>
                      <NavDropdown.Item as={NavLink} to={link.url}>
                        {link.name}
                      </NavDropdown.Item>
                      <NavDropdown.Divider /></>
                    );
                  })}
                </NavDropdown>


                <NavDropdown className="ml-auto" title={localStorage.getItem('username') + "(" + localStorage.getItem('segment') + ")"} id="basic-nav-dropdown">
                  {localStorage.getItem('usertypecode') == 'CUST' ? (<>
                    <NavDropdown.Item as={NavLink} to="/password" >
                      Change Password
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                  </>
                  ) : (<></>)}
                  <NavDropdown.Item as={NavLink} to="/logout" onClick={this.logout} >
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
                {/* <Nav.Link className="ml-auto">{localStorage.getItem('username') +      "("+ localStorage.getItem('usertypecode')+")"+   localStorage.getItem('segment')}</Nav.Link> */}

              </Nav>
            }

            {(localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined || localStorage.getItem("token") == null) &&

              <Nav className="ml-auto" >
                <Nav.Link as={NavLink} to="/login">Login</Nav.Link>
              </Nav>
            }

          </Navbar.Collapse>
        </Navbar>

      </div>
    )
  }

  logout = () => {
    localStorage.setItem("usertype", "")
    localStorage.setItem("token", "")
    localStorage.setItem('usertypecode', "")
    localStorage.setItem('user_id', "")
    localStorage.setItem('empcode', "")
    localStorage.setItem('username', "")
    localStorage.setItem('usertypeid', "")
    window.location.href = "/login"
  }


}

export default NavMenu

