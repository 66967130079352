import React from 'react';
import { Component } from 'react'
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import homeimage from '../images/2wexport.png'
import ohtimage from '../images/oht.png'
import euroimage from '../images/euro.png'

export default class HomeComponent extends Component {
    constructor(props){
        super(props)

        this.state = {

        }
    }

    componentDidMount(){
        localStorage.setItem("screen_zoom", "80%")
        document.body.style.zoom = localStorage.getItem("screen_zoom");
        this.redirectLogin();
        
    }

    redirectLogin = () => {
        if(localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined || localStorage.getItem("token") == null){
            localStorage.setItem('token', '')
            window.location.href = "/login"
        } 
    }

  

    render(){
        return(
            <div className="container">
                {localStorage.getItem('segment')== '2WEXPORT'?
                (<img style={{marginTop :140}} src={homeimage}  />):(<>
                 {localStorage.getItem('segment')== 'OHT'?
                 (  <img style={{marginTop :140 }} src={ohtimage}  />):(
                    <img style={{marginTop :140}} src={euroimage}  />
                 )}
                </>
                  
                )}
               
            </div>

        )
    }
}
