import React from 'react';
import { Component } from 'react'
import { Document, Page } from 'react-pdf';
import { Grid } from 'semantic-ui-react';
import { Table, Form } from 'react-bootstrap';
import { Button, Card, ProgressBar } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Fab from '@material-ui/core/Fab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-select2-wrapper/css/select2.css';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import axios from 'axios';
import moment from 'moment'
import { Typeahead, TypeaheadInputMulti } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';



export default class LcadvPayment extends Component {
  constructor(props) {
    super(props)
    this.state = ({
      cust_list: [],
      order_add: [],
      get_cu_details: [],
      month_year: "",
      po_number: "",
      customer_name: "",
      customer_code: "",
      modal: false,
      view: false,
      featured_image: null,
      featured_image1: null,
      featured_image2: null,
      featured_image3: null,
      featured_image4: null,
      featured_image_name: '',
      featured_image_name1: '',
      featured_image_name2: '',
      featured_image_name3: '',
      featured_image_name4: '',
      order_no: "",
      order_version: "",
      numPages: null,
      pageNumber: 1,
      todos: {},
      document_no: '',
      document: '',
      fileupload: [],
      fileInfos: [],
      file_title: '',
      from_date: '',
      to_date: "",
      order_type: "A",
      get_cust_name: '',
      get_cust_code: '',
      baseurl: process.env['REACT_APP_API_URL_' + localStorage.getItem('segment')],

    })

    this.handle_cust = this.handle_cust.bind(this);
    this.toggle = this.toggle.bind(this);
    this.togglee = this.togglee.bind(this);

    this.toggleview = this.toggleview.bind(this);
    this.toggleeview = this.toggleeview.bind(this);
    this.deletetoggleview = this.deletetoggleview.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChange1 = this.handleInputChange1.bind(this);
    this.handleInputChange2 = this.handleInputChange2.bind(this);
    this.handleInputChange3 = this.handleInputChange3.bind(this);
    this.handleInputChange4 = this.handleInputChange4.bind(this);
    this.handle_document = this.handle_document.bind(this);



  }

  componentDidMount() {
    this.redirectLogin()
    localStorage.setItem("screen_zoom", "80%")
    document.body.style.zoom = localStorage.getItem("screen_zoom");

    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    this.state.from_date = defaultValue
    this.state.to_date = defaultValue

    if (localStorage.getItem('usertypecode') == 'CUST') {
      axios({
        method: "POST",
        url: this.state.baseurl + 'custdetails',//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          user_code: localStorage.getItem('empcode'),
          user_type_code: localStorage.getItem('usertypecode')
        }
      }).then((response) => {
        console.log("Testlcupload", response.data.data[0].customer_name)
        this.setState({
          cust_list: response.data.data, // you have it as this.data not response
          get_cust_name: response.data.data[0].customer_name,
          get_cust_code: response.data.data[0].customer_sap_code
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'prehdf_sales',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            customer_sap_code: response.data.data[0].customer_sap_code,
            fromdate: this.state.from_date,
            todate: this.state.to_date,

          }
        }).then((response) => {
          console.log("MANIsales", response.data.data)
          this.setState({
            get_cu_details: response.data.data,
          });
        });

      });
    } else {
      axios({
        method: "POST",
        url: this.state.baseurl + 'custdetails',//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          user_code: localStorage.getItem('empcode'),
          user_type_code: localStorage.getItem('usertypecode')
        }
      }).then((response) => {
        console.log("Test", response.data.data)
        this.setState({
          cust_list: response.data.data, // you have it as this.data not response
        });
      });
    }
  }

  redirectLogin = () => {
    if (localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined || localStorage.getItem("token") == null) {
      localStorage.setItem('token', '')
      window.location.href = "/login"
    }
  }



  // handle customer name 

  handle_cust = (selected) => {
    var cuname = "";
    var cuscode = '';
    for (var i = 0; i < selected.length; i++) {
      cuname = selected[i].customer_name;
      cuscode = selected[i].customer_sap_code
    }
    this.setState({
      customer_name: cuname,
      customer_code: cuscode
    })

    console.log("usercode", cuscode)

    // axios({
    //   method: "POST",
    //   url: this.state.baseurl + 'prehdf_sales',//"http://localhost:3300/custdetails",
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'token': localStorage.getItem('token')
    //   },
    //   data: { 
    //     customer_sap_code: cuscode,
    //     fromdate:this.state.from_date,
    //     todate:this.state.to_date
    //   }
    // }).then((response) => {
    //   console.log("listdocument", response.data.data)
    //   this.setState({
    //     get_cu_details: response.data.data,
    //   });
    // });



  }

  // handle Month and year  
  handle_month_year = (e) => {

    console.log("Mani",)
    this.setState({
      month_year: e.target.value
    })

  }

  //Select Status 
  handle_ordertype = (e) => {
    this.setState({
      order_type: e.target.value
    })

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }

    // axios({
    //   method: "POST",
    //   url: this.state.baseurl + 'prehdfstatus_sales',//"http://localhost:3300/custdetails",
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'token': localStorage.getItem('token')
    //   },
    //   data: { 
    //     customer_sap_code: custcode,
    //     fromdate :this.state.from_date,
    //     todate : this.state.to_date,
    //     pi_approved_by_sm_status :e.target.value
    //   }
    // }).then((response) => {
    //   console.log("MANI", response.data.data)
    //   this.setState({
    //     get_cu_details: response.data.data,
    //   });
    // });
  }


  handle_document = (id, e) => {

    var items = this.state.get_cu_details;

    this.setState({
      document_no: e.target.value
    })

    console.log("Test", e.target.value);

    items[id].document_no = e.target.value;

    this.setState({
      get_cu_details: items
    });
  }


  handle_show_list = (e) => {

    this.state.get_cu_details = []

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code
    }

    let order_type = ""
    if (this.state.order_type == 'A') {
      order_type = ""
    } else {
      order_type = this.state.order_type
    }



    axios({
      method: "POST",
      url: this.state.baseurl + 'prehdf_sales',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        customer_sap_code: custcode,
        fromdate: this.state.from_date,
        todate: this.state.to_date,
        pi_approved_by_sm_status: order_type,
      }
    }).then((response) => {
      console.log("MANI", response.data.data)
      if (response.data.status == 'Success') {
        this.setState({
          get_cu_details: response.data.data,
        });
      } else {
        alert("No data found for your selected date")
      }

    });

  }


  // handle_sample = (e)=>{
  //   alert("Hai")
  // }

  submitsave = (event) => {
    event.preventDefault();

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }
    const insertdate = JSON.stringify({
      "order_mmyyyy": this.state.month_year,
      "order_no": this.state.order_number,
      "order_version": this.state.order_version,
      "order_category": "",
      "customer_id": this.state.customer_id,
      "customer_sap_code": custcode,
      "notifyparty_sap_code": "",
      "po_number": this.state.po_number,
      "po_date": this.state.po_date,
      "container_type": this.state.container_size,
      "container_capacity": "0",
      "total_order_qty": "0",
      "total_order_value": "0",
      "se_code": "0",
      "final_destination": "",
      "port_of_discharge": "",
      "payment_term": this.state.pay_term,
      "inco_term": this.state.incotern,
      "order_type": this.state.order_type,
      "notes": "",
      "filler_item": "",
      "priority_item": "",
      "pi_status": "save",
      "created_at": "",
      "modified_at": "",

    })

    console.log("Header", insertdate)

    console.log("details ", this.state.add_get_size)
    axios({
      method: "POST",
      url: this.state.baseurl + 'update_pre_order',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        p_prepi_details: JSON.stringify({
          "order_mmyyyy": this.state.month_year,
          "order_no": this.state.order_number,
          "order_version": this.state.order_version,
          "order_category": "",
          // "customer_id":this.state.customer_id,
          "customer_sap_code": custcode,
          "notifyparty_sap_code": "",
          "po_number": this.state.po_number,
          "po_date": this.state.po_date,
          "container_type": this.state.container_size,
          "container_capacity": "0",
          "total_order_qty": "0",
          "total_order_value": "0",
          "se_code": "0",
          "final_destination": "",
          "port_of_discharge": "",
          "payment_term": this.state.pay_term,
          "inco_term": this.state.incotern,
          "order_type": this.state.order_type,
          "notes": "",
          "filler_item": "",
          "priority_item": "",
          "pi_status": "save",
          "created_at": "",
          "modified_at": "",
        }),

        p_prepi_details_item: JSON.stringify({ "items": this.state.add_get_size })

      }
    }).then((response) => {
      let res = response.data.message
      this.setState({
        order_type: response.data.data, // you have it as this.data not response
      });
      var getres = ''
      for (var i = 0; i < res.length; i++) {
        getres = res[i].fi01_prepi_order
      }
      var gtres = getres.substring(0, 1)
      if (gtres == "R") {
        this.notify(getres)
      } else {
        window.confirm(getres)
      }
    });

  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  nextPage = () => {

    const currentPageNumber = this.state.pageNumber;
    let nextPageNumber;

    if (currentPageNumber + 1 > this.state.numPages) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = currentPageNumber + 1;
    }

    this.setState({
      pageNumber: nextPageNumber
    });
  }

  goToPrevPage = () => this.setState(state => ({ pageNumber: state.pageNumber - 1 }));

  goToNextPage = () => this.setState(state => ({ pageNumber: state.pageNumber + 1 }));



  notify = (getres) => {
    toast.success(getres, {
      position: toast.POSITION.TOP_CENTER
    });
  };

  errorNotify = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER
    });
  }


  toggle = todo => {
    console.log("order", todo.order_version)

    // if (todo.pi_approved_by_sm_status == 'N' && this.state.document_no == '') {
    //   alert("Please enter document number")
    // }
    // //else{

    // // }
    // // if(this.state.document_no == ''){
    // //   alert("Please enter document number")
    // // }
    // else {
   
    // }

    console.log("Testmani", todo)
    this.setState(prevState => ({
      modal: !prevState.modal,
      order_no: todo.order_no,
      order_version: todo.order_version,
      document: todo.document_no
    }));

    axios({
      method: "POST",
      url: this.state.baseurl + 'uploadshow',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        order_no: todo.order_no
      }
    }).then((response) => {
      console.log("GET", response.data.data)
      this.setState({
        fileInfos: response.data.data, // you have it as this.data not response
      });
    });
  }

  togglee = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));

  }


  toggleview1 = () => {
    this.setState(prevState => ({
      view: !prevState.view
    }));

  }

  toggleview = (file) => {
    console.log("mANI", file.title)
    this.setState(prevState => ({
      view: !prevState.view,
      file_title: file.title
    }));

  }


  toggleeview = () => {
    this.setState(prevState => ({
      view: !prevState.view
    }));
  }

  deletetoggleview = (file) => {
    console.log("mANIdelete", file)
    this.setState(prevState => ({

    }));
    axios({
      method: "POST",
      url: this.state.baseurl + 'doc_delete',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        id: file.id,
        status: "false",
        order_no: file.order_no
      }
    }).then((response) => {
      console.log("GETmani", response.data.data)
      this.setState({
        fileInfos: response.data.data, // you have it as this.data not response
      });
    });
  }



  handleuploaddoc1 = (event) => {

    let file_name = "LC" + this.state.order_no + ".pdf"

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }

    console.log("image", this.state.featured_image)
    if (this.state.featured_image == null) {
      alert("PLEASE CHOOSE FILE ")
    } else {
      const formData = new FormData();
      formData.append(
        'type', 'POPDF');
      formData.append(
        'document', this.state.featured_image, file_name);
      console.log("documentupload", formData)
      fetch(process.env.REACT_APP_API_URL_FILE + 'aws_upload', { // 'http://10.10.1.38:5005/aws_upload'
        method: 'POST',
        body: formData
      })
        .catch(error => console.log("docupload", error));



      axios({
        method: "POST",
        url: this.state.baseurl + 'uploadcreate',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          featured_image: this.state.featured_image,
          customer_sap_code: custcode,
          order_no: this.state.order_no,
          title: file_name,
          document_no: this.state.document_no,
          order_version: parseInt(this.state.order_version),
          body: "text/plain",
          status: 'true'
        }
      }).then((response) => {
        console.log("GET", response.data.data)
        this.setState({
          orderaddmodel: response.data.data, // you have it as this.data not response
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'uploadshow',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            order_no: this.state.order_no
          }
        }).then((response) => {
          console.log("GET", response.data.data)
          this.setState({
            fileInfos: response.data.data, // you have it as this.data not response
          });
        });

      });
    }





  }

  handleuploaddoc2 = (event) => {

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }
    if (this.state.featured_image1 == null) {
      alert("PLEASE CHOOSE FILE ")
    } else {

      const formData = new FormData();

      formData.append(
        'type', 'POPDF');
      formData.append(
        'document', this.state.featured_image);
      console.log("documentupload", formData)
      fetch(process.env.REACT_APP_API_URL_FILE + 'aws_upload', {
        method: 'POST',
        body: formData
      })
        .catch(error => console.log(error));


      axios({
        method: "POST",
        url: this.state.baseurl + 'uploadcreate',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          featured_image: this.state.featured_image1,
          customer_sap_code: custcode,
          order_no: this.state.order_no,
          title: this.state.featured_image_name1,
          document_no: this.state.document_no,
          body: "text/plain",
          status: 'true'
        }
      }).then((response) => {
        console.log("GET", response.data.data)
        this.setState({
          orderaddmodel: response.data.data, // you have it as this.data not response
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'uploadshow',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            order_no: this.state.order_no
          }
        }).then((response) => {
          console.log("GET", response.data.data)
          this.setState({
            fileInfos: response.data.data, // you have it as this.data not response
          });
        });
      });
    }
  }


  handleuploaddoc3 = (event) => {
    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }
    if (this.state.featured_image2 == null) {
      alert("PLEASE CHOOSE FILE ")
    } else {
      const formData = new FormData();

      //'http://localhost:5000/upload_documents'
      formData.append('document', this.state.featured_image2);
      fetch('http://10.10.1.192:32500/upload_documents', {
        method: 'POST',
        body: formData
      })
        .catch(error => console.log(error));


      axios({
        method: "POST",
        url: this.state.baseurl + 'uploadcreate',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          featured_image: this.state.featured_image2,
          customer_sap_code: custcode,
          order_no: this.state.order_no,
          title: this.state.featured_image_name2,
          document_no: this.state.document_no,
          body: "text/plain",
          status: 'true'
        }
      }).then((response) => {
        console.log("GET", response.data.data)
        this.setState({
          orderaddmodel: response.data.data, // you have it as this.data not response
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'uploadshow',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            order_no: this.state.order_no
          }
        }).then((response) => {
          console.log("GET", response.data.data)
          this.setState({
            fileInfos: response.data.data, // you have it as this.data not response
          });
        });
      });
    }
  }


  handleuploaddoc4 = (event) => {
    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }
    if (this.state.featured_image3 == null) {
      alert("PLEASE CHOOSE FILE ")
    } else {
      const formData = new FormData();

      //'http://localhost:5000/upload_documents'
      formData.append('document', this.state.featured_image3);
      fetch('http://10.10.1.192:32500/upload_documents', {
        method: 'POST',
        body: formData
      })
        .catch(error => console.log(error));


      axios({
        method: "POST",
        url: this.state.baseurl + 'uploadcreate',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          featured_image: this.state.featured_image3,
          customer_sap_code: custcode,
          order_no: this.state.order_no,
          title: this.state.featured_image_name3,
          document_no: this.state.document_no,
          body: "text/plain",
          status: 'true'
        }
      }).then((response) => {
        console.log("GET", response.data.data)
        this.setState({
          orderaddmodel: response.data.data, // you have it as this.data not response
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'uploadshow',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            order_no: this.state.order_no
          }
        }).then((response) => {
          console.log("GET", response.data.data)
          this.setState({
            fileInfos: response.data.data, // you have it as this.data not response
          });
        });
      });
    }
  }

  handleuploaddoc5 = (event) => {

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }
    if (this.state.featured_image4 == null) {
      alert("PLEASE CHOOSE FILE ")
    } else {
      const formData = new FormData();

      //'http://localhost:5000/upload_documents'
      formData.append('document', this.state.featured_image4);
      fetch('http://10.10.1.192:32500/upload_documents', {
        method: 'POST',
        body: formData
      })
        .catch(error => console.log(error));


      axios({
        method: "POST",
        url: this.state.baseurl + 'uploadcreate',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          featured_image: this.state.featured_image4,
          customer_sap_code: custcode,
          order_no: this.state.order_no,
          title: this.state.featured_image_name4,
          document_no: this.state.document_no,
          body: "text/plain",
          status: 'true'
        }
      }).then((response) => {
        console.log("GET", response.data.data)
        this.setState({
          orderaddmodel: response.data.data, // you have it as this.data not response
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'uploadshow',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            order_no: this.state.order_no
          }
        }).then((response) => {
          console.log("GET", response.data.data)
          this.setState({
            fileInfos: response.data.data, // you have it as this.data not response
          });
        });
      });
    }
  }



  handleuploaddoc_m = (event) => {

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }
    console.log("Manifile", this.state.fileupload)
    const formData = new FormData();
    //'http://localhost:5000/upload_documents'
    formData.append('document', this.state.featured_image4);
    fetch('http://10.10.1.192:32500/upload_documents', {
      method: 'POST',
      body: formData
    })
      .catch(error => console.log(error));


    axios({
      method: "POST",
      url: this.state.baseurl + 'uploadcreate',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        featured_image: this.state.featured_image4,
        customer_sap_code: custcode,
        order_no: this.state.order_no,
        title: this.state.featured_image_name4,
        document_no: this.state.document_no,
        body: "text/plain",
      }
    }).then((response) => {
      console.log("GET", response.data.data)
      this.setState({
        orderaddmodel: response.data.data, // you have it as this.data not response
      });
    });
  }
  handleInputChange = event => {
    this.setState({
      featured_image: event.target.files[0],
      featured_image_name: event.target.files[0].name
    });

  };

  handleInputChange1 = event => {
    this.setState({
      featured_image1: event.target.files[0],
      featured_image_name1: event.target.files[0].name
    });


  }
  handleInputChange2 = event => {
    this.setState({
      featured_image2: event.target.files[0],
      featured_image_name2: event.target.files[0].name
    });
    console.log("Test12", event.target.files[0])



  }
  handleInputChange3 = (event) => {
    this.setState({
      featured_image3: event.target.files[0],
      featured_image_name3: event.target.files[0].name
    });
    console.log("Test", event.target.files[0])



  }
  handleInputChange4 = (event) => {
    this.setState({
      featured_image4: event.target.files[0],
      featured_image_name4: event.target.files[0].name
    });
    console.log("Test", event.target.files[0])

  }

  handle_from_date = (e) => {
    this.setState({
      from_date: e.target.value
    })
  }

  handle_to_date = (e) => {

    if (this.state.from_date > e.target.value) {
      alert("To date shound be > of from date")
      this.setState({
        to_date: ''
      })
    } else {

      this.setState({
        to_date: e.target.value
      })
      let custcode = ''
      if (localStorage.getItem('usertypecode') == 'CUST') {
        custcode = this.state.get_cust_code
      } else {
        custcode = this.state.customer_code

      }

      // axios({
      //   method: "POST",
      //   url: this.state.baseurl + 'prehdf_sales',//"http://localhost:3300/custdetails",
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'token': localStorage.getItem('token')
      //   },
      //   data: { 
      //     "customer_sap_code": custcode,
      //     "fromdate":this.state.from_date,
      //     "todate":e.target.value
      //   }
      // }).then((response) => {
      //   console.log("MANI", response.data.data)
      //   this.setState({
      //     get_cu_details: response.data.data,
      //   });
      // });
    }
  }




  render() {
    const { get_cu_details = [] } = this.state
    const { pageNumber, numPages } = this.state;
    const { fileInfos, file_title } = this.state;


    var pdf_urlpath = process.env.REACT_APP_API_URL_FILE + "download_aws?type=POPDF&key="
    console.log("Tet", pdf_urlpath + file_title)


    return (
      <div>
        <Modal style={{ maxWidth: '900px', width: '100%' }} isOpen={this.state.view} toggle={this.toggleview}>
          <ModalHeader toggle={this.toggleview}>Document View </ModalHeader>
          <ModalBody >

            <Grid centered columns={2}>
              <Grid.Column textAlign="center" onClick={this.nextPage}>
                <Document
                  file={pdf_urlpath + file_title}
                  onLoadSuccess={this.onDocumentLoadSuccess} noData={<h4></h4>}

                >
                  <Page pageNumber={pageNumber} />
                </Document>

                <p>
                  Page {pageNumber} of {numPages}
                </p>
                <button onClick={this.nextPage}>
                  Next page
                </button>
              </Grid.Column>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleeview} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>

        <Modal style={{ maxWidth: '600px', width: '100%' }} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Document Upload </ModalHeader>
          <ModalBody>


            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col>
                <label className="text-white">Select File :</label>
                <input type="file" multiple
                  style={{ width: "350px", height: '80' }}
                  className="form-control" name="upload_file"
                  onChange={this.handleInputChange}
                />
              </Col>


              <Col>
                <Button style={{ marginTop: "30px" }} onClick={this.handleuploaddoc1} color="primary"  >Upload</Button>
              </Col>
            </Row>


            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col>
                <div className="card">
                  <div className="card-header">List of Files</div>
                  <ul className="list-group list-group-flush">
                    {fileInfos &&
                      fileInfos.map((file, index) => (
                        <li className="list-group-item" key={index}>
                          <Row>
                            <Col>
                              <a href={file.url}>{file.title}</a>
                            </Col>
                            <Col>
                              <Fab size="small" color="primary">
                                <VisibilityIcon onClick={() => this.toggleview(file)} size="small" />
                              </Fab>
                            </Col>
                            <Col>
                              <Fab size="small" color="primary" aria-label="add">
                                <DeleteIcon onClick={() => this.deletetoggleview(file)} size="small" />
                              </Fab>
                            </Col>
                          </Row>


                        </li>
                      ))}
                  </ul>
                </div>

              </Col>

            </Row>



          </ModalBody>
          <ModalFooter>
            <Button onClick={this.togglee} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>
        <Container fluid>
          <Card style={{ margin: "5px" }}>

            <Card.Body>
              <h4><b>Payment Upload</b></h4>
              <Row style={{ marginTop: "2px", marginBottom: "7px" }}>


                {/* <Col><Form.Label className="name-label">Master Name</Form.Label></Col> */}
                {/* <Col className="text-left">
                      <Form.Label className="name-label">Month & Year</Form.Label>
                      <input  className="form-control" required="required" 
                      type="month" name="setup_name" 
                      onChange={this.handle_month_year}
                       />    
                      
                    </Col> */}

                <Col className="text-left">
                  <Form.Label className="name-label">From Date</Form.Label>
                  <input className="form-control" required="required"
                    type="date" name="setup_name"
                    value={this.state.from_date}
                    onChange={this.handle_from_date}
                  />

                </Col>
                <Col className="text-left">
                  <Form.Label className="name-label">To Date</Form.Label>
                  <input className="form-control" required="required"
                    type="date" name="setup_name"
                    value={this.state.to_date}
                    onChange={this.handle_to_date}
                  />


                </Col>

                <Col >
                  {localStorage.getItem('usertypecode') != 'CUST' ? (<>
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                    <div>
                      <Typeahead
                        id="exampleSelect"
                        style={{ width: "490px" }}
                        selected={this.selected}
                        onChange={this.handle_cust.bind(this)}
                        value={this.state.customer_name}
                        labelKey={option => `${option.customer_name} `}
                        options={this.state.cust_list}
                        placeholder="Select a customer..."
                      />
                    </div>
                  </>) : (<>
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                    <div>

                      <input className="form-control" required="required"
                        placeholder=''
                        style={{ width: "490px" }}
                        type="text" name="setup_name" readOnly={true} value={this.state.get_cust_name} />
                    </div>
                  </>)}



                </Col>

                <Col className="text-left">
                  <div className="text-left">
                    <Form.Label className="name-label" >Select Status</Form.Label>
                  </div>


                  <div>
                    <select className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`}
                      defaultChecked={this.state.master_type} value={this.state.order_type} onChange={this.handle_ordertype}>
                      <option value="A">All</option>
                      <option value="N">Pending</option>
                      <option value="Y">Completed</option>
                    </select>
                  </div>
                </Col>

                <Col className="text-left">
                  <Button style={{ marginTop: 30 }} onClick={this.handle_show_list} color="primary"  >Display</Button>{' '}
                </Col>
                <Col className="text-left">


                </Col>


              </Row>
            </Card.Body>
          </Card>
        </Container>

        <div className="table" style={{ width: "99%", display: "inline-table", margin: "5px", backgroundColor: "#fff" }}>
          <Table striped bordered responsive="sm">
            <thead style={{ backgroundColor: "#413c69", color: "#ffffff" }}>
              <tr>
                <th style={{ width: "20px" }}>#</th>
                <th style={{ width: "100px" }}>Customer Sap Code</th>
                <th style={{ width: "100px" }}>PI Date</th>
                <th style={{ width: "80px" }}>PI Reference</th>
                <th style={{ width: "80px" }}>Order Ref No</th>
                <th style={{ width: "60px" }}>Version</th>
                <th style={{ width: "100px" }}>PI Value</th>
                <th style={{ width: "100px" }}>Payment Term</th>
                <th style={{ width: "100px" }}>Receive Date</th>
                <th style={{ width: "150px" }}>Status</th>
                <th style={{ width: "200px" }}>Document number/LC</th>
                <th style={{ width: "150px" }}>LC/Payment Document Upload</th>

              </tr>
            </thead>
            <tbody>
              {get_cu_details.length ?
                get_cu_details.map((todo, id) => (
                  <tr key={id} >
                    <td>{id + 1}</td>
                    {todo.customer_sap_code != null ? (<td>{todo.customer_sap_code}</td>) : (<td>-</td>)}
                    {todo.pi_date != null ? (<td>{moment(todo.pi_date).format('DD-MM-YYYY')}</td>) : (<td>-</td>)}
                    {todo.pi_number != null ? (<td>{todo.pi_number}</td>) : (<td>-</td>)}
                    {todo.order_no != null ? (<td>{todo.order_no}</td>) : (<td>-</td>)}
                    {todo.order_version != null ? (<td>{todo.order_version}</td>) : (<td>-</td>)}
                    {todo.pi_value != null ? (<td>{todo.pi_value}</td>) : (<td>-</td>)}
                    {todo.payment_term != null ? (<td>{todo.payment_term}</td>) : (<td>-</td>)}
                    {todo.pi_date != null ? (<td>{moment(todo.pi_date).format('DD-MM-YYYY')}</td>) : (<td>-</td>)}
                    {todo.pi_approved_by_sm_status == "Y" ? (<td>{'Completed'}</td>) : (
                      <td>
                        {'Pending'}
                      </td>
                    )}
                    {todo.pi_approved_by_sm_status == "Y" ? (<th>-</th>) : (
                      <th>
                        <input
                          className="form-control"
                          id={"exampleSelect"}
                          placeholder=""
                          onChange={this.handle_document.bind(this, id)}
                          value={this.state.get_cu_details[id].document_no}
                          type="text">
                        </input>
                      </th>
                    )}

                    <td>
                      <Button
                        style={{ marginRight: "2%" }}
                        onClick={() => this.toggle(todo)}
                      >Click to Upload </Button>

                    </td>


                  </tr>
                ))
                :
                (<tr>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>- </th>
                  <th>-</th>
                  <th>- </th>
                </tr>)
              }

            </tbody>
          </Table>
        </div>


        <div className="text-center" style={{ marginBottom: "50px" }}>
          <Button variant="success"
            style={{ marginRight: "2%" }}
            onClick={(e) => { if (window.confirm("Are you sure save?")) this.submitsave(e) }}>Save</Button>

          <Button variant="success" onClick={() => { window.location.href = "/home" }}>Exit</Button>

          <Button variant="success" >Submit</Button>
        </div>


        <div><ToastContainer /></div>

      </div>
    )
  }

}


