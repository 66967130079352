import React from 'react';
import { Component} from 'react'
import { Table, Form } from 'react-bootstrap';
import {Button,Card, ProgressBar} from 'react-bootstrap';
import {Container } from 'react-bootstrap';
import {Row,Col } from 'react-bootstrap';
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import Fab from '@material-ui/core/Fab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-select2-wrapper/css/select2.css';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import axios from 'axios';
import moment from 'moment'
import { Typeahead, TypeaheadInputMulti } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ReactHTMLTableToExcel from "react-html-table-to-excel";

var pageNumbers = []
export default class ContainerTracking extends Component {
  constructor(props){
    super(props)
    this.state = ({
      cust_list :[],
      order_add :[],
      get_cu_details :[],
      month_year :"",
      po_number :"",
      customer_name :"",
      customer_code :"",
      selectedFile:'',
      order_type :'',
      portship:'',
      modal: false,
      from_date :'',
      to_date :"",

      container_date :'',
      shipment_date :'',
      shipstart_date :'',
      expected_date :'',
      revised_date :'',
      actuval_date :'',
      port_of_ship :'',
      ship_agent :'',
      ship_line_name :'',


      container_available_date :'',
      port_of_shipment:'',
      shipping_agent:'',
      ship_loading_date:'',
      shipping_line_name:'',
      ship_start_date:'',
      expected_time_arrival:'',
      revised_time_arrival:'',
      actual_time_arrival:'',
      invoice_date :'',
      baseurl :  process.env['REACT_APP_API_URL_'+localStorage.getItem('segment') ],
      get_invoice_no: '',

      total: null,
      per_page: null,
      current_page: 1,
     
    })

    this.handle_cust = this.handle_cust.bind(this);
    this.toggle = this.toggle.bind(this);
    this.togglee = this.togglee.bind(this);
    this.handle_approval = this.handle_approval.bind(this);
    this.handleportship = this.handleportship.bind(this);
    this.editdepartment = this.editdepartment.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.togupdate = this.togupdate.bind(this);
    this.handle_save= this.handle_save.bind(this);
    this.handle_container_date = this.handle_container_date.bind(this);
    this.handle_port = this.handle_port.bind(this);
    this.handle_shipment_date = this.handle_shipment_date.bind(this);
    this.handle_shipagent = this.handle_shipagent.bind(this);
    this.handle_shipname = this.handle_shipname.bind(this);
    this.handle_ship_date = this.handle_ship_date.bind(this);
    this.handle_expected = this.handle_expected.bind(this);
    this.handle_revised = this.handle_revised.bind(this);
    this.handle_actuval = this.handle_actuval.bind(this);
    
  }

  togupdate() {
    this.setState(prevState => ({
        update: !prevState.update
    }));
  }

  handle_save =(id)=>{
    

    // var items = this.state.get_cu_details;
    // items[id].port_of_shipment = this.state.port_of_ship
    // items[id].shipping_agent = this.state.shipping_agent
    // items[id].ship_loading_date =this.state.ship_loading_date
    // items[id].ship_loading_date =this.state.ship_loading_date
    // items[id].shipping_line_name =this.state.shipping_line_name
    // items[id].ship_start_date =this.state.ship_start_date
    // items[id].expected_time_arrival =this.state.expected_time_arrival
    // items[id].revised_time_arrival =this.state.revised_time_arrival
    // items[id].actual_time_arrival =this.state.actual_time_arrival

    // this.setState({
    //   get_cu_details: items,
      
    // });
    console.log("details ", JSON.stringify({"items" : this.state.get_cu_details}))
    
    
    
    axios({
      method: "POST",
      url: this.state.baseurl + 'sapupdate',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: { 

        container_details: JSON.stringify({"items" : this.state.get_cu_details})
       
      }
    }).then((response) => {
      let res = response.data.message
      this.setState({
        order_type: response.data.data, // you have it as this.data not response
      });
      var getres =''
      for (var i =0; i< res.length ;i++){
        getres = res[i].fi01_invoice_container_detail
      }
      var gtres = getres.substring(0,1)
      if(gtres == "R"){
        this.notify(getres)
      }else{
        window.confirm(getres)
      }
    });
  }


  editdepartment = todo => {
    this.setState(prevState => ({
    update: !prevState.update,
  }));
    // axios({
    //   method: "POST",
    //   url: this.state.baseurl + 'getcustomerinvoice',//"http://localhost:3300/custdetails",
    //   data: { 
    //     "from_date":this.state.from_date,
    //     "to_date":e.target.value,
    //     "customer_sap_code":this.state.customer_code
    //   }
    // }).then((response) => {
    //   console.log("MANI", response.data.data)
    //   this.setState({
    //     get_cu_details: response.data.data,
    //   });
    // });

}

handleChange=(e)=>
{
  alert(e.target.value)
}


  componentDidMount(){
    this.redirectLogin()
    localStorage.setItem("screen_zoom", "80%")
    document.body.style.zoom = localStorage.getItem("screen_zoom");
    
    const date = new Date();
    const futureDate = date.getDate() ;
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    this.state.from_date = defaultValue
    this.state.to_date = defaultValue
       
    if(localStorage.getItem('usertypecode') == 'CUST'){
      axios({
        method: "POST",
        url: this.state.baseurl +'custdetails' ,//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: { 
          user_code :  localStorage.getItem('empcode'),
          user_type_code :localStorage.getItem('usertypecode')
        }
      }).then((response) => {
        console.log("Test", response.data.data)
        this.setState({
          cust_list: response.data.data, // you have it as this.data not response
          get_cust_name :response.data.data[0].customer_name,
          get_cust_code : response.data.data[0].customer_sap_code 
        });
      });
    }else{
      axios({
        method: "POST",
        url: this.state.baseurl +'custdetails' ,//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: { 
          user_code :  localStorage.getItem('empcode'),
          user_type_code :localStorage.getItem('usertypecode')
        }
      }).then((response) => {
        console.log("Test", response.data.data)
        this.setState({
          cust_list: response.data.data, // you have it as this.data not response
        });
      });
    }
  }


  redirectLogin = () => {
    if(localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined || localStorage.getItem("token") == null){
        localStorage.setItem('token', '')
        window.location.href = "/login"
    } 
  }
 

  handle_container_date=(id , e)=>{
    var items  = this.state.get_cu_details
    this.setState({
      container_available_date : e.target.value
    })

    items[id].container_available_date = e.target.value
  }

  handle_port =(id ,e)=>{
    var items  = this.state.get_cu_details
    this.setState({
      port_of_shipment : e.target.value
    })
    items[id].port_of_shipment = e.target.value
  }

  handle_shipment_date =(id ,e)=>{
    var items  = this.state.get_cu_details
    this.setState({
      ship_loading_date : e.target.value
    })
    items[id].ship_loading_date = e.target.value
  }

  handle_shipagent=(id ,e)=>{
    var items  = this.state.get_cu_details
    this.setState({
      shipping_agent : e.target.value
    })
    items[id].shipping_agent = e.target.value
  }

  handle_shipname=(id ,e)=>{
    var items  = this.state.get_cu_details
    this.setState({
      shipping_line_name : e.target.value
    })
    items[id].shipping_line_name = e.target.value
  }

  handle_ship_date=(id ,e)=>{
    var items  = this.state.get_cu_details
    this.setState({
      ship_start_date : e.target.value
    })
    items[id].ship_start_date = e.target.value
  }

  handle_expected=(id ,e)=>{
    var items  = this.state.get_cu_details
    this.setState({
      expected_time_arrival : e.target.value
    })
    items[id].expected_time_arrival = e.target.value
  }

  handle_revised=(id ,e)=>{
    var items  = this.state.get_cu_details
    this.setState({
      revised_time_arrival : e.target.value
    })
    items[id].revised_time_arrival = e.target.value
  }

  handle_actuval=(id ,e)=>{
    var items  = this.state.get_cu_details
    this.setState({
      actual_time_arrival : e.target.value
    })
    items[id].actual_time_arrival = e.target.value
  }
  
 

    // handle customer name 
    
    handle_cust =(selected)=>{
      var cuname = "";
      var cuscode ='';
      for (var i = 0; i < selected.length; i++) {
        cuname = selected[i].customer_name;
        cuscode =  selected[i].customer_sap_code
      }
      this.setState({
        customer_name:cuname,
        customer_code :cuscode
      })


      axios({
        method: "POST",
        url: this.state.baseurl + 'getcustomerinvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: { 
          "from_date":this.state.from_date,
          "to_date":this.state.to_date,
          "customer_sap_code":cuscode
        }
      }).then((response) => {
        console.log("MANI", response.data.data)
        this.setState({
          get_cu_details: response.data.data,
        });

        var  get_cust  = response.data.data
        for (var j=0; j< get_cust.length;j++){
          this.state.container_available_date = moment(get_cust[j].container_available_date).format('YYYY-MM-DD')
          this.state.port_of_shipment = get_cust[j].port_of_shipment;
          this.state.invoice_date = get_cust[j].invoice_date;
        }
      });
    
     
    }



    handle_approval =(id,e)=>{

      var items = this.state.get_cu_details;
    
    
      items[id].pi_approved_by_sm_status = 'Y'
  
      this.setState({
        get_cu_details: items
      });
     
  
      
  
     
    }


    handleportship=(e)=>
    {
      this.setState({portship:e.target.value})

    }
    handle_fromdate =(e)=>{
  
      this.setState({
        from_date : e.target.value
      })
    }
  
       // handle Month and year  
       handle_fromdate =(e)=>{
  
      this.setState({
        from_date : e.target.value
      })
    }

      handle_todate =(e)=>{

        
        
            this.setState({
              to_date : e.target.value
            })
          
    

      // axios({
      //   method: "POST",
      //   url: this.state.baseurl + 'getcustomerinvoice',//"http://localhost:3300/custdetails",
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'token': localStorage.getItem('token')
      //   },
      //   data: { 
      //     "from_date":this.state.from_date,
      //     "to_date":e.target.value,
      //     "customer_sap_code":this.state.customer_code
      //   }
      // }).then((response) => {
      //   console.log("MANI", response.data.data)
      //   this.setState({
      //     get_cu_details: response.data.data,
      //   });

      //   var  get_cust  = response.data.data
      //   for (var j=0; j< get_cust.length;j++){
      //     this.state.container_available_date = moment(get_cust[j].container_available_date).format('YYYY-MM-DD')
      //     this.state.port_of_shipment = get_cust[j].port_of_shipment;
         
      //   }
      // });
    
    }


    handle_invoice_no = (e) => {

      this.setState({
        get_invoice_no: e.target.value,
       
      })
  
      console.log("Invoiceno", e.target.value)
    }
  
    handle_show_invoice = ( e) => {
  
      this.state.get_cu_details =[]
  
      let custcode = ''
      if(localStorage.getItem('usertypecode') == 'CUST'){
        custcode = this.state.get_cust_code
      }else{
        custcode = this.state.customer_code
      }
    
      axios({
        method: "POST",
        url: this.state.baseurl + 'getcustomerinvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          "from_date": this.state.from_date,
          "to_date": this.state.to_date,
          "customer_sap_code": custcode,
          "invoice_no": this.state.get_invoice_no,
          "usertype": localStorage.getItem('usertypecode'),
          "username": localStorage.getItem('username'),
          "page":1
        }
      }).then((response) => {
        console.log("Invoicedatashipment", response.data.data)
        this.setState({
          get_cu_details: response.data.data,
          total: response.data.total_page,
          current_page: response.data.current_page,
          per_page: response.data.per_page,
        });
        
        var  get_cust  = response.data.data
        for (var j=0; j< get_cust.length;j++){
          this.state.container_available_date = moment(get_cust[j].container_available_date).format('YYYY-MM-DD')
          this.state.port_of_shipment = get_cust[j].port_of_shipment;
         
        }
      });
    }
  
    handle_show_invoice1 = ( page_number) => {

      this.state.get_cu_details =[]
  
      console.log("Mani", page_number)
      let custcode = ''
      if(localStorage.getItem('usertypecode') == 'CUST'){
        custcode = this.state.get_cust_code
      }else{
        custcode = this.state.customer_code
      }
      axios({
        method: "POST",
        url: this.state.baseurl + 'getcustomerinvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          "from_date": this.state.from_date,
          "to_date": this.state.to_date,
          "customer_sap_code": custcode,
          "invoice_no": this.state.get_invoice_no,
          "usertype": localStorage.getItem('usertypecode'),
          "username": localStorage.getItem('username'),
          "page":page_number
        }
      }).then((response) => {
        console.log("Invoicedata", response.data.total_page)
        this.setState({
          get_cu_details: response.data.data,
          total: response.data.total_page,
          current_page: response.data.current_page,
          per_page: response.data.per_page,
        });
        
     
      });
    }


    handle_ordertype=(e)=>{
      
      this.setState({
        order_type : e.target.value
      })

      axios({
        method: "POST",
        url: this.state.baseurl + 'getcustomerinvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: { 
          customer_sap_code: this.state.customer_code,
          from_date:this.state.from_date,
          to_date :this.state.to_date
        }
      }).then((response) => {
        console.log("MANI", response.data.data)
        this.setState({
          get_cu_details: response.data.data,
        });
      });
    }

    submitsave =(event) =>{
      event.preventDefault();
    

      console.log("detailsmani ", this.state.get_cu_details)
      // axios({
      //   method: "POST",
      //   url: this.state.baseurl + 'update_approval',//"http://localhost:3300/custdetails",
      //   data: { 

      //     p_prepi_details: JSON.stringify({"items" : this.state.get_cu_details})
         
      //   }
      // }).then((response) => {
      //   let res = response.data.message
      //   this.setState({
      //     order_type: response.data.data, // you have it as this.data not response
      //   });
      //   var getres =''
      //   for (var i =0; i< res.length ;i++){
      //     getres = res[i].fi01_prepi_sm_approval
      //   }
      //   var gtres = getres.substring(0,1)
      //   if(gtres == "R"){
      //     this.notify(getres)
      //   }else{
      //     window.confirm(getres)
      //   }
      // });

    }

    submitLists =(event) => {
      event.preventDefault();
      const insertdate =  JSON.stringify({ 
        "order_mmyyyy":this.state.month_year,
        "order_no":this.state.order_number,
        "order_version":this.state.order_version,
        "order_category":"",
        "customer_id":this.state.customer_id,
        "customer_sap_code":this.state.customer_code,
        "notifyparty_sap_code":"",
        "po_number":this.state.po_number,
        "po_date":this.state.po_date,
        "container_type":this.state.container_size,
        "container_capacity":"0",
        "total_order_qty":"0",
        "total_order_value":"0",
        "se_code":"0",
        "final_destination":"",
        "port_of_discharge":"",
        "payment_term":this.state.pay_term,
        "inco_term":this.state.incotern,
        "order_type":this.state.order_type,
        "notes":"",
        "filler_item":"",
        "priority_item":"",
        "pi_status":"submit",
        "created_at":"",
        "modified_at":"",

       }) 

       console.log("Header", insertdate)

      console.log("details ", this.state.add_get_size)
      axios({
        method: "POST",
        url: this.state.baseurl + 'update_pre_order',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: { 
          p_prepi_details: JSON.stringify({ 
            "order_mmyyyy":this.state.month_year,
            "order_no":this.state.order_number,
            "order_version":this.state.order_version,
            "order_category":"",
           // "customer_id":this.state.customer_id,
            "customer_sap_code":this.state.customer_code,
            "notifyparty_sap_code":"",
            "po_number":this.state.po_number,
            "po_date":this.state.po_date,
            "container_type":this.state.container_size,
            "container_capacity":"0",
            "total_order_qty":"0",
            "total_order_value":"0",
            "se_code":"0",
            "final_destination":"",
            "port_of_discharge":"",
            "payment_term":this.state.pay_term,
            "inco_term":this.state.incotern,
            "order_type":this.state.order_type,
            "notes":"",
            "filler_item":"",
            "priority_item":"",
            "pi_status":"submit",
            "created_at":"",
            "modified_at":"",
           }) ,

           p_prepi_details_item: JSON.stringify({"items" : this.state.add_get_size})
         
        }
      }).then((response) => {
        let res = response.data.message
        this.setState({
          order_type: response.data.data, // you have it as this.data not response
        });
        var getres =''
        for (var i =0; i< res.length ;i++){
          getres = res[i].fi01_prepi_order
        }
        var gtres = getres.substring(0,1)
        if(gtres == "R"){
          this.notify(getres)
        }else{
          window.confirm(getres)
        }
      });

    }

    pisubmitLists =(event) => {
      event.preventDefault();
      const insertdate =  JSON.stringify({ 
        "order_mmyyyy":this.state.month_year,
        "order_no":this.state.order_number,
        "order_version":this.state.order_version,
        "order_category":"",
        "customer_id":this.state.customer_id,
        "customer_sap_code":this.state.customer_code,
        "notifyparty_sap_code":"",
        "po_number":this.state.po_number,
        "po_date":this.state.po_date,
        "container_type":this.state.container_size,
        "container_capacity":"0",
        "total_order_qty":"0",
        "total_order_value":"0",
        "se_code":"0",
        "final_destination":"",
        "port_of_discharge":"",
        "payment_term":this.state.pay_term,
        "inco_term":this.state.incotern,
        "order_type":this.state.order_type,
        "notes":"",
        "filler_item":"",
        "priority_item":"",
        "pi_status":"piconfirm",
        "created_at":"",
        "modified_at":"",

       }) 

       console.log("Header", insertdate)

      console.log("details ", this.state.add_get_size)
      axios({
        method: "POST",
        url: this.state.baseurl + 'update_pre_order',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: { 
          p_prepi_details: JSON.stringify({ 
            "order_mmyyyy":this.state.month_year,
            "order_no":this.state.order_number,
            "order_version":this.state.order_version,
            "order_category":"",
           // "customer_id":this.state.customer_id,
            "customer_sap_code":this.state.customer_code,
            "notifyparty_sap_code":"",
            "po_number":this.state.po_number,
            "po_date":this.state.po_date,
            "container_type":this.state.container_size,
            "container_capacity":"0",
            "total_order_qty":"0",
            "total_order_value":"0",
            "se_code":"0",
            "final_destination":"",
            "port_of_discharge":"",
            "payment_term":this.state.pay_term,
            "inco_term":this.state.incotern,
            "order_type":this.state.order_type,
            "notes":"",
            "filler_item":"",
            "priority_item":"",
            "pi_status":"piconfirm",
            "created_at":"",
            "modified_at":"",
           }) ,

           p_prepi_details_item: JSON.stringify({"items" : this.state.add_get_size})
         
        }
      }).then((response) => {
        let res = response.data.message
        this.setState({
          order_type: response.data.data, // you have it as this.data not response
        });
        var getres =''
        for (var i =0; i< res.length ;i++){
          getres = res[i].fi01_prepi_order
        }
        var gtres = getres.substring(0,1)
        if(gtres == "R"){
          this.notify(getres)
        }else{
          window.confirm(getres)
        }
      });

    }

   notify = (getres) => {
        toast.success(getres, {
            position: toast.POSITION.TOP_CENTER
        });
    };
    
    errorNotify = (message) => {
        toast.error(message, {
            position: toast.POSITION.TOP_CENTER
        });
    }


    toggle =() => {
      this.setState(prevState => ({
          modal: !prevState.modal
      }));    
    }
 
    togglee =() => {
      this.setState(prevState => ({
          modal: !prevState.modal
      }));    
    }
 
    handleInputChange(event) {
      this.setState({
          selectedFile: event.target.files[0],
        })
  }
  

  handleNextPage = (current_page) => {
    var last_page = pageNumbers[pageNumbers.length - 1]
    if(last_page == current_page){

      this.handle_show_invoice1(1)
      // if(this.state.searching){
      //   this.handle(1);
      // } else {
      //   this.fetchMasterData(1)
      // }
      
    } else {
      if(this.state.searching){
        this.fetchSearchResult(current_page + 1);
      } else {
        this.handle_show_invoice1(current_page + 1)
      }
      
    }
  }

  

    render(){
      const { container_available_date,shipping_agent,sapid,get_cu_details =[],portship="", get_order =[], orderaddmodel =[], get_wishlist =[]}= this.state

      
      pageNumbers = []
      if (this.state.total !== null) {
        for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
          pageNumbers.push(i);
        }
      }
    
      const renderPageNumbers = pageNumbers.map(number => {
        let classes = this.state.current_page === number ? 'pagination-active' : '';
      
        if (number == 1 || number == this.state.total || (number >= this.state.current_page - 2 && number <= this.state.current_page + 5)) {
          if(this.state.searching){
            return (
              <span key={number} className={classes} onClick={() => this.fetchSearchResult(number)}>{number}</span>
            );
          } else {
            return (
              <span key={number} className={classes} onClick={() => this.handle_show_invoice1(number)}>{number}</span>
            );
          }
          
        }
      });
    
      return(
       
        <div>
          
          <Modal isOpen={this.state.update} toggle={() => this.editdepartment()}>
          <ModalHeader toggle={ this.togupdate}>Update SAP details</ModalHeader> 
                      <ModalBody>

                      <Row>
                          <Col lg="6">
                     
                          <label
                            className="form-control-label"
                            htmlFor="input-email">Ship Loading Date</label>
                                </Col>

                                <Col lg="6">
                             <input
                               className="form-control"
                                id="input-email"
                                placeholder=""
                                type="date"
                                value=""
                                
                               
                              />
                                </Col>
                           
                        
                        </Row>
                       <br></br>
                        <Row>
                          <Col lg="6">
                     
                          <label
                            className="form-control-label"
                            htmlFor="input-email">Shipping Agent</label>
                            </Col>

                            <Col lg="6">
                             <input
                                className="form-control"
                                id="input-email"
                                placeholder="Enter Department Name"
                                type="text"
                                onChange={this.handleChange.bind(this)} 
                                value={shipping_agent}
                              />
                             
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                          <Col lg="6">
                     
                          <label
                            className="form-control-label"
                            htmlFor="input-email">Shipping Line Name</label>
                            </Col>

                            <Col lg="6">
                             <input
                                className="form-control"
                                id="input-email"
                                placeholder="Shipping Line Name"
                                type="text"
                                onChange={this.handleChange.bind(this)} 
                                value=""
                              />
                             
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                          <Col lg="6">
                     
                          <label
                            className="form-control-label"
                            htmlFor="input-email">Ship Start Date</label>
                            </Col>

                            <Col lg="6">
                             <input
                                className="form-control"
                                id="input-email"
                                placeholder="Ship Start Date"
                                type="date"
                                onChange={this.handleChange.bind(this)} 
                                value=""
                              />
                             
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                          <Col lg="6">
                     
                          <label
                            className="form-control-label"
                            htmlFor="input-email">Expected_time_arrival</label>
                            </Col>

                            <Col lg="6">
                             <input
                                className="form-control"
                                id="input-email"
                                placeholder="Expected Time arrival"
                                type="date"
                                onChange={this.handleChange.bind(this)} 
                                value=""
                              />
                             
                            </Col>
                        </Row>
                        <br></br>
                          <Row>
                          <Col lg="6">
                     
                          <label
                            className="form-control-label"
                            htmlFor="input-email">Revised_time_arrival</label>
                            </Col>

                            <Col lg="6">
                             <input
                                className="form-control"
                                id="input-email"
                                placeholder="Revised_time_arrival"
                                type="date"
                                onChange={this.handleChange.bind(this)} 
                                value=""
                              />
                             
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                          <Col lg="6">
                     
                          <label
                            className="form-control-label"
                            htmlFor="input-email">Actual_time_arrival</label>
                            </Col>

                            <Col lg="6">
                             <input
                                className="form-control"
                                id="input-email"
                                placeholder="Actual_time_arrival"
                                type="date"
                                onChange={this.handleChange.bind(this)} 
                                value=""
                              />
                             
                            </Col>
                        </Row>

                   </ModalBody>
                   <ModalFooter>
                   <Button color="secondary" onClick={this.editdepartment}>Close</Button>
                   </ModalFooter>
            </Modal>

          <Modal style={{maxWidth: '400px', width: '100%'}} isOpen={this.state.modal} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>Document Upload </ModalHeader>
                <ModalBody>
                <Row style={{marginTop:"2px", marginBottom: "7px"}}>
                  <Col>
                  <input
                       className="form-control"
                       style={{width: "250px"}}
                       id={"exampleSelect" }
                       placeholder="https:// documents"
                       type="text">
                       </input>       
                  </Col>
                  <Col>
                  <Button  color="primary">View</Button>
                  </Col>
                  </Row>
                  <Row style={{marginTop:"2px", marginBottom: "7px"}}>
                  <Col>
                  <label className="text-white">Select File :</label>
                  <input type="file" className="form-control" name="upload_file" onChange={this.handleInputChange} />
                                   
                  </Col>
                  <Col>
                  <Button    color="primary"  >View</Button>
                  </Col>
                  </Row>
                  <Row style={{marginTop:"2px", marginBottom: "7px"}}>
                  <Col>
                  <input
                       className="form-control"
                       style={{width: "250px"}}
                       id={"exampleSelect" }
                       placeholder="https:// documents"
                       type="text">
                       </input>       
                  </Col>
                  <Col>
                  <Button    color="primary"  >View</Button>
                  </Col>
                  </Row>
                  <Row style={{marginTop:"2px", marginBottom: "7px"}}>
                  <Col>
                  <input
                       className="form-control"
                       style={{width: "250px"}}
                       id={"exampleSelect" }
                       placeholder="https:// documents"
                       type="text">
                       </input>       
                  </Col>
                  <Col>
                  <Button color="primary"  >View</Button>
                  </Col>
                  </Row>
   
                </ModalBody>  
                <ModalFooter>
                <Button  onClick={this.togglee } color="secondary" >Close</Button>
                </ModalFooter>         
            </Modal>  
          <Container fluid>
              <Card style={{margin: "5px"}}>
                <Card.Body>
                <h4><b>Shipment - Container Tracking</b></h4>
                  <Row style={{marginTop:"2px", marginBottom: "7px"}}>
               
                    {/* <Col><Form.Label className="name-label">Master Name</Form.Label></Col> */}
                     <Col className="text-left">
                      <Form.Label className="name-label">From  Date   </Form.Label>
                      <input  className="form-control" required="required" 
                      type="date" name="setup_name" onChange={this.handle_fromdate} 
                      value={this.state.from_date}/> 
                         
                    </Col> 

            

                    <Col className="text-left">
                      <Form.Label className="name-label">To Date   </Form.Label>
                      <input  className="form-control" required="required"   
                      type="date" name="setup_name" onChange={this.handle_todate} 
                      value={this.state.to_date}/>    
                    </Col> 
                    
                    <Col >
                  {localStorage.getItem('usertypecode') != 'CUST'?(<>
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                      <div>
                      <Typeahead
                                 id="exampleSelect" 
                                 style={{width: "490px"}}
                                selected={this.selected}
                                onChange={this.handle_cust.bind(this)}
                                 value={this.state.customer_name}
                                labelKey={option => `${option.customer_name} `}
                                options={this.state.cust_list}
                                placeholder="Select a customer..."
                              />
                      </div>
                  </>):(<>
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                      <div>

                      <input  className="form-control" required="required" 
                          placeholder=''
                          style={{width: "490px"}}
                          type="text" name="setup_name" readOnly ={true} value={this.state.get_cust_name}/> 
                      </div>
                  </>)}
                    
                    

                  </Col>
                    

                    <Col className="text-left">
                    <Form.Label className="name-label">Invoice No</Form.Label>
                    <input className="form-control" required="required"
                      type="number" name="setup_name" onChange={this.handle_invoice_no}
                      maxlength="7"
                      value={this.state.get_invoice_no} />
                  </Col>
                  <Col className="text-left">
                    <Button style={{ marginTop: 30 }} onClick={this.handle_show_invoice} color="primary"  >Display</Button>{' '}
                  </Col>
                        

                  </Row>
                </Card.Body>
              </Card>
          </Container>

          <div className="table p-2" style={{width:"99%",display:"inline-table", margin: "5px", backgroundColor: "#fff"}}>
          <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
                <Col className="text-left">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-success mb-3"
                    table="example"
                    filename="Invoice List"
                    sheet="tablexls"
                    buttonText="Download as XLS"
                  />
                </Col>
                  <Col className="text-left">
                  </Col>
                  <Col className="text-left">
                  </Col>
                  <Col className="text-left">
                  </Col>
                  <Col className="text-left">
                  </Col>
                <Col className="text-left">
                {this.state.total != null?(<Form.Label className="name-label">Total : {this.state.total}   </Form.Label>)
                :(<Form.Label className="name-label">Total :    </Form.Label>)}
                </Col>
          </Row>
                    <Table striped bordered  responsive="sm">
                      <thead style={{backgroundColor:"#413c69",color:"#ffffff"}}>
                        <tr>
                       
                          <th >#</th>
                          
                          <th style={{width: "150px"}}>Invoice Number</th>
                          <th style={{width: "300px"}}>Invoice Date</th>
                          <th style={{width: "150px"}}>Container Name</th>                          
                          <th style={{width: "150px"}}>Container Available Date</th>
                          <th style={{width: "200px"}}>Port of Shipment</th>
                          <th style={{width: "150px"}}>Ship loading Date  </th>                         
                          <th style={{width: "200px"}}>Shipment Agent</th> 
                          <th style={{width: "200px"}}>Shipping Line Name</th> 
                          <th style={{width: "150px"}}>Ship Start Date</th>
                          <th style={{width: "150px"}}>Expected time arrival</th>
                          <th style={{width: "150px"}}>Revised time arrival</th>
                          <th style={{width: "150px"}}>Actual time arrival</th>
                          <th style={{width: "50px"}}>Action</th>
                         
                        </tr>
                      </thead>
                      <tbody>
                      {get_cu_details.length ? 
                        get_cu_details.map((todo , id)=> (
                        <tr key ={id} >
                          <th style={{width: "50px"}}>{id +1}</th>
                          <th style={{width: "100px"}} >{todo.invoice_doc_no}</th>
                          <th ><input  className="form-control" required="required"  
                                type="text" name="setup_name" 
                                readOnly={true} 
                                style={{width: "150px"}}
                                value={moment(this.state.get_cu_details[id].invoice_date).format('YYYY-MM-DD')}/>
                                </th>
                          <th style={{width: "150px"}}>
                          <input  className="form-control" required="required"  
                                type="text" name="setup_name" 
                                readOnly={true} 
                                style={{width: "150px"}}
                                value={this.state.get_cu_details[id].container_name}/>
                          </th>
                         
                          <th style={{width: "180px"}} > <input  className="form-control" required="required"  
                              type="date" name="setup_name" onChange={this.handle_container_date.bind(this, id)} 
                              value={moment(this.state.get_cu_details[id].container_available_date).format('YYYY-MM-DD')}/>  </th>                          
                          <th> <input  className="form-control" required="required" 
                              placeholder='Port of Shipment'
                              style={{width: "200px"}}
                              type="text" name="setup_name" onChange={this.handle_port.bind(this, id)} value={this.state.get_cu_details[id].port_of_shipment}/>   </th>
                          <th> <input  className="form-control" required="required"   
                              type="date" name="setup_name" onChange={this.handle_shipment_date.bind(this, id)} 
                              style={{width: "180px"}} 
                              value={moment(this.state.get_cu_details[id].ship_loading_date).format('YYYY-MM-DD')}/></th>
                          <th><input  className="form-control" required="required" 
                              placeholder='Shipment Agent'
                              style={{width: "200px"}}
                              type="text" name="setup_name" onChange={this.handle_shipagent.bind(this, id)} value={this.state.get_cu_details[id].shipping_agent}/></th>
                          <th><input  className="form-control" required="required" 
                              placeholder='Shipment Agent'
                              style={{width: "200px"}} 
                              type="text" name="setup_name" onChange={this.handle_shipname.bind(this, id)} value={this.state.get_cu_details[id].shipping_line_name}/></th>
                          <th><input  className="form-control" required="required"  
                               style={{width: "180px"}} 
                              type="date" name="setup_name" onChange={this.handle_ship_date.bind(this, id)} 
                              value={moment(this.state.get_cu_details[id].ship_start_date).format('YYYY-MM-DD')}/> </th>
                          <th><input  className="form-control" required="required"   
                              type="date" name="setup_name" onChange={this.handle_expected.bind(this, id)} 
                              style={{width: "180px"}} 
                              value={moment(this.state.get_cu_details[id].expected_time_arrival).format('YYYY-MM-DD')}/></th>
                          <th><input  className="form-control" required="required"   
                              type="date" name="setup_name" onChange={this.handle_revised.bind(this, id)} 
                              style={{width: "180px"}} 
                              value={moment(this.state.get_cu_details[id].revised_time_arrival).format('YYYY-MM-DD')}/></th>
                          <th><input  className="form-control" required="required"   
                              type="date" name="setup_name" onChange={this.handle_actuval.bind(this, id)} 
                              style={{width: "180px"}} 
                              value={moment(this.state.get_cu_details[id].actual_time_arrival).format('YYYY-MM-DD')}/></th>
                         <th><Button variant = "success"  onClick={() => this.handle_save(id)}>Save</Button></th>
                          
                         
                         
                        </tr>
                        ))
                        : 
                        (<tr>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                         
                        </tr>)
                      } 
                       
                      </tbody>
                    </Table>

                    <div className='pagination' style={{backgroundColor: "white" , justifyContent: 'right'}}>
                    <span onClick={() => this.handle_show_invoice1(1)}>&laquo;</span>
                      {renderPageNumbers}
                    <span onClick={() => this.handleNextPage(this.state.current_page)}>&raquo;</span>
                  </div>  
                  </div>
                 
                
                    {/* <div className="text-center" style={{marginBottom: "50px"}}>
                    <Button variant = "success" 
                          style={{marginRight:"2%"}} 
                          onClick={(e) => { if (window.confirm("Are you sure save?")) this.submitsave(e) }}>Save</Button>
                     
                      <Button variant = "success" onClick={() => {window.location.href="/dashboard"}}>Exit</Button>      
                    </div> */}
                 
                    
                  <div><ToastContainer/></div>

          </div>
      )        
    }
  
  }
