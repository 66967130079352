import React from 'react';
import { Component} from 'react'
import { Table, Form } from 'react-bootstrap';
import {Button,Card, ProgressBar} from 'react-bootstrap';
import {  Grid } from 'semantic-ui-react';
import { Document, Page } from 'react-pdf';
import {Container } from 'react-bootstrap';
import {Row,Col } from 'react-bootstrap';
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Fab from '@material-ui/core/Fab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-select2-wrapper/css/select2.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import axios from 'axios';
import moment from 'moment'
import { Typeahead, TypeaheadInputMulti } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';


export default class FinanceManger extends Component {
  constructor(props){
    super(props)
    this.state = ({
      cust_list :[],
      order_add :[],
      get_cu_details :[],
      month_year :"",
      po_number :"",
      customer_name :"",
      customer_code :"",
      order_type :'',
      modal: false,
      from_date :'',
      to_date :"",
      fileInfos: [],
      pageNumber: 1,
      file_title: '',
      baseurl :  process.env['REACT_APP_API_URL_'+localStorage.getItem('segment') ],
     
    })

    this.handle_cust = this.handle_cust.bind(this);
    this.toggle = this.toggle.bind(this);
    this.togglee = this.togglee.bind(this);
    this.handle_approval = this.handle_approval.bind(this);
    this.toggleview = this.toggleview.bind(this);
    this.toggleeview= this.toggleeview.bind(this);
    
  }

  componentDidMount(){
    this.redirectLogin()

    localStorage.setItem("screen_zoom", "80%")
    document.body.style.zoom = localStorage.getItem("screen_zoom");
    
    const date = new Date();
    const futureDate = date.getDate() ;
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    this.state.from_date = defaultValue
    this.state.to_date = defaultValue
   
   
    if(localStorage.getItem('usertypecode') == 'CUST'){
      axios({
        method: "POST",
        url: this.state.baseurl +'custdetails' ,//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: { 
          user_code :  localStorage.getItem('empcode'),
          user_type_code :localStorage.getItem('usertypecode')
        }
      }).then((response) => {
        console.log("Test", response.data.data)
        this.setState({
          cust_list: response.data.data, // you have it as this.data not response
        });
      });
    }else{
      axios({
        method: "POST",
        url: this.state.baseurl +'custdetails' ,//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: { 
          user_code :  localStorage.getItem('empcode'),
          user_type_code :localStorage.getItem('usertypecode')
        }
      }).then((response) => {
        console.log("Test", response.data.data)
        this.setState({
          cust_list: response.data.data, // you have it as this.data not response
        });
      });
    }
  }

 
  redirectLogin = () => {
    if(localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined || localStorage.getItem("token") == null){
        localStorage.setItem('token', '')
        window.location.href = "/login"
    } 
  }
  
 

    // handle customer name 
    
    handle_cust =(selected)=>{
      var cuname = "";
      var cuscode ='';
      for (var i = 0; i < selected.length; i++) {
        cuname = selected[i].customer_name;
        cuscode =  selected[i].customer_sap_code
      }
      this.setState({
        customer_name:cuname,
        customer_code :cuscode
      })
    
      axios({
        method: "POST",
        url: this.state.baseurl + 'prehdf_sales',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: { 
          customer_sap_code: cuscode,
          fromdate:this.state.from_date,
          todate:this.state.to_date
        }
      }).then((response) => {
        console.log("MANI", response.data.data)
        this.setState({
          get_cu_details: response.data.data,
        });
      });
      
    }



    handle_approval =(id,e)=>{

      var items = this.state.get_cu_details;
      items[id].pi_approved_by_sm_status = 'Y'
  
      this.setState({
        get_cu_details: items
      });
    }
  
       // handle Month and year  
    handle_month_year =(e)=>{
      this.setState({
        month_year : e.target.value
      })
    }

    handle_from_date=(e)=>{
      this.setState({
        from_date : e.target.value
      })
    }

    handle_to_date=(e)=>{
      this.setState({
        to_date : e.target.value
      })
      axios({
        method: "POST",
        url: this.state.baseurl + 'prehdf_sales',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: { 
          customer_sap_code: this.state.customer_code,
          fromdate :this.state.from_date,
          todate : this.state.to_date,
          pi_approved_by_sm_status :e.target.value
        }
      }).then((response) => {
        console.log("MANI", response.data.data)
        this.setState({
          get_cu_details: response.data.data,
        });
      });
    }

    handle_ordertype=(e)=>{
      
      this.setState({
        order_type : e.target.value
      })

      axios({
        method: "POST",
        url: this.state.baseurl + 'prehdfstatus_sales',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: { 
          customer_sap_code: this.state.customer_code,
          fromdate :this.state.from_date,
          todate : this.state.to_date,
          pi_approved_by_sm_status :e.target.value
        }
      }).then((response) => {
        console.log("MANI", response.data.data)
        this.setState({
          get_cu_details: response.data.data,
        });
      });
    }

    submitsave =(event) =>{
      event.preventDefault();
    

      console.log("details ", this.state.get_cu_details)
      axios({
        method: "POST",
        url: this.state.baseurl + 'update_approval',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: { 

          p_prepi_details: JSON.stringify({"items" : this.state.get_cu_details})
         
        }
      }).then((response) => {
        let res = response.data.message
        this.setState({
          order_type: response.data.data, // you have it as this.data not response
        });
        var getres =''
        for (var i =0; i< res.length ;i++){
          getres = res[i].fi01_prepi_sm_approval
        }
        var gtres = getres.substring(0,1)
        if(gtres == "R"){
          this.notify(getres)
        }else{
          window.confirm(getres)
        }
      });

    }

  

   notify = (getres) => {
        toast.success(getres, {
            position: toast.POSITION.TOP_CENTER
        });
    };
    
    errorNotify = (message) => {
        toast.error(message, {
            position: toast.POSITION.TOP_CENTER
        });
    }


    toggle =(todo) => {
      this.setState(prevState => ({
          modal: !prevState.modal
      }));    

      axios({
        method: "POST",
        url: this.state.baseurl + 'uploadshow',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: { 
          order_no:todo.order_no
        }
      }).then((response) => {
        console.log("GET", response.data.data)
        this.setState({
          fileInfos: response.data.data, // you have it as this.data not response
        });
      });
    }
 
    togglee =() => {
      this.setState(prevState => ({
          modal: !prevState.modal
      }));    
    }
 
    toggleview =(file) => {
      
      this.setState(prevState => ({
          view: !prevState.view,
          file_title : file.title
      }));  
     
    }

    
    toggleeview =() => {
      this.setState(prevState => ({
          view: !prevState.view
      }));    
    }
  
  

    render(){
      const {  get_cu_details =[], get_order =[], orderaddmodel =[], get_wishlist =[]}= this.state

      const { pageNumber, numPages } = this.state;
      const {  fileInfos, file_title } = this.state;
      var pdf_urlpath =  process.env.REACT_APP_API_URL_FILE + "download_aws?type=POPDF&key="; 
    
      return(
        <div>
          
          <Modal style={{maxWidth: '400px', width: '100%'}} isOpen={this.state.modal} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>Document List </ModalHeader>
                <ModalBody>
                <Row style={{marginTop:"2px", marginBottom: "7px"}}>
                  <Col>
                  <div className="card">
                    <div className="card-header">List of Files</div>
                    <ul className="list-group list-group-flush">
                      {fileInfos &&
                        fileInfos.map((file, index) => (
                          <li className="list-group-item" key={index}>
                            <Row>
                            <Col>
                               <a href={file.url}>{file.title}</a>
                            </Col>
                            <Col>
                              <Fab  size="small" color="primary">
                                  <VisibilityIcon  onClick={()=>this.toggleview(file)} size="small"/>
                              </Fab>
                              </Col>
                            </Row>
                           
                           
                          </li>
                        ))}
                    </ul>
                  </div>
                  
                  </Col>

                </Row>
   
                </ModalBody>  
                <ModalFooter>
                <Button  onClick={this.togglee } color="secondary" >Close</Button>
                </ModalFooter>         
          </Modal>  

          <Modal style={{maxWidth: '900px', width: '100%'}} isOpen={this.state.view} toggle={this.toggleview}>
                <ModalHeader toggle={this.toggleview}>Document View </ModalHeader>
                <ModalBody>

             
                <Grid centered columns={2}>
                  <Grid.Column textAlign="center" onClick={this.nextPage}>
                  <Document
                    file={pdf_urlpath + file_title}
                    onLoadSuccess={this.onDocumentLoadSuccess} noData={<h4></h4>}>
                        <Page pageNumber={pageNumber}  />
                    </Document>
                  
                    {this.state.featured_image ? <p>Page {pageNumber} of {numPages}</p> : null}
                  </Grid.Column>
                </Grid>
                </ModalBody>  
                <ModalFooter>
                <Button  onClick={this.toggleeview } color="secondary" >Close</Button>
                </ModalFooter>         
            </Modal>  
          <Container fluid>
              <Card style={{margin: "5px"}}>
                <Card.Body>
                  <Row style={{marginTop:"2px", marginBottom: "7px"}}>
                  <Col >
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                      <div>
                      <Typeahead
                                 id="exampleSelect" 
                                 style={{width: "490px"}}
                                selected={this.selected}
                                onChange={this.handle_cust.bind(this)}
                                 value={this.state.customer_name}
                                labelKey={option => `${option.customer_name} `}
                                options={this.state.cust_list}
                                placeholder="Select a customer..."
                              />
                      </div>
                    

                  </Col>
                    
                 
                     <Col className="text-left">
                    <Form.Label className="name-label">From Date</Form.Label>
                      <input  className="form-control" required="required" 
                      type="date" name="setup_name" 
                      value ={this.state.from_date}
                      onChange={this.handle_from_date}
                       />    
                      
                     </Col> 
                    <Col className="text-left">
                    <Form.Label className="name-label">To Date</Form.Label>
                      <input  className="form-control" required="required" 
                      type="date" name="setup_name" 
                      value ={this.state.to_date}
                      onChange={this.handle_to_date}
                       />    
                      
                   
                    </Col> 
                    
                    <Col className="text-left">
                    <div className="text-left">
                      <Form.Label className="name-label" >Select Status</Form.Label>
                    </div>
                    
                  
                      <div>
                        <select  className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`}
                         defaultChecked={this.state.master_type} value={this.state.order_type} onChange={this.handle_ordertype}>
                          <option>Select Status</option>
                          <option value="A">All</option>
                          <option value="N">Pending</option>
                          <option value="Y">Completed</option>
                        </select>
                      </div>    
                    </Col>   

                      <Col className="text-left">
                     </Col> 
                 
                        

                  </Row>
                </Card.Body>
              </Card>
          </Container>

          <div className="table" style={{width:"99%",display:"inline-table", margin: "5px", backgroundColor: "#fff"}}>
                    <Table striped bordered  responsive="sm">
                      <thead style={{backgroundColor:"#413c69",color:"#ffffff"}}>
                        <tr>
                          <th style={{width: "20px"}}>#</th>
                          <th style={{width: "150px"}}>Order Ref No</th>
                          <th style={{width: "150px"}}>Order Ref Date</th>
                          <th style={{width: "100px"}}>SAP PI Reference</th>
                          <th style={{width: "100px"}}>PI Date</th>
                          <th style={{width: "100px"}}>PI Value</th>
                          <th style={{width: "80px"}}>PI Approval by SM</th>
                          <th style={{width: "80px"}}>PI Approval by Finance</th>
                          <th style={{width: "100px"}}>LC View</th>
                         
                        </tr>
                      </thead>
                      <tbody>
                      {get_cu_details.length ? 
                        get_cu_details.map((todo , id)=> (
                        <tr key ={id} >
                          <th>{id +1}</th>
                          <th>{todo.order_no}</th>
                          <th>{todo.order_mmyyyy}</th>
                          <th>{todo.pi_number}</th>
                          <th>{moment(todo.pi_date).format('DD-MM-YYYY')}</th>
                          <th>{todo.pi_value}</th>
                          <th>{todo.pi_approved_by_sm_status == "Y"?("Approved"):("Pending")}</th>
                          <th>{todo.pi_approved_by_finance_status == "N"?(<>
                            <input   
                           style={{marginLeft :15, marginTop:15}} on  type="checkbox"
                           onChange={this.handle_approval.bind(this, id)}
                            />
                          </>):(<>
                            <input   
                           style={{marginLeft :15, marginTop:15}} on  type="checkbox"
                           defaultChecked="true"
                           onChange={this.handle_approval.bind(this, id)}
                            />
                          </>)}
                              </th>
                          <th>
                          <Button 
                          style={{marginRight:"2%"}} 
                          onClick={() => this.toggle(todo)}
                           >View to doc </Button>
                          </th>
                         
                        </tr>
                        ))
                        : 
                        (<tr>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                        
                        </tr>)
                      } 
                       
                      </tbody>
                    </Table>
                  </div>

                
                    <div className="text-center" style={{marginBottom: "50px"}}>
                    <Button variant = "success" 
                          style={{marginRight:"2%"}} 
                          onClick={(e) => { if (window.confirm("Are you sure save?")) this.submitsave(e) }}>Save</Button>
                     
                      <Button variant = "success" onClick={() => {window.location.href="/dashboard"}}>Exit</Button>      
                    </div>
                 
                    
                  <div><ToastContainer/></div>

          </div>
      )        
    }
  
  }


 