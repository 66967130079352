import React from 'react';
import { Component } from 'react'
import { Table, Form, Alert } from 'react-bootstrap';
import { Button, Card, ProgressBar, Modall } from 'react-bootstrap';
import { Document, Page, } from 'react-pdf';
import { Container } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CalculateIcon from '@material-ui/icons/Add';
import AddIcon from '@material-ui/icons/Add';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ListIcon from '@material-ui/icons/List';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import moment from 'moment'
import Fab from '@material-ui/core/Fab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-select2-wrapper/css/select2.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import axios from 'axios';
import { Typeahead, TypeaheadInputMulti } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import tyres from '../../images/tyres.jpg';
import 'uikit/dist/css/uikit.css';
import ReactTooltip from "react-tooltip";
import Select from 'react-select';
import * as XLSX from 'xlsx';



import calImage from '../../images/OrderBookingTemplate.xlsx';


/*
  Spec details
*/

import { slideDown, slideUp } from "./anim";


function capitalize(str) {
  return str
    .split(" ")
    .map((s) => {
      return s.charAt(0).toUpperCase() + s.substr(1);
    })
    .join(" ");
}


class UserTableRow extends React.Component {
  state = { expanded: false };

  toggleExpander = (e) => {
    if (e.target.type === "checkbox") return;

    if (!this.state.expanded) {
      this.setState({ expanded: true }, () => {
        if (this.refs.expanderBody) {
          slideDown(this.refs.expanderBody);
        }
      });
    } else {
      slideUp(this.refs.expanderBody, {
        onComplete: () => {
          this.setState({ expanded: false });
        }
      });
    }
  };

  render() {
    const { user } = this.props;

    return [
      <tr key="main" onClick={this.toggleExpander}>
        <td>
          <input className="uk-checkbox" type="checkbox" />
        </td>
        <td className="uk-text-nowrap" style={{ fontSize: "xx-large" }}>{this.props.index}.</td>
        <td>
          <img
            className="uk-preserve-width uk-border-circle"
            src={user.thumbnail}
            width={60}
            alt="avatar"
          />
        </td>
        <td style={{ fontSize: "xx-large" }}>{capitalize(user.product_desc)}</td>
        <td style={{ fontSize: "xx-large" }}>{capitalize(user.product_code)}</td>
      </tr>,
      this.state.expanded && (
        <tr className="expandable" key="tr-expander">
          <td className="uk-background-muted" colSpan={6}>
            <div ref="expanderBody" className="inner uk-grid">
              <div className="uk-width-1-2">
                <h3 style={{ textAlign: "center", color: "#0054A6" }}>
                  Specification
                </h3>

                <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
                  <thead>
                    <tr>
                      <td> Tubing</td>
                      <td> Hello 2</td>
                    </tr>
                    <tr>
                      <td> Wheel Position</td>
                      <td> Hello 2</td>
                    </tr>
                    <tr>
                      <td> Aspect Ratio</td>
                      <td> Hello 2</td>
                    </tr>
                    <tr>
                      <td> RIM Diameter</td>
                      <td> Hello 2</td>
                    </tr>

                    <tr>
                      <td> Load Index</td>
                      <td> Hello 2</td>
                    </tr>

                    <tr>
                      <td> Speed Index</td>
                      <td> Hello 2</td>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
              <div className="uk-width-1-4 uk-text-center">
                <img
                  className="uk-preserve-width uk-border-circle"
                  src={user.thumbnail}
                  width={300}
                  alt="avatar"
                />
              </div>
            </div>
          </td>
        </tr>
      )
    ];
  }
}


export default class PrePIOrderMaster extends Component {
  constructor(props) {
    super(props)
    this.state = ({
      cust_list: [],
      order_add: [],
      get_cu_details: [],
      month_year: "",
      po_number: "",
      customer_name: "",
      customer_code: "",
      customer_id: "",
      country: '',
      pay_term: "",
      po_date: "",
      curreny: "",
      incotern: "",
      port_delivery: "",
      container_size: "",
      notify: "",
      order_type: "",
      rows: [],
      orderadd: [],
      nofify_code: '',

      segment: [],
      product: [],
      prouct_name: [],

      add_segment: '',
      add_size: '',
      add_pname: '',
      add_pcode: '',
      add_price: '',
      add_oqty: '',
      add_tqty: 0,
      add_bqty: 0,
      add_value: '',
      volume_occupied: 0,
      container: [],
      portof: [],
      order_insert: [],
      showHide: false,
      get_order: [],
      modal: false,
      modalcust: false,
      modaladd: false,
      modelitem: false,
      modelkititem: false,
      modelpdf: false,
      get_order_no: [],
      get_order_verion: [],
      order_number: '',
      order_version: '1',
      getohtordertype: '',

      cust_name_model: "",
      month_year_model: "",
      orderaddmodel: [],
      model_size: '',
      add_get_size: [],
      add_get_size1: [],
      add_getkit_size: '',
      addsizeget: [],
      load_size: [],
      modalwish: false,
      get_wishlist: [],
      total_pi_qty: '',
      get_month_order: [],
      get_order_s: [],
      get_qty_master: [],
      numPages: null,
      pageNumber: 1,
      viewpdf: '',
      dtlsre: '',
      pinumber: '',
      get_order: '',
      get_version: '',
      get_order_verion1: [],
      displaycontainercapper: '0.0',
      edit: '',
      get_tot: 0,
      get_conta: 0,
      concapqty: '',
      pistatus: '',
      action_status: '',
      fileInfos: [],
      file_title: '',
      gorderno: '',
      modalwishview: false,
      excel_upload: false,
      produdct: '',
      get_wishlistview: [],
      get_wishdesc: [],
      isChecked: false,
      getwishstatus: 'Y',
      checked: false,
      checkedview: true,
      find_search_code: '',
      price_date: '',
      getprice_date: '',
      product_view: [],
      find_pr_code: '',
      find_pr_desc: '',
      product_list: false,
      product_view_model: false,
      deleted_ids: [],
      deleted_ids_kit: [],
      viewlist: [],
      vichecked: false,
      get_cust_name: '',
      get_cust_code: '',
      notychecked: false,
      po_number: '',
      po_date: '',
      add_price_date: '',
      pi_qty: 0,

      piapprovedstatus: '',
      get_container: '',

      gettypesegment: 'OHT',

      shipement_type: '',
      portofdischarge: '',
      freight: 0,
      dateofship: '',
      insurance: 0,
      discount: 0,
      notifyinfo: '',
      notifyinfo1: '',
      notifyinfo2: '',
      notifyinfo3: '',
      notifyinfo4: '',
      incoternlocation: '',
      shipdate: '',
      notifyparty: '',
      baseurl: process.env['REACT_APP_API_URL_' + localStorage.getItem('segment')],
      pistatuserror: '',
      remarkes: '',
      remarkes1: '',
      payment_term: [],
      inco_term: [],
      countryoffindes: '',
      notifymaster: [],
      notifymastername: '',
      notifymastercode: '',
      filler_item: '',
      getkit_product_list: [],
      getkit_product_desc: [],
      getkit_product_tube: [],
      getkitcode: '',
      getprice_date: '',
      getkitpcode: '',
      getkitpdesc: '',
      getkitpricedate: '',
      getkitqty: 0,
      users: [
        {
          id: 2423,
          segment_code: "",
          segment_desc: "",
          product_group: "50",
          product_code: "3811050005016",
          product_desc: "50 x M16 BALL KNOB WITH MOULDED THREAD",
          product_type: "",
          product_set: "",
          tyre_code: "",
          tyre_desc: "",
          tube_code: "",
          tube_desc: "",
          flap_code: "",
          flap_desc: "",
          currency: "",
          price: "0.0",
          status: "Y",
          created_at: "2022-02-24T17:37:53.442+05:30",
          modified_at: "2022-02-24T17:37:53.442+05:30",
          weight: "84.01",
          thumbnail:
            "https://www.tvseurogrip.com/wp-content/uploads/2021/09/80_100-17-54P-REINF.-TEG-SPORTORQ-QR-TL-Lt.png",
          specs: {
            tubing: "TL",
            size: "100/80-12",
            wheel_position: "Front",
            rim_diameter: "12"
          }
        },
        {
          id: 2424,
          segment_code: "",
          segment_desc: "",
          product_group: "32x1/4-20x3/8",
          product_code: "3811320100046",
          product_desc: "32x1/4-20x3/8 STAR KNOB WT BRASS INSERT",
          product_type: "",
          product_set: "",
          tyre_code: "",
          tyre_desc: "",
          tube_code: "",
          tube_desc: "",
          flap_code: "",
          flap_desc: "",
          currency: "",
          price: "0.0",
          status: "Y",
          created_at: "2022-02-24T17:37:53.442+05:30",
          modified_at: "2022-02-24T17:37:53.442+05:30",
          weight: "12.3",
          thumbnail:
            "https://www.tvseurogrip.com/wp-content/uploads/2021/04/TVS-Remora-ATT-455R-Lt-2-1.jpg",
          specs: {
            tubing: "TL",
            size: "100/80-12",
            wheel_position: "Front",
            rim_diameter: "12"
          }
        }
      ],
      piapprovedstatus: '',

      getproduct_code: [],
      excel_file: "",
      excel_upload_error: '',
      excel_error: [],
      country_master: [],
      getkititem: '',
      sonumber: '',
      gettubecode: '',
      gettubedesc: '',
      gettubeprice: '',
      delget_tot: 0,
      delget_conta: 0.0,
      deldisplaycontainercapper: 0,
      get_price_date_list: [],
      get_bom_no: [],
      select_bom: '',
      wish_kit_bom: [],
      kit_status: "",
      getlist: [],
      bomn1: [],
      kit_grid_item: [],
      portofloading: 'TUTICORIN PORT, INDIA',
      payment_term_code: '',
      grid_price_date: '',
      ad_code: '0009999',
      validto: '',

    })

    this.handle_cust = this.handle_cust.bind(this);
    this.handle_getproduct_code = this.handle_getproduct_code.bind(this);
    this.handle_getproduct_name = this.handle_getproduct_name.bind(this);
    this.handle_getkitproduct_code = this.handle_getkitproduct_code.bind(this);
    this.handle_getkitproduct_name = this.handle_getkitproduct_name.bind(this);
    //this.handle_segment = this.handle_segment.bind(this);
    this.handle_productsize = this.handle_productsize.bind(this);
    this.handle_productname = this.handle_productname.bind(this);
    this.handle_dateprice = this.handle_dateprice.bind(this);
    this.handle_qty = this.handle_qty.bind(this);
    this.handle_tqty = this.handle_tqty.bind(this);
    this.handle_bqty = this.handle_bqty.bind(this);
    this.handle_containter = this.handle_containter.bind(this);
    // this.handle_cust_name = this.handle_cust_name.bind(this);
    this.toggle = this.toggle.bind(this);
    //this.handle_size = this.handle_size.bind(this);
    this.togglee = this.togglee.bind(this);
    this.toggleadd = this.toggleadd.bind(this);
    // this.toggleeadd = this.toggleeadd.bind(this);

    this.handle_order_size = this.handle_order_size.bind(this);

    this.toggleewish = this.toggleewish.bind(this);
    this.togglewish = this.togglewish.bind(this);
    this.handle_total_piqty = this.handle_total_piqty.bind(this);


    this.toggleitem = this.toggleitem.bind(this);
    this.toggleeitem = this.toggleeitem.bind(this);

    this.togglekititem = this.togglekititem.bind(this);
    this.toggleekititem = this.toggleekititem.bind(this);
    this.toggleekititemclose = this.toggleekititemclose.bind(this);

    this.toggleview = this.toggleview.bind(this);
    this.toggleeview = this.toggleeview.bind(this);
    this.calculation = this.calculation.bind(this);

    this.toggleewishview = this.toggleewishview.bind(this);
    this.togglewishview = this.togglewishview.bind(this);
    this.toggleewishviewclose = this.toggleewishviewclose.bind(this);

    this.handle_find = this.handle_find.bind(this);

    this.toggleeproduct = this.toggleeproduct.bind(this);
    this.toggleepadd = this.toggleepadd.bind(this);
    this.toggleepclose = this.toggleepclose.bind(this);

    this.toggleeproductview = this.toggleeproductview.bind(this);
    this.toggleepv_add = this.toggleepv_add.bind(this);
    this.toggleepv_close = this.toggleepv_close.bind(this);
    this.toggleexcel = this.toggleexcel.bind(this);
    this.toggleeexcel = this.toggleeexcel.bind(this);


    this.handle_product_code = this.handle_product_code.bind(this)
    this.handle_product_desc = this.handle_product_desc.bind(this)

  }

  componentDidMount() {
    localStorage.setItem("screen_zoom", "80%")
    document.body.style.zoom = localStorage.getItem("screen_zoom");

    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    this.state.price_date = defaultValue

    this.state.add_price_date = defaultValue
    this.state.po_date = defaultValue
    this.state.shipdate = defaultValue
    this.state.getkitpricedate = defaultValue
    this.state.grid_price_date = defaultValue


    this.state.ad_code = '0009999'

    var gemonth = date.getUTCMonth() + 1; //months from 01-12

    var targetDate = new Date();
    targetDate.setDate(targetDate.getDate() + 60);
    console.log("Manidate", targetDate.toLocaleDateString('en-CA'))
    if (localStorage.getItem('segment') == 'OHT') {
      this.state.validto = targetDate.toLocaleDateString('en-CA')
    } else {
      this.state.validto = defaultValue
    }

    var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    var d = new Date();
    var monthName = months[d.getMonth()];
    var year = date.getUTCFullYear();
    const newdate = year + "-" + monthName;
    this.state.month_year = newdate
    console.log("CURRENTDATE", newdate)


    this.redirectLogin()

    const month = date.toLocaleString('default', { month: 'long' });
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    console.log("Manidate", '"' + moment(lastDay).format('DD') + 'th"' + month)

    //var base_url = process.env['REACT_APP_API_URL_'+localStorage.getItem('segment') ]

    // alert(this.state.baseurl)

    // axios.get(this.state.baseurl +'listproductdescview').then(response => {
    axios.get(this.state.baseurl + 'listproductdescview', {
      'headers': {
        'token': localStorage.getItem('token')
      }
    }).then(response => {
      this.setState({
        segment: response.data.data, // you have it as this.data not response
      });
    });

    // axios.get(this.state.baseurl +'productmasters').then(response => {
    axios.get(this.state.baseurl + 'productmasters', {
      'headers': {
        'token': localStorage.getItem('token')
      }
    }).then(response => {
      this.setState({
        product_view: response.data.data, // you have it as this.data not response
      });
    });

    axios.get(this.state.baseurl + 'containermasters', {
      'headers': {
        'token': localStorage.getItem('token')
      }
    }).then(response => {
      this.setState({
        container: response.data.data, // you have it as this.data not response
      });
    });

    if (localStorage.getItem('usertypecode') != 'CUST') {

      axios.get(this.state.baseurl + 'paymentterm', {
        'headers': {
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        console.log("Manipayment", response.data.data[0].tag_value)
        this.setState({
          payment_term: response.data.data, // you have it as this.data not response
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'getordertype',//"http://localhost:3300/listproduct",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            'tag_value': response.data.data[0].tag_value,
          }
        }).then((response) => {
          console.log("maniadditem", response.data.data)
          this.setState({
            order_type: response.data.data[0].tag_type
          });
        });

      });
    }

    if (localStorage.getItem('usertypecode') != 'CUST') {
      axios.get(this.state.baseurl + 'incoterm', {
        'headers': {
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        console.log("Maniincopayment", response.data.data)
        this.setState({
          inco_term: response.data.data, // you have it as this.data not response
        });
      });
    }

    axios.get(this.state.baseurl + 'country_list', {
      'headers': {
        'token': localStorage.getItem('token')
      }
    }).then(response => {
      console.log("Country", response.data.data)
      this.setState({
        country_master: response.data.data, // you have it as this.data not response
      });
    });

    axios.get(this.state.baseurl + 'getkit_product_list', {
      'headers': {
        'token': localStorage.getItem('token')
      }
    }).then(response => {
      console.log("kit code", response.data.data)
      this.setState({
        getkit_product_list: response.data.data, // you have it as this.data not response
      });
    });

    axios.get(this.state.baseurl + 'getkit_product_list_tube', {
      'headers': {
        'token': localStorage.getItem('token')
      }
    }).then(response => {
      console.log("tube", response.data.data)
      this.setState({
        getkit_product_tube: response.data.data, // you have it as this.data not response
      });
    });

    axios.get(this.state.baseurl + 'getkit_product_desc', {
      'headers': {
        'token': localStorage.getItem('token')
      }
    }).then(response => {
      console.log("kit desc", response.data.data)
      this.setState({
        getkit_product_desc: response.data.data, // you have it as this.data not response
      });
    });


    axios({
      method: "POST",
      url: this.state.baseurl + 'uploadshow',//"http://localhost:3000/uploadshow",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        order_no: '2202160001'
      }
    }).then((response) =>

      this.setState({ todos: response.data }),

    );



    if (localStorage.getItem('usertypecode') == 'CUST') {
      axios({
        method: "POST",
        url: this.state.baseurl + 'custdetails',//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          user_code: localStorage.getItem('empcode'),
          user_type_code: localStorage.getItem('usertypecode')
        }
      }).then((response) => {
        console.log("custmani", response.data.data[0].payment_term)
        this.setState({
          get_cust_name: response.data.data[0].customer_name,
          get_cust_code: response.data.data[0].customer_sap_code,
          customer_code: response.data.data[0].customer_sap_code,
          curreny: response.data.data[0].currency,
          incotern: response.data.data[0].inco_term,
          pay_term: response.data.data[0].payment_term
        });

        let cust_code = response.data.data[0].customer_sap_code

        axios({
          method: "POST",
          url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            tag_value: response.data.data[0].payment_term,
          }
        }).then((response) => {
          console.log("Testpayment", response.data.data)
          this.setState({
            payment_term: response.data.data[0].payment_term,
            payment_term_code: response.data.data[0].tag_desc
          })
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'getordertype',//"http://localhost:3300/listproduct",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            'tag_value': response.data.data[0].payment_term,
          }
        }).then((response) => {
          console.log("maniadditem", response.data.data)
          this.setState({
            order_type: response.data.data[0].tag_type
          });
        });


        axios({
          method: "POST",
          url: this.state.baseurl + 'showorder_list',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            customer_sap_code: cust_code,
            order_mmyyyy: this.state.month_year,
          }
        }).then((response) => {
          console.log("Order", response.data.data[0])
          this.setState({
            get_order_no: response.data.data,
            order_number: response.data.data[0],
          });
          axios({
            method: "POST",
            url: this.state.baseurl + 'showversion_list',//"http://localhost:3300/custdetails",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
            },
            data: {
              customer_sap_code: cust_code,
              order_mmyyyy: this.state.month_year,
              order_no: response.data.data[0]
            }
          }).then((response) => {
            console.log("version", response.data.data[0])
            this.setState({
              order_version: response.data.data[0],
              get_order_verion: response.data.data,
            });
            axios({
              method: "POST",
              url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
              },
              data: {
                customer_sap_code: cust_code,
                order_mmyyyy: this.state.month_year,
                order_no: this.state.order_number,
                order_version: this.state.order_version
              }
            }).then((response) => {
              console.log("Customer List123", response.data.hdrs)
              var get_cu = response.data.hdrs
              var get_tag = ''
              if (this.state.order_number == 'New') {

                const date = new Date();
                const defaultValue = date.toLocaleDateString('en-CA');
                this.state.pistatus = 'Open'
                this.state.action_status = 'New'
                this.state.get_tot = '0'
                this.state.displaycontainercapper = 0
                this.state.get_conta = 0
                this.state.po_number = ''
                this.state.pinumber = ""
                this.state.po_date = defaultValue
                this.state.sonumber = ''
                this.state.notifymastercode = ''

                for (var j = 0; j < get_cu.length; j++) {
                  console.log("Manitag", get_cu[j].payment_term)
                  this.state.curreny = get_cu[j].order_category
                  //    this.state.pay_term = get_cu[j].payment_term;
                  // get_tag = get_cu[j].payment_term;
                  this.state.incotern = get_cu[j].inco_term;
                  this.state.port_delivery = get_cu[j].port_of_discharge;
                  this.state.customer_code = get_cu[j].customer_sap_code;
                  this.state.customer_id = get_cu[j].customer_id
                  // this.state.order_type = get_cu[j].order_type
                  this.state.container_size = get_cu[j].container_type
                  this.state.po_number = get_cu[j].po_number
                  this.state.pistatus = "Open"
                  this.state.pinumber = ""
                  this.state.container_size = get_cu[j].container_type
                  this.state.notes = get_cu[j].notes
                  this.state.priority_item = get_cu[j].priority_item
                  this.state.final_desc = get_cu[j].final_destination
                  this.state.displaycontainercapper = ""
                  this.state.get_tot = ""
                  this.state.get_conta = ""
                  this.state.action_status = ""
                  this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
                  this.state.portofdischarge = get_cu[j].port_of_discharge
                  this.state.shipement_type = get_cu[j].precarriage_by
                  this.state.remarkes = get_cu[j].remarks_quo
                  this.state.remarkes1 = get_cu[j].remarks_so
                  this.state.discount = get_cu[j].discount
                  this.state.insurance = get_cu[j].insurance
                  this.state.freight = get_cu[j].freight
                  this.state.incoternlocation = get_cu[j].inco_term_location
                  this.state.priority_item = get_cu[j].priority_item
                  this.state.notifyparty = get_cu[j].notify_party_info1
                  this.state.notifyinfo = get_cu[j].notify_party_info1
                  this.state.notifyinfo1 = get_cu[j].notify_party_info2
                  this.state.notifyinfo2 = get_cu[j].notify_party_info3
                  this.state.notifyinfo3 = get_cu[j].notify_party_info4
                  this.state.notifyinfo4 = get_cu[j].notify_party_info5
                  this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
                  this.state.filler_item = get_cu[j].filler_item
                  //  this.state.notifymastercode = get_cu[j].notifyparty_sap_code
                  if (get_cu[j].ad_code != null) {
                    this.state.ad_code = get_cu[j].ad_code
                  } else {
                    this.state.ad_code = "0009999"
                  }

                  //  this.state.validto = defaultValue

                  this.state.notychecked = false



                }

              } else {
                for (var j = 0; j < get_cu.length; j++) {
                  this.state.curreny = get_cu[j].order_category
                  // this.state.pay_term = get_cu[j].payment_term;
                  // get_tag = get_cu[j].payment_term;
                  this.state.incotern = get_cu[j].inco_term;
                  this.state.port_delivery = get_cu[j].port_of_discharge;
                  this.state.customer_code = get_cu[j].customer_sap_code;
                  this.state.customer_id = get_cu[j].customer_id
                  // this.state.order_type = get_cu[j].order_type
                  this.state.container_size = get_cu[j].container_type
                  this.state.po_number = get_cu[j].po_number
                  this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
                  this.state.pistatus = get_cu[j].pi_status
                  if (get_cu[j].pi_number == null) {
                    this.state.pinumber = ""
                  } else {
                    this.state.pinumber = get_cu[j].pi_number
                  }
                  this.state.container_size = get_cu[j].container_type
                  this.state.notes = get_cu[j].notes
                  this.state.priority_item = get_cu[j].priority_item
                  this.state.final_desc = get_cu[j].final_destination
                  this.state.displaycontainercapper = get_cu[j].container_capacity
                  this.state.get_tot = get_cu[j].total_order_qty
                  this.state.get_conta = get_cu[j].no_of_containers
                  this.state.action_status = get_cu[j].action_status
                  this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
                  this.state.portofdischarge = get_cu[j].port_of_discharge
                  this.state.shipement_type = get_cu[j].precarriage_by
                  this.state.remarkes = get_cu[j].remarks_quo
                  this.state.remarkes1 = get_cu[j].remarks_so
                  this.state.discount = get_cu[j].discount
                  this.state.insurance = get_cu[j].insurance
                  this.state.freight = get_cu[j].freight
                  this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                  this.state.incoternlocation = get_cu[j].inco_term_location
                  this.state.priority_item = get_cu[j].priority_item
                  this.state.notifyinfo = get_cu[j].notify_party_info1
                  this.state.notifyinfo1 = get_cu[j].notify_party_info2
                  this.state.notifyinfo2 = get_cu[j].notify_party_info3
                  this.state.notifyinfo3 = get_cu[j].notify_party_info4
                  this.state.notifyinfo4 = get_cu[j].notify_party_info5
                  this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
                  this.state.filler_item = get_cu[j].filler_item
                  this.state.notifymastercode = get_cu[j].notifyparty_sap_code
                  if (get_cu[j].notifyparty_sap_code != "") {
                    this.state.notychecked = true
                  } else {
                    this.state.notychecked = false
                  }

                }

                axios({
                  method: "POST",
                  url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    tag_value: get_tag,

                  }
                }).then((response) => {
                  // console.log("Test", response.data.data[0].tag_desc)
                  this.setState({
                    payment_term_code: response.data.data[0].tag_desc
                  })
                });


                console.log("Maniwish05 ", response.data.data)
                const wishlist = response.data.data;
                const getlist = []
                let piqty = '0';
                let pi_value = "0";
                let price_date = this.state.price_date
                let wi_prtype = ''
                let wi_pcode = ''
                let segment_desc = ""
                let segment_code = ""
                let product_group = ""
                let product_desc = ""
                let product_code = ""
                let price = ""
                let pi_qty = ""
                let container_capacity_qty = ""
                let container_capacity = ""
                let container_capacity_per = ""
                let product_type = ""
                let parent_product_code = ""
                let bom_no = ''
                let id = ''

                for (var w = 0; w < wishlist.length; w++) {

                  if (localStorage.getItem("segment") == 'EURO') {
                    price_date = wishlist[w].price_date
                  }

                  wi_prtype = wishlist[w].product_type
                  segment_desc = wishlist[w].segment_desc
                  segment_code = wishlist[w].segment_code
                  product_group = wishlist[w].product_group
                  product_desc = wishlist[w].product_desc
                  product_code = wishlist[w].product_code
                  price = wishlist[w].price
                  price_date = wishlist[w].price_date
                  pi_qty = wishlist[w].pi_qty
                  pi_value = wishlist[w].pi_value
                  container_capacity_qty = wishlist[w].container_capacity_qty
                  container_capacity = wishlist[w].container_capacity
                  container_capacity_per = wishlist[w].container_capacity_per
                  product_type = wishlist[w].product_type
                  parent_product_code = wishlist[w].parent_product_code
                  bom_no = wishlist[w].bom_no
                  id = wishlist[w].id

                  getlist.push({
                    segment_desc: segment_desc,
                    segment_code: segment_code,
                    product_group: product_group,
                    product_desc: product_desc,
                    product_code: product_code,
                    price: price,
                    price_date: price_date,
                    bom_no: bom_no,
                    bom_no1: "",
                    pi_qty: pi_qty,
                    pi_value: pi_value,
                    container_capacity_qty: container_capacity_qty,
                    container_capacity: container_capacity,
                    container_capacity_per: container_capacity_per,
                    product_type: product_type,
                    parent_product_code: parent_product_code,
                    id: id
                  })


                  if (wi_prtype == 'Set') {
                    wi_pcode = wishlist[w].product_code

                    axios({
                      method: "POST",
                      url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                      headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                      },
                      data: {
                        'product_code': wishlist[w].product_code,
                      }
                    }).then((response) => {
                      const getbomkit = []
                      let kibom = response.data.data;
                      var items = getlist
                      var kitbom = response.data.data
                      let item_pcode = ''
                      let kit_pcode = ''

                      for (var b = 0; b < kitbom.length; b++) {
                        getbomkit.push({
                          // product_code :kitbom[b].product_code,
                          alt_bom_no: kitbom[b].alt_bom_no
                        })
                      }
                      this.setState({
                        wish_kit_bom: getbomkit
                      })

                      for (var k = 0; k < kitbom.length; k++) {
                        kit_pcode = kitbom[k].product_code

                        for (var b = 0; b < items.length; b++) {
                          item_pcode = items[b].product_code

                          if (kit_pcode == item_pcode) {
                            items[b].bom_no1 = response.data.data
                            items[b].bom_no = response.data.data[0].alt_bom_no
                          } else {

                          }
                        }
                      }
                      this.setState({
                        add_get_size: getlist
                      })
                    });
                  } else {
                    console.log("Testmanicode", getlist)
                    this.setState({
                      add_get_size: getlist
                    })
                  }


                }

                this.setState({
                  get_cu_details: response.data.hdrs,
                  // you have it as this.data not response
                });
              }


              console.log("Maniwish05 ", response.data.data)
              const wishlist = response.data.data;
              const getlist = []
              let piqty = '0';
              let pi_value = "0";
              let price_date = this.state.price_date
              let wi_prtype = ''
              let wi_pcode = ''
              let segment_desc = ""
              let segment_code = ""
              let product_group = ""
              let product_desc = ""
              let product_code = ""
              let price = ""
              let pi_qty = ""
              let container_capacity_qty = ""
              let container_capacity = ""
              let container_capacity_per = ""
              let product_type = ""
              let parent_product_code = ""
              let bom_no = ''
              let id = ''

              for (var w = 0; w < wishlist.length; w++) {

                if (localStorage.getItem("segment") == 'EURO') {
                  price_date = wishlist[w].price_date
                }

                wi_prtype = wishlist[w].product_type
                segment_desc = wishlist[w].segment_desc
                segment_code = wishlist[w].segment_code
                product_group = wishlist[w].product_group
                product_desc = wishlist[w].product_desc
                product_code = wishlist[w].product_code
                price = wishlist[w].price
                price_date = wishlist[w].price_date
                pi_qty = wishlist[w].pi_qty
                pi_value = wishlist[w].pi_value
                container_capacity_qty = wishlist[w].container_capacity_qty
                container_capacity = wishlist[w].container_capacity
                container_capacity_per = wishlist[w].container_capacity_per
                product_type = wishlist[w].product_type
                parent_product_code = wishlist[w].parent_product_code
                bom_no = wishlist[w].bom_no
                id = wishlist[w].id

                getlist.push({
                  segment_desc: segment_desc,
                  segment_code: segment_code,
                  product_group: product_group,
                  product_desc: product_desc,
                  product_code: product_code,
                  price: price,
                  price_date: price_date,
                  bom_no: bom_no,
                  bom_no1: "",
                  pi_qty: pi_qty,
                  pi_value: pi_value,
                  container_capacity_qty: container_capacity_qty,
                  container_capacity: container_capacity,
                  container_capacity_per: container_capacity_per,
                  product_type: product_type,
                  parent_product_code: parent_product_code,
                  id: id
                })


                if (wi_prtype == 'Set') {
                  wi_pcode = wishlist[w].product_code

                  axios({
                    method: "POST",
                    url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                    headers: {
                      'Content-Type': 'application/json',
                      'token': localStorage.getItem('token')
                    },
                    data: {
                      'product_code': wishlist[w].product_code,
                    }
                  }).then((response) => {
                    const getbomkit = []
                    let kibom = response.data.data;
                    var items = getlist
                    var kitbom = response.data.data
                    let item_pcode = ''
                    let kit_pcode = ''

                    for (var b = 0; b < kitbom.length; b++) {
                      getbomkit.push({
                        // product_code :kitbom[b].product_code,
                        alt_bom_no: kitbom[b].alt_bom_no
                      })
                    }
                    this.setState({
                      wish_kit_bom: getbomkit
                    })

                    for (var k = 0; k < kitbom.length; k++) {
                      kit_pcode = kitbom[k].product_code

                      for (var b = 0; b < items.length; b++) {
                        item_pcode = items[b].product_code

                        if (kit_pcode == item_pcode) {
                          items[b].bom_no1 = response.data.data
                          items[b].bom_no = response.data.data[0].alt_bom_no
                        } else {

                        }
                      }
                    }
                    this.setState({
                      add_get_size: getlist
                    })
                  });
                } else {
                  console.log("Testmanicode", getlist)
                  this.setState({
                    add_get_size: getlist
                  })
                }


              }


              this.setState({
                get_cu_details: response.data.hdrs,
                // you have it as this.data not response
              });


            });
          });
        });



        axios({
          method: "POST",
          url: this.state.baseurl + 'monthlygroupdtls',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            customer_sap_code: cust_code,
            order_mmyyyy: this.state.month_year
          }
        }).then((response) => {

          this.setState({
            get_order_s: response.data.data,
            // you have it as this.data not response
          });
        });


        axios({
          method: "POST",
          url: this.state.baseurl + 'get_product_list',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            customer_sap_code: cust_code,
            user_type: localStorage.getItem('usertypecode')

          }
        }).then((response) => {
          console.log("Maniproduct_code", response.data.data)
          this.setState({
            getproduct_code: response.data.data
            // you have it as this.data not response
          });
        });


        axios({
          method: "POST",
          url: this.state.baseurl + 'notify_master_list',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            customer_sap_code: cust_code,
            segment: localStorage.getItem('segment')

          }
        }).then((response) => {
          console.log("Testnotify123", response.data.data)
          this.setState({
            notifymaster: response.data.data,
            // you have it as this.data not response
          });


        });

      });
    } else {
      axios({
        method: "POST",
        url: this.state.baseurl + 'custdetails',//"http://localhost:3300/custdetails",

        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          user_code: localStorage.getItem('empcode'),
          user_type_code: localStorage.getItem('usertypecode')
          //order_mmyyyy: e.target.value, 
        }
      }).then((response) => {
        console.log("Test3231212", response.data.data)
        this.setState({
          cust_list: response.data.data, // you have it as this.data not response
        });
      });
    }



  }

  redirectLogin = () => {
    if (localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined || localStorage.getItem("token") == null) {
      localStorage.setItem('token', '')
      window.location.href = "/login"
    }
  }


  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  nextPage = () => {

    const currentPageNumber = this.state.pageNumber;
    let nextPageNumber;

    if (currentPageNumber + 1 > this.state.numPages) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = currentPageNumber + 1;
    }

    this.setState({
      pageNumber: nextPageNumber
    });
  }

  goToPrevPage = () => this.setState(state => ({ pageNumber: state.pageNumber - 1 }));

  goToNextPage = () => this.setState(state => ({ pageNumber: state.pageNumber + 1 }));



  handle_order_size = (e) => {

    this.setState({
      model_size: e.target.value
    })



    axios({
      method: "POST",
      url: this.state.baseurl + 'monthlydtls',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        customer_sap_code: this.state.customer_code,
        order_mmyyyy: this.state.month_year,
        product_group: e.target.value
      }
    }).then((response) => {
      console.log("GET", response.data.data)
      this.setState({
        orderaddmodel: response.data.data, // you have it as this.data not response
      });
    });
  }

  // handle_size =(selected)=>{
  //   var size = "";
  //   for (var i = 0; i < selected.length; i++) {
  //     size = selected[i].product_group
  //   }
  //   this.setState({
  //     model_size:size
  //   })

  //   axios({
  //     method: "POST",
  //     url: this.state.baseurl + 'monthlydtls',//"http://localhost:3300/custdetails",
  //     data: { 
  //       customer_sap_code: this.state.customer_code,
  //       order_mmyyyy:this.state.month_year,
  //       product_group:size
  //     }
  //   }).then((response) => {
  //     console.log("GET", response.data.data)
  //     this.setState({
  //       orderaddmodel: response.data.data, // you have it as this.data not response
  //     });
  //   });
  // }




  // handle customer name 

  handle_cust = (selected) => {

    var base_url = process.env['REACT_APP_API_URL_' + localStorage.getItem('segment')]
    var cuname = "";
    var cuscode = '';
    for (var i = 0; i < selected.length; i++) {
      cuname = selected[i].customer_name;
      cuscode = selected[i].customer_sap_code
    }
    this.setState({
      customer_name: cuname,
      customer_code: cuscode,
    })

    this.state.curreny = ''
    this.state.pay_term = '';
    this.state.incotern = '';
    this.state.port_delivery = '';
    this.state.customer_code = '';
    this.state.customer_id = ''
    this.state.order_type = ''
    this.state.container_size = ''
    this.state.po_number = ''
    this.state.pistatus = ''
    this.state.pinumber = ""
    this.state.container_size = ''
    this.state.notes = ''
    this.state.priority_item = ''
    this.state.final_desc = ''
    this.state.displaycontainercapper = ''
    this.state.get_tot = ''
    this.state.get_conta = ''
    this.state.action_status = ''
    this.state.piapprovedstatus = ''
    this.state.portofdischarge = ''
    this.state.shipement_type = ''
    this.state.remarkes = ''
    this.state.remarkes1 = ''
    this.state.discount = ''
    this.state.freight = ''
    this.state.insurance = ''
    this.state.incoternlocation = ''
    this.state.priority_item = ""
    this.state.notifyparty = ''
    this.state.notifyinfo = ''
    this.state.notifyinfo1 = ''
    this.state.notifyinfo2 = ''
    this.state.notifyinfo3 = ''
    this.state.notifyinfo4 = ''
    this.state.countryoffindes = ''
    this.state.filler_item = ''
    this.state.add_get_size = []
    this.state.notifymastercode = ''
    this.state.notychecked = false
    this.state.shipement_type = ''
    this.state.sonumber = ''


    axios({
      method: "POST",
      url: this.state.baseurl + 'showorder_list',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        customer_sap_code: cuscode,
        order_mmyyyy: this.state.month_year,
      }
    }).then((response) => {
      console.log("Ordertest", response.data.data)
      this.setState({
        get_order_no: response.data.data,
        order_number: response.data.data[0],
      });
      axios({
        method: "POST",
        url: this.state.baseurl + 'showversion_list',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          customer_sap_code: cuscode,
          order_mmyyyy: this.state.month_year,
          order_no: response.data.data[0]
        }
      }).then((response) => {
        console.log("version", response.data.data)
        this.setState({
          order_version: response.data.data[0],
          get_order_verion: response.data.data,
        });
        axios({
          method: "POST",
          url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            customer_sap_code: cuscode,
            order_mmyyyy: this.state.month_year,
            order_no: this.state.order_number,
            order_version: this.state.order_version
          }
        }).then((response) => {
          console.log("CustDetails", response.data.hdrs)

          if (this.state.order_number == 'New') {

            console.log("Maniordernew", response.data.hdrs)

            const date = new Date();
            const defaultValue = date.toLocaleDateString('en-CA');
            this.state.pistatus = 'Open'
            this.state.action_status = 'New'
            this.state.get_tot = '0'
            this.state.displaycontainercapper = 0
            this.state.get_conta = 0
            this.state.po_number = ''
            this.state.pinumber = ""
            this.state.po_date = defaultValue
            this.state.sonumber = ''
            this.state.shipdate = defaultValue
            var targetDate = new Date();
            targetDate.setDate(targetDate.getDate() + 60);

            if (response.data.hdrs != '') {
              var get_cu = response.data.hdrs
              var get_tag = ''
              for (var j = 0; j < get_cu.length; j++) {
                console.log("Manitag", get_cu[j].payment_term)
                this.state.curreny = get_cu[j].order_category
                this.state.pay_term = get_cu[j].payment_term;
                get_tag = get_cu[j].payment_term;
                this.state.incotern = get_cu[j].inco_term;
                this.state.port_delivery = get_cu[j].port_of_discharge;
                this.state.customer_code = get_cu[j].customer_sap_code;
                this.state.customer_id = get_cu[j].customer_id
                this.state.order_type = get_cu[j].order_type
                this.state.container_size = get_cu[j].container_type
                this.state.po_number = get_cu[j].po_number
                this.state.pistatus = "Open"
                this.state.pinumber = ""
                this.state.container_size = get_cu[j].container_type
                this.state.notes = get_cu[j].notes
                this.state.priority_item = get_cu[j].priority_item
                this.state.final_desc = get_cu[j].final_destination
                this.state.displaycontainercapper = ""
                this.state.get_tot = ""
                this.state.get_conta = ""
                this.state.action_status = ""
                this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
                this.state.portofdischarge = get_cu[j].port_of_discharge
                this.state.shipement_type = get_cu[j].precarriage_by
                this.state.remarkes = get_cu[j].remarks_quo
                this.state.remarkes1 = get_cu[j].remarks_so
                this.state.discount = get_cu[j].discount
                this.state.insurance = get_cu[j].insurance
                this.state.freight = get_cu[j].freight
                this.state.incoternlocation = get_cu[j].inco_term_location
                this.state.priority_item = get_cu[j].priority_item
                this.state.notifyparty = get_cu[j].notify_party_info1
                this.state.notifyinfo = get_cu[j].notify_party_info1
                this.state.notifyinfo1 = get_cu[j].notify_party_info2
                this.state.notifyinfo2 = get_cu[j].notify_party_info3
                this.state.notifyinfo3 = get_cu[j].notify_party_info4
                this.state.notifyinfo4 = get_cu[j].notify_party_info5
                this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
                this.state.filler_item = get_cu[j].filler_item
                this.state.notifymastercode = get_cu[j].notifyparty_sap_code
                if (get_cu[j].ad_code != null) {
                  this.state.ad_code = get_cu[j].ad_code
                } else {
                  this.state.ad_code = "0009999"
                }
                if (localStorage.getItem('segment') == 'OHT') {
                  this.state.validto = targetDate.toLocaleDateString('en-CA')
                } else {
                  this.state.validto = targetDate.toLocaleDateString('en-CA')
                }
                // if (get_cu[j].pi_valid_to != null) {
                //   this.state.validto = moment(get_cu[j].pi_valid_to).format('YYYY-MM-DD')
                // } else {

                // }

                this.state.notychecked = false

                // if (this.state.notifymastercode != "") {


                //   let notycode = this.state.notifymastercode

                //   if (localStorage.getItem('segment') != 'OHT') {
                //     axios({
                //       method: "POST",
                //       url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",
                //       headers: {
                //         'Content-Type': 'application/json',
                //         'token': localStorage.getItem('token')
                //       },

                //       data: {
                //         customer_sap_code: this.state.customer_code,
                //         notify_sap_code: notycode,
                //         segment: localStorage.getItem('segment')
                //       }
                //     }).then((response) => {

                //       if (response.data.status == 'Success') {
                //         this.setState({
                //           notifymastername: response.data.data[0].notify_name
                //         });
                //       }


                //     });
                //   } else {
                //     axios({
                //       method: "POST",
                //       url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",

                //       headers: {
                //         'Content-Type': 'application/json',
                //         'token': localStorage.getItem('token')
                //       },

                //       data: {
                //         customer_sap_code: notycode,
                //         segment: localStorage.getItem('segment')
                //       }
                //     }).then((response) => {

                //       console.log("testoht", response.data.data)
                //       if (response.data.status == 'Success') {
                //         this.setState({
                //           notifymastername: response.data.data[0].customer_name
                //         });
                //       }


                //     });
                //   }


                // }

              }

              if (localStorage.getItem('usertypecode') == 'CUST') {
                axios({
                  method: "POST",
                  url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    tag_value: get_tag,

                  }
                }).then((response) => {
                  console.log("Testpayment", response.data.data)
                  this.setState({
                    payment_term_code: response.data.data[0].tag_desc
                  })


                });
              } else {
                this.state.pay_term = get_tag
              }

              console.log("Maniwish05 ", response.data.data)
              const wishlist = response.data.data;
              const getlist = []
              let piqty = '0';
              let pi_value = "0";
              let price_date = this.state.price_date
              let wi_prtype = ''
              let wi_pcode = ''
              let segment_desc = ""
              let segment_code = ""
              let product_group = ""
              let product_desc = ""
              let product_code = ""
              let price = ""
              let pi_qty = ""
              let container_capacity_qty = ""
              let container_capacity = ""
              let container_capacity_per = ""
              let product_type = ""
              let parent_product_code = ""
              let bom_no = ''
              let id = ''

              for (var w = 0; w < wishlist.length; w++) {

                if (localStorage.getItem("segment") == 'EURO') {
                  price_date = wishlist[w].price_date
                }

                wi_prtype = wishlist[w].product_type
                segment_desc = wishlist[w].segment_desc
                segment_code = wishlist[w].segment_code
                product_group = wishlist[w].product_group
                product_desc = wishlist[w].product_desc
                product_code = wishlist[w].product_code
                price = wishlist[w].price
                price_date = wishlist[w].price_date
                pi_qty = wishlist[w].pi_qty
                pi_value = wishlist[w].pi_value
                container_capacity_qty = wishlist[w].container_capacity_qty
                container_capacity = wishlist[w].container_capacity
                container_capacity_per = wishlist[w].container_capacity_per
                product_type = wishlist[w].product_type
                parent_product_code = wishlist[w].parent_product_code
                bom_no = wishlist[w].bom_no
                id = wishlist[w].id

                getlist.push({
                  segment_desc: segment_desc,
                  segment_code: segment_code,
                  product_group: product_group,
                  product_desc: product_desc,
                  product_code: product_code,
                  price: price,
                  price_date: price_date,
                  bom_no: bom_no,
                  bom_no1: "",
                  pi_qty: pi_qty,
                  pi_value: pi_value,
                  container_capacity_qty: container_capacity_qty,
                  container_capacity: container_capacity,
                  container_capacity_per: container_capacity_per,
                  product_type: product_type,
                  parent_product_code: parent_product_code,
                  id: id
                })


                if (wi_prtype == 'Set') {
                  wi_pcode = wishlist[w].product_code

                  axios({
                    method: "POST",
                    url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                    headers: {
                      'Content-Type': 'application/json',
                      'token': localStorage.getItem('token')
                    },
                    data: {
                      'product_code': wishlist[w].product_code,
                    }
                  }).then((response) => {
                    const getbomkit = []
                    let kibom = response.data.data;
                    var items = getlist
                    var kitbom = response.data.data
                    let item_pcode = ''
                    let kit_pcode = ''

                    for (var b = 0; b < kitbom.length; b++) {
                      getbomkit.push({
                        // product_code :kitbom[b].product_code,
                        alt_bom_no: kitbom[b].alt_bom_no
                      })
                    }
                    this.setState({
                      wish_kit_bom: getbomkit
                    })

                    for (var k = 0; k < kitbom.length; k++) {
                      kit_pcode = kitbom[k].product_code

                      for (var b = 0; b < items.length; b++) {
                        item_pcode = items[b].product_code

                        if (kit_pcode == item_pcode) {
                          items[b].bom_no1 = response.data.data
                          items[b].bom_no = response.data.data[0].alt_bom_no
                        } else {

                        }
                      }
                    }
                    this.setState({
                      add_get_size: getlist
                    })
                  });
                } else {
                  console.log("Testmanicode", getlist)
                  this.setState({
                    add_get_size: getlist
                  })
                }


              }

              this.setState({
                //add_get_size: response.data.data,
                get_cu_details: response.data.hdrs,
                // you have it as this.data not response
              });
            } else {

              axios({
                method: "POST",
                url: this.state.baseurl + 'customer_details',//"http://localhost:3300/custdetails",
                headers: {
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
                },
                data: {
                  customer_sap_code: cuscode,

                  //order_mmyyyy: e.target.value, 
                }
              }).then((response) => {
                console.log("prepimonth", response.data.data[0].payment_term)


                var get_cust1 = response.data.data

                for (var j = 0; j < get_cust1.length; j++) {

                  this.state.curreny = get_cust1[j].currency
                  this.state.pay_term = get_cust1[j].payment_term;
                  this.state.incotern = get_cust1[j].inco_term;
                  this.state.port_delivery = get_cust1[j].port;
                  this.state.customer_code = get_cust1[j].customer_sap_code;
                  this.state.customer_id = get_cust1[j].customer_id

                }


                axios({
                  method: "POST",
                  url: this.state.baseurl + 'getordertype',//"http://localhost:3300/listproduct",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    'tag_value': response.data.data[0].payment_term,
                  }
                }).then((response) => {
                  console.log("maniadditem", response.data.data)
                  this.setState({
                    order_type: response.data.data[0].tag_type
                  });
                });



                this.setState({
                  get_month_order: ''
                });


                axios({
                  method: "POST",
                  url: this.state.baseurl + 'get_port',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    'country': response.data.data[0].country//response.data.data[0].country,

                  }
                }).then((response) => {
                  console.log("Manitest", response.data.data)
                  this.setState({
                    portof: response.data.data,
                    // you have it as this.data not response
                  });
                });
              });


            }
          } else {
            console.log("Maniorder123", response.data.hdrs)
            this.state.action_status = ""
            this.state.pistatus = ""
            if (response.data.status == 'Success') {

              var get_cu = response.data.hdrs

              var get_tag = ''
              var get_shipdate = ''
              const date = new Date();
              const defaultValue = date.toLocaleDateString('en-CA');

              var targetDate = new Date();
              targetDate.setDate(targetDate.getDate() + 60);
              for (var j = 0; j < get_cu.length; j++) {

                this.state.curreny = get_cu[j].order_category
                this.state.pay_term = get_cu[j].payment_term;
                get_tag = get_cu[j].payment_term;
                this.state.incotern = get_cu[j].inco_term;
                this.state.port_delivery = get_cu[j].port_of_discharge;
                this.state.customer_code = get_cu[j].customer_sap_code;
                this.state.customer_id = get_cu[j].customer_id
                this.state.order_type = get_cu[j].order_type
                this.state.container_size = get_cu[j].container_type
                this.state.po_number = get_cu[j].po_number
                this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
                this.state.pistatus = get_cu[j].pi_status
                this.state.pinumber = get_cu[j].pi_number
                this.state.container_size = get_cu[j].container_type
                this.state.notes = get_cu[j].notes
                this.state.priority_item = get_cu[j].priority_item
                this.state.final_desc = get_cu[j].final_destination
                this.state.displaycontainercapper = get_cu[j].container_capacity
                this.state.get_tot = get_cu[j].total_order_qty
                this.state.get_conta = get_cu[j].no_of_containers
                this.state.action_status = get_cu[j].action_status
                this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
                this.state.sonumber = get_cu[j].so_number
                this.state.remarkes = get_cu[j].remarks_quo
                this.state.remarkes1 = get_cu[j].remarks_so
                this.state.portofdischarge = get_cu[j].port_of_discharge
                this.state.incoternlocation = get_cu[j].inco_term_location
                this.state.shipement_type = get_cu[j].precarriage_by
                //this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                get_shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
                this.state.filler_item = get_cu[j].filler_item
                this.state.notifymastercode = get_cu[j].notifyparty_sap_code
                this.state.priority_item = get_cu[j].priority_item
                this.state.notifyparty = get_cu[j].notify_party_info1
                this.state.notifyinfo = get_cu[j].notify_party_info1
                this.state.notifyinfo1 = get_cu[j].notify_party_info2
                this.state.notifyinfo2 = get_cu[j].notify_party_info3
                this.state.notifyinfo3 = get_cu[j].notify_party_info4
                this.state.notifyinfo4 = get_cu[j].notify_party_info5
                this.state.discount = get_cu[j].discount
                this.state.insurance = get_cu[j].insurance
                this.state.freight = get_cu[j].freight

                if (localStorage.getItem('segment') == 'OHT') {
                  this.state.shipdate = defaultValue
                } else {
                  this.state.shipdate = get_shipdate
                }
                if (this.state.notifymastercode != "") {
                  this.state.notychecked = true
                  let notycode = this.state.notifymastercode

                  if (localStorage.getItem('segment') != 'OHT') {
                    axios({
                      method: "POST",
                      url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",
                      headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                      },

                      data: {
                        customer_sap_code: this.state.customer_code,
                        notify_sap_code: notycode,
                        segment: localStorage.getItem('segment')
                      }
                    }).then((response) => {

                      if (response.data.status == 'Success') {
                        this.setState({
                          notifymastername: response.data.data[0].notify_name
                        });
                      }


                    });
                  } else {
                    axios({
                      method: "POST",
                      url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",

                      headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                      },

                      data: {
                        customer_sap_code: notycode,
                        segment: localStorage.getItem('segment')
                      }
                    }).then((response) => {

                      console.log("testoht", response.data.data)
                      if (response.data.status == 'Success') {
                        this.setState({
                          notifymastername: response.data.data[0].customer_name
                        });
                      }


                    });
                  }

                }
                if (get_cu[j].ad_code != null) {
                  this.state.ad_code = get_cu[j].ad_code
                } else {
                  this.state.ad_code = "0009999"
                }

                if (get_cu[j].pi_valid_to != null) {
                  this.state.validto = targetDate.toLocaleDateString('en-CA')
                } else {
                  this.state.validto = targetDate.toLocaleDateString('en-CA')
                }

              }


              if (localStorage.getItem('usertypecode') == 'CUST') {
                axios({
                  method: "POST",
                  url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    tag_value: get_tag,

                  }
                }).then((response) => {
                  console.log("Test", response.data.data[0].tag_desc)
                  this.setState({
                    payment_term_code: response.data.data[0].tag_desc
                  })


                });
              } else {
                this.state.pay_term = get_tag
              }


              console.log("CustGridDeails", response.data.data)
              const wishlist = response.data.data;
              const getlist = []
              let piqty = '0';
              let pi_value = "0";
              let price_date = this.state.price_date
              let wi_prtype = ''
              let wi_pcode = ''
              let segment_desc = ""
              let segment_code = ""
              let product_group = ""
              let product_desc = ""
              let product_code = ""
              let price = ""
              let pi_qty = ""
              let container_capacity_qty = ""
              let container_capacity = ""
              let container_capacity_per = ""
              let product_type = ""
              let parent_product_code = ""
              let bom_no = ''
              let id = ''

              for (var w = 0; w < wishlist.length; w++) {

                if (localStorage.getItem("segment") == 'EURO') {
                  price_date = wishlist[w].price_date
                }

                wi_prtype = wishlist[w].product_type
                segment_desc = wishlist[w].segment_desc
                segment_code = wishlist[w].segment_code
                product_group = wishlist[w].product_group
                product_desc = wishlist[w].product_desc
                product_code = wishlist[w].product_code
                price = wishlist[w].price
                price_date = wishlist[w].price_date
                pi_qty = wishlist[w].pi_qty
                pi_value = wishlist[w].pi_value
                container_capacity_qty = wishlist[w].container_capacity_qty
                container_capacity = wishlist[w].container_capacity
                container_capacity_per = wishlist[w].container_capacity_per
                product_type = wishlist[w].product_type
                parent_product_code = wishlist[w].parent_product_code
                bom_no = wishlist[w].bom_no
                id = wishlist[w].id

                getlist.push({
                  segment_desc: segment_desc,
                  segment_code: segment_code,
                  product_group: product_group,
                  product_desc: product_desc,
                  product_code: product_code,
                  price: price,
                  price_date: price_date,
                  bom_no: bom_no,
                  bom_no1: "",
                  pi_qty: pi_qty,
                  pi_value: pi_value,
                  container_capacity_qty: container_capacity_qty,
                  container_capacity: container_capacity,
                  container_capacity_per: container_capacity_per,
                  product_type: product_type,
                  parent_product_code: parent_product_code,
                  id: id
                })


                if (wi_prtype == 'Set') {
                  wi_pcode = wishlist[w].product_code

                  axios({
                    method: "POST",
                    url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                    headers: {
                      'Content-Type': 'application/json',
                      'token': localStorage.getItem('token')
                    },
                    data: {
                      'product_code': wishlist[w].product_code,
                    }
                  }).then((response) => {
                    const getbomkit = []
                    let kibom = response.data.data;
                    var items = getlist
                    var kitbom = response.data.data
                    let item_pcode = ''
                    let kit_pcode = ''

                    for (var b = 0; b < kitbom.length; b++) {
                      getbomkit.push({
                        // product_code :kitbom[b].product_code,
                        alt_bom_no: kitbom[b].alt_bom_no
                      })
                    }
                    this.setState({
                      wish_kit_bom: getbomkit
                    })

                    for (var k = 0; k < kitbom.length; k++) {
                      kit_pcode = kitbom[k].product_code

                      for (var b = 0; b < items.length; b++) {
                        item_pcode = items[b].product_code

                        if (kit_pcode == item_pcode) {
                          items[b].bom_no1 = response.data.data
                          //   items[b].bom_no = response.data.data[0].alt_bom_no
                        } else {

                        }
                      }
                    }
                    this.setState({
                      add_get_size: getlist
                    })
                  });
                } else {
                  console.log("Testmanicode", getlist)
                  this.setState({
                    add_get_size: getlist
                  })
                }


              }


              this.setState({
                // add_get_size: response.data.data,
                get_cu_details: response.data.hdrs,
                // you have it as this.data not response
              });
            } else {
              this.state.order_version = ''
              var get_cu = response.data.hdrs
              const date = new Date();
              const defaultValue = date.toLocaleDateString('en-CA');
              this.state.pistatus = 'Open'
              this.state.action_status = 'New'
              this.state.get_tot = '0'
              this.state.displaycontainercapper = 0
              this.state.get_conta = 0
              this.state.po_number = ''
              this.state.port_delivery = ''
              this.state.pinumber = ""
              this.state.po_date = defaultValue

              var get_tag = ''
              for (var j = 0; j < get_cu.length; j++) {

                this.state.curreny = get_cu[j].order_category
                this.state.pay_term = get_cu[j].payment_term;
                get_tag = get_cu[j].payment_term;
                this.state.incotern = get_cu[j].inco_term;
                this.state.port_delivery = get_cu[j].port_of_discharge;
                this.state.customer_code = get_cu[j].customer_sap_code;
                this.state.customer_id = get_cu[j].customer_id
                this.state.order_type = get_cu[j].order_type
                this.state.container_size = get_cu[j].container_type
                this.state.po_number = get_cu[j].po_number
                this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
                this.state.pistatus = ''
                this.state.pinumber = ''
                this.state.container_size = get_cu[j].container_type
                this.state.notes = get_cu[j].notes
                this.state.priority_item = get_cu[j].priority_item
                this.state.final_desc = get_cu[j].final_destination
                this.state.displaycontainercapper = get_cu[j].container_capacity
                this.state.get_tot = get_cu[j].total_order_qty
                this.state.get_conta = get_cu[j].no_of_containers
                this.state.action_status = ''
                this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
              }

              if (localStorage.getItem('usertypecode') == 'CUST') {
                axios({
                  method: "POST",
                  url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    tag_value: get_tag,

                  }
                }).then((response) => {
                  console.log("Test", response.data.data[0].tag_desc)
                  this.setState({
                    payment_term_code: response.data.data[0].tag_desc
                  })


                });
              } else {
                this.state.pay_term = get_tag
              }

              console.log("Maniwish05 ", response.data.data)
              const wishlist = response.data.data;
              const getlist = []
              let piqty = '0';
              let pi_value = "0";
              let price_date = this.state.price_date
              let wi_prtype = ''
              let wi_pcode = ''
              let segment_desc = ""
              let segment_code = ""
              let product_group = ""
              let product_desc = ""
              let product_code = ""
              let price = ""
              let pi_qty = ""
              let container_capacity_qty = ""
              let container_capacity = ""
              let container_capacity_per = ""
              let product_type = ""
              let parent_product_code = ""
              let bom_no = ''
              let id = ''

              for (var w = 0; w < wishlist.length; w++) {

                if (localStorage.getItem("segment") == 'EURO') {
                  price_date = wishlist[w].price_date
                }

                wi_prtype = wishlist[w].product_type
                segment_desc = wishlist[w].segment_desc
                segment_code = wishlist[w].segment_code
                product_group = wishlist[w].product_group
                product_desc = wishlist[w].product_desc
                product_code = wishlist[w].product_code
                price = wishlist[w].price
                price_date = wishlist[w].price_date
                pi_qty = wishlist[w].pi_qty
                pi_value = wishlist[w].pi_value
                container_capacity_qty = wishlist[w].container_capacity_qty
                container_capacity = wishlist[w].container_capacity
                container_capacity_per = wishlist[w].container_capacity_per
                product_type = wishlist[w].product_type
                parent_product_code = wishlist[w].parent_product_code
                bom_no = wishlist[w].bom_no
                id = wishlist[w].id

                getlist.push({
                  segment_desc: segment_desc,
                  segment_code: segment_code,
                  product_group: product_group,
                  product_desc: product_desc,
                  product_code: product_code,
                  price: price,
                  price_date: price_date,
                  bom_no: bom_no,
                  bom_no1: "",
                  pi_qty: pi_qty,
                  pi_value: pi_value,
                  container_capacity_qty: container_capacity_qty,
                  container_capacity: container_capacity,
                  container_capacity_per: container_capacity_per,
                  product_type: product_type,
                  parent_product_code: parent_product_code,
                  id: id
                })


                if (wi_prtype == 'Set') {
                  wi_pcode = wishlist[w].product_code

                  axios({
                    method: "POST",
                    url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                    headers: {
                      'Content-Type': 'application/json',
                      'token': localStorage.getItem('token')
                    },
                    data: {
                      'product_code': wishlist[w].product_code,
                    }
                  }).then((response) => {
                    const getbomkit = []
                    let kibom = response.data.data;
                    var items = getlist
                    var kitbom = response.data.data
                    let item_pcode = ''
                    let kit_pcode = ''

                    for (var b = 0; b < kitbom.length; b++) {
                      getbomkit.push({
                        // product_code :kitbom[b].product_code,
                        alt_bom_no: kitbom[b].alt_bom_no
                      })
                    }
                    this.setState({
                      wish_kit_bom: getbomkit
                    })

                    for (var k = 0; k < kitbom.length; k++) {
                      kit_pcode = kitbom[k].product_code

                      for (var b = 0; b < items.length; b++) {
                        item_pcode = items[b].product_code

                        if (kit_pcode == item_pcode) {
                          items[b].bom_no1 = response.data.data
                          items[b].bom_no = response.data.data[0].alt_bom_no
                        } else {

                        }
                      }
                    }
                    this.setState({
                      add_get_size: getlist
                    })
                  });
                } else {
                  console.log("Testmanicode", getlist)
                  this.setState({
                    add_get_size: getlist
                  })
                }


              }


              this.setState({
                // add_get_size: response.data.data,
                get_cu_details: response.data.hdrs,
                // you have it as this.data not response
              });

            }

          }




          // if (response.data.hdrs != '') {
          //   if (response.data.status == 'Success') {
          //     var get_cu = response.data.hdrs


          //     for (var j = 0; j < get_cu.length; j++) {

          //       this.state.curreny = get_cu[j].order_category
          //       this.state.pay_term = get_cu[j].payment_term;
          //       this.state.incotern = get_cu[j].inco_term;
          //       this.state.port_delivery = get_cu[j].port_of_discharge;
          //       this.state.customer_code = get_cu[j].customer_sap_code;
          //       this.state.customer_id = get_cu[j].customer_id
          //       this.state.order_type = get_cu[j].order_type
          //       this.state.container_size = get_cu[j].container_type
          //       this.state.po_number = get_cu[j].po_number
          //       this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
          //       this.state.pistatus = get_cu[j].pi_status
          //       if (get_cu[j].pi_number == null) {
          //         this.state.pinumber = ""
          //       } else {
          //         this.state.pinumber = get_cu[j].pi_number
          //       }

          //       this.state.container_size = get_cu[j].container_type
          //       this.state.notes = get_cu[j].notes
          //       this.state.priority_item = get_cu[j].priority_item
          //       this.state.final_desc = get_cu[j].final_destination
          //       this.state.displaycontainercapper = get_cu[j].container_capacity
          //       this.state.get_tot = get_cu[j].total_order_qty
          //       this.state.get_conta = get_cu[j].no_of_containers
          //       this.state.action_status = get_cu[j].action_status
          //       this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
          //       this.state.portofdischarge = get_cu[j].port_of_discharge
          //       this.state.shipement_type = get_cu[j].precarriage_by
          //       this.state.remarkes = get_cu[j].remarks_quo
          //       this.state.remarkes1 = get_cu[j].remarks_so
          //       this.state.discount = get_cu[j].discount
          //       this.state.freight = get_cu[j].freight
          //       this.state.insurance = get_cu[j].insurance
          //       this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
          //       this.state.incoternlocation = get_cu[j].inco_term_location
          //       this.state.priority_item = get_cu[j].priority_item
          //       this.state.notifyparty = get_cu[j].notify_party_info1
          //       this.state.notifyinfo = get_cu[j].notify_party_info1
          //       this.state.notifyinfo1 = get_cu[j].notify_party_info2
          //       this.state.notifyinfo2 = get_cu[j].notify_party_info3
          //       this.state.notifyinfo3 = get_cu[j].notify_party_info4
          //       this.state.notifyinfo4 = get_cu[j].notify_party_info5
          //       this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
          //       this.state.filler_item = get_cu[j].filler_item
          //       this.state.sonumber = get_cu[j].so_number
          //       this.state.notifymastercode = get_cu[j].notifyparty_sap_code

          //       //commit_date_of_shipment

          //     }



          //     if (this.state.notifymastercode != "") {
          //       this.state.notychecked = true

          //       let notycode = this.state.notifymastercode

          //       if (localStorage.getItem('segment') != 'OHT') {
          //         axios({
          //           method: "POST",
          //           url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",
          //           headers: {
          //             'Content-Type': 'application/json',
          //             'token': localStorage.getItem('token')
          //           },

          //           data: {
          //             customer_sap_code: cuscode,
          //             notify_sap_code: notycode,
          //             segment: localStorage.getItem('segment')
          //           }
          //         }).then((response) => {

          //           if (response.data.status == 'Success') {
          //             this.setState({
          //               notifymastername: response.data.data[0].notify_name
          //             });
          //           }


          //         });
          //       } else {
          //         axios({
          //           method: "POST",
          //           url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",

          //           headers: {
          //             'Content-Type': 'application/json',
          //             'token': localStorage.getItem('token')
          //           },

          //           data: {
          //             customer_sap_code: notycode,
          //             segment: localStorage.getItem('segment')
          //           }
          //         }).then((response) => {

          //           console.log("testoht", response.data.data)
          //           if (response.data.status == 'Success') {
          //             this.setState({
          //               notifymastername: response.data.data[0].customer_name
          //             });
          //           }


          //         });
          //       }


          //     }

          //     console.log("Maninoty", this.state.notifymastername)


          //     console.log("Maniwish05 ", response.data.data)
          //     const wishlist = response.data.data;
          //     const getlist = []
          //     let piqty = '0';
          //     let pi_value = "0";
          //     let price_date = this.state.price_date
          //     let wi_prtype =''
          //     let wi_pcode =''
          //     let segment_desc =""
          //     let segment_code =""
          //     let product_group =""
          //     let product_desc =""
          //     let product_code =""
          //     let price =""
          //     let pi_qty =""
          //     let container_capacity_qty =""
          //     let container_capacity =""
          //     let container_capacity_per =""
          //     let product_type =""
          //     let parent_product_code =""
          //     let bom_no =''
          //     let id =''

          //     for (var w = 0; w < wishlist.length; w++) {

          //     if (localStorage.getItem("segment") == 'EURO') {
          //         price_date = wishlist[w].price_date
          //       }

          //       wi_prtype  = wishlist[w].product_type
          //       segment_desc= wishlist[w].segment_desc
          //       segment_code= wishlist[w].segment_code
          //       product_group= wishlist[w].product_group
          //       product_desc= wishlist[w].product_desc
          //       product_code= wishlist[w].product_code
          //       price= wishlist[w].price
          //       price_date=wishlist[w].price_date
          //       pi_qty= wishlist[w].pi_qty
          //       pi_value= wishlist[w].pi_value
          //       container_capacity_qty= wishlist[w].container_qty
          //       container_capacity= wishlist[w].container_capacity
          //       container_capacity_per= wishlist[w].conatainer_qty
          //       product_type= wishlist[w].product_type
          //       parent_product_code= wishlist[w].parent_product_code
          //       bom_no =wishlist[w].bom_no
          //       id = wishlist[w].id

          //       getlist.push({
          //         segment_desc: segment_desc,
          //         segment_code: segment_code,
          //         product_group: product_group,
          //         product_desc: product_desc,
          //         product_code: product_code,
          //         price: price,
          //         price_date: price_date,
          //         bom_no :bom_no,
          //         bom_no1 :"",
          //         pi_qty: pi_qty,
          //         pi_value: pi_value,
          //         container_capacity_qty: container_capacity_qty,
          //         container_capacity:container_capacity,
          //         container_capacity_per: container_capacity_per,
          //         product_type: product_type,
          //         parent_product_code: parent_product_code,
          //         id: id
          //       })


          //       if (wi_prtype == 'Set'){
          //         wi_pcode = wishlist[w].product_code

          //         axios({
          //           method: "POST",
          //           url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
          //           headers: {
          //             'Content-Type': 'application/json',
          //             'token': localStorage.getItem('token')
          //           },
          //           data: {
          //             'product_code': wishlist[w].product_code,
          //           }
          //         }).then((response) => {
          //           console.log("Manitest",  response.data.data)
          //           const getbomkit = []
          //             let kibom = response.data.data;
          //             var items = getlist
          //             var kitbom = response.data.data
          //             let item_pcode =''
          //             let kit_pcode =''

          //             for (var b = 0; b< kitbom.length; b++){
          //               getbomkit.push({
          //               // product_code :kitbom[b].product_code,
          //                 alt_bom_no : kitbom[b].alt_bom_no 
          //               })
          //             }
          //             this.setState({
          //               wish_kit_bom : getbomkit
          //             })

          //             for(var k = 0; k<kitbom.length; k++){
          //               kit_pcode = kitbom[k].product_code

          //               for (var b =0; b< items.length; b++){
          //                 item_pcode = items[b].product_code

          //                 if(kit_pcode == item_pcode ){
          //                   items[b].bom_no1 =  response.data.data
          //                   items[b].bom_no =  response.data.data[0].alt_bom_no
          //               }else{

          //               }
          //               }
          //             }
          //             this.setState({
          //               add_get_size : getlist
          //             })
          //         });
          //       }else{
          //         console.log("Testmanicode", getlist)
          //         this.setState({
          //           add_get_size : getlist
          //         })
          //       }


          //     }




          //     this.setState({
          //       //add_get_size: response.data.data,
          //       get_cu_details: response.data.hdrs,
          //       // you have it as this.data not response
          //     });



          //   } else {
          //     var get_cu = response.data.hdrs

          //     for (var j = 0; j < get_cu.length; j++) {

          //       this.state.curreny = get_cu[j].order_category
          //       this.state.pay_term = get_cu[j].payment_term;
          //       this.state.incotern = get_cu[j].inco_term;
          //       this.state.port_delivery = get_cu[j].port_of_discharge;
          //       this.state.customer_code = get_cu[j].customer_sap_code;
          //       this.state.customer_id = get_cu[j].customer_id
          //       this.state.order_type = get_cu[j].order_type
          //       this.state.container_size = get_cu[j].container_type
          //       this.state.po_number = get_cu[j].po_number
          //       this.state.po_date = get_cu[j].po_date
          //       this.state.pistatus = "Open"
          //       this.state.pinumber = ""
          //       this.state.container_size = get_cu[j].container_type
          //       this.state.notes = get_cu[j].notes
          //       this.state.priority_item = get_cu[j].priority_item
          //       this.state.final_desc = get_cu[j].final_destination
          //       this.state.displaycontainercapper = get_cu[j].container_capacity
          //       this.state.get_tot = get_cu[j].total_order_qty
          //       this.state.get_conta = get_cu[j].no_of_containers
          //       this.state.action_status = 'New'
          //     }


          //     this.setState({
          //       add_get_size: response.data.data,
          //       get_cu_details: response.data.hdrs,
          //       // you have it as this.data not response
          //     });
          //   }
          // } else {
          //   axios({
          //     method: "POST",
          //     url: this.state.baseurl + 'customer_details',//"http://localhost:3300/custdetails",
          //     headers: {
          //       'Content-Type': 'application/json',
          //       'token': localStorage.getItem('token')
          //     },
          //     data: {
          //       customer_sap_code: cuscode,

          //       //order_mmyyyy: e.target.value, 
          //     }
          //   }).then((response) => {
          //     console.log("prepimonth", response.data.data[0].payment_term)


          //     var get_cust1 = response.data.data

          //     for (var j = 0; j < get_cust1.length; j++) {

          //       this.state.curreny = get_cust1[j].currency
          //       this.state.pay_term = get_cust1[j].payment_term;
          //       this.state.incotern = get_cust1[j].inco_term;
          //       this.state.port_delivery = get_cust1[j].port;
          //       this.state.customer_code = get_cust1[j].customer_sap_code;
          //       this.state.customer_id = get_cust1[j].customer_id

          //     }


          //     axios({
          //       method: "POST",
          //       url: this.state.baseurl + 'getordertype',//"http://localhost:3300/listproduct",
          //       headers: {
          //         'Content-Type': 'application/json',
          //         'token': localStorage.getItem('token')
          //       },
          //       data: {
          //         'tag_value': response.data.data[0].payment_term,
          //       }
          //     }).then((response) => {
          //       console.log("maniadditem", response.data.data)
          //       this.setState({
          //         order_type: response.data.data[0].tag_type
          //       });
          //     });



          //     this.setState({
          //       get_month_order: ''
          //     });


          //     axios({
          //       method: "POST",
          //       url: this.state.baseurl + 'get_port',//"http://localhost:3300/custdetails",
          //       headers: {
          //         'Content-Type': 'application/json',
          //         'token': localStorage.getItem('token')
          //       },
          //       data: {
          //         'country': response.data.data[0].country//response.data.data[0].country,

          //       }
          //     }).then((response) => {
          //       console.log("Manitest", response.data.data)
          //       this.setState({
          //         portof: response.data.data,
          //         // you have it as this.data not response
          //       });
          //     });
          //   });
          // }

        });
      });
    });






    axios({
      method: "POST",
      url: this.state.baseurl + 'monthlygroupdtls',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },

      data: {
        customer_sap_code: cuscode,
        order_mmyyyy: this.state.month_year
      }
    }).then((response) => {

      this.setState({
        get_order_s: response.data.data,
        // you have it as this.data not response
      });
    });

    axios({
      method: "POST",
      url: this.state.baseurl + 'notify_master_list',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        customer_sap_code: cuscode,
        segment: localStorage.getItem('segment')

      }
    }).then((response) => {
      console.log("Testnotify", response.data.data)
      this.setState({
        notifymaster: response.data.data,
        // you have it as this.data not response
      });


    });

    console.log("Manicountr", this.state.country)


    axios({
      method: "POST",
      url: this.state.baseurl + 'get_product_list',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        customer_sap_code: cuscode,
        user_type: localStorage.getItem('usertypecode')

      }
    }).then((response) => {
      console.log("Maniproduct_code", response.data.data)
      this.setState({
        getproduct_code: response.data.data
        // you have it as this.data not response
      });
    });


    axios({
      method: "POST",
      url: this.state.baseurl + 'monthlydtls_view',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        customer_sap_code: cuscode,
        order_mmyyyy: this.state.month_year,

      }
    }).then((response) => {
      console.log("GET", response.data.data)
      this.setState({
        orderaddmodel: response.data.data, // you have it as this.data not response
      });
    });


  }


  togglewishview = () => {

    if (this.state.customer_code == '') {
      alert("Please Select Customer Name")
    } else {
      if (this.state.container_size == '') {
        alert("Please select Container size")

      } else {
        this.setState(prevState => ({
          modalwishview: !prevState.modalwishview
        }));
        axios({
          method: "POST",
          url: this.state.baseurl + 'wishlistview',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            customer_sap_code: this.state.customer_code

          }
        }).then((response) => {
          console.log("Testwsishlist", response.data.data)
          this.setState({
            get_wishlistview: response.data.data, // you have it as this.data not response
          });

          const getwish = response.data.data;
          const list = [];

          for (var i = 0; i < getwish.length; i++) {
            list.push({
              id: getwish[i].id,
              product_code: getwish[i].product_code,
              product_desc: getwish[i].product_desc,
              currency: getwish[i].currency,
              price: getwish[i].price,
              valid_from: getwish[i].valid_from,
              valid_to: getwish[i].valid_to,
              status: getwish[i].status,
              wish_list: getwish[i].wish_list,
              incoterm: getwish[i].incoterm,
              remove_item: ""
            })
          }



          this.setState({
            viewlist: list, // you have it as this.data not response
          });

        });
      }

    }


  }

  toggleewishview = () => {

    this.state.get_wish_order = []

    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    console.log("Testdate", defaultValue)

    this.setState(prevState => ({
      modalwishview: !prevState.modalwishview,
    }));

    const gtwish = this.state.viewlist
    const wish = [];
    for (var J = 0; J < gtwish.length; J++) {
      let rewish = gtwish[J].remove_item;
      if (rewish == '') {
        wish.push({
          "Code": gtwish[J].product_code
        })
      }
    }



    axios({
      method: "POST",
      url: this.state.baseurl + 'getwishlist',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "customer_sap_code": this.state.customer_code,
        "price_date": this.state.price_date,
        "user_name": localStorage.getItem('username'),
        "order_number": this.state.order_number,
        "order_version": this.state.order_version,
        "container_type": this.state.container_size,
        "Segment": localStorage.getItem('segment')
      }

    }).then((response) => {

      const wishlist = response.data.data;
      console.log("Wishlist ", wishlist)
      const getlist = []
      let piqty = '0';
      let pi_value = "0";
      let price_date = this.state.price_date
      let wi_prtype = ''
      let wi_pcode = ''
      let segment_desc = ""
      let segment_code = ""
      let product_group = ""
      let product_desc = ""
      let product_code = ""
      let price = ""
      const bom_no = []
      let pi_qty = ""
      let container_capacity_qty = ""
      let container_capacity = ""
      let container_capacity_per = ""
      let product_type = ""
      let parent_product_code = ""
      let kit_flag = ""

      for (var w = 0; w < wishlist.length; w++) {

        // if (localStorage.getItem("segment") == 'EURO') {
        //   price_date = wishlist[w].price_date
        // }

        wi_prtype = wishlist[w].product_type
        segment_desc = wishlist[w].segment_desc
        segment_code = wishlist[w].segment_code
        product_group = wishlist[w].product_group
        product_desc = wishlist[w].product_desc
        product_code = wishlist[w].product_code
        price = wishlist[w].price
        price_date = moment(wishlist[w].created_at).format('YYYY-MM-DD')
        pi_qty = wishlist[w].pi_qty
        pi_value = wishlist[w].pi_value
        container_capacity_qty = wishlist[w].container_qty
        container_capacity = wishlist[w].container_capacity
        container_capacity_per = wishlist[w].container_capacity
        product_type = wishlist[w].product_type
        parent_product_code = wishlist[w].parent_product_code
        kit_flag = "S"


        getlist.push({
          segment_desc: segment_desc,
          segment_code: segment_code,
          product_group: product_group,
          product_desc: product_desc,
          product_code: product_code,
          price: price,
          price_date: price_date,
          bom_no: "0",
          bom_no1: "",
          pi_qty: pi_qty,
          pi_value: pi_value,
          container_capacity_qty: container_capacity_qty,
          container_capacity: container_capacity,
          container_capacity_per: container_capacity_per,
          product_type: product_type,
          parent_product_code: parent_product_code,
          kit_flag: kit_flag
        })

        console.log("Maniwish", getlist)

        if (wi_prtype == 'Set') {
          wi_pcode = wishlist[w].product_code

          axios({
            method: "POST",
            url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
            },
            data: {
              'product_code': wishlist[w].product_code,
            }
          }).then((response) => {
            console.log("bomlist", response.data.data)
            const getbomkit = []
            let kibom = response.data.data;
            var items = getlist
            var kitbom = response.data.data
            let item_pcode = ''
            let kit_pcode = ''

            for (var b = 0; b < kitbom.length; b++) {
              getbomkit.push({
                // product_code :kitbom[b].product_code,
                alt_bom_no: kitbom[b].alt_bom_no
              })
            }
            this.setState({
              wish_kit_bom: getbomkit
            })

            for (var k = 0; k < kitbom.length; k++) {
              kit_pcode = kitbom[k].product_code

              for (var b = 0; b < items.length; b++) {
                item_pcode = items[b].product_code

                if (kit_pcode == item_pcode) {
                  items[b].bom_no = response.data.data[0].alt_bom_no
                  items[b].bom_no1 = response.data.data
                } else {

                }
              }

            }
            console.log("Maniwishlist ", getlist)

            this.setState({
              add_get_size: getlist
            })

          });
        } else {
          this.setState({
            add_get_size: getlist
          })
        }


      }

      this.calculation()


    });

    // axios({
    //   method: "POST",
    //   url: this.state.baseurl + 'listofwishpi',//"http://localhost:3300/custdetails",
    //   data: {
    //     desc : wish,
    //     "cus_code" : this.state.customer_code,
    //     "order_no" :this.state.order_number,
    //     "order_version":this.state.order_version,
    //     "fromdate":this.state.price_date,
    //     "todate":this.state.price_date
    //   }

    // }).then((response) => {
    //   console.log("Maniwish ", response.data.data )

    //   const wishlist   = response.data.data;
    //   const getlist  =[]
    //   let piqty ='0';
    //   let pi_value  ="0";
    //   for (var w =0 ;w < wishlist.length; w++){


    //     getlist.push({
    //       segment_desc: wishlist[w].segment_desc,
    //       segment_code :wishlist[w].segment_code,
    //       product_group :wishlist[w].product_group,
    //       product_desc :wishlist[w].product_desc,
    //       product_code :wishlist[w].product_code,
    //       price :wishlist[w].price,
    //       price_date :this.state.price_date,
    //       pi_qty:wishlist[w].pi_qty,
    //       pi_value:wishlist[w].pi_value,
    //     })
    //   }

    //   this.setState({
    //     add_get_size: getlist, // you have it as this.data not response
    //   });
    // });

  }

  toggleewishviewclose = () => {
    this.setState(prevState => ({
      modalwishview: !prevState.modalwishview,
    }));
  }




  handle_find = (e) => {
    e.preventDefault()
    console.log("Manidesc", e.target.value)

    this.setState({
      find_search: e.target.value.toUpperCase(),
      checked: false,
      get_wishlist: []
    })


    axios({
      method: "POST",
      url: this.state.baseurl + 'wishlistfind',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "customer_sap_code": this.state.customer_code,
        "wish_list": this.state.selwish,
        "product_desc": e.target.value.toUpperCase()
      }
    }).then((response) => {
      console.log("Testwsishlistfind", response.data.data)
      this.setState({
        get_wishlist: response.data.data, // you have it as this.data not response
      });
    });


  }

  handle_find_code = (e) => {
    e.preventDefault()
    console.log("Manidesc", e.target.value)

    this.setState({
      find_search_code: e.target.value.toUpperCase(),
      checked: false,
      get_wishlist: []
    })


    axios({
      method: "POST",
      url: this.state.baseurl + 'wishlistfind',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "customer_sap_code": this.state.customer_code,
        "wish_list": this.state.selwish,
        "product_code": e.target.value.toUpperCase()
      }
    }).then((response) => {
      console.log("Testwsishlistfind", response.data.data)
      this.setState({
        get_wishlist: response.data.data, // you have it as this.data not response
      });
    });


  }


  handle_product_code = (e) => {
    e.preventDefault()
    this.setState({
      find_pr_code: e.target.value
    })
    axios({
      method: "POST",
      url: this.state.baseurl + 'listproduct_view',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "product_code": e.target.value
      }
    }).then((response) => {
      console.log("Testwsishlistfind", response.data.data)
      this.setState({
        product_view: response.data.data, // you have it as this.data not response
      });
    });
  }

  handle_product_desc = (e) => {
    e.preventDefault()
    this.setState({
      find_pr_desc: e.target.value
    })
    axios({
      method: "POST",
      url: this.state.baseurl + 'listproduct_view',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "product_desc": e.target.value
      }
    }).then((response) => {
      console.log("Testwsishlistfind", response.data.data)
      this.setState({
        product_view: response.data.data, // you have it as this.data not response
      });
    });
  }



  handle_month_year = (e) => {

    e.preventDefault()
    this.state.add_get_size = []
    this.state.get_cu_details = []
    this.state.get_order_no = []
    this.state.get_order_verion = []
    this.state.order_number = ''

    var gtno = ''
    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code
    }

    this.setState({
      month_year: e.target.value
    })
    let get_date = e.target.value
    axios({
      method: "POST",
      url: this.state.baseurl + 'showorder_list',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        customer_sap_code: custcode,
        order_mmyyyy: e.target.value,
      }
    }).then((response) => {
      console.log("Order123", response.data.data[0])
      gtno = response.data.data[0]
      this.setState({
        get_order_no: response.data.data,
        order_number: response.data.data[0],

      });
      axios({
        method: "POST",
        url: this.state.baseurl + 'showversion_list',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          customer_sap_code: custcode,
          order_no: response.data.data[0],
          order_mmyyyy: get_date

        }
      }).then((response) => {
        console.log("version", response.data.data[0])
        this.setState({
          order_version: response.data.data[0],
          get_order_verion: response.data.data,
        });
        axios({
          method: "POST",
          url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            customer_sap_code: custcode,
            order_no: this.state.order_number,
            order_version: this.state.order_version,
            order_mmyyyy: get_date
          }
        }).then((response) => {
          console.log("custhdrsMonth and year", response.data.hdrs)
          var get_cu = response.data.hdrs
          var get_tag = ''
          var get_shipdate = ''
          const date = new Date();
          const defaultValue = date.toLocaleDateString('en-CA');
          if (gtno == 'New') {

            console.log("Maniordernew", response.data.hdrs)


            this.state.pistatus = 'Open'
            this.state.action_status = 'New'
            this.state.get_tot = '0'
            this.state.displaycontainercapper = 0
            this.state.get_conta = 0
            this.state.po_number = ''
            this.state.pinumber = ""
            this.state.po_date = defaultValue
            this.state.sonumber = ''
            this.state.shipdate = defaultValue
            var targetDate = new Date();
            targetDate.setDate(targetDate.getDate() + 60);

            if (response.data.hdrs != '') {
              var get_cu = response.data.hdrs
              var get_tag = ''
              for (var j = 0; j < get_cu.length; j++) {
                console.log("Manitag", get_cu[j].payment_term)
                this.state.curreny = get_cu[j].order_category
                this.state.pay_term = get_cu[j].payment_term;
                get_tag = get_cu[j].payment_term;
                this.state.incotern = get_cu[j].inco_term;
                this.state.port_delivery = get_cu[j].port_of_discharge;
                this.state.customer_code = get_cu[j].customer_sap_code;
                this.state.customer_id = get_cu[j].customer_id
                this.state.order_type = get_cu[j].order_type
                this.state.container_size = get_cu[j].container_type
                this.state.po_number = get_cu[j].po_number
                // this.state.po_date =  moment(get_cu[j].po_date).format('YYYY-MM-DD')
                this.state.pistatus = "Open"
                this.state.pinumber = ""
                this.state.container_size = get_cu[j].container_type
                this.state.notes = get_cu[j].notes
                this.state.priority_item = get_cu[j].priority_item
                this.state.final_desc = get_cu[j].final_destination
                this.state.displaycontainercapper = ""
                this.state.get_tot = ""
                this.state.get_conta = ""
                this.state.action_status = ""
                this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
                this.state.portofdischarge = get_cu[j].port_of_discharge
                this.state.shipement_type = get_cu[j].precarriage_by
                this.state.remarkes = get_cu[j].remarks_quo
                this.state.remarkes1 = get_cu[j].remarks_so
                this.state.discount = get_cu[j].discount
                this.state.insurance = get_cu[j].insurance
                this.state.freight = get_cu[j].freight
                //  this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                this.state.incoternlocation = get_cu[j].inco_term_location
                this.state.priority_item = get_cu[j].priority_item
                this.state.notifyparty = get_cu[j].notify_party_info1
                this.state.notifyinfo = get_cu[j].notify_party_info1
                this.state.notifyinfo1 = get_cu[j].notify_party_info2
                this.state.notifyinfo2 = get_cu[j].notify_party_info3
                this.state.notifyinfo3 = get_cu[j].notify_party_info4
                this.state.notifyinfo4 = get_cu[j].notify_party_info5
                this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
                this.state.filler_item = get_cu[j].filler_item
                this.state.notifymastercode = get_cu[j].notifyparty_sap_code
                if (get_cu[j].ad_code != null) {
                  this.state.ad_code = get_cu[j].ad_code
                } else {
                  this.state.ad_code = "0009999"
                }


                if (localStorage.getItem('segment') == 'OHT') {
                  this.state.validto = targetDate.toLocaleDateString('en-CA')
                } else {
                  this.state.validto = targetDate.toLocaleDateString('en-CA')
                }
                // if (get_cu[j].pi_valid_to != null) {
                //   this.state.validto = moment(get_cu[j].pi_valid_to).format('YYYY-MM-DD')
                // } else {

                // }
                this.state.notychecked = false
                // if (this.state.notifymastercode != "") {


                //   let notycode = this.state.notifymastercode

                //   if (localStorage.getItem('segment') != 'OHT') {
                //     axios({
                //       method: "POST",
                //       url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",
                //       headers: {
                //         'Content-Type': 'application/json',
                //         'token': localStorage.getItem('token')
                //       },

                //       data: {
                //         customer_sap_code: this.state.customer_code,
                //         notify_sap_code: notycode,
                //         segment: localStorage.getItem('segment')
                //       }
                //     }).then((response) => {

                //       if (response.data.status == 'Success') {
                //         this.setState({
                //           notifymastername: response.data.data[0].notify_name
                //         });
                //       }


                //     });
                //   } else {
                //     axios({
                //       method: "POST",
                //       url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",

                //       headers: {
                //         'Content-Type': 'application/json',
                //         'token': localStorage.getItem('token')
                //       },

                //       data: {
                //         customer_sap_code: notycode,
                //         segment: localStorage.getItem('segment')
                //       }
                //     }).then((response) => {

                //       console.log("testoht", response.data.data)
                //       if (response.data.status == 'Success') {
                //         this.setState({
                //           notifymastername: response.data.data[0].customer_name
                //         });
                //       }


                //     });
                //   }


                // }

              }

              if (localStorage.getItem('usertypecode') == 'CUST') {
                axios({
                  method: "POST",
                  url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    tag_value: get_tag,

                  }
                }).then((response) => {
                  console.log("Testpayment", response.data.data)
                  this.setState({
                    payment_term_code: response.data.data[0].tag_desc
                  })


                });
              } else {
                this.state.pay_term = get_tag
              }

              console.log("Maniwish05 ", response.data.data)
              const wishlist = response.data.data;
              const getlist = []
              let piqty = '0';
              let pi_value = "0";
              let price_date = this.state.price_date
              let wi_prtype = ''
              let wi_pcode = ''
              let segment_desc = ""
              let segment_code = ""
              let product_group = ""
              let product_desc = ""
              let product_code = ""
              let price = ""
              let pi_qty = ""
              let container_capacity_qty = ""
              let container_capacity = ""
              let container_capacity_per = ""
              let product_type = ""
              let parent_product_code = ""
              let bom_no = ''
              let id = ''

              for (var w = 0; w < wishlist.length; w++) {

                if (localStorage.getItem("segment") == 'EURO') {
                  price_date = wishlist[w].price_date
                }

                wi_prtype = wishlist[w].product_type
                segment_desc = wishlist[w].segment_desc
                segment_code = wishlist[w].segment_code
                product_group = wishlist[w].product_group
                product_desc = wishlist[w].product_desc
                product_code = wishlist[w].product_code
                price = wishlist[w].price
                price_date = wishlist[w].price_date
                pi_qty = wishlist[w].pi_qty
                pi_value = wishlist[w].pi_value
                container_capacity_qty = wishlist[w].container_capacity_qty
                container_capacity = wishlist[w].container_capacity
                container_capacity_per = wishlist[w].container_capacity_per
                product_type = wishlist[w].product_type
                parent_product_code = wishlist[w].parent_product_code
                bom_no = wishlist[w].bom_no
                id = wishlist[w].id

                getlist.push({
                  segment_desc: segment_desc,
                  segment_code: segment_code,
                  product_group: product_group,
                  product_desc: product_desc,
                  product_code: product_code,
                  price: price,
                  price_date: price_date,
                  bom_no: bom_no,
                  bom_no1: "",
                  pi_qty: pi_qty,
                  pi_value: pi_value,
                  container_capacity_qty: container_capacity_qty,
                  container_capacity: container_capacity,
                  container_capacity_per: container_capacity_per,
                  product_type: product_type,
                  parent_product_code: parent_product_code,
                  id: id
                })


                if (wi_prtype == 'Set') {
                  wi_pcode = wishlist[w].product_code

                  axios({
                    method: "POST",
                    url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                    headers: {
                      'Content-Type': 'application/json',
                      'token': localStorage.getItem('token')
                    },
                    data: {
                      'product_code': wishlist[w].product_code,
                    }
                  }).then((response) => {
                    const getbomkit = []
                    let kibom = response.data.data;
                    var items = getlist
                    var kitbom = response.data.data
                    let item_pcode = ''
                    let kit_pcode = ''

                    for (var b = 0; b < kitbom.length; b++) {
                      getbomkit.push({
                        // product_code :kitbom[b].product_code,
                        alt_bom_no: kitbom[b].alt_bom_no
                      })
                    }
                    this.setState({
                      wish_kit_bom: getbomkit
                    })

                    for (var k = 0; k < kitbom.length; k++) {
                      kit_pcode = kitbom[k].product_code

                      for (var b = 0; b < items.length; b++) {
                        item_pcode = items[b].product_code

                        if (kit_pcode == item_pcode) {
                          items[b].bom_no1 = response.data.data
                          items[b].bom_no = response.data.data[0].alt_bom_no
                        } else {

                        }
                      }
                    }
                    this.setState({
                      add_get_size: getlist
                    })
                  });
                } else {
                  console.log("Testmanicode", getlist)
                  this.setState({
                    add_get_size: getlist
                  })
                }


              }

              this.setState({
                //add_get_size: response.data.data,
                get_cu_details: response.data.hdrs,
                // you have it as this.data not response
              });
            } else {
              this.setState({
                add_get_size: response.data.data,
                get_cu_details: response.data.hdrs,
                // you have it as this.data not response
              });
            }
          } else {
            console.log("Maniorder123", response.data.hdrs)
            this.state.action_status = ""
            this.state.pistatus = ""
            if (response.data.status == 'Success') {

              var get_cu = response.data.hdrs

              var get_tag = ''
              const date = new Date();
              const defaultValue = date.toLocaleDateString('en-CA');
              var targetDate = new Date();
              targetDate.setDate(targetDate.getDate() + 60);
              for (var j = 0; j < get_cu.length; j++) {

                this.state.curreny = get_cu[j].order_category
                this.state.pay_term = get_cu[j].payment_term;
                get_tag = get_cu[j].payment_term;
                this.state.incotern = get_cu[j].inco_term;
                this.state.port_delivery = get_cu[j].port_of_discharge;
                this.state.customer_code = get_cu[j].customer_sap_code;
                this.state.customer_id = get_cu[j].customer_id
                this.state.order_type = get_cu[j].order_type
                this.state.container_size = get_cu[j].container_type
                this.state.po_number = get_cu[j].po_number
                this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
                this.state.pistatus = get_cu[j].pi_status
                this.state.pinumber = get_cu[j].pi_number
                this.state.container_size = get_cu[j].container_type
                this.state.notes = get_cu[j].notes
                this.state.priority_item = get_cu[j].priority_item
                this.state.final_desc = get_cu[j].final_destination
                this.state.displaycontainercapper = get_cu[j].container_capacity
                this.state.get_tot = get_cu[j].total_order_qty
                this.state.get_conta = get_cu[j].no_of_containers
                this.state.action_status = get_cu[j].action_status
                this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
                this.state.sonumber = get_cu[j].so_number
                this.state.remarkes = get_cu[j].remarks_quo
                this.state.remarkes1 = get_cu[j].remarks_so
                this.state.portofdischarge = get_cu[j].port_of_discharge
                this.state.incoternlocation = get_cu[j].inco_term_location
                this.state.shipement_type = get_cu[j].precarriage_by
                //  this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                get_shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
                this.state.filler_item = get_cu[j].filler_item
                this.state.notifymastercode = get_cu[j].notifyparty_sap_code
                this.state.priority_item = get_cu[j].priority_item
                this.state.notifyparty = get_cu[j].notify_party_info1
                this.state.notifyinfo = get_cu[j].notify_party_info1
                this.state.notifyinfo1 = get_cu[j].notify_party_info2
                this.state.notifyinfo2 = get_cu[j].notify_party_info3
                this.state.notifyinfo3 = get_cu[j].notify_party_info4
                this.state.notifyinfo4 = get_cu[j].notify_party_info5
                this.state.discount = get_cu[j].discount
                this.state.insurance = get_cu[j].insurance
                this.state.freight = get_cu[j].freight

                if (localStorage.getItem('segment') == 'OHT') {
                  this.state.shipdate = defaultValue
                } else {
                  this.state.shipdate = get_shipdate
                }


                if (this.state.notifymastercode != "") {
                  this.state.notychecked = true
                  let notycode = this.state.notifymastercode

                  if (localStorage.getItem('segment') != 'OHT') {
                    axios({
                      method: "POST",
                      url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",
                      headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                      },

                      data: {
                        customer_sap_code: this.state.customer_code,
                        notify_sap_code: notycode,
                        segment: localStorage.getItem('segment')
                      }
                    }).then((response) => {

                      if (response.data.status == 'Success') {
                        this.setState({
                          notifymastername: response.data.data[0].notify_name
                        });
                      }


                    });
                  } else {
                    axios({
                      method: "POST",
                      url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",

                      headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                      },

                      data: {
                        customer_sap_code: notycode,
                        segment: localStorage.getItem('segment')
                      }
                    }).then((response) => {

                      console.log("testoht", response.data.data)
                      if (response.data.status == 'Success') {
                        this.setState({
                          notifymastername: response.data.data[0].customer_name
                        });
                      }


                    });
                  }

                }
                if (get_cu[j].ad_code != null) {
                  this.state.ad_code = get_cu[j].ad_code
                } else {
                  this.state.ad_code = "0009999"
                }

                if (get_cu[j].pi_valid_to != null) {
                  this.state.validto = targetDate.toLocaleDateString('en-CA')
                } else {
                  this.state.validto = targetDate.toLocaleDateString('en-CA')
                }

              }


              if (localStorage.getItem('usertypecode') == 'CUST') {
                axios({
                  method: "POST",
                  url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    tag_value: get_tag,

                  }
                }).then((response) => {
                  console.log("Test", response.data.data[0].tag_desc)
                  this.setState({
                    payment_term_code: response.data.data[0].tag_desc
                  })


                });
              } else {
                this.state.pay_term = get_tag
              }


              console.log("MonthYearDetails ", response.data.data)
              const wishlist = response.data.data;
              const getlist = []
              let piqty = '0';
              let pi_value = "0";
              let price_date = this.state.price_date
              let wi_prtype = ''
              let wi_pcode = ''
              let segment_desc = ""
              let segment_code = ""
              let product_group = ""
              let product_desc = ""
              let product_code = ""
              let price = ""
              let pi_qty = ""
              let container_capacity_qty = ""
              let container_capacity = ""
              let container_capacity_per = ""
              let product_type = ""
              let parent_product_code = ""
              let bom_no = ''
              let id = ''

              for (var w = 0; w < wishlist.length; w++) {

                if (localStorage.getItem("segment") == 'EURO') {
                  price_date = wishlist[w].price_date
                }

                wi_prtype = wishlist[w].product_type
                segment_desc = wishlist[w].segment_desc
                segment_code = wishlist[w].segment_code
                product_group = wishlist[w].product_group
                product_desc = wishlist[w].product_desc
                product_code = wishlist[w].product_code
                price = wishlist[w].price
                price_date = wishlist[w].price_date
                pi_qty = wishlist[w].pi_qty
                pi_value = wishlist[w].pi_value
                container_capacity_qty = wishlist[w].container_capacity_qty
                container_capacity = wishlist[w].container_capacity
                container_capacity_per = wishlist[w].container_capacity_per
                product_type = wishlist[w].product_type
                parent_product_code = wishlist[w].parent_product_code
                bom_no = wishlist[w].bom_no
                id = wishlist[w].id

                getlist.push({
                  segment_desc: segment_desc,
                  segment_code: segment_code,
                  product_group: product_group,
                  product_desc: product_desc,
                  product_code: product_code,
                  price: price,
                  price_date: price_date,
                  bom_no: bom_no,
                  bom_no1: "",
                  pi_qty: pi_qty,
                  pi_value: pi_value,
                  container_capacity_qty: container_capacity_qty,
                  container_capacity: container_capacity,
                  container_capacity_per: container_capacity_per,
                  product_type: product_type,
                  parent_product_code: parent_product_code,
                  id: id
                })


                if (wi_prtype == 'Set') {
                  wi_pcode = wishlist[w].product_code

                  axios({
                    method: "POST",
                    url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                    headers: {
                      'Content-Type': 'application/json',
                      'token': localStorage.getItem('token')
                    },
                    data: {
                      'product_code': wishlist[w].product_code,
                    }
                  }).then((response) => {
                    const getbomkit = []
                    let kibom = response.data.data;
                    var items = getlist
                    var kitbom = response.data.data
                    let item_pcode = ''
                    let kit_pcode = ''

                    for (var b = 0; b < kitbom.length; b++) {
                      getbomkit.push({
                        // product_code :kitbom[b].product_code,
                        alt_bom_no: kitbom[b].alt_bom_no
                      })
                    }
                    this.setState({
                      wish_kit_bom: getbomkit
                    })

                    for (var k = 0; k < kitbom.length; k++) {
                      kit_pcode = kitbom[k].product_code

                      for (var b = 0; b < items.length; b++) {
                        item_pcode = items[b].product_code

                        if (kit_pcode == item_pcode) {
                          items[b].bom_no1 = response.data.data
                          //items[b].bom_no = response.data.data[0].alt_bom_no
                        } else {

                        }
                      }
                    }
                    this.setState({
                      add_get_size: getlist
                    })
                  });
                } else {
                  console.log("Testmanicode", getlist)
                  this.setState({
                    add_get_size: getlist
                  })
                }


              }


              this.setState({
                // add_get_size: response.data.data,
                get_cu_details: response.data.hdrs,
                // you have it as this.data not response
              });
            } else {
              this.state.order_version = ''
              var get_cu = response.data.hdrs
              const date = new Date();
              const defaultValue = date.toLocaleDateString('en-CA');
              var targetDate = new Date();
              targetDate.setDate(targetDate.getDate() + 60);
              this.state.pistatus = 'Open'
              this.state.action_status = 'New'
              this.state.get_tot = '0'
              this.state.displaycontainercapper = 0
              this.state.get_conta = 0
              this.state.po_number = ''
              this.state.port_delivery = ''
              this.state.pinumber = ""
              this.state.po_date = defaultValue

              var get_tag = ''
              for (var j = 0; j < get_cu.length; j++) {

                this.state.curreny = get_cu[j].order_category
                this.state.pay_term = get_cu[j].payment_term;
                get_tag = get_cu[j].payment_term;
                this.state.incotern = get_cu[j].inco_term;
                this.state.port_delivery = get_cu[j].port_of_discharge;
                this.state.customer_code = get_cu[j].customer_sap_code;
                this.state.customer_id = get_cu[j].customer_id
                this.state.order_type = get_cu[j].order_type
                this.state.container_size = get_cu[j].container_type
                this.state.po_number = get_cu[j].po_number
                this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
                this.state.pistatus = ''
                this.state.pinumber = ''
                this.state.container_size = get_cu[j].container_type
                this.state.notes = get_cu[j].notes
                this.state.priority_item = get_cu[j].priority_item
                this.state.final_desc = get_cu[j].final_destination
                this.state.displaycontainercapper = get_cu[j].container_capacity
                this.state.get_tot = get_cu[j].total_order_qty
                this.state.get_conta = get_cu[j].no_of_containers
                this.state.action_status = ''
                this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
                this.state.portofdischarge = get_cu[j].port_of_discharge
                this.state.incoternlocation = get_cu[j].inco_term_location
                this.state.shipement_type = get_cu[j].precarriage_by
                this.state.ad_code = get_cu[j].ad_code
                this.state.validto = targetDate.toLocaleDateString('en-CA')

              }

              if (localStorage.getItem('usertypecode') == 'CUST') {
                axios({
                  method: "POST",
                  url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    tag_value: get_tag,

                  }
                }).then((response) => {
                  console.log("Test", response.data.data[0].tag_desc)
                  this.setState({
                    payment_term_code: response.data.data[0].tag_desc
                  })


                });
              } else {
                this.state.pay_term = get_tag
              }

              console.log("Maniwish05 ", response.data.data)
              const wishlist = response.data.data;
              const getlist = []
              let piqty = '0';
              let pi_value = "0";
              let price_date = this.state.price_date
              let wi_prtype = ''
              let wi_pcode = ''
              let segment_desc = ""
              let segment_code = ""
              let product_group = ""
              let product_desc = ""
              let product_code = ""
              let price = ""
              let pi_qty = ""
              let container_capacity_qty = ""
              let container_capacity = ""
              let container_capacity_per = ""
              let product_type = ""
              let parent_product_code = ""
              let bom_no = ''
              let id = ''

              for (var w = 0; w < wishlist.length; w++) {

                if (localStorage.getItem("segment") == 'EURO') {
                  price_date = wishlist[w].price_date
                }

                wi_prtype = wishlist[w].product_type
                segment_desc = wishlist[w].segment_desc
                segment_code = wishlist[w].segment_code
                product_group = wishlist[w].product_group
                product_desc = wishlist[w].product_desc
                product_code = wishlist[w].product_code
                price = wishlist[w].price
                price_date = wishlist[w].price_date
                pi_qty = wishlist[w].pi_qty
                pi_value = wishlist[w].pi_value
                container_capacity_qty = wishlist[w].container_capacity_qty
                container_capacity = wishlist[w].container_capacity
                container_capacity_per = wishlist[w].container_capacity_per
                product_type = wishlist[w].product_type
                parent_product_code = wishlist[w].parent_product_code
                bom_no = wishlist[w].bom_no
                id = wishlist[w].id

                getlist.push({
                  segment_desc: segment_desc,
                  segment_code: segment_code,
                  product_group: product_group,
                  product_desc: product_desc,
                  product_code: product_code,
                  price: price,
                  price_date: price_date,
                  bom_no: bom_no,
                  bom_no1: "",
                  pi_qty: pi_qty,
                  pi_value: pi_value,
                  container_capacity_qty: container_capacity_qty,
                  container_capacity: container_capacity,
                  container_capacity_per: container_capacity_per,
                  product_type: product_type,
                  parent_product_code: parent_product_code,
                  id: id
                })


                if (wi_prtype == 'Set') {
                  wi_pcode = wishlist[w].product_code

                  axios({
                    method: "POST",
                    url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                    headers: {
                      'Content-Type': 'application/json',
                      'token': localStorage.getItem('token')
                    },
                    data: {
                      'product_code': wishlist[w].product_code,
                    }
                  }).then((response) => {
                    const getbomkit = []
                    let kibom = response.data.data;
                    var items = getlist
                    var kitbom = response.data.data
                    let item_pcode = ''
                    let kit_pcode = ''

                    for (var b = 0; b < kitbom.length; b++) {
                      getbomkit.push({
                        // product_code :kitbom[b].product_code,
                        alt_bom_no: kitbom[b].alt_bom_no
                      })
                    }
                    this.setState({
                      wish_kit_bom: getbomkit
                    })

                    for (var k = 0; k < kitbom.length; k++) {
                      kit_pcode = kitbom[k].product_code

                      for (var b = 0; b < items.length; b++) {
                        item_pcode = items[b].product_code

                        if (kit_pcode == item_pcode) {
                          items[b].bom_no1 = response.data.data
                          items[b].bom_no = response.data.data[0].alt_bom_no
                        } else {

                        }
                      }
                    }
                    this.setState({
                      add_get_size: getlist
                    })
                  });
                } else {
                  console.log("Testmanicode", getlist)
                  this.setState({
                    add_get_size: getlist
                  })
                }


              }


              this.setState({
                // add_get_size: response.data.data,
                get_cu_details: response.data.hdrs,
                // you have it as this.data not response
              });

            }

          }


        });
      });
    });



    axios({
      method: "POST",
      url: this.state.baseurl + 'monthlygroupdtls',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        customer_sap_code: this.state.customer_code,
        order_mmyyyy: e.target.value
      }
    }).then((response) => {

      this.setState({
        get_order_s: response.data.data,
        // you have it as this.data not response
      });
    });


    axios({
      method: "POST",
      url: this.state.baseurl + 'monthlydtls_view',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        customer_sap_code: this.state.customer_code,
        order_mmyyyy: e.target.value,

      }
    }).then((response) => {
      console.log("GET", response.data.data)
      this.setState({
        orderaddmodel: response.data.data, // you have it as this.data not response
      });
    });


  }

  handle_adcode = (e) => {
    e.preventDefault()
    alert("Mani", e.target.value)
    this.setState({
      ad_code: e.target.value
    })
  }

  handle_validto = (e) => {
    e.preventDefault()
    this.setState({
      validto: e.target.value
    })
  }

  handle_ponumber = (e) => {
    e.preventDefault()
    this.setState({
      po_number: e.target.value
    })
  }

  handle_podate = (e) => {
    e.preventDefault()
    this.setState({
      po_date: e.target.value
    })
  }
  handle_order_number = (e) => {
    this.setState({
      order_number: e.target.value,
    })
    this.state.add_get_size = []
    this.state.piapprovedstatus = ''
    this.state.notifymastercode = ''
    this.state.shipement_type = ''
    this.state.notychecked = false
    this.state.sonumber = ''
    this.state.pay_term = ''
    var gtno = e.target.value
    axios({
      method: "POST",
      url: this.state.baseurl + 'showversion_list',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        customer_sap_code: this.state.customer_code,
        order_mmyyyy: this.state.month_year,
        order_no: e.target.value

      }
    }).then((response) => {
      console.log("versionorder", response.data.data[0])
      this.setState({
        order_version: response.data.data[0],
        get_order_verion: response.data.data,
      });

      axios({
        method: "POST",
        url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          customer_sap_code: this.state.customer_code,
          order_mmyyyy: this.state.month_year,
          order_no: this.state.order_number,
          order_version: this.state.order_version.toString()
        }
      }).then((response) => {

        console.log("DetailsOrder", response.data.hdrs)

        if (gtno == 'New') {

          console.log("Maniordernew", response.data.hdrs)

          const date = new Date();
          const defaultValue = date.toLocaleDateString('en-CA');
          this.state.pistatus = 'Open'
          this.state.action_status = 'New'
          this.state.get_tot = '0'
          this.state.displaycontainercapper = 0
          this.state.get_conta = 0
          this.state.po_number = ''
          this.state.pinumber = ""
          this.state.po_date = defaultValue
          this.state.sonumber = ''
          this.state.shipdate = defaultValue
          this.state.notifymastercode = ""
          var targetDate = new Date();
          targetDate.setDate(targetDate.getDate() + 60);

          if (response.data.hdrs != '') {
            var get_cu = response.data.hdrs
            var get_tag = ''
            for (var j = 0; j < get_cu.length; j++) {
              console.log("Manitag", get_cu[j].payment_term)
              this.state.curreny = get_cu[j].order_category
              this.state.pay_term = get_cu[j].payment_term;
              get_tag = get_cu[j].payment_term;
              this.state.incotern = get_cu[j].inco_term;
              this.state.port_delivery = get_cu[j].port_of_discharge;
              this.state.customer_code = get_cu[j].customer_sap_code;
              this.state.customer_id = get_cu[j].customer_id
              this.state.order_type = get_cu[j].order_type
              this.state.container_size = get_cu[j].container_type
              this.state.po_number = get_cu[j].po_number
              // this.state.po_date =  moment(get_cu[j].po_date).format('YYYY-MM-DD')
              this.state.pistatus = "Open"
              this.state.pinumber = ""
              this.state.container_size = get_cu[j].container_type
              this.state.notes = get_cu[j].notes
              this.state.priority_item = get_cu[j].priority_item
              this.state.final_desc = get_cu[j].final_destination
              this.state.displaycontainercapper = ""
              this.state.get_tot = ""
              this.state.get_conta = ""
              this.state.action_status = ""
              this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
              this.state.portofdischarge = get_cu[j].port_of_discharge
              this.state.shipement_type = get_cu[j].precarriage_by
              this.state.remarkes = get_cu[j].remarks_quo
              this.state.remarkes1 = get_cu[j].remarks_so
              this.state.discount = get_cu[j].discount
              this.state.insurance = get_cu[j].insurance
              this.state.freight = get_cu[j].freight
              //this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
              this.state.incoternlocation = get_cu[j].inco_term_location
              this.state.priority_item = get_cu[j].priority_item
              this.state.notifyparty = get_cu[j].notify_party_info1
              this.state.notifyinfo = get_cu[j].notify_party_info1
              this.state.notifyinfo1 = get_cu[j].notify_party_info2
              this.state.notifyinfo2 = get_cu[j].notify_party_info3
              this.state.notifyinfo3 = get_cu[j].notify_party_info4
              this.state.notifyinfo4 = get_cu[j].notify_party_info5
              this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
              this.state.filler_item = get_cu[j].filler_item
              //  this.state.notifymastercode = get_cu[j].notifyparty_sap_code
              if (get_cu[j].ad_code != null) {
                this.state.ad_code = get_cu[j].ad_code
              } else {
                this.state.ad_code = "0009999"
              }
              if (localStorage.getItem('segment') == 'OHT') {
                this.state.validto = targetDate.toLocaleDateString('en-CA')
              } else {
                this.state.validto = targetDate.toLocaleDateString('en-CA')
              }

              this.state.notychecked = false

              // if (get_cu[j].pi_valid_to != null) {
              //   this.state.validto = moment(get_cu[j].pi_valid_to).format('YYYY-MM-DD')
              // } else {

              // }
              // if (this.state.notifymastercode != "") {
              //   this.state.notychecked = true

              //   let notycode = this.state.notifymastercode

              //   if (localStorage.getItem('segment') != 'OHT') {
              //     axios({
              //       method: "POST",
              //       url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",
              //       headers: {
              //         'Content-Type': 'application/json',
              //         'token': localStorage.getItem('token')
              //       },

              //       data: {
              //         customer_sap_code: this.state.customer_code,
              //         notify_sap_code: notycode,
              //         segment: localStorage.getItem('segment')
              //       }
              //     }).then((response) => {

              //       if (response.data.status == 'Success') {
              //         this.setState({
              //           notifymastername: response.data.data[0].notify_name
              //         });
              //       }


              //     });
              //   } else {
              //     axios({
              //       method: "POST",
              //       url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",

              //       headers: {
              //         'Content-Type': 'application/json',
              //         'token': localStorage.getItem('token')
              //       },

              //       data: {
              //         customer_sap_code: notycode,
              //         segment: localStorage.getItem('segment')
              //       }
              //     }).then((response) => {

              //       console.log("testoht", response.data.data)
              //       if (response.data.status == 'Success') {
              //         this.setState({
              //           notifymastername: response.data.data[0].customer_name
              //         });
              //       }


              //     });
              //   }


              // }

            }

            if (localStorage.getItem('usertypecode') == 'CUST') {
              axios({
                method: "POST",
                url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                headers: {
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
                },
                data: {
                  tag_value: get_tag,

                }
              }).then((response) => {
                console.log("Testpayment", response.data.data)
                this.setState({
                  payment_term_code: response.data.data[0].tag_desc
                })


              });
            } else {
              this.state.pay_term = get_tag
            }

            console.log("Maniwish05 ", response.data.data)
            const wishlist = response.data.data;
            const getlist = []
            let piqty = '0';
            let pi_value = "0";
            let price_date = this.state.price_date
            let wi_prtype = ''
            let wi_pcode = ''
            let segment_desc = ""
            let segment_code = ""
            let product_group = ""
            let product_desc = ""
            let product_code = ""
            let price = ""
            let pi_qty = ""
            let container_capacity_qty = ""
            let container_capacity = ""
            let container_capacity_per = ""
            let product_type = ""
            let parent_product_code = ""
            let bom_no = ''
            let id = ''

            for (var w = 0; w < wishlist.length; w++) {

              if (localStorage.getItem("segment") == 'EURO') {
                price_date = wishlist[w].price_date
              }

              wi_prtype = wishlist[w].product_type
              segment_desc = wishlist[w].segment_desc
              segment_code = wishlist[w].segment_code
              product_group = wishlist[w].product_group
              product_desc = wishlist[w].product_desc
              product_code = wishlist[w].product_code
              price = wishlist[w].price
              price_date = wishlist[w].price_date
              pi_qty = wishlist[w].pi_qty
              pi_value = wishlist[w].pi_value
              container_capacity_qty = wishlist[w].container_capacity_qty
              container_capacity = wishlist[w].container_capacity
              container_capacity_per = wishlist[w].container_capacity_per
              product_type = wishlist[w].product_type
              parent_product_code = wishlist[w].parent_product_code
              bom_no = wishlist[w].bom_no
              id = wishlist[w].id

              getlist.push({
                segment_desc: segment_desc,
                segment_code: segment_code,
                product_group: product_group,
                product_desc: product_desc,
                product_code: product_code,
                price: price,
                price_date: price_date,
                bom_no: bom_no,
                bom_no1: "",
                pi_qty: pi_qty,
                pi_value: pi_value,
                container_capacity_qty: container_capacity_qty,
                container_capacity: container_capacity,
                container_capacity_per: container_capacity_per,
                product_type: product_type,
                parent_product_code: parent_product_code,
                id: id
              })


              if (wi_prtype == 'Set') {
                wi_pcode = wishlist[w].product_code

                axios({
                  method: "POST",
                  url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    'product_code': wishlist[w].product_code,
                  }
                }).then((response) => {
                  const getbomkit = []
                  let kibom = response.data.data;
                  var items = getlist
                  var kitbom = response.data.data
                  let item_pcode = ''
                  let kit_pcode = ''

                  for (var b = 0; b < kitbom.length; b++) {
                    getbomkit.push({
                      // product_code :kitbom[b].product_code,
                      alt_bom_no: kitbom[b].alt_bom_no
                    })
                  }
                  this.setState({
                    wish_kit_bom: getbomkit
                  })

                  for (var k = 0; k < kitbom.length; k++) {
                    kit_pcode = kitbom[k].product_code

                    for (var b = 0; b < items.length; b++) {
                      item_pcode = items[b].product_code

                      if (kit_pcode == item_pcode) {
                        items[b].bom_no1 = response.data.data
                        items[b].bom_no = response.data.data[0].alt_bom_no
                      } else {

                      }
                    }
                  }
                  this.setState({
                    add_get_size: getlist
                  })
                });
              } else {
                console.log("Testmanicode", getlist)
                this.setState({
                  add_get_size: getlist
                })
              }


            }

            this.setState({
              //add_get_size: response.data.data,
              get_cu_details: response.data.hdrs,
              // you have it as this.data not response
            });
          } else {
            this.setState({
              add_get_size: response.data.data,
              get_cu_details: response.data.hdrs,
              // you have it as this.data not response
            });
          }
        } else {
          console.log("Maniorder123", response.data.hdrs)
          this.state.action_status = ""
          this.state.pistatus = ""
          const date = new Date();
          const defaultValue = date.toLocaleDateString('en-CA');
          var targetDate = new Date();
          targetDate.setDate(targetDate.getDate() + 60);
          if (response.data.status == 'Success') {

            var get_cu = response.data.hdrs

            var get_tag = ''
            var get_shipdate = ''

            const date = new Date();
            const defaultValue = date.toLocaleDateString('en-CA');

            for (var j = 0; j < get_cu.length; j++) {

              this.state.curreny = get_cu[j].order_category
              this.state.pay_term = get_cu[j].payment_term;
              get_tag = get_cu[j].payment_term;
              this.state.incotern = get_cu[j].inco_term;
              this.state.port_delivery = get_cu[j].port_of_discharge;
              this.state.customer_code = get_cu[j].customer_sap_code;
              this.state.customer_id = get_cu[j].customer_id
              this.state.order_type = get_cu[j].order_type
              this.state.container_size = get_cu[j].container_type
              this.state.po_number = get_cu[j].po_number
              this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
              this.state.pistatus = get_cu[j].pi_status
              this.state.pinumber = get_cu[j].pi_number
              this.state.container_size = get_cu[j].container_type
              this.state.notes = get_cu[j].notes
              this.state.priority_item = get_cu[j].priority_item
              this.state.final_desc = get_cu[j].final_destination
              this.state.displaycontainercapper = get_cu[j].container_capacity
              this.state.get_tot = get_cu[j].total_order_qty
              this.state.get_conta = get_cu[j].no_of_containers
              this.state.action_status = get_cu[j].action_status
              this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
              this.state.sonumber = get_cu[j].so_number
              this.state.remarkes = get_cu[j].remarks_quo
              this.state.remarkes1 = get_cu[j].remarks_so
              this.state.portofdischarge = get_cu[j].port_of_discharge
              this.state.incoternlocation = get_cu[j].inco_term_location
              this.state.shipement_type = get_cu[j].precarriage_by
              //  this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
              get_shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
              this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
              this.state.filler_item = get_cu[j].filler_item
              this.state.notifymastercode = get_cu[j].notifyparty_sap_code
              this.state.priority_item = get_cu[j].priority_item
              this.state.notifyparty = get_cu[j].notify_party_info1
              this.state.notifyinfo = get_cu[j].notify_party_info1
              this.state.notifyinfo1 = get_cu[j].notify_party_info2
              this.state.notifyinfo2 = get_cu[j].notify_party_info3
              this.state.notifyinfo3 = get_cu[j].notify_party_info4
              this.state.notifyinfo4 = get_cu[j].notify_party_info5
              this.state.discount = get_cu[j].discount
              this.state.insurance = get_cu[j].insurance
              this.state.freight = get_cu[j].freight

              if (localStorage.getItem('segment') == 'OHT') {
                this.state.shipdate = defaultValue
              } else {
                this.state.shipdate = get_shipdate
              }

              if (this.state.notifymastercode != "") {
                this.state.notychecked = true
                let notycode = this.state.notifymastercode

                if (localStorage.getItem('segment') != 'OHT') {
                  axios({
                    method: "POST",
                    url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",
                    headers: {
                      'Content-Type': 'application/json',
                      'token': localStorage.getItem('token')
                    },

                    data: {
                      customer_sap_code: this.state.customer_code,
                      notify_sap_code: notycode,
                      segment: localStorage.getItem('segment')
                    }
                  }).then((response) => {

                    if (response.data.status == 'Success') {
                      this.setState({
                        notifymastername: response.data.data[0].notify_name
                      });
                    }


                  });
                } else {
                  axios({
                    method: "POST",
                    url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",

                    headers: {
                      'Content-Type': 'application/json',
                      'token': localStorage.getItem('token')
                    },

                    data: {
                      customer_sap_code: notycode,
                      segment: localStorage.getItem('segment')
                    }
                  }).then((response) => {

                    console.log("testoht", response.data.data)
                    if (response.data.status == 'Success') {
                      this.setState({
                        notifymastername: response.data.data[0].customer_name
                      });
                    }


                  });
                }

              }
              if (get_cu[j].ad_code != null) {
                this.state.ad_code = get_cu[j].ad_code
              } else {
                this.state.ad_code = "0009999"
              }

              if (get_cu[j].pi_valid_to != null) {
                this.state.validto = targetDate.toLocaleDateString('en-CA')
              } else {
                this.state.validto = targetDate.toLocaleDateString('en-CA')
              }

            }


            if (localStorage.getItem('usertypecode') == 'CUST') {
              axios({
                method: "POST",
                url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                headers: {
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
                },
                data: {
                  tag_value: get_tag,

                }
              }).then((response) => {
                console.log("Test", response.data.data[0].tag_desc)
                this.setState({
                  payment_term_code: response.data.data[0].tag_desc
                })


              });
            } else {
              this.state.pay_term = get_tag
            }


            console.log("OrderCustDetails ", response.data.data)
            const wishlist = response.data.data;
            const getlist = []
            let piqty = '0';
            let pi_value = "0";
            let price_date = this.state.price_date
            let wi_prtype = ''
            let wi_pcode = ''
            let segment_desc = ""
            let segment_code = ""
            let product_group = ""
            let product_desc = ""
            let product_code = ""
            let price = ""
            let pi_qty = ""
            let container_capacity_qty = ""
            let container_capacity = ""
            let container_capacity_per = ""
            let product_type = ""
            let parent_product_code = ""
            let bom_no = ''
            let id = ''

            for (var w = 0; w < wishlist.length; w++) {

              if (localStorage.getItem("segment") == 'EURO') {
                price_date = wishlist[w].price_date
              }

              wi_prtype = wishlist[w].product_type
              segment_desc = wishlist[w].segment_desc
              segment_code = wishlist[w].segment_code
              product_group = wishlist[w].product_group
              product_desc = wishlist[w].product_desc
              product_code = wishlist[w].product_code
              price = wishlist[w].price
              price_date = wishlist[w].price_date
              pi_qty = wishlist[w].pi_qty
              pi_value = wishlist[w].pi_value
              container_capacity_qty = wishlist[w].container_capacity_qty
              container_capacity = wishlist[w].container_capacity
              container_capacity_per = wishlist[w].container_capacity_per
              product_type = wishlist[w].product_type
              parent_product_code = wishlist[w].parent_product_code
              bom_no = wishlist[w].bom_no
              id = wishlist[w].id

              getlist.push({
                segment_desc: segment_desc,
                segment_code: segment_code,
                product_group: product_group,
                product_desc: product_desc,
                product_code: product_code,
                price: price,
                price_date: price_date,
                bom_no: bom_no,
                bom_no1: "",
                pi_qty: pi_qty,
                pi_value: pi_value,
                container_capacity_qty: container_capacity_qty,
                container_capacity: container_capacity,
                container_capacity_per: container_capacity_per,
                product_type: product_type,
                parent_product_code: parent_product_code,
                id: id
              })


              if (wi_prtype == 'Set') {
                wi_pcode = wishlist[w].product_code

                axios({
                  method: "POST",
                  url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    'product_code': wishlist[w].product_code,
                  }
                }).then((response) => {
                  const getbomkit = []
                  let kibom = response.data.data;
                  var items = getlist
                  var kitbom = response.data.data
                  let item_pcode = ''
                  let kit_pcode = ''

                  for (var b = 0; b < kitbom.length; b++) {
                    getbomkit.push({
                      // product_code :kitbom[b].product_code,
                      alt_bom_no: kitbom[b].alt_bom_no
                    })
                  }
                  this.setState({
                    wish_kit_bom: getbomkit
                  })

                  for (var k = 0; k < kitbom.length; k++) {
                    kit_pcode = kitbom[k].product_code

                    for (var b = 0; b < items.length; b++) {
                      item_pcode = items[b].product_code

                      if (kit_pcode == item_pcode) {
                        items[b].bom_no1 = response.data.data
                        //items[b].bom_no = response.data.data[0].alt_bom_no
                      } else {

                      }
                    }
                  }
                  this.setState({
                    add_get_size: getlist
                  })
                });
              } else {
                console.log("Testmanicode", getlist)
                this.setState({
                  add_get_size: getlist
                })
              }


            }


            this.setState({
              // add_get_size: response.data.data,
              get_cu_details: response.data.hdrs,
              // you have it as this.data not response
            });
          } else {
            this.state.order_version = ''
            var get_cu = response.data.hdrs
            var get_shipdate
            const date = new Date();
            const defaultValue = date.toLocaleDateString('en-CA');
            this.state.pistatus = 'Open'
            this.state.action_status = 'New'
            this.state.get_tot = '0'
            this.state.displaycontainercapper = 0
            this.state.get_conta = 0
            this.state.po_number = ''
            this.state.port_delivery = ''
            this.state.pinumber = ""
            this.state.po_date = defaultValue

            var get_tag = ''
            for (var j = 0; j < get_cu.length; j++) {

              this.state.curreny = get_cu[j].order_category
              this.state.pay_term = get_cu[j].payment_term;
              get_tag = get_cu[j].payment_term;
              this.state.incotern = get_cu[j].inco_term;
              this.state.port_delivery = get_cu[j].port_of_discharge;
              this.state.customer_code = get_cu[j].customer_sap_code;
              this.state.customer_id = get_cu[j].customer_id
              this.state.order_type = get_cu[j].order_type
              this.state.container_size = get_cu[j].container_type
              this.state.po_number = get_cu[j].po_number
              this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
              this.state.pistatus = ''
              this.state.pinumber = ''
              this.state.container_size = get_cu[j].container_type
              this.state.notes = get_cu[j].notes
              this.state.priority_item = get_cu[j].priority_item
              this.state.final_desc = get_cu[j].final_destination
              this.state.displaycontainercapper = get_cu[j].container_capacity
              this.state.get_tot = get_cu[j].total_order_qty
              this.state.get_conta = get_cu[j].no_of_containers
              this.state.action_status = ''
              this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
            }

            if (localStorage.getItem('usertypecode') == 'CUST') {
              axios({
                method: "POST",
                url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                headers: {
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
                },
                data: {
                  tag_value: get_tag,

                }
              }).then((response) => {
                console.log("Test", response.data.data[0].tag_desc)
                this.setState({
                  payment_term_code: response.data.data[0].tag_desc
                })


              });
            } else {
              this.state.pay_term = get_tag
            }

            console.log("Maniwish05 ", response.data.data)
            const wishlist = response.data.data;
            const getlist = []
            let piqty = '0';
            let pi_value = "0";
            let price_date = this.state.price_date
            let wi_prtype = ''
            let wi_pcode = ''
            let segment_desc = ""
            let segment_code = ""
            let product_group = ""
            let product_desc = ""
            let product_code = ""
            let price = ""
            let pi_qty = ""
            let container_capacity_qty = ""
            let container_capacity = ""
            let container_capacity_per = ""
            let product_type = ""
            let parent_product_code = ""
            let bom_no = ''
            let id = ''

            for (var w = 0; w < wishlist.length; w++) {

              if (localStorage.getItem("segment") == 'EURO') {
                price_date = wishlist[w].price_date
              }

              wi_prtype = wishlist[w].product_type
              segment_desc = wishlist[w].segment_desc
              segment_code = wishlist[w].segment_code
              product_group = wishlist[w].product_group
              product_desc = wishlist[w].product_desc
              product_code = wishlist[w].product_code
              price = wishlist[w].price
              price_date = wishlist[w].price_date
              pi_qty = wishlist[w].pi_qty
              pi_value = wishlist[w].pi_value
              container_capacity_qty = wishlist[w].container_capacity_qty
              container_capacity = wishlist[w].container_capacity
              container_capacity_per = wishlist[w].container_capacity_per
              product_type = wishlist[w].product_type
              parent_product_code = wishlist[w].parent_product_code
              bom_no = wishlist[w].bom_no
              id = wishlist[w].id

              getlist.push({
                segment_desc: segment_desc,
                segment_code: segment_code,
                product_group: product_group,
                product_desc: product_desc,
                product_code: product_code,
                price: price,
                price_date: price_date,
                bom_no: bom_no,
                bom_no1: "",
                pi_qty: pi_qty,
                pi_value: pi_value,
                container_capacity_qty: container_capacity_qty,
                container_capacity: container_capacity,
                container_capacity_per: container_capacity_per,
                product_type: product_type,
                parent_product_code: parent_product_code,
                id: id
              })


              if (wi_prtype == 'Set') {
                wi_pcode = wishlist[w].product_code

                axios({
                  method: "POST",
                  url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    'product_code': wishlist[w].product_code,
                  }
                }).then((response) => {
                  const getbomkit = []
                  let kibom = response.data.data;
                  var items = getlist
                  var kitbom = response.data.data
                  let item_pcode = ''
                  let kit_pcode = ''

                  for (var b = 0; b < kitbom.length; b++) {
                    getbomkit.push({
                      // product_code :kitbom[b].product_code,
                      alt_bom_no: kitbom[b].alt_bom_no
                    })
                  }
                  this.setState({
                    wish_kit_bom: getbomkit
                  })

                  for (var k = 0; k < kitbom.length; k++) {
                    kit_pcode = kitbom[k].product_code

                    for (var b = 0; b < items.length; b++) {
                      item_pcode = items[b].product_code

                      if (kit_pcode == item_pcode) {
                        items[b].bom_no1 = response.data.data
                        // items[b].bom_no = response.data.data[0].alt_bom_no
                      } else {

                      }
                    }
                  }
                  this.setState({
                    add_get_size: getlist
                  })
                });
              } else {
                console.log("Testmanicode", getlist)
                this.setState({
                  add_get_size: getlist
                })
              }


            }


            this.setState({
              // add_get_size: response.data.data,
              get_cu_details: response.data.hdrs,
              // you have it as this.data not response
            });

          }

        }


      });

    });





  }
  handle_order_version = (e) => {
    e.preventDefault()
    var pdf_urlpath = "http://localhost:3000/pdf/test2.pdf"


    this.setState({
      order_version: e.target.value,
      viewpdf: pdf_urlpath
    })

    console.log("Testvetrsion", e.target.value);
    this.state.notifymastercode = ''
    this.state.shipement_type = ''
    this.state.notychecked = false

    axios({
      method: "POST",
      url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        customer_sap_code: this.state.customer_code,
        order_mmyyyy: this.state.month_year,
        order_no: this.state.order_number,
        order_version: e.target.value
      }
    }).then((response) => {
      console.log("Testmajak", response.data.hdrs)

      this.state.action_status = ''
      if (response.data.status == 'Success') {
        const date = new Date();
        const defaultValue = date.toLocaleDateString('en-CA');
        var targetDate = new Date();
        targetDate.setDate(targetDate.getDate() + 60);
        console.log("Manidate", targetDate.toLocaleDateString('en-CA'))
        var get_cu = response.data.hdrs
        var get_shipdate = ""
        for (var j = 0; j < get_cu.length; j++) {

          this.state.curreny = get_cu[j].order_category
          this.state.pay_term = get_cu[j].payment_term;
          this.state.incotern = get_cu[j].inco_term;
          this.state.port_delivery = get_cu[j].port_of_discharge;
          this.state.customer_code = get_cu[j].customer_sap_code;
          this.state.customer_id = get_cu[j].customer_id
          this.state.order_type = get_cu[j].order_type
          this.state.container_size = get_cu[j].container_type
          this.state.po_number = get_cu[j].po_number
          this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
          this.state.pistatus = get_cu[j].pi_status
          this.state.pinumber = get_cu[j].pi_number
          this.state.container_size = get_cu[j].container_type
          this.state.notes = get_cu[j].notes
          this.state.priority_item = get_cu[j].priority_item
          this.state.final_desc = get_cu[j].final_destination
          this.state.displaycontainercapper = get_cu[j].container_capacity
          this.state.get_tot = get_cu[j].total_order_qty
          this.state.get_conta = get_cu[j].no_of_containers
          this.state.action_status = get_cu[j].action_status
          this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
          this.state.portofdischarge = get_cu[j].port_of_discharge
          this.state.shipement_type = get_cu[j].precarriage_by
          this.state.remarkes = get_cu[j].remarks_quo
          this.state.remarkes1 = get_cu[j].remarks_so
          this.state.discount = get_cu[j].discount
          this.state.freight = get_cu[j].freight
          this.state.insurance = get_cu[j].insurance
          // this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
          get_shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
          this.state.incoternlocation = get_cu[j].inco_term_location
          this.state.priority_item = get_cu[j].priority_item
          this.state.notifyparty = get_cu[j].notify_party_info1
          this.state.notifyinfo1 = get_cu[j].notify_party_info2
          this.state.notifyinfo2 = get_cu[j].notify_party_info3
          this.state.notifyinfo3 = get_cu[j].notify_party_info4
          this.state.notifyinfo4 = get_cu[j].notify_party_info5
          this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
          this.state.filler_item = get_cu[j].filler_item
          this.state.notifymastercode = get_cu[j].notifyparty_sap_code
          this.state.sonumber = get_cu[j].so_number
          this.state.ad_code = get_cu[j].ad_code
          this.state.validto = targetDate.toLocaleDateString('en-CA')

          if (localStorage.getItem('segment') == 'OHT') {
            this.state.shipdate = defaultValue
          } else {
            this.state.shipdate = get_shipdate
          }
          if (this.state.notifymastercode != "") {
            this.state.notychecked = true

            let notycode = this.state.notifymastercode

            if (localStorage.getItem('segment') != 'OHT') {
              axios({
                method: "POST",
                url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",
                headers: {
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
                },

                data: {
                  customer_sap_code: this.state.customer_code,
                  notify_sap_code: notycode,
                  segment: localStorage.getItem('segment')
                }
              }).then((response) => {

                if (response.data.status == 'Success') {
                  this.setState({
                    notifymastername: response.data.data[0].notify_name
                  });
                }


              });
            } else {
              axios({
                method: "POST",
                url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",

                headers: {
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
                },

                data: {
                  customer_sap_code: notycode,
                  segment: localStorage.getItem('segment')
                }
              }).then((response) => {

                console.log("testoht", response.data.data)
                if (response.data.status == 'Success') {
                  this.setState({
                    notifymastername: response.data.data[0].customer_name
                  });
                }


              });
            }


          }
        }

        console.log("Maniwish05 ", response.data.data)
        const wishlist = response.data.data;
        const getlist = []
        let piqty = '0';
        let pi_value = "0";
        let price_date = this.state.price_date
        let wi_prtype = ''
        let wi_pcode = ''
        let segment_desc = ""
        let segment_code = ""
        let product_group = ""
        let product_desc = ""
        let product_code = ""
        let price = ""
        let pi_qty = ""
        let container_capacity_qty = ""
        let container_capacity = ""
        let container_capacity_per = ""
        let product_type = ""
        let parent_product_code = ""
        let bom_no = ''
        let id = ''

        for (var w = 0; w < wishlist.length; w++) {

          if (localStorage.getItem("segment") == 'EURO') {
            price_date = wishlist[w].price_date
          }

          wi_prtype = wishlist[w].product_type
          segment_desc = wishlist[w].segment_desc
          segment_code = wishlist[w].segment_code
          product_group = wishlist[w].product_group
          product_desc = wishlist[w].product_desc
          product_code = wishlist[w].product_code
          price = wishlist[w].price
          price_date = wishlist[w].price_date
          pi_qty = wishlist[w].pi_qty
          pi_value = wishlist[w].pi_value
          container_capacity_qty = wishlist[w].container_capacity_qty
          container_capacity = wishlist[w].container_capacity
          container_capacity_per = wishlist[w].container_capacity_per
          product_type = wishlist[w].product_type
          parent_product_code = wishlist[w].parent_product_code
          bom_no = wishlist[w].bom_no
          id = wishlist[w].id

          getlist.push({
            segment_desc: segment_desc,
            segment_code: segment_code,
            product_group: product_group,
            product_desc: product_desc,
            product_code: product_code,
            price: price,
            price_date: price_date,
            bom_no: bom_no,
            bom_no1: "",
            pi_qty: pi_qty,
            pi_value: pi_value,
            container_capacity_qty: container_capacity_qty,
            container_capacity: container_capacity,
            container_capacity_per: container_capacity_per,
            product_type: product_type,
            parent_product_code: parent_product_code,
            id: id
          })


          if (wi_prtype == 'Set') {
            wi_pcode = wishlist[w].product_code

            axios({
              method: "POST",
              url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
              },
              data: {
                'product_code': wishlist[w].product_code,
              }
            }).then((response) => {
              console.log("Manitest", response.data.data)
              const getbomkit = []
              let kibom = response.data.data;
              var items = getlist
              var kitbom = response.data.data
              let item_pcode = ''
              let kit_pcode = ''

              for (var b = 0; b < kitbom.length; b++) {
                getbomkit.push({
                  // product_code :kitbom[b].product_code,
                  alt_bom_no: kitbom[b].alt_bom_no
                })
              }
              this.setState({
                wish_kit_bom: getbomkit
              })

              for (var k = 0; k < kitbom.length; k++) {
                kit_pcode = kitbom[k].product_code

                for (var b = 0; b < items.length; b++) {
                  item_pcode = items[b].product_code

                  if (kit_pcode == item_pcode) {
                    items[b].bom_no1 = response.data.data
                    items[b].bom_no = response.data.data[0].alt_bom_no
                  } else {

                  }
                }
              }
              this.setState({
                add_get_size: getlist
              })
            });
          } else {
            console.log("Testmanicode", getlist)
            this.setState({
              add_get_size: getlist
            })
          }


        }

        this.setState({
          // add_get_size: response.data.data,
          get_cu_details: response.data.hdrs,
          // you have it as this.data not response
        });
      } else {
        this.state.action_status = 'New'
        this.state.pinumber = ''
        this.state.po_date = ''
        this.state.pistatus = "Open"

        console.log("Maniwish05 ", response.data.data)
        const wishlist = response.data.data;
        const getlist = []
        let piqty = '0';
        let pi_value = "0";
        let price_date = this.state.price_date
        let wi_prtype = ''
        let wi_pcode = ''
        let segment_desc = ""
        let segment_code = ""
        let product_group = ""
        let product_desc = ""
        let product_code = ""
        let price = ""
        let pi_qty = ""
        let container_capacity_qty = ""
        let container_capacity = ""
        let container_capacity_per = ""
        let product_type = ""
        let parent_product_code = ""
        let bom_no = ''
        let id = ''

        for (var w = 0; w < wishlist.length; w++) {

          if (localStorage.getItem("segment") == 'EURO') {
            price_date = wishlist[w].price_date
          }

          wi_prtype = wishlist[w].product_type
          segment_desc = wishlist[w].segment_desc
          segment_code = wishlist[w].segment_code
          product_group = wishlist[w].product_group
          product_desc = wishlist[w].product_desc
          product_code = wishlist[w].product_code
          price = wishlist[w].price
          price_date = wishlist[w].price_date
          pi_qty = wishlist[w].pi_qty
          pi_value = wishlist[w].pi_value
          container_capacity_qty = wishlist[w].container_capacity_qty
          container_capacity = wishlist[w].container_capacity
          container_capacity_per = wishlist[w].container_capacity_per
          product_type = wishlist[w].product_type
          parent_product_code = wishlist[w].parent_product_code
          bom_no = wishlist[w].bom_no
          id = wishlist[w].id

          getlist.push({
            segment_desc: segment_desc,
            segment_code: segment_code,
            product_group: product_group,
            product_desc: product_desc,
            product_code: product_code,
            price: price,
            price_date: price_date,
            bom_no: bom_no,
            bom_no1: "",
            pi_qty: pi_qty,
            pi_value: pi_value,
            container_capacity_qty: container_capacity_qty,
            container_capacity: container_capacity,
            container_capacity_per: container_capacity_per,
            product_type: product_type,
            parent_product_code: parent_product_code,
            id: id
          })


          if (wi_prtype == 'Set') {
            wi_pcode = wishlist[w].product_code

            axios({
              method: "POST",
              url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
              },
              data: {
                'product_code': wishlist[w].product_code,
              }
            }).then((response) => {
              console.log("Manitest", response.data.data)
              const getbomkit = []
              let kibom = response.data.data;
              var items = getlist
              var kitbom = response.data.data
              let item_pcode = ''
              let kit_pcode = ''

              for (var b = 0; b < kitbom.length; b++) {
                getbomkit.push({
                  // product_code :kitbom[b].product_code,
                  alt_bom_no: kitbom[b].alt_bom_no
                })
              }
              this.setState({
                wish_kit_bom: getbomkit
              })

              for (var k = 0; k < kitbom.length; k++) {
                kit_pcode = kitbom[k].product_code

                for (var b = 0; b < items.length; b++) {
                  item_pcode = items[b].product_code

                  if (kit_pcode == item_pcode) {
                    items[b].bom_no1 = response.data.data
                    items[b].bom_no = response.data.data[0].alt_bom_no
                  } else {

                  }
                }
              }
              this.setState({
                add_get_size: getlist
              })
            });
          } else {
            console.log("Testmanicode", getlist)
            this.setState({
              add_get_size: getlist
            })
          }


        }
        this.setState({
          // add_get_size: response.data.data,
          get_cu_details: response.data.hdrs,
          // you have it as this.data not response
        });
      }



    });


    axios({
      method: "POST",
      url: this.state.baseurl + 'uploadshowprepi',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "order_no": this.state.order_number,
        "order_version": e.target.value
      }
    }).then((response) => {
      console.log("Test1232mani", response.data.data[0].title)
      this.setState({
        file_title: response.data.data[0].title,
        // you have it as this.data not response
      });
    });

  }

  // handle_pricedate =(e)=>{
  //   this.setState({
  //     price_date : e.target.value
  //   })
  // }

  handle_ordertype = (e) => {
    e.preventDefault()
    this.setState({
      order_type: e.target.value
    })
  }

  handle_shipmenttype = (e) => {
    e.preventDefault()

    this.setState({
      shipement_type: e.target.value
    })
  }

  handle_port_delivery = (e) => {
    e.preventDefault()
    this.setState({
      port_delivery: e.target.value.toUpperCase()
    })
  }

  handle_notifyparty = (e) => {
    e.preventDefault()

    this.setState({
      notifyparty: e.target.value
    })
  }

  handle_notifyname = (e) => {
    e.preventDefault()
    this.setState({
      notifymastercode: e.target.value
    })

    console.log("notifymani", e.target.value)

    let cust_code = ''
    // if (localStorage.getItem('segment') == '2WEXPORT' || localStorage.getItem('segment') == 'EURO') {
    cust_code = this.state.customer_code

    // axios({
    //   method: "POST",
    //   url: this.state.baseurl + 'notify_master_code',//"http://localhost:3300/custdetails",
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'token': localStorage.getItem('token')
    //   },
    //   data: {
    //     customer_sap_code: cust_code,
    //     notify_name: e.target.value,
    //     segment: localStorage.getItem('segment')
    //   }
    // }).then((response) => {
    //   console.log("Manitest", response.data.data[0].notify_sap_code)
    //   this.setState({
    //     notifymastercode: response.data.data[0].notify_sap_code,
    //     // you have it as this.data not response
    //   });
    // });
    // } else {
    //   this.setState({
    //     notifymastercode: e.target.value
    //   });

    // }




  }


  notichecked = (e) => {
    const { checked } = e.target

    let getchek = checked;

    console.log("Manichec", getchek)

    this.setState({
      notychecked: getchek
    })



  }


  handle_containter = (e) => {

    this.setState({
      container_size: e.target.value
    })
    this.state.container_size = e.target.value
    console.log("GETCONTAINERSIZE", e.target.value)

    if (this.state.order_number != 'New') {
      if (localStorage.getItem('segment') != '2WEXPORT') {
        axios({
          method: "POST",
          url: this.state.baseurl + 'container_qtyupdate',//"http://localhost:3300/listproduct",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            'customer_sap_code': this.state.customer_code,
            'order_no': this.state.order_number,
            'order_version': this.state.order_version,
            'container_type': e.target.value
          }
        }).then((response) => {
          console.log("Manite", response.data.data)

          axios({
            method: "POST",
            url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
            },
            data: {
              customer_sap_code: this.state.customer_code,
              order_mmyyyy: this.state.month_year,
              order_no: this.state.order_number,
              order_version: this.state.order_version
            }
          }).then((response) => {
            console.log("Maniwish05 ", response.data.data)
            const wishlist = response.data.data;
            const getlist = []
            let piqty = '0';
            let pi_value = "0";
            let price_date = this.state.price_date
            let wi_prtype = ''
            let wi_pcode = ''
            let segment_desc = ""
            let segment_code = ""
            let product_group = ""
            let product_desc = ""
            let product_code = ""
            let price = ""
            let pi_qty = ""
            let container_capacity_qty = ""
            let container_capacity = ""
            let container_capacity_per = ""
            let product_type = ""
            let parent_product_code = ""
            let bom_no = ''
            let id = ''

            for (var w = 0; w < wishlist.length; w++) {

              if (localStorage.getItem("segment") == 'EURO') {
                price_date = wishlist[w].price_date
              }

              wi_prtype = wishlist[w].product_type
              segment_desc = wishlist[w].segment_desc
              segment_code = wishlist[w].segment_code
              product_group = wishlist[w].product_group
              product_desc = wishlist[w].product_desc
              product_code = wishlist[w].product_code
              price = wishlist[w].price
              price_date = wishlist[w].price_date
              pi_qty = wishlist[w].pi_qty
              pi_value = wishlist[w].pi_value
              container_capacity_qty = wishlist[w].container_capacity_qty
              container_capacity = wishlist[w].container_capacity
              container_capacity_per = wishlist[w].container_capacity_per
              product_type = wishlist[w].product_type
              parent_product_code = wishlist[w].parent_product_code
              bom_no = wishlist[w].bom_no
              id = wishlist[w].id

              getlist.push({
                segment_desc: segment_desc,
                segment_code: segment_code,
                product_group: product_group,
                product_desc: product_desc,
                product_code: product_code,
                price: price,
                price_date: price_date,
                bom_no: bom_no,
                bom_no1: "",
                pi_qty: pi_qty,
                pi_value: pi_value,
                container_capacity_qty: container_capacity_qty,
                container_capacity: container_capacity,
                container_capacity_per: container_capacity_per,
                product_type: product_type,
                parent_product_code: parent_product_code,
                id: id
              })


              if (wi_prtype == 'Set') {
                wi_pcode = wishlist[w].product_code

                axios({
                  method: "POST",
                  url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    'product_code': wishlist[w].product_code,
                  }
                }).then((response) => {
                  console.log("Manitest", response.data.data)
                  const getbomkit = []
                  let kibom = response.data.data;
                  var items = getlist
                  var kitbom = response.data.data
                  let item_pcode = ''
                  let kit_pcode = ''

                  for (var b = 0; b < kitbom.length; b++) {
                    getbomkit.push({
                      // product_code :kitbom[b].product_code,
                      alt_bom_no: kitbom[b].alt_bom_no
                    })
                  }
                  this.setState({
                    wish_kit_bom: getbomkit
                  })

                  for (var k = 0; k < kitbom.length; k++) {
                    kit_pcode = kitbom[k].product_code

                    for (var b = 0; b < items.length; b++) {
                      item_pcode = items[b].product_code

                      if (kit_pcode == item_pcode) {
                        items[b].bom_no1 = response.data.data
                        items[b].bom_no = response.data.data[0].alt_bom_no
                      } else {

                      }
                    }
                  }
                  this.setState({
                    add_get_size: getlist
                  })
                });
              } else {
                console.log("Testmanicode", getlist)
                this.setState({
                  add_get_size: getlist
                })
              }


            }
            // this.setState({
            //   add_get_size: response.data.data,
            // });
          });

          this.calculation()
        });
      }
    }





  }

  handle_payterm = (e) => {
    console.log("Maniest", e.target.value)
    this.setState({
      pay_term: e.target.value
    })
    //  if (localStorage.getItem('segment') != '2WEXPORT') {
    axios({
      method: "POST",
      url: this.state.baseurl + 'getordertype',//"http://localhost:3300/listproduct",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        'tag_value': e.target.value,
      }
    }).then((response) => {
      console.log("maniadditem", response.data.data)
      this.setState({
        order_type: response.data.data[0].tag_type
      });
    });
    //  }
    console.log("PAYMENT", e.target.value)
  }

  handle_incotern = (e) => {

    this.setState({
      incotern: e.target.value

    })

    if (e.target.value = 'FOB') {
      this.setState({
        freight: 0,
        insurance: 0,
        discount: 0
      })
    } else {
      this.setState({
        freight: '',
        insurance: '',
        discount: ''
      })
    }

  }

  // handle_se_incotern=(e)=>{
  //   console.log("incoterm", e.target.value)
  //   this.setState({
  //     inco_term : e.target.value
  //   })
  // } 

  handle_incotern_loation = (e) => {

    this.setState({
      incoternlocation: e.target.value.toUpperCase()
    })
  }


  handle_port_discharge = (e) => {
    this.setState({
      portofdischarge: e.target.value.toUpperCase()
    })
  }
  handle_freight = (e) => {
    this.setState({
      freight: e.target.value
    })
  }

  handle_date_ship = (e) => {

    this.setState({
      shipdate: e.target.value
    })
  }

  handle_insurance = (e) => {
    this.setState({
      insurance: e.target.value
    })
  }

  handle_discount = (e) => {
    this.setState({
      discount: e.target.value
    })
  }


  handle_curreny = (e) => {
    this.setState({
      currency: e.target.value
    })
  }


  handle_getproduct_code = (selected) => {
    console.log("Manite", localStorage.getItem('segment'))
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');
    this.state.add_size = ""
    this.state.add_pname = ""
    this.state.add_pcode = ""
    this.state.add_price = ""
    this.state.add_tqty = ""
    this.state.add_value = ""
    this.state.price_date = "";
    let prcode = ''
    for (var i = 0; i < selected.length; i++) {
      prcode = selected[i].product_code
    }
    axios({
      method: "POST",
      url: this.state.baseurl + 'setadditem',//"http://localhost:3300/listproduct",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        'product_code': prcode,
        'from_date': defaultValue,
        'to_date': defaultValue,
        'container_type': this.state.container_size,
        'segment': localStorage.getItem('segment'),
        'customer_sap_code': this.state.customer_code
      }
    }).then((response) => {
      console.log("maniadditem", response.data.data)
      this.setState({
        add_segment: response.data.data[0].segment_desc,
        add_size: response.data.data[0].product_group,
        add_pname: response.data.data[0].product_desc,
        add_pcode: response.data.data[0].product_code,
        // add_price: response.data.data[0].price,
        container_capacity_qty: response.data.data[0].quantity,

      });
    });

    axios({
      method: "POST",
      url: this.state.baseurl + 'additem_price_date',//"http://localhost:3300/listproduct",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        'product_code': prcode,
        'segment': localStorage.getItem('segment'),
        'customer_sap_code': this.state.customer_code
      }
    }).then((response) => {
      console.log("maniprice", response.data.data[0].valid_from)

      let get_date = ""

      if (localStorage.getItem('usertypecode') == 'CUST' && localStorage.getItem('segment') == '2WEXPORT') {

        get_date = defaultValue
        this.setState({
          get_price_date_list: response.data.data,
          price_date: defaultValue

        });
      } else {
        get_date = response.data.data[0].valid_from
        this.setState({
          get_price_date_list: response.data.data,
          price_date: moment(response.data.data[0].valid_from).format('YYYY-MM-DD')
        });
      }

      console.log("Manicurrentdate", get_date)

      axios({
        method: "POST",
        url: this.state.baseurl + 'getdateprice',//"http://localhost:3300/listproduct",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          'product_code': prcode,
          'segment': localStorage.getItem('segment'),
          'customer_sap_code': this.state.customer_code,
          'valid_from': moment(get_date).format('YYYY-MM-DD')
        }
      }).then((response) => {
        // console.log("getprice", response.data.data[0].price)
        console.log("GeTpRICE", response.data.data)
        if (response.data.status == 'Success') {
          this.setState({
            add_price: response.data.data[0].price
          });
        } else {
          this.setState({
            add_price: '0.0'
          });
        }

      });


    });



  }



  handle_getkitproduct_code = (selected) => {

    this.stategetkitcode = []

    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');
    this.state.getkitpricedate = defaultValue
    let prcode = ''
    let prname = ''
    this.state.getkitpdesc = ''
    for (var i = 0; i < selected.length; i++) {
      prcode = selected[i].product_code
      prname = selected[i].product_desc

      console.log("Mani", prname)

      console.log()
      this.setState({
        getkitpcode: prcode,
        getkitpdesc: prname,
        getkitcode: []
      })
    }

    axios({
      method: "POST",
      url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        'product_code': prcode,
      }
    }).then((response) => {
      console.log("bom no", response.data.data)
      this.setState({
        get_bom_no: response.data.data,
        select_bom: response.data.data[0].alt_bom_no
      })
    });


  }
  handle_getkitproduct_name = (selected) => {
    this.stategetkitcode = []
    let prcode = ''
    let prname = ''
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');
    this.state.getkitpricedate = defaultValue
    for (var i = 0; i < selected.length; i++) {
      prcode = selected[i].product_code
      prname = selected[i].product_desc
      this.state.getkitpcode = ''
      this.setState({
        getkitpcode: prcode,
        getkitpdesc: prname,
        getkitcode: []
      })
    }
  }

  handle_bomno = (e) => {

    this.setState({
      select_bom: e.target.value
    })

    console.log("Mai", e.target.value)

  }

  handle_getdateprice = (e) => {

    this.setState({
      getkitpricedate: e.target.value
    })

  }

  handle_getkitproduct_tube = (selected) => {
    let prcode = ''
    let prname = ''
    for (var i = 0; i < selected.length; i++) {
      prcode = selected[i].product_code
      prname = selected[i].product_desc
    }


    axios({
      method: "POST",
      url: this.state.baseurl + 'gettube_price',//"http://localhost:3300/listproduct",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        'customer_sap_code': this.state.customer_code,
        'product_code': prcode,
        'valid_from': this.state.getkitpricedate,
        'valid_to': this.state.getkitpricedate,

      }
    }).then((response) => {
      console.log("kititemprice", response.data.data)

      if (response.data.status == 'Success') {
        this.setState({
          gettubecode: prcode,
          gettubedesc: prname,
          gettubeprice: response.data.data[0].price
        })
      } else {
        alert("This item price not avaliable")
      }
    });

  }

  handlechagetube = (e) => {

    console.log("tube", this.state.gettubecode + "," + this.state.gettubedesc)

    const gettube = this.state.getkitcode;

    const getthube = []
    for (var i = 0; i < gettube.length; i++) {

      if (gettube[i].product_type == 'Tube') {

        let amount = gettube[i].qty * this.state.gettubeprice
        getthube.push({
          amount: amount,
          container_qty: gettube[i].container_qty,
          parent_product_code: gettube[i].parent_product_code,
          price: this.state.gettubeprice,
          price_date: gettube[i].price_date,
          product_code: this.state.gettubecode,
          product_desc: this.state.gettubedesc,
          product_group: gettube[i].product_group,
          product_type: gettube[i].product_type,
          qty: gettube[i].qty,
          segment_desc: gettube[i].segment_desc,
        })
      } else {
        getthube.push({
          amount: gettube[i].amount,
          container_qty: gettube[i].container_qty,
          parent_product_code: gettube[i].parent_product_code,
          price: gettube[i].price,
          price_date: gettube[i].price_date,
          product_code: gettube[i].product_code,
          product_desc: gettube[i].product_desc,
          product_group: gettube[i].product_group,
          product_type: gettube[i].product_type,
          qty: gettube[i].qty,
          segment_desc: gettube[i].segment_desc,
        })

      }
      this.setState({
        getkitcode: getthube
      })
    }
  }


  handlekitadd = (e) => {

    if (this.state.getkitpcode != '' && this.state.getkitqty != '') {
      axios({
        method: "POST",
        url: this.state.baseurl + 'orderkit_list',//"http://localhost:3300/listproduct",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          'customer_sap_code': this.state.customer_code,
          'product_code': this.state.getkitpcode,
          'product_desc': this.state.getkitpdesc,
          'price_date': this.state.getkitpricedate,
          'bom_no': this.state.select_bom,
          'qty': this.state.getkitqty,
          'container_type': this.state.container_size,
          'user_name': localStorage.getItem('username')
        }
      }).then((response) => {
        console.log("kitadditem", response.data.message)
        this.setState({
          getkitcode: response.data.message
        });
      });
    } else if (this.state.getkitpcode == '' && this.state.getkitqty == '') {
      alert('Please select productcode & Qty')
    } else if (this.state.getkitpcode == '') {
      alert('Please select product code')
    } else if (this.state.getkitqty == "") {
      alert('Please select Qty')
    }


  }

  handle_getkitqty = (e) => {
    this.setState({
      getkitqty: e.target.value
    })
  }

  handle_getproduct_name = (selected) => {

    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');
    let prcode = ''
    let prname = ''
    for (var i = 0; i < selected.length; i++) {
      prcode = selected[i].product_code
      prname = selected[i].product_desc
    }
    axios({
      method: "POST",
      url: this.state.baseurl + 'setadditem_name',//"http://localhost:3300/listproduct",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        'product_code': prcode,
        'product_desc': prname,
        'from_date': defaultValue,
        'to_date': defaultValue,
        'container_type': this.state.container_size,
        'segment': localStorage.getItem('segment'),
        'customer_sap_code': this.state.customer_code
      }
    }).then((response) => {
      console.log("additemname", response.data.data)
      this.setState({
        add_segment: response.data.data[0].segment_desc,
        add_size: response.data.data[0].product_group,
        add_pname: response.data.data[0].product_desc,
        add_pcode: response.data.data[0].product_code,
        add_price: response.data.data[0].price,
        container_capacity_qty: response.data.data[0].quantity
      });
    });



  }

  //Tabel add and remove 
  handle_segment = (selected) => {
    var see = "";
    var code = '';
    for (var i = 0; i < selected.length; i++) {
      see = selected[i].segment_desc
      code = selected[i].segment_code
    }
    this.setState({ add_segment: see });


    axios({
      method: "POST",
      url: this.state.baseurl + 'listproduct',//"http://localhost:3300/listproduct",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        segment_desc: see
      }
    }).then((response) => {
      console.log("Seg", response.data.data)
      this.setState({
        product: response.data.data, // you have it as this.data not response
      });
    });


  }


  handle_productsize = (selected) => {


    if (this.state.add_segment == "") {

      alert("Please Select Segment")
    } else {

      var desc = "";
      for (var i = 0; i < selected.length; i++) {
        desc = selected[i].product_group
      }
      console.log(selected)
      this.setState({ add_size: desc });
      axios({
        method: "POST",
        url: this.state.baseurl + 'listproductdesc', //"http://localhost:3300/listproductdesc",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          product_group: desc
        }
      }).then((response) => {
        console.log("Test", response.data.data)
        this.setState({
          prouct_name: response.data.data, // you have it as this.data not response
        });
      });
    }




  }

  handle_productname = (selected) => {

    var pname = "";
    for (var i = 0; i < selected.length; i++) {
      pname = selected[i].product_desc
    }

    this.setState({ add_pname: pname });

    this.set_pcode(pname)

  }


  version_increment = () => {


    this.state.add_get_size = []

    this.setState({
      pistatus: '',
      action_status: 'New'
    })

    axios({
      method: "POST",
      url: this.state.baseurl + 'showversion_increment',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        customer_sap_code: this.state.customer_code,
        order_mmyyyy: this.state.month_year,
        order_no: this.state.order_number
      }
    }).then((response) => {
      console.log("version", response.data.data[0])
      this.setState({
        order_version: response.data.data[0],
        get_order_verion: response.data.data,
      });

      axios({
        method: "POST",
        url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          customer_sap_code: this.state.customer_code,
          order_mmyyyy: this.state.month_year,
          order_no: this.state.order_number,
          order_version: response.data.data[0]
        }
      }).then((response) => {
        console.log("custhdrsAmentment1223213123123", response.data.data)
        console.log("custhdrsAmentment123123123123131", response.data.data)
        if (response.data.hdrs != '') {
          if (response.data.status == 'Success') {
            var get_cu = response.data.hdrs

            for (var j = 0; j < get_cu.length; j++) {

              this.state.curreny = get_cu[j].order_category
              this.state.pay_term = get_cu[j].payment_term;
              this.state.incotern = get_cu[j].inco_term;
              this.state.port_delivery = get_cu[j].port_of_discharge;
              this.state.customer_code = get_cu[j].customer_sap_code;
              this.state.customer_id = get_cu[j].customer_id
              this.state.order_type = get_cu[j].order_type
              this.state.container_size = get_cu[j].container_type
              this.state.po_number = get_cu[j].po_number
              this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
              this.state.pistatus = get_cu[j].pi_status
              this.state.pinumber = get_cu[j].pi_number
              this.state.container_size = get_cu[j].container_type
              this.state.notes = get_cu[j].notes
              this.state.priority_item = get_cu[j].priority_item
              this.state.final_desc = get_cu[j].final_destination
              this.state.displaycontainercapper = get_cu[j].container_capacity
              this.state.get_tot = get_cu[j].total_order_qty
              this.state.get_conta = get_cu[j].no_of_containers
              this.state.action_status = get_cu[j].action_status
              this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
              this.state.portofdischarge = get_cu[j].port_of_discharge
              this.state.shipement_type = get_cu[j].precarriage_by
              this.state.remarkes = get_cu[j].remarks_quo
              this.state.remarkes1 = get_cu[j].remarks_so
              this.state.discount = get_cu[j].discount
              this.state.insurance = get_cu[j].insurance
              this.state.freight = get_cu[j].freight
              this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
              this.state.incoternlocation = get_cu[j].inco_term_location
              this.state.priority_item = get_cu[j].priority_item
              this.state.notifyparty = get_cu[j].notify_party_info1
              this.state.notifyinfo = get_cu[j].notify_party_info1
              this.state.notifyinfo1 = get_cu[j].notify_party_info2
              this.state.notifyinfo2 = get_cu[j].notify_party_info3
              this.state.notifyinfo3 = get_cu[j].notify_party_info4
              this.state.notifyinfo4 = get_cu[j].notify_party_info5
              this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
              this.state.filler_item = get_cu[j].filler_item
              this.state.notifymastercode = get_cu[j].notifyparty_sap_code
            }

            const wishlist = response.data.data;
            console.log("Order amentment", wishlist)
            const getlist = []
            let piqty = '0';
            let pi_value = "0";
            let price_date = this.state.price_date
            let wi_prtype = ''
            let wi_pcode = ''
            let segment_desc = ""
            let segment_code = ""
            let product_group = ""
            let product_desc = ""
            let product_code = ""
            let price = ""
            let pi_qty = ""
            let container_capacity_qty = ""
            let container_capacity = ""
            let container_capacity_per = ""
            let product_type = ""
            let parent_product_code = ""
            let bom_no = ''
            let id = ''

            for (var w = 0; w < wishlist.length; w++) {

              if (localStorage.getItem("segment") == 'EURO') {
                price_date = wishlist[w].price_date
              }

              console.log("capcity", wishlist[w].container_capacity_per)

              wi_prtype = wishlist[w].product_type
              segment_desc = wishlist[w].segment_desc
              segment_code = wishlist[w].segment_code
              product_group = wishlist[w].product_group
              product_desc = wishlist[w].product_desc
              product_code = wishlist[w].product_code
              price = wishlist[w].price
              price_date = wishlist[w].price_date
              pi_qty = wishlist[w].pi_qty
              pi_value = wishlist[w].pi_value
              container_capacity_qty = wishlist[w].container_capacity_qty
              container_capacity = wishlist[w].container_capacity
              container_capacity_per = wishlist[w].container_capacity_per
              product_type = wishlist[w].product_type
              parent_product_code = wishlist[w].parent_product_code
              bom_no = wishlist[w].bom_no
              id = wishlist[w].id

              getlist.push({
                segment_desc: segment_desc,
                segment_code: segment_code,
                product_group: product_group,
                product_desc: product_desc,
                product_code: product_code,
                price: price,
                price_date: price_date,
                bom_no: bom_no,
                bom_no1: "",
                pi_qty: pi_qty,
                pi_value: pi_value,
                container_capacity_qty: container_capacity_qty,
                container_capacity: container_capacity,
                container_capacity_per: container_capacity_per,
                product_type: product_type,
                parent_product_code: parent_product_code,
                id: id
              })


              if (wi_prtype == 'Set') {
                wi_pcode = wishlist[w].product_code

                axios({
                  method: "POST",
                  url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    'product_code': wishlist[w].product_code,
                  }
                }).then((response) => {
                  const getbomkit = []
                  let kibom = response.data.data;
                  var items = getlist
                  var kitbom = response.data.data
                  let item_pcode = ''
                  let kit_pcode = ''

                  for (var b = 0; b < kitbom.length; b++) {
                    getbomkit.push({
                      // product_code :kitbom[b].product_code,
                      alt_bom_no: kitbom[b].alt_bom_no
                    })
                  }
                  this.setState({
                    wish_kit_bom: getbomkit
                  })

                  for (var k = 0; k < kitbom.length; k++) {
                    kit_pcode = kitbom[k].product_code

                    for (var b = 0; b < items.length; b++) {
                      item_pcode = items[b].product_code

                      if (kit_pcode == item_pcode) {
                        items[b].bom_no1 = response.data.data
                        items[b].bom_no = response.data.data[0].alt_bom_no
                      } else {

                      }
                    }
                  }
                  this.setState({
                    add_get_size: getlist
                  })
                });
              } else {

                console.log("TestmanicodeAmentment123123123", getlist)
                this.setState({
                  add_get_size: getlist
                })
              }


            }


            this.setState({
              //  add_get_size: response.data.data,
              get_cu_details: response.data.hdrs,
              // you have it as this.data not response
            });



          } else {
            var get_cu = response.data.hdrs

            for (var j = 0; j < get_cu.length; j++) {

              this.state.curreny = get_cu[j].order_category
              this.state.pay_term = get_cu[j].payment_term;
              this.state.incotern = get_cu[j].inco_term;
              this.state.port_delivery = get_cu[j].port_of_discharge;
              this.state.customer_code = get_cu[j].customer_sap_code;
              this.state.customer_id = get_cu[j].customer_id
              this.state.order_type = get_cu[j].order_type
              this.state.container_size = get_cu[j].container_type
              this.state.po_number = get_cu[j].po_number
              this.state.po_date = get_cu[j].po_date
              this.state.pistatus = "Open"
              this.state.pinumber = ""
              this.state.container_size = get_cu[j].container_type
              this.state.notes = get_cu[j].notes
              this.state.priority_item = get_cu[j].priority_item
              this.state.final_desc = get_cu[j].final_destination
              this.state.displaycontainercapper = get_cu[j].container_capacity
              this.state.get_tot = get_cu[j].total_order_qty
              this.state.get_conta = get_cu[j].no_of_containers
              this.state.action_status = 'New'
            }

            const wishlist = response.data.data;

            console.log("Manielse13123123", wishlist)
            const getlist = []
            let piqty = '0';
            let pi_value = "0";
            let price_date = this.state.price_date
            let wi_prtype = ''
            let wi_pcode = ''
            let segment_desc = ""
            let segment_code = ""
            let product_group = ""
            let product_desc = ""
            let product_code = ""
            let price = ""
            let pi_qty = ""
            let container_capacity_qty = ""
            let container_capacity = ""
            let container_capacity_per = ""
            let product_type = ""
            let parent_product_code = ""
            let bom_no = ''
            let id = ''

            for (var w = 0; w < wishlist.length; w++) {

              if (localStorage.getItem("segment") == 'EURO') {
                price_date = wishlist[w].price_date
              }

              console.log("PER_QTY", wishlist[w].container_capacity_qty)
              wi_prtype = wishlist[w].product_type
              segment_desc = wishlist[w].segment_desc
              segment_code = wishlist[w].segment_code
              product_group = wishlist[w].product_group
              product_desc = wishlist[w].product_desc
              product_code = wishlist[w].product_code
              price = wishlist[w].price
              price_date = wishlist[w].price_date
              pi_qty = wishlist[w].pi_qty
              pi_value = wishlist[w].pi_value
              container_capacity_qty = wishlist[w].container_capacity_qty
              container_capacity = wishlist[w].container_capacity
              container_capacity_per = wishlist[w].container_capacity_per
              product_type = wishlist[w].product_type
              parent_product_code = wishlist[w].parent_product_code
              bom_no = wishlist[w].bom_no
              id = wishlist[w].id

              getlist.push({
                segment_desc: segment_desc,
                segment_code: segment_code,
                product_group: product_group,
                product_desc: product_desc,
                product_code: product_code,
                price: price,
                price_date: price_date,
                bom_no: bom_no,
                bom_no1: "",
                pi_qty: pi_qty,
                pi_value: pi_value,
                container_capacity_qty: container_capacity_qty,
                container_capacity: container_capacity,
                container_capacity_per: container_capacity_per,
                product_type: product_type,
                parent_product_code: parent_product_code,
                id: id
              })


              if (wi_prtype == 'Set') {
                wi_pcode = wishlist[w].product_code

                axios({
                  method: "POST",
                  url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    'product_code': wishlist[w].product_code,
                  }
                }).then((response) => {
                  const getbomkit = []
                  let kibom = response.data.data;
                  var items = getlist
                  var kitbom = response.data.data
                  let item_pcode = ''
                  let kit_pcode = ''

                  for (var b = 0; b < kitbom.length; b++) {
                    getbomkit.push({
                      // product_code :kitbom[b].product_code,
                      alt_bom_no: kitbom[b].alt_bom_no
                    })
                  }
                  this.setState({
                    wish_kit_bom: getbomkit
                  })

                  for (var k = 0; k < kitbom.length; k++) {
                    kit_pcode = kitbom[k].product_code

                    for (var b = 0; b < items.length; b++) {
                      item_pcode = items[b].product_code

                      if (kit_pcode == item_pcode) {
                        items[b].bom_no1 = response.data.data
                        items[b].bom_no = response.data.data[0].alt_bom_no
                      } else {

                      }
                    }
                  }
                  this.setState({
                    add_get_size: getlist
                  })
                });
              } else {

                console.log("TestmanicodeAmentment", getlist)
                this.setState({
                  add_get_size: getlist
                })
              }


            }



            this.setState({
              //  add_get_size: response.data.data,
              get_cu_details: response.data.hdrs,
              // you have it as this.data not response
            });
          }
        } else {
          axios({
            method: "POST",
            url: this.state.baseurl + 'custdetails',//"http://localhost:3300/custdetails",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
            },
            data: {
              customer_sap_code: this.state.customer_code,
              //order_mmyyyy: e.target.value, 
            }
          }).then((response) => {
            console.log("Test123", response.data.data)


            var get_cust1 = response.data.data

            for (var j = 0; j < get_cust1.length; j++) {

              this.state.curreny = get_cust1[j].currency
              this.state.pay_term = get_cust1[j].payment_term;
              this.state.incotern = get_cust1[j].inco_term;
              this.state.port_delivery = get_cust1[j].port;
              this.state.customer_code = get_cust1[j].customer_sap_code;
              this.state.customer_id = get_cust1[j].customer_id


            }

            this.setState({
              get_month_order: ''
            });


          });
        }

      });
    });

  }


  set_pcode(pname) {
    let getcode = ''
    let gpcode = ''
    let gprcode = ''
    let from_date = this.state.price_date;
    let to_date = this.state.price_date;
    axios({
      method: "POST",
      url: this.state.baseurl + 'listproductcode',//"http://localhost:3300/listproductcode",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        product_desc: pname
      }
    }).then((response) => {
      getcode = response.data.data
      gprcode = response.data.data[0].product_code
      for (var i = 0; i < getcode.length; i++) {
        gpcode = getcode[i].product_code

      }

      console.log("Test", gpcode);
      this.setState({ add_pcode: gpcode });

      this.get_price_date(gprcode, from_date, to_date)

    });
  }






  handle_dateprice = (e) => {

    console.log("Manienda", e.target.value)


    let gprcode = this.state.add_pcode
    this.state.add_price = ''

    this.setState({
      price_date: e.target.value,
      //add_value :this.state.add_price * e.target.value
    })

    this.state.getprice_date = []

    axios({
      method: "POST",
      url: this.state.baseurl + 'getdateprice',//"http://localhost:3300/listproduct",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        'product_code': this.state.add_pcode,
        'segment': localStorage.getItem('segment'),
        'customer_sap_code': this.state.customer_code,
        'valid_from': e.target.value
      }
    }).then((response) => {
      console.log("getprice", response.data.data[0].price)
      this.setState({
        add_price: response.data.data[0].price
      });
    });
    // axios({
    //   method: "POST",
    //   url: this.state.baseurl + 'getprice',//"http://localhost:3300/user_login",
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'token': localStorage.getItem('token')
    //   },
    //   data: {
    //     "valid_from": e.target.value,
    //     "valid_to": e.target.value,
    //     "customer_sap_code": this.state.customer_code,
    //     "product_code": gprcode,
    //     "container_size": this.state.container_size

    //   }
    // }).then((response) => {
    //   console.log("datepricemani1232", response.data.data[0].quantity)
    //   this.setState({
    //     getprice_date: response.data.data[0],
    //     get_container: response.data.data[0].quantity // you have it as this.data not response
    //   });

    //   if (response.data.status == 'Success') {
    //     this.set_price(gprcode)
    //   }

    // });

  }

  get_price_date(pcode, from_date, to_date) {

    axios({
      method: "POST",
      url: this.state.baseurl + 'getprice',//"http://localhost:3300/user_login",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "valid_from": from_date,
        "valid_to": to_date,
        "customer_sap_code": this.state.customer_code,
        "product_code": pcode,
        "container_size": this.state.container_size

      }
    }).then((response) => {
      console.log("dateprice", response.data.data)

      let getpr = response.data.data[0].price
      // let getcontainer = 
      this.setState({
        getprice_date: getpr,
        // you have it as this.data not response
      });

      if (response.data.status == 'Success') {
        this.set_price(pcode)
      }

    });
  }


  set_price(pname) {
    let getprice = ''
    let gprice = ''
    axios({
      method: "POST",
      url: this.state.baseurl + 'listproductprice', //"http://localhost:3300/listproductprice",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        product_code: pname,
        customer_sap_code: this.state.customer_code,
        "valid_from": this.state.price_date,
        "valid_to": this.state.price_date
      }
    }).then((response) => {
      getprice = response.data.data
      console.log("Preprice", getprice)
      for (var i = 0; i < getprice.length; i++) {
        gprice = getprice[i].price

      }
      this.setState({
        add_price: gprice,
        add_value: (this.state.add_tqty * gprice).toFixed(2)
      });


    });
  }
  handle_qty = (e) => {

    const re = /^[0-9\b]+$/;
    let getvalue = ''
    if (e.target.value === '' || re.test(e.target.value)) {

      getvalue = e.target.value
    }
    this.setState({
      add_oqty: getvalue
      //add_value :this.state.add_price * e.target.value
    })
  }

  handle_tqty = (e) => {
    const re = /^[0-9\b]+$/;
    let getvalue = ''
    if (e.target.value === '' || re.test(e.target.value)) {
      getvalue = e.target.value
    }
    let getvolume = 0
    if (localStorage.getItem('segment') == '2WEXPORT') {
      getvolume = Math.round((((getvalue * this.state.container_capacity_qty) / 150) * 100))
    } else {
      if (this.state.container_capacity_qty != 0.0) {
        getvolume = ((getvalue / this.state.container_capacity_qty) * 100).toFixed(2)
      } else {
        getvolume = 0
      }


    }

    console.log("Mani", this.state.container_capacity_qty)

    let volumeget = 0
    if (getvolume != 0) {
      volumeget = getvolume
    } else {
      volumeget = 0
    }



    this.setState({
      add_tqty: getvalue,
      add_value: (this.state.add_price * getvalue).toFixed(2),
      volume_occupied: volumeget
    })

  }

  handle_bqty = (e) => {
    this.setState({
      add_bqty: e.target.value
    })
  }

  handle_notes = (e) => {
    this.setState({
      notes: e.target.value.toUpperCase()
    })
  }

  handle_portofloading = (e) => {
    this.setState({
      portofloading: e.target.value
    })
  }

  handle_add_pricedate = (e) => {


    var items = this.state.add_get_size;
    console.log("pricedate", items)
    let prdate = ''
    // for (var p=0; p< items.length; p++){
    //   items[p].price_date = e.target.value
    //   console.log("Manitest",  items[p].price_date)
    // }




    this.setState({
      add_price_date: e.target.value
    })

  }


  togglupdatedate = (e) => {

    axios({
      method: "POST",
      url: this.state.baseurl + 'dateupdate',//"http://localhost:3300/user_login",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {

        "price_date": this.state.add_price_date,
        "customer_sap_code": this.state.customer_code,
        "order_no": this.state.order_number,
        "order_version": this.state.order_version,
        "order_mmyyyy": this.state.month_year


      }
    }).then((response) => {
      console.log("Manitest", response.data.data)
      this.setState({
        add_get_size: response.data.data,

      });

    });
  }

  handle_priority = (e) => {
    this.setState({
      priority_item: e.target.value.toUpperCase()
    })
  }

  handle_filleritem = (e) => {
    this.setState({
      filler_item: e.target.value.toUpperCase()
    })
  }

  handle_notifyinfo = (e) => {
    this.setState({
      notifyinfo: e.target.value.toUpperCase()
    })
  }


  handle_findescounty = (e) => {
    this.setState({
      countryoffindes: e.target.value
    })
  }
  handle_notifyinfo1 = (e) => {
    this.setState({
      notifyinfo1: e.target.value.toUpperCase()
    })
  }


  handle_notifyinfo2 = (e) => {
    this.setState({
      notifyinfo2: e.target.value.toUpperCase()
    })
  }

  handle_notifyinfo3 = (e) => {
    this.setState({
      notifyinfo3: e.target.value.toUpperCase()
    })
  }

  handle_notifyinfo4 = (e) => {
    this.setState({
      notifyinfo4: e.target.value.toUpperCase()
    })
  }

  handle_final = (e) => {
    this.setState({
      final_desc: e.target.value.toUpperCase()
    })
  }

  calculation = () => {



    let total_pi = 0;
    let piqty = 0;
    let containercapqty = 0;
    let containercapper = 0;
    let totalcontainercapper = 0;
    let noofcontiner = 0;
    let containermaxcapacity = 0;
    let volume_occupied = 0;
    let total_volume = 0;
    let containersizevolume = 0;
    let getuom = ''
    let getcont = 0

    if (localStorage.getItem('segment') == '2WEXPORT') {

      for (var i = 0; i < this.state.add_get_size.length; i++) {

        piqty = parseInt(this.state.add_get_size[i].pi_qty);
        console.log("Manie", piqty)
        getuom = this.state.add_get_size[i].product_type

        containercapqty = this.state.add_get_size[i].container_capacity_qty;
        containermaxcapacity = this.state.add_get_size[i].container_capacity;

        if (getuom == 'Set' || getuom == null || getuom == '') {
          total_pi = total_pi + parseInt(piqty);
        } else {
          total_pi = total_pi;
        }
        // total_pi = total_pi + parseInt(piqty);

        if (isNaN(piqty)) {
          piqty = 0.0
        }


        if (containercapqty != '0') {
          volume_occupied = ((piqty * containercapqty) / 150) * 100;
        }


        total_volume = total_volume + volume_occupied


        console.log("kitbom123", containercapqty)

      }



      if (this.state.container_size == '20 Feet') {
        containersizevolume = 2030.535
      }

      if (this.state.container_size == '40 Feet') {
        containersizevolume = 4113.135
      }

      if (this.state.container_size == '40 High Cube') {
        containersizevolume = 4113.135
      }


      let noofcontainer = ''

      noofcontainer = Math.ceil(total_volume / containersizevolume)

      this.setState({
        displaycontainercapper: ((total_volume / containersizevolume) * 100).toFixed(0),
        get_conta: noofcontainer,
        get_tot: total_pi
      })

      this.state.displaycontainercapper = ((total_volume / containersizevolume) * 100).toFixed(0);


    } else {
      console.log("OHTCustAdd", "OHT")
      console.log("OHTCustLength", this.state.add_get_size)
      for (var i = 0; i < this.state.add_get_size.length; i++) {

        piqty = parseInt(this.state.add_get_size[i].pi_qty);
        console.log("OHTCusttestqty", piqty)
        getuom = this.state.add_get_size[i].product_type

        console.log("OHTCustGetuom", getuom)
        if (this.state.add_get_size[i].container_capacity_qty != '') {
          containercapqty = parseInt(this.state.add_get_size[i].container_capacity_qty);
        } else {
          containercapqty = '0'
        }
        console.log("OHTCustContainer", containercapqty)
        if (getuom == 'Set' || getuom == null || getuom == '') {

          total_pi = total_pi + parseInt(piqty);
          console.log("OhtCust", total_pi)
        } else {
          total_pi = total_pi;
        }
        // total_pi = total_pi + piqty;
        console.log("OHTCustTotalpi", total_pi)
        if (isNaN(piqty)) {
          piqty = 0.0
        }

        if (containercapqty != '0') {
          containercapper = (piqty / containercapqty) * 100;
          console.log("OHTCustOHT1", containercapper)
          totalcontainercapper = totalcontainercapper + containercapper;
        }

        // if (getuom == 'Set' || getuom == 'Tyre' || getuom == 'Tube' ||getuom =='Flap' ) {

        //   if(getuom =='Tyre' && getuom == 'Tube'&& getuom =='Flap'){
        //     getcont  = 0
        //   }else{
        //     getcont  = getcont + containercapper
        //   }
        //   console.log("OHTUOMLIST", getcont)
        //   console.log("OHTCustCalca", getcont)
        //   totalcontainercapper = (getcont)/3
        // }else{
        //   console.log("OHTCustCalca2", containercapper)
        //   totalcontainercapper = totalcontainercapper + containercapper;
        // }

        // totalcontainercapper = totalcontainercapper + containercapper;

      }



      console.log("OHTCustNoofconta", totalcontainercapper)
      let noofcontainer = ''
      if (totalcontainercapper != '0' || totalcontainercapper != null) {
        //noofcontainer = Math.floor(totalcontainercapper / 100) + Math.floor((totalcontainercapper % 100) / 95);
        noofcontainer = totalcontainercapper / 100;
      } else {
        noofcontainer = ''
      }

      let get_totcontainerper = 0.0
      if (totalcontainercapper != 0) {
        get_totcontainerper = 100 - (totalcontainercapper % 100).toFixed(2)
      } else {
        get_totcontainerper = 0.0
      }

      //console.log("OHTCustEURO", get_totcontainerper)

      //this.state.displaycontainercapper = 100 - (totalcontainercapper % 100).toFixed(2);
      this.setState({
        displaycontainercapper: get_totcontainerper.toFixed(2),
        get_tot: total_pi,
        get_conta: (noofcontainer).toFixed(2)
      })

      // let noofcontainer = ''
      // if (totalcontainercapper != '0' || totalcontainercapper != null) {
      //   noofcontainer = Math.floor(totalcontainercapper / 100) + Math.floor((totalcontainercapper % 100) / 95);
      // } else {
      //   noofcontainer = ''
      // }
      // this.state.displaycontainercapper = (totalcontainercapper % 100).toFixed(2);
      // this.setState({
      //   displaycontainercapper: (totalcontainercapper % 100).toFixed(2),
      //   get_tot: total_pi,
      //   get_conta: noofcontainer
      // })
    }

  }

  deletecalculation = (id) => {

    console.log("DELETEOCONoht", id)

    let total_pi = 0;
    let piqty = 0;
    let containercapqty = 0;
    let containercapper = 0;
    let totalcontainercapper = 0;
    let noofcontiner = 0;
    let containermaxcapacity = 0;
    let volume_occupied = 0;
    let total_volume = 0;
    let containersizevolume = 0;




    if (localStorage.getItem('segment') == '2WEXPORT') {

      for (var i = 0; i < this.state.add_get_size.length; i++) {

        if (id != this.state.add_get_size[i].pi_qty) {
          piqty = parseInt(this.state.add_get_size[i].pi_qty);

          console.log("getqty", this.state.add_get_size[i].pi_qty)
          containercapqty = this.state.add_get_size[i].container_capacity_qty;
          containermaxcapacity = this.state.add_get_size[i].container_capacity;

          total_pi = total_pi + parseInt(piqty);
          console.log("totalqty", total_pi)

          if (containercapqty != '0') {
            volume_occupied = ((piqty * containercapqty) / 150) * 100;
          }
          total_volume = total_volume + volume_occupied


          console.log("Testoht", total_volume)
        } else {

        }


      }

      console.log("size", this.state.container_size)

      if (this.state.container_size == '20 Feet') {
        containersizevolume = 2030.535
      }

      if (this.state.container_size == '40 Feet') {
        containersizevolume = 4113.135
      }

      if (this.state.container_size == '40 High Cube') {
        containersizevolume = 4113.135
      }


      let noofcontainer = ''

      noofcontainer = Math.ceil(total_volume / containersizevolume)




      this.setState({
        displaycontainercapper: ((total_volume / containersizevolume) * 100).toFixed(0),
        get_conta: noofcontainer,
        get_tot: total_pi
      })

      this.state.displaycontainercapper = ((total_volume / containersizevolume) * 100).toFixed(0);


    } else {



      for (var i = 0; i < this.state.add_get_size.length; i++) {
        if (id != this.state.add_get_size[i].id) {
          piqty = parseInt(this.state.add_get_size[i].pi_qty);
          if (this.state.add_get_size[i].container_capacity_qty != '') {
            containercapqty = parseInt(this.state.add_get_size[i].container_capacity_qty);

          } else {
            containercapqty = '0'
          }
          total_pi = total_pi + piqty;
          if (containercapqty != '0') {
            containercapper = (piqty / containercapqty) * 100;
          }
          totalcontainercapper = totalcontainercapper + containercapper;
        } else {

        }

      }

      let noofcontainer = ''
      if (totalcontainercapper != '0' || totalcontainercapper != null) {
        noofcontainer = Math.floor(totalcontainercapper / 100) + Math.floor((totalcontainercapper % 100) / 95);
      } else {
        noofcontainer = ''
      }
      this.state.displaycontainercapper = (totalcontainercapper % 100).toFixed(2);
      this.setState({
        displaycontainercapper: (totalcontainercapper % 100).toFixed(2),
        get_tot: total_pi,
        get_conta: noofcontainer,

      })
    }

  }

  submitsave = (event) => {
    event.preventDefault();

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }

    let ot = this.state.order_type.toUpperCase();
    let po = this.state.po_number.toUpperCase();
    let size = this.state.container_size.toUpperCase();
    let payterm = this.state.pay_term
    let shitype = this.state.shipement_type.toUpperCase();
    let portdis = this.state.portofdischarge.toUpperCase();
    let incor = this.state.incotern.toUpperCase()
    let incolo = this.state.incoternlocation;
    let freight = this.state.freight;
    let final_desc = this.state.final_desc;
    let insurance = this.state.insurance;
    //let notes = this.state.notes;
    let notifymastername = this.state.notifymastername;
    //let priority_item = this.state.priority_item;
    let discount = this.state.discount;




    const insertdate = JSON.stringify({
      "order_mmyyyy": this.state.month_year,
      "order_no": this.state.order_number,
      "order_version": this.state.order_version,
      "order_category": this.state.curreny.toUpperCase(),
      // "customer_id":this.state.customer_id,
      "customer_sap_code": custcode,
      "notifyparty_sap_code": this.state.notifymastercode,
      "po_number": this.state.po_number,
      "po_date": this.state.po_date,
      "container_type": this.state.container_size,
      "container_capacity": this.state.displaycontainercapper,
      "total_order_qty": this.state.get_tot,
      "no_of_containers": this.state.get_conta,
      "total_order_value": this.state.get_tot,
      "se_code": "0",
      "final_destination": this.state.final_desc.toUpperCase(),
      // "port_of_discharge":this.state.port_delivery,
      "payment_term": this.state.pay_term.toUpperCase(),
      "inco_term": this.state.incotern.toUpperCase(),
      "order_type": this.state.order_type.toUpperCase(),
      "notes": this.state.notes,
      "filler_item": this.state.filler_item,
      "priority_item": this.state.priority_item,
      "pi_status": "save",
      "created_at": "",
      "modified_at": "",
      "notify_party_info1": this.state.notifyinfo.toUpperCase(),
      "notify_party_info2": this.state.notifyinfo1.toUpperCase(),
      "notify_party_info3": this.state.notifyinfo2.toUpperCase(),
      "notify_party_info4": this.state.notifyinfo3.toUpperCase(),
      "notify_party_info5": this.state.notifyinfo4.toUpperCase(),
      "inco_term_location": this.state.incoternlocation.toUpperCase(),
      "freight": this.state.freight,
      "insurance": this.state.insurance,
      "discount": this.state.discount,
      "precarriage_by": this.state.shipement_type.toUpperCase(),
      "port_of_discharge": this.state.portofdischarge.toUpperCase(),
      "commit_date_of_shipment": this.state.shipdate.toUpperCase(),
      "country_of_final_destination_place_of_supply": this.state.countryoffindes.toUpperCase(),
      "port_of_loading": this.state.portofloading


    })

    console.log("Header", insertdate)

    var details = this.state.add_get_size
    console.log("Header+details", details)
    const details_item = []

    for (var d = 0; d < details.length; d++) {
      details_item.push({
        segment_desc: details[d].segment_desc,
        segment_code: details[d].segment_code,
        product_group: details[d].product_group,
        product_desc: details[d].product_desc,
        product_code: details[d].product_code,
        price: details[d].price,
        price_date: details[d].price_date,
        bom_no: details[d].bom_no,
        pi_qty: details[d].pi_qty,
        pi_value: details[d].pi_value,
        container_capacity_qty: details[d].container_capacity_qty,
        container_capacity: details[d].container_capacity,
        container_capacity_per: details[d].container_capacity_per,
        product_type: details[d].product_type,
        parent_product_code: details[d].parent_product_code
      })
    }

    console.log("details ", details_item)
    console.log("mANITE", this.state.add_get_size)

    let getpiqty = ''
    let getpiprice = ''
    let errorpricesize = ''
    let errorqty = ''
    let get_price_date = ''
    let get_oht_pr = ''
    let error_date = ''
    for (var q = 0; q < this.state.add_get_size.length; q++) {

      getpiqty = this.state.add_get_size[q].pi_qty
      getpiprice = this.state.add_get_size[q].price

      get_price_date = this.state.add_get_size[q].price_date

      console.log("mANIDATE", get_price_date)

      if (getpiqty = '' || getpiqty == null) {
        errorqty = this.state.add_get_size[q].product_desc
      }

      if (getpiprice == '0.00') {
        errorpricesize = this.state.add_get_size[q].product_desc
      }
    }


    if (ot == "" || po == "" || size == "" || payterm == "" || incolo == "") {

      if (po == "") {
        alert("Please Enter po Number ")
      }

      if (ot == "") {
        alert("Please Select Order Type ")
      }

      if (size == "") {
        alert("Please Select Containter Size ")
      }

      if (payterm == "") {
        alert("Please Select Payment Term ")
      }

      if (incolo == "") {
        alert("Please Enter Incoterm Location")
      }

    } else {


      if (window.confirm(" Want to save?")) {

        var filler_item = ""
        var priority_item = ""
        var notes = ""

        var shipdate = ""

        if (this.state.filler_item != "" && this.state.filler_item !== undefined && this.state.filler_item !== null) {
          filler_item = this.state.filler_item.toUpperCase()
        } else {
          filler_item = ""
        }

        if (this.state.priority_item != "" && this.state.priority_item !== undefined && this.state.priority_item !== null) {
          priority_item = this.state.priority_item.toUpperCase()
        } else {
          priority_item = ""
        }

        if (this.state.notes != "" && this.state.notes !== undefined && this.state.notes !== null) {
          notes = this.state.notes.toUpperCase()
        } else {
          notes = ""
        }

        if (localStorage.getItem('segment') == 'OHT') {
          shipdate = ""
        } else {
          shipdate = this.state.shipdate
        }


        if (this.state.add_get_size != '') {

          axios({
            method: "POST",
            url: this.state.baseurl + 'update_pre_order',//"http://localhost:3300/custdetails",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
            },
            data: {
              p_prepi_details: JSON.stringify({
                "order_mmyyyy": this.state.month_year,
                "order_no": this.state.order_number,
                "order_version": this.state.order_version,
                "order_category": this.state.curreny.toUpperCase(),
                // "customer_id":this.state.customer_id,
                "customer_sap_code": custcode,
                "notifyparty_sap_code": this.state.notifymastercode,
                "po_number": this.state.po_number.toUpperCase(),
                "po_date": this.state.po_date,
                "container_type": this.state.container_size,
                "container_capacity": this.state.displaycontainercapper,
                "total_order_qty": this.state.get_tot,
                "no_of_containers": this.state.get_conta,
                "total_order_value": "0",
                "se_code": "0",
                "final_destination": this.state.final_desc.toUpperCase(),
                "port_of_discharge": this.state.port_delivery.toUpperCase(),
                "payment_term": this.state.pay_term.toUpperCase(),
                "inco_term": this.state.incotern.toUpperCase(),
                "order_type": this.state.order_type,
                "notes": notes,
                "filler_item": filler_item,
                "priority_item": priority_item,
                "pi_status": "save",
                "created_at": "",
                "modified_at": "",
                "notify_party_info1": this.state.notifyinfo.toUpperCase(),
                "notify_party_info2": this.state.notifyinfo1.toUpperCase(),
                "notify_party_info3": this.state.notifyinfo2.toUpperCase(),
                "notify_party_info4": this.state.notifyinfo3.toUpperCase(),
                "notify_party_info5": this.state.notifyinfo4.toUpperCase(),
                "inco_term_location": this.state.incoternlocation.toUpperCase(),
                "freight": this.state.freight,
                "insurance": this.state.insurance,
                "discount": this.state.discount,
                "precarriage_by": this.state.shipement_type.toUpperCase(),
                "port_of_discharge": this.state.portofdischarge.toUpperCase(),
                //"commit_date_of_shipment": this.state.shipdate,
                "commit_date_of_shipment": shipdate,
                "country_of_final_destination_place_of_supply": this.state.countryoffindes.toUpperCase(),
                "port_of_loading": this.state.portofloading,
                "ad_code": this.state.ad_code,
                "pi_valid_to": this.state.validto
              }),

              p_prepi_details_item: JSON.stringify({ "items": this.state.add_get_size })

            }
          }).then((response) => {

            if (response.data.status == 'success') {

              this.notify("Record Saved")
              let res = response.data.message
              console.log("saveorder", res)
              this.setState({
                order_type: response.data.data, // you have it as this.data not response
              });
              var getres = ''
              for (var i = 0; i < res.length; i++) {
                getres = res[i].fi01_prepi_order
              }
              // alert(getres)
              var gtres = getres.substring(0, 1)
              if (gtres == "R") {
                this.notify(getres)
              } else {
                //this.notify(getres)
                //  window.confirm(getres)
              }
              var gtres = getres.substring(0, 1)
              if (gtres == "A" || gtres == "E" || gtres == "O") {
                this.notify("Order Created :" + getres.substring(0, 11))

                this.setState({
                  order_number: getres.substring(0, 11)
                })
              }


              axios({
                method: "POST",
                url: this.state.baseurl + 'showorder_list',//"http://localhost:3300/custdetails",
                headers: {
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
                },
                data: {
                  customer_sap_code: this.state.customer_code,
                  order_mmyyyy: this.state.month_year,
                }
              }).then((response) => {
                console.log("Order", response.data.data[0])
                this.setState({
                  get_order_no: response.data.data,
                  order_number: response.data.data[0],
                });
                axios({
                  method: "POST",
                  url: this.state.baseurl + 'showversion_list',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    customer_sap_code: this.state.customer_code,
                    order_mmyyyy: this.state.month_year,
                    order_no: response.data.data[0]
                  }
                }).then((response) => {
                  console.log("version", response.data.data[0])
                  this.setState({
                    order_version: response.data.data[0],
                    get_order_verion: response.data.data,
                  });
                  axios({
                    method: "POST",
                    url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
                    headers: {
                      'Content-Type': 'application/json',
                      'token': localStorage.getItem('token')
                    },
                    data: {
                      customer_sap_code: this.state.customer_code,
                      order_mmyyyy: this.state.month_year,
                      order_no: this.state.order_number,
                      order_version: this.state.order_version
                    }
                  }).then((response) => {
                    console.log("custhdrs", response.data.status)

                    if (response.data.status == 'Success') {
                      var get_cu = response.data.hdrs

                      var get_tag = ''
                      var get_shipdate = ''

                      for (var j = 0; j < get_cu.length; j++) {

                        this.state.curreny = get_cu[j].order_category
                        this.state.pay_term = get_cu[j].payment_term;
                        get_tag = get_cu[j].payment_term;
                        this.state.incotern = get_cu[j].inco_term;
                        this.state.port_delivery = get_cu[j].port_of_discharge;
                        this.state.customer_code = get_cu[j].customer_sap_code;
                        this.state.customer_id = get_cu[j].customer_id
                        this.state.order_type = get_cu[j].order_type
                        this.state.container_size = get_cu[j].container_type
                        this.state.po_number = get_cu[j].po_number
                        this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
                        this.state.pistatus = get_cu[j].pi_status
                        this.state.pinumber = get_cu[j].pi_number
                        this.state.container_size = get_cu[j].container_type
                        this.state.notes = get_cu[j].notes
                        this.state.priority_item = get_cu[j].priority_item
                        this.state.final_desc = get_cu[j].final_destination
                        this.state.displaycontainercapper = get_cu[j].container_capacity
                        this.state.get_tot = get_cu[j].total_order_qty
                        this.state.get_conta = get_cu[j].no_of_containers
                        this.state.action_status = get_cu[j].action_status
                        this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
                        this.state.portofdischarge = get_cu[j].port_of_discharge
                        this.state.shipement_type = get_cu[j].precarriage_by
                        this.state.remarkes = get_cu[j].remarks_quo
                        this.state.remarkes1 = get_cu[j].remarks_so
                        this.state.discount = get_cu[j].discount
                        this.state.insurance = get_cu[j].insurance
                        this.state.freight = get_cu[j].freight
                        // this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                        get_shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                        this.state.incoternlocation = get_cu[j].inco_term_location
                        this.state.priority_item = get_cu[j].priority_item
                        this.state.notifyparty = get_cu[j].notify_party_info1
                        this.state.notifyinfo = get_cu[j].notify_party_info1
                        this.state.notifyinfo1 = get_cu[j].notify_party_info2
                        this.state.notifyinfo2 = get_cu[j].notify_party_info3
                        this.state.notifyinfo3 = get_cu[j].notify_party_info4
                        this.state.notifyinfo4 = get_cu[j].notify_party_info5
                        this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
                        this.state.filler_item = get_cu[j].filler_item
                        this.state.notifymastercode = get_cu[j].notifyparty_sap_code
                      }



                      const date = new Date();
                      const defaultValue = date.toLocaleDateString('en-CA');
                      if (localStorage.getItem('segment') == 'OHT') {
                        this.state.shipdate = defaultValue
                      } else {
                        this.state.shipdate = get_shipdate
                      }

                      if (localStorage.getItem('usertypecode') == 'CUST') {
                        axios({
                          method: "POST",
                          url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                          headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('token')
                          },
                          data: {
                            tag_value: get_tag,

                          }
                        }).then((response) => {
                          console.log("Test", response.data.data[0].tag_desc)
                          this.setState({
                            payment_term_code: response.data.data[0].tag_desc
                          })


                        });
                      } else {
                        this.state.pay_term = get_tag
                      }

                      console.log("SaveDeatails ", response.data.data)
                      const wishlist = response.data.data;
                      const getlist = []
                      let piqty = '0';
                      let pi_value = "0";
                      let price_date = this.state.price_date
                      let wi_prtype = ''
                      let wi_pcode = ''
                      let segment_desc = ""
                      let segment_code = ""
                      let product_group = ""
                      let product_desc = ""
                      let product_code = ""
                      let price = ""
                      let pi_qty = ""
                      let container_capacity_qty = ""
                      let container_capacity = ""
                      let container_capacity_per = ""
                      let product_type = ""
                      let parent_product_code = ""
                      let id = ""
                      let bom_no = ""

                      for (var w = 0; w < wishlist.length; w++) {

                        if (localStorage.getItem("segment") == 'EURO') {
                          price_date = wishlist[w].price_date
                        }

                        wi_prtype = wishlist[w].product_type
                        segment_desc = wishlist[w].segment_desc
                        segment_code = wishlist[w].segment_code
                        product_group = wishlist[w].product_group
                        product_desc = wishlist[w].product_desc
                        product_code = wishlist[w].product_code
                        price = wishlist[w].price
                        price_date = price_date
                        pi_qty = wishlist[w].pi_qty
                        pi_value = wishlist[w].pi_value
                        container_capacity_qty = wishlist[w].container_capacity_qty
                        container_capacity = wishlist[w].container_capacity
                        container_capacity_per = wishlist[w].container_capacity_per
                        product_type = wishlist[w].product_type
                        parent_product_code = wishlist[w].parent_product_code
                        id = wishlist[w].id
                        bom_no = wishlist[w].bom_no


                        getlist.push({
                          segment_desc: segment_desc,
                          segment_code: segment_code,
                          product_group: product_group,
                          product_desc: product_desc,
                          product_code: product_code,
                          price: price,
                          price_date: price_date,
                          bom_no: bom_no,
                          bom_no1: "",
                          pi_qty: pi_qty,
                          pi_value: pi_value,
                          container_capacity_qty: container_capacity_qty,
                          container_capacity: container_capacity,
                          container_capacity_per: container_capacity_per,
                          product_type: product_type,
                          parent_product_code: parent_product_code,
                          id: id
                        })


                        if (wi_prtype == 'Set') {
                          wi_pcode = wishlist[w].product_code

                          axios({
                            method: "POST",
                            url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                            headers: {
                              'Content-Type': 'application/json',
                              'token': localStorage.getItem('token')
                            },
                            data: {
                              'product_code': wishlist[w].product_code,
                            }
                          }).then((response) => {
                            const getbomkit = []
                            let kibom = response.data.data;
                            var items = getlist
                            var kitbom = response.data.data
                            let item_pcode = ''
                            let kit_pcode = ''

                            for (var b = 0; b < kitbom.length; b++) {
                              getbomkit.push({
                                // product_code :kitbom[b].product_code,
                                alt_bom_no: kitbom[b].alt_bom_no
                              })
                            }
                            this.setState({
                              wish_kit_bom: getbomkit
                            })

                            for (var k = 0; k < kitbom.length; k++) {
                              kit_pcode = kitbom[k].product_code

                              for (var b = 0; b < items.length; b++) {
                                item_pcode = items[b].product_code
                                console.log("Getbom", bom_no)
                                if (kit_pcode == item_pcode) {
                                  // items[b].bom_no = bom_no
                                  items[b].bom_no1 = response.data.data
                                } else {

                                }
                              }

                            }


                            this.setState({
                              add_get_size: getlist
                            })
                          });
                        }


                      }


                      this.setState({
                        //  add_get_size: response.data.data,
                        get_cu_details: response.data.hdrs,
                        // you have it as this.data not response
                      });
                    } else {
                      var get_cu = response.data.hdrs

                      var get_tag = ''

                      for (var j = 0; j < get_cu.length; j++) {

                        this.state.curreny = get_cu[j].order_category
                        this.state.pay_term = get_cu[j].payment_term;
                        get_tag = get_cu[j].payment_term;
                        this.state.incotern = get_cu[j].inco_term;
                        this.state.port_delivery = get_cu[j].port_of_discharge;
                        this.state.customer_code = get_cu[j].customer_sap_code;
                        this.state.customer_id = get_cu[j].customer_id
                        this.state.order_type = get_cu[j].order_type
                        this.state.container_size = get_cu[j].container_type
                        this.state.po_number = get_cu[j].po_number
                        this.state.po_date = get_cu[j].po_date
                        this.state.pistatus = ""
                        this.state.pinumber = ""
                        this.state.container_size = get_cu[j].container_type
                        this.state.notes = get_cu[j].notes
                        this.state.priority_item = get_cu[j].priority_item
                        this.state.final_desc = get_cu[j].final_destination
                        this.state.displaycontainercapper = get_cu[j].container_capacity
                        this.state.get_tot = get_cu[j].total_order_qty
                        this.state.get_conta = get_cu[j].no_of_containers
                        this.state.action_status = get_cu[j].action_status
                      }

                      if (localStorage.getItem('usertypecode') == 'CUST') {
                        axios({
                          method: "POST",
                          url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                          headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('token')
                          },
                          data: {
                            tag_value: get_tag,

                          }
                        }).then((response) => {
                          console.log("Test", response.data.data[0].tag_desc)
                          this.setState({
                            payment_term_code: response.data.data[0].tag_desc
                          })


                        });
                      } else {
                        this.state.pay_term = get_tag
                      }
                      console.log("Maniwish05 ", response.data.data)
                      const wishlist = response.data.data;
                      const getlist = []
                      let piqty = '0';
                      let pi_value = "0";
                      let price_date = this.state.price_date
                      let wi_prtype = ''
                      let wi_pcode = ''
                      let segment_desc = ""
                      let segment_code = ""
                      let product_group = ""
                      let product_desc = ""
                      let product_code = ""
                      let price = ""
                      const bom_no = []
                      let pi_qty = ""
                      let container_capacity_qty = ""
                      let container_capacity = ""
                      let container_capacity_per = ""
                      let product_type = ""
                      let parent_product_code = ""

                      for (var w = 0; w < wishlist.length; w++) {

                        if (localStorage.getItem("segment") == 'EURO') {
                          price_date = wishlist[w].price_date
                        }

                        wi_prtype = wishlist[w].product_type
                        segment_desc = wishlist[w].segment_desc
                        segment_code = wishlist[w].segment_code
                        product_group = wishlist[w].product_group
                        product_desc = wishlist[w].product_desc
                        product_code = wishlist[w].product_code
                        price = wishlist[w].price
                        price_date = price_date
                        pi_qty = wishlist[w].pi_qty
                        pi_value = wishlist[w].pi_value
                        container_capacity_qty = wishlist[w].container_capacity_qty
                        container_capacity = wishlist[w].container_capacity
                        container_capacity_per = wishlist[w].container_capacity_per
                        product_type = wishlist[w].product_type
                        parent_product_code = wishlist[w].parent_product_code


                        getlist.push({
                          segment_desc: segment_desc,
                          segment_code: segment_code,
                          product_group: product_group,
                          product_desc: product_desc,
                          product_code: product_code,
                          price: price,
                          price_date: price_date,
                          bom_no: "0",
                          bom_no1: "",
                          pi_qty: pi_qty,
                          pi_value: pi_value,
                          container_capacity_qty: container_capacity_qty,
                          container_capacity: container_capacity,
                          container_capacity_per: container_capacity_per,
                          product_type: product_type,
                          parent_product_code: parent_product_code
                        })


                        if (wi_prtype == 'Set') {
                          wi_pcode = wishlist[w].product_code

                          axios({
                            method: "POST",
                            url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                            headers: {
                              'Content-Type': 'application/json',
                              'token': localStorage.getItem('token')
                            },
                            data: {
                              'product_code': wishlist[w].product_code,
                            }
                          }).then((response) => {
                            const getbomkit = []
                            let kibom = response.data.data;
                            var items = getlist
                            var kitbom = response.data.data
                            let item_pcode = ''
                            let kit_pcode = ''

                            for (var b = 0; b < kitbom.length; b++) {
                              getbomkit.push({
                                // product_code :kitbom[b].product_code,
                                alt_bom_no: kitbom[b].alt_bom_no
                              })
                            }
                            this.setState({
                              wish_kit_bom: getbomkit
                            })

                            for (var k = 0; k < kitbom.length; k++) {
                              kit_pcode = kitbom[k].product_code

                              for (var b = 0; b < items.length; b++) {
                                item_pcode = items[b].product_code

                                if (kit_pcode == item_pcode) {
                                  // items[b].bom_no = response.data.data[0].alt_bom_no
                                  items[b].bom_no1 = response.data.data
                                } else {

                                }
                              }

                            }


                            this.setState({
                              add_get_size: getlist
                            })
                          });
                        }


                      }

                      this.setState({
                        // add_get_size: response.data.data,
                        get_cu_details: response.data.hdrs,
                        // you have it as this.data not response
                      });
                    }
                  });
                });
              });

            } else {

            }



          });


        } else {
          axios({
            method: "POST",
            url: this.state.baseurl + 'update_pre_order',//"http://localhost:3300/custdetails",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
            },
            data: {
              p_prepi_details: JSON.stringify({
                "order_mmyyyy": this.state.month_year,
                "order_no": this.state.order_number,
                "order_version": this.state.order_version,
                "order_category": this.state.curreny.toUpperCase(),
                // "customer_id":this.state.customer_id,
                "customer_sap_code": custcode,
                "notifyparty_sap_code": this.state.notifymastercode,
                "po_number": this.state.po_number.toUpperCase(),
                "po_date": this.state.po_date,
                "container_type": this.state.container_size,
                "container_capacity": this.state.displaycontainercapper,
                "total_order_qty": this.state.get_tot,
                "no_of_containers": this.state.get_conta,
                "total_order_value": "0",
                "se_code": "0",
                "final_destination": this.state.final_desc.toUpperCase(),
                "port_of_discharge": this.state.port_delivery.toUpperCase(),
                "payment_term": this.state.pay_term.toUpperCase(),
                "inco_term": this.state.incotern.toUpperCase(),
                "order_type": this.state.order_type,
                "notes": this.state.notes.toUpperCase(),
                "filler_item": this.state.filler_item.toUpperCase(),
                "priority_item": this.state.priority_item.toUpperCase(),
                "pi_status": "save",
                "created_at": "",
                "modified_at": "",
                "notify_party_info1": this.state.notifyinfo.toUpperCase(),
                "notify_party_info2": this.state.notifyinfo1.toUpperCase(),
                "notify_party_info3": this.state.notifyinfo2.toUpperCase(),
                "notify_party_info4": this.state.notifyinfo3.toUpperCase(),
                "notify_party_info5": this.state.notifyinfo4.toUpperCase(),
                "inco_term_location": this.state.incoternlocation.toUpperCase(),
                "freight": this.state.freight,
                "insurance": this.state.insurance,
                "discount": this.state.discount,
                "precarriage_by": this.state.shipement_type.toUpperCase(),
                "port_of_discharge": this.state.portofdischarge.toUpperCase(),
                //"commit_date_of_shipment": this.state.shipdate,
                "commit_date_of_shipment": shipdate,
                "country_of_final_destination_place_of_supply": this.state.countryoffindes.toUpperCase(),
                "port_of_loading": this.state.portofloading
              }),

              p_prepi_details_item: JSON.stringify({ "items": details_item })

            }
          }).then((response) => {

            if (response.data.status == 'success') {
              let res = response.data.message

              this.notify("Record Saved")
              this.setState({
                order_type: response.data.data, // you have it as this.data not response
              });
              var getres = ''
              for (var i = 0; i < res.length; i++) {
                getres = res[i].fi01_prepi_order
              }
              // alert(getres)
              var gtres = getres.substring(0, 1)
              if (gtres == "R") {
                this.notify(getres)
              } else {
                //this.notify(getres)
                //  window.confirm(getres)
              }
              var gtres = getres.substring(0, 1)
              if (gtres == "A") {
                this.notify("Order Created :" + getres.substring(1, 10))

                this.setState({
                  order_number: getres.substring(1, 10)
                })
              }
              axios({
                method: "POST",
                url: this.state.baseurl + 'showorder_list',//"http://localhost:3300/custdetails",
                headers: {
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
                },
                data: {
                  customer_sap_code: this.state.customer_code,
                  order_mmyyyy: this.state.month_year,
                }
              }).then((response) => {
                console.log("Order", response.data.data[0])
                this.setState({
                  get_order_no: response.data.data,
                  order_number: response.data.data[0],
                });
                axios({
                  method: "POST",
                  url: this.state.baseurl + 'showversion_list',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    customer_sap_code: this.state.customer_code,
                    order_mmyyyy: this.state.month_year,
                    order_no: response.data.data[0]
                  }
                }).then((response) => {
                  console.log("version", response.data.data[0])
                  this.setState({
                    order_version: response.data.data[0],
                    get_order_verion: response.data.data,
                  });
                  axios({
                    method: "POST",
                    url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
                    headers: {
                      'Content-Type': 'application/json',
                      'token': localStorage.getItem('token')
                    },
                    data: {
                      customer_sap_code: this.state.customer_code,
                      order_mmyyyy: this.state.month_year,
                      order_no: this.state.order_number,
                      order_version: this.state.order_version
                    }
                  }).then((response) => {
                    console.log("custhdrsmani", response.data.status)

                    if (response.data.status == 'Success') {
                      var get_cu = response.data.hdrs

                      var get_tag = ''
                      var get_shipdate = ""
                      for (var j = 0; j < get_cu.length; j++) {

                        this.state.curreny = get_cu[j].order_category
                        this.state.pay_term = get_cu[j].payment_term;
                        get_tag = get_cu[j].payment_term;
                        this.state.incotern = get_cu[j].inco_term;
                        this.state.port_delivery = get_cu[j].port_of_discharge;
                        this.state.customer_code = get_cu[j].customer_sap_code;
                        this.state.customer_id = get_cu[j].customer_id
                        this.state.order_type = get_cu[j].order_type
                        this.state.container_size = get_cu[j].container_type
                        this.state.po_number = get_cu[j].po_number
                        this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
                        this.state.pistatus = get_cu[j].pi_status
                        this.state.pinumber = get_cu[j].pi_number
                        this.state.container_size = get_cu[j].container_type
                        this.state.notes = get_cu[j].notes
                        this.state.priority_item = get_cu[j].priority_item
                        this.state.final_desc = get_cu[j].final_destination
                        this.state.displaycontainercapper = get_cu[j].container_capacity
                        this.state.get_tot = get_cu[j].total_order_qty
                        this.state.get_conta = get_cu[j].no_of_containers
                        this.state.action_status = get_cu[j].action_status
                        this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
                        this.state.portofdischarge = get_cu[j].port_of_discharge
                        this.state.shipement_type = get_cu[j].precarriage_by
                        this.state.remarkes = get_cu[j].remarks_quo
                        this.state.remarkes1 = get_cu[j].remarks_so
                        this.state.discount = get_cu[j].discount
                        this.state.freight = get_cu[j].freight
                        this.state.insurance = get_cu[j].insurance
                        //this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                        get_shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                        this.state.incoternlocation = get_cu[j].inco_term_location
                        this.state.priority_item = get_cu[j].priority_item
                        this.state.notifyparty = get_cu[j].notify_party_info1
                        this.state.notifyinfo = get_cu[j].notify_party_info1
                        this.state.notifyinfo1 = get_cu[j].notify_party_info2
                        this.state.notifyinfo2 = get_cu[j].notify_party_info3
                        this.state.notifyinfo3 = get_cu[j].notify_party_info4
                        this.state.notifyinfo4 = get_cu[j].notify_party_info5
                        this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
                        this.state.filler_item = get_cu[j].filler_item
                        this.state.notifymastercode = get_cu[j].notifyparty_sap_code
                      }


                      if (localStorage.getItem('segment') == 'OHT') {
                        this.state.shipdate = ""
                      } else {
                        this.state.shipdate = get_shipdate
                      }

                      if (localStorage.getItem('usertypecode') == 'CUST') {
                        axios({
                          method: "POST",
                          url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                          headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('token')
                          },
                          data: {
                            tag_value: get_tag,

                          }
                        }).then((response) => {
                          console.log("Test", response.data.data[0].tag_desc)
                          this.setState({
                            payment_term_code: response.data.data[0].tag_desc
                          })


                        });
                      } else {
                        this.state.pay_term = get_tag
                      }



                      console.log("Savepi order  ", response.data.data)
                      const wishlist = response.data.data;
                      const getlist = []
                      let piqty = '0';
                      let pi_value = "0";
                      let price_date = this.state.price_date
                      let wi_prtype = ''
                      let wi_pcode = ''
                      let segment_desc = ""
                      let segment_code = ""
                      let product_group = ""
                      let product_desc = ""
                      let product_code = ""
                      let price = ""
                      const bom_no = []
                      let pi_qty = ""
                      let container_capacity_qty = ""
                      let container_capacity = ""
                      let container_capacity_per = ""
                      let product_type = ""
                      let parent_product_code = ""
                      let id = ""

                      for (var w = 0; w < wishlist.length; w++) {

                        if (localStorage.getItem("segment") == 'EURO') {
                          price_date = wishlist[w].price_date
                        }

                        wi_prtype = wishlist[w].product_type
                        segment_desc = wishlist[w].segment_desc
                        segment_code = wishlist[w].segment_code
                        product_group = wishlist[w].product_group
                        product_desc = wishlist[w].product_desc
                        product_code = wishlist[w].product_code
                        price = wishlist[w].price
                        price_date = price_date
                        pi_qty = wishlist[w].pi_qty
                        pi_value = wishlist[w].pi_value
                        container_capacity_qty = wishlist[w].container_capacity_qty
                        container_capacity = wishlist[w].container_capacity
                        container_capacity_per = wishlist[w].container_capacity_per
                        product_type = wishlist[w].product_type
                        parent_product_code = wishlist[w].parent_product_code
                        id = wishlist[w].id


                        getlist.push({
                          segment_desc: segment_desc,
                          segment_code: segment_code,
                          product_group: product_group,
                          product_desc: product_desc,
                          product_code: product_code,
                          price: price,
                          price_date: price_date,
                          bom_no: "0",
                          bom_no1: "",
                          pi_qty: pi_qty,
                          pi_value: pi_value,
                          container_capacity_qty: container_capacity_qty,
                          container_capacity: container_capacity,
                          container_capacity_per: container_capacity_per,
                          product_type: product_type,
                          parent_product_code: parent_product_code,
                          id: id

                        })


                        if (wi_prtype == 'Set') {
                          wi_pcode = wishlist[w].product_code

                          axios({
                            method: "POST",
                            url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                            headers: {
                              'Content-Type': 'application/json',
                              'token': localStorage.getItem('token')
                            },
                            data: {
                              'product_code': wishlist[w].product_code,
                            }
                          }).then((response) => {
                            console.log("bomkit", response.data.data)
                            const getbomkit = []
                            let kibom = response.data.data;
                            var items = getlist
                            var kitbom = response.data.data
                            let item_pcode = ''
                            let kit_pcode = ''

                            for (var b = 0; b < kitbom.length; b++) {
                              getbomkit.push({
                                // product_code :kitbom[b].product_code,
                                alt_bom_no: kitbom[b].alt_bom_no
                              })
                            }
                            this.setState({
                              wish_kit_bom: getbomkit
                            })

                            for (var k = 0; k < kitbom.length; k++) {
                              kit_pcode = kitbom[k].product_code

                              for (var b = 0; b < items.length; b++) {
                                item_pcode = items[b].product_code

                                if (kit_pcode == item_pcode) {
                                  items[b].bom_no1 = response.data.data
                                  items[b].bom_no = response.data.data[0].alt_bom_no
                                } else {

                                }
                              }

                            }


                            this.setState({
                              add_get_size: getlist
                            })
                          });
                        }


                      }





                      // this.setState({
                      //   add_get_size: response.data.data,
                      //   get_cu_details: response.data.hdrs,
                      //   // you have it as this.data not response
                      // });
                    } else {
                      var get_cu = response.data.hdrs
                      var get_tag = ''
                      for (var j = 0; j < get_cu.length; j++) {

                        this.state.curreny = get_cu[j].order_category
                        this.state.pay_term = get_cu[j].payment_term;
                        get_tag = get_cu[j].payment_term;
                        this.state.incotern = get_cu[j].inco_term;
                        this.state.port_delivery = get_cu[j].port_of_discharge;
                        this.state.customer_code = get_cu[j].customer_sap_code;
                        this.state.customer_id = get_cu[j].customer_id
                        this.state.order_type = get_cu[j].order_type
                        this.state.container_size = get_cu[j].container_type
                        this.state.po_number = get_cu[j].po_number
                        this.state.po_date = get_cu[j].po_date
                        this.state.pistatus = ""
                        this.state.pinumber = ""
                        this.state.container_size = get_cu[j].container_type
                        this.state.notes = get_cu[j].notes
                        this.state.priority_item = get_cu[j].priority_item
                        this.state.final_desc = get_cu[j].final_destination
                        this.state.displaycontainercapper = get_cu[j].container_capacity
                        this.state.get_tot = get_cu[j].total_order_qty
                        this.state.get_conta = get_cu[j].no_of_containers
                        this.state.action_status = get_cu[j].action_status
                      }


                      if (localStorage.getItem('usertypecode') == 'CUST') {
                        axios({
                          method: "POST",
                          url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                          headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('token')
                          },
                          data: {
                            tag_value: get_tag,

                          }
                        }).then((response) => {
                          console.log("Test", response.data.data[0].tag_desc)
                          this.setState({
                            payment_term_code: response.data.data[0].tag_desc
                          })


                        });
                      } else {
                        this.state.pay_term = get_tag
                      }

                      console.log("Maniwish05 ", response.data.data)
                      const wishlist = response.data.data;
                      const getlist = []
                      let piqty = '0';
                      let pi_value = "0";
                      let price_date = this.state.price_date
                      let wi_prtype = ''
                      let wi_pcode = ''
                      let segment_desc = ""
                      let segment_code = ""
                      let product_group = ""
                      let product_desc = ""
                      let product_code = ""
                      let price = ""
                      const bom_no = []
                      let pi_qty = ""
                      let container_capacity_qty = ""
                      let container_capacity = ""
                      let container_capacity_per = ""
                      let product_type = ""
                      let parent_product_code = ""

                      for (var w = 0; w < wishlist.length; w++) {

                        if (localStorage.getItem("segment") == 'EURO') {
                          price_date = wishlist[w].price_date
                        }

                        wi_prtype = wishlist[w].product_type
                        segment_desc = wishlist[w].segment_desc
                        segment_code = wishlist[w].segment_code
                        product_group = wishlist[w].product_group
                        product_desc = wishlist[w].product_desc
                        product_code = wishlist[w].product_code
                        price = wishlist[w].price
                        price_date = price_date
                        pi_qty = wishlist[w].pi_qty
                        pi_value = wishlist[w].pi_value
                        container_capacity_qty = wishlist[w].container_capacity_qty
                        container_capacity = wishlist[w].container_capacity
                        container_capacity_per = wishlist[w].container_capacity_per
                        product_type = wishlist[w].product_type
                        parent_product_code = wishlist[w].parent_product_code


                        getlist.push({
                          segment_desc: segment_desc,
                          segment_code: segment_code,
                          product_group: product_group,
                          product_desc: product_desc,
                          product_code: product_code,
                          price: price,
                          price_date: price_date,
                          bom_no: "0",
                          bom_no1: "",
                          pi_qty: pi_qty,
                          pi_value: pi_value,
                          container_capacity_qty: container_capacity_qty,
                          container_capacity: container_capacity,
                          container_capacity_per: container_capacity_per,
                          product_type: product_type,
                          parent_product_code: parent_product_code
                        })


                        if (wi_prtype == 'Set') {
                          wi_pcode = wishlist[w].product_code

                          axios({
                            method: "POST",
                            url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                            headers: {
                              'Content-Type': 'application/json',
                              'token': localStorage.getItem('token')
                            },
                            data: {
                              'product_code': wishlist[w].product_code,
                            }
                          }).then((response) => {
                            const getbomkit = []
                            let kibom = response.data.data;
                            var items = getlist
                            var kitbom = response.data.data
                            let item_pcode = ''
                            let kit_pcode = ''

                            for (var b = 0; b < kitbom.length; b++) {
                              getbomkit.push({
                                // product_code :kitbom[b].product_code,
                                alt_bom_no: kitbom[b].alt_bom_no
                              })
                            }
                            this.setState({
                              wish_kit_bom: getbomkit
                            })

                            for (var k = 0; k < kitbom.length; k++) {
                              kit_pcode = kitbom[k].product_code

                              for (var b = 0; b < items.length; b++) {
                                item_pcode = items[b].product_code

                                if (kit_pcode == item_pcode) {
                                  items[b].bom_no1 = response.data.data
                                  items[b].bom_no = response.data.data[0].alt_bom_no
                                } else {

                                }
                              }

                            }


                            this.setState({
                              add_get_size: getlist
                            })
                          });
                        }


                      }


                      // this.setState({
                      //   add_get_size: response.data.data,
                      //   get_cu_details: response.data.hdrs,
                      //   // you have it as this.data not response
                      // });
                    }
                  });
                });
              });
            } else {

            }

          });
        }
      }
    }

  }

  submitLists = (event) => {
    event.preventDefault();

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }
    const insertdate = JSON.stringify({
      "order_mmyyyy": this.state.month_year,
      "order_no": this.state.order_number,
      "order_version": this.state.order_version,
      "order_category": "",
      "customer_id": this.state.customer_id,
      "customer_sap_code": custcode,
      "notifyparty_sap_code": this.state.notifymastercode,
      "po_number": this.state.po_number.toUpperCase(),
      "po_date": this.state.po_date,
      "container_type": this.state.container_size,
      "container_capacity": this.state.displaycontainercapper,
      "total_order_qty": this.state.get_tot,
      "no_of_containers": this.state.get_conta,
      "total_order_value": "0",
      "se_code": "0",
      "final_destination": this.state.final_desc,
      "port_of_discharge": this.state.port_delivery,
      "payment_term": this.state.pay_term,
      "inco_term": this.state.incotern,
      "order_type": this.state.order_type.toUpperCase(),
      "notes": this.state.notes,
      "filler_item": this.state.filler_item,
      "priority_item": this.state.priority_item,
      "pi_status": "submit",
      "created_at": "",
      "modified_at": "",

    })

    console.log("Header", insertdate)
    var date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    console.log("Manidate", '"' + moment(lastDay).format('DD') + 'th"' + month)

    console.log("Manidate", moment(lastDay).format('MMM-DD'))
    console.log("details ", this.state.add_get_size)
    let getpiqty = ''
    let getpiprice = ''
    let errorpricesize = ''
    let errorqty = ''
    let get_price_date = ''
    let get_oht_pr = ''
    for (var q = 0; q < this.state.add_get_size.length; q++) {

      getpiqty = this.state.add_get_size[q].pi_qty
      getpiprice = this.state.add_get_size[q].price
      get_price_date = this.state.add_get_size[q].price_date
      if (getpiqty = '' || getpiqty == null) {
        errorqty = this.state.add_get_size[q].product_desc
      }
      if (getpiprice == '0.0') {
        errorpricesize = this.state.add_get_size[q].product_desc
      }


      console.log("Mani", errorpricesize)

    }

    var shipdate = ""
    if (localStorage.getItem('segment') == 'OHT') {
      shipdate = ""
    } else {
      shipdate = this.state.shipdate
    }

    if (this.state.add_get_size != '') {
      if (errorpricesize != '') {
        alert(errorpricesize + " :Price  > 0")
      } else {
        if (errorqty != "") {
          alert(errorqty + ": PFI Qty > 0")
        } else {
          if (localStorage.getItem('usertypecode') == 'CUST' && localStorage.getItem('segment') == '2WEXPORT') {
            alert("Price valid for the shipment made on or before" + '"' + moment(lastDay).format('DD') + 'th"' + month)
            // axios({
            //   method: "POST",
            //   url: this.state.baseurl + 'update_pre_order',//"http://localhost:3300/custdetails",
            //   headers: {
            //     'Content-Type': 'application/json',
            //     'token': localStorage.getItem('token')
            //   },
            //   data: {
            //     p_prepi_details: JSON.stringify({
            //       "order_mmyyyy": this.state.month_year,
            //       "order_no": this.state.order_number,
            //       "order_version": this.state.order_version,
            //       "order_category": this.state.curreny.toUpperCase(),
            //       // "customer_id":this.state.customer_id,
            //       "customer_sap_code": custcode,
            //       "notifyparty_sap_code": this.state.notifymastercode,
            //       "po_number": this.state.po_number.toUpperCase(),
            //       "po_date": this.state.po_date,
            //       "container_type": this.state.container_size,
            //       "container_capacity": this.state.displaycontainercapper,
            //       "total_order_qty": this.state.get_tot,
            //       "no_of_containers": this.state.get_conta,
            //       "total_order_value": "0",
            //       "se_code": "0",
            //       "final_destination": this.state.final_desc.toUpperCase(),
            //       "port_of_discharge": this.state.port_delivery.toUpperCase(),
            //       "payment_term": this.state.pay_term.toUpperCase(),
            //       "inco_term": this.state.incotern.toUpperCase(),
            //       "order_type": this.state.order_type,
            //       "notes": this.state.notes.toUpperCase(),
            //       "filler_item": this.state.filler_item.toUpperCase(),
            //       "priority_item": this.state.priority_item.toUpperCase(),
            //       "pi_status": "submit",
            //       "created_at": "",
            //       "modified_at": "",
            //       "notify_party_info1": this.state.notifyinfo.toUpperCase(),
            //       "notify_party_info2": this.state.notifyinfo1.toUpperCase(),
            //       "notify_party_info3": this.state.notifyinfo2.toUpperCase(),
            //       "notify_party_info4": this.state.notifyinfo3.toUpperCase(),
            //       "notify_party_info5": this.state.notifyinfo4.toUpperCase(),
            //       "inco_term_location": this.state.incoternlocation.toUpperCase(),
            //       "freight": this.state.freight,
            //       "insurance": this.state.insurance,
            //       "discount": this.state.discount,
            //       "precarriage_by": this.state.shipement_type.toUpperCase(),
            //       "port_of_discharge": this.state.portofdischarge.toUpperCase(),
            //       // "commit_date_of_shipment": this.state.shipdate,
            //       "commit_date_of_shipment": shipdate,
            //       "country_of_final_destination_place_of_supply": this.state.countryoffindes.toUpperCase(),
            //       "port_of_loading": this.state.portofloading
            //     }),

            //     p_prepi_details_item: JSON.stringify({ "items": this.state.add_get_size })

            //   }
            // }).then((response) => {

            //   if (response.data.status == 'success') {
            //     let res = response.data.message

            //     axios({
            //       method: "POST",
            //       url: this.state.baseurl + 'submit_pre_order',//"http://localhost:3300/custdetails",
            //       headers: {
            //         'Content-Type': 'application/json',
            //         'token': localStorage.getItem('token')
            //       },
            //       data: {
            //         order_mmyyyy: this.state.month_year,
            //         customer_sap_code: this.state.customer_code,
            //         order_no: this.state.order_number,
            //         order_version: this.state.order_version,
            //       }
            //     }).then((response) => {
            //       let res = response.data.message

            //       if (response.data.status == 'success') {
            //         this.notify("Record Submited")
            //       }

            //     });

            //     this.setState({
            //       order_type: response.data.data, // you have it as this.data not response
            //     });
            //     var getres = ''
            //     for (var i = 0; i < res.length; i++) {
            //       getres = res[i].fi01_prepi_order
            //     }
            //     var gtres = getres.substring(0, 1)
            //     if (gtres == "R") {
            //       this.notify(getres)
            //       // window.location.reload(true);
            //     } else {
            //       // window.confirm(getres)
            //     }

            //     axios({
            //       method: "POST",
            //       url: this.state.baseurl + 'showorder_list',//"http://localhost:3300/custdetails",
            //       headers: {
            //         'Content-Type': 'application/json',
            //         'token': localStorage.getItem('token')
            //       },
            //       data: {
            //         customer_sap_code: this.state.customer_code,
            //         order_mmyyyy: this.state.month_year,
            //       }
            //     }).then((response) => {
            //       console.log("Order", response.data.data[0])
            //       this.setState({
            //         get_order_no: response.data.data,
            //         order_number: response.data.data[0],
            //       });
            //       axios({
            //         method: "POST",
            //         url: this.state.baseurl + 'showversion_list',//"http://localhost:3300/custdetails",
            //         headers: {
            //           'Content-Type': 'application/json',
            //           'token': localStorage.getItem('token')
            //         },
            //         data: {
            //           customer_sap_code: this.state.customer_code,
            //           order_mmyyyy: this.state.month_year,
            //           order_no: response.data.data[0]
            //         }
            //       }).then((response) => {
            //         console.log("version", response.data.data[0])
            //         this.setState({
            //           order_version: response.data.data[0],
            //           get_order_verion: response.data.data,
            //         });
            //         axios({
            //           method: "POST",
            //           url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
            //           headers: {
            //             'Content-Type': 'application/json',
            //             'token': localStorage.getItem('token')
            //           },
            //           data: {
            //             customer_sap_code: this.state.customer_code,
            //             order_mmyyyy: this.state.month_year,
            //             order_no: this.state.order_number,
            //             order_version: this.state.order_version
            //           }
            //         }).then((response) => {
            //           console.log("custhdrs", response.data.status)

            //           if (response.data.status == 'Success') {
            //             var get_cu = response.data.hdrs
            //             var get_tag = ''
            //             var get_shipdate = ''
            //             const date = new Date();
            //             const defaultValue = date.toLocaleDateString('en-CA');
            //             for (var j = 0; j < get_cu.length; j++) {

            //               this.state.curreny = get_cu[j].order_category
            //               this.state.pay_term = get_cu[j].payment_term;
            //               get_tag = get_cu[j].payment_term;
            //               this.state.incotern = get_cu[j].inco_term;
            //               this.state.port_delivery = get_cu[j].port_of_discharge;
            //               this.state.customer_code = get_cu[j].customer_sap_code;
            //               this.state.customer_id = get_cu[j].customer_id
            //               this.state.order_type = get_cu[j].order_type
            //               this.state.container_size = get_cu[j].container_type
            //               this.state.po_number = get_cu[j].po_number
            //               this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
            //               this.state.pistatus = get_cu[j].pi_status
            //               this.state.pinumber = get_cu[j].pi_number
            //               this.state.container_size = get_cu[j].container_type
            //               this.state.notes = get_cu[j].notes
            //               this.state.priority_item = get_cu[j].priority_item
            //               this.state.final_desc = get_cu[j].final_destination
            //               this.state.displaycontainercapper = get_cu[j].container_capacity
            //               this.state.get_tot = get_cu[j].total_order_qty
            //               this.state.get_conta = get_cu[j].no_of_containers
            //               this.state.action_status = get_cu[j].action_status
            //               this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
            //               this.state.portofdischarge = get_cu[j].port_of_discharge
            //               this.state.shipement_type = get_cu[j].precarriage_by
            //               this.state.remarkes = get_cu[j].remarks_quo
            //               this.state.remarkes1 = get_cu[j].remarks_so
            //               this.state.discount = get_cu[j].discount
            //               this.state.freight = get_cu[j].freight
            //               this.state.insurance = get_cu[j].insurance
            //               //this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
            //               get_shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
            //               this.state.incoternlocation = get_cu[j].inco_term_location
            //               this.state.priority_item = get_cu[j].priority_item
            //               this.state.notifyparty = get_cu[j].notify_party_info1
            //               this.state.notifyinfo = get_cu[j].notify_party_info1
            //               this.state.notifyinfo1 = get_cu[j].notify_party_info2
            //               this.state.notifyinfo2 = get_cu[j].notify_party_info3
            //               this.state.notifyinfo3 = get_cu[j].notify_party_info4
            //               this.state.notifyinfo4 = get_cu[j].notify_party_info5
            //               this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
            //               this.state.filler_item = get_cu[j].filler_item
            //               this.state.notifymastercode = get_cu[j].notifyparty_sap_code
            //             }

            //             if (localStorage.getItem('segment') == 'OHT') {
            //               this.state.shipdate = defaultValue
            //             } else {
            //               this.state.shipdate = get_shipdate
            //             }

            //             if (localStorage.getItem('usertypecode') == 'CUST') {
            //               axios({
            //                 method: "POST",
            //                 url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
            //                 headers: {
            //                   'Content-Type': 'application/json',
            //                   'token': localStorage.getItem('token')
            //                 },
            //                 data: {
            //                   tag_value: get_tag,

            //                 }
            //               }).then((response) => {
            //                 console.log("Test", response.data.data[0].tag_desc)
            //                 this.setState({
            //                   payment_term_code: response.data.data[0].tag_desc
            //                 })


            //               });
            //             } else {
            //               this.state.pay_term = get_tag
            //             }

            //             console.log("Maniwish05 ", response.data.data)
            //             const wishlist = response.data.data;
            //             const getlist = []
            //             let piqty = '0';
            //             let pi_value = "0";
            //             let price_date = this.state.price_date
            //             let wi_prtype = ''
            //             let wi_pcode = ''
            //             let segment_desc = ""
            //             let segment_code = ""
            //             let product_group = ""
            //             let product_desc = ""
            //             let product_code = ""
            //             let price = ""
            //             let pi_qty = ""
            //             let container_capacity_qty = ""
            //             let container_capacity = ""
            //             let container_capacity_per = ""
            //             let product_type = ""
            //             let parent_product_code = ""
            //             let bom_no = ''
            //             let id = ''

            //             for (var w = 0; w < wishlist.length; w++) {

            //               if (localStorage.getItem("segment") == 'EURO') {
            //                 price_date = wishlist[w].price_date
            //               }

            //               wi_prtype = wishlist[w].product_type
            //               segment_desc = wishlist[w].segment_desc
            //               segment_code = wishlist[w].segment_code
            //               product_group = wishlist[w].product_group
            //               product_desc = wishlist[w].product_desc
            //               product_code = wishlist[w].product_code
            //               price = wishlist[w].price
            //               price_date = wishlist[w].price_date
            //               pi_qty = wishlist[w].pi_qty
            //               pi_value = wishlist[w].pi_value
            //               container_capacity_qty = wishlist[w].container_capacity_qty
            //               container_capacity = wishlist[w].container_capacity
            //               container_capacity_per = wishlist[w].container_capacity_per
            //               product_type = wishlist[w].product_type
            //               parent_product_code = wishlist[w].parent_product_code
            //               bom_no = wishlist[w].bom_no
            //               id = wishlist[w].id

            //               getlist.push({
            //                 segment_desc: segment_desc,
            //                 segment_code: segment_code,
            //                 product_group: product_group,
            //                 product_desc: product_desc,
            //                 product_code: product_code,
            //                 price: price,
            //                 price_date: price_date,
            //                 bom_no: bom_no,
            //                 bom_no1: "",
            //                 pi_qty: pi_qty,
            //                 pi_value: pi_value,
            //                 container_capacity_qty: container_capacity_qty,
            //                 container_capacity: container_capacity,
            //                 container_capacity_per: container_capacity_per,
            //                 product_type: product_type,
            //                 parent_product_code: parent_product_code,
            //                 id: id
            //               })


            //               if (wi_prtype == 'Set') {
            //                 wi_pcode = wishlist[w].product_code

            //                 axios({
            //                   method: "POST",
            //                   url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
            //                   headers: {
            //                     'Content-Type': 'application/json',
            //                     'token': localStorage.getItem('token')
            //                   },
            //                   data: {
            //                     'product_code': wishlist[w].product_code,
            //                   }
            //                 }).then((response) => {
            //                   console.log("Manitest", response.data.data)
            //                   const getbomkit = []
            //                   let kibom = response.data.data;
            //                   var items = getlist
            //                   var kitbom = response.data.data
            //                   let item_pcode = ''
            //                   let kit_pcode = ''

            //                   for (var b = 0; b < kitbom.length; b++) {
            //                     getbomkit.push({
            //                       // product_code :kitbom[b].product_code,
            //                       alt_bom_no: kitbom[b].alt_bom_no
            //                     })
            //                   }
            //                   this.setState({
            //                     wish_kit_bom: getbomkit
            //                   })

            //                   for (var k = 0; k < kitbom.length; k++) {
            //                     kit_pcode = kitbom[k].product_code

            //                     for (var b = 0; b < items.length; b++) {
            //                       item_pcode = items[b].product_code

            //                       if (kit_pcode == item_pcode) {
            //                         items[b].bom_no1 = response.data.data
            //                         items[b].bom_no = response.data.data[0].alt_bom_no
            //                       } else {

            //                       }
            //                     }
            //                   }
            //                   this.setState({
            //                     add_get_size: getlist
            //                   })
            //                 });
            //               } else {
            //                 console.log("Testmanicode", getlist)
            //                 this.setState({
            //                   add_get_size: getlist
            //                 })
            //               }


            //             }

            //             this.setState({
            //               //  add_get_size: response.data.data,
            //               get_cu_details: response.data.hdrs,
            //               // you have it as this.data not response
            //             });
            //           } else {
            //             var get_cu = response.data.hdrs
            //             var get_tag = ''
            //             for (var j = 0; j < get_cu.length; j++) {

            //               this.state.curreny = get_cu[j].order_category
            //               this.state.pay_term = get_cu[j].payment_term;
            //               get_tag = get_cu[j].payment_term;
            //               this.state.incotern = get_cu[j].inco_term;
            //               this.state.port_delivery = get_cu[j].port_of_discharge;
            //               this.state.customer_code = get_cu[j].customer_sap_code;
            //               this.state.customer_id = get_cu[j].customer_id
            //               this.state.order_type = get_cu[j].order_type
            //               this.state.container_size = get_cu[j].container_type
            //               this.state.po_number = get_cu[j].po_number
            //               this.state.po_date = get_cu[j].po_date
            //               this.state.pistatus = ""
            //               this.state.pinumber = ""
            //               this.state.container_size = get_cu[j].container_type
            //               this.state.notes = get_cu[j].notes
            //               this.state.priority_item = get_cu[j].priority_item
            //               this.state.final_desc = get_cu[j].final_destination
            //               this.state.displaycontainercapper = get_cu[j].container_capacity
            //               this.state.get_tot = get_cu[j].total_order_qty
            //               this.state.get_conta = get_cu[j].no_of_containers
            //               this.state.action_status = get_cu[j].action_status
            //             }

            //             if (localStorage.getItem('usertypecode') == 'CUST') {
            //               axios({
            //                 method: "POST",
            //                 url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
            //                 headers: {
            //                   'Content-Type': 'application/json',
            //                   'token': localStorage.getItem('token')
            //                 },
            //                 data: {
            //                   tag_value: get_tag,

            //                 }
            //               }).then((response) => {
            //                 console.log("Test", response.data.data[0].tag_desc)
            //                 this.setState({
            //                   payment_term_code: response.data.data[0].tag_desc
            //                 })


            //               });
            //             } else {
            //               this.state.pay_term = get_tag
            //             }

            //             console.log("Maniwish05 ", response.data.data)
            //             const wishlist = response.data.data;
            //             const getlist = []
            //             let piqty = '0';
            //             let pi_value = "0";
            //             let price_date = this.state.price_date
            //             let wi_prtype = ''
            //             let wi_pcode = ''
            //             let segment_desc = ""
            //             let segment_code = ""
            //             let product_group = ""
            //             let product_desc = ""
            //             let product_code = ""
            //             let price = ""
            //             let pi_qty = ""
            //             let container_capacity_qty = ""
            //             let container_capacity = ""
            //             let container_capacity_per = ""
            //             let product_type = ""
            //             let parent_product_code = ""
            //             let bom_no = ''
            //             let id = ''

            //             for (var w = 0; w < wishlist.length; w++) {

            //               if (localStorage.getItem("segment") == 'EURO') {
            //                 price_date = wishlist[w].price_date
            //               }

            //               wi_prtype = wishlist[w].product_type
            //               segment_desc = wishlist[w].segment_desc
            //               segment_code = wishlist[w].segment_code
            //               product_group = wishlist[w].product_group
            //               product_desc = wishlist[w].product_desc
            //               product_code = wishlist[w].product_code
            //               price = wishlist[w].price
            //               price_date = wishlist[w].price_date
            //               pi_qty = wishlist[w].pi_qty
            //               pi_value = wishlist[w].pi_value
            //               container_capacity_qty = wishlist[w].container_capacity_qty
            //               container_capacity = wishlist[w].container_capacity
            //               container_capacity_per = wishlist[w].container_capacity_per
            //               product_type = wishlist[w].product_type
            //               parent_product_code = wishlist[w].parent_product_code
            //               bom_no = wishlist[w].bom_no
            //               id = wishlist[w].id

            //               getlist.push({
            //                 segment_desc: segment_desc,
            //                 segment_code: segment_code,
            //                 product_group: product_group,
            //                 product_desc: product_desc,
            //                 product_code: product_code,
            //                 price: price,
            //                 price_date: price_date,
            //                 bom_no: bom_no,
            //                 bom_no1: "",
            //                 pi_qty: pi_qty,
            //                 pi_value: pi_value,
            //                 container_capacity_qty: container_capacity_qty,
            //                 container_capacity: container_capacity,
            //                 container_capacity_per: container_capacity_per,
            //                 product_type: product_type,
            //                 parent_product_code: parent_product_code,
            //                 id: id
            //               })


            //               if (wi_prtype == 'Set') {
            //                 wi_pcode = wishlist[w].product_code

            //                 axios({
            //                   method: "POST",
            //                   url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
            //                   headers: {
            //                     'Content-Type': 'application/json',
            //                     'token': localStorage.getItem('token')
            //                   },
            //                   data: {
            //                     'product_code': wishlist[w].product_code,
            //                   }
            //                 }).then((response) => {
            //                   console.log("Manitest", response.data.data)
            //                   const getbomkit = []
            //                   let kibom = response.data.data;
            //                   var items = getlist
            //                   var kitbom = response.data.data
            //                   let item_pcode = ''
            //                   let kit_pcode = ''

            //                   for (var b = 0; b < kitbom.length; b++) {
            //                     getbomkit.push({
            //                       // product_code :kitbom[b].product_code,
            //                       alt_bom_no: kitbom[b].alt_bom_no
            //                     })
            //                   }
            //                   this.setState({
            //                     wish_kit_bom: getbomkit
            //                   })

            //                   for (var k = 0; k < kitbom.length; k++) {
            //                     kit_pcode = kitbom[k].product_code

            //                     for (var b = 0; b < items.length; b++) {
            //                       item_pcode = items[b].product_code

            //                       if (kit_pcode == item_pcode) {
            //                         items[b].bom_no1 = response.data.data
            //                         items[b].bom_no = response.data.data[0].alt_bom_no
            //                       } else {

            //                       }
            //                     }
            //                   }
            //                   this.setState({
            //                     add_get_size: getlist
            //                   })
            //                 });
            //               } else {
            //                 console.log("Testmanicode", getlist)
            //                 this.setState({
            //                   add_get_size: getlist
            //                 })
            //               }


            //             }
            //             this.setState({
            //               //  add_get_size: response.data.data,
            //               get_cu_details: response.data.hdrs,
            //               // you have it as this.data not response
            //             });
            //           }
            //         });
            //       });
            //     });
            //   } else {

            //   }

            // });
          } else {
            axios({
              method: "POST",
              url: this.state.baseurl + 'update_pre_order',//"http://localhost:3300/custdetails",
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
              },
              data: {
                p_prepi_details: JSON.stringify({
                  "order_mmyyyy": this.state.month_year,
                  "order_no": this.state.order_number,
                  "order_version": this.state.order_version,
                  "order_category": this.state.curreny.toUpperCase(),
                  // "customer_id":this.state.customer_id,
                  "customer_sap_code": custcode,
                  "notifyparty_sap_code": this.state.notifymastercode,
                  "po_number": this.state.po_number.toUpperCase(),
                  "po_date": this.state.po_date,
                  "container_type": this.state.container_size,
                  "container_capacity": this.state.displaycontainercapper,
                  "total_order_qty": this.state.get_tot,
                  "no_of_containers": this.state.get_conta,
                  "total_order_value": "0",
                  "se_code": "0",
                  "final_destination": this.state.final_desc,
                  "port_of_discharge": this.state.port_delivery,
                  "payment_term": this.state.pay_term.toUpperCase(),
                  "inco_term": this.state.incotern.toUpperCase(),
                  "order_type": this.state.order_type,
                  "notes": this.state.notes.toUpperCase(),
                  "filler_item": this.state.filler_item.toUpperCase(),
                  "priority_item": this.state.priority_item.toUpperCase(),
                  "pi_status": "submit",
                  "created_at": "",
                  "modified_at": "",
                  "notify_party_info1": this.state.notifyinfo.toUpperCase(),
                  "notify_party_info2": this.state.notifyinfo1.toUpperCase(),
                  "notify_party_info3": this.state.notifyinfo2.toUpperCase(),
                  "notify_party_info4": this.state.notifyinfo3.toUpperCase(),
                  "notify_party_info5": this.state.notifyinfo4.toUpperCase(),
                  "inco_term_location": this.state.incoternlocation,
                  "freight": this.state.freight,
                  "insurance": this.state.insurance,
                  "discount": this.state.discount,
                  "precarriage_by": this.state.shipement_type.toUpperCase(),
                  "port_of_discharge": this.state.portofdischarge.toUpperCase(),
                  //"commit_date_of_shipment": this.state.shipdate,
                  "commit_date_of_shipment": shipdate,
                  "country_of_final_destination_place_of_supply": this.state.countryoffindes,
                  "port_of_loading": this.state.portofloading,
                  "ad_code": this.state.ad_code,
                  "pi_valid_to": this.state.validto
                }),
                p_prepi_details_item: JSON.stringify({ "items": this.state.add_get_size })

              }
            }).then((response) => {
              let res = response.data.message

              if (response.data.status == 'success') {
                axios({
                  method: "POST",
                  url: this.state.baseurl + 'submit_pre_order',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    order_mmyyyy: this.state.month_year,
                    customer_sap_code: this.state.customer_code,
                    order_no: this.state.order_number,
                    order_version: this.state.order_version,
                    emp_code: localStorage.getItem('empcode')
                  }
                }).then((response) => {

                  if (response.data.status == 'success') {
                    this.notify("Record Submit")
                  }

                });

                this.setState({
                  order_type: response.data.data, // you have it as this.data not response
                });
                var getres = ''
                for (var i = 0; i < res.length; i++) {
                  getres = res[i].fi01_prepi_order
                }
                var gtres = getres.substring(0, 1)
                if (gtres == "R") {
                  this.notify(getres)
                  // window.location.reload(true);
                } else {
                  // window.confirm(getres)
                }

                axios({
                  method: "POST",
                  url: this.state.baseurl + 'showorder_list',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    customer_sap_code: this.state.customer_code,
                    order_mmyyyy: this.state.month_year,
                  }
                }).then((response) => {
                  console.log("Order", response.data.data[0])
                  this.setState({
                    get_order_no: response.data.data,
                    order_number: response.data.data[0],
                  });
                  axios({
                    method: "POST",
                    url: this.state.baseurl + 'showversion_list',//"http://localhost:3300/custdetails",
                    headers: {
                      'Content-Type': 'application/json',
                      'token': localStorage.getItem('token')
                    },
                    data: {
                      customer_sap_code: this.state.customer_code,
                      order_mmyyyy: this.state.month_year,
                      order_no: response.data.data[0]
                    }
                  }).then((response) => {
                    console.log("version", response.data.data[0])
                    this.setState({
                      order_version: response.data.data[0],
                      get_order_verion: response.data.data,
                    });
                    axios({
                      method: "POST",
                      url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
                      headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                      },
                      data: {
                        customer_sap_code: this.state.customer_code,
                        order_mmyyyy: this.state.month_year,
                        order_no: this.state.order_number,
                        order_version: this.state.order_version
                      }
                    }).then((response) => {
                      console.log("custhdrs", response.data.status)

                      if (response.data.status == 'Success') {
                        var get_cu = response.data.hdrs
                        var get_tag = ''
                        for (var j = 0; j < get_cu.length; j++) {

                          this.state.curreny = get_cu[j].order_category
                          this.state.pay_term = get_cu[j].payment_term;
                          get_tag = get_cu[j].payment_term;
                          this.state.incotern = get_cu[j].inco_term;
                          this.state.port_delivery = get_cu[j].port_of_discharge;
                          this.state.customer_code = get_cu[j].customer_sap_code;
                          this.state.customer_id = get_cu[j].customer_id
                          this.state.order_type = get_cu[j].order_type
                          this.state.container_size = get_cu[j].container_type
                          this.state.po_number = get_cu[j].po_number
                          this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
                          this.state.pistatus = get_cu[j].pi_status
                          this.state.pinumber = get_cu[j].pi_number
                          this.state.container_size = get_cu[j].container_type
                          this.state.notes = get_cu[j].notes
                          this.state.priority_item = get_cu[j].priority_item
                          this.state.final_desc = get_cu[j].final_destination
                          this.state.displaycontainercapper = get_cu[j].container_capacity
                          this.state.get_tot = get_cu[j].total_order_qty
                          this.state.get_conta = get_cu[j].no_of_containers
                          this.state.action_status = get_cu[j].action_status
                          this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
                          this.state.portofdischarge = get_cu[j].port_of_discharge
                          this.state.shipement_type = get_cu[j].precarriage_by
                          this.state.remarkes = get_cu[j].remarks_quo
                          this.state.remarkes1 = get_cu[j].remarks_so
                          this.state.discount = get_cu[j].discount
                          this.state.freight = get_cu[j].freight
                          this.state.insurance = get_cu[j].insurance
                          this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                          this.state.incoternlocation = get_cu[j].inco_term_location
                          this.state.priority_item = get_cu[j].priority_item
                          this.state.notifyparty = get_cu[j].notify_party_info1
                          this.state.notifyinfo = get_cu[j].notify_party_info1
                          this.state.notifyinfo1 = get_cu[j].notify_party_info2
                          this.state.notifyinfo2 = get_cu[j].notify_party_info3
                          this.state.notifyinfo3 = get_cu[j].notify_party_info4
                          this.state.notifyinfo4 = get_cu[j].notify_party_info5
                          this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
                          this.state.filler_item = get_cu[j].filler_item
                          this.state.notifymastercode = get_cu[j].notifyparty_sap_code
                        }


                        if (localStorage.getItem('usertypecode') == 'CUST') {
                          axios({
                            method: "POST",
                            url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                            headers: {
                              'Content-Type': 'application/json',
                              'token': localStorage.getItem('token')
                            },
                            data: {
                              tag_value: get_tag,

                            }
                          }).then((response) => {
                            console.log("Test", response.data.data[0].tag_desc)
                            this.setState({
                              payment_term_code: response.data.data[0].tag_desc
                            })


                          });
                        } else {
                          this.state.pay_term = get_tag
                        }

                        console.log("Maniwish05 ", response.data.data)
                        const wishlist = response.data.data;
                        const getlist = []
                        let piqty = '0';
                        let pi_value = "0";
                        let price_date = this.state.price_date
                        let wi_prtype = ''
                        let wi_pcode = ''
                        let segment_desc = ""
                        let segment_code = ""
                        let product_group = ""
                        let product_desc = ""
                        let product_code = ""
                        let price = ""
                        let pi_qty = ""
                        let container_capacity_qty = ""
                        let container_capacity = ""
                        let container_capacity_per = ""
                        let product_type = ""
                        let parent_product_code = ""
                        let bom_no = ''
                        let id = ''

                        for (var w = 0; w < wishlist.length; w++) {

                          if (localStorage.getItem("segment") == 'EURO') {
                            price_date = wishlist[w].price_date
                          }

                          wi_prtype = wishlist[w].product_type
                          segment_desc = wishlist[w].segment_desc
                          segment_code = wishlist[w].segment_code
                          product_group = wishlist[w].product_group
                          product_desc = wishlist[w].product_desc
                          product_code = wishlist[w].product_code
                          price = wishlist[w].price
                          price_date = wishlist[w].price_date
                          pi_qty = wishlist[w].pi_qty
                          pi_value = wishlist[w].pi_value
                          container_capacity_qty = wishlist[w].container_capacity_qty
                          container_capacity = wishlist[w].container_capacity
                          container_capacity_per = wishlist[w].container_capacity_per
                          product_type = wishlist[w].product_type
                          parent_product_code = wishlist[w].parent_product_code
                          bom_no = wishlist[w].bom_no
                          id = wishlist[w].id

                          getlist.push({
                            segment_desc: segment_desc,
                            segment_code: segment_code,
                            product_group: product_group,
                            product_desc: product_desc,
                            product_code: product_code,
                            price: price,
                            price_date: price_date,
                            bom_no: bom_no,
                            bom_no1: "",
                            pi_qty: pi_qty,
                            pi_value: pi_value,
                            container_capacity_qty: container_capacity_qty,
                            container_capacity: container_capacity,
                            container_capacity_per: container_capacity_per,
                            product_type: product_type,
                            parent_product_code: parent_product_code,
                            id: id
                          })


                          if (wi_prtype == 'Set') {
                            wi_pcode = wishlist[w].product_code

                            axios({
                              method: "POST",
                              url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                              headers: {
                                'Content-Type': 'application/json',
                                'token': localStorage.getItem('token')
                              },
                              data: {
                                'product_code': wishlist[w].product_code,
                              }
                            }).then((response) => {
                              console.log("Manitest", response.data.data)
                              const getbomkit = []
                              let kibom = response.data.data;
                              var items = getlist
                              var kitbom = response.data.data
                              let item_pcode = ''
                              let kit_pcode = ''

                              for (var b = 0; b < kitbom.length; b++) {
                                getbomkit.push({
                                  // product_code :kitbom[b].product_code,
                                  alt_bom_no: kitbom[b].alt_bom_no
                                })
                              }
                              this.setState({
                                wish_kit_bom: getbomkit
                              })

                              for (var k = 0; k < kitbom.length; k++) {
                                kit_pcode = kitbom[k].product_code

                                for (var b = 0; b < items.length; b++) {
                                  item_pcode = items[b].product_code

                                  if (kit_pcode == item_pcode) {
                                    items[b].bom_no1 = response.data.data
                                    items[b].bom_no = response.data.data[0].alt_bom_no
                                  } else {

                                  }
                                }
                              }
                              this.setState({
                                add_get_size: getlist
                              })
                            });
                          } else {
                            console.log("Testmanicode", getlist)
                            this.setState({
                              add_get_size: getlist
                            })
                          }


                        }

                        this.setState({
                          // add_get_size: response.data.data,
                          get_cu_details: response.data.hdrs,
                          // you have it as this.data not response
                        });
                      } else {
                        var get_cu = response.data.hdrs

                        for (var j = 0; j < get_cu.length; j++) {

                          this.state.curreny = get_cu[j].order_category
                          this.state.pay_term = get_cu[j].payment_term;
                          this.state.incotern = get_cu[j].inco_term;
                          this.state.port_delivery = get_cu[j].port_of_discharge;
                          this.state.customer_code = get_cu[j].customer_sap_code;
                          this.state.customer_id = get_cu[j].customer_id
                          this.state.order_type = get_cu[j].order_type
                          this.state.container_size = get_cu[j].container_type
                          this.state.po_number = get_cu[j].po_number
                          this.state.po_date = get_cu[j].po_date
                          this.state.pistatus = ""
                          this.state.pinumber = ""
                          this.state.container_size = get_cu[j].container_type
                          this.state.notes = get_cu[j].notes
                          this.state.priority_item = get_cu[j].priority_item
                          this.state.final_desc = get_cu[j].final_destination
                          this.state.displaycontainercapper = get_cu[j].container_capacity
                          this.state.get_tot = get_cu[j].total_order_qty
                          this.state.get_conta = get_cu[j].no_of_containers
                          this.state.action_status = get_cu[j].action_status
                        }

                        console.log("Maniwish05 ", response.data.data)
                        const wishlist = response.data.data;
                        const getlist = []
                        let piqty = '0';
                        let pi_value = "0";
                        let price_date = this.state.price_date
                        let wi_prtype = ''
                        let wi_pcode = ''
                        let segment_desc = ""
                        let segment_code = ""
                        let product_group = ""
                        let product_desc = ""
                        let product_code = ""
                        let price = ""
                        let pi_qty = ""
                        let container_capacity_qty = ""
                        let container_capacity = ""
                        let container_capacity_per = ""
                        let product_type = ""
                        let parent_product_code = ""
                        let bom_no = ''
                        let id = ''

                        for (var w = 0; w < wishlist.length; w++) {

                          if (localStorage.getItem("segment") == 'EURO') {
                            price_date = wishlist[w].price_date
                          }

                          wi_prtype = wishlist[w].product_type
                          segment_desc = wishlist[w].segment_desc
                          segment_code = wishlist[w].segment_code
                          product_group = wishlist[w].product_group
                          product_desc = wishlist[w].product_desc
                          product_code = wishlist[w].product_code
                          price = wishlist[w].price
                          price_date = wishlist[w].price_date
                          pi_qty = wishlist[w].pi_qty
                          pi_value = wishlist[w].pi_value
                          container_capacity_qty = wishlist[w].container_capacity_qty
                          container_capacity = wishlist[w].container_capacity
                          container_capacity_per = wishlist[w].container_capacity_per
                          product_type = wishlist[w].product_type
                          parent_product_code = wishlist[w].parent_product_code
                          bom_no = wishlist[w].bom_no
                          id = wishlist[w].id

                          getlist.push({
                            segment_desc: segment_desc,
                            segment_code: segment_code,
                            product_group: product_group,
                            product_desc: product_desc,
                            product_code: product_code,
                            price: price,
                            price_date: price_date,
                            bom_no: bom_no,
                            bom_no1: "",
                            pi_qty: pi_qty,
                            pi_value: pi_value,
                            container_capacity_qty: container_capacity_qty,
                            container_capacity: container_capacity,
                            container_capacity_per: container_capacity_per,
                            product_type: product_type,
                            parent_product_code: parent_product_code,
                            id: id
                          })


                          if (wi_prtype == 'Set') {
                            wi_pcode = wishlist[w].product_code

                            axios({
                              method: "POST",
                              url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                              headers: {
                                'Content-Type': 'application/json',
                                'token': localStorage.getItem('token')
                              },
                              data: {
                                'product_code': wishlist[w].product_code,
                              }
                            }).then((response) => {
                              console.log("Manitest", response.data.data)
                              const getbomkit = []
                              let kibom = response.data.data;
                              var items = getlist
                              var kitbom = response.data.data
                              let item_pcode = ''
                              let kit_pcode = ''

                              for (var b = 0; b < kitbom.length; b++) {
                                getbomkit.push({
                                  // product_code :kitbom[b].product_code,
                                  alt_bom_no: kitbom[b].alt_bom_no
                                })
                              }
                              this.setState({
                                wish_kit_bom: getbomkit
                              })

                              for (var k = 0; k < kitbom.length; k++) {
                                kit_pcode = kitbom[k].product_code

                                for (var b = 0; b < items.length; b++) {
                                  item_pcode = items[b].product_code

                                  if (kit_pcode == item_pcode) {
                                    items[b].bom_no1 = response.data.data
                                    items[b].bom_no = response.data.data[0].alt_bom_no
                                  } else {

                                  }
                                }
                              }
                              this.setState({
                                add_get_size: getlist
                              })
                            });
                          } else {
                            console.log("Testmanicode", getlist)
                            this.setState({
                              add_get_size: getlist
                            })
                          }


                        }

                        this.setState({
                          //add_get_size: response.data.data,
                          get_cu_details: response.data.hdrs,
                          // you have it as this.data not response
                        });
                      }
                    });
                  });
                });
              } else {

              }


            });
          }
        }
      }
    } else {

      if (localStorage.getItem('usertypecode') == 'CUST' && localStorage.getItem('segment') == '2WEXPORT') {

        axios({
          method: "POST",
          url: this.state.baseurl + 'update_pre_order',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            p_prepi_details: JSON.stringify({
              "order_mmyyyy": this.state.month_year,
              "order_no": this.state.order_number,
              "order_version": this.state.order_version,
              "order_category": this.state.curreny.toUpperCase(),
              // "customer_id":this.state.customer_id,
              "customer_sap_code": custcode,
              "notifyparty_sap_code": this.state.notifymastercode,
              "po_number": this.state.po_number.toUpperCase(),
              "po_date": this.state.po_date,
              "container_type": this.state.container_size,
              "container_capacity": this.state.displaycontainercapper,
              "total_order_qty": this.state.get_tot,
              "no_of_containers": this.state.get_conta,
              "total_order_value": "0",
              "se_code": "0",
              "final_destination": this.state.final_desc.toUpperCase(),
              "port_of_discharge": this.state.port_delivery.toUpperCase(),
              "payment_term": this.state.pay_term.toUpperCase(),
              "inco_term": this.state.incotern.toUpperCase(),
              "order_type": this.state.order_type,
              "notes": this.state.notes.toUpperCase(),
              "filler_item": this.state.filler_item.toUpperCase(),
              "priority_item": this.state.priority_item.toUpperCase(),
              "pi_status": "submit",
              "created_at": "",
              "modified_at": "",
              "notify_party_info1": this.state.notifyinfo.toUpperCase(),
              "notify_party_info2": this.state.notifyinfo1.toUpperCase(),
              "notify_party_info3": this.state.notifyinfo2.toUpperCase(),
              "notify_party_info4": this.state.notifyinfo3.toUpperCase(),
              "notify_party_info5": this.state.notifyinfo4.toUpperCase(),
              "inco_term_location": this.state.incoternlocation.toUpperCase(),
              "freight": this.state.freight,
              "insurance": this.state.insurance,
              "discount": this.state.discount,
              "precarriage_by": this.state.shipement_type.toUpperCase(),
              "port_of_discharge": this.state.portofdischarge.toUpperCase(),
              // "commit_date_of_shipment": this.state.shipdate,
              "commit_date_of_shipment": shipdate,
              "country_of_final_destination_place_of_supply": this.state.countryoffindes.toUpperCase(),
              "port_of_loading": this.state.portofloading
            }),

            p_prepi_details_item: JSON.stringify({ "items": this.state.add_get_size })

          }
        }).then((response) => {

          if (response.data.status == 'success') {
            let res = response.data.message

            axios({
              method: "POST",
              url: this.state.baseurl + 'submit_pre_order',//"http://localhost:3300/custdetails",
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
              },
              data: {
                order_mmyyyy: this.state.month_year,
                customer_sap_code: this.state.customer_code,
                order_no: this.state.order_number,
                order_version: this.state.order_version,
              }
            }).then((response) => {
              let res = response.data.message

              if (response.data.status == 'success') {
                this.notify("Record Submited")
              }

            });

            this.setState({
              order_type: response.data.data, // you have it as this.data not response
            });
            var getres = ''
            for (var i = 0; i < res.length; i++) {
              getres = res[i].fi01_prepi_order
            }
            var gtres = getres.substring(0, 1)
            if (gtres == "R") {
              this.notify(getres)
              // window.location.reload(true);
            } else {
              // window.confirm(getres)
            }

            axios({
              method: "POST",
              url: this.state.baseurl + 'showorder_list',//"http://localhost:3300/custdetails",
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
              },
              data: {
                customer_sap_code: this.state.customer_code,
                order_mmyyyy: this.state.month_year,
              }
            }).then((response) => {
              console.log("Order", response.data.data[0])
              this.setState({
                get_order_no: response.data.data,
                order_number: response.data.data[0],
              });
              axios({
                method: "POST",
                url: this.state.baseurl + 'showversion_list',//"http://localhost:3300/custdetails",
                headers: {
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
                },
                data: {
                  customer_sap_code: this.state.customer_code,
                  order_mmyyyy: this.state.month_year,
                  order_no: response.data.data[0]
                }
              }).then((response) => {
                console.log("version", response.data.data[0])
                this.setState({
                  order_version: response.data.data[0],
                  get_order_verion: response.data.data,
                });
                axios({
                  method: "POST",
                  url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    customer_sap_code: this.state.customer_code,
                    order_mmyyyy: this.state.month_year,
                    order_no: this.state.order_number,
                    order_version: this.state.order_version
                  }
                }).then((response) => {
                  console.log("custhdrs", response.data.status)

                  if (response.data.status == 'Success') {
                    var get_cu = response.data.hdrs
                    var get_tag = ''
                    var get_shipdate = ''
                    const date = new Date();
                    const defaultValue = date.toLocaleDateString('en-CA');
                    for (var j = 0; j < get_cu.length; j++) {

                      this.state.curreny = get_cu[j].order_category
                      this.state.pay_term = get_cu[j].payment_term;
                      get_tag = get_cu[j].payment_term;
                      this.state.incotern = get_cu[j].inco_term;
                      this.state.port_delivery = get_cu[j].port_of_discharge;
                      this.state.customer_code = get_cu[j].customer_sap_code;
                      this.state.customer_id = get_cu[j].customer_id
                      this.state.order_type = get_cu[j].order_type
                      this.state.container_size = get_cu[j].container_type
                      this.state.po_number = get_cu[j].po_number
                      this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
                      this.state.pistatus = get_cu[j].pi_status
                      this.state.pinumber = get_cu[j].pi_number
                      this.state.container_size = get_cu[j].container_type
                      this.state.notes = get_cu[j].notes
                      this.state.priority_item = get_cu[j].priority_item
                      this.state.final_desc = get_cu[j].final_destination
                      this.state.displaycontainercapper = get_cu[j].container_capacity
                      this.state.get_tot = get_cu[j].total_order_qty
                      this.state.get_conta = get_cu[j].no_of_containers
                      this.state.action_status = get_cu[j].action_status
                      this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
                      this.state.portofdischarge = get_cu[j].port_of_discharge
                      this.state.shipement_type = get_cu[j].precarriage_by
                      this.state.remarkes = get_cu[j].remarks_quo
                      this.state.remarkes1 = get_cu[j].remarks_so
                      this.state.discount = get_cu[j].discount
                      this.state.freight = get_cu[j].freight
                      this.state.insurance = get_cu[j].insurance
                      //this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                      get_shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                      this.state.incoternlocation = get_cu[j].inco_term_location
                      this.state.priority_item = get_cu[j].priority_item
                      this.state.notifyparty = get_cu[j].notify_party_info1
                      this.state.notifyinfo = get_cu[j].notify_party_info1
                      this.state.notifyinfo1 = get_cu[j].notify_party_info2
                      this.state.notifyinfo2 = get_cu[j].notify_party_info3
                      this.state.notifyinfo3 = get_cu[j].notify_party_info4
                      this.state.notifyinfo4 = get_cu[j].notify_party_info5
                      this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
                      this.state.filler_item = get_cu[j].filler_item
                      this.state.notifymastercode = get_cu[j].notifyparty_sap_code
                    }

                    if (localStorage.getItem('segment') == 'OHT') {
                      this.state.shipdate = defaultValue
                    } else {
                      this.state.shipdate = get_shipdate
                    }

                    if (localStorage.getItem('usertypecode') == 'CUST') {
                      axios({
                        method: "POST",
                        url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                        headers: {
                          'Content-Type': 'application/json',
                          'token': localStorage.getItem('token')
                        },
                        data: {
                          tag_value: get_tag,

                        }
                      }).then((response) => {
                        console.log("Test", response.data.data[0].tag_desc)
                        this.setState({
                          payment_term_code: response.data.data[0].tag_desc
                        })


                      });
                    } else {
                      this.state.pay_term = get_tag
                    }

                    console.log("Maniwish05 ", response.data.data)
                    const wishlist = response.data.data;
                    const getlist = []
                    let piqty = '0';
                    let pi_value = "0";
                    let price_date = this.state.price_date
                    let wi_prtype = ''
                    let wi_pcode = ''
                    let segment_desc = ""
                    let segment_code = ""
                    let product_group = ""
                    let product_desc = ""
                    let product_code = ""
                    let price = ""
                    let pi_qty = ""
                    let container_capacity_qty = ""
                    let container_capacity = ""
                    let container_capacity_per = ""
                    let product_type = ""
                    let parent_product_code = ""
                    let bom_no = ''
                    let id = ''

                    for (var w = 0; w < wishlist.length; w++) {

                      if (localStorage.getItem("segment") == 'EURO') {
                        price_date = wishlist[w].price_date
                      }

                      wi_prtype = wishlist[w].product_type
                      segment_desc = wishlist[w].segment_desc
                      segment_code = wishlist[w].segment_code
                      product_group = wishlist[w].product_group
                      product_desc = wishlist[w].product_desc
                      product_code = wishlist[w].product_code
                      price = wishlist[w].price
                      price_date = wishlist[w].price_date
                      pi_qty = wishlist[w].pi_qty
                      pi_value = wishlist[w].pi_value
                      container_capacity_qty = wishlist[w].container_capacity_qty
                      container_capacity = wishlist[w].container_capacity
                      container_capacity_per = wishlist[w].container_capacity_per
                      product_type = wishlist[w].product_type
                      parent_product_code = wishlist[w].parent_product_code
                      bom_no = wishlist[w].bom_no
                      id = wishlist[w].id

                      getlist.push({
                        segment_desc: segment_desc,
                        segment_code: segment_code,
                        product_group: product_group,
                        product_desc: product_desc,
                        product_code: product_code,
                        price: price,
                        price_date: price_date,
                        bom_no: bom_no,
                        bom_no1: "",
                        pi_qty: pi_qty,
                        pi_value: pi_value,
                        container_capacity_qty: container_capacity_qty,
                        container_capacity: container_capacity,
                        container_capacity_per: container_capacity_per,
                        product_type: product_type,
                        parent_product_code: parent_product_code,
                        id: id
                      })


                      if (wi_prtype == 'Set') {
                        wi_pcode = wishlist[w].product_code

                        axios({
                          method: "POST",
                          url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                          headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('token')
                          },
                          data: {
                            'product_code': wishlist[w].product_code,
                          }
                        }).then((response) => {
                          console.log("Manitest", response.data.data)
                          const getbomkit = []
                          let kibom = response.data.data;
                          var items = getlist
                          var kitbom = response.data.data
                          let item_pcode = ''
                          let kit_pcode = ''

                          for (var b = 0; b < kitbom.length; b++) {
                            getbomkit.push({
                              // product_code :kitbom[b].product_code,
                              alt_bom_no: kitbom[b].alt_bom_no
                            })
                          }
                          this.setState({
                            wish_kit_bom: getbomkit
                          })

                          for (var k = 0; k < kitbom.length; k++) {
                            kit_pcode = kitbom[k].product_code

                            for (var b = 0; b < items.length; b++) {
                              item_pcode = items[b].product_code

                              if (kit_pcode == item_pcode) {
                                items[b].bom_no1 = response.data.data
                                items[b].bom_no = response.data.data[0].alt_bom_no
                              } else {

                              }
                            }
                          }
                          this.setState({
                            add_get_size: getlist
                          })
                        });
                      } else {
                        console.log("Testmanicode", getlist)
                        this.setState({
                          add_get_size: getlist
                        })
                      }


                    }

                    this.setState({
                      //  add_get_size: response.data.data,
                      get_cu_details: response.data.hdrs,
                      // you have it as this.data not response
                    });
                  } else {
                    var get_cu = response.data.hdrs
                    var get_tag = ''
                    for (var j = 0; j < get_cu.length; j++) {

                      this.state.curreny = get_cu[j].order_category
                      this.state.pay_term = get_cu[j].payment_term;
                      get_tag = get_cu[j].payment_term;
                      this.state.incotern = get_cu[j].inco_term;
                      this.state.port_delivery = get_cu[j].port_of_discharge;
                      this.state.customer_code = get_cu[j].customer_sap_code;
                      this.state.customer_id = get_cu[j].customer_id
                      this.state.order_type = get_cu[j].order_type
                      this.state.container_size = get_cu[j].container_type
                      this.state.po_number = get_cu[j].po_number
                      this.state.po_date = get_cu[j].po_date
                      this.state.pistatus = ""
                      this.state.pinumber = ""
                      this.state.container_size = get_cu[j].container_type
                      this.state.notes = get_cu[j].notes
                      this.state.priority_item = get_cu[j].priority_item
                      this.state.final_desc = get_cu[j].final_destination
                      this.state.displaycontainercapper = get_cu[j].container_capacity
                      this.state.get_tot = get_cu[j].total_order_qty
                      this.state.get_conta = get_cu[j].no_of_containers
                      this.state.action_status = get_cu[j].action_status
                    }

                    if (localStorage.getItem('usertypecode') == 'CUST') {
                      axios({
                        method: "POST",
                        url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                        headers: {
                          'Content-Type': 'application/json',
                          'token': localStorage.getItem('token')
                        },
                        data: {
                          tag_value: get_tag,

                        }
                      }).then((response) => {
                        console.log("Test", response.data.data[0].tag_desc)
                        this.setState({
                          payment_term_code: response.data.data[0].tag_desc
                        })


                      });
                    } else {
                      this.state.pay_term = get_tag
                    }

                    console.log("Maniwish05 ", response.data.data)
                    const wishlist = response.data.data;
                    const getlist = []
                    let piqty = '0';
                    let pi_value = "0";
                    let price_date = this.state.price_date
                    let wi_prtype = ''
                    let wi_pcode = ''
                    let segment_desc = ""
                    let segment_code = ""
                    let product_group = ""
                    let product_desc = ""
                    let product_code = ""
                    let price = ""
                    let pi_qty = ""
                    let container_capacity_qty = ""
                    let container_capacity = ""
                    let container_capacity_per = ""
                    let product_type = ""
                    let parent_product_code = ""
                    let bom_no = ''
                    let id = ''

                    for (var w = 0; w < wishlist.length; w++) {

                      if (localStorage.getItem("segment") == 'EURO') {
                        price_date = wishlist[w].price_date
                      }

                      wi_prtype = wishlist[w].product_type
                      segment_desc = wishlist[w].segment_desc
                      segment_code = wishlist[w].segment_code
                      product_group = wishlist[w].product_group
                      product_desc = wishlist[w].product_desc
                      product_code = wishlist[w].product_code
                      price = wishlist[w].price
                      price_date = wishlist[w].price_date
                      pi_qty = wishlist[w].pi_qty
                      pi_value = wishlist[w].pi_value
                      container_capacity_qty = wishlist[w].container_capacity_qty
                      container_capacity = wishlist[w].container_capacity
                      container_capacity_per = wishlist[w].container_capacity_per
                      product_type = wishlist[w].product_type
                      parent_product_code = wishlist[w].parent_product_code
                      bom_no = wishlist[w].bom_no
                      id = wishlist[w].id

                      getlist.push({
                        segment_desc: segment_desc,
                        segment_code: segment_code,
                        product_group: product_group,
                        product_desc: product_desc,
                        product_code: product_code,
                        price: price,
                        price_date: price_date,
                        bom_no: bom_no,
                        bom_no1: "",
                        pi_qty: pi_qty,
                        pi_value: pi_value,
                        container_capacity_qty: container_capacity_qty,
                        container_capacity: container_capacity,
                        container_capacity_per: container_capacity_per,
                        product_type: product_type,
                        parent_product_code: parent_product_code,
                        id: id
                      })


                      if (wi_prtype == 'Set') {
                        wi_pcode = wishlist[w].product_code

                        axios({
                          method: "POST",
                          url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                          headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('token')
                          },
                          data: {
                            'product_code': wishlist[w].product_code,
                          }
                        }).then((response) => {
                          console.log("Manitest", response.data.data)
                          const getbomkit = []
                          let kibom = response.data.data;
                          var items = getlist
                          var kitbom = response.data.data
                          let item_pcode = ''
                          let kit_pcode = ''

                          for (var b = 0; b < kitbom.length; b++) {
                            getbomkit.push({
                              // product_code :kitbom[b].product_code,
                              alt_bom_no: kitbom[b].alt_bom_no
                            })
                          }
                          this.setState({
                            wish_kit_bom: getbomkit
                          })

                          for (var k = 0; k < kitbom.length; k++) {
                            kit_pcode = kitbom[k].product_code

                            for (var b = 0; b < items.length; b++) {
                              item_pcode = items[b].product_code

                              if (kit_pcode == item_pcode) {
                                items[b].bom_no1 = response.data.data
                                items[b].bom_no = response.data.data[0].alt_bom_no
                              } else {

                              }
                            }
                          }
                          this.setState({
                            add_get_size: getlist
                          })
                        });
                      } else {
                        console.log("Testmanicode", getlist)
                        this.setState({
                          add_get_size: getlist
                        })
                      }


                    }
                    this.setState({
                      //  add_get_size: response.data.data,
                      get_cu_details: response.data.hdrs,
                      // you have it as this.data not response
                    });
                  }
                });
              });
            });
          } else {

          }

        });
        alert("Price valid for the shipment made on or before" + '"' + moment(lastDay).format('DD') + 'th"' + month)
      } else {
        axios({
          method: "POST",
          url: this.state.baseurl + 'update_pre_order',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            p_prepi_details: JSON.stringify({
              "order_mmyyyy": this.state.month_year,
              "order_no": this.state.order_number,
              "order_version": this.state.order_version,
              "order_category": this.state.curreny.toUpperCase(),
              // "customer_id":this.state.customer_id,
              "customer_sap_code": custcode,
              "notifyparty_sap_code": this.state.notifymastercode,
              "po_number": this.state.po_number.toUpperCase(),
              "po_date": this.state.po_date,
              "container_type": this.state.container_size,
              "container_capacity": this.state.displaycontainercapper,
              "total_order_qty": this.state.get_tot,
              "no_of_containers": this.state.get_conta,
              "total_order_value": "0",
              "se_code": "0",
              "final_destination": this.state.final_desc.toUpperCase(),
              "port_of_discharge": this.state.port_delivery.toUpperCase(),
              "payment_term": this.state.pay_term.toUpperCase(),
              "inco_term": this.state.incotern.toUpperCase(),
              "order_type": this.state.order_type,
              "notes": this.state.notes.toUpperCase(),
              "filler_item": this.state.filler_item.toUpperCase(),
              "priority_item": this.state.priority_item.toUpperCase(),
              "pi_status": "submit",
              "created_at": "",
              "modified_at": "",
              "notify_party_info1": this.state.notifyinfo.toUpperCase(),
              "notify_party_info2": this.state.notifyinfo1.toUpperCase(),
              "notify_party_info3": this.state.notifyinfo2.toUpperCase(),
              "notify_party_info4": this.state.notifyinfo3.toUpperCase(),
              "notify_party_info5": this.state.notifyinfo4.toUpperCase(),
              "inco_term_location": this.state.incoternlocation.toUpperCase(),
              "freight": this.state.freight,
              "insurance": this.state.insurance,
              "discount": this.state.discount,
              "precarriage_by": this.state.shipement_type.toUpperCase(),
              "port_of_discharge": this.state.portofdischarge.toUpperCase(),
              // "commit_date_of_shipment": this.state.shipdate,
              "commit_date_of_shipment": shipdate,
              "country_of_final_destination_place_of_supply": this.state.countryoffindes.toUpperCase(),
              "port_of_loading": this.state.portofloading
            }),

            p_prepi_details_item: JSON.stringify({ "items": this.state.add_get_size })

          }
        }).then((response) => {

          if (response.data.status == 'success') {
            let res = response.data.message

            axios({
              method: "POST",
              url: this.state.baseurl + 'submit_pre_order',//"http://localhost:3300/custdetails",
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
              },
              data: {
                order_mmyyyy: this.state.month_year,
                customer_sap_code: this.state.customer_code,
                order_no: this.state.order_number,
                order_version: this.state.order_version,
              }
            }).then((response) => {
              let res = response.data.message

              if (response.data.status == 'success') {
                this.notify("Record Submited")
              }

            });

            this.setState({
              order_type: response.data.data, // you have it as this.data not response
            });
            var getres = ''
            for (var i = 0; i < res.length; i++) {
              getres = res[i].fi01_prepi_order
            }
            var gtres = getres.substring(0, 1)
            if (gtres == "R") {
              this.notify(getres)
              // window.location.reload(true);
            } else {
              // window.confirm(getres)
            }

            axios({
              method: "POST",
              url: this.state.baseurl + 'showorder_list',//"http://localhost:3300/custdetails",
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
              },
              data: {
                customer_sap_code: this.state.customer_code,
                order_mmyyyy: this.state.month_year,
              }
            }).then((response) => {
              console.log("Order", response.data.data[0])
              this.setState({
                get_order_no: response.data.data,
                order_number: response.data.data[0],
              });
              axios({
                method: "POST",
                url: this.state.baseurl + 'showversion_list',//"http://localhost:3300/custdetails",
                headers: {
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
                },
                data: {
                  customer_sap_code: this.state.customer_code,
                  order_mmyyyy: this.state.month_year,
                  order_no: response.data.data[0]
                }
              }).then((response) => {
                console.log("version", response.data.data[0])
                this.setState({
                  order_version: response.data.data[0],
                  get_order_verion: response.data.data,
                });
                axios({
                  method: "POST",
                  url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    customer_sap_code: this.state.customer_code,
                    order_mmyyyy: this.state.month_year,
                    order_no: this.state.order_number,
                    order_version: this.state.order_version
                  }
                }).then((response) => {
                  console.log("custhdrs", response.data.status)

                  if (response.data.status == 'Success') {
                    var get_cu = response.data.hdrs
                    var get_tag = ''
                    var get_shipdate = ''
                    const date = new Date();
                    const defaultValue = date.toLocaleDateString('en-CA');
                    for (var j = 0; j < get_cu.length; j++) {

                      this.state.curreny = get_cu[j].order_category
                      this.state.pay_term = get_cu[j].payment_term;
                      get_tag = get_cu[j].payment_term;
                      this.state.incotern = get_cu[j].inco_term;
                      this.state.port_delivery = get_cu[j].port_of_discharge;
                      this.state.customer_code = get_cu[j].customer_sap_code;
                      this.state.customer_id = get_cu[j].customer_id
                      this.state.order_type = get_cu[j].order_type
                      this.state.container_size = get_cu[j].container_type
                      this.state.po_number = get_cu[j].po_number
                      this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
                      this.state.pistatus = get_cu[j].pi_status
                      this.state.pinumber = get_cu[j].pi_number
                      this.state.container_size = get_cu[j].container_type
                      this.state.notes = get_cu[j].notes
                      this.state.priority_item = get_cu[j].priority_item
                      this.state.final_desc = get_cu[j].final_destination
                      this.state.displaycontainercapper = get_cu[j].container_capacity
                      this.state.get_tot = get_cu[j].total_order_qty
                      this.state.get_conta = get_cu[j].no_of_containers
                      this.state.action_status = get_cu[j].action_status
                      this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
                      this.state.portofdischarge = get_cu[j].port_of_discharge
                      this.state.shipement_type = get_cu[j].precarriage_by
                      this.state.remarkes = get_cu[j].remarks_quo
                      this.state.remarkes1 = get_cu[j].remarks_so
                      this.state.discount = get_cu[j].discount
                      this.state.freight = get_cu[j].freight
                      this.state.insurance = get_cu[j].insurance
                      //this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                      get_shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                      this.state.incoternlocation = get_cu[j].inco_term_location
                      this.state.priority_item = get_cu[j].priority_item
                      this.state.notifyparty = get_cu[j].notify_party_info1
                      this.state.notifyinfo = get_cu[j].notify_party_info1
                      this.state.notifyinfo1 = get_cu[j].notify_party_info2
                      this.state.notifyinfo2 = get_cu[j].notify_party_info3
                      this.state.notifyinfo3 = get_cu[j].notify_party_info4
                      this.state.notifyinfo4 = get_cu[j].notify_party_info5
                      this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
                      this.state.filler_item = get_cu[j].filler_item
                      this.state.notifymastercode = get_cu[j].notifyparty_sap_code
                    }

                    if (localStorage.getItem('segment') == 'OHT') {
                      this.state.shipdate = defaultValue
                    } else {
                      this.state.shipdate = get_shipdate
                    }

                    if (localStorage.getItem('usertypecode') == 'CUST') {
                      axios({
                        method: "POST",
                        url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                        headers: {
                          'Content-Type': 'application/json',
                          'token': localStorage.getItem('token')
                        },
                        data: {
                          tag_value: get_tag,

                        }
                      }).then((response) => {
                        console.log("Test", response.data.data[0].tag_desc)
                        this.setState({
                          payment_term_code: response.data.data[0].tag_desc
                        })


                      });
                    } else {
                      this.state.pay_term = get_tag
                    }

                    console.log("Maniwish05 ", response.data.data)
                    const wishlist = response.data.data;
                    const getlist = []
                    let piqty = '0';
                    let pi_value = "0";
                    let price_date = this.state.price_date
                    let wi_prtype = ''
                    let wi_pcode = ''
                    let segment_desc = ""
                    let segment_code = ""
                    let product_group = ""
                    let product_desc = ""
                    let product_code = ""
                    let price = ""
                    let pi_qty = ""
                    let container_capacity_qty = ""
                    let container_capacity = ""
                    let container_capacity_per = ""
                    let product_type = ""
                    let parent_product_code = ""
                    let bom_no = ''
                    let id = ''

                    for (var w = 0; w < wishlist.length; w++) {

                      if (localStorage.getItem("segment") == 'EURO') {
                        price_date = wishlist[w].price_date
                      }

                      wi_prtype = wishlist[w].product_type
                      segment_desc = wishlist[w].segment_desc
                      segment_code = wishlist[w].segment_code
                      product_group = wishlist[w].product_group
                      product_desc = wishlist[w].product_desc
                      product_code = wishlist[w].product_code
                      price = wishlist[w].price
                      price_date = wishlist[w].price_date
                      pi_qty = wishlist[w].pi_qty
                      pi_value = wishlist[w].pi_value
                      container_capacity_qty = wishlist[w].container_capacity_qty
                      container_capacity = wishlist[w].container_capacity
                      container_capacity_per = wishlist[w].container_capacity_per
                      product_type = wishlist[w].product_type
                      parent_product_code = wishlist[w].parent_product_code
                      bom_no = wishlist[w].bom_no
                      id = wishlist[w].id

                      getlist.push({
                        segment_desc: segment_desc,
                        segment_code: segment_code,
                        product_group: product_group,
                        product_desc: product_desc,
                        product_code: product_code,
                        price: price,
                        price_date: price_date,
                        bom_no: bom_no,
                        bom_no1: "",
                        pi_qty: pi_qty,
                        pi_value: pi_value,
                        container_capacity_qty: container_capacity_qty,
                        container_capacity: container_capacity,
                        container_capacity_per: container_capacity_per,
                        product_type: product_type,
                        parent_product_code: parent_product_code,
                        id: id
                      })


                      if (wi_prtype == 'Set') {
                        wi_pcode = wishlist[w].product_code

                        axios({
                          method: "POST",
                          url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                          headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('token')
                          },
                          data: {
                            'product_code': wishlist[w].product_code,
                          }
                        }).then((response) => {
                          console.log("Manitest", response.data.data)
                          const getbomkit = []
                          let kibom = response.data.data;
                          var items = getlist
                          var kitbom = response.data.data
                          let item_pcode = ''
                          let kit_pcode = ''

                          for (var b = 0; b < kitbom.length; b++) {
                            getbomkit.push({
                              // product_code :kitbom[b].product_code,
                              alt_bom_no: kitbom[b].alt_bom_no
                            })
                          }
                          this.setState({
                            wish_kit_bom: getbomkit
                          })

                          for (var k = 0; k < kitbom.length; k++) {
                            kit_pcode = kitbom[k].product_code

                            for (var b = 0; b < items.length; b++) {
                              item_pcode = items[b].product_code

                              if (kit_pcode == item_pcode) {
                                items[b].bom_no1 = response.data.data
                                items[b].bom_no = response.data.data[0].alt_bom_no
                              } else {

                              }
                            }
                          }
                          this.setState({
                            add_get_size: getlist
                          })
                        });
                      } else {
                        console.log("Testmanicode", getlist)
                        this.setState({
                          add_get_size: getlist
                        })
                      }


                    }

                    this.setState({
                      //  add_get_size: response.data.data,
                      get_cu_details: response.data.hdrs,
                      // you have it as this.data not response
                    });
                  } else {
                    var get_cu = response.data.hdrs
                    var get_tag = ''
                    for (var j = 0; j < get_cu.length; j++) {

                      this.state.curreny = get_cu[j].order_category
                      this.state.pay_term = get_cu[j].payment_term;
                      get_tag = get_cu[j].payment_term;
                      this.state.incotern = get_cu[j].inco_term;
                      this.state.port_delivery = get_cu[j].port_of_discharge;
                      this.state.customer_code = get_cu[j].customer_sap_code;
                      this.state.customer_id = get_cu[j].customer_id
                      this.state.order_type = get_cu[j].order_type
                      this.state.container_size = get_cu[j].container_type
                      this.state.po_number = get_cu[j].po_number
                      this.state.po_date = get_cu[j].po_date
                      this.state.pistatus = ""
                      this.state.pinumber = ""
                      this.state.container_size = get_cu[j].container_type
                      this.state.notes = get_cu[j].notes
                      this.state.priority_item = get_cu[j].priority_item
                      this.state.final_desc = get_cu[j].final_destination
                      this.state.displaycontainercapper = get_cu[j].container_capacity
                      this.state.get_tot = get_cu[j].total_order_qty
                      this.state.get_conta = get_cu[j].no_of_containers
                      this.state.action_status = get_cu[j].action_status
                    }

                    if (localStorage.getItem('usertypecode') == 'CUST') {
                      axios({
                        method: "POST",
                        url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                        headers: {
                          'Content-Type': 'application/json',
                          'token': localStorage.getItem('token')
                        },
                        data: {
                          tag_value: get_tag,

                        }
                      }).then((response) => {
                        console.log("Test", response.data.data[0].tag_desc)
                        this.setState({
                          payment_term_code: response.data.data[0].tag_desc
                        })


                      });
                    } else {
                      this.state.pay_term = get_tag
                    }

                    console.log("Maniwish05 ", response.data.data)
                    const wishlist = response.data.data;
                    const getlist = []
                    let piqty = '0';
                    let pi_value = "0";
                    let price_date = this.state.price_date
                    let wi_prtype = ''
                    let wi_pcode = ''
                    let segment_desc = ""
                    let segment_code = ""
                    let product_group = ""
                    let product_desc = ""
                    let product_code = ""
                    let price = ""
                    let pi_qty = ""
                    let container_capacity_qty = ""
                    let container_capacity = ""
                    let container_capacity_per = ""
                    let product_type = ""
                    let parent_product_code = ""
                    let bom_no = ''
                    let id = ''

                    for (var w = 0; w < wishlist.length; w++) {

                      if (localStorage.getItem("segment") == 'EURO') {
                        price_date = wishlist[w].price_date
                      }

                      wi_prtype = wishlist[w].product_type
                      segment_desc = wishlist[w].segment_desc
                      segment_code = wishlist[w].segment_code
                      product_group = wishlist[w].product_group
                      product_desc = wishlist[w].product_desc
                      product_code = wishlist[w].product_code
                      price = wishlist[w].price
                      price_date = wishlist[w].price_date
                      pi_qty = wishlist[w].pi_qty
                      pi_value = wishlist[w].pi_value
                      container_capacity_qty = wishlist[w].container_capacity_qty
                      container_capacity = wishlist[w].container_capacity
                      container_capacity_per = wishlist[w].container_capacity_per
                      product_type = wishlist[w].product_type
                      parent_product_code = wishlist[w].parent_product_code
                      bom_no = wishlist[w].bom_no
                      id = wishlist[w].id

                      getlist.push({
                        segment_desc: segment_desc,
                        segment_code: segment_code,
                        product_group: product_group,
                        product_desc: product_desc,
                        product_code: product_code,
                        price: price,
                        price_date: price_date,
                        bom_no: bom_no,
                        bom_no1: "",
                        pi_qty: pi_qty,
                        pi_value: pi_value,
                        container_capacity_qty: container_capacity_qty,
                        container_capacity: container_capacity,
                        container_capacity_per: container_capacity_per,
                        product_type: product_type,
                        parent_product_code: parent_product_code,
                        id: id
                      })


                      if (wi_prtype == 'Set') {
                        wi_pcode = wishlist[w].product_code

                        axios({
                          method: "POST",
                          url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                          headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('token')
                          },
                          data: {
                            'product_code': wishlist[w].product_code,
                          }
                        }).then((response) => {
                          console.log("Manitest", response.data.data)
                          const getbomkit = []
                          let kibom = response.data.data;
                          var items = getlist
                          var kitbom = response.data.data
                          let item_pcode = ''
                          let kit_pcode = ''

                          for (var b = 0; b < kitbom.length; b++) {
                            getbomkit.push({
                              // product_code :kitbom[b].product_code,
                              alt_bom_no: kitbom[b].alt_bom_no
                            })
                          }
                          this.setState({
                            wish_kit_bom: getbomkit
                          })

                          for (var k = 0; k < kitbom.length; k++) {
                            kit_pcode = kitbom[k].product_code

                            for (var b = 0; b < items.length; b++) {
                              item_pcode = items[b].product_code

                              if (kit_pcode == item_pcode) {
                                items[b].bom_no1 = response.data.data
                                items[b].bom_no = response.data.data[0].alt_bom_no
                              } else {

                              }
                            }
                          }
                          this.setState({
                            add_get_size: getlist
                          })
                        });
                      } else {
                        console.log("Testmanicode", getlist)
                        this.setState({
                          add_get_size: getlist
                        })
                      }


                    }
                    this.setState({
                      //  add_get_size: response.data.data,
                      get_cu_details: response.data.hdrs,
                      // you have it as this.data not response
                    });
                  }
                });
              });
            });
          } else {

          }

        });
      }

    }

  }


  pisubmitLists = (event) => {
    event.preventDefault();

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }

    var shipdate = ""
    if (localStorage.getItem('segment') == 'OHT') {
      shipdate = ""
    } else {
      shipdate = this.state.shipdate
    }
    if (this.state.pinumber != null) {


      const insertdate = JSON.stringify({
        "order_mmyyyy": this.state.month_year,
        "order_no": this.state.order_number,
        "order_version": this.state.order_version,
        "order_category": this.state.curreny,
        // "customer_id":this.state.customer_id,
        "customer_sap_code": custcode,
        "notifyparty_sap_code": this.state.notifymastercode,
        "po_number": this.state.po_number,
        "po_date": this.state.po_date,
        "container_type": this.state.container_size,
        "container_capacity": this.state.displaycontainercapper,
        "total_order_qty": this.state.get_tot,
        "no_of_containers": this.state.get_conta,
        "total_order_value": "0",
        "se_code": "0",
        "final_destination": this.state.final_desc,
        "port_of_discharge": this.state.port_delivery,
        "payment_term": this.state.pay_term,
        "inco_term": this.state.incotern,
        "order_type": this.state.order_type,
        "notes": this.state.notes,
        "filler_item": this.state.filler_item,
        "priority_item": this.state.priority_item,
        "pi_status": "piconfirm",
        "created_at": "",
        "modified_at": "",
        "notify_party_info1": this.state.notifyinfo,
        "notify_party_info2": this.state.notifyinfo1,
        "notify_party_info3": this.state.notifyinfo2,
        "notify_party_info4": this.state.notifyinfo3,
        "notify_party_info5": this.state.notifyinfo4,
        "inco_term_location": this.state.incoternlocation,
        "freight": this.state.freight,
        "insurance": this.state.insurance,
        "discount": this.state.discount,
        "precarriage_by": this.state.shipement_type,
        "port_of_discharge": this.state.portofdischarge,
        "commit_date_of_shipment": this.state.shipdate,
        "country_of_final_destination_place_of_supply": this.state.countryoffindes

      })

      console.log("Header", insertdate)

      console.log("details ", this.state.add_get_size)
      axios({
        method: "POST",
        url: this.state.baseurl + 'update_pre_order',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          p_prepi_details: JSON.stringify({
            "order_mmyyyy": this.state.month_year,
            "order_no": this.state.order_number,
            "order_version": this.state.order_version,
            "order_category": this.state.curreny.toUpperCase(),
            // "customer_id":this.state.customer_id,
            "customer_sap_code": custcode,
            "notifyparty_sap_code": this.state.notifymastercode,
            "po_number": this.state.po_number.toUpperCase(),
            "po_date": this.state.po_date,
            "container_type": this.state.container_size,
            "container_capacity": this.state.displaycontainercapper,
            "total_order_qty": this.state.get_tot,
            "no_of_containers": this.state.get_conta,
            "total_order_value": "0",
            "se_code": "0",
            "final_destination": this.state.final_desc.toUpperCase(),
            "port_of_discharge": this.state.port_delivery.toUpperCase(),
            "payment_term": this.state.pay_term.toUpperCase(),
            "inco_term": this.state.incotern.toUpperCase(),
            "order_type": this.state.order_type,
            "notes": this.state.notes.toUpperCase(),
            "filler_item": this.state.filler_item.toUpperCase(),
            "priority_item": this.state.priority_item.toUpperCase(),
            "pi_status": "piconfirm",
            "created_at": "",
            "modified_at": "",
            "notify_party_info1": this.state.notifyinfo.toUpperCase(),
            "notify_party_info2": this.state.notifyinfo1.toUpperCase(),
            "notify_party_info3": this.state.notifyinfo2.toUpperCase(),
            "notify_party_info4": this.state.notifyinfo3.toUpperCase(),
            "notify_party_info5": this.state.notifyinfo4.toUpperCase(),
            "inco_term_location": this.state.incoternlocation.toUpperCase(),
            "freight": this.state.freight,
            "insurance": this.state.insurance,
            "discount": this.state.discount,
            "precarriage_by": this.state.shipement_type.toUpperCase(),
            "port_of_discharge": this.state.portofdischarge.toUpperCase(),
            //"commit_date_of_shipment": this.state.shipdate,
            "commit_date_of_shipment": shipdate,
            "country_of_final_destination_place_of_supply": this.state.countryoffindes.toUpperCase(),
            "port_of_loading": this.state.portofloading,
            "ad_code": this.state.ad_code,
            "pi_valid_to": this.state.validto
          }),

          p_prepi_details_item: JSON.stringify({ "items": this.state.add_get_size })

        }
      }).then((response) => {

        if (response.data.status == 'success') {
          let res = response.data.message
          this.setState({
            order_type: response.data.data, // you have it as this.data not response
          });
          var getres = ''
          for (var i = 0; i < res.length; i++) {
            getres = res[i].fi01_prepi_order
          }
          var gtres = getres.substring(0, 1)
          if (gtres == "R") {
            // this.notify(getres)
            window.location.reload(true);
          } else {
            // window.confirm(getres)
          }

          axios({
            method: "POST",
            url: this.state.baseurl + 'submit_piconfirm_order',//"http://localhost:3300/custdetails",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
            },

            data: {
              customer_sap_code: this.state.customer_code,
              order_no: this.state.order_number,
              order_version: this.state.order_version
            }
          }).then((response) => {

            if (response.data.status == 'success') {
              this.notify("Record Completed")
              window.location.reload(true);
            }
          });


          axios({
            method: "POST",
            url: this.state.baseurl + 'showorder_list',//"http://localhost:3300/custdetails",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
            },
            data: {
              customer_sap_code: this.state.customer_code,
              order_mmyyyy: this.state.month_year,
            }
          }).then((response) => {
            console.log("Order", response.data.data[0])
            this.setState({
              get_order_no: response.data.data,
              order_number: response.data.data[0],
            });
            axios({
              method: "POST",
              url: this.state.baseurl + 'showversion_list',//"http://localhost:3300/custdetails",
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
              },
              data: {
                customer_sap_code: this.state.customer_code,
                order_mmyyyy: this.state.month_year,
                order_no: response.data.data[0]
              }
            }).then((response) => {
              console.log("version", response.data.data[0])
              this.setState({
                order_version: response.data.data[0],
                get_order_verion: response.data.data,
              });
              axios({
                method: "POST",
                url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
                headers: {
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
                },
                data: {
                  customer_sap_code: this.state.customer_code,
                  order_mmyyyy: this.state.month_year,
                  order_no: this.state.order_number,
                  order_version: this.state.order_version
                }
              }).then((response) => {
                console.log("custhdrs", response.data.status)

                if (response.data.status == 'Success') {
                  var get_cu = response.data.hdrs
                  var get_tag = ''
                  var get_shipdate = ''

                  const date = new Date();
                  const defaultValue = date.toLocaleDateString('en-CA');
                  for (var j = 0; j < get_cu.length; j++) {

                    this.state.curreny = get_cu[j].order_category
                    this.state.pay_term = get_cu[j].payment_term;
                    get_tag = get_cu[j].payment_term;
                    this.state.incotern = get_cu[j].inco_term;
                    this.state.port_delivery = get_cu[j].port_of_discharge;
                    this.state.customer_code = get_cu[j].customer_sap_code;
                    this.state.customer_id = get_cu[j].customer_id
                    this.state.order_type = get_cu[j].order_type
                    this.state.container_size = get_cu[j].container_type
                    this.state.po_number = get_cu[j].po_number
                    this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
                    this.state.pistatus = get_cu[j].pi_status
                    this.state.pinumber = get_cu[j].pi_number
                    this.state.container_size = get_cu[j].container_type
                    this.state.notes = get_cu[j].notes
                    this.state.priority_item = get_cu[j].priority_item
                    this.state.final_desc = get_cu[j].final_destination
                    this.state.displaycontainercapper = get_cu[j].container_capacity
                    this.state.get_tot = get_cu[j].total_order_qty
                    this.state.get_conta = get_cu[j].no_of_containers
                    this.state.action_status = get_cu[j].action_status
                    this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
                    this.state.portofdischarge = get_cu[j].port_of_discharge
                    this.state.shipement_type = get_cu[j].precarriage_by
                    this.state.remarkes = get_cu[j].remarks_quo
                    this.state.remarkes1 = get_cu[j].remarks_so
                    this.state.discount = get_cu[j].discount
                    this.state.insurance = get_cu[j].insurance
                    this.state.freight = get_cu[j].freight
                    //this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                    get_shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
                    this.state.incoternlocation = get_cu[j].inco_term_location
                    this.state.priority_item = get_cu[j].priority_item
                    this.state.notifyparty = get_cu[j].notify_party_info1
                    this.state.notifyinfo = get_cu[j].notify_party_info1
                    this.state.notifyinfo1 = get_cu[j].notify_party_info2
                    this.state.notifyinfo2 = get_cu[j].notify_party_info3
                    this.state.notifyinfo3 = get_cu[j].notify_party_info4
                    this.state.notifyinfo4 = get_cu[j].notify_party_info5
                    this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
                    this.state.filler_item = get_cu[j].filler_item
                    this.state.notifymastercode = get_cu[j].notifyparty_sap_code
                  }
                  if (localStorage.getItem('segment') == 'OHT') {
                    this.state.shipdate = defaultValue
                  } else {
                    this.state.shipdate = get_shipdate
                  }
                  if (localStorage.getItem('usertypecode') == 'CUST') {
                    axios({
                      method: "POST",
                      url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                      headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                      },
                      data: {
                        tag_value: get_tag,

                      }
                    }).then((response) => {
                      console.log("Test", response.data.data[0].tag_desc)
                      this.setState({
                        payment_term_code: response.data.data[0].tag_desc
                      })


                    });
                  } else {
                    this.state.pay_term = get_tag
                  }


                  console.log("Maniwish05 ", response.data.data)
                  const wishlist = response.data.data;
                  const getlist = []
                  let piqty = '0';
                  let pi_value = "0";
                  let price_date = this.state.price_date
                  let wi_prtype = ''
                  let wi_pcode = ''
                  let segment_desc = ""
                  let segment_code = ""
                  let product_group = ""
                  let product_desc = ""
                  let product_code = ""
                  let price = ""
                  let pi_qty = ""
                  let container_capacity_qty = ""
                  let container_capacity = ""
                  let container_capacity_per = ""
                  let product_type = ""
                  let parent_product_code = ""
                  let bom_no = ''
                  let id = ''

                  for (var w = 0; w < wishlist.length; w++) {

                    if (localStorage.getItem("segment") == 'EURO') {
                      price_date = wishlist[w].price_date
                    }

                    wi_prtype = wishlist[w].product_type
                    segment_desc = wishlist[w].segment_desc
                    segment_code = wishlist[w].segment_code
                    product_group = wishlist[w].product_group
                    product_desc = wishlist[w].product_desc
                    product_code = wishlist[w].product_code
                    price = wishlist[w].price
                    price_date = wishlist[w].price_date
                    pi_qty = wishlist[w].pi_qty
                    pi_value = wishlist[w].pi_value
                    container_capacity_qty = wishlist[w].container_capacity_qty
                    container_capacity = wishlist[w].container_capacity
                    container_capacity_per = wishlist[w].container_capacity_per
                    product_type = wishlist[w].product_type
                    parent_product_code = wishlist[w].parent_product_code
                    bom_no = wishlist[w].bom_no
                    id = wishlist[w].id

                    getlist.push({
                      segment_desc: segment_desc,
                      segment_code: segment_code,
                      product_group: product_group,
                      product_desc: product_desc,
                      product_code: product_code,
                      price: price,
                      price_date: price_date,
                      bom_no: bom_no,
                      bom_no1: "",
                      pi_qty: pi_qty,
                      pi_value: pi_value,
                      container_capacity_qty: container_capacity_qty,
                      container_capacity: container_capacity,
                      container_capacity_per: container_capacity_per,
                      product_type: product_type,
                      parent_product_code: parent_product_code,
                      id: id
                    })


                    if (wi_prtype == 'Set') {
                      wi_pcode = wishlist[w].product_code

                      axios({
                        method: "POST",
                        url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                        headers: {
                          'Content-Type': 'application/json',
                          'token': localStorage.getItem('token')
                        },
                        data: {
                          'product_code': wishlist[w].product_code,
                        }
                      }).then((response) => {
                        console.log("Manitest", response.data.data)
                        const getbomkit = []
                        let kibom = response.data.data;
                        var items = getlist
                        var kitbom = response.data.data
                        let item_pcode = ''
                        let kit_pcode = ''

                        for (var b = 0; b < kitbom.length; b++) {
                          getbomkit.push({
                            // product_code :kitbom[b].product_code,
                            alt_bom_no: kitbom[b].alt_bom_no
                          })
                        }
                        this.setState({
                          wish_kit_bom: getbomkit
                        })

                        for (var k = 0; k < kitbom.length; k++) {
                          kit_pcode = kitbom[k].product_code

                          for (var b = 0; b < items.length; b++) {
                            item_pcode = items[b].product_code

                            if (kit_pcode == item_pcode) {
                              items[b].bom_no1 = response.data.data
                              items[b].bom_no = response.data.data[0].alt_bom_no
                            } else {

                            }
                          }
                        }
                        this.setState({
                          add_get_size: getlist
                        })
                      });
                    } else {
                      console.log("Testmanicode", getlist)
                      this.setState({
                        add_get_size: getlist
                      })
                    }


                  }

                  this.setState({
                    //add_get_size: response.data.data,
                    get_cu_details: response.data.hdrs,
                    // you have it as this.data not response
                  });
                } else {
                  var get_cu = response.data.hdrs
                  var get_tag = ''
                  for (var j = 0; j < get_cu.length; j++) {

                    this.state.curreny = get_cu[j].order_category
                    this.state.pay_term = get_cu[j].payment_term;
                    get_tag = get_cu[j].payment_term;
                    this.state.incotern = get_cu[j].inco_term;
                    this.state.port_delivery = get_cu[j].port_of_discharge;
                    this.state.customer_code = get_cu[j].customer_sap_code;
                    this.state.customer_id = get_cu[j].customer_id
                    this.state.order_type = get_cu[j].order_type
                    this.state.container_size = get_cu[j].container_type
                    this.state.po_number = get_cu[j].po_number
                    this.state.po_date = get_cu[j].po_date
                    this.state.pistatus = ""
                    this.state.pinumber = ""
                    this.state.container_size = get_cu[j].container_type
                    this.state.notes = get_cu[j].notes
                    this.state.priority_item = get_cu[j].priority_item
                    this.state.final_desc = get_cu[j].final_destination
                    this.state.displaycontainercapper = get_cu[j].container_capacity
                    this.state.get_tot = get_cu[j].total_order_qty
                    this.state.get_conta = get_cu[j].no_of_containers
                    this.state.action_status = get_cu[j].action_status
                  }

                  if (localStorage.getItem('usertypecode') == 'CUST') {
                    axios({
                      method: "POST",
                      url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
                      headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                      },
                      data: {
                        tag_value: get_tag,

                      }
                    }).then((response) => {
                      console.log("Test", response.data.data[0].tag_desc)
                      this.setState({
                        payment_term_code: response.data.data[0].tag_desc
                      })


                    });
                  } else {
                    this.state.pay_term = get_tag
                  }

                  console.log("Maniwish05 ", response.data.data)
                  const wishlist = response.data.data;
                  const getlist = []
                  let piqty = '0';
                  let pi_value = "0";
                  let price_date = this.state.price_date
                  let wi_prtype = ''
                  let wi_pcode = ''
                  let segment_desc = ""
                  let segment_code = ""
                  let product_group = ""
                  let product_desc = ""
                  let product_code = ""
                  let price = ""
                  let pi_qty = ""
                  let container_capacity_qty = ""
                  let container_capacity = ""
                  let container_capacity_per = ""
                  let product_type = ""
                  let parent_product_code = ""
                  let bom_no = ''
                  let id = ''

                  for (var w = 0; w < wishlist.length; w++) {

                    if (localStorage.getItem("segment") == 'EURO') {
                      price_date = wishlist[w].price_date
                    }

                    wi_prtype = wishlist[w].product_type
                    segment_desc = wishlist[w].segment_desc
                    segment_code = wishlist[w].segment_code
                    product_group = wishlist[w].product_group
                    product_desc = wishlist[w].product_desc
                    product_code = wishlist[w].product_code
                    price = wishlist[w].price
                    price_date = wishlist[w].price_date
                    pi_qty = wishlist[w].pi_qty
                    pi_value = wishlist[w].pi_value
                    container_capacity_qty = wishlist[w].container_capacity_qty
                    container_capacity = wishlist[w].container_capacity
                    container_capacity_per = wishlist[w].container_capacity_per
                    product_type = wishlist[w].product_type
                    parent_product_code = wishlist[w].parent_product_code
                    bom_no = wishlist[w].bom_no
                    id = wishlist[w].id

                    getlist.push({
                      segment_desc: segment_desc,
                      segment_code: segment_code,
                      product_group: product_group,
                      product_desc: product_desc,
                      product_code: product_code,
                      price: price,
                      price_date: price_date,
                      bom_no: bom_no,
                      bom_no1: "",
                      pi_qty: pi_qty,
                      pi_value: pi_value,
                      container_capacity_qty: container_capacity_qty,
                      container_capacity: container_capacity,
                      container_capacity_per: container_capacity_per,
                      product_type: product_type,
                      parent_product_code: parent_product_code,
                      id: id
                    })


                    if (wi_prtype == 'Set') {
                      wi_pcode = wishlist[w].product_code

                      axios({
                        method: "POST",
                        url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                        headers: {
                          'Content-Type': 'application/json',
                          'token': localStorage.getItem('token')
                        },
                        data: {
                          'product_code': wishlist[w].product_code,
                        }
                      }).then((response) => {
                        console.log("Manitest", response.data.data)
                        const getbomkit = []
                        let kibom = response.data.data;
                        var items = getlist
                        var kitbom = response.data.data
                        let item_pcode = ''
                        let kit_pcode = ''

                        for (var b = 0; b < kitbom.length; b++) {
                          getbomkit.push({
                            // product_code :kitbom[b].product_code,
                            alt_bom_no: kitbom[b].alt_bom_no
                          })
                        }
                        this.setState({
                          wish_kit_bom: getbomkit
                        })

                        for (var k = 0; k < kitbom.length; k++) {
                          kit_pcode = kitbom[k].product_code

                          for (var b = 0; b < items.length; b++) {
                            item_pcode = items[b].product_code

                            if (kit_pcode == item_pcode) {
                              items[b].bom_no1 = response.data.data
                              items[b].bom_no = response.data.data[0].alt_bom_no
                            } else {

                            }
                          }
                        }
                        this.setState({
                          add_get_size: getlist
                        })
                      });
                    } else {
                      console.log("Testmanicode", getlist)
                      this.setState({
                        add_get_size: getlist
                      })
                    }


                  }

                  this.setState({
                    //add_get_size: response.data.data,
                    get_cu_details: response.data.hdrs,
                    // you have it as this.data not response
                  });
                }
              });
            });
          });
        }

      });

    } else {
      alert("PI is not generated, Wating for PI")

    }




  }

  notify = (getres) => {
    toast.success(getres, {
      position: toast.POSITION.TOP_CENTER
    });
  };

  errorNotify = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER
    });
  }


  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide })
  }

  toggle = () => {

    if (this.state.customer_code == '') {
      alert("Please Select Customer Name")
    } else {
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }
  }

  toggleadd = () => {

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }

    if (custcode == '') {
      alert("Please Select Customer Name")
    } else {
      // axios({
      //   method: "POST",
      //   url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'token': localStorage.getItem('token')
      //   },
      //   data: {
      //     customer_sap_code: this.state.customer_code,
      //     order_mmyyyy: this.state.month_year,
      //     order_no: this.state.order_number,
      //     order_version: this.state.order_version
      //   }
      // }).then((response) => {
      //   console.log("custhdrsAdd info", response.data.hdrs)

      //   console.log("ADDINFOORDER", this.state.order_number)

      //   if (this.state.action_status == 'New') {
      //     // this.state.displaycontainercapper = 0
      //     // this.state.get_conta = 0
      //     // this.state.get_tot = 0
      //     this.state.action_status = 'New'
      //     if (response.data.hdrs != '') {
      //       var get_cu = response.data.hdrs
      //       var get_tag = ''
      //       for (var j = 0; j < get_cu.length; j++) {

      //         this.state.curreny = get_cu[j].order_category
      //         this.state.pay_term = get_cu[j].payment_term;
      //         get_tag = get_cu[j].payment_term;
      //         this.state.incotern = get_cu[j].inco_term;
      //         //this.state.port_delivery = get_cu[j].port_of_discharge;
      //         this.state.customer_code = get_cu[j].customer_sap_code;
      //         this.state.customer_id = get_cu[j].customer_id
      //         this.state.order_type = get_cu[j].order_type
      //         this.state.container_size = get_cu[j].container_type
      //         this.state.po_number = get_cu[j].po_number
      //         this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
      //         this.state.pistatus = get_cu[j].pi_status
      //         if (get_cu[j].pi_number == null) {
      //           this.state.pinumber = ""
      //         } else {
      //           this.state.pinumber = get_cu[j].pi_number
      //         }

      //         this.state.container_size = get_cu[j].container_type
      //         this.state.notes = get_cu[j].notes
      //         this.state.priority_item = get_cu[j].priority_item
      //         this.state.final_desc = get_cu[j].final_destination
      //         //this.state.displaycontainercapper = get_cu[j].container_capacity
      //         //this.state.get_tot = get_cu[j].total_order_qty
      //         //this.state.get_conta = get_cu[j].no_of_containers
      //         //this.state.action_status = get_cu[j].action_status
      //         this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
      //         this.state.portofdischarge = get_cu[j].port_of_discharge
      //         this.state.shipement_type = get_cu[j].precarriage_by
      //         this.state.remarkes = get_cu[j].remarks_quo
      //         this.state.remarkes1 = get_cu[j].remarks_so
      //         this.state.discount = get_cu[j].discount
      //         this.state.freight = get_cu[j].freight
      //         this.state.insurance = get_cu[j].insurance
      //         this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
      //         this.state.incoternlocation = get_cu[j].inco_term_location
      //         this.state.priority_item = get_cu[j].priority_item
      //         this.state.notifyparty = get_cu[j].notify_party_info1
      //         this.state.notifyinfo = get_cu[j].notify_party_info1
      //         this.state.notifyinfo1 = get_cu[j].notify_party_info2
      //         this.state.notifyinfo2 = get_cu[j].notify_party_info3
      //         this.state.notifyinfo3 = get_cu[j].notify_party_info4
      //         this.state.notifyinfo4 = get_cu[j].notify_party_info5
      //         this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
      //         this.state.filler_item = get_cu[j].filler_item
      //        // this.state.sonumber = get_cu[j].so_number
      //         this.state.notifymastercode = get_cu[j].notifyparty_sap_code
      //         //commit_date_of_shipment

      //       }

      //       if (localStorage.getItem('usertypecode') == 'CUST') {
      //         axios({
      //           method: "POST",
      //           url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
      //           headers: {
      //             'Content-Type': 'application/json',
      //             'token': localStorage.getItem('token')
      //           },
      //           data: {
      //             tag_value: get_tag,

      //           }
      //         }).then((response) => {
      //           console.log("Test", response.data.data[0].tag_desc)
      //           this.setState({
      //             payment_term_code: response.data.data[0].tag_desc
      //           })


      //         });
      //       } else {
      //         this.state.pay_term = get_tag
      //       }
      //       if (this.state.notifymastercode != "") {
      //         this.state.notychecked = true

      //         let notycode = this.state.notifymastercode

      //         if (localStorage.getItem('segment') != 'OHT') {
      //           axios({
      //             method: "POST",
      //             url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",

      //             headers: {
      //               'Content-Type': 'application/json',
      //               'token': localStorage.getItem('token')
      //             },

      //             data: {
      //               customer_sap_code: this.state.customer_code,
      //               notify_sap_code: notycode,
      //               segment: localStorage.getItem('segment')
      //             }
      //           }).then((response) => {
      //             console.log("Notify", response.data.data)
      //             if (response.data.status == 'Success') {
      //               this.setState({
      //                 notifymastername: response.data.data[0].notify_name
      //               });
      //             }


      //           });
      //         } else {
      //           axios({
      //             method: "POST",
      //             url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",

      //             headers: {
      //               'Content-Type': 'application/json',
      //               'token': localStorage.getItem('token')
      //             },

      //             data: {
      //               customer_sap_code: notycode,
      //               segment: localStorage.getItem('segment')
      //             }
      //           }).then((response) => {

      //             console.log("testoht", response.data.data)
      //             if (response.data.status == 'Success') {
      //               this.setState({
      //                 notifymastername: response.data.data[0].customer_name
      //               });
      //             }


      //           });
      //         }


      //       }
      //     } else {
      //       axios({
      //         method: "POST",
      //         url: this.state.baseurl + 'customer_details',//"http://localhost:3300/custdetails",
      //         headers: {
      //           'Content-Type': 'application/json',
      //           'token': localStorage.getItem('token')
      //         },
      //         data: {
      //           customer_sap_code: this.state.customer_code,
      //         }
      //       }).then((response) => {
      //         console.log("prepimonth", response.data.data[0].payment_term)


      //         var get_cust1 = response.data.data
      //         var get_tag = ''
      //         for (var j = 0; j < get_cust1.length; j++) {

      //           this.state.curreny = get_cust1[j].currency
      //           this.state.pay_term = get_cust1[j].payment_term;
      //           get_tag = get_cust1[j].payment_term;
      //           this.state.incotern = get_cust1[j].inco_term;
      //           // this.state.port_delivery = get_cust1[j].port;
      //           this.state.customer_code = get_cust1[j].customer_sap_code;
      //           this.state.customer_id = get_cust1[j].customer_id

      //         }


      //         if (localStorage.getItem('usertypecode') == 'CUST') {
      //           axios({
      //             method: "POST",
      //             url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
      //             headers: {
      //               'Content-Type': 'application/json',
      //               'token': localStorage.getItem('token')
      //             },
      //             data: {
      //               tag_value: get_tag,

      //             }
      //           }).then((response) => {
      //             console.log("Test", response.data.data[0].tag_desc)
      //             this.setState({
      //               payment_term_code: response.data.data[0].tag_desc
      //             })


      //           });
      //         } else {
      //           this.state.pay_term = get_tag
      //         }

      //       });
      //     }
      //   } else {
      //     var get_tag = ''
      //     for (var j = 0; j < get_cu.length; j++) {

      //       this.state.curreny = get_cu[j].order_category
      //       this.state.pay_term = get_cu[j].payment_term;
      //       get_tag = get_cu[j].payment_term
      //       this.state.incotern = get_cu[j].inco_term
      //       this.state.port_delivery = get_cu[j].port_of_discharge
      //       this.state.customer_code = get_cu[j].customer_sap_code
      //       this.state.customer_id = get_cu[j].customer_id
      //       this.state.order_type = get_cu[j].order_type
      //       this.state.container_size = get_cu[j].container_type
      //       this.state.po_number = get_cu[j].po_number
      //       this.state.po_date = moment(get_cu[j].po_date).format('YYYY-MM-DD')
      //       this.state.pistatus = get_cu[j].pi_status
      //       if (get_cu[j].pi_number == null) {
      //         this.state.pinumber = ""
      //       } else {
      //         this.state.pinumber = get_cu[j].pi_number
      //       }

      //       this.state.container_size = get_cu[j].container_type
      //       this.state.notes = get_cu[j].notes
      //       this.state.priority_item = get_cu[j].priority_item
      //       this.state.final_desc = get_cu[j].final_destination
      //       this.state.displaycontainercapper = get_cu[j].container_capacity
      //       this.state.get_tot = get_cu[j].total_order_qty
      //       this.state.get_conta = get_cu[j].no_of_containers
      //       this.state.action_status = get_cu[j].action_status
      //       this.state.piapprovedstatus = get_cu[j].pi_approved_by_sm_status
      //       this.state.portofdischarge = get_cu[j].port_of_discharge
      //       this.state.shipement_type = get_cu[j].precarriage_by
      //       this.state.remarkes = get_cu[j].remarks_quo
      //       this.state.remarkes1 = get_cu[j].remarks_so
      //       this.state.discount = get_cu[j].discount
      //       this.state.freight = get_cu[j].freight
      //       this.state.insurance = get_cu[j].insurance
      //       this.state.shipdate = moment(get_cu[j].commit_date_of_shipment).format('YYYY-MM-DD')
      //       this.state.incoternlocation = get_cu[j].inco_term_location
      //       this.state.priority_item = get_cu[j].priority_item
      //       this.state.notifyparty = get_cu[j].notify_party_info1
      //       this.state.notifyinfo = get_cu[j].notify_party_info1
      //       this.state.notifyinfo1 = get_cu[j].notify_party_info2
      //       this.state.notifyinfo2 = get_cu[j].notify_party_info3
      //       this.state.notifyinfo3 = get_cu[j].notify_party_info4
      //       this.state.notifyinfo4 = get_cu[j].notify_party_info5
      //       this.state.countryoffindes = get_cu[j].country_of_final_destination_place_of_supply
      //       this.state.filler_item = get_cu[j].filler_item
      //       this.state.sonumber = get_cu[j].so_number
      //       this.state.notifymastercode = get_cu[j].notifyparty_sap_code
      //       //commit_date_of_shipment

      //     }

      //     if (localStorage.getItem('usertypecode') == 'CUST') {
      //       axios({
      //         method: "POST",
      //         url: this.state.baseurl + 'gettag_desc',//"http://localhost:3300/custdetails",
      //         headers: {
      //           'Content-Type': 'application/json',
      //           'token': localStorage.getItem('token')
      //         },
      //         data: {
      //           tag_value: get_tag,

      //         }
      //       }).then((response) => {
      //         console.log("Test", response.data.data[0].tag_desc)
      //         this.setState({
      //           payment_term_code: response.data.data[0].tag_desc
      //         })


      //       });
      //     } else {
      //       this.state.pay_term = get_tag
      //     }


      //     if (this.state.notifymastercode != "") {
      //       this.state.notychecked = true

      //       let notycode = this.state.notifymastercode

      //       if (localStorage.getItem('segment') != 'OHT') {
      //         axios({
      //           method: "POST",
      //           url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",
      //           headers: {
      //             'Content-Type': 'application/json',
      //             'token': localStorage.getItem('token')
      //           },

      //           data: {
      //             customer_sap_code: this.state.customer_code,
      //             notify_sap_code: notycode,
      //             segment: localStorage.getItem('segment')
      //           }
      //         }).then((response) => {

      //           if (response.data.status == 'Success') {
      //             this.setState({
      //               notifymastername: response.data.data[0].notify_name
      //             });
      //           }


      //         });
      //       } else {
      //         axios({
      //           method: "POST",
      //           url: this.state.baseurl + 'notify_master_name',//"http://localhost:3300/custdetails",

      //           headers: {
      //             'Content-Type': 'application/json',
      //             'token': localStorage.getItem('token')
      //           },

      //           data: {
      //             customer_sap_code: notycode,
      //             segment: localStorage.getItem('segment')
      //           }
      //         }).then((response) => {

      //           console.log("testoht", response.data.data)
      //           if (response.data.status == 'Success') {
      //             this.setState({
      //               notifymastername: response.data.data[0].customer_name
      //             });
      //           }


      //         });
      //       }


      //     }
      //   }


      // });

      this.setState(prevState => ({
        modaladd: !prevState.modaladd
      }));
    }
  }
  toggleeadd = () => {
    this.setState(prevState => ({
      modaladd: !prevState.modaladd
    }));
  }


  toggleexcel = () => {
    if (this.state.customer_code == '') {
      alert("Please Select Customer Name")
    } else {
      if (this.state.container_size == '') {
        alert("Please Select Container Size")
      } else {

        this.setState(prevState => ({
          excel_upload: !prevState.excel_upload,
          excel_error: []
        }));
      }
    }
  }
  toggleeexcel = () => {
    this.setState(prevState => ({
      excel_upload: !prevState.excel_upload
    }));
  }

  handleexcel = (event) => {
    this.setState({
      excel_file: event.target.files[0]
    })
  }

  handleuplodexcel = () => {

    console.log("excelupload", this.state.excel_file)
    const formData = new FormData();
    formData.append(
      'excel_file', this.state.excel_file);

    const data = ''
    var f = this.state.excel_file;
    var name = f.name;
    const reader = new FileReader();
    reader.onload = (evt) => {

      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, {
        header: 1,
        blankrows: false
      });
      /* Update state */
      console.log("Data>>>" + data);// shows that excel data is read
      const items = this.convertToJson(data)
      console.log(JSON.stringify({ "items": this.convertToJson(data) }).replace(/"\s+|\s+"/g, '"')); // shows data in json format



      axios({
        method: "POST",
        url: this.state.baseurl + 'excel_upload',//"http://localhost:3300/custdetails",z
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          p_prepi_details: JSON.stringify({
            "order_mmyyyy": this.state.month_year,
            "order_no": localStorage.getItem('empcode'),
            "order_version": "0",
            "order_category": "",
            "customer_sap_code": this.state.customer_code,
            "notifyparty_sap_code": "",
            "po_number": "",
            "po_date": "",
            "container_type": this.state.container_size,
            "container_capacity": "",
            "total_order_qty": "0",
            "no_of_containers": "0",
            "total_order_value": "0",
            "se_code": "0",
            "port_of_discharge": "",
            "payment_term": "",
            "inco_term": "",
            "order_type": "",
            "filler_item": "",
            "pi_status": "",
            "created_at": "",
            "modified_at": "",
          }),
          p_prepi_details_item: JSON.stringify({ "items": this.convertToJson(data) }).replace(/"\s+|\s+"/g, '"')
        }

      }).then((response) => {
        let res = response.data.message
        console.log("Manitest", res)

        var getres = ''
        for (var i = 0; i < res.length; i++) {
          getres = res[i].prepi_order_exl_validate
        }
        var gtres = getres.substring(0, 1)



        if (gtres == "S") {

          axios({
            method: "POST",
            url: this.state.baseurl + 'excel_sucess_file',//"http://localhost:3300/custdetails",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
            },
            data: {
              order_mmyyyy: this.state.month_year,
              customer_sap_code: this.state.customer_code,
              user_name: localStorage.getItem('empcode')
            }
          }).then((response) => {

            console.log("Maniexcelupload ", response.data.data)
            const wishlist = response.data.data;
            const getlist = []
            let piqty = '0';
            let pi_value = "0";
            let price_date = this.state.price_date
            let wi_prtype = ''
            let wi_pcode = ''
            let segment_desc = ""
            let segment_code = ""
            let product_group = ""
            let product_desc = ""
            let product_code = ""
            let price = ""
            const bom_no = []
            let pi_qty = ""
            let container_capacity_qty = ""
            let container_capacity = ""
            let container_capacity_per = ""
            let product_type = ""
            let parent_product_code = ""
            let kit_flag = ""
            let id = ""

            for (var w = 0; w < wishlist.length; w++) {

              if (localStorage.getItem("segment") == 'EURO') {
                price_date = wishlist[w].price_date
              }

              wi_prtype = wishlist[w].product_type
              segment_desc = wishlist[w].segment_desc
              segment_code = wishlist[w].segment_code
              product_group = wishlist[w].product_group
              product_desc = wishlist[w].product_desc
              product_code = wishlist[w].product_code
              price = wishlist[w].price
              price_date = wishlist[w].price_date
              pi_qty = wishlist[w].pi_qty
              pi_value = wishlist[w].pi_value
              container_capacity_qty = wishlist[w].container_capacity_qty
              container_capacity = wishlist[w].container_capacity
              container_capacity_per = wishlist[w].container_capacity_per
              product_type = wishlist[w].product_type
              parent_product_code = wishlist[w].parent_product_code
              id = wishlist[w].id
              kit_flag = "S"



              getlist.push({
                segment_desc: segment_desc,
                segment_code: segment_code,
                product_group: product_group,
                product_desc: product_desc,
                product_code: product_code,
                price: price,
                price_date: price_date,
                bom_no: "0",
                bom_no1: "",
                pi_qty: pi_qty,
                pi_value: pi_value,
                container_capacity_qty: container_capacity_qty,
                container_capacity: container_capacity,
                container_capacity_per: container_capacity_per,
                product_type: product_type,
                parent_product_code: parent_product_code,
                id: id,
                kit_flag: kit_flag
              })


              if (wi_prtype == 'Set') {
                wi_pcode = wishlist[w].product_code

                axios({
                  method: "POST",
                  url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    'product_code': wishlist[w].product_code,
                  }
                }).then((response) => {
                  const getbomkit = []
                  let kibom = response.data.data;
                  var items = getlist
                  var kitbom = response.data.data
                  let item_pcode = ''
                  let kit_pcode = ''

                  for (var b = 0; b < kitbom.length; b++) {
                    getbomkit.push({
                      // product_code :kitbom[b].product_code,
                      alt_bom_no: kitbom[b].alt_bom_no
                    })
                  }
                  this.setState({
                    wish_kit_bom: getbomkit
                  })

                  for (var k = 0; k < kitbom.length; k++) {
                    kit_pcode = kitbom[k].product_code

                    for (var b = 0; b < items.length; b++) {
                      item_pcode = items[b].product_code

                      if (kit_pcode == item_pcode) {
                        items[b].bom_no1 = response.data.data
                        items[b].bom_no = response.data.data[0].alt_bom_no
                      } else {

                      }
                    }

                  }


                  this.setState({
                    add_get_size: getlist
                  })

                  this.calculation()

                  // if (localStorage.getItem('segment') == '2WEXPORT') {

                  //   console.log("Manits",this.state.add_get_size)

                  //   let total_pi = 0;
                  //   let piqty = ''
                  //   let containercapqty = 0;
                  //   let containercapper = 0
                  //   let totalcontainercapper = 0;
                  //   let noofcontiner = 0;
                  //   let containermaxcapacity = 0;
                  //   let volume_occupied = ''
                  //   let total_volume = 0;
                  //   let containersizevolume = ''
                  //   const getitem =getlist
                  //   let getuom =''
                  //   for (var i = 0; i < getitem.length; i++) {
                  //     piqty = parseInt(getitem[i].pi_qty);
                  //     getuom = getitem[i].product_type

                  //     containercapqty = getitem[i].container_capacity_qty;
                  //     console.log("Testconta", containercapqty)
                  //     containermaxcapacity = getitem[i].container_capacity;
                  //     if(getuom == 'Set' || getuom == null ||getuom == ''  ){
                  //       total_pi = total_pi + parseInt(piqty);
                  //     }else{
                  //       total_pi = total_pi ;
                  //     }


                  //     if (containercapqty != '0' || containercapqty != null ) {
                  //       console.log("Containerqty",containercapqty )
                  //       volume_occupied = (((piqty * containercapqty) / 150) * 100).toFixed(2);
                  //     } else {
                  //       volume_occupied = 0
                  //     }
                  //     total_volume = total_volume + volume_occupied


                  //     console.log("Totalpi", total_pi)

                  //     getitem[i].container_capacity_per = volume_occupied
                  //   }



                  //   console.log("size", this.state.container_size)

                  //   if (this.state.container_size == '20 Feet') {
                  //     containersizevolume = 2030.535
                  //   }

                  //   if (this.state.container_size == '40 Feet') {
                  //     containersizevolume = 4113.135
                  //   }

                  //   if (this.state.container_size == '40 High Cube') {
                  //     containersizevolume = 4113.135
                  //   }


                  //   let noofcontainer = ''

                  //   noofcontainer = Math.ceil(total_volume / containersizevolume)


                  //   this.state.displaycontainercapper = ((total_volume / containersizevolume) * 100).toFixed(0);
                  //   console.log("Mni", total_volume)

                  //   this.setState({
                  //     displaycontainercapper: ((total_volume / containersizevolume) * 100).toFixed(0),
                  //     get_conta: noofcontainer,
                  //     get_tot: total_pi
                  //   })




                  // } else {

                  //   let total_pi = 0;
                  //   let piqty = this.state.add_tqty
                  //   let containercapqty = this.state.get_container
                  //   let containercapper = 0
                  //   let totalcontainercapper = 0;
                  //   let noofcontiner = 0;
                  //   let volume_occupied = ''
                  //   let total_volume = 0;
                  //   let containersizevolume = ''
                  //   let getuom =''
                  //   const getitem = getlist
                  //   for (var i = 0; i < getitem.length; i++) {
                  //     piqty = parseInt(getitem[i].pi_qty);
                  //     getuom = getitem[i].product_type
                  //     console.log("ADDITEMLIST", getitem[i].container_capacity_qty)
                  //     if (getitem[i].container_capacity_qty != '') {
                  //       containercapqty = parseInt(getitem[i].container_capacity_qty);
                  //     } else {
                  //       containercapqty = '0'
                  //     }
                  //     if(getuom == 'Set' || getuom == null ||getuom == ''  ){
                  //       total_pi = total_pi + parseInt(piqty);
                  //     }else{
                  //       total_pi = total_pi ;
                  //     }

                  //     if (containercapqty != '0') {
                  //       containercapper = (piqty / containercapqty) * 100;
                  //     }

                  //     totalcontainercapper = totalcontainercapper + containercapper;

                  //     if (containercapqty != '0') {
                  //       volume_occupied = (((piqty * containercapqty) / 150) * 100).toFixed(2);
                  //     } else {
                  //       volume_occupied = 0
                  //     }
                  //     total_volume = total_volume + volume_occupied


                  //     console.log("Totalpi", volume_occupied)

                  //     getitem[i].container_capacity_per = volume_occupied

                  //   }



                  //   let noofcontainer = ''
                  //   if (totalcontainercapper != '0' || totalcontainercapper != null) {
                  //     noofcontainer = Math.floor(totalcontainercapper / 100) + Math.floor((totalcontainercapper % 100) / 95);
                  //   } else {
                  //     noofcontainer = ''
                  //   }
                  //   this.state.displaycontainercapper = (totalcontainercapper % 100).toFixed(2);
                  //   this.setState({
                  //     displaycontainercapper: (totalcontainercapper % 100).toFixed(2),
                  //     get_tot: total_pi,
                  //     get_conta: noofcontainer
                  //   })
                  // }

                });
              } else {
                this.setState({
                  add_get_size: getlist
                })
                this.calculation()

                // if (localStorage.getItem('segment') == '2WEXPORT') {

                //   console.log("Manits",this.state.add_get_size)

                //   let total_pi = 0;
                //   let piqty = ''
                //   let containercapqty = 0;
                //   let containercapper = 0
                //   let totalcontainercapper = 0;
                //   let noofcontiner = 0;
                //   let containermaxcapacity = 0;
                //   let volume_occupied = ''
                //   let total_volume = 0;
                //   let containersizevolume = ''
                //   const getitem =getlist
                //   let getuom =''
                //   for (var i = 0; i < getitem.length; i++) {
                //     piqty = parseInt(getitem[i].pi_qty);
                //     getuom = getitem[i].product_type
                //     console.log("Test", piqty)
                //     containercapqty = getitem[i].container_capacity_qty;
                //     containermaxcapacity = getitem[i].container_capacity;
                //     if(getuom == 'Set' || getuom == null ||getuom == ''  ){
                //       total_pi = total_pi + parseInt(piqty);
                //     }else{
                //       total_pi = total_pi ;
                //     }


                //     if (containercapqty != '0') {
                //       volume_occupied = (((piqty * containercapqty) / 150) * 100).toFixed(2);
                //     } else {
                //       volume_occupied = 0
                //     }
                //     total_volume = total_volume + volume_occupied


                //     console.log("Totalpi", total_pi)

                //     getitem[i].container_capacity_per = volume_occupied
                //   }



                //   console.log("size", this.state.container_size)

                //   if (this.state.container_size == '20 Feet') {
                //     containersizevolume = 2030.535
                //   }

                //   if (this.state.container_size == '40 Feet') {
                //     containersizevolume = 4113.135
                //   }

                //   if (this.state.container_size == '40 High Cube') {
                //     containersizevolume = 4113.135
                //   }


                //   let noofcontainer = ''

                //   noofcontainer = Math.ceil(total_volume / containersizevolume)




                //   this.setState({
                //     displaycontainercapper: ((total_volume / containersizevolume) * 100).toFixed(0),
                //     get_conta: noofcontainer,
                //     get_tot: total_pi
                //   })

                //   this.state.displaycontainercapper = ((total_volume / containersizevolume) * 100).toFixed(0);


                // } else {

                //   let total_pi = 0;
                //   let piqty = this.state.add_tqty
                //   let containercapqty = this.state.get_container
                //   let containercapper = 0
                //   let totalcontainercapper = 0;
                //   let noofcontiner = 0;
                //   let volume_occupied = ''
                //   let total_volume = 0;
                //   let containersizevolume = ''
                //   let getuom =''
                //   const getitem = getlist
                //   for (var i = 0; i < getitem.length; i++) {
                //     piqty = parseInt(getitem[i].pi_qty);
                //     getuom = getitem[i].product_type
                //     console.log("ADDITEMLIST", getitem[i].container_capacity_qty)
                //     if (getitem[i].container_capacity_qty != '') {
                //       containercapqty = parseInt(getitem[i].container_capacity_qty);
                //     } else {
                //       containercapqty = '0'
                //     }
                //     if(getuom == 'Set' || getuom == null ||getuom == ''  ){
                //       total_pi = total_pi + parseInt(piqty);
                //     }else{
                //       total_pi = total_pi ;
                //     }

                //     if (containercapqty != '0') {
                //       containercapper = (piqty / containercapqty) * 100;
                //     }

                //     totalcontainercapper = totalcontainercapper + containercapper;

                //     if (containercapqty != '0') {
                //       volume_occupied = (((piqty * containercapqty) / 150) * 100).toFixed(2);
                //     } else {
                //       volume_occupied = 0
                //     }
                //     total_volume = total_volume + volume_occupied


                //     console.log("Totalpi", volume_occupied)

                //     getitem[i].container_capacity_per = volume_occupied

                //   }



                //   let noofcontainer = ''
                //   if (totalcontainercapper != '0' || totalcontainercapper != null) {
                //     noofcontainer = Math.floor(totalcontainercapper / 100) + Math.floor((totalcontainercapper % 100) / 95);
                //   } else {
                //     noofcontainer = ''
                //   }
                //   this.state.displaycontainercapper = (totalcontainercapper % 100).toFixed(2);
                //   this.setState({
                //     displaycontainercapper: (totalcontainercapper % 100).toFixed(2),
                //     get_tot: total_pi,
                //     get_conta: noofcontainer
                //   })
                // }
              }


            }



            // this.setState({
            //   add_get_size: response.data.data, // you have it as this.data not response
            // });
            this.setState(prevState => ({
              excel_upload: !prevState.excel_upload
            }));
          });

        } else {
          console.log("mANIATEW", gtres)
          this.state.excel_error = []
          axios({
            method: "POST",
            url: this.state.baseurl + 'excel_error_file',//"http://localhost:3300/custdetails",
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
            },
            data: {
              order_mmyyyy: this.state.month_year,
              customer_sap_code: this.state.customer_code,
              user_name: localStorage.getItem('empcode')
            }
          }).then((response) => {
            console.log("Excelerror", response.data.data)
            this.setState({
              excel_error: response.data.data
            })

          });

        }
      });
      //}

    };
    reader.readAsBinaryString(f);



  }


  filePathset(e) {
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    console.log(file);
    this.setState({ file });

    console.log(this.state.file);
  }

  readFile() {
    var f = this.state.file;
    var name = f.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      console.log("Data>>>" + data);// shows that excel data is read
      console.log(data); // shows data in json format
    };
    reader.readAsBinaryString(f);
  }

  convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    return result; //JSON
    //return result; //JavaScript object
    //return JSON.stringify({"items":result}); //JSON
  }



  addwish = (todo, e) => {

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }


    const { checked } = e.target

    let getchek = checked;


    this.setState({
      checked: checked
    })

    let wish = ''
    if (getchek == true) {
      wish = 'Y'
    } else {
      wish = 'N'
    }

    axios({
      method: "POST",
      url: this.state.baseurl + 'wishlistupdate',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        //"id": todo.id,
        "wish_list": wish,
        "customer_sap_code": custcode,
        "product_code": todo.product_code,

      }
    }).then((response) => {
      console.log("Testwish", response.data.data)
      this.setState({
        // get_wishlist: response.data.data, // you have it as this.data not response
      });
    });

  }


  addwishview = (todo, e) => {

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }


    const { checked } = e.target

    let getchek = checked;


    this.setState({
      checked: checked
    })

    let wish = ''
    if (getchek == true) {
      wish = 'Y'
    } else {
      wish = 'N'
    }

    axios({
      method: "POST",
      url: this.state.baseurl + 'wishlistupdate',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        //"id": todo.id,
        "wish_list": wish,
        "customer_sap_code": custcode,
        "product_code": todo.product_code,

      }
    }).then((response) => {
      console.log("Testwish", response.data.data)
      this.setState({
        // get_wishlist: response.data.data, // you have it as this.data not response
      });
    });

  }




  handle_total_piqty = (id, e) => {

    var items = this.state.orderaddmodel;
    let or_qty = '';
    let pcode = ''
    let piqt = ''
    piqt = items[id].pi_qty
    for (var i = 0; i < items.length; i++) {
      or_qty = items[i].order_qty
      pcode = items[i].product_code
      piqt = items[id].pi_qty
    }


    const re = /^[0-9\b]+$/;
    let getvalue = ''
    if (e.target.value === '' || re.test(e.target.value)) {

      getvalue = e.target.value
    }

    let bal_qty = or_qty - getvalue
    console.log("Testmani", e.target.value)
    let gtpi = getvalue;


    this.setState({
      total_pi_qty: getvalue
    })

    this.setState({
      pi_qty: getvalue
    })

    var items = this.state.orderaddmodel;

    items[id].pi_qty = getvalue;
    items[id].order_no = this.state.order_number;
    items[id].order_version = this.state.order_version

    console.log("Test123month", this.state.container_size)
    axios({
      method: "POST",
      url: this.state.baseurl + 'qty_master',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        container_type: this.state.container_size,
        product_code: pcode,
      }
    }).then((response) => {
      var getmqty = response.data.data
      console.log("Tesqty", getmqty)
      this.setState({
        get_qty_master: response.data.data
      });

      var gtymca = ''
      for (var i = 0; i < getmqty.length; i++) {
        gtymca = getmqty[i].quantity
      }


      items[id].container_capacity_qty = gtymca


      this.setState({
        orderaddmodel: items,
        concapqty: gtymca
      });


    });

  }



  handle_pricedate_g = (id, e) => {
    var items = this.state.orderaddmodel;

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code
    }


    console.log("Mani", items)
    let pr_code = ""

    for (var i = 0; i < items.length; i++) {
      pr_code = items[id].product_code
    }
    this.setState({
      price_date: e.target.value
    })

    items[id].price_date = e.target.value;

    this.setState({
      orderaddmodel: items,
    });

    axios({
      method: "POST",
      url: this.state.baseurl + 'getprice',//"http://localhost:3300/user_login",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "valid_from": e.target.value,
        "valid_to": e.target.value,
        "customer_sap_code": custcode,
        "product_code": pr_code,

      }
    }).then((response) => {
      console.log("datepriceset", response.data.data)
      this.setState({
        getprice_date: response.data.data[0], // you have it as this.data not response
      });
      items[id].price = response.data.data[0].price

    });
  }

  handle_pricedate = (id, e) => {


    var items = this.state.add_get_size;

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }


    console.log("Mani", items)
    let pr_code = ""
    let gqty = ''
    let parent_pcode = ''
    let pr_type = ''
    let getpcode = items[id].product_code

    let get_pdate = e.target.value
    let get_bomno = items[id].bom_no

    this.setState({
      price_date: get_pdate
    })

    const product_code = []
    for (var i = 0; i < items.length; i++) {
      pr_code = items[id].product_code
      gqty = items[id].pi_qty
      parent_pcode = items[i].parent_product_code
      pr_type = items[id].product_type

      console.log("Main0509", parent_pcode)



      if (parent_pcode != "" || parent_pcode != null) {
        console.log("05092022", items[id].product_code)
        if (getpcode == parent_pcode) {
          console.log("manitest", parent_pcode)
          items[i].price_date = get_pdate;
          product_code.push({
            "pcode": items[i].product_code
          })
        } else {
          items[id].price_date = get_pdate;
          product_code.push({
            "pcode": items[id].product_code
          })
        }


      } else {

        items[id].price_date = get_pdate;
        product_code.push({
          "pcode": items[id].product_code
        })
        console.log("Test05092022", items[id].product_code)
      }

    }


    console.log("Test23", product_code)
    this.state.getprice_date = []
    items[id].price_date = get_pdate;
    items[id].price = ''

    if (pr_type == "") {
      axios({
        method: "POST",
        url: this.state.baseurl + 'getprice',//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          "valid_from": get_pdate,
          "valid_to": get_pdate,
          "customer_sap_code": custcode,
          "product_code": product_code,


        }
      }).then((response) => {
        console.log("datepricesetm", response.data.data)

        if (response.data.status == "Success") {
          console.log("Mani")
          items[id].price = ''
          const setprice = response.data.data
          this.setState({
            getprice_date: response.data.data[0], // you have it as this.data not response
          });
          let parent_pcode = ''
          let pr_type = ''
          let pcode = ''
          for (var i = 0; i < items.length; i++) {
            parent_pcode = items[i].parent_product_code
            pr_type = items[i].product_type
            pcode = items[i].product_code

            console.log("Manite", pcode)

            if (parent_pcode != '') {

              if (pr_type != 'Set') {
                for (var p = 0; p < setprice.length; p++) {


                  if (pcode == setprice[p].product_code) {
                    items[i].price = setprice[p].price
                  } else {
                    //items[i].price=  0.0

                  }
                }

              } else {
                let par_price = 0;
                let chi_price = 0
                for (var p = 0; p < setprice.length; p++) {
                  console.log("Setpcode", setprice[p].product_code + "," + pcode)

                  chi_price = parseFloat(setprice[p].price).toFixed(2)
                  par_price = parseFloat(par_price) + parseFloat(chi_price)

                  console.log("Manitestkit", pcode)

                  if (pcode == getpcode) {
                    items[i].price = par_price
                  }


                }



                console.log("par_price", par_price)

              }
            } else {
              items[id].price = response.data.data[0].price
            }
          }


          let getvalue = (response.data.data[0].price * gqty).toFixed(2)
          console.log("Manitestprice", getvalue)
          items[id].pi_value = getvalue
          this.setState({
            orderaddmodel: items,
          });
        } else {
          items[id].price = ''
          const setprice = response.data.data
          this.setState({
            getprice_date: response.data.data[0], // you have it as this.data not response
          });
          let parent_pcode = ''
          let pr_type = ''
          let pcode = ''
          for (var i = 0; i < items.length; i++) {
            parent_pcode = items[i].parent_product_code
            pr_type = items[i].product_type
            pcode = items[i].product_code

            console.log("Manite", pcode)

            if (parent_pcode != '') {

              if (pr_type != 'Set') {
                for (var p = 0; p < setprice.length; p++) {


                  if (pcode == setprice[p].product_code) {
                    items[i].price = 0.0
                  } else {
                    //items[i].price=  0.0

                  }
                }

              } else {
                let par_price = 0;
                let chi_price = 0
                for (var p = 0; p < setprice.length; p++) {
                  console.log("Setpcode", setprice[p].product_code + "," + pcode)

                  chi_price = parseFloat(setprice[p].price).toFixed(2)
                  par_price = parseFloat(par_price) + parseFloat(chi_price)

                  console.log("Manitestkit", pcode)
                  console.log("Manitestkit", par_price)

                  if (pcode == getpcode) {
                    items[i].price = par_price.toFixed(2)
                  }


                }



                console.log("par_price", par_price)

              }
            } else {
              items[id].price = response.data.data[0].price
            }
          }
        }

      });

    } else {

      axios({
        method: "POST",
        url: this.state.baseurl + 'getkitprice',//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          "customer_sap_code": custcode,
          "product_code": getpcode,
          "product_desc": "",
          "price_date": get_pdate,
          "qty": gqty,
          "container_type": this.state.container_size,
          "user_name": localStorage.getItem('username'),
          "bom_no": get_bomno


        }
      }).then((response) => {
        console.log("datakitbomprice", response.data.data)
        console.log("Manikitbomprice", items)
        const setprice = response.data.data

        let parent_pcode = ''
        let pr_type = ''
        let pcode = ''
        for (var i = 0; i < items.length; i++) {
          parent_pcode = items[i].parent_product_code
          pr_type = items[i].product_type
          pcode = items[i].product_code

          console.log("Manite", pcode)

          for (var p = 0; p < setprice.length; p++) {
            console.log("Pricemani", setprice[p].price)
            if (pcode == setprice[p].product_code) {
              items[i].price = setprice[p].price
              console.log("ManiPrice", items[i].price)
            }
          }

          this.setState({
            orderaddmodel: items,
          });
        }
      });
    }



  }


  handle_kitbom = (id, e) => {

    let bom = e.target.value

    var items = this.state.add_get_size

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }


    var myarray = bom.split('-');
    let pcode = ''
    let bomno = ''

    let getpcode = items[id].product_code
    let gqty = items[id].pi_qty
    let getpricedate = items[id].price_date


    for (var i = 0; i < myarray.length; i++) {
      pcode = myarray[0]
      bomno = myarray[1]
    }

    axios({
      method: "POST",
      url: this.state.baseurl + 'get_bom_list',//"http://localhost:3300/user_login",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {

        "product_code": pcode,
        "alt_bom_no": bomno
      }
    }).then((response) => {
      console.log("databomno123", response.data.data)


      const getchildbom = response.data.data
      let parent_pcode = ''
      let pr_type = ''
      let item_pr_code = ''
      let item_id
      let from_date = ''
      let to_date = ''

      let par_price1 = 0;
      let chi_price1 = 0;

      for (var i = 0; i < items.length; i++) {
        parent_pcode = items[i].parent_product_code
        pr_type = items[i].product_type
        pcode = items[i].product_code
        item_id = items[i].id
        from_date = items[i].price_date
        to_date = items[i].price_date

        chi_price1 = parseFloat(items[i].price).toFixed(2)
        par_price1 = parseFloat(par_price1) + parseFloat(chi_price1)

        console.log("Getpricebom", par_price1)
        console.log("Manite", items)

        if (parent_pcode != '') {

          if (pr_type != 'Set') {
            console.log("Getmanibom9480403", getchildbom)

            let par_pcode = ''
            for (var p = 0; p < getchildbom.length; p++) {


              if (parent_pcode == getchildbom[p].product_code) {
                console.log("tube", getchildbom[p].component)
                par_pcode = getchildbom[p].product_code
                console.log("tube1231232", getchildbom[p].product_code)
                let bom = getchildbom[p].component
                console.log("Mnaites", bom[0])
                if (bom[0] != '3') {
                  if (pr_type == 'Tube') {
                    console.log("Tubemani", getchildbom[p].component)
                    items[i].product_code = getchildbom[p].component

                    const product_code = []

                    axios({
                      method: "POST",
                      url: this.state.baseurl + 'getkitprice',//"http://localhost:3300/user_login",
                      headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                      },
                      data: {
                        "customer_sap_code": custcode,
                        "product_code": getpcode,
                        "product_desc": "",
                        "price_date": getpricedate,
                        "qty": gqty,
                        "container_type": this.state.container_size,
                        "user_name": localStorage.getItem('username'),
                        "bom_no": bomno


                      }
                    }).then((response) => {
                      console.log("datakitbomprice", response.data.data)
                      console.log("Manikitbomprice", items)
                      const setprice = response.data.data

                      let parent_pcode = ''
                      let pr_type = ''
                      let pcode = ''
                      for (var i = 0; i < items.length; i++) {
                        parent_pcode = items[i].parent_product_code
                        pr_type = items[i].product_type
                        pcode = items[i].product_code

                        console.log("Manite", pcode)

                        for (var p = 0; p < setprice.length; p++) {
                          console.log("Pricemani", setprice[p].price)
                          if (pcode == setprice[p].product_code) {
                            items[i].price = setprice[p].price
                            console.log("ManiPrice", items[i].price)
                          }
                        }

                        this.setState({
                          orderaddmodel: items,
                        });
                      }
                    });

                    // product_code.push({
                    //   "pcode": getchildbom[p].component
                    // })
                    // axios({
                    //   method: "POST",
                    //   url: this.state.baseurl + 'getprice',//"http://localhost:3300/user_login",
                    //   headers: {
                    //     'Content-Type': 'application/json',
                    //     'token': localStorage.getItem('token')
                    //   },
                    //   data: {
                    //     "valid_from": from_date,
                    //     "valid_to": to_date,
                    //     "customer_sap_code": custcode,
                    //     "product_code": product_code


                    //   }
                    // }).then((response) => {
                    //   console.log("BomChange ", response.data.data)

                    //   if (response.data.status == "Success") {
                    //     console.log("Mani")
                    //     items[id].price = ''
                    //     const setprice = response.data.data
                    //     this.setState({
                    //       getprice_date: response.data.data[0], // you have it as this.data not response
                    //     });
                    //     let parent_pcode = ''
                    //     let pr_type = ''
                    //     let pcode = ''
                    //     let gqty = 0

                    //     for (var i = 0; i < items.length; i++) {
                    //       parent_pcode = items[i].parent_product_code
                    //       pr_type = items[i].product_type
                    //       pcode = items[i].product_code
                    //       gqty = items[i].pi_qty
                    //       console.log("Manite", pcode)



                    //       if (parent_pcode != '') {

                    //         if (pr_type != 'Set') {
                    //           for (var p = 0; p < setprice.length; p++) {


                    //             if (pcode == setprice[p].product_code) {
                    //               items[i].price = setprice[p].price
                    //             } else {
                    //               //items[i].price=  0.0

                    //             }
                    //           }

                    //         } else {

                    //           console.log("bomprice", items)
                    //           let par_price = 0;

                    //           let chi_price = 0

                    //           let getcode = ''
                    //           let final_price = 12.97
                    //           let get_tyre_price = 0

                    //           console.log("maniavarutha", par_pcode)

                    //           for (var b = 0; b < items.length; b++) {
                    //             console.log("Gettyreprice1212", par_pcode)
                    //             if (par_pcode == items[b].parent_product_code) {

                    //               if (items[b].product_type == 'Tyre') {
                    //                 get_tyre_price = items[b].price
                    //               }

                    //               console.log("Gettyreprice", get_tyre_price)

                    //             }
                    //           }


                    //           for (var p = 0; p < setprice.length; p++) {
                    //             console.log("Setpcode", setprice[p].product_code + "," + pcode)
                    //             chi_price = parseFloat(setprice[p].price).toFixed(2)
                    //             par_price = parseFloat(par_price) + parseFloat(chi_price).toFixed(2)

                    //           }


                    //           let getcalprice = parseFloat(get_tyre_price) + parseFloat(par_price)

                    //           // let getbprice = parseFloat(get_tyre_price)+ parseFloat(chi_price)

                    //           // final_price  = parseFloat(final_price) + parseFloat(getbprice) 

                    //           console.log("Maniget", pcode + "====" + par_pcode)

                    //           if (pcode == par_pcode) {
                    //             items[i].price = getcalprice.toFixed(2)
                    //             console.log("par_pricemani", getcalprice.toFixed(2))
                    //           } else {
                    //             items[id].price = 0.0
                    //           }


                    //         }
                    //       } else {
                    //         items[id].price = response.data.data[0].price
                    //       }
                    //     }


                    //     let getvalue = (response.data.data[0].price * gqty).toFixed(2)

                    //     console.log("Manitestbom", getvalue)
                    //     items[id].pi_value = getvalue
                    //     this.setState({
                    //       orderaddmodel: items,
                    //     });
                    //   } else {
                    //     items[id].price = ''
                    //     const setprice = response.data.data
                    //     this.setState({
                    //       getprice_date: response.data.data[0], // you have it as this.data not response
                    //     });
                    //     let parent_pcode = ''
                    //     let pr_type = ''
                    //     let pcode = ''
                    //     for (var i = 0; i < items.length; i++) {
                    //       parent_pcode = items[i].parent_product_code
                    //       pr_type = items[i].product_type
                    //       pcode = items[i].product_code

                    //       console.log("Manite", pcode)

                    //       if (parent_pcode != '') {

                    //         if (pr_type != 'Set') {
                    //           for (var p = 0; p < setprice.length; p++) {


                    //             if (pcode == setprice[p].product_code) {
                    //               items[i].price = 0.0
                    //             } else {
                    //               //items[i].price=  0.0

                    //             }
                    //           }

                    //         } else {
                    //           let par_price = 0;
                    //           let chi_price = 0
                    //           console.log("Mniat", setprice)
                    //           for (var p = 0; p < setprice.length; p++) {
                    //             console.log("Setpcode", setprice[p].product_code + "," + pcode)

                    //             chi_price = parseFloat(setprice[p].price).toFixed(2)
                    //             par_price = parseFloat(par_price) + parseFloat(chi_price)

                    //             console.log("Manitestkit", pcode)

                    //             if (pcode == getchildbom[p].component) {
                    //               items[i].price = par_price
                    //             }


                    //           }



                    //           console.log("par_price12121", par_price)

                    //         }
                    //       } else {
                    //         items[id].price = response.data.data[0].price
                    //       }
                    //     }
                    //   }

                    // });

                  }
                } else {
                  // items[i].product_code = getchildbom[p].component
                }

              }
            }

          } else {


          }
        }
      }


      items[id].bom_no = bomno

      console.log("Maniatest", items[id].bom_no)
      console.log("Tubebom", items)

      this.setState({
        orderaddmodel: items,
      });
    });


  }


  // handle_kitbom = (id, e) => {

  //   let bom = e.target.value

  //   var items = this.state.add_get_size
  //   console.log("Maibom", items)

  //   let custcode = ''
  //   if (localStorage.getItem('usertypecode') == 'CUST') {
  //     custcode = this.state.get_cust_code
  //   } else {
  //     custcode = this.state.customer_code

  //   }


  //   let get_pdate = e.target.value

  //   var myarray = bom.split('-');
  //   let pcode = ''
  //   let bomno = ''

  //   let gqty = ''
  //   let getpcode = items[id].product_code
  //   gqty = items[id].pi_qty
  //   let getpricedate = items[id].price_date

  //   for (var i = 0; i < myarray.length; i++) {
  //     pcode = myarray[0]
  //     bomno = myarray[1]
  //   }

  //   axios({
  //     method: "POST",
  //     url: this.state.baseurl + 'get_bom_list',//"http://localhost:3300/user_login",
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'token': localStorage.getItem('token')
  //     },
  //     data: {

  //       "product_code": pcode,
  //       "alt_bom_no": bomno
  //     }
  //   }).then((response) => {
  //     console.log("databomno", response.data.data)


  //     const getchildbom = response.data.data[0].product_code
  //     let parent_pcode = ''
  //     let pr_type = ''
  //     let item_pr_code = ''
  //     let item_id
  //     let from_date = ''
  //     let to_date = ''

  //     let par_price1 = 0;
  //     let chi_price1 = 0;



  //     for (var i = 0; i < items.length; i++) {
  //       parent_pcode = items[i].parent_product_code
  //       pr_type = items[i].product_type
  //       pcode = items[i].product_code
  //       item_id = items[i].id
  //       from_date = items[i].price_date
  //       to_date = items[i].price_date

  //       chi_price1 = parseFloat(items[i].price).toFixed(2)
  //       par_price1 = parseFloat(par_price1) + parseFloat(chi_price1)

  //       console.log("Getpricebom", par_price1)
  //       console.log("Manite", items)

  //       if (parent_pcode != '') {

  //         if (pr_type != 'Set') {
  //           console.log("Getmanibom9480403", getchildbom)

  //           let par_pcode = ''
  //           for (var p = 0; p < getchildbom.length; p++) {


  //             if (parent_pcode == getchildbom[p].product_code) {
  //               console.log("tube", getchildbom[p].component)
  //               par_pcode = getchildbom[p].product_code
  //               console.log("tube1231232", getchildbom[p].product_code)
  //               let bom = getchildbom[p].component
  //               console.log("Mnaites", bom[0])
  //               if (bom[0] != '3') {
  //                 if (pr_type == 'Tube') {
  //                   console.log("Tubemani", getchildbom[p].component)
  //                   items[i].product_code = getchildbom[p].component

  //                   const product_code = []

  //                   product_code.push({
  //                     "pcode": getchildbom[p].component
  //                   })




  //                   axios({
  //                     method: "POST",
  //                     url: this.state.baseurl + 'getprice',//"http://localhost:3300/user_login",
  //                     headers: {
  //                       'Content-Type': 'application/json',
  //                       'token': localStorage.getItem('token')
  //                     },
  //                     data: {
  //                       "valid_from": from_date,
  //                       "valid_to": to_date,
  //                       "customer_sap_code": custcode,
  //                       "product_code": product_code


  //                     }
  //                   }).then((response) => {
  //                     console.log("BomChange ", response.data.data)

  //                     if (response.data.status == "Success") {
  //                       console.log("Mani")
  //                       items[id].price = ''
  //                       const setprice = response.data.data
  //                       this.setState({
  //                         getprice_date: response.data.data[0], // you have it as this.data not response
  //                       });
  //                       let parent_pcode = ''
  //                       let pr_type = ''
  //                       let pcode = ''
  //                       let gqty = 0

  //                       for (var i = 0; i < items.length; i++) {
  //                         parent_pcode = items[i].parent_product_code
  //                         pr_type = items[i].product_type
  //                         pcode = items[i].product_code
  //                         gqty = items[i].pi_qty
  //                         console.log("Manite", pcode)



  //                         if (parent_pcode != '') {

  //                           if (pr_type != 'Set') {
  //                             for (var p = 0; p < setprice.length; p++) {


  //                               if (pcode == setprice[p].product_code) {
  //                                 items[i].price = setprice[p].price
  //                               } else {
  //                                 //items[i].price=  0.0

  //                               }
  //                             }

  //                           } else {

  //                             console.log("bomprice", items)
  //                             let par_price = 0;

  //                             let chi_price = 0

  //                             let getcode = ''
  //                             let final_price = 12.97
  //                             let get_tyre_price = 0

  //                             console.log("maniavarutha", par_pcode)

  //                             for (var b = 0; b < items.length; b++) {
  //                               console.log("Gettyreprice1212", par_pcode)
  //                               if (par_pcode == items[b].parent_product_code) {

  //                                 if (items[b].product_type == 'Tyre') {
  //                                   get_tyre_price = items[b].price
  //                                 }

  //                                 console.log("Gettyreprice", get_tyre_price)

  //                               }
  //                             }


  //                             for (var p = 0; p < setprice.length; p++) {
  //                               console.log("Setpcode", setprice[p].product_code + "," + pcode)
  //                               chi_price = parseFloat(setprice[p].price).toFixed(2)
  //                               par_price = parseFloat(par_price) + parseFloat(chi_price).toFixed(2)

  //                             }


  //                             let getcalprice = parseFloat(get_tyre_price) + parseFloat(par_price)

  //                             // let getbprice = parseFloat(get_tyre_price)+ parseFloat(chi_price)

  //                             // final_price  = parseFloat(final_price) + parseFloat(getbprice) 

  //                             console.log("Maniget", pcode + "====" + par_pcode)

  //                             if (pcode == par_pcode) {
  //                               items[i].price = getcalprice.toFixed(2)
  //                               console.log("par_pricemani", getcalprice.toFixed(2))
  //                             } else {
  //                               items[id].price = 0.0
  //                             }


  //                           }
  //                         } else {
  //                           items[id].price = response.data.data[0].price
  //                         }
  //                       }


  //                       let getvalue = (response.data.data[0].price * gqty).toFixed(2)

  //                       console.log("Manitestbom", getvalue)
  //                       items[id].pi_value = getvalue
  //                       this.setState({
  //                         orderaddmodel: items,
  //                       });
  //                     } else {
  //                       items[id].price = ''
  //                       const setprice = response.data.data
  //                       this.setState({
  //                         getprice_date: response.data.data[0], // you have it as this.data not response
  //                       });
  //                       let parent_pcode = ''
  //                       let pr_type = ''
  //                       let pcode = ''
  //                       for (var i = 0; i < items.length; i++) {
  //                         parent_pcode = items[i].parent_product_code
  //                         pr_type = items[i].product_type
  //                         pcode = items[i].product_code

  //                         console.log("Manite", pcode)

  //                         if (parent_pcode != '') {

  //                           if (pr_type != 'Set') {
  //                             for (var p = 0; p < setprice.length; p++) {


  //                               if (pcode == setprice[p].product_code) {
  //                                 items[i].price = 0.0
  //                               } else {
  //                                 //items[i].price=  0.0

  //                               }
  //                             }

  //                           } else {
  //                             let par_price = 0;
  //                             let chi_price = 0
  //                             console.log("Mniat", setprice)
  //                             for (var p = 0; p < setprice.length; p++) {
  //                               console.log("Setpcode", setprice[p].product_code + "," + pcode)

  //                               chi_price = parseFloat(setprice[p].price).toFixed(2)
  //                               par_price = parseFloat(par_price) + parseFloat(chi_price)

  //                               console.log("Manitestkit", pcode)

  //                               if (pcode == getchildbom[p].component) {
  //                                 items[i].price = par_price
  //                               }


  //                             }



  //                             console.log("par_price12121", par_price)

  //                           }
  //                         } else {
  //                           items[id].price = response.data.data[0].price
  //                         }
  //                       }
  //                     }

  //                   });

  //                 }
  //               } else {
  //                 items[i].product_code = getchildbom[p].component
  //               }

  //             }
  //           }

  //         } else {


  //         }
  //       }
  //     }


  //     items[id].bom_no = bomno

  //     console.log("Maniatest", items[id].bom_no)
  //     console.log("Tubebom", items)

  //     this.setState({
  //       orderaddmodel: items,
  //     });
  //   });


  // }


  handle_total_piqty_o = (id, e) => {
    console.log("Mani", e.target.value)

    const re = /^[0-9\b]+$/;
    let getvalue = 0
    if (e.target.value === '' || re.test(e.target.value)) {
      console.log("VALUE", e.target.value)

      getvalue = e.target.value
    }

    var items = this.state.add_get_size;
    console.log("Maniedit", items)
    let or_qty = '';
    let pcode = ''
    let piqt = ''
    let geprice = ''
    let container_qty = 0
    let total_pi = 0;
    let containercapper = 0
    let totalcontainercapper = 0;
    let noofcontiner = 0;
    let parent_pcode = ''
    let pr_type = ''
    let getpcode = items[id].product_code
    for (var i = 0; i < items.length; i++) {
      or_qty = items[i].order_qty
      pcode = items[i].product_code
      piqt = items[id].pi_qty
      geprice = items[id].price
      container_qty = items[id].container_capacity_qty
      parent_pcode = items[i].parent_product_code
      pr_type = items[id].product_type

      if (parent_pcode != '') {
        if (getpcode == parent_pcode) {
          console.log("manitest", parent_pcode)
          items[i].pi_qty = getvalue;
        } else {
          items[id].pi_qty = getvalue;
        }

      } else {
        items[id].pi_qty = getvalue;
      }
    }

    let bal_qty = or_qty - getvalue

    this.setState({
      pi_qty: getvalue
    })


    items[id].pi_qty = getvalue;


    var items = this.state.add_get_size;
    getpcode = items[id].product_code
    if (parent_pcode != '') {
      if (getpcode == parent_pcode) {
        items[id].pi_qty = getvalue;
      } else {

      }

    } else {

    }

    items[id].pi_qty = getvalue;



    if (geprice != '') {
      let getvalue = (geprice * e.target.value).toFixed(2)

      items[id].pi_value = getvalue
      items[id].balance_order_qty = ''
    } else {
      items[id].balance_order_qty = bal_qty
    }
    let volume_occupied = 0
    if (localStorage.getItem('segment') == '2WEXPORT') {
      console.log("Manicontainerqty123", container_qty)
      if (container_qty != 0.0 || container_qty != 0 || container_qty != undefined || container_qty != null) {
        volume_occupied = (((e.target.value * container_qty) / 150) * 100).toFixed(2);
      } else {
        volume_occupied = 0;
      }

    } else {
      console.log("Manicontainerqty", container_qty)
      if (container_qty != 0.0 || container_qty != 0) {
        volume_occupied = ((e.target.value / container_qty) * 100).toFixed(2);
      } else {
        volume_occupied = 0
      }
    }



    if (volume_occupied != '') {
      items[id].container_capacity_per = parseInt(volume_occupied)
    } else {
      items[id].container_capacity_per = 0
    }


    this.setState({
      add_get_size: items
    });

    this.calculation();
  }

  addtable = (todo) => {


    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }

    let price_get = ''
    axios({
      method: "POST",
      url: this.state.baseurl + 'getprice',//"http://localhost:3300/user_login",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "valid_from": this.state.price_date,
        "valid_to": this.state.price_date,
        "customer_sap_code": custcode,
        "product_code": todo.product_code,

      }
    }).then((response) => {
      console.log("dateprice", response.data.status)

      if (response.data.status == "Success") {
        this.setState({
          getprice_date: response.data.data[0], // you have it as this.data not response
        });
        price_get = response.data.data[0].price

        const item = {
          order_mmyyyy: this.state.month_year,
          order_no: this.state.order_number,
          order_version: this.state.order_version,
          customer_id: this.state.customer_id,
          customer_sap_code: custcode,
          notifyparty_sap_code: "",
          segment_code: "",
          segment_desc: todo.segment_desc,
          product_group: todo.product_group,
          product_code: todo.product_code,
          product_desc: todo.product_desc,
          price_date: this.state.price_date,
          uom: "EA",
          order_qty: todo.order_qty,
          pi_qty: todo.pi_qty,
          price: price_get,
          pi_value: todo.order_value,
          pi_confirmation_status: "",
          pi_confirmation_date: "",
          pi_approved_by_sm_status: "",
          pi_approved_by_sm_date: "",
          pi_approved_by_finance_status: "",
          pi_approved_by_finance_date: "",
          pi_sap_transfer_no: "",
          pi_sap_transfer_status: "",
          pi_number: "",
          pi_date: "",
          status: "",
          created_at: "",
          modified_at: "",
          total_pi_qty: todo.total_pi_qty,
          balance_order_qty: todo.balance_order_qty,
          container_capacity_qty: todo.container_capacity_qty,
          container_capacity_per: todo.container_capacity_per
          // order_value :,
        };

        console.log("Maniv", [item])
        this.setState({
          add_get_size: [...this.state.add_get_size, item],
        })
      } else {
        alert("This is item  price not available")
      }

    });

    console.log("Maniprice", price_get)



  }

  togglee() {

    console.log("Test")
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));

  }

  togglewish = () => {
    console.log("Manicust", this.state.customer_code)

    this.state.checked = '';
    this.state.find_search_code = '';
    this.state.find_search = '';
    if (this.state.customer_code == '') {
      alert("Please Select Customer Name")
    } else {

      this.setState(prevState => ({
        modalwish: !prevState.modalwish
      }));

      let custcode = ''
      if (localStorage.getItem('usertypecode') == 'CUST') {
        custcode = this.state.get_cust_code
      } else {
        custcode = this.state.customer_code

      }

      this.state.get_wishdesc = [];
      this.state.get_wishlist = [];

      axios({
        method: "POST",
        url: this.state.baseurl + 'wishlistdesc',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          customer_sap_code: custcode
        }
      }).then((response) => {
        console.log("Testwsishlist", response.data.data)
        this.setState({
          get_wishdesc: response.data.data, // you have it as this.data not response
        });
      });

      axios({
        method: "POST",
        url: this.state.baseurl + 'wishlist',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          customer_sap_code: custcode,
          user_type: localStorage.getItem('usertypecode')

        }
      }).then((response) => {
        console.log("Testwsishlist", response.data.data)
        this.setState({
          get_wishlist: response.data.data, // you have it as this.data not response
        });
      });
    }

  }
  toggleewish = () => {
    this.setState(prevState => ({
      modalwish: !prevState.modalwish
    }));
  }


  toggleitem = () => {

    if (this.state.customer_code == '') {
      alert("Please Select Customer Name")
    } else {

      if (this.state.container_size == '') {
        alert("Please Select Container Size")
      } else {
        const date = new Date();
        const futureDate = date.getDate();
        date.setDate(futureDate);
        const defaultValue = date.toLocaleDateString('en-CA');

        this.setState(prevState => ({
          modelitem: !prevState.modelitem,
          add_segment: '',
          add_pcode: '',
          add_pname: '',
          add_size: '',
          add_price: '',
          add_tqty: '',
          add_value: '',
          price_date: defaultValue

        }));
      }

    }


  }

  toggleeitem = () => {

    console.log("pricedate", this.state.add_get_size)
    if (this.state.add_price != '' && this.state.add_pname != "") {
      const item = {
        segment_desc: this.state.add_segment,
        product_group: this.state.add_size,
        product_desc: this.state.add_pname,
        product_code: this.state.add_pcode,
        price: this.state.add_price,
        price_date: this.state.price_date,
        order_qty: this.state.or_qty,
        pi_qty: this.state.add_tqty,
        balance_order_qty: this.state.add_bqty,
        pi_value: this.state.add_value,
        container_capacity_qty: this.state.container_capacity_qty,
        container_capacity_per: this.state.volume_occupied,
        kit_flag: "N",
        parent_product_code: "",
        product_type: "",
        bom_no: "0"
      };

      console.log("Manitest", [...this.state.add_get_size, item])
      //const getitem = [...this.state.add_get_size, item]
      const getitem = this.state.add_get_size
      let get_pcode
      let get_pr_date
      let errorpcode = ''
      let errorpdate = ''
      for (var j = 0; j < getitem.length; j++) {

        get_pcode = getitem[j].product_code
        get_pr_date = getitem[j].price_date

        if (getitem[j].kit_flag == "S") {

        } else {
          if (get_pcode == item.product_code) {
            errorpcode = getitem[j].product_code
          }
        }

        if (localStorage.getItem('segment') == 'OHT') {
          if (get_pr_date == this.state.price_date) {
            errorpdate = 'true'
          } else {
            errorpdate = 'false'
          }
        }



        console.log("getitem", errorpcode)
      }

      if (errorpcode != '') {
        alert("Already added this item." + errorpcode)
      } else {
       
        this.setState(prevState => ({
          modelitem: !prevState.modelitem,
          add_get_size: [...this.state.add_get_size, item],
        }));
        
        console.log("Maniadditem", [...this.state.add_get_size, item])
      
        if (localStorage.getItem('segment') == '2WEXPORT') {

          let total_pi = 0;
          let piqty = ''
          let containercapqty = 0;
          let containercapper = 0
          let totalcontainercapper = 0;
          let noofcontiner = 0;
          let containermaxcapacity = 0;
          let volume_occupied = ''
          let total_volume = 0;
          let containersizevolume = ''
          let getuom = ''
          const getitem = [...this.state.add_get_size, item]
          for (var i = 0; i < getitem.length; i++) {
            piqty = parseInt(getitem[i].pi_qty);
            getuom = getitem[i].product_type
            console.log("Test", piqty)
            containercapqty = getitem[i].container_capacity_qty;
            containermaxcapacity = getitem[i].container_capacity;
            if (getuom == 'Set' || getuom == null || getuom == '') {
              total_pi = total_pi + parseInt(piqty);
            } else {
              total_pi = total_pi;
            }
            // total_pi = total_pi + piqty;

            if (containercapqty != '0') {
              volume_occupied = ((piqty * containercapqty) / 150) * 100;
            }
            total_volume = total_volume + volume_occupied


            console.log("Totalpi", total_pi)

            this.state.volume_occupied = volume_occupied
          }
          console.log("size", this.state.container_size)

          if (this.state.container_size == '20 Feet') {
            containersizevolume = 2030.535
          }

          if (this.state.container_size == '40 Feet') {
            containersizevolume = 4113.135
          }

          if (this.state.container_size == '40 High Cube') {
            containersizevolume = 4113.135
          }


          let noofcontainer = ''

          noofcontainer = Math.ceil(total_volume / containersizevolume)


          console.log("Totalpi", total_pi)

          this.setState({
            displaycontainercapper: ((total_volume / containersizevolume) * 100).toFixed(0),
            get_conta: noofcontainer,
            get_tot: total_pi
          })

          this.state.displaycontainercapper = ((total_volume / containersizevolume) * 100).toFixed(0);


        } else {
          if (localStorage.getItem('segment') == 'OHT') {

            let total_pi = 0;
            let piqty = ''
            let containercapqty = 0;
            let containercapper = 0
            let totalcontainercapper = 0;
            let noofcontiner = 0;
            let containermaxcapacity = 0;
            let volume_occupied = ''
            let total_volume = 0;
            let containersizevolume = ''
            let getuom = ''

            //const getitem = [...this.state.add_get_size, item]
            const get_list_item =[...this.state.add_get_size, item]

            console.log("OHTCustLength", get_list_item)
            for (var i = 0; i < get_list_item.length; i++) {

              piqty = parseInt(get_list_item[i].pi_qty);
              console.log("OHTCusttestqty", piqty)
              getuom = get_list_item[i].product_type

              console.log("OHTCustGetuom", getuom)
              if (get_list_item[i].container_capacity_qty != '') {
                containercapqty = parseInt(get_list_item[i].container_capacity_qty);
              } else {
                containercapqty = '0'
              }
              console.log("OHTCustContainer", containercapqty)
              if (getuom == 'Set' || getuom == null || getuom == '') {

                total_pi = total_pi + parseInt(piqty);
                console.log("OhtCust", total_pi)
              } else {
                total_pi = total_pi;
              }
              // total_pi = total_pi + piqty;
              console.log("OHTCustTotalpi", total_pi)
              if (isNaN(piqty)) {
                piqty = 0.0
              }

              if (containercapqty != '0') {
                containercapper = (piqty / containercapqty) * 100;
                console.log("OHTCustOHT1", containercapper)
                totalcontainercapper = totalcontainercapper + containercapper;
              }

             

            }



            console.log("OHTCustNoofconta", totalcontainercapper)
            let noofcontainer = ''
            if (totalcontainercapper != '0' || totalcontainercapper != null) {
              //noofcontainer = Math.floor(totalcontainercapper / 100) + Math.floor((totalcontainercapper % 100) / 95);
              noofcontainer = totalcontainercapper / 100;
            } else {
              noofcontainer = ''
            }

            let get_totcontainerper = 0.0
            if (totalcontainercapper != 0) {
              get_totcontainerper = 100 - (totalcontainercapper % 100).toFixed(2)
            } else {
              get_totcontainerper = 0.0
            }

            //console.log("OHTCustEURO", get_totcontainerper)

            //this.state.displaycontainercapper = 100 - (totalcontainercapper % 100).toFixed(2);
            this.setState({
              displaycontainercapper: get_totcontainerper.toFixed(2),
              get_tot: total_pi,
              get_conta: (noofcontainer).toFixed(2)
            })
          } else {
            console.log("OHT", localStorage.getItem('segment'))
            let total_pi = 0;
            let piqty = this.state.add_tqty
            let containercapqty = this.state.get_container
            let containercapper = 0
            let totalcontainercapper = 0;
            let noofcontiner = 0;
            let getuom = ''
            let getcont = 0;
            const getitem = [...this.state.add_get_size, item]
            for (var i = 0; i < getitem.length; i++) {
              piqty = parseInt(getitem[i].pi_qty);
              getuom = getitem[i].product_type
              console.log("ADDITEMLIST", getitem[i].pi_qty)
              if (getitem[i].container_capacity_qty != '') {
                containercapqty = parseInt(getitem[i].container_capacity_qty);
              } else {
                containercapqty = '0'
              }

              if (getuom == 'Set' || getuom == null || getuom == '') {
                total_pi = total_pi + parseInt(piqty);
              } else {
                total_pi = total_pi;
              }
              //total_pi = total_pi + piqty;

              if (containercapqty != '0') {
                containercapper = (piqty / containercapqty) * 100;
              }

              if (getuom == 'Set' || getuom == 'Tyre' || getuom == 'Tube') {
                getcont = getcont + containercapper

                console.log("", getcont / getcont * 100)
                totalcontainercapper = (getcont / getcont * 100);

              } else {
                totalcontainercapper = totalcontainercapper + containercapper;
              }
              // totalcontainercapper = totalcontainercapper + containercapper;
            }

            let noofcontainer = ''
            if (totalcontainercapper != '0' || totalcontainercapper != null) {
              //noofcontainer = Math.floor(totalcontainercapper / 100) + Math.floor((totalcontainercapper % 100) / 95);
              noofcontainer = totalcontainercapper / 100;
            } else {
              noofcontainer = ''
            }
            let gettot_container = 0.0
            if (totalcontainercapper != 0) {
              gettot_container = 100 - (totalcontainercapper % 100).toFixed(2)
            } else {
              gettot_container = 0.0
            }
            // this.state.displaycontainercapper = 100 - (totalcontainercapper % 100).toFixed(2);
            this.setState({
              displaycontainercapper: gettot_container.toFixed(2),
              get_tot: total_pi,
              get_conta: (noofcontainer).toFixed(2)
            })
          }
        }
      }

    } else if (this.state.add_pname == "") {
      alert("Check the Product Code")
    } else {
      if (localStorage.getItem('usertypecode') == 'CUST') {
        alert("Price not available")
      } else {
        alert("Price not available  for selected date ")
      }
    }



  }


  toggleeitemclose = () => {
    this.setState(prevState => ({
      modelitem: !prevState.modelitem,
    }));
  }




  togglekititem = () => {

    if (this.state.customer_code == '') {
      alert("Please Select Customer Name")
    } else {

      if (this.state.container_size == '') {
        alert("Please Select Container Size")
      } else {
        const date = new Date();
        const futureDate = date.getDate();
        date.setDate(futureDate);
        const defaultValue = date.toLocaleDateString('en-CA');

        this.setState(prevState => ({
          modelkititem: !prevState.modelkititem,
          getkitcode: [],
          getkitqty: '',
          getkitpcode: '',
          getkitpdesc: '',
          getkitpricedate: defaultValue

        }));
      }

    }


  }

  toggleekititem = () => {

    console.log("Manite", this.state.getkitcode)

    console.log("kitmanifirst", this.state.add_get_size)

    this.state.getlist = []

    const getkit = this.state.getkitcode;
    let segment_desc = ''
    let product_group = ''
    let product_desc = ''
    let product_code = ''
    let price = ''
    let price_date = ''
    let order_qty = ''
    let pi_qty = ''
    let balance_order_qty = ''
    let pi_value = ''
    let container_capacity_qty = ''
    let container_capacity_per = ''
    let kit_flag = ''
    let parent_product_code = ''
    let product_type = ''
    let bom_no = '0'
    const bom_no1 = []
    const getlist = []
    let get_oht_pr = ''

    const listkit = ''

    for (var i = 0; i < getkit.length; i++) {
      segment_desc = getkit[i].segment_desc
      product_group = getkit[i].product_group
      product_desc = getkit[i].product_desc
      product_code = getkit[i].product_code
      price = getkit[i].price
      price_date = getkit[i].price_date
      order_qty = "0"
      pi_qty = getkit[i].qty
      balance_order_qty = 0
      pi_value = getkit[i].amount
      container_capacity_qty = getkit[i].container_qty
      container_capacity_per = 0
      kit_flag = 'S'
      parent_product_code = getkit[i].parent_product_code
      product_type = getkit[i].product_type
      bom_no = "0"
      let wi_pcode = ''
      const getbom = []


      wi_pcode = getkit[i].product_type.product_code

      const item = {
        segment_desc: segment_desc,
        product_group: product_group,
        product_desc: product_desc,
        product_code: product_code,
        price: price,
        price_date: price_date,
        order_qty: "0",
        pi_qty: pi_qty,
        balance_order_qty: 0,
        pi_value: pi_value,
        container_capacity_qty: container_capacity_qty,
        container_capacity_per: 0,
        kit_flag: 'S',
        parent_product_code: parent_product_code,
        product_type: product_type,
        bom_no: "0",
        bom_no1: []
      };


      getlist.push({
        segment_desc: segment_desc,
        product_group: product_group,
        product_desc: product_desc,
        product_code: product_code,
        price: price,
        price_date: price_date,
        bom_no: "0",
        bom_no1: "",
        pi_qty: pi_qty,
        pi_value: pi_value,
        container_capacity_qty: container_capacity_qty,
        container_capacity_per: container_capacity_per,
        product_type: product_type,
        parent_product_code: parent_product_code
      })


      var items = getlist

      if (product_type == 'Set') {
        wi_pcode = product_code

        let bomno1
        let bomno = ''
        axios({
          method: "POST",
          url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            'product_code': product_code,
          }
        }).then((response) => {
          const getbomkit = []
          let kibom = response.data.data;

          var kitbom = response.data.data
          let item_pcode = ''
          let kit_pcode = ''

          for (var b = 0; b < kitbom.length; b++) {
            getbomkit.push({
              // product_code :kitbom[b].product_code,
              alt_bom_no: kitbom[b].alt_bom_no
            })
          }
          this.setState({
            wish_kit_bom: getbomkit
          })

          for (var k = 0; k < kitbom.length; k++) {
            kit_pcode = kitbom[k].product_code

            for (var b = 0; b < items.length; b++) {
              item_pcode = items[b].product_code
              console.log("Testkit", item_pcode)
              if (kit_pcode == item_pcode) {

                bomno1 = response.data.data
                bomno = response.data.data[0].alt_bom_no

                this.state.bomn1 = response.data.data
              }


            }

          }
          items[0].bom_no1 = bomno1
          items[0].bom_no = bomno


          item.bom_no = bomno
          item.bom_no1 = bomno1

          this.setState({
            kit_grid_item: getlist
          })
        });
        console.log("kitmani", item)
      } else {

      }


      console.log("gridkit", getlist)
      const getitem = this.state.add_get_size
      console.log("KITITEM", this.state.add_get_size)
      let get_pcode = ''
      let errorpcode = ''
      let get_par_code = ''
      for (var j = 0; j < getitem.length; j++) {
        get_pcode = getitem[j].product_code
        get_par_code = getitem[j].parent_product_code
        if (get_pcode == item.product_code && get_par_code == item.parent_product_code) {
          errorpcode = getitem[j].product_code
        }
        console.log("getitem", errorpcode)
      }

      if (errorpcode != '') {
        alert("Already added this kit item." + errorpcode)
      } else {

        // if(localStorage.getItem('segment')=='OHT'){
        //   if(this.state.price_date == price_date){
        //     this.state.add_get_size = [...this.state.add_get_size, item]
        //   }else{
        //     alert("Price not avaliable for  Current Date")
        //   }
        // }else{
        this.state.add_get_size = [...this.state.add_get_size, item]
        // }


      }

    }




    if (get_oht_pr == 'true') {

    }

    this.setState(prevState => ({
      modelkititem: !prevState.modelkititem,
    }));

    // const d1 = Date.parse(this.state.price_date);
    // const d2 = Date.parse(price_date);

    // if (d1 == d2) {
    //   console.log("Error!");
    // }else{
    //   console.log("manite")
    // }



    // if (localStorage.getItem('segment') == '2WEXPORT') {

    //   let total_pi = 0;
    //   let piqty = ''
    //   let containercapqty = 0;
    //   let containercapper = 0
    //   let totalcontainercapper = 0;
    //   let noofcontiner = 0;
    //   let containermaxcapacity = 0;
    //   let volume_occupied = ''
    //   let total_volume = 0;
    //   let containersizevolume = ''
    //   const getitem = this.state.add_get_size
    //   let getuom =''
    //   for (var i = 0; i < getitem.length; i++) {
    //     piqty = parseInt(getitem[i].pi_qty);
    //     getuom = getitem[i].product_type
    //     console.log("Test", piqty)
    //     containercapqty = getitem[i].container_capacity_qty;
    //     containermaxcapacity = getitem[i].container_capacity;
    //     if(getuom == 'Set' || getuom == null ||getuom == ''  ){
    //       total_pi = total_pi + parseInt(piqty);
    //     }else{
    //       total_pi = total_pi ;
    //     }


    //     if (containercapqty != '0') {
    //       volume_occupied = ((piqty * containercapqty) / 150) * 100;
    //     } else {
    //       volume_occupied = 0
    //     }
    //     total_volume = total_volume + volume_occupied


    //     console.log("Totalpi", total_pi)

    //     getitem[i].container_capacity_per = volume_occupied
    //   }



    //   console.log("size", this.state.container_size)

    //   if (this.state.container_size == '20 Feet') {
    //     containersizevolume = 2030.535
    //   }

    //   if (this.state.container_size == '40 Feet') {
    //     containersizevolume = 4113.135
    //   }

    //   if (this.state.container_size == '40 High Cube') {
    //     containersizevolume = 4113.135
    //   }


    //   let noofcontainer = ''

    //   noofcontainer = Math.ceil(total_volume / containersizevolume)




    //   this.setState({
    //     displaycontainercapper: ((total_volume / containersizevolume) * 100).toFixed(0),
    //     get_conta: noofcontainer,
    //     get_tot: total_pi
    //   })

    //   this.state.displaycontainercapper = ((total_volume / containersizevolume) * 100).toFixed(0);


    // } else {

    //   let total_pi = 0;
    //   let piqty = this.state.add_tqty
    //   let containercapqty = this.state.get_container
    //   let containercapper = 0
    //   let totalcontainercapper = 0;
    //   let noofcontiner = 0;
    //   let volume_occupied = ''
    //   let total_volume = 0;
    //   let containersizevolume = ''
    //   let getuom =''
    //   const getitem = this.state.add_get_size
    //   for (var i = 0; i < getitem.length; i++) {
    //     piqty = parseInt(getitem[i].pi_qty);
    //     getuom = getitem[i].product_type
    //     console.log("ADDITEMLIST", getitem[i].container_capacity_qty)
    //     if (getitem[i].container_capacity_qty != '') {
    //       containercapqty = parseInt(getitem[i].container_capacity_qty);
    //     } else {
    //       containercapqty = '0'
    //     }
    //     if(getuom == 'Set' || getuom == null ||getuom == ''  ){
    //       total_pi = total_pi + parseInt(piqty);
    //     }else{
    //       total_pi = total_pi ;
    //     }

    //     if (containercapqty != '0') {
    //       containercapper = (piqty / containercapqty) * 100;
    //     }

    //     totalcontainercapper = totalcontainercapper + containercapper;

    //     if (containercapqty != '0') {
    //       volume_occupied = (((piqty * containercapqty) / 150) * 100).toFixed(2);
    //     } else {
    //       volume_occupied = 0
    //     }
    //     total_volume = total_volume + volume_occupied


    //     console.log("Totalpi", volume_occupied)

    //     getitem[i].container_capacity_per = volume_occupied

    //   }



    //   let noofcontainer = ''
    //   if (totalcontainercapper != '0' || totalcontainercapper != null) {
    //     noofcontainer = Math.floor(totalcontainercapper / 100) + Math.floor((totalcontainercapper % 100) / 95);
    //   } else {
    //     noofcontainer = ''
    //   }
    //   this.state.displaycontainercapper = (totalcontainercapper % 100).toFixed(2);
    //   this.setState({
    //     displaycontainercapper: (totalcontainercapper % 100).toFixed(2),
    //     get_tot: total_pi,
    //     get_conta: noofcontainer
    //   })
    // }

    this.calculation()

  }

  toggleekititemclose = () => {
    this.setState(prevState => ({
      modelkititem: !prevState.modelkititem,
    }));
  }
  toggleview = () => {

    // var base_url = this.state.baseurl + localStorage.getItem('segment')
    // alert(base_url)
    this.setState({
      pageNumber: 1
    });

    axios({
      method: "POST",
      url: this.state.baseurl + 'uploadshowprepi',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "order_no": this.state.order_number,
        "order_version": this.state.order_version.toString()
      }
    }).then((response) => {
      console.log("Test1232mani", response.data.data[0].title)
      this.setState({
        file_title: response.data.data[0].title,
        // you have it as this.data not response
      });
    });

    this.setState(prevState => ({
      modelpdf: !prevState.modelpdf,
      //viewpdf: pdfv
    }));


  }

  toggleeview = () => {
    this.setState(prevState => ({
      modelpdf: !prevState.modelpdf
    }));
  }

  toggleeproduct = () => {
    this.setState(prevState => ({
      product_list: !prevState.product_list
    }));
  }

  toggleepadd = () => {
    this.setState(prevState => ({
      product_list: !prevState.product_list
    }));
  }

  toggleepclose = () => {
    this.setState(prevState => ({
      product_list: !prevState.product_list
    }));
  }



  toggleeproductview = () => {
    this.setState(prevState => ({
      product_view_model: !prevState.product_view_model
    }));
  }

  toggleepv_add = () => {
    this.setState(prevState => ({
      product_view_model: !prevState.product_view_model
    }));
  }

  toggleepv_close = () => {
    this.setState(prevState => ({
      product_view_model: !prevState.product_view_model
    }));
  }

  // kitgrid (){

  //   for (var k =0; k<this.state.kit_grid_item.length ;k ++){
  //     const item = {
  //       segment_desc:this.statekit_grid_item[k].segment_desc,
  //       product_group: this.statekit_grid_item[k].product_group,
  //       product_desc: this.statekit_grid_item[k].product_desc,
  //       product_code: this.statekit_grid_item[k].product_code,
  //       price: this.statekit_grid_item[k].price,
  //       price_date: this.statekit_grid_item[k].price_date,
  //       order_qty: "0",
  //       pi_qty: this.statekit_grid_item[k].pi_qty,
  //       balance_order_qty: 0,
  //       pi_value: this.statekit_grid_item[k].pi_value,
  //       container_capacity_qty: this.statekit_grid_item[k].container_capacity_qty,
  //       container_capacity_per: 0,
  //       kit_flag: 'S',
  //       parent_product_code:this.statekit_grid_item[k].parent_product_code,
  //       product_type:this.statekit_grid_item[k].product_type,
  //       bom_no:this.statekit_grid_item[k].bom_no,
  //       bom_no1:this.statekit_grid_item[k].bom_no1
  //     };


  //     const getitem = this.state.add_get_size
  //     console.log("KITITEM", this.state.add_get_size)
  //     let get_pcode =''
  //     let errorpcode = ''
  //     let get_par_code =''
  //     for (var j = 0; j < getitem.length; j++) {
  //       get_pcode = getitem[j].product_code
  //       get_par_code = getitem[j].parent_product_code
  //           if (get_pcode == item.product_code  && get_par_code == item.parent_product_code ) {
  //               errorpcode = getitem[j].product_code
  //           }
  //       console.log("getitem", errorpcode)
  //     }

  //     if (errorpcode != '') {
  //       alert("Already added this kit item." + errorpcode)
  //     } else {
  //       this.state.add_get_size = [...this.state.add_get_size, item]
  //     } 

  //   }
  // }




  render() {
    const { get_cu_details = [], get_order = [], orderaddmodel = [], get_wishlist = [], get_wishlistview = [], getkit_product_list = [], getkitcode = [] } = this.state

    const { get_order_no = [], get_order_verion = [], order_add = [], orderadd = [], add_get_size = [], load_size, file_title } = this.state

    const { getproduct_code = [], get_price_date_list = [], wish_kit_bom = [], kit_grid_item = [] } = this.state;

    const { pageNumber, numPages } = this.state;


    console.log("Maniapproveal", this.state.piapprovedstatus)

    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    console.log("Manite", this.state.piapprovedstatus)

    const price_date = []

    for (var p = 0; p < get_price_date_list.length; p++) {
      price_date.push({
        valid_from: moment(get_price_date_list[p].valid_from).format('YYYY-MM-DD')
      })
    }


    let gettotalvalue = 0.0

    for (var t = 0; t < add_get_size.length; t++) {
      let get_pivalue = parseFloat(add_get_size[t].pi_value);

      if (add_get_size[t].product_type != 'Tube' && add_get_size[t].product_type != 'Tyre' && add_get_size[t].product_type != 'Flap') {
        gettotalvalue = (gettotalvalue + get_pivalue)
      } else {
        gettotalvalue = gettotalvalue + 0.0;
      }


    }

    console.log("Manitotal", gettotalvalue.toFixed(2))



    const orderget = [];
    const versionget = [];

    const manisegment = ['Mani']

    let remark = this.state.remarkes;

    console.log("sonumber123", remark)

    let toastshow = ""
    if (remark != '' && remark != null) {
      toastshow = this.state.remarkes
    } else {
      toastshow = this.state.sonumber
    }


    console.log("sonumber", toastshow)




    const getlist_notify = this.state.notifymaster.filter((item, index) => this.state.notifymaster.indexOf(item) === index)


    console.log("notify", getlist_notify.reverse())

    const getlistorder = get_order_no.filter((item, index) => get_order_no.indexOf(item) === index)


    console.log("Orderno", getlistorder.reverse())

    for (var i = 0; i < getlistorder.length; i++) {

      orderget.push({ id: i + 1, or_no: getlistorder[i] })
    }



    for (var k = 0; k < get_order_verion.length; k++) {

      versionget.push({ id: k + 1, version: get_order_verion[k] })
    }

    const product_code = []


    const style = { width: "355px", marginTop: 3, height: 35, color: "#070662" };

    this.state.pistatuserror = { width: "180px", fontWeight: 'bold' }
    if (this.state.action_status == 'PI Error') {
      this.state.pistatuserror = { width: "180px", fontWeight: 'bold', color: 'Red' }
    }




    var pdf_urlpath = process.env.REACT_APP_API_URL_FILE + "download_documents?filename=assets/uploads/PIPDF/"; //"http://10.10.1.192:32501/download_documents?filename=assets/uploads/PIPDF/"

    console.log("document_url", pdf_urlpath + file_title)

    const { isChecked } = this.state;

    const pdfFile = '';

    return (
      <div>

        <Modal backdrop={false} style={{ maxWidth: '1200px', width: '100%' }} isOpen={this.state.modalcust} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Alert  </ModalHeader>
          <ModalBody>


          </ModalBody>
          <ModalFooter>
            <Button onClick={this.togglee} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>
        <Modal backdrop={false} style={{ maxWidth: '1200px', width: '100%' }} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Get Order  </ModalHeader>
          <ModalBody>
            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">
                <Form.Label style={{ marginLeft: 15 }} className="name-label">Size Term   </Form.Label>

                <input className="form-control" required="required"
                  style={{ width: "250px", marginLeft: 15 }}
                  placeholder="Enter size"
                  type="text" name="setup_name" onChange={this.handle_order_size.bind(this)}
                  value={this.state.model_size} />
                {/* <Typeahead
                         id="exampleSelect" 
                         style={{width: "200px" ,marginLeft:15}}
                        selected={this.selected}
                        onChange={this.handle_size.bind(this)}
                        value={this.state.model_size}
                        labelKey={option => `${option.product_group} `}
                        options={this.state.get_order_s}
                        placeholder="Select a Container..."
                      />         */}
              </Col>
            </Row>
            <div className="table" style={{ width: "100%", display: "inline-table", margin: "15px", backgroundColor: "#fff" }}>
              <Table striped bordered responsive="sm">
                <thead style={{ backgroundColor: "#413c69", color: "#ffffff" }}>
                  <tr>
                    <th style={{ width: "20px" }}>#</th>
                    <th style={{ width: "200px" }}>Segment</th>
                    <th style={{ width: "100px" }}>Size</th>
                    <th style={{ width: "400px" }}>Product Name</th>
                    <th style={{ width: "200px" }}>Product Code</th>
                    {/* <th style={{width: "200px"}}>Price Date</th> */}
                    {/* <th style={{width: "150px"}}>Price</th> */}
                    <th style={{ width: "150px" }}>Order Qty</th>
                    <th style={{ width: "50px" }}> PI Qty</th>
                    <th style={{ width: "100px" }}>Balance Qty</th>
                    <th style={{ width: "150px" }}>Value</th>
                    <th style={{ width: "70px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {orderaddmodel.length ?
                    orderaddmodel.map((todo, id) => (
                      <tr key={id}>
                        <th>{id + 1}</th>
                        <th>{todo.segment_desc}</th>
                        <th>{todo.product_group}</th>
                        <th>{todo.product_desc}</th>
                        <th>{todo.product_code}</th>
                        {/* <th> <input  className="form-control" required="required"   
                                type="date" name="setup_name" onChange={this.handle_pricedate_g.bind(this, id)} 
                                value={this.state.price_date}/>  </th> */}
                        {/* <th>{todo.price}</th> */}
                        <th>{todo.order_qty}</th>
                        <th >
                          <input
                            className="form-control-alternative"
                            style={{ width: 50 }}
                            id={"exampleSelect" + id}
                            onChange={this.handle_total_piqty.bind(this, id)}
                            value={this.state.orderaddmodel[id].pi_qty}
                            placeholder="Enter PI qty"
                            maxlength="7"
                            type="text"
                          />

                        </th>
                        <th>{todo.balance_order_qty}  </th>
                        <th>{todo.order_value}</th>
                        <th> <input onClick={() => this.addtable(todo)} style={{ marginLeft: 15, marginTop: 25 }} type="checkbox" /></th>
                      </tr>
                    ))
                    :
                    (<tr>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                    </tr>)
                  }


                </tbody>
              </Table>
            </div>



          </ModalBody>
          <ModalFooter>
            <Button onClick={this.togglee} color="primary"  >Add</Button>{' '}
            <Button onClick={this.togglee} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>

        <Modal backdrop={false} style={{ maxWidth: '850px', width: '100%' }} isOpen={this.state.modelitem} toggle={this.toggleitem}>
          <ModalHeader toggle={this.toggleitem}>Add Item   </ModalHeader>
          <ModalBody>
            <Row>

              <Col className="text-left">
                <Form.Label className="name-label"> Find Product Code</Form.Label>
                <Typeahead
                  id={"exampleSelect"}
                  selected={this.selected}
                  onChange={this.handle_getproduct_code.bind(this)}
                  value=''
                  labelKey={option => `${option.product_code} `}
                  options={this.state.getproduct_code}
                  placeholder="Select a product code..."
                />
              </Col>
              <Col className="text-left">

                <Form.Label className="name-label"> Find Product Name</Form.Label>
                <Typeahead
                  id={"exampleSelect"}
                  selected={this.selected}
                  onChange={this.handle_getproduct_name.bind(this)}
                  value=''
                  labelKey={option => `${option.product_desc} `}
                  options={this.state.getproduct_code}
                  placeholder="Select a product Name..."
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">
                <Form.Label className="name-label"> Segment</Form.Label>
                <input
                  className="form-control"
                  id={"exampleSelect"}
                  value={this.state.add_segment}
                  placeholder=""
                  readOnly={true}
                  type="text">
                </input>


              </Col>
              <Col className="text-left">
                <Form.Label className="name-label"> Size</Form.Label>

                <input
                  className="form-control"
                  id={"exampleSelect"}
                  value={this.state.add_size}
                  placeholder=""
                  readOnly={true}
                  type="text">
                </input>

              </Col>
            </Row>

            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">
                <Form.Label className="name-label">  Product Name</Form.Label>
                <input
                  className="form-control"
                  id={"exampleSelect"}
                  value={this.state.add_pname}
                  placeholder=""
                  readOnly={true}
                  type="text">
                </input>

              </Col>
              <Col className="text-left">
                <Form.Label className="name-label">Product Code</Form.Label>
                <input
                  className="form-control"
                  id={"exampleSelect"}
                  value={this.state.add_pcode}
                  placeholder=""
                  readOnly={true}
                  type="text">
                </input>
              </Col>

            </Row>


            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">
                <Form.Label className="name-label">   Price date</Form.Label>
                {localStorage.getItem('usertypecode') == 'CUST' ? (<>
                  <input
                    className="form-control"
                    id={"exampleSelect"}
                    readOnly={true}
                    onChange={this.handle_dateprice.bind(this)}
                    value={this.state.price_date}
                    placeholder=""
                    type="date">
                  </input>
                </>) : (<>


                  <select className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`}
                    value={this.state.price_date} onChange={this.handle_dateprice.bind(this)}>
                    {price_date.length ?
                      price_date.map((todo, id) => (
                        <option value={todo.valid_from}>{todo.valid_from}</option>
                      ))
                      :
                      (<option></option>)}
                  </select>
                  {/* <input
                    className="form-control"
                    id={"exampleSelect"}
                    onChange={this.handle_dateprice.bind(this)}
                    value={this.state.price_date}
                    placeholder=""
                    type="date">
                  </input> */}
                </>)}

              </Col>
              <Col className="text-left">
                <Form.Label className="name-label">   Price</Form.Label>
                <input
                  className="form-control"
                  id={"exampleSelect"}
                  value={this.state.add_price}
                  placeholder=""
                  readOnly={true}
                  type="text">
                </input>
              </Col>

            </Row>

            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>

              <Col className="text-left">
                <Form.Label className="name-label"> PI Qty</Form.Label>
                <input
                  className="form-control"
                  id={"exampleSelect"}
                  onChange={this.handle_tqty.bind(this)}
                  value={this.state.add_tqty}
                  placeholder=""
                  maxlength="7"
                  type="number">
                </input>

              </Col>
              <Col className="text-left">
                <Form.Label className="name-label"> Monthly Plan Qty</Form.Label>
                <input
                  className="form-control"
                  id={"exampleSelect"}
                  readOnly={true}
                  onChange={this.handle_qty.bind(this)}
                  value={this.state.order_qty}
                  placeholder="Enter Qty"
                  type="text">
                </input>
              </Col>

            </Row>
            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>

              <Col className="text-left">
                <Form.Label className="name-label">Balance Qty</Form.Label>
                <input
                  className="form-control"
                  id={"exampleSelect"}
                  value={this.state.add_bqty}
                  placeholder=""
                  readOnly={true}
                  type="text">
                </input>
              </Col>
              <Col className="text-left">
                <div style={{ width: "390px", marginLeft: 20 }} className="text-left">
                  <Form.Label className="name-label">  Value</Form.Label>
                  <input
                    className="form-control"
                    id={"exampleSelect"}
                    value={this.state.add_value}
                    placeholder=""
                    readOnly={true}
                    type="text">
                  </input>
                </div>
              </Col>
            </Row>

          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleeitem} color="primary"  >Add</Button>{' '}
            <Button onClick={this.toggleeitemclose} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>

        <Modal backdrop={false} style={{ maxWidth: '1300px', width: '100%' }} isOpen={this.state.modelkititem} toggle={this.togglekititem}>
          <ModalHeader toggle={this.togglekititem}>Add kit Item   </ModalHeader>
          <ModalBody>
            <Row>

              <Col className="text-left">
                <Form.Label className="name-label"> Find Product Code</Form.Label>
                <Typeahead
                  id={"exampleSelect"}
                  onChange={this.handle_getkitproduct_code.bind(this)}
                  labelKey={option => `${option.product_code} `}
                  options={this.state.getkit_product_list}
                  placeholder="Select a product code..."
                />
              </Col>
              <Col className="text-left">

                <Form.Label className="name-label">  Product Name</Form.Label>
                <input
                  className="form-control"
                  id={"exampleSelect"}
                  value={this.state.getkitpdesc}
                  placeholder=""
                  readOnly={true}
                  type="text">
                </input>
              </Col>
              {/* <Col className="text-left">
              <Form.Label className="name-label"> Bom No</Form.Label>
              <select className={`form-control `}
                    style ={{width: 60}}
                    value={this.state.select_bom} onChange={this.handle_bomno}>
                    <option>Select</option>
                    {this.state.get_bom_no.length ?
                      this.state.get_bom_no.map((todo, id) => (
                        <option value={todo.alt_bom_no}>{todo.alt_bom_no}</option>
                      ))
                      :
                      (<option></option>)}
                  </select>
              </Col> */}
              <Col className="text-left">
                <Form.Label className="name-label"> Qty</Form.Label>
                <input
                  className="form-control"
                  id={"exampleSelect"}
                  onChange={this.handle_getkitqty.bind(this)}
                  value={this.state.getkitqty}
                  placeholder=""
                  type="number">
                </input>
              </Col>
              <Col className="text-left">
                {localStorage.getItem('usertypecode') == 'CUST' ? (<>
                  <Form.Label className="name-label"> Price Date</Form.Label>
                  <input
                    className="form-control"
                    id={"exampleSelect"}
                    onChange={this.handle_getdateprice.bind(this)}
                    value={this.state.getkitpricedate}
                    placeholder=""
                    readOnly={true}
                    type="date">
                  </input>
                </>) : (<>
                  <Form.Label className="name-label"> Price Date</Form.Label>
                  <input
                    className="form-control"
                    id={"exampleSelect"}
                    onChange={this.handle_getdateprice.bind(this)}
                    value={this.state.getkitpricedate}
                    placeholder=""
                    type="date">
                  </input>
                </>)}

              </Col>
              <Col className="text-left">
                <Button style={{ marginTop: 30 }} onClick={this.handlekitadd} color="primary"  >submit</Button>{' '}
              </Col>
              <Col></Col>
            </Row>

            <div className="table" style={{ maxWidth: '1200px', width: '100%', height: '300px', overflow: 'auto' }}>
              <Table striped bordered responsive="sm">
                <thead style={{ backgroundColor: "#413c69", color: "#ffffff" }}>
                  <tr>
                    <th style={{ width: "20px" }}>#</th>
                    <th >Tyre Code</th>
                    <th >Tyre Desc</th>
                    <th >Price Date</th>
                    <th >Price</th>
                    <th >Qty</th>
                    <th >Value</th>

                  </tr>
                </thead>
                <tbody>
                  {getkitcode.length ?
                    getkitcode.map((todo, id) => (
                      <tr key={id}>
                        <th>{id + 1}</th>
                        <th>{todo.product_code}</th>
                        <th>{todo.product_desc}</th>
                        <th>{todo.price_date}  </th>
                        <th>{todo.price}</th>
                        <th>{todo.qty}</th>
                        <th>{todo.amount}</th>
                      </tr>
                    ))
                    :
                    (<tr>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                    </tr>)
                  }

                </tbody>
              </Table>
            </div>

            {/* <Row>
              <Col className="text-left">
                <Form.Label className="name-label"> Change Tube Code</Form.Label>
                <Typeahead
                  id={"exampleSelect"}
                  selected={this.selected}
                  style={{ width: 300 }}
                  onChange={this.handle_getkitproduct_tube.bind(this)}
                  value=''
                  labelKey={option => `${option.product_code} `}
                  options={this.state.getkit_product_tube}
                  placeholder="Select a product code..."
                />
              </Col>
              <Col className="text-left">
                <Button style={{ marginTop: 30 }} onClick={this.handlechagetube} color="primary"  >Change</Button>{' '}
              </Col>
            </Row> */}

          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleekititem} color="primary"  >Add</Button>{' '}
            <Button onClick={this.toggleekititemclose} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>

        {/* <Modal style={{ maxWidth: '1000px', width: '100%' }} isOpen={this.state.modelkititem} toggle={this.togglekititem}>
          <ModalHeader toggle={this.togglekititem}>Add kit Item   </ModalHeader>
          <ModalBody>
            <Row>

              <Col className="text-left">
                <Form.Label className="name-label"> Find Product Code</Form.Label>
                <Typeahead
                  id={"exampleSelect"}
                  onChange={this.handle_getkitproduct_code.bind(this)}
                  labelKey={option => `${option.product_code} `}
                  options={this.state.getkit_product_list}
                  placeholder="Select a product code..."
                />
              </Col>
              <Col className="text-left">

                <Form.Label className="name-label"> Find Product Name</Form.Label>
                <Typeahead
                  id={"exampleSelectname"}
                  selected={this.selected}
                  onChange={this.handle_getkitproduct_name.bind(this)}
                  labelKey={option => `${option.product_desc} `}
                  options={this.state.getkit_product_desc}
                  placeholder="Select a product Name..."
                />
              </Col>
              <Col className="text-left">
                <Form.Label className="name-label"> Qty</Form.Label>
                <input
                  className="form-control"
                  id={"exampleSelect"}
                  onChange={this.handle_getkitqty.bind(this)}
                  value={this.state.getkitqty}
                  placeholder=""
                  type="number">
                </input>
              </Col>
              <Col className="text-left">

                <Form.Label className="name-label"> Price Date</Form.Label>
                <input
                  className="form-control"
                  id={"exampleSelect"}
                  onChange={this.handle_getdateprice.bind(this)}
                  value={this.state.getkitpricedate}
                  placeholder=""
                  type="date">
                </input>
              </Col>
              <Col className="text-left">
                <Button style={{ marginTop: 30 }} onClick={this.handlekitadd} color="primary"  >submit</Button>{' '}
              </Col>
            </Row>

            <div className="table" style={{ maxWidth: '1200px', width: '100%', height: '300px', overflow: 'auto' }}>
              <Table striped bordered responsive="sm">
                <thead style={{ backgroundColor: "#413c69", color: "#ffffff" }}>
                  <tr>
                    <th style={{ width: "20px" }}>#</th>
                    <th >Tyre Code</th>
                    <th >Tyre Desc</th>
                    <th >Price Date</th>
                    <th >Price</th>
                    <th >Qty</th>
                    <th >Value</th>

                  </tr>
                </thead>
                <tbody>
                  {getkitcode.length ?
                    getkitcode.map((todo, id) => (
                      <tr key={id}>
                        <th>{id + 1}</th>
                        <th>{todo.product_code}</th>
                        <th>{todo.product_desc}</th>
                        <th>{todo.price_date}  </th>
                        <th>{todo.price}</th>
                        <th>{todo.qty}</th>
                        <th>{todo.amount}</th>
                      </tr>
                    ))
                    :
                    (<tr>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                    </tr>)
                  }

                </tbody>
              </Table>
            </div>

          

          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleekititem} color="primary"  >Add</Button>{' '}
            <Button onClick={this.toggleekititemclose} color="secondary" >Close</Button>
          </ModalFooter>
         </Modal> */}

        <Modal backdrop={false} style={{ maxWidth: '850px', width: '100%' }} isOpen={this.state.modaladd} toggle={this.toggleadd}>
          <ModalHeader toggle={this.toggleadd}>Add Info   </ModalHeader>
          <ModalBody>
            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">

                <Form.Label className="name-label">Customer Code </Form.Label>
                <input
                  className="form-control" required="required" type="text" name="setup_name"
                  readOnly={true}
                  value={this.state.customer_code} />

              </Col>
              <Col className="text-left">

                <Form.Label className="name-label" >Order Type</Form.Label>
                <input
                  className="form-control" required="required" type="text" name="setup_name"
                  readOnly={true}
                  value={this.state.order_type} />
                {/* )} */}


              </Col>
            </Row>

            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">

                <Form.Label className="name-label">AD Code </Form.Label>
                <select className={`form-control `}
                  value={this.state.ad_code}
                  onChange={this.handle_adcode}>
                  <option value="0009999">0009999</option>
                </select>


              </Col>
              <Col className="text-left">

                <Form.Label className="name-label" >Valid to</Form.Label>
                { }
                <input className="form-control" required="required"
                  type="date" name="setup_name" onChange={this.handle_validto}
                  value={this.state.validto} />


              </Col>
            </Row>

            {/* <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">
                <Form.Label className="name-label">PO Number   </Form.Label>
                <input className="form-control" required="required"
                  placeholder='Enter PO Number'
                  style={{ textTransform: 'uppercase' }}
                  type="text" name="setup_name" onChange={this.handle_ponumber} value={this.state.po_number} />
              </Col>

              <Col className="text-left">
                <Form.Label className="name-label">PO Date   </Form.Label>
                <input className="form-control" required="required"

                  type="date" name="setup_name" onChange={this.handle_podate}
                  value={this.state.po_date} />
              </Col>
            </Row> */}

            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">

                <Form.Label className="name-label">Shipment type </Form.Label>
                <select className={`form-control `}

                  value={this.state.shipement_type}
                  onChange={this.handle_shipmenttype}>
                  <option>Select Shipment type</option>
                  <option value="SEA">SEA</option>
                  <option value="ROAD">ROAD</option>
                  <option value="AIR">AIR</option>
                  <option value="RAIL">RAIL</option>
                </select>

              </Col>
              <Col className="text-left">

                <Form.Label className="name-label">Incoterm   </Form.Label>

                {localStorage.getItem('usertypecode') == 'CUST' ? (<>
                  <input className="form-control" required="required"
                    readOnly={true}
                    type="text" name="setup_name"
                    onChange={this.handle_incotern}
                    value={this.state.incotern} />
                </>) : (<>

                  <select className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`}
                    value={this.state.incotern} onChange={this.handle_incotern}>
                    {this.state.inco_term.length ?
                      this.state.inco_term.map((todo, id) => (
                        <option value={todo.tag_value}>{todo.tag_value}</option>
                      ))
                      :
                      (<option></option>)}
                  </select>

                </>)}



              </Col>
            </Row>

            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">

                <Form.Label className="name-label">Port of discharge   </Form.Label>
                <input className="form-control" required="required"
                  style={{ textTransform: 'uppercase' }}
                  type="text" name="setup_name" onChange={this.handle_port_discharge} value={this.state.portofdischarge} />
              </Col>
              <Col className="text-left">


                <Form.Label className="name-label">Incoterm Location   </Form.Label>

                <input className="form-control" required="required"
                  style={{ textTransform: 'uppercase' }}
                  type="text" name="setup_name" onChange={this.handle_incotern_loation} value={this.state.incoternlocation} />


              </Col>
            </Row>

            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">

                <Form.Label className="name-label">Due Date  </Form.Label>
                <input className="form-control" required="required"
                  type="date" name="setup_name" onChange={this.handle_date_ship} value={this.state.shipdate} />
              </Col>
              <Col className="text-left">

                <Form.Label className="name-label">Freight   </Form.Label>
                {localStorage.getItem('usertypecode') == 'CUST' && localStorage.getItem('segment') == '2WEXPORT' ? (<>
                  <input className="form-control" required="required"
                    readOnly={true}
                    type="text" name="setup_name"
                  />
                </>) : (<>
                  {this.state.incotern == 'FOB' ? (<>
                    <input className="form-control" required="required"
                      readOnly={true}
                      type="number" name="setup_name" onChange={this.handle_freight} value={this.state.freight} />
                  </>) : (<>
                    <Row>
                      <Col>
                        <input className="form-control" required="required"
                          style={{ width: 250 }}
                          type="number" name="setup_name" onChange={this.handle_freight} value={this.state.freight} />
                      </Col>
                      <Col>
                        <input className="form-control" required="required"
                          readOnly={true}
                          type="text" name="setup_name"
                          value={this.state.curreny} />
                      </Col>
                    </Row>
                  </>)}
                </>)}




              </Col>
            </Row>

            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>

              <Col className="text-left">

                <Form.Label className="name-label">   Final Destination</Form.Label>
                <input
                  className="form-control" required="required" type="text" name="setup_name"
                  onChange={this.handle_final}
                  style={{ textTransform: 'uppercase' }}
                  value={this.state.final_desc} />
              </Col>
              <Col className="text-left">

                <Form.Label className="name-label">Insurance   </Form.Label>
                {localStorage.getItem('usertypecode') == 'CUST' && localStorage.getItem('segment') == '2WEXPORT' ? (<>
                  <input className="form-control" required="required"
                    readOnly={true}
                    type="text" name="setup_name"
                  />
                </>) : (<>
                  {this.state.incotern == 'FOB' ? (<>
                    <input className="form-control" required="required"
                      readOnly={true}
                      type="number" name="setup_name" onChange={this.handle_insurance} value={this.state.insurance} />

                  </>) : (<>
                    <Row>
                      <Col>
                        <input className="form-control" required="required"
                          style={{ width: 250 }}
                          type="number" name="setup_name" onChange={this.handle_insurance} value={this.state.insurance} />
                      </Col>
                      <Col>
                        <input className="form-control" required="required"
                          readOnly={true}
                          type="text" name="setup_name"
                          value={this.state.curreny} />
                      </Col>
                    </Row>
                  </>)}
                </>)}




              </Col>
            </Row>

            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">

                <Form.Label className="name-label">Notify Party </Form.Label>
                {this.state.notychecked == true ? (<input style={{ marginLeft: 15, marginTop: 30 }} type="checkbox"
                  defaultChecked={true}
                  onChange={this.notichecked} />) : (<input style={{ marginLeft: 15, marginTop: 30 }} type="checkbox"
                    defaultChecked={false}
                    onChange={this.notichecked} />)}



              </Col>
              <Col className="text-left">
                <Form.Label className="name-label">Discount   </Form.Label>
                {localStorage.getItem('usertypecode') == 'CUST' && localStorage.getItem('segment') == '2WEXPORT' ? (<>
                  <input className="form-control" required="required"
                    readOnly={true}
                    type="text" name="setup_name"
                  />
                </>) : (<>
                  {this.state.incotern == 'FOB' ? (<>
                    <input className="form-control" required="required"
                      type="number" name="setup_name" onChange={this.handle_discount} value={this.state.discount} />
                  </>) : (<>
                    <Row>
                      <Col>
                        <input className="form-control" required="required"
                          style={{ width: 250 }}
                          type="number" name="setup_name" onChange={this.handle_discount} value={this.state.discount} />
                      </Col>
                      <Col>
                        <input className="form-control" required="required"
                          readOnly={true}
                          type="text" name="setup_name"
                          value={this.state.curreny} />
                      </Col>
                    </Row>
                  </>)}
                </>)}



              </Col>
            </Row>

            {this.state.notychecked == true ? (<>

              <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
                {this.state.order_number == 'New' ? (
                  <Col className="text-left">

                    <Form.Label className="name-label" >Notify Party Name</Form.Label>
                    <select className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`}
                      value={this.state.notifymastercode} onChange={this.handle_notifyname}>
                      <option>Select Notify Party Name</option>

                      {this.state.notifymaster.length ?
                        this.state.notifymaster.map((todo, id) => (
                          <option value={todo.notify_sap_code}>{todo.notify_sap_code + "-" + todo.notify_name}</option>
                        )) :
                        (<option></option>)}
                    </select>
                    {/* {localStorage.getItem('segment') == '2WEXPORT' || localStorage.getItem('segment') == 'EURO' ? (
                    <select className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`}
                      value={this.state.notifymastername} onChange={this.handle_notifyname}>
                      <option>Select Notify Party Name</option>

                      {this.state.notifymaster.length ?
                        this.state.notifymaster.map((todo, id) => (
                          <option value={todo.notify_name}>{todo.notify_sap_code + "-" + todo.notify_name}</option>
                        )) :
                        (<option></option>)}
                    </select>
                  ) : (<select className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`}
                  value={this.state.notifymastername} onChange={this.handle_notifyname}>
                  <option>Select Notify Party Name</option>

                  {this.state.notifymaster.length ?
                    this.state.notifymaster.map((todo, id) => (
                      <option value={todo.notify_name}>{todo.notify_sap_code + "-" + todo.notify_name}</option>
                    )) :
                    (<option></option>)}
                </select>)} */}

                  </Col>
                ) : (
                  <Col className="text-left">

                    <Form.Label className="name-label" >Notify Party Name</Form.Label>
                    <select className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`}
                      value={this.state.notifymastercode} onChange={this.handle_notifyname}>
                      readOnly ={true}
                      <option>Select Notify Party Name</option>

                      {this.state.notifymaster.length ?
                        this.state.notifymaster.map((todo, id) => (
                          <option value={todo.notify_sap_code}>{todo.notify_sap_code + "-" + todo.notify_name}</option>
                        )) :
                        (<option></option>)}
                    </select>


                  </Col>
                )}

                <Col className="text-left">
                  <Form.Label className="name-label">   Notify Party Code </Form.Label>
                  <input
                    className="form-control" required="required" type="text" name="setup_name"
                    readOnly={true}
                    value={this.state.notifymastercode} />
                </Col>
              </Row>
            </>) : (<>

            </>)}

            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">
                <Form.Label className="name-label">   Priority Item</Form.Label>
                <input
                  className="form-control" required="required" type="text" name="setup_name"
                  onChange={this.handle_priority}
                  style={{ textTransform: 'uppercase' }}
                  value={this.state.priority_item} />
              </Col>
              <Col className="text-left">
                <Form.Label className="name-label">   Filler Item</Form.Label>
                <input
                  className="form-control" required="required" type="text" name="setup_name"
                  onChange={this.handle_filleritem}
                  style={{ textTransform: 'uppercase' }}
                  value={this.state.filler_item} />

              </Col>
            </Row>

            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">
                <Form.Label className="name-label">  Country of final destination place of supply</Form.Label>
                {/* <input  className="form-control" required="required" 
                      type="text" name="setup_name" maxlength = "50" onChange={this.handle_findescounty} 
                      value={this.state.countryoffindes}/> */}

                <select className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`}
                  value={this.state.countryoffindes} onChange={this.handle_findescounty}>
                  {this.state.country_master.length ?
                    this.state.country_master.map((todo, id) => (
                      <option value={todo.country.toUpperCase()}>{todo.country.toUpperCase()}</option>
                    ))
                    :
                    (<option></option>)}
                </select>
              </Col>
              <Col className="text-left">
                <Form.Label className="name-label" >Notes</Form.Label>
                <input
                  className="form-control" required="required" type="text" name="setup_name"
                  style={{ textTransform: 'uppercase' }}
                  onChange={this.handle_notes} value={this.state.notes} />
              </Col>
            </Row>
            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">
                <Form.Label className="name-label">Port of Loading </Form.Label>
                <select className={`form-control `}

                  value={this.state.portofloading}
                  onChange={this.handle_portofloading}>
                  <option>Select Port </option>
                  <option value="TUTICORIN PORT, INDIA">TUTICORIN PORT, INDIA</option>
                  <option value="MADURAI PORT, INDIA">MADURAI PORT, INDIA</option>
                  <option value="CHENNAI PORT, INDIA">CHENNAI PORT, INDIA</option>
                  <option value="MUMBAI PORT, INDIA">MUMBAI PORT, INDIA</option>
                  <option value="MUNDRA PORT, INDIA">MUNDRA PORT, INDIA</option>
                  <option value="BANGALORE PORT, INDIA">BANGALORE PORT, INDIA</option>
                  <option value="KOLKATA PORT, INDIA">KOLKATA PORT, INDIA</option>
                  <option value="COCHIN PORT, INDIA">COCHIN PORT, INDIA</option>
                  <option value="ANY SEAPORT IN INDIA">ANY SEAPORT IN INDIA</option>
                  <option value="RAXAUL PORT,INDIA">RAXAUL PORT,INDIA</option>
                  <option value="FRANCE">FRANCE</option>
                  <option value="NHAVA SHEVA">NHAVA SHEVA</option>
                  <option value="PARIS">PARIS</option>
                  <option value="SEATTLE">SEATTLE</option>
                  <option value="RUDRAPUR">RUDRAPUR</option>
                </select>

              </Col>
              <Col >
                <div className="text-left">
                  <Form.Label className="name-label" >Port of Delivery</Form.Label>
                  {this.state.pistatus != 'Completed' && this.state.pistatus != 'Confirmed' ?
                    (<input className="form-control"
                      style={{ textTransform: 'uppercase' }}
                      required="required" type="text" name="setup_name"
                      onChange={this.handle_port_delivery} value={this.state.port_delivery} />) : (<input className="form-control"
                        style={{ textTransform: 'uppercase' }}
                        readOnly={true}
                        required="required" type="text" name="setup_name"
                        onChange={this.handle_port_delivery} value={this.state.port_delivery} />)}

                </div>

              </Col>
            </Row>
            {this.state.notychecked == true ? (<>
              <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
                <Col className="text-left">

                  <Form.Label className="name-label">  Notify Info</Form.Label>
                  <input className="form-control" required="required"
                    style={{ textTransform: 'uppercase' }}
                    type="text" name="setup_name" maxlength="50" onChange={this.handle_notifyinfo}
                    value={this.state.notifyinfo} />

                  <input className="form-control" required="required"
                    style={{ marginTop: "4px", textTransform: 'uppercase' }}
                    type="text" name="setup_name" maxlength="50" onChange={this.handle_notifyinfo1}
                    value={this.state.notifyinfo1} />

                  <input className="form-control" required="required"
                    style={{ marginTop: "4px", textTransform: 'uppercase' }}
                    type="text" name="setup_name" maxlength="50" onChange={this.handle_notifyinfo2}
                    value={this.state.notifyinfo2} />

                  <input className="form-control" required="required"
                    style={{ marginTop: "4px", textTransform: 'uppercase' }}
                    type="text" name="setup_name" maxlength="50" onChange={this.handle_notifyinfo3}
                    value={this.state.notifyinfo3} />


                  <input className="form-control" required="required"
                    style={{ marginTop: "4px", textTransform: 'uppercase' }}
                    type="text" name="setup_name" maxlength="50" onChange={this.handle_notifyinfo4}
                    value={this.state.notifyinfo4} />
                  {/* <textarea 
                        className="form-control" 
                        required="required" 
                        type="text" name="setup_name" 
                        cols="40" rows="5"
                        onChange ={this.handle_notifyinfo}
                        value={this.state.notifyinfo}/> */}

                </Col>
              </Row>
            </>) : (<></>)}





          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleeadd} color="primary"  >Add</Button>{' '}
            <Button onClick={this.toggleeadd} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>

        <Modal backdrop={false} style={{ maxWidth: '850px', width: '100%' }} isOpen={this.state.excel_upload} toggle={this.toggleexcel}>
          <ModalHeader toggle={this.toggleexcel}>Excel Upload  </ModalHeader>
          <ModalBody>
            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">
                <Form.Label className="name-label" style={{ marginLeft: 20 }}>  Download Excel Template</Form.Label>

                <a style={{ marginLeft: '20px' }} href={calImage} download="OrderBookingTemplate"> Click to download</a>

                {/* <Button style={{marginLeft: '10px'}}>
                  <a href={calImage} download ></a>
                   Click to download
                  </Button> */}

              </Col>

            </Row>
            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">
                <Form.Label className="name-label" style={{ marginLeft: 20 }}>  Note : Template date format ('YYYY-MM-DD')</Form.Label>
              </Col>
            </Row>
            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col className="text-left">
                <div style={{ width: "390px", marginLeft: 20 }} className="text-left">
                  <Form.Label className="name-label">  Upload Excel file </Form.Label>
                  <input
                    style={{ width: "350px", height: '80' }}
                    className="form-control" name="upload_file"
                    type="file"
                    multiple={false}
                    onChange={this.handleexcel}
                  >
                  </input>
                </div>
              </Col>

              <Col className="text-left">
                <Button style={{ marginTop: "30px" }} onClick={this.handleuplodexcel} color="primary"  >Upload</Button>
              </Col>
              <Col className="text-left"></Col>
            </Row>


            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              {this.state.excel_upload_error == "S" ? (<div className="table" style={{ width: "100%", display: "inline-table", margin: "10px", backgroundColor: "#fff" }}>
                <Table striped bordered responsive="sm">
                  <thead style={{ backgroundColor: "#413c69", color: "#ffffff" }}>
                    <tr>
                      <th style={{ width: "20px", color: 'white' }}>#</th>
                      <th style={{ width: "200px", color: 'white' }}>Segment</th>
                      <th style={{ width: "100px", color: 'white' }}>Size</th>
                      <th style={{ width: "400px", color: 'white' }}>Product Name</th>
                      <th style={{ width: "200px", color: 'white' }}>Product Code</th>
                      <th style={{ width: "200px", color: 'white' }}>Price date</th>
                      <th style={{ width: "150px", color: 'white' }}>Price</th>
                      <th style={{ width: "100px", color: 'white' }}>PI Qty</th>
                      <th style={{ width: "150px", color: 'white' }}>Value</th>
                      <th style={{ width: "150px", color: 'white' }}>Container Space</th>

                    </tr>
                  </thead>
                  <tbody>
                    {add_get_size.length ?
                      add_get_size.map((todo, id) => (
                        <tr key={id} >
                          <th>{id + 1}</th>
                          <th>{todo.segment_desc}</th>
                          <th>{todo.product_group}</th>
                          <th>{todo.product_desc}</th>
                          <th>{todo.product_code}</th>
                          <th>{todo.price_date}</th>
                          <th>{todo.price}</th>
                          <th>{todo.qty}</th>
                          <th></th>
                          <th>{todo.container_capacity_qty}</th>
                          {/* <th>{todo.container_capacity}</th> */}
                        </tr>
                      ))
                      :
                      (<tr>
                        <th>-</th>
                        <th>-</th>
                        <th>-</th>
                        <th>-</th>
                        <th>-</th>
                        <th>-</th>
                        <th>-</th>
                        <th>-</th>
                        <th>-</th>
                        <th>-</th>
                        <th>-</th>

                      </tr>)
                    }

                  </tbody>
                </Table>
              </div>
              ) : (<>
                <div className="table" style={{ width: "100%", display: "inline-table", margin: "10px", backgroundColor: "#fff" }}>
                  <Table striped bordered responsive="sm">
                    <thead style={{ backgroundColor: "#413c69", color: "#ffffff" }}>
                      <tr>
                        <th style={{ width: "20px", color: 'white' }}>#</th>
                        <th style={{ width: "200px", color: 'white' }}>Type</th>
                        <th style={{ width: "200px", color: 'white' }}>message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.excel_error.length ?
                        this.state.excel_error.map((todo, id) => (
                          <tr key={id} >
                            <th>{id + 1}</th>
                            <th>{todo.error_type}</th>
                            <th>{todo.message}</th>

                          </tr>
                        ))
                        :
                        (<tr>
                          <th>-</th>
                          <th>-</th>
                        </tr>)
                      }

                    </tbody>
                  </Table>
                </div>
              </>)}

            </Row>

          </ModalBody>
          {/* <ModalFooter>
                    <Button   onClick={this.toggleeexcel } color="primary"  >Add</Button>{' '}
                    <Button  onClick={this.toggleeexcel } color="secondary" >Close</Button>
                </ModalFooter>          */}
        </Modal>

        <Modal backdrop={false} style={{ maxWidth: '800px', width: '100%', height: '500px' }} isOpen={this.state.modalwish} toggle={this.togglewish}>
          <ModalHeader toggle={this.togglewish}>Wish List </ModalHeader>
          <ModalBody>



            <Row style={{ marginTop: "1px", marginBottom: "3px" }}>
              <Col >
                <Form.Label className="name-label">Product Code   </Form.Label>
                <input className="form-control" required="required"
                  type="text" name="setup_name" onChange={this.handle_find_code.bind(this)}
                  value={this.state.find_search_code} />

              </Col>
              <Col >

                <Form.Label className="name-label">Product Desc   </Form.Label>
                <input className="form-control" required="required"
                  type="text" name="setup_name" onChange={this.handle_find.bind(this)}
                  value={this.state.find_search} />

              </Col>
            </Row>

            <div className="table" style={{ maxWidth: '800px', height: '500px', overflow: 'auto' }}>
              <Table striped bordered responsive="sm">
                <thead style={{ backgroundColor: "#413c69", color: "#ffffff" }}>
                  <tr>
                    <th style={{ width: "20px" }}>#</th>
                    <th >Product Code</th>
                    <th >Product Desc</th>
                    <th ></th>

                  </tr>
                </thead>
                <tbody>
                  {get_wishlist.length ?
                    get_wishlist.map((todo, id) => (
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td>{todo.product_code}</td>
                        <td>{todo.product_desc}</td>

                        {todo.wish_list == 'Y' ? (<>
                          <td><input
                            defaultChecked={true}
                            onChange={this.addwish.bind(this, todo)}
                            style={{ marginLeft: 15 }} type="checkbox" /></td>
                        </>) : (<>
                          <td><input
                            onChange={this.addwish.bind(this, todo)}
                            defaultChecked={this.state.checked}
                            style={{ marginLeft: 15 }} type="checkbox" /></td>
                        </>)}
                      </tr>
                    ))
                    :
                    (<tr>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>

                    </tr>)
                  }

                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleewish} color="primary"  >Save</Button>{' '}
            <Button onClick={this.toggleewish} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>

        <Modal style={{ maxWidth: '800px', height: '500px' }}
          isOpen={this.state.modalwishview} toggle={this.togglewishview}>
          <ModalHeader toggle={this.togglewishview}>Wish List view
          </ModalHeader>
          <ModalBody>


            <div className="table" style={{ maxWidth: '800px', width: '100%', height: '500px', overflow: 'auto' }}>
              <Table striped bordered responsive="sm">
                <thead style={{ backgroundColor: "#413c69", color: "#ffffff", }}>
                  <tr>
                    <th style={{ width: "20px" }}>#</th>
                    <th >Product Desc </th>
                    <th >Product Code</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody >
                  {this.state.viewlist.length ?
                    this.state.viewlist.map((todo, id) => (
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td >{todo.product_desc}</td>
                        <td >{todo.product_code}</td>
                        {todo.wish_list == 'Y' ? (<>
                          <td><input
                            defaultChecked={true}
                            onChange={this.addwishview.bind(this, todo)}
                            style={{ marginLeft: 15 }} type="checkbox" /></td>
                        </>) : (<>
                          <td><input
                            onChange={this.addwishview.bind(this, todo)}
                            defaultChecked={this.state.checkedview}
                            style={{ fontSize: 20, marginLeft: 15 }} type="checkbox" /></td>
                        </>)}
                      </tr>
                    ))
                    :
                    (<tr>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>

                    </tr>)
                  }

                </tbody>
              </Table>
            </div>


          </ModalBody>
          <ModalFooter>

            <Button onClick={() => this.toggleewishview()} color="primary"  >Create PFI Order</Button>{' '}
            <Button onClick={this.toggleewishviewclose} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>


        <Modal style={{ maxWidth: '1000px' }} isOpen={this.state.modelpdf} toggle={this.toggleview}>
          <ModalHeader toggle={this.toggleview}>Document View   <a style={{ marginLeft: '600px', textAlign: 'right' }} href={pdf_urlpath + file_title + ".pdf"} download={this.state.po_number}> Click to download PDF</a></ModalHeader>
          <ModalBody style={{ maxWidth: '1200px' }} >
            <div >
              <Document
                style={{ width: '1200px' }}
                file={pdf_urlpath + file_title + ".pdf"}
                onLoadSuccess={this.onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>

              <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
                <Col>
                  <p>
                    Page {pageNumber} of {numPages}
                  </p>
                  <button onClick={this.nextPage}>
                    Next page
                  </button>
                </Col>

              </Row>

            </div>


          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleeview} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>


        <Modal style={{ maxWidth: '1200px', width: '100%', height: '700px' }} isOpen={this.state.product_list} toggle={this.toggleeproduct}>

          {/* old Product list */}
          {/* <ModalHeader toggle={this.toggleeproduct}>Product List  </ModalHeader>
                <ModalBody>

                <Row style={{marginTop:"2px", marginBottom: "7px"}}>
                <Col >
                  <Form.Label className="name-label">Product code   </Form.Label>
                  <input  className="form-control" required="required"   
                      type="text" name="setup_name" onChange={this.handle_product_code.bind(this)} 
                      value={this.state.find_pr_code}/>    
                  </Col>
                  <Col >
                  <Form.Label className="name-label">Product Desc   </Form.Label>
                  <input  className="form-control" required="required"   
                      type="text" name="setup_name" onChange={this.handle_product_desc.bind(this)} 
                      value={this.state.find_pr_desc}/>    
                  </Col>
                </Row>
                
                <div className="table" style={{maxWidth: '1200px', width: '100%', height: '600px', overflow: 'auto'}}>
                    <Table striped bordered  responsive="sm">
                      <thead style={{backgroundColor:"#413c69",color:"#ffffff"}}>
                        <tr>
                          <th style={{width: "20px"}}>#</th>
                          <th >Segmend code</th>
                          <th >Product Group</th>
                          <th >Product desc</th>
                          <th >product Code</th> 
                        </tr>
                      </thead>
                      <tbody>
                      {product_view.length ? 
                        product_view.map((todo,id)=> (
                        <tr key={id}>
                          <th>{id +1 }</th>
                          <th>{todo.segment_desc}</th>
                          <th>{todo.product_group}</th>
                          <th>{todo.product_desc}</th>
                          <th style={{textDecorationLine: 'underline',}}><a  onClick={() => this.toggleeproductview()}> {todo.product_code} </a></th>
                         
                        </tr>
                        ))
                        : 
                        (<tr>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                          <th>-</th>
                        </tr>)
                      } 
                     
                      </tbody>
                    </Table>
                  </div>
                </ModalBody>  
                <ModalFooter>
                    <Button   onClick={this.toggleepadd } color="primary"  >Save</Button>{' '}
                    <Button  onClick={this.toggleepclose } color="secondary" >Close</Button>
                </ModalFooter>     */}


          <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
            <thead>
              <tr>
                <th className="uk-table-shrink" />
                <th className="uk-table-shrink" />
                <th className="uk-table-shrink"></th>
                <th style={{ fontSize: "xx-large" }}>Size</th>
                <th style={{ fontSize: "xx-large" }}>SKU</th>
              </tr>
            </thead>
            <tbody>
              {this.state.users.map((user, index) => (
                <UserTableRow key={index} index={index + 1} user={user} />

              ))}
            </tbody>
          </table>

        </Modal>

        <Modal style={{ maxWidth: '1200px', width: '100%', height: '700px' }} isOpen={this.state.product_view_model} toggle={this.toggleeproductview}>
          <ModalHeader toggle={this.toggleeproductview}>Porudct View  </ModalHeader>
          <ModalBody>

            <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
              <Col >
                <Form.Label className="name-label">Product code   </Form.Label>
                <input className="form-control" required="required"
                  type="text" name="setup_name" onChange={this.handle_product_code.bind(this)}
                  value={this.state.find_pr_code} />
              </Col>
              <Col >
                <Form.Label className="name-label">Product Desc   </Form.Label>
                <input className="form-control" required="required"
                  type="text" name="setup_name" onChange={this.handle_product_desc.bind(this)}
                  value={this.state.find_pr_desc} />
              </Col>
            </Row>


          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleepv_add} color="primary"  >Save</Button>{' '}
            <Button onClick={this.toggleepclose} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>

        <Container fluid>
          <Card style={{ margin: "16px" }}>
            <Card.Body>

              <h4><b>Order Booking</b></h4>
              <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
                <Col >
                  {localStorage.getItem('usertypecode') != 'CUST' ? (
                    <>
                      <div className="text-left">
                        <Form.Label className="name-label" >Customer Name</Form.Label>
                      </div>
                      <div>
                        <Typeahead
                          id="exampleSelect"
                          style={{ width: "490px" }}
                          selected={this.selected}
                          onChange={this.handle_cust.bind(this)}
                          value={this.state.customer_name}
                          labelKey={option => `${option.customer_name} `}
                          options={this.state.cust_list}
                          placeholder="Select a customer..."
                        />
                      </div>
                    </>
                  ) : (<>
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                    <div>

                      <input className="form-control" required="required"
                        placeholder=''
                        style={{ width: "490px" }}
                        type="text" name="setup_name" readOnly={true} value={this.state.get_cust_name} />
                    </div>
                  </>)}



                </Col>

                {/* <Col><Form.Label className="name-label">Master Name</Form.Label></Col> */}
                <Col className="text-left">
                  <Form.Label className="name-label">Month & Year</Form.Label>
                  <input className="form-control" required="required"
                    type="month" name="setup_name"
                    onChange={this.handle_month_year}
                    value={this.state.month_year} />

                </Col>

                <Col className="text-left">
                  <Form.Label className="name-label">Order Number  </Form.Label>

                  <select className={`form-control `}
                    defaultValue={orderget[1]}
                    style={{ marginLeft: 10 }}
                    value={this.state.order_number}
                    options={orderget}
                    onChange={this.handle_order_number}>
                    {orderget.length ?
                      orderget.map((todo, id) => (

                        <option key={id} value={todo.or_no} >{todo.or_no}</option>
                      ))
                      :
                      (<option></option>)
                    }
                  </select>

                </Col>

                <Col className="text-left">
                  <Form.Label className="name-label">Version  </Form.Label>
                  <select className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`}
                    defaultValue={this.state.order_version}
                    style={{ marginLeft: 10 }}
                    value={this.state.order_version}
                    onChange={this.handle_order_version}>
                    {versionget.length ?
                      versionget.map((todo, id) => (

                        <option value={todo.version} >{todo.version}</option>
                      ))
                      :
                      (<option></option>)
                    }
                  </select>

                </Col>

                {/* <Fab style={{marginTop:30,marginLeft:10}} size="small"  color="primary"  aria-label="add">
                        <CalculateIcon onClick={() => this.version_increment()} ></CalculateIcon>
                      </Fab> */}

                <Col className="text-left">
                  <Form.Label className="name-label">PFI Number/ Date  </Form.Label>
                  {this.state.pinumber == 'null' ? (<>
                    <input className="form-control"
                      readOnly={true}
                      style={{ width: "250px", marginLeft: 20 }}
                      value={""}
                      type="text" name="setup_name" />
                  </>) : (<>
                    <input className="form-control"
                      readOnly={true}
                      style={{ width: "250px", marginLeft: 20 }}
                      value={this.state.pinumber + "/" + moment(this.state.po_date).format('YYYY-MM-DD')}
                      type="text" name="setup_name" />
                  </>)}


                </Col>

                <Fab style={{ marginTop: 25, marginRight: 15 }} data-tip data-for="pdfview" size="small" color="primary">
                  <VisibilityIcon onClick={() => this.toggleview()} size="small" />
                </Fab>

                <ReactTooltip id="pdfview" place="top" effect="solid">
                  PDF View
                </ReactTooltip>


              </Row>

              <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
                <Col className="text-left">
                  <Form.Label className="name-label">PO Number   </Form.Label>
                  <input className="form-control" required="required"
                    placeholder='Enter PO Number'
                    style={{ textTransform: 'uppercase' }}
                    type="text" name="setup_name" onChange={this.handle_ponumber} value={this.state.po_number} />
                </Col>
                <Col >
                  <Form.Label className="name-label">PO Date   </Form.Label>
                  <input className="form-control" required="required"

                    type="date" name="setup_name" onChange={this.handle_podate}
                    value={this.state.po_date} />
                </Col>
                {/* <Col >
                  <div className="text-left">
                    <Form.Label className="name-label" >Port of Delivery</Form.Label>
                    {this.state.pistatus != 'Completed' && this.state.pistatus != 'Confirmed' ?
                      (<input className="form-control"
                        style={{ width: "490px", textTransform: 'uppercase' }}
                        required="required" type="text" name="setup_name"
                        onChange={this.handle_port_delivery} value={this.state.port_delivery} />) : (<input className="form-control"
                          style={{ width: "490px", textTransform: 'uppercase' }}
                          readOnly={true}
                          required="required" type="text" name="setup_name"
                          onChange={this.handle_port_delivery} value={this.state.port_delivery} />)}

                  </div>

                </Col> */}


                <Col >
                  <Form.Label className="name-label">Containter Size</Form.Label>
                  {this.state.pistatus != 'Completed' && this.state.pistatus != 'Confirmed' ? (
                    <select className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`}
                      value={this.state.container_size} onChange={this.handle_containter}>
                      <option>Select</option>
                      {this.state.container.length ?
                        this.state.container.map((todo, id) => (
                          <option value={todo.container_type}>{todo.container_type}</option>
                        ))
                        :
                        (<option></option>)}
                    </select>) : (<input className="form-control"
                      style={{ textTransform: 'uppercase' }}
                      readOnly={true}
                      required="required" type="text" name="setup_name"
                      value={this.state.container_size} />)}


                </Col>


                <Col className="text-left">
                  <Form.Label className="name-label">Payment Term   </Form.Label>
                  {localStorage.getItem('usertypecode') == 'CUST' ? (<>
                    <input className="form-control"
                      readOnly={true}
                      value={this.state.payment_term_code} />
                  </>) : (<>

                    <select className={`form-control ${this.state.master_type_error ? 'is-invalid' : ''}`}
                      value={this.state.pay_term} onChange={this.handle_payterm}>
                      {this.state.payment_term.length ?
                        this.state.payment_term.map((todo, id) => (
                          <option value={todo.tag_value}>{todo.tag_desc}</option>
                        ))
                        :
                        (<option></option>)}
                    </select>
                  </>)}




                </Col>


                <Col className="text-left">
                  <Form.Label className="name-label">Currency   </Form.Label>
                  <input className="form-control" required="required"
                    readOnly={true}
                    type="text" name="setup_name" value={this.state.curreny} />
                </Col>

                <Col className="text-left">
                  {localStorage.getItem('usertypecode') == 'CUST' ?
                    (<>
                      <Form.Label className="name-label">Price  Date   </Form.Label>
                      <input className="form-control" required="required"
                        type="date" name="setup_name" readOnly={true}
                        value={this.state.add_price_date} />
                    </>) : (<>
                      <Form.Label className="name-label">Price  Date   </Form.Label>
                      {this.state.pistatus == 'Completed' || this.state.pistatus == 'Confirmed' ? (<>
                        <input className="form-control" required="required"
                          readOnly={true}
                          type="date" name="setup_name" onChange={this.handle_add_pricedate}
                          value={this.state.add_price_date} />
                      </>) : (<>
                        <input className="form-control" required="required"
                          type="date" name="setup_name" onChange={this.handle_add_pricedate}
                          value={this.state.add_price_date} />
                      </>)}
                    </>)}



                </Col>
                {localStorage.getItem('usertypecode') != 'CUST' ? (<>
                  {this.state.pistatus == 'Completed' || this.state.pistatus == 'Confirmed' ? (<></>) : (<>
                    <Fab style={{ marginTop: 25, marginRight: 15 }} data-tip data-for="Updatepricedate" size="small" color="primary">
                      <AttachMoneyIcon
                        onClick={(e) => { if (window.confirm(" Want to  Update price date?")) this.togglupdatedate() }}
                        size="small" />
                    </Fab>
                  </>)}
                </>) : (<></>)}



                <ReactTooltip id="Updatepricedate" place="top" effect="solid">
                  Update price date
                </ReactTooltip>
              </Row>

              <Row style={{ marginTop: "2px", marginBottom: "7px" }}>

                <Col >

                  {localStorage.getItem('segment') == '2WEXPORT' ? (<Form.Label className="name-label">Containter Available % </Form.Label>)
                    : (<Form.Label className="name-label">Balance Available  Capacity % </Form.Label>)}

                  <ProgressBar style={style}
                    now={this.state.displaycontainercapper}
                    label={`${this.state.displaycontainercapper}%`} animated />
                </Col>
                <Col className="text-left">


                  <Form.Label style={{ marginLeft: 5, }} className="name-label">Containter</Form.Label>
                  <input style={{ width: "100px", marginLeft: 5, fontWeight: 'bold' }}
                    className="form-control" required="required" type="number" name="setup_name"
                    readOnly={true}
                    value={this.state.get_conta} />

                </Col>

                <Col className="text-left">
                  <Form.Label className="name-label">Total PFI Qty </Form.Label>
                  <input style={{ width: '120px', marginRight: 35 }} className="form-control" required="required"
                    type="number" name="setup_name"
                    readOnly={true}
                    value={this.state.get_tot} />
                </Col>
                <Col className="text-left">
                  <Form.Label className="name-label">Total PFI Value </Form.Label>
                  <input style={{ width: '130px', marginRight: 55, color: 'Red' }} className="form-control" required="required"
                    type="number" name="setup_name"
                    readOnly={true}
                    value={gettotalvalue.toFixed(2)} />
                </Col>

                <Col className="text-left">


                  <Form.Label style={{ marginRight: 20, }} className="name-label">PFI Status </Form.Label>
                  <input style={this.state.pistatuserror}
                    data-tip data-for="pierror"
                    className="form-control" required="required" type="text" name="setup_name"
                    readOnly={true}
                    value={this.state.action_status} />



                  {/* {this.state.action_status == 'PI Error'?( ""
                    ):(
                        <input 
                        style={this.state.pistatuserror} 
                        data-tip data-for="sono" 
                        className="form-control" required="required" type="text" name="setup_name" 
                        readOnly={true}
                        value={this.state.action_status }/>
                      )} */}

                </Col>

                <Col className="text-left">
                  {this.state.sonumber != null && this.state.sonumber != "" ? (<>
                    <Form.Label style={{ marginRight: 20, }} className="name-label">So Number</Form.Label>
                    <input tyle={{ width: '100px', color: 'Red' }}
                      className="form-control" required="required" type="text" name="setup_name"
                      readOnly={true}
                      value={this.state.sonumber} />
                  </>) : (<></>)}
                </Col>



                <ReactTooltip id="pierror" place="top" effect="solid">
                  {toastshow}
                </ReactTooltip>

                <Fab style={{ marginTop: 25 }} data-tip data-for="addinfo" size="small" color="primary" aria-label="info">
                  <InfoIcon onClick={() => this.toggleadd()} />
                </Fab>

                <ReactTooltip id="addinfo" place="top" effect="solid">
                  Add info
                </ReactTooltip>

                {this.state.pistatus == 'Completed' || this.state.pistatus == 'Confirmed' ? (<>

                </>) : (<>



                  <Fab style={{ marginTop: 25, marginLeft: 5 }} data-tip data-for="excel" size="small" color="primary" aria-label="info">
                    < CloudUploadIcon onClick={() => this.toggleexcel()} />
                  </Fab>

                  <ReactTooltip id="excel" place="top" effect="solid">
                    Excel upload
                  </ReactTooltip>

                  <Fab style={{ marginTop: 25, marginLeft: 5 }} data-tip data-for="wish" size="small" color="primary" aria-label="FavoriteBorderOutlined">
                    < FavoriteBorderOutlinedIcon onClick={() => this.togglewish()} />
                  </Fab>
                  <ReactTooltip id="wish" place="top" effect="solid">
                    Wish list
                  </ReactTooltip>

                  <Fab style={{ marginTop: 25, marginLeft: 5 }} data-tip data-for="createpfi" size="small" color="primary" aria-label="FavoriteBorderOutlined">
                    < AddShoppingCartIcon onClick={() => this.togglewishview()} />
                  </Fab>
                  <ReactTooltip id="createpfi" place="top" effect="solid">
                    Create PFI
                  </ReactTooltip>
                </>)}
                {/* {<Fab style={{ marginTop: 25, marginLeft: 5 }} size="small" color="primary" aria-label="info">
                <ListIcon onClick={() => this.toggleeproduct()} />
              </Fab>} */}

                <Col></Col>

                {/* <Fab style={{marginTop:25, marginLeft: 5}} size="small" color="primary">
                    <VisibilityIcon  onClick={()=>this.toggleview()} size="small"/>
                  </Fab> */}


              </Row>

              {/* <Row style={{ marginTop: "2px", marginBottom: "7px" }}>

                <Col className="text-left">
                  <Form.Label className="name-label">PO Number   </Form.Label>
                  <input className="form-control" required="required"
                    placeholder='Enter PO Number'
                    style={{ width: '230px', textTransform: 'uppercase' }}
                    type="text" name="setup_name" onChange={this.handle_ponumber} value={this.state.po_number} />
                </Col>

                <Col className="text-left">
                  <Form.Label className="name-label">PO Date   </Form.Label>
                  <input className="form-control" required="required"
                    style={{ width: '230px' }}
                    type="date" name="setup_name" onChange={this.handle_podate}
                    value={this.state.po_date} />
                </Col>
               

                <Col className="text-left">

                  <Form.Label className="name-label">Total PFI Qty </Form.Label>
                  <input style={{ width: '100px' }} className="form-control" required="required"
                    type="number" name="setup_name"
                    readOnly={true}
                    onChange={this.handleChangeMaster} value={this.state.get_tot} />
                </Col>
                <Col >
                  <Form.Label className="name-label">Containter Available % </Form.Label>
                  <ProgressBar style={style}
                    now={this.state.displaycontainercapper}
                    label={`${this.state.displaycontainercapper}%`} animated />
                </Col>


                <Col className="text-left">

                  <Form.Label style={{ marginLeft: 5, }} className="name-label">Containter</Form.Label>
                  <input style={{ width: "100px", marginLeft: 5, fontWeight: 'bold' }}
                    className="form-control" required="required" type="number" name="setup_name"
                    readOnly={true}
                    onChange={this.handleChangeMaster} value={this.state.get_conta} />

                </Col>

                <Col>


                  <Form.Label style={{ marginRight: 20, }} className="name-label">PFI Status </Form.Label>
                  <input style={this.state.pistatuserror}
                    data-tip data-for="pierror"
                    className="form-control" required="required" type="text" name="setup_name"
                    readOnly={true}
                    value={this.state.action_status} />

                </Col>

                <ReactTooltip id="pierror" place="top" effect="solid">
                  {toastshow}
                </ReactTooltip>

                <Fab style={{ marginTop: 25 }} data-tip data-for="addinfo" size="small" color="primary" aria-label="info">
                  <InfoIcon onClick={() => this.toggleadd()} />
                </Fab>

                <ReactTooltip id="addinfo" place="top" effect="solid">
                  Add info
                </ReactTooltip>




                {this.state.pistatus == 'Completed' || this.state.pistatus == 'Confirmed' ? (<>

                </>) : (<>

                  <Fab style={{ marginTop: 25, marginLeft: 5 }} data-tip data-for="excel" size="small" color="primary" aria-label="info">
                    < CloudUploadIcon onClick={() => this.toggleexcel()} />
                  </Fab>

                  <ReactTooltip id="excel" place="top" effect="solid">
                    Excel upload
                  </ReactTooltip>

                  <Fab style={{ marginTop: 25, marginLeft: 5 }} data-tip data-for="wish" size="small" color="primary" aria-label="FavoriteBorderOutlined">
                    < FavoriteBorderOutlinedIcon onClick={() => this.togglewish()} />
                  </Fab>
                  <ReactTooltip id="wish" place="top" effect="solid">
                    Wish list
                  </ReactTooltip>

                  <Fab style={{ marginTop: 25, marginLeft: 5 }} data-tip data-for="createpfi" size="small" color="primary" aria-label="FavoriteBorderOutlined">
                    < AddShoppingCartIcon onClick={() => this.togglewishview()} />
                  </Fab>
                  <ReactTooltip id="createpfi" place="top" effect="solid">
                    Create PFI
                  </ReactTooltip>
                </>)}
                {<Fab style={{ marginTop: 25, marginLeft: 5 }} size="small" color="primary" aria-label="info">
                  <ListIcon onClick={() => this.toggleeproduct()} />
                </Fab>}

                <Col></Col>

             

              </Row> */}
            </Card.Body>
          </Card>
        </Container>

        <div className="table" style={{ width: "100%", display: "inline-table", margin: "10px", backgroundColor: "#fff" }}>
          <Table striped bordered responsive="sm">
            <thead style={{ backgroundColor: "#413c69", color: "#ffffff" }}>
              <tr>
                <th style={{ width: "20px", color: 'white' }}>#</th>
                <th style={{ width: "200px", color: 'white' }}>Segment</th>
                <th style={{ width: "100px", color: 'white' }}>Size</th>
                <th style={{ width: "350px", color: 'white' }}>Product Name</th>
                <th style={{ width: "200px", color: 'white' }}>Product Code</th>
                <th style={{ width: "200px", color: 'white' }}>Price date</th>
                <th style={{ width: "80px", color: 'white' }}>Bom No</th>
                <th style={{ width: "150px", color: 'white' }}>Price</th>
                {/* <th style={{width: "150px", color:'white'}}>Order Qty</th> */}
                <th style={{ width: "130px", color: 'white' }}>PFI Qty</th>
                {/* <th style={{width: "100px", color:'white'}}>Balance Qty</th> */}
                <th style={{ width: "150px", color: 'white' }}>Value</th>
                {localStorage.getItem('segment') == '2WEXPORT' ? (<>
                  <th style={{ width: "150px", color: 'white' }}>CFT/tyre</th>
                  <th style={{ width: "150px", color: 'white' }}>Volume Occupied</th>
                </>
                ) : (<>
                  <th style={{ width: "150px", color: 'white' }}>Container Qty</th>
                  {/* <th style={{ width: "150px", color: 'white' }}>Container Space (%) </th> */}
                </>
                )}


                {this.state.pistatus != 'Completed' && this.state.pistatus != 'Confirmed' ? (<>
                  <th style={{ width: "190px", color: 'white' }}>

                    <Fab data-tip data-for="addkititem" size="small" aria-label="add">
                      <CardGiftcardIcon onClick={() => this.togglekititem()} />
                    </Fab>

                    <ReactTooltip id="addkititem" place="top" effect="solid">
                      Add kit Item
                    </ReactTooltip>

                    <Fab style={{ marginLeft: 10 }} data-tip data-for="additem" size="small" aria-label="add">
                      <AddIcon onClick={() => this.toggleitem()} />
                    </Fab>

                    <ReactTooltip id="additem" place="top" effect="solid">
                      Add Item
                    </ReactTooltip>

                  </th>
                </>) : (<>
                  <th style={{ width: "70px", color: 'white' }}> - </th>
                </>)}
              </tr>
            </thead>
            <tbody >
              {add_get_size.length ?
                add_get_size.map((todo, id) => (
                  <tr key={id} >
                    <td>{id + 1}</td>
                    <td>{todo.segment_desc}</td>
                    <td>{todo.product_group}</td>
                    <td>{todo.product_desc}</td>
                    <td>{todo.product_code}</td>
                    <td>
                      {localStorage.getItem('usertypecode') == 'CUST' ? (<>
                        <input className="form-control" required="required"
                          readOnly={true}
                          type="date" name="setup_name" onChange={this.handle_pricedate.bind(this, id)}
                          value={moment(this.state.add_get_size[id].price_date).format('YYYY-MM-DD')} />
                      </>) : (<>
                        {this.state.pistatus == 'Completed' || this.state.pistatus == 'Confirmed' || todo.product_type == 'Tube' || todo.product_type == 'Tyre' || todo.product_type == 'Flap' ? (<>
                          <input className="form-control" required="required"
                            readOnly={true}
                            type="date" name="setup_name" onChange={this.handle_pricedate.bind(this, id)}
                            value={moment(this.state.add_get_size[id].price_date).format('YYYY-MM-DD')} />
                        </>) : (<>
                          <input className="form-control" required="required"
                            type="date" name="setup_name" onChange={this.handle_pricedate.bind(this, id)}
                            value={moment(this.state.add_get_size[id].price_date).format('YYYY-MM-DD')} />
                        </>)}
                      </>)}</td>
                    <td>
                      {localStorage.getItem('usertypecode') == 'CUST' ? (<>

                        {this.state.pistatus == 'Completed' || this.state.pistatus == 'Confirmed'
                          || todo.product_type == 'Flap' || todo.product_type == 'Tube' || todo.product_type == 'Tyre' || todo.product_type == null || todo.product_type == "" ? (
                          <>
                            {this.state.add_get_size[id].bom_no != 0 ? (<>{this.state.add_get_size[id].bom_no}</>) : (<>
                              {this.state.add_get_size[id].bom_no}</>)}
                          </>) : (<>
                            <select className={`form-control `}
                              style={{ width: 80 }}
                              onChange={this.handle_kitbom.bind(this, id)}>
                              {this.state.add_get_size[id].bom_no1.length ?
                                this.state.add_get_size[id].bom_no1.map((bom, id) => (
                                  <option value={bom.product_code + "-" + bom.alt_bom_no}>{bom.alt_bom_no}</option>
                                ))
                                :
                                (<option></option>)}
                            </select>
                          </>)}
                      </>) : (<>
                        {this.state.pistatus == 'Completed' || this.state.pistatus == 'Confirmed'
                          || todo.product_type == 'Flap' || todo.product_type == 'Tube' || todo.product_type == 'Tyre' || todo.product_type == null || todo.product_type == "" ? (
                          <>
                            {this.state.add_get_size[id].bom_no != 0 ? (<>{this.state.add_get_size[id].bom_no}</>) : (<>
                            </>)}

                          </>) : (<>
                            <select className={`form-control `}
                              style={{ width: 80 }}
                              onChange={this.handle_kitbom.bind(this, id)}>
                              {this.state.add_get_size[id].bom_no1.length ?
                                this.state.add_get_size[id].bom_no1.map((bom, id) => (
                                  <option value={bom.product_code + "-" + bom.alt_bom_no}>{bom.alt_bom_no}</option>
                                ))
                                :
                                (<option></option>)}
                            </select>
                          </>)}
                      </>)}</td>
                    <td>{this.state.add_get_size[id].price}</td>
                    {/* <th>{todo.order_qty}</th> */}
                    <td> {this.state.pistatus == 'Completed' || this.state.pistatus == 'Confirmed' || todo.product_type == 'Tube' || todo.product_type == 'Tyre' || todo.product_type == 'Flap' ? (<>
                      <input
                        className="form-control"
                        style={{ width: 130 }}
                        id={"exampleSelect" + id}
                        onChange={this.handle_total_piqty_o.bind(this, id)}
                        value={this.state.add_get_size[id].pi_qty}
                        readOnly={true}
                        placeholder=""
                        type="text" /></>) : (<>
                          <input
                            className="form-control"
                            id={"exampleSelect" + id}
                            style={{ width: 130 }}
                            onChange={this.handle_total_piqty_o.bind(this, id)}
                            value={this.state.add_get_size[id].pi_qty}
                            placeholder=""
                            maxlength="7"
                            type="text" />
                        </>)}
                    </td>
                    {/* {todo.balance_order_qty != '' || todo.balance_order_qty != 'NaN'?( <th>{todo.balance_order_qty}  </th>):("-")} */}

                    <td>{todo.pi_value}</td>
                    <td>{todo.container_capacity_qty}</td>
                    {/* <th>{todo.container_capacity}</th> */}
                    {localStorage.getItem('segment') == '2WEXPORT' ? (<td>{todo.container_capacity_per}</td>) : (<></>)}



                    {this.state.pistatus != 'Completed' && this.state.pistatus != 'Confirmed' ? (<>
                      {/* {this.state.pistatus != 'Completed' && this.state.pistatus != 'Confirmed' &&  todo.kit_flag != 'S' && todo.product_type == 'Set' && todo.uom == 'Set' || todo.uom == null   ? (<> */}

                      {todo.product_type == 'Set' || todo.product_type == null || todo.product_type == "" ? (<>
                        <td>
                          <Fab size="small"
                            color="primary" aria-label="add">
                            <DeleteIcon onClick={() => this.handleDeleteIcon(todo, id)} size="small" />
                          </Fab>
                        </td>
                      </>) : (<>
                        <td>-</td>
                      </>)}


                    </>) : (<> <td>-</td></>)}

                  </tr>
                ))
                :
                (<tr>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                </tr>)
              }

            </tbody>
          </Table>



        </div>


        <div className="text-center" style={{ marginBottom: "50px" }}>


          {this.state.add_get_size == '' || this.state.add_get_size != '' ? (<>


            {this.state.pistatus != 'Completed' && this.state.pistatus != 'Confirmed' ? (<>

              <Button variant="success"
                style={{ marginRight: "2%" }}
                onClick={(e) => { this.submitsave(e) }}>Save</Button>
              {this.state.action_status == 'save' ? (<Button variant="success"
                style={{ marginRight: "2%" }}
                onClick={(e) => { if (window.confirm(" Want to  submit?")) this.submitLists(e) }}>Submit</Button>) : (<></>)}

            </>) : (<>
              {this.state.pistatus != 'Confirmed' && this.state.pinumber != null && this.state.action_status == 'PI Received' || this.state.action_status == 'PI Error' || this.state.action_status == 'Form Uploaded' ? (<>
                <Button variant="success"
                  style={{ marginRight: "2%" }}
                  onClick={(e) => { if (window.confirm("Want to Amentment Order?")) this.version_increment(e) }}>Order Amentment</Button>
                {this.state.piapprovedstatus == 'Y' ? (<>
                  {localStorage.getItem('usertypecode') != 'CUST' ? (<Button variant="success"
                    style={{ marginRight: "2%" }}
                    onClick={(e) => { if (window.confirm("Want to Confirm PI?")) this.pisubmitLists(e) }}>
                    confirm PFA
                  </Button>) : (<>
                    {this.state.piapprovedstatus}
                  </>)}

                </>) : (<>
                </>)}

              </>) : (<>
              </>)}
              <Button variant="success" onClick={() => { window.location.href = "/home" }}>Exit </Button>
            </>)}


          </>) : (<>

          </>)}




        </div>


        <div><ToastContainer /></div>


        {/* </Container> */}
      </div>
    )
  }





  handleAddRow = () => {


    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }


    const item = {
      add_segment: this.state.add_segment,
      add_size: this.state.add_size,
      add_pname: this.state.add_pname,
      add_pcode: this.state.add_pcode,
      add_price: this.state.add_price,
      add_oqty: this.state.add_oqty,
      add_tqty: this.state.add_tqty,
      add_bqty: this.state.add_bqty,
      add_value: this.state.add_value,
    };
    this.setState({
      rows: [...this.state.rows, item]
    });

    this.setState({
      gets: item.value_spe
    })

    this.state.orderadd.push({
      id: this.state.rows.length + 1,
      order_mmyyyy: this.state.month_year,
      order_no: "22000000000" + 1,
      order_version: "01",
      customer_id: this.state.customer_id,
      customer_sap_code: custcode,
      notifyparty_sap_code: "",
      segment_code: "",
      product_group: "",
      product_code: "",
      product_desc: "",
      uom: "EA",
      pi_qty: "",
      price: "",
      pi_value: "",
      pi_confirmation_status: "0",
      pi_confirmation_date: "0",
      pi_approved_by_sm_status: "",
      pi_approved_by_sm_date: "",
      pi_approved_by_finance_status: "",
      pi_approved_by_finance_date: "",
      pi_sap_transfer_no: "",
      pi_sap_transfer_status: "",
      pi_number: "",
      pi_date: "",
      status: "",
      created_at: "",
      modified_at: "",
    })

  };





  handleDeleteIcon = (todo, id) => {
    var currentSetup = this.state.add_get_size
    console.log("DELETEITEM", id)
    console.log("Deletekit", currentSetup)




    let pcode = todo.parent_product_code

    console.log("Manitestdelete", todo)
    console.log("ACTION", this.state.action_status)
    if (this.state.action_status == 'New' || this.state.action_status == "") {

      if (todo.parent_product_code != '' && todo.parent_product_code != null) {

        var getdel = this.state.add_get_size

        var parcode = ''
        for (var i = 0; i < getdel.length; i++) {

          var current_deleted_ids = this.state.deleted_ids;
          var deleted_ids = current_deleted_ids.push(id);
          if (pcode == getdel[i].parent_product_code) {

            console.log("Deleteid", id)

            this.setState({
              deleted_ids: current_deleted_ids
            })
            if (getdel[i].product_type == 'Flap') {
              currentSetup.splice(id, 4)
            } else {
              currentSetup.splice(id, 3)
            }

            this.setState({ add_get_size: currentSetup })
          }


        }


        this.calculation()
        // if (todo.id != 0) {
        //   var current_deleted_ids = this.state.deleted_ids;
        //   var current_deleted_ids_kit = this.state.deleted_ids_kit;
        //   var deleted_ids = current_deleted_ids.push(id);
        //   var deleted_ppc = current_deleted_ids_kit.push(todo.parent_product_code)
        //   this.setState({ deleted_ids: current_deleted_ids ,
        //     deleted_ppc : current_deleted_ids_kit
        //   })

        //   this.calculation()
        //   }

      } else {
        if (todo.id != 0) {
          var current_deleted_ids = this.state.deleted_ids;
          var deleted_ids = current_deleted_ids.push(id);
          this.setState({ deleted_ids: current_deleted_ids })
          console.log("DELETE", deleted_ids)
          this.calculation()
        }
        currentSetup.splice(id, 1)
        this.setState({ add_get_size: currentSetup })
      }

    } else {
      console.log("Manitest12345", todo.id)

      if (todo.parent_product_code != '' && todo.parent_product_code != null) {
        console.log("Manitest123", todo.id)
        axios({
          method: "POST",
          url: this.state.baseurl + 'delete_record_pi',//"http://localhost:3300/user_login",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            deleted_ids: todo.id,
            'parent_code': todo.parent_product_code,
            "container_capacity": this.state.displaycontainercapper,
            "total_order_qty": this.state.get_tot,
            "no_of_containers": this.state.get_conta,
            customer_sap_code: this.state.customer_code,
          }
        }).then((response) => {
          this.state.rows = []

          if (response.data.status == "Success") {
            axios({
              method: "POST",
              url: this.state.baseurl + 'showorder_list',//"http://localhost:3300/custdetails",
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
              },
              data: {
                customer_sap_code: this.state.customer_code,
                order_mmyyyy: this.state.month_year,
              }
            }).then((response) => {
              console.log("Order", response.data.data[0])
              this.setState({
                get_order_no: response.data.data,
                order_number: response.data.data[0],
              });
              axios({
                method: "POST",
                url: this.state.baseurl + 'showversion_list',//"http://localhost:3300/custdetails",
                headers: {
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
                },
                data: {
                  customer_sap_code: this.state.customer_code,
                  order_mmyyyy: this.state.month_year,
                  order_no: response.data.data[0]
                }
              }).then((response) => {
                console.log("version", response.data.data[0])
                this.setState({
                  order_version: response.data.data[0],
                  get_order_verion: response.data.data,
                });
                axios({
                  method: "POST",
                  url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    customer_sap_code: this.state.customer_code,
                    order_mmyyyy: this.state.month_year,
                    order_no: this.state.order_number,
                    order_version: this.state.order_version
                  }
                }).then((response) => {
                  console.log("custhdrsdelete", response.data.data)
                  if (response.data.hdrs != '') {
                    if (response.data.status == 'Success') {

                      console.log("Maniwish05 ", response.data.data)
                      const wishlist = response.data.data;
                      const getlist = []
                      let piqty = '0';
                      let pi_value = "0";
                      let price_date = this.state.price_date
                      let wi_prtype = ''
                      let wi_pcode = ''
                      let segment_desc = ""
                      let segment_code = ""
                      let product_group = ""
                      let product_desc = ""
                      let product_code = ""
                      let price = ""
                      let pi_qty = ""
                      let container_capacity_qty = ""
                      let container_capacity = ""
                      let container_capacity_per = ""
                      let product_type = ""
                      let parent_product_code = ""
                      let bom_no = ''
                      let id = ''

                      for (var w = 0; w < wishlist.length; w++) {

                        if (localStorage.getItem("segment") == 'EURO') {
                          price_date = wishlist[w].price_date
                        }

                        wi_prtype = wishlist[w].product_type
                        segment_desc = wishlist[w].segment_desc
                        segment_code = wishlist[w].segment_code
                        product_group = wishlist[w].product_group
                        product_desc = wishlist[w].product_desc
                        product_code = wishlist[w].product_code
                        price = wishlist[w].price
                        price_date = wishlist[w].price_date
                        pi_qty = wishlist[w].pi_qty
                        pi_value = wishlist[w].pi_value
                        container_capacity_qty = wishlist[w].container_capacity_qty
                        container_capacity = wishlist[w].container_capacity
                        container_capacity_per = wishlist[w].container_capacity_per
                        product_type = wishlist[w].product_type
                        parent_product_code = wishlist[w].parent_product_code
                        bom_no = wishlist[w].bom_no
                        id = wishlist[w].id

                        getlist.push({
                          segment_desc: segment_desc,
                          segment_code: segment_code,
                          product_group: product_group,
                          product_desc: product_desc,
                          product_code: product_code,
                          price: price,
                          price_date: price_date,
                          bom_no: bom_no,
                          bom_no1: "",
                          pi_qty: pi_qty,
                          pi_value: pi_value,
                          container_capacity_qty: container_capacity_qty,
                          container_capacity: container_capacity,
                          container_capacity_per: container_capacity_per,
                          product_type: product_type,
                          parent_product_code: parent_product_code,
                          id: id
                        })


                        if (wi_prtype == 'Set') {
                          wi_pcode = wishlist[w].product_code

                          axios({
                            method: "POST",
                            url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                            headers: {
                              'Content-Type': 'application/json',
                              'token': localStorage.getItem('token')
                            },
                            data: {
                              'product_code': wishlist[w].product_code,
                            }
                          }).then((response) => {
                            console.log("Manitest", response.data.data)
                            const getbomkit = []
                            let kibom = response.data.data;
                            var items = getlist
                            var kitbom = response.data.data
                            let item_pcode = ''
                            let kit_pcode = ''

                            for (var b = 0; b < kitbom.length; b++) {
                              getbomkit.push({
                                // product_code :kitbom[b].product_code,
                                alt_bom_no: kitbom[b].alt_bom_no
                              })
                            }
                            this.setState({
                              wish_kit_bom: getbomkit
                            })

                            for (var k = 0; k < kitbom.length; k++) {
                              kit_pcode = kitbom[k].product_code

                              for (var b = 0; b < items.length; b++) {
                                item_pcode = items[b].product_code

                                if (kit_pcode == item_pcode) {
                                  items[b].bom_no1 = response.data.data
                                  items[b].bom_no = response.data.data[0].alt_bom_no
                                } else {

                                }
                              }
                            }
                            this.setState({
                              add_get_size: getlist
                            })
                          });
                        } else {
                          console.log("Testmanicode", getlist)
                          this.setState({
                            add_get_size: getlist
                          })
                        }


                      }


                      this.setState({
                        // add_get_size: response.data.data,
                        get_cu_details: response.data.hdrs,
                        // you have it as this.data not response
                      });



                    }
                  }

                });
              });
            });
          }
        });
      } else {
        this.calculation()
        console.log("Manitest", todo.id)
        axios({
          method: "POST",
          url: this.state.baseurl + 'delete_record_pi',//"http://localhost:3300/user_login",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            deleted_ids: todo.id,
            "container_capacity": this.state.displaycontainercapper,
            'parent_code': "",
            "total_order_qty": this.state.get_tot,
            "no_of_containers": this.state.get_conta,
            customer_sap_code: this.state.customer_code


          }
        }).then((response) => {
          this.state.rows = []

          if (response.data.status == "Success") {
            axios({
              method: "POST",
              url: this.state.baseurl + 'showorder_list',//"http://localhost:3300/custdetails",
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
              },
              data: {
                customer_sap_code: this.state.customer_code,
                order_mmyyyy: this.state.month_year,
              }
            }).then((response) => {
              console.log("Order", response.data.data[0])
              this.setState({
                get_order_no: response.data.data,
                order_number: response.data.data[0],
              });
              axios({
                method: "POST",
                url: this.state.baseurl + 'showversion_list',//"http://localhost:3300/custdetails",
                headers: {
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
                },
                data: {
                  customer_sap_code: this.state.customer_code,
                  order_mmyyyy: this.state.month_year,
                  order_no: response.data.data[0]
                }
              }).then((response) => {
                console.log("version", response.data.data[0])
                this.setState({
                  order_version: response.data.data[0],
                  get_order_verion: response.data.data,
                });
                axios({
                  method: "POST",
                  url: this.state.baseurl + 'get_dtls_order',//"http://localhost:3300/custdetails",
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                  },
                  data: {
                    customer_sap_code: this.state.customer_code,
                    order_mmyyyy: this.state.month_year,
                    order_no: this.state.order_number,
                    order_version: this.state.order_version
                  }
                }).then((response) => {
                  console.log("custhdrs", response.data.data)
                  if (response.data.hdrs != '') {
                    if (response.data.status == 'Success') {



                      console.log("Maniwish05 ", response.data.data)
                      const wishlist = response.data.data;
                      const getlist = []
                      let piqty = '0';
                      let pi_value = "0";
                      let price_date = this.state.price_date
                      let wi_prtype = ''
                      let wi_pcode = ''
                      let segment_desc = ""
                      let segment_code = ""
                      let product_group = ""
                      let product_desc = ""
                      let product_code = ""
                      let price = ""
                      let pi_qty = ""
                      let container_capacity_qty = ""
                      let container_capacity = ""
                      let container_capacity_per = ""
                      let product_type = ""
                      let parent_product_code = ""
                      let bom_no = ''
                      let id = ''

                      for (var w = 0; w < wishlist.length; w++) {

                        if (localStorage.getItem("segment") == 'EURO') {
                          price_date = wishlist[w].price_date
                        }

                        wi_prtype = wishlist[w].product_type
                        segment_desc = wishlist[w].segment_desc
                        segment_code = wishlist[w].segment_code
                        product_group = wishlist[w].product_group
                        product_desc = wishlist[w].product_desc
                        product_code = wishlist[w].product_code
                        price = wishlist[w].price
                        price_date = wishlist[w].price_date
                        pi_qty = wishlist[w].pi_qty
                        pi_value = wishlist[w].pi_value
                        container_capacity_qty = wishlist[w].container_capacity_qty
                        container_capacity = wishlist[w].container_capacity
                        container_capacity_per = wishlist[w].container_capacity_per
                        product_type = wishlist[w].product_type
                        parent_product_code = wishlist[w].parent_product_code
                        bom_no = wishlist[w].bom_no
                        id = wishlist[w].id

                        getlist.push({
                          segment_desc: segment_desc,
                          segment_code: segment_code,
                          product_group: product_group,
                          product_desc: product_desc,
                          product_code: product_code,
                          price: price,
                          price_date: price_date,
                          bom_no: bom_no,
                          bom_no1: "",
                          pi_qty: pi_qty,
                          pi_value: pi_value,
                          container_capacity_qty: container_capacity_qty,
                          container_capacity: container_capacity,
                          container_capacity_per: container_capacity_per,
                          product_type: product_type,
                          parent_product_code: parent_product_code,
                          id: id
                        })


                        if (wi_prtype == 'Set') {
                          wi_pcode = wishlist[w].product_code

                          axios({
                            method: "POST",
                            url: this.state.baseurl + 'get_bom_no',//"http://localhost:3300/listproduct",
                            headers: {
                              'Content-Type': 'application/json',
                              'token': localStorage.getItem('token')
                            },
                            data: {
                              'product_code': wishlist[w].product_code,
                            }
                          }).then((response) => {
                            console.log("Manitest", response.data.data)
                            const getbomkit = []
                            let kibom = response.data.data;
                            var items = getlist
                            var kitbom = response.data.data
                            let item_pcode = ''
                            let kit_pcode = ''

                            for (var b = 0; b < kitbom.length; b++) {
                              getbomkit.push({
                                // product_code :kitbom[b].product_code,
                                alt_bom_no: kitbom[b].alt_bom_no
                              })
                            }
                            this.setState({
                              wish_kit_bom: getbomkit
                            })

                            for (var k = 0; k < kitbom.length; k++) {
                              kit_pcode = kitbom[k].product_code

                              for (var b = 0; b < items.length; b++) {
                                item_pcode = items[b].product_code

                                if (kit_pcode == item_pcode) {
                                  items[b].bom_no1 = response.data.data
                                  items[b].bom_no = response.data.data[0].alt_bom_no
                                } else {

                                }
                              }
                            }
                            this.setState({
                              add_get_size: getlist
                            })
                          });
                        } else {
                          console.log("Testmanicode", getlist)
                          this.setState({
                            add_get_size: getlist
                          })
                        }


                      }

                      this.setState({
                        //add_get_size: response.data.data,
                        get_cu_details: response.data.hdrs,
                        // you have it as this.data not response
                      });


                      this.calculation()
                    }
                  }

                });
              });
            });
          }
        });
      }

    }




  }
}


