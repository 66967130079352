import React from 'react';
import { Component } from 'react'
import { Table, Form } from 'react-bootstrap';
import { Button, Card, ProgressBar } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Fab from '@material-ui/core/Fab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-select2-wrapper/css/select2.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import axios from 'axios';
import moment from 'moment'
import { Typeahead, TypeaheadInputMulti } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
//import ExportReactCSV from "../../Components/ExportReactCSV";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

var pageNumbers = []
export default class TwExportReport2 extends Component {
  constructor(props) {
    super(props)
    this.state = ({
      cust_list: [],
      order_add: [],
      get_cu_details: [],
      get_invoice_dtlss :[],
      get_invoice_dtls: [],
      month_year: "",
      po_number: "",
      customer_name: "",
      customer_code: "",
      selectedFile: '',
      order_type: '',

      modal: false,
      from_date: '',
      to_date: "",
      get_cust_name: '',
      get_cust_code: '',
      baseurl: process.env['REACT_APP_API_URL_' + localStorage.getItem('segment')],

      get_invoice_no: '',
      total: null,
      per_page: null,
      current_page: 1,

    })

    this.handle_cust = this.handle_cust.bind(this);

    this.invoice = this.invoice.bind(this);
    this.invoice_close = this.invoice_close.bind(this);

  }

  componentDidMount() {
    this.redirectLogin()
    localStorage.setItem("screen_zoom", "80%")
    document.body.style.zoom = localStorage.getItem("screen_zoom");

    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    this.state.from_date = defaultValue
    this.state.to_date = defaultValue

    if (localStorage.getItem('usertypecode') == 'CUST') {
      axios({
        method: "POST",
        url: this.state.baseurl + 'custdetails',//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          user_code: localStorage.getItem('empcode'),
          user_type_code: localStorage.getItem('usertypecode')
        }
      }).then((response) => {
        console.log("Test", response.data.data)
        this.setState({
          cust_list: response.data.data, // you have it as this.data not response
          get_cust_name: response.data.data[0].customer_name,
          get_cust_code: response.data.data[0].customer_sap_code
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'getcustomerinvoice',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            "from_date": this.state.from_date,
            "to_date": this.state.to_date,
            "customer_sap_code": response.data.data[0].customer_sap_code
          }
        }).then((response) => {
          console.log("MANI", response.data.data)
          this.setState({
            get_cu_details: response.data.data,
          });
        });

      });
    } else {
      axios({
        method: "POST",
        url: this.state.baseurl + 'custdetails',//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          user_code: localStorage.getItem('empcode'),
          user_type_code: localStorage.getItem('usertypecode')
        }
      }).then((response) => {
        console.log("Test", response.data.data)
        this.setState({
          cust_list: response.data.data, // you have it as this.data not response
        });
      });
    }

    if (!$.fn.DataTable.isDataTable("#myTable")) {
      $(document).ready(function () {
        setTimeout(function () {
          $("#table").DataTable({
            pagingType: "full_numbers",
            pageLength: 20,
            processing: true,
            dom: "Bfrtip",
            select: {
              style: "single",
            },

            buttons: [
              {
                extend: "pageLength",
                className: "btn btn-secondary bg-secondary",
              },
              {
                extend: "copy",
                className: "btn btn-secondary bg-secondary",
              },
              {
                extend: "csv",
                className: "btn btn-secondary bg-secondary",
              },
              {
                extend: "print",
                customize: function (win) {
                  $(win.document.body).css("font-size", "10pt");
                  $(win.document.body)
                    .find("table")
                    .addClass("compact")
                    .css("font-size", "inherit");
                },
                className: "btn btn-secondary bg-secondary",
              },
            ],

            fnRowCallback: function (
              nRow,
              aData,
              iDisplayIndex,
              iDisplayIndexFull
            ) {
              var index = iDisplayIndexFull + 1;
              $("td:first", nRow).html(index);
              return nRow;
            },

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });
        }, 1000);
      });
    }



  }

  redirectLogin = () => {
    if (localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined || localStorage.getItem("token") == null) {
      localStorage.setItem('token', '')
      window.location.href = "/login"
    }
  }

  // handle customer name 

  handle_cust = (selected) => {
    var cuname = "";
    var cuscode = '';
    for (var i = 0; i < selected.length; i++) {
      cuname = selected[i].customer_name;
      cuscode = selected[i].customer_sap_code
    }
    this.setState({
      customer_name: cuname,
      customer_code: cuscode
    })

    // axios({
    //   method: "POST",
    //   url: this.state.baseurl + 'getcustomerinvoice',//"http://localhost:3300/custdetails",
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'token': localStorage.getItem('token')
    // },
    //   data: { 
    //     "from_date":this.state.from_date,
    //     "to_date":this.state.to_date,
    //     "customer_sap_code":cuscode
    //   }
    // }).then((response) => {
    //   console.log("MANI", response.data.data)
    //   this.setState({
    //     get_cu_details: response.data.data,
    //   });
    // });

  }




  // handle Month and year  
  handle_fromdate = (e) => {

    this.setState({
      from_date: e.target.value
    })

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }
    // axios({
    //   method: "POST",
    //   url: this.state.baseurl + 'getcustomerinvoice',//"http://localhost:3300/custdetails",
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'token': localStorage.getItem('token')
    // },
    //   data: { 
    //     "from_date": e.target.value,
    //     "to_date": this.state.to_date,
    //     "customer_sap_code":custcode
    //   }
    // }).then((response) => {
    //   console.log("MANI", response.data.data)
    //   this.setState({
    //     get_cu_details: response.data.data,
    //   });
    // });

  }

  handle_todate = (e) => {
    this.setState({
      to_date: e.target.value
    })
    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code

    }
    // axios({
    //   method: "POST",
    //   url: this.state.baseurl + 'getcustomerinvoice',//"http://localhost:3300/custdetails",
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'token': localStorage.getItem('token')
    // },
    //   data: { 
    //     "from_date":this.state.from_date,
    //     "to_date":e.target.value,
    //     "customer_sap_code":custcode
    //   }
    // }).then((response) => {
    //   console.log("MANI", response.data.data)
    //   this.setState({
    //     get_cu_details: response.data.data,
    //   });
    // });

  }




  handle_invoice_no = (e) => {

    this.setState({
      get_invoice_no: e.target.value
    })

    console.log("Invoiceno", e.target.value)
  }


  handle_show_invoice = (e) => {

    let custcode = ''
    if(localStorage.getItem('usertypecode') == 'CUST'){
      custcode = this.state.get_cust_code
    }else{
      custcode = this.state.customer_code
    }


    console.log("usertypecode", localStorage.getItem('username'))

    axios({
      method: "POST",
      url: this.state.baseurl + 'getcustomerinvoice',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "from_date": this.state.from_date,
        "to_date": this.state.to_date,
        "customer_sap_code": custcode,
        "invoice_no": this.state.get_invoice_no,
        "usertype": localStorage.getItem('usertypecode'),
        "username": localStorage.getItem('username'),
        "page":1
      }
    }).then((response) => {
      this.setState({
        get_cu_details: response.data.data,
        total: response.data.total_page,
        current_page: response.data.current_page,
        per_page: response.data.per_page,
      });
    });

    axios({
      method: "POST",
      url: this.state.baseurl + 'getcustomerinvoice_excel',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "from_date": this.state.from_date,
        "to_date": this.state.to_date,
        "customer_sap_code": custcode,
        "invoice_no": this.state.get_invoice_no,
       
      }
    }).then((response) => {
      console.log("Invoicedata", response.data.data)
      this.setState({
        get_invoice_dtlss: response.data.data,
       
      });

    });
    
  }

  handle_show_invoice1 = ( page_number) => {

    this.state.get_cu_details =[]

    console.log("Mani", page_number)

    let custcode = ''
    if(localStorage.getItem('usertypecode') == 'CUST'){
      custcode = this.state.get_cust_code
    }else{
      custcode = this.state.customer_code
    }

    
  
    axios({
      method: "POST",
      url: this.state.baseurl + 'getcustomerinvoice',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "from_date": this.state.from_date,
        "to_date": this.state.to_date,
        "customer_sap_code": custcode,
        "invoice_no": this.state.get_invoice_no,
        "usertype": localStorage.getItem('usertypecode'),
        "username": localStorage.getItem('username'),
        "page":page_number
      }
    }).then((response) => {
      console.log("Invoicedata", response.data.total_page)
      this.setState({
        get_cu_details: response.data.data,
        total: response.data.total_page,
        current_page: response.data.current_page,
        per_page: response.data.per_page,
      });
      
   
    });


  }

  invoice = todo => {
    axios({
      method: "POST",
      url: this.state.baseurl + 'getinvoice_dtls',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "customer_sap_code": todo.customer_sap_code,
        "invoice_no": todo.invoice_doc_no
      }
    }).then((response) => {
      console.log("Invoicedtls", response.data.data)
      this.setState({
        get_invoice_dtls: response.data.data,
      });
    });

    this.setState(prevState => ({
      invoice_view: !prevState.invoice_view,
    }));
  }

  invoice_close = () => {
    this.setState(prevState => ({
      invoice_view: !prevState.invoice_view
    }));
  }

  handleNextPage = (current_page) => {
    var last_page = pageNumbers[pageNumbers.length - 1]
    if(last_page == current_page){

      this.handle_show_invoice1(1)
      // if(this.state.searching){
      //   this.handle(1);
      // } else {
      //   this.fetchMasterData(1)
      // }
      
    } else {
      if(this.state.searching){
        this.fetchSearchResult(current_page + 1);
      } else {
        this.handle_show_invoice1(current_page + 1)
      }
      
    }
  }


  render() {
    const { get_cu_details = [], get_invoice_dtls = [] , get_invoice_dtlss = [] } = this.state
   
    pageNumbers = []
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
        pageNumbers.push(i);
      }
    }
  
    const renderPageNumbers = pageNumbers.map(number => {
      let classes = this.state.current_page === number ? 'pagination-active' : '';
    
      if (number == 1 || number == this.state.total || (number >= this.state.current_page - 2 && number <= this.state.current_page + 5)) {
        if(this.state.searching){
          return (
            <span key={number} className={classes} onClick={() => this.fetchSearchResult(number)}>{number}</span>
          );
        } else {
          return (
            <span key={number} className={classes} onClick={() => this.handle_show_invoice1(number)}>{number}</span>
          );
        }
        
      }
    });
   

    console.log("Manicount",this.state.total)
    
    var myTable= $('#myTableId').DataTable({
      columns: [
          //any column configuration
          { "bSearchable": false }, //col 1
          { "bSearchable": true },  //col 2
          { "bSearchable": true },  //col 3
          { "bSearchable": false }, //col 4
          { "bSearchable": false }, //col 5
          { "bSearchable": false } //col 6 
      ]
  });


  const headers = [
    { label: "Customer Name", key: "customer_name" },
    { label: "Invoice No", key: "invoice_doc_no" },
    { label: "Invoice Date", key: "invoice_date" },
    { label: "Currency", key: "currency" },
    { label: "Container Name", key: "container_name" },
    { label: "BL Number", key: "bl_number" },
    { label: "BL Doc Date", key: "bl_doc_date" },
    { label: "Port of Shipment", key: "port_of_shipment" },
    { label: "Port of Discharge", key: "port_of_discharge" },
    { label: "Shipping Agent", key: "shipping_agent" },
    { label: "Shipment from Factory", key: "date_of_shipment_from_factory" },
    { label: "Date of Sailing", key: "bl_doc_date" }
  ];


    return (
      <div>

        <Modal style={{ maxWidth: '1000px', width: '100%' }} isOpen={this.state.invoice_view} toggle={this.invoice}>
          <ModalHeader toggle={this.invoice}>Invoice  View </ModalHeader>
          <ModalBody>
            <div className="table" style={{ width: "100%", display: "inline-table", margin: "5px", backgroundColor: "#fff" }}>
              <Table striped bordered responsive="sm" >
                <thead style={{ backgroundColor: "#413c69", color: "#ffffff" }}>
                  <tr>
                    <th style={{ width: "20px" }}>#</th>
                    <th style={{ width: "20px" }}>Invoice Item</th>
                    <th style={{ width: "100px" }}>Product Code Name</th>
                    <th style={{ width: "60px" }}>Product Desc</th>
                    <th style={{ width: "120px" }}>Invoice Qty</th>
                    <th style={{ width: "100px" }}>Invoice Value</th>


                  </tr>
                </thead>
                <tbody>
                  {get_invoice_dtls.length ?
                    get_invoice_dtls.map((todo, id) => (
                      <tr key={id} >
                        <th>{id + 1}</th>
                        {todo.invoice_item != null ? (<th style={{ width: "100px" }}>{todo.invoice_item}</th>) : (<th>-</th>)}
                        {todo.product_code != null ? (<th style={{ width: "100px" }}> {todo.product_code}</th>) : (<th>-</th>)}
                        {todo.product_desc != null ? (<th style={{ width: "100px" }}>{todo.product_desc}</th>) : (<th>-</th>)}
                        {todo.invoice_qty != null ? (<th style={{ width: "100px" }}>{todo.invoice_qty}</th>) : (<th>-</th>)}
                        {todo.invoice_value != null ? (<th style={{ width: "100px" }}>{todo.invoice_value}</th>) : (<th>-</th>)}



                      </tr>
                    ))
                    :
                    (<tr>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>


                    </tr>)
                  }

                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.invoice_close} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>

        <Container fluid>
          <Card style={{ margin: "5px" }}>
            <Card.Body>
            <h4><b>Invoice BL Status</b></h4>
              <Row style={{ marginTop: "2px", marginBottom: "7px" }}>


                <Col className="text-left">
                  <Form.Label className="name-label">From  Date   </Form.Label>
                  <input className="form-control" required="required"
                    type="date" name="setup_name" onChange={this.handle_fromdate}
                    value={this.state.from_date} />

                </Col>

                <Col className="text-left">
                  <Form.Label className="name-label">To Date   </Form.Label>
                  <input className="form-control" required="required"
                    type="date" name="setup_name" onChange={this.handle_todate}
                    value={this.state.to_date} />
                </Col>

                <Col >
                  {localStorage.getItem('usertypecode') != 'CUST' ? (<>
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                    <div>
                      <Typeahead
                        id="exampleSelect"
                        style={{ width: "490px" }}
                        selected={this.selected}
                        onChange={this.handle_cust.bind(this)}
                        value={this.state.customer_name}
                        labelKey={option => `${option.customer_name} `}
                        options={this.state.cust_list}
                        placeholder="Select a customer..."
                      />
                    </div>
                  </>) : (<>
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                    <div>

                      <input className="form-control" required="required"
                        placeholder=''
                        style={{ width: "490px" }}
                        type="text" name="setup_name" readOnly={true} value={this.state.get_cust_name} />
                    </div>
                  </>)}


                </Col>

                <Col className="text-left">
                  <Form.Label className="name-label">Invoice No</Form.Label>
                  <input className="form-control" required="required"
                    type="number" name="setup_name" onChange={this.handle_invoice_no}
                    maxlength="7"
                    value={this.state.get_invoice_no} />
                </Col>
                <Col className="text-left">

                  <Button style={{ marginTop: 30 }} onClick={this.handle_show_invoice} color="primary"  >Display</Button>{' '}
                </Col>


              </Row>
            </Card.Body>
          </Card>
        </Container>

        <div className="table p-2" style={{ width: "100%", display: "inline-table", margin: "5px", backgroundColor: "#fff" }}>
        <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
                <Col className="text-left">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-success mb-3"
                    table="example"
                    filename="Invoice List"
                    sheet="tablexls"
                    buttonText="Download as XLS"
                  />
                {/* <ExportReactCSV   
                    csvHeaders={headers}
                    csvData={get_invoice_dtlss}
                    fileName="InvoiceShipment.csv"
                  /> */}
                </Col>
                  <Col className="text-left">
                  </Col>
                  <Col className="text-left">
                  </Col>
                  <Col className="text-left">
                  </Col>
                  <Col className="text-left">
                  </Col>
                <Col className="text-left">
                {this.state.total != null?(<Form.Label className="name-label">Total : {this.state.total}   </Form.Label>)
                :(<Form.Label className="name-label">Total :    </Form.Label>)}
                </Col>
          </Row>
         
          <Table id="example" striped bordered responsive="sm">
            <thead style={{ backgroundColor: "#413c69", color: "#ffffff" }}>
              <tr>
                <th style={{ width: "20px" }}>#</th>
                <th style={{ width: "100px" }}>Customer Name</th>
                <th style={{ width: "60px" }}>Invoice No</th>
                <th style={{ width: "120px" }}>Invoice Date</th>
                <th style={{ width: "100px" }}>Invoice Value</th>
                <th style={{ width: "100px" }}>Currency</th>
                <th style={{ width: "100px" }}>Container Name</th>
                <th style={{ width: "60px" }}>BL No</th>
                <th style={{ width: "100px" }}>BL Date</th>
                {/* <th style={{width: "80px"}}>Container Available</th> */}
                <th style={{ width: "80px" }}>Port of Shipment</th>
                <th style={{ width: "80px" }}>Port of Discharge</th>
                <th style={{ width: "80px" }}>Shipment Agent</th>
                <th style={{ width: "100px" }}>Shipment from Factory</th>
                <th style={{ width: "100px" }}>Date of Sailing</th>

              </tr>
            </thead>
            <tbody>
              {get_cu_details.length ?
                get_cu_details.map((todo, id) => (
                  <tr key={id} >
                    <th>{id + 1}</th>
                    {todo.customer_name != null ? (<th style={{ width: "60px" }}>
                      <Form.Label onClick={() => this.invoice(todo)}>{todo.customer_name.trim()}</Form.Label></th>) : (<th>-</th>)}
                    {todo.invoice_doc_no != null ? (<th style={{ width: "60px" }}>{todo.invoice_doc_no.trim()}</th>) : (<th>-</th>)}
                    {todo.invoice_date != null ? (<th style={{ width: "150px" }}>{moment(todo.invoice_date).format('DD-MM-YYYY')}</th>) : (<th>-</th>)}
                    {todo.invoice_net_value != null ? (<th style={{ width: "150px" }}>{todo.invoice_net_value}</th>) : (<th>-</th>)}
                    {todo.currency != null ? (<th style={{ width: "150px" }}>{todo.currency}</th>) : (<th>-</th>)}
                    {todo.container_name != null ? (<th style={{ width: "120px" }}>{todo.container_name}</th>) : (<th>-</th>)}
                    {todo.bl_number != null ? (<th style={{ width: "60px" }}>{todo.bl_number.trim()}</th>) : (<th>-</th>)}
                    {todo.bl_doc_date != null ? (<th style={{ width: "130px" }}>{moment(todo.bl_doc_date).format('DD-MM-YYYY')}</th>) : (<th>-</th>)}
                    {/* {todo.container_available_date != null?(<th style={{width: "130px"}}>{moment(todo.container_available_date).format('DD-MM-YYYY')}</th>):(<th>-</th>)} */}
                    {todo.port_of_shipment != null ? (<th style={{ width: "250px" }}>{todo.port_of_shipment}</th>) : (<th>-</th>)}
                    {todo.port_of_discharge != null ? (<th>{todo.port_of_discharge}</th>) : (<th>-</th>)}
                    {todo.shipping_agent != null ? (<th>{todo.shipping_agent}</th>) : (<th>-</th>)}
                    {todo.date_of_shipment_from_factory != null ? (<th style={{ width: "130px" }}>{moment(todo.date_of_shipment_from_factory).format('DD-MM-YYYY')}</th>) : (<th>-</th>)}
                    {todo.bl_doc_date != null ? (<th style={{ width: "150px" }}>{moment(todo.bl_doc_date).format('DD-MM-YYYY')}</th>) : (<th>-</th>)}


                  </tr>
                ))
                :
                (<tr>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>

                </tr>)
              }

            </tbody>
          </Table>

          <div className='pagination' style={{backgroundColor: "white" , justifyContent: 'right'}}>
              <span onClick={() => this.fetchMasterData(1)}>&laquo;</span>
                {renderPageNumbers}
              <span onClick={() => this.handle_show_invoice1(this.state.current_page)}>&raquo;</span>
            </div>  
        </div>


        <div className="text-center" style={{ marginBottom: "50px" }}>
          <Button variant="success" onClick={() => { window.location.href = "/home" }}>Exit</Button>
        </div>


        <div><ToastContainer /></div>

      </div>
    )
  }

}
