import React from 'react';
import { Component } from 'react'
import { Alert, Form, Table } from 'react-bootstrap';
import { Button, Card, ProgressBar } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { Grid } from 'semantic-ui-react';
import { Container } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Fab from '@material-ui/core/Fab';
import { ToastContainer, toast } from 'react-toastify';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteIcon from '@material-ui/icons/Delete';
import 'react-toastify/dist/ReactToastify.css';
import 'react-select2-wrapper/css/select2.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import axios from 'axios';
import moment from 'moment'
import { Typeahead } from 'react-bootstrap-typeahead';
//import ExportReactCSV from "../../Components/ExportReactCSV";

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import ReactHTMLTableToExcel from "react-html-table-to-excel";



var pageNumbers = []
export default class TwExportReport2 extends Component {
  constructor(props) {
    super(props)

    this.state = ({
      // user_type:localstorage.getItem('usertype'),
      user_type: localStorage.getItem('usertypecode'), //'SCM',
      cust_list: [],
      order_add: [],
      get_cu_details: [],
      get_invoice_dtlss: [],
      get_invoice_dtls: [],
      month_year: "",
      po_number: "",
      customer_name: "",
      customer_code: "",
      selectedFile: '',
      order_type: '',
      fileInfos1: [],
      fileInfos2: [],
      fileInfos3: [],
      fileInfos4: [],
      fileInfos5: [],

      fileInfosrate: [],

      fileInfos11: [],

      view: false,
      modal1: false,
      modal2: false,

      invoice_view: false,
      from_date: '',
      to_date: "",
      pageNumber: 1,
      baseurl: process.env['REACT_APP_API_URL_' + localStorage.getItem('segment')],

      get_invoice_no: '',
      invoice_pdf_name: 'INVPDF',
      invoice_doc_type: 'INV',
      total: null,
      per_page: null,
      current_page: 1,
      psi_status: 'true',
      psi2file_name: "",
      psifile_url: false,
      pkglistfile_name: "",
      pkglistfile_url: false,
      rlfile_name: "",
      rlfile_url: false,
      popinvfile_name: "",
      popinvfile_url: false,
      poppkgfile_name: "",
      poppkgfile_url: false,
      blfile_name: "",
      blfile_url: false,
      coofile_name: "",
      coofile_url: false,
      insufile_name: "",
      insufile_url: false,
      cifile_name: "",
      cifile_url: false,


    })

    this.handle_cust = this.handle_cust.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.togglee1 = this.togglee1.bind(this);

    this.toggle2 = this.toggle2.bind(this);
    this.togglee2 = this.togglee2.bind(this);


    this.toggleview = this.toggleview.bind(this);
    this.toggleeview = this.toggleeview.bind(this);

    this.invoice = this.invoice.bind(this);
    this.invoice_close = this.invoice_close.bind(this);




  }

  componentDidMount() {

    console.log("SCMMANI", localStorage.getItem('usertypecode'))
    this.redirectLogin()
    localStorage.setItem("screen_zoom", "80%")
    document.body.style.zoom = localStorage.getItem("screen_zoom");

    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    this.state.from_date = defaultValue
    this.state.to_date = defaultValue


    if (localStorage.getItem('usertypecode') == 'CUST') {
      axios({
        method: "POST",
        url: this.state.baseurl + 'custdetails',//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          user_code: localStorage.getItem('empcode'),
          user_type_code: localStorage.getItem('usertypecode')
        }
      }).then((response) => {
        console.log("Test", response.data.data)
        this.setState({
          cust_list: response.data.data, // you have it as this.data not response
          get_cust_name: response.data.data[0].customer_name,
          get_cust_code: response.data.data[0].customer_sap_code
        });
      });
    } else {
      axios({
        method: "POST",
        url: this.state.baseurl + 'custdetails',//"http://localhost:3300/user_login",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          user_code: localStorage.getItem('empcode'),
          user_type_code: localStorage.getItem('usertypecode')
        }
      }).then((response) => {
        console.log("Test", response.data.data)
        this.setState({
          cust_list: response.data.data, // you have it as this.data not response
        });
      });
    }



    //   $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(

    //     );
    //     } ,
    //     1000
    //     );
    // });



  }

  redirectLogin = () => {
    if (localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined || localStorage.getItem("token") == null) {
      localStorage.setItem('token', '')
      window.location.href = "/login"
    }
  }


  // handle customer name 

  handle_cust = (selected) => {
    var cuname = "";
    var cuscode = '';
    for (var i = 0; i < selected.length; i++) {
      cuname = selected[i].customer_name;
      cuscode = selected[i].customer_sap_code
    }
    this.setState({
      customer_name: cuname,
      customer_code: cuscode,

    })



  }


  // handle Month and year  
  handle_fromdate = (e) => {

    this.setState({
      from_date: e.target.value,

    })


  }

  handle_todate = (e) => {
    this.setState({
      to_date: e.target.value,

    })


  }

  handle_invoice_no = (e) => {

    this.setState({
      get_invoice_no: e.target.value,

    })

    console.log("Invoiceno", e.target.value)
  }

  handle_show_invoice = (e) => {

    this.state.get_cu_details = []

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code
    }

    axios({
      method: "POST",
      url: this.state.baseurl + 'getcustomerinvoice',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "from_date": this.state.from_date,
        "to_date": this.state.to_date,
        "customer_sap_code": custcode,
        "invoice_no": this.state.get_invoice_no,
        "usertype": localStorage.getItem('usertypecode'),
        "username": localStorage.getItem('username'),
        "page": 1
      }
    }).then((response) => {
      console.log("Invoicedatarecord", response.data.data)
      this.setState({
        get_cu_details: response.data.data,
        total: response.data.total_page,
        current_page: response.data.current_page,
        per_page: response.data.per_page,
      });


    });

    axios({
      method: "POST",
      url: this.state.baseurl + 'getcustomerinvoice_excel',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "from_date": this.state.from_date,
        "to_date": this.state.to_date,
        "customer_sap_code": custcode,
        "invoice_no": this.state.get_invoice_no,

      }
    }).then((response) => {
      console.log("Invoicedata", response.data.data)
      this.setState({
        get_invoice_dtlss: response.data.data,

      });


    });

  }

  handle_show_invoice1 = (page_number) => {

    this.state.get_cu_details = []

    let custcode = ''
    if (localStorage.getItem('usertypecode') == 'CUST') {
      custcode = this.state.get_cust_code
    } else {
      custcode = this.state.customer_code
    }

    axios({
      method: "POST",
      url: this.state.baseurl + 'getcustomerinvoice',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "from_date": this.state.from_date,
        "to_date": this.state.to_date,
        "customer_sap_code": custcode,
        "invoice_no": this.state.get_invoice_no,
        "usertype": localStorage.getItem('usertypecode'),
        "username": localStorage.getItem('username'),
        "page": page_number
      }
    }).then((response) => {
      console.log("Invoicedata", response.data.total_page)
      this.setState({
        get_cu_details: response.data.data,
        total: response.data.total_page,
        current_page: response.data.current_page,
        per_page: response.data.per_page,
      });


    });
  }

  invoice = todo => {
    axios({
      method: "POST",
      url: this.state.baseurl + 'getinvoice_dtls',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        "customer_sap_code": todo.customer_sap_code,
        "invoice_no": todo.invoice_doc_no
      }
    }).then((response) => {
      console.log("Invoicedtls", response.data.data)
      this.setState({
        get_invoice_dtls: response.data.data,
      });
    });

    this.setState(prevState => ({
      invoice_view: !prevState.invoice_view,
    }));
  }

  invoice_close = () => {
    this.setState(prevState => ({
      invoice_view: !prevState.invoice_view
    }));
  }


  toggle1 = todo => {
    console.log("Manitest", todo)

    this.state.psi2file_name = ""
    this.state.pkglistfile_name = ""
    this.state.rlfile_name = ""
    this.state.popinvfile_name = ""
    this.state.poppkgfile_name = ""
    this.state.cifile_name = ""
    this.state.blfile_name = ""
    this.state.coofile_name = ""
    this.state.insufile_name = ""

    this.state.psifile_url = false
    this.state.pkglistfile_url = false
    this.state.rlfile_url = false
    this.state.popinvfile_url = false
    this.state.poppkgfile_url = false
    this.state.cifile_url = false
    this.state.blfile_url = false
    this.state.coofile_url = false
    this.state.insufile_url = false


    if (this.state.document_no == 'x') {
      alert("Please enter document number")
    } else {

      this.state.order_no = ""


      this.setState(prevState => ({
        modal1: !prevState.modal1,
        order_no: todo.invoice_doc_no,
        document: "",
        // customer_code: todo.customer_sap_code
      }));

      let PSI = todo.invoice_doc_no + "PSI.PDF"
      let PKGLIST = todo.invoice_doc_no + "PKGLIST.PDF"
      let RL = todo.invoice_doc_no + "RL.PDF"
      let POPINV = todo.invoice_doc_no + "POPINV.PDF"
      let POPPKG = todo.invoice_doc_no + "POPPKG.PDF"
      let BL = todo.invoice_doc_no + "BL.PDF"
      let COO = todo.invoice_doc_no + "COO.PDF"
      let CI = todo.invoice_doc_no + "CI.PDF"
      let INSU = todo.invoice_doc_no + "INSU.PDF"
      console.log("Invoice List", PSI + "--" + PKGLIST + "--" + RL + "--" + POPINV + "--" + POPPKG + "--" + BL + "--" + COO + "--" + INSU)

      console.log("Invoiceurl1", process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + PSI,)

      if (this.state.user_type == 'SCM' || this.state.user_type == 'SE') {
        this.getScmPSI(PSI, PKGLIST, RL, POPINV, POPPKG, BL, COO)
      }

      if (this.state.user_type == 'FIN') {
        this.getFinCI(CI, PKGLIST, POPINV, POPPKG, INSU)
      }

    }

  }


  toggle2 = todo => {

    if (this.state.document_no == 'x') {
      alert("Please enter document number")
    } else {

      this.state.psi2file_name = ""
      this.state.pkglistfile_name = ""
      this.state.rlfile_name = ""
      this.state.popinvfile_name = ""
      this.state.poppkgfile_name = ""
      this.state.cifile_name = ""
      this.state.blfile_name = ""
      this.state.coofile_name = ""
      this.state.insufile_name = ""

      this.state.psifile_url = false
      this.state.pkglistfile_url = false
      this.state.rlfile_url = false
      this.state.popinvfile_url = false
      this.state.poppkgfile_url = false
      this.state.cifile_url = false
      this.state.blfile_url = false
      this.state.coofile_url = false
      this.state.insufile_url = false

      this.setState({
        pageNumber: 1,
      });

      this.setState(prevState => ({
        modal2: !prevState.modal2,
      }));

      let PSI = todo.invoice_doc_no + "PSI.PDF"
      let PKGLIST = todo.invoice_doc_no + "PKGLIST.PDF"
      let RL = todo.invoice_doc_no + "RL.PDF"
      let POPINV = todo.invoice_doc_no + "POPINV.PDF"
      let POPPKG = todo.invoice_doc_no + "POPPKG.PDF"
      let BL = todo.invoice_doc_no + "BL.PDF"
      let COO = todo.invoice_doc_no + "COO.PDF"
      let CI = todo.invoice_doc_no + "CI.PDF"
      let INSU = todo.invoice_doc_no + "INSU.PDF"
      console.log("Invoice List", PSI + "--" + PKGLIST + "--" + RL + "--" + POPINV + "--" + POPPKG + "--" + BL + "--" + COO + "--" + INSU)

      //console.log("Invoiceurl1", process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + PSI,)
      if (this.state.user_type == 'FIN' || this.state.user_type == 'AM' || this.state.user_type == 'CUST') {
        this.getPSI(PSI, PKGLIST, CI, RL, BL, COO)
      }

      if (this.state.user_type == 'SCM' || this.state.user_type == 'SE') {
        this.getseCI(CI, INSU)
      }


    }

  }


  getPSI(getpsi, getpkg, getcl, getrl, getbl, getcoo) {
    try {

      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getpsi, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            psifile_url: true,
            psi2file_name: getpsi
          })
        } else {
          this.setState({
            psifile_url: false
          })
        }
        this.getPKG(getpkg, getcl, getrl, getbl, getcoo)

        console.log("ManitestPSI", response.status)
      }).catch((error) => {

        this.getPKG(getpkg, getcl, getrl, getbl, getcoo)

      })

    } catch (error) {
      console.log(error)
    }
  }


  getPKG(getpkg, getcl, getrl, getbl, getcoo) {
    try {

      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getpkg, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            pkglistfile_url: true,
            pkglistfile_name: getpkg
          })
        } else {
          this.setState({
            pkglistfile_url: false
          })
        }

        this.getCI(getcl, getrl, getbl, getcoo)

      }).catch((error) => {

        this.getCI(getcl, getrl, getbl, getcoo)
      })

    } catch (error) {
      console.log(error)
    }
  }

  getCI(getcl, getrl, getbl, getcoo) {
    try {
      console.log("DocCI", process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getcl,)
      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getcl, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            cifile_url: true,
            cifile_name: getcl
          })
        } else {
          this.setState({
            cifile_url: true,
          })
        }
        this.getRL(getrl, getbl, getcoo)

      }).catch((error) => {
        console.log("DocCI1", error)
        this.getRL(getrl, getbl, getcoo)
      })

    } catch (error) {
      console.log(error)
    }
  }

  getRL(getrl, getbl, getcoo) {
    try {

      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getrl, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            rlfile_url: true,
            rlfile_name: getrl
          })
        } else {
          this.setState({
            rlfile_url: false
          })
        }
        this.getBL(getbl, getcoo)

      }).catch((error) => {

        this.getBL(getbl, getcoo)
      })

    } catch (error) {
      console.log(error)
    }
  }

  getBL(getbl, getcoo) {
    try {
      console.log("DocBL", "IN")
      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getbl, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            blfile_url: true,
            blfile_name: getbl
          })
        } else {
          this.setState({
            blfile_url: true,
          })
        }
        this.getCOO(getcoo)

      }).catch((error) => {
        console.log("DocBL1", error)
        this.getCOO(getcoo)
      })

    } catch (error) {
      console.log(error)
    }

  }

  getCOO(getcoo) {
    try {
      console.log("DocCOO", "IN")
      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getcoo, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            coofile_url: true,
            coofile_name: getcoo
          })
        } else {
          this.setState({
            coofile_url: false
          })
        }


      }).catch((error) => {
        console.log("DocCOO112", error)

      })

    } catch (error) {

      console.log(error)
    }

  }


  getseCI(getcl, getins) {
    try {
      console.log("DocBL", "IN")
      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getcl, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            cifile_url: true,
            cifile_name: getcl
          })
        } else {
          this.setState({
            cifile_url: true,
          })
        }
        this.getseINS(getins)

      }).catch((error) => {
        console.log("DocCI1", error)
        this.getseINS(getins)
      })

    } catch (error) {
      console.log(error)
    }

  }

  getseINS(getins) {
    try {

      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getins, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            insufile_url: true,
            insufile_name: getins
          })
        } else {
          this.setState({
            insufile_url: true,
          })
        }
      }).catch((error) => {
      })

    } catch (error) {

      console.log(error)
    }

  }


  /// User Type == SCM SE

  getScmPSI(getpsi, getpkg, getrl, getpopin, getpoppkg, getbl, getcoo) {
    try {

      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getpsi, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            psifile_url: true,
            psi2file_name: getpsi
          })
        } else {
          this.setState({
            psifile_url: false
          })
        }
        this.getScmPKG(getpkg, getrl, getpopin, getpoppkg, getbl, getcoo,)

        console.log("ManitestPSI", response.status)
      }).catch((error) => {

        this.getScmPKG(getpkg, getrl, getpopin, getpoppkg, getbl, getcoo)

      })

    } catch (error) {
      console.log(error)
    }
  }


  getScmPKG(getpkg, getrl, getpopin, getpoppkg, getbl, getcoo) {
    try {

      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getpkg, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            pkglistfile_url: true,
            pkglistfile_name: getpkg
          })
        } else {
          this.setState({
            pkglistfile_url: false
          })
        }

        this.getScmRL(getrl, getpopin, getpoppkg, getbl, getcoo)

      }).catch((error) => {

        this.getScmRL(getrl, getpopin, getpoppkg, getbl, getcoo)
      })

    } catch (error) {
      console.log(error)
    }
  }

  getScmRL(getrl, getpopin, getpoppkg, getbl, getcoo) {
    try {

      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getrl, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            rlfile_url: true,
            rlfile_name: getrl
          })
        } else {
          this.setState({
            rlfile_url: false
          })
        }
        this.getScmPOPINV(getpopin, getpoppkg, getbl, getcoo,)

      }).catch((error) => {

        this.getScmPOPINV(getpopin, getpoppkg, getbl, getcoo)
      })

    } catch (error) {
      console.log(error)
    }
  }

  getScmPOPINV(getpopin, getpoppkg, getbl, getcoo) {
    try {
      console.log("DocPOPINV", "in")
      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getpopin, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            popinvfile_url: true,
            popinvfile_name: getpopin
          })
        } else {
          this.setState({
            popinvfile_url: false
          })
        }
        this.getScmPOPPKG(getpoppkg, getbl, getcoo)

      }).catch((error) => {
        console.log("DocPOPINV", error)
        this.getScmPOPPKG(getpoppkg, getbl, getcoo)
      })

    } catch (error) {
      console.log(error)
    }
  }

  getScmPOPPKG(getpoppkg, getbl, getcoo) {
    try {
      console.log("DocPOPPKG", "IN")
      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getpoppkg, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            poppkgfile_url: true,
            poppkgfile_name: getpoppkg
          })
        } else {
          this.setState({
            poppkgfile_url: false
          })
        }
        this.getScmBL(getbl, getcoo)

      }).catch((error) => {
        console.log("DocPOPPKG1", error)
        this.getScmBL(getbl, getcoo)
      })

    } catch (error) {
      console.log(error)
    }
  }

  getScmBL(getbl, getcoo) {
    try {
      console.log("DocBL", "IN")
      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getbl, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            blfile_url: true,
            blfile_name: getbl
          })
        } else {
          this.setState({
            blfile_url: true,
          })
        }
        this.getScmCOO(getcoo)

      }).catch((error) => {
        console.log("DocBL1", error)
        this.getScmCOO(getcoo)
      })

    } catch (error) {
      console.log(error)
    }

  }

  getScmCOO(getcoo,) {
    try {
      console.log("DocCOO", "IN")
      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getcoo, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            coofile_url: true,
            coofile_name: getcoo
          })
        } else {
          this.setState({
            coofile_url: false
          })
        }


      }).catch((error) => {
        console.log("DocCOO1", error)

      })

    } catch (error) {

      console.log(error)
    }

  }
  /// User Type == SCM SE

  /// User Type == FIN

  getFinCI(getcl, getpkg, getpopin, getpoppkg, ins) {
    try {

      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getcl, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            cifile_url: true,
            cifile_name: getcl
          })
        } else {
          this.setState({
            cifile_url: true,
          })
        }
        this.getFinPKG(getpkg, getpopin, getpoppkg, ins)

      }).catch((error) => {
        console.log("DocCI1", error)
        this.getFinPKG(getpkg, getpopin, getpoppkg, ins)
      })

    } catch (error) {
      console.log(error)
    }
  }


  getFinPKG(getpkg, getpopin, getpoppkg, ins) {
    try {

      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getpkg, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            pkglistfile_url: true,
            pkglistfile_name: getpkg
          })
        } else {
          this.setState({
            pkglistfile_url: false
          })
        }

        this.getFinPOPINV(getpopin, getpoppkg, ins)

      }).catch((error) => {

        this.getFinPOPINV(getpopin, getpoppkg, ins)
      })

    } catch (error) {
      console.log(error)
    }
  }

  getFinPOPINV(getpopin, getpoppkg, ins) {
    try {
      console.log("DocPOPINV", "in")
      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getpopin, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            popinvfile_url: true,
            popinvfile_name: getpopin
          })
        } else {
          this.setState({
            popinvfile_url: false
          })
        }
        this.getFinPOPPKG(getpoppkg, ins)

      }).catch((error) => {
        console.log("DocPOPINV", error)
        this.getFinPOPPKG(getpoppkg, ins)
      })

    } catch (error) {
      console.log(error)
    }
  }

  getFinPOPPKG(getpoppkg, ins) {
    try {
      console.log("DocPOPPKG", "IN")
      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + getpoppkg, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({
            poppkgfile_url: true,
            poppkgfile_name: getpoppkg
          })
        } else {
          this.setState({
            poppkgfile_url: false
          })
        }
        this.getFinIns(ins)

      }).catch((error) => {
        console.log("DocPOPPKG1", error)
        this.getFinIns(ins)
      })

    } catch (error) {
      console.log(error)
    }
  }

  getFinIns(ins) {
    try {
      console.log("DocBL", "IN")
      axios.get(process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=" + ins, {
      }).then(response => {

        if (response.status == "200") {
          this.setState({

          })
        } else {
          this.setState({

          })
        }


      }).catch((error) => {
        console.log("DocBL1", error)

      })

    } catch (error) {
      console.log(error)
    }

  }

  /// End User Type == FIN


  togglee1 = () => {
    this.setState(prevState => ({
      modal1: !prevState.modal1
    }));

  }

  togglee2 = () => {
    this.setState(prevState => ({
      modal2: !prevState.modal2
    }));

  }

  handleInputChange1 = event => {

    var test = event.target.files[0].type

    if (event.target.files[0].type == "application/pdf") {
      this.setState({
        featured_image1: event.target.files[0],
        featured_image1_name: event.target.files[0].name
      });
      console.log("Test", event.target.files[0].type)
    } else {
      alert("Please upload PDF Only ")

    }



  };

  handleInputChange2 = event => {
    this.setState({
      featured_image2: event.target.files[0],
      featured_image2_name: event.target.files[0].name
    });
    console.log("Test", event.target.files[0])


  };

  handleInputChange3 = event => {
    this.setState({
      featured_image3: event.target.files[0],
      featured_image3_name: event.target.files[0].name
    });

  };

  handleInputChangerate = event => {
    this.setState({
      featured_image_rate: event.target.files[0],
      featured_image_rate_name: event.target.files[0].name
    });

  };
  handleInputChange4 = event => {
    this.setState({
      featured_image4: event.target.files[0],
      featured_image4_name: event.target.files[0].name
    });

  };
  handleInputChange5 = event => {
    this.setState({
      featured_image5: event.target.files[0],
      featured_image5_name: event.target.files[0].name
    });

  };

  handleInputChange11 = event => {
    this.setState({
      featured_image11: event.target.files[0],
      featured_image11_name: event.target.files[0].name
    });

  };

  handleInputChange12 = event => {
    this.setState({
      featured_image12: event.target.files[0],
      featured_image12_name: event.target.files[0].name
    });

  };

  handleInputChange13 = event => {
    this.setState({
      featured_image13: event.target.files[0],
      featured_image13_name: event.target.files[0].name
    });

  };

  handleInputChange14 = event => {
    this.setState({
      featured_image14: event.target.files[0],
      featured_image14_name: event.target.files[0].name
    });

  };


  handleuploaddoc1 = (event) => {

    // console.log("image", this.state.featured_image1_name ='Test123')
    if (this.state.featured_image1 == null) {
      alert("PLEASE CHOOSE FILE ")
    } else {
      const formData = new FormData();

      const fileSize = this.state.featured_image1.size / 1024; // in MiB


      formData.append('type', 'POPDF');
      formData.append('document', this.state.featured_image1, this.state.order_no + 'PSI.pdf');
      fetch(process.env.REACT_APP_API_URL_FILE + 'aws_upload', {
        method: 'POST',
        body: formData
      })
        .catch(error => console.log(error));


      // axios({
      //   method: "POST",
      //   url: this.state.baseurl + 'uploadcreatepostinvoice',//"http://localhost:3300/custdetails",
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'token': localStorage.getItem('token')
      //   },
      //   data: {
      //     featured_image: this.state.invoice_pdf_name,
      //     doc_type: this.state.invoice_doc_type,
      //     customer_sap_code: this.state.customer_code,
      //     order_no: this.state.order_no,
      //     title: this.state.order_no + 'PSI.pdf',
      //     document_no: "1",
      //     body: "text/plain",
      //     status: 'true'
      //   }
      // }).then((response) => {
      //   console.log("GET", response.data.data)
      //   this.setState({
      //     orderaddmodel: response.data.data, // you have it as this.data not response
      //   });

      //   axios({
      //     method: "POST",
      //     url: this.state.baseurl + 'uploadshow_view',//"http://localhost:3300/custdetails",
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'token': localStorage.getItem('token')
      //     },
      //     data: {
      //       order_no: this.state.order_no,
      //       document_no: "1"
      //     }
      //   }).then((response) => {
      //     console.log("GETPRESHIPMENT", response.data.data)
      //     this.setState({
      //       fileInfos1: response.data.data, // you have it as this.data not response
      //     });
      //   });

      // });




    }
  }

  handleuploaddoc2 = (event) => {

    if (this.state.featured_image2 == null) {
      alert("PLEASE CHOOSE FILE ")
    } else {
      const formData = new FormData();

      // const fileSize = this.state.featured_image1.size / 1024 / 1024; // in MiB


      formData.append('type', 'POPDF');
      formData.append('document', this.state.featured_image2, this.state.order_no + 'PKGLIST.pdf');
      fetch(process.env.REACT_APP_API_URL_FILE + 'aws_upload', {
        method: 'POST',
        body: formData
      })
        .catch(error => console.log(error));


      axios({
        method: "POST",
        url: this.state.baseurl + 'uploadcreatepostinvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          featured_image: this.state.invoice_pdf_name,
          doc_type: this.state.invoice_doc_type,
          customer_sap_code: this.state.customer_code,
          order_no: this.state.order_no,
          title: this.state.order_no + 'PKGLIST.pdf',
          document_no: "2",
          body: "text/plain",
          status: 'true'
        }
      }).then((response) => {
        console.log("GET", response.data.data)
        this.setState({
          orderaddmodel: response.data.data, // you have it as this.data not response
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'uploadshow_view',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            order_no: this.state.order_no,
            document_no: "2"
          }
        }).then((response) => {
          console.log("GET", response.data.data)
          this.setState({
            fileInfos2: response.data.data, // you have it as this.data not response
          });
        });

      });
    }
  }

  handleuploaddoc3 = (event) => {

    if (this.state.featured_image3 == null) {
      alert("PLEASE CHOOSE FILE ")
    } else {
      const formData = new FormData();
      formData.append('type', 'POPDF');
      formData.append('document', this.state.featured_image3, this.state.order_no + 'POPINV.pdf');
      fetch(process.env.REACT_APP_API_URL_FILE + 'aws_upload', {
        method: 'POST',
        body: formData
      })
        .catch(error => console.log(error));


      axios({
        method: "POST",
        url: this.state.baseurl + 'uploadcreatepostinvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          featured_image: this.state.invoice_pdf_name,
          doc_type: this.state.invoice_doc_type,
          customer_sap_code: this.state.customer_code,
          order_no: this.state.order_no,
          title: this.state.order_no + 'POPINV.pdf',
          document_no: "3",
          body: "text/plain",
          status: 'true'
        }
      }).then((response) => {
        console.log("GET", response.data.data)
        this.setState({
          orderaddmodel: response.data.data, // you have it as this.data not response
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'uploadshow_view',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            order_no: this.state.order_no,
            document_no: "3"
          }
        }).then((response) => {
          console.log("GETPI", response.data.data)
          this.setState({
            fileInfos3: response.data.data, // you have it as this.data not response
          });
        });

      });
    }
  }

  handleuploaddoc_rate = (event) => {

    if (this.state.featured_image_rate == null) {
      alert("PLEASE CHOOSE FILE ")
    } else {
      const formData = new FormData();
      formData.append('type', 'POPDF');
      formData.append('document', this.state.featured_image_rate, this.state.order_no + 'RL.pdf');
      fetch(process.env.REACT_APP_API_URL_FILE + 'aws_upload', {
        method: 'POST',
        body: formData
      })
        .catch(error => console.log(error));


      axios({
        method: "POST",
        url: this.state.baseurl + 'uploadcreatepostinvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          featured_image: this.state.invoice_pdf_name,
          doc_type: this.state.invoice_doc_type,
          customer_sap_code: this.state.customer_code,
          order_no: this.state.order_no,
          title: this.state.order_no + 'RL.pdf',
          document_no: "15",
          body: "text/plain",
          status: 'true'
        }
      }).then((response) => {
        console.log("GET", response.data.data)
        this.setState({
          orderaddmodel: response.data.data, // you have it as this.data not response
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'uploadshow_view',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            order_no: this.state.order_no,
            document_no: "15"
          }
        }).then((response) => {
          console.log("GETrate", response.data.data)
          this.setState({
            fileInfosrate: response.data.data, // you have it as this.data not response
          });
        });

      });
    }
  }

  handleuploaddoc4 = (event) => {

    if (this.state.featured_image4 == null) {
      alert("PLEASE CHOOSE FILE ")
    } else {
      const formData = new FormData();
      formData.append('type', 'POPDF');
      formData.append('document', this.state.featured_image4, this.state.order_no + 'POPPKG.pdf');
      fetch(process.env.REACT_APP_API_URL_FILE + 'aws_upload', {
        method: 'POST',
        body: formData
      })
        .catch(error => console.log(error));


      axios({
        method: "POST",
        url: this.state.baseurl + 'uploadcreatepostinvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          featured_image: this.state.invoice_pdf_name,
          doc_type: this.state.invoice_doc_type,
          customer_sap_code: this.state.customer_code,
          order_no: this.state.order_no,
          title: this.state.order_no + 'POPPKG.pdf',
          document_no: "4",
          body: "text/plain",
          status: 'true'
        }
      }).then((response) => {
        console.log("GET", response.data.data)
        this.setState({
          orderaddmodel: response.data.data, // you have it as this.data not response
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'uploadshow_view',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            order_no: this.state.order_no,
            document_no: "4"
          }
        }).then((response) => {
          console.log("GET", response.data.data)
          this.setState({
            fileInfos4: response.data.data, // you have it as this.data not response
          });
        });

      });
    }
  }

  handleuploaddoc5 = (event) => {
    if (this.state.featured_image5 == null) {
      alert("PLEASE CHOOSE FILE ")
    } else {
      const formData = new FormData();
      formData.append('type', 'INVATT');
      formData.append('document', this.state.featured_image5, this.state.order_no + 'COO.pdf');
      fetch(process.env.REACT_APP_API_URL_FILE + 'aws_upload', {
        method: 'POST',
        body: formData
      })
        .catch(error => console.log(error));


      // axios({
      //   method: "POST",
      //   url: this.state.baseurl + 'uploadcreatepostinvoice',//"http://localhost:3300/custdetails",
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'token': localStorage.getItem('token')
      //   },
      //   data: {
      //     featured_image: this.state.invoice_pdf_name,
      //     doc_type: this.state.invoice_doc_type,
      //     customer_sap_code: this.state.customer_code,
      //     order_no: this.state.order_no,
      //     title: this.state.order_no + 'COO.pdf',
      //     document_no: "5",
      //     body: "text/plain",
      //     status: 'true'
      //   }
      // }).then((response) => {
      //   console.log("GET", response.data.data)
      //   this.setState({
      //     orderaddmodel: response.data.data, // you have it as this.data not response
      //   });

      //   axios({
      //     method: "POST",
      //     url: this.state.baseurl + 'uploadshow_view',//"http://localhost:3300/custdetails",
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'token': localStorage.getItem('token')
      //     },
      //     data: {
      //       order_no: this.state.order_no,
      //       document_no: "5"
      //     }
      //   }).then((response) => {
      //     console.log("GET", response.data.data)
      //     this.setState({
      //       fileInfos5: response.data.data, // you have it as this.data not response
      //     });
      //   });

      // });
    }
  }

  handleuploaddoc11 = (event) => {

    if (this.state.featured_image11 == null) {
      alert("PLEASE CHOOSE FILE ")
    } else {
      const formData = new FormData();
      formData.append('type', 'POPDF');
      formData.append('document', this.state.featured_image11, this.state.order_no + 'CI.pdf');
      fetch(process.env.REACT_APP_API_URL_FILE + 'aws_upload', {
        method: 'POST',
        body: formData
      })
        .catch(error => console.log(error));


      axios({
        method: "POST",
        url: this.state.baseurl + 'uploadcreatepostinvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          featured_image: this.state.invoice_pdf_name,
          doc_type: this.state.invoice_doc_type,
          customer_sap_code: this.state.customer_code,
          order_no: this.state.order_no,
          title: this.state.order_no + 'CI.pdf',
          document_no: "11",
          body: "text/plain",
          status: 'true'
        }
      }).then((response) => {
        console.log("GET", response.data.data)
        this.setState({
          orderaddmodel: response.data.data, // you have it as this.data not response
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'uploadshow_view',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            order_no: this.state.order_no,
            document_no: "11"
          }
        }).then((response) => {
          console.log("GET", response.data.data)
          this.setState({
            fileInfos11: response.data.data, // you have it as this.data not response
          });
        });

      });
    }
  }

  handleuploaddoc12 = (event) => {

    if (this.state.featured_image12 == null) {
      alert("PLEASE CHOOSE FILE ")
    } else {
      const formData = new FormData();
      formData.append('type', 'POPDF');
      formData.append('document', this.state.featured_image11, this.state.order_no + 'FPL.pdf');
      fetch(process.env.REACT_APP_API_URL_FILE + 'aws_upload', {
        method: 'POST',
        body: formData
      })
        .catch(error => console.log(error));


      axios({
        method: "POST",
        url: this.state.baseurl + 'uploadcreatepostinvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },

        data: {
          featured_image: this.state.invoice_pdf_name,
          doc_type: this.state.invoice_doc_type,
          customer_sap_code: this.state.customer_code,
          order_no: this.state.order_no,
          title: this.state.order_no + 'FPL.pdf',
          document_no: "12",
          body: "text/plain",
          status: 'true'
        }
      }).then((response) => {
        console.log("GET", response.data.data)
        this.setState({
          orderaddmodel: response.data.data, // you have it as this.data not response
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'uploadshow_view',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            order_no: this.state.order_no,
            document_no: "12"
          }
        }).then((response) => {
          console.log("GET", response.data.data)
          this.setState({
            fileInfos12: response.data.data, // you have it as this.data not response
          });
        });

      });
    }
  }

  handleuploaddoc13 = (event) => {

    if (this.state.featured_image13 == null) {
      alert("PLEASE CHOOSE FILE ")
    } else {
      const formData = new FormData();
      formData.append('type', 'INVATT');
      formData.append('document', this.state.featured_image13, this.state.order_no + 'BL.pdf');
      fetch(process.env.REACT_APP_API_URL_FILE + 'aws_upload', {
        method: 'POST',
        body: formData
      }).then((response) => {

        alert("Upload", response)

      });
      //.catch(error => console.log(error));


      // axios({
      //   method: "POST",
      //   url: this.state.baseurl + 'uploadcreatepostinvoice',//"http://localhost:3300/custdetails",
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'token': localStorage.getItem('token')
      //   },
      //   data: {
      //     featured_image: this.state.invoice_pdf_name,
      //     doc_type: this.state.invoice_doc_type,
      //     customer_sap_code: this.state.customer_code,
      //     order_no: this.state.order_no,
      //     title: this.state.order_no + 'BL.pdf',
      //     document_no: "13",
      //     body: "text/plain",
      //     status: 'true'
      //   }
      // }).then((response) => {
      //   console.log("GET", response.data.data)
      //   this.setState({
      //     orderaddmodel: response.data.data, // you have it as this.data not response
      //   });

      //   axios({
      //     method: "POST",
      //     url: this.state.baseurl + 'uploadshow_view',//"http://localhost:3300/custdetails",
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'token': localStorage.getItem('token')
      //     },
      //     data: {
      //       order_no: this.state.order_no,
      //       document_no: "13"
      //     }
      //   }).then((response) => {
      //     console.log("GET", response.data.data)
      //     this.setState({
      //       fileInfos13: response.data.data, // you have it as this.data not response
      //     });
      //   });

      // });
    }
  }

  handleuploaddoc14 = (event) => {

    if (this.state.featured_image14 == null) {
      alert("PLEASE CHOOSE FILE ")
    } else {
      const formData = new FormData();
      formData.append('type', 'POPDF');
      formData.append('document', this.state.featured_image14, this.state.order_no + 'INSU.pdf');
      fetch(process.env.REACT_APP_API_URL_FILE + 'aws_upload', {
        method: 'POST',
        body: formData
      })
        .catch(error => console.log(error));


      axios({
        method: "POST",
        url: this.state.baseurl + 'uploadcreatepostinvoice',//"http://localhost:3300/custdetails",
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        data: {
          featured_image: this.state.invoice_pdf_name,
          doc_type: this.state.invoice_doc_type,
          customer_sap_code: this.state.customer_code,
          order_no: this.state.order_no,
          title: this.state.order_no + 'INSU.pdf',
          document_no: "14",
          body: "text/plain",
          status: 'true'
        }
      }).then((response) => {
        console.log("GET", response.data.data)
        this.setState({
          orderaddmodel: response.data.data, // you have it as this.data not response
        });

        axios({
          method: "POST",
          url: this.state.baseurl + 'uploadshow_view',//"http://localhost:3300/custdetails",
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          },
          data: {
            order_no: this.state.order_no,
            document_no: "14"
          }
        }).then((response) => {
          console.log("GET", response.data.data)
          this.setState({
            fileInfos14: response.data.data, // you have it as this.data not response
          });
        });

      });
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  nextPage = () => {

    const currentPageNumber = this.state.pageNumber;
    let nextPageNumber;

    if (currentPageNumber + 1 > this.state.numPages) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = currentPageNumber + 1;
    }

    this.setState({
      pageNumber: nextPageNumber
    });
  }

  goToPrevPage = () => this.setState(state => ({ pageNumber: state.pageNumber - 1 }));

  goToNextPage = () => this.setState(state => ({ pageNumber: state.pageNumber + 1 }));


  toggleview = (file) => {
    console.log("Manite", file)
    this.setState(prevState => ({
      view: !prevState.view,
      file_title: file
    }));

    this.setState({
      pageNumber: 1
    });
  }


  toggleeview = () => {
    this.setState(prevState => ({
      view: !prevState.view
    }));
  }




  deletetoggleview1 = (file) => {

    this.setState(prevState => ({

    }));
    axios({
      method: "POST",
      url: this.state.baseurl + 'doc_delete_view',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        id: file.id,
        status: "false",
        order_no: file.order_no,
        document_no: "1"

      }
    }).then((response) => {
      this.setState({
        fileInfos1: response.data.data[0].title, // you have it as this.data not response
      });
    });
  }

  deletetoggleview2 = (file) => {

    this.setState(prevState => ({

    }));
    axios({
      method: "POST",
      url: this.state.baseurl + 'doc_delete_view',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        id: file.id,
        status: "false",
        order_no: file.order_no,
        document_no: "2"
      }
    }).then((response) => {
      this.setState({
        fileInfos2: response.data.data, // you have it as this.data not response
      });
    });
  }

  deletetoggleview3 = (file) => {

    this.setState(prevState => ({

    }));
    axios({
      method: "POST",
      url: this.state.baseurl + 'doc_delete_view',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        id: file.id,
        status: "false",
        order_no: file.order_no,
        document_no: "3"
      }
    }).then((response) => {
      this.setState({
        fileInfos3: response.data.data, // you have it as this.data not response
      });
    });
  }

  deletetoggleviewrate = (file) => {

    this.setState(prevState => ({

    }));
    axios({
      method: "POST",
      url: this.state.baseurl + 'doc_delete_view',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        id: file.id,
        status: "false",
        order_no: file.order_no,
        document_no: "15"
      }
    }).then((response) => {
      this.setState({
        fileInfos3: response.data.data, // you have it as this.data not response
      });
    });
  }


  deletetoggleview4 = (file) => {

    this.setState(prevState => ({

    }));
    axios({
      method: "POST",
      url: this.state.baseurl + 'doc_delete_view',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        id: file.id,
        status: "false",
        order_no: file.order_no,
        document_no: "4"
      }
    }).then((response) => {
      this.setState({
        fileInfos4: response.data.data, // you have it as this.data not response
      });
    });
  }

  deletetoggleview5 = (file) => {

    this.setState(prevState => ({

    }));
    axios({
      method: "POST",
      url: this.state.baseurl + 'doc_delete_view',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        id: file.id,
        status: "false",
        order_no: file.order_no,
        document_no: "5"
      }
    }).then((response) => {
      this.setState({
        fileInfos5: response.data.data, // you have it as this.data not response
      });
    });
  }

  deletetoggleview11 = (file) => {

    this.setState(prevState => ({

    }));
    axios({
      method: "POST",
      url: this.state.baseurl + 'doc_delete_view',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        id: file.id,
        status: "false",
        order_no: file.order_no,
        document_no: "11"

      }
    }).then((response) => {
      this.setState({
        fileInfos11: response.data.data, // you have it as this.data not response
      });
    });
  }

  deletetoggleview12 = (file) => {

    this.setState(prevState => ({

    }));
    axios({
      method: "POST",
      url: this.state.baseurl + 'doc_delete_view',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        id: file.id,
        status: "false",
        order_no: file.order_no,
        document_no: "12"

      }
    }).then((response) => {
      this.setState({
        fileInfos12: response.data.data, // you have it as this.data not response
      });
    });
  }

  deletetoggleview13 = (file) => {

    this.setState(prevState => ({

    }));
    axios({
      method: "POST",
      url: this.state.baseurl + 'doc_delete_view',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        id: file.id,
        status: "false",
        order_no: file.order_no,
        document_no: "13"

      }
    }).then((response) => {
      this.setState({
        fileInfos13: response.data.data, // you have it as this.data not response
      });
    });
  }

  deletetoggleview14 = (file) => {

    this.setState(prevState => ({

    }));
    axios({
      method: "POST",
      url: this.state.baseurl + 'doc_delete_view',//"http://localhost:3300/custdetails",
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
      data: {
        id: file.id,
        status: "false",
        order_no: file.order_no,
        document_no: "14"

      }
    }).then((response) => {
      this.setState({
        fileInfos14: response.data.data[0].title, // you have it as this.data not response
      });
    });
  }


  handleNextPage = (current_page) => {
    var last_page = pageNumbers[pageNumbers.length - 1]
    if (last_page == current_page) {

      this.handle_show_invoice1(1)
      // if(this.state.searching){
      //   this.handle(1);
      // } else {
      //   this.fetchMasterData(1)
      // }

    } else {
      if (this.state.searching) {
        this.fetchSearchResult(current_page + 1);
      } else {
        this.handle_show_invoice1(current_page + 1)
      }

    }
  }





  render() {
    const { get_cu_details = [], get_invoice_dtls = [], get_invoice_dtlss = [] } = this.state
    const { fileInfos1, fileInfos2, fileInfos3, fileInfos4, fileInfos5, file_title, fileInfosrate } = this.state;
    const { fileInfos11, fileInfos12, fileInfos13, fileInfos14, fileInfos15 } = this.state;

    const { pageNumber, numPages } = this.state;
    var pdf_urlpath = process.env.REACT_APP_API_URL_FILE + "download_aws?type=INVATT&key=";
    var urlpath = pdf_urlpath + file_title;
    // console.log("Tet", pdf_urlpath + file_title)

    let vusertype = this.state.user_type

    console.log("truefalse", this.state.psifile_url)

    const options = {
      cMapUrl: 'cmaps/',
      cMapPacked: true,
      standardFontDataUrl: 'standard_fonts/',
    };
    pageNumbers = []
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
        pageNumbers.push(i);
      }
    }

    let example = ""

    const renderPageNumbers = pageNumbers.map(number => {
      let classes = this.state.current_page === number ? 'pagination-active' : '';

      if (number == 1 || number == this.state.total || (number >= this.state.current_page - 2 && number <= this.state.current_page + 5)) {
        if (this.state.searching) {
          return (
            <span key={number} className={classes} onClick={() => this.fetchSearchResult(number)}>{number}</span>
          );
        } else {
          return (
            <span key={number} className={classes} onClick={() => this.handle_show_invoice1(number)}>{number}</span>
          );
        }

      }
    });


    const headers = [
      { label: "Customer Name", key: "customer_name" },
      { label: "Invoice No", key: "invoice_doc_no" },
      { label: "Invoice Date", key: "invoice_date" },
      { label: "Currency", key: "currency" },
      { label: "Container Name", key: "container_name" },
      { label: "BL Number", key: "bl_number" },
      { label: "BL Doc Date", key: "bl_doc_date" },
      { label: "Port of Shipment", key: "port_of_shipment" },
      { label: "Port of Discharge", key: "port_of_discharge" },
      { label: "Shipping Agent", key: "shipping_agent" }
    ];






    return (
      <div>

        <Modal style={{ maxWidth: '1000px', width: '100%' }} isOpen={this.state.view} toggle={this.toggleview}>
          <ModalHeader toggle={this.toggleview}>Document View
            <a style={{ marginLeft: '600px', textAlign: 'right' }} href={pdf_urlpath + file_title} download={this.state.order_no}>
              Click to download PDF</a></ModalHeader>
          <ModalBody>


            <Grid centered columns={2}>
              <Grid.Column textAlign="center" onClick={this.nextPage}>
                <Document
                  file={pdf_urlpath + file_title}
                  onLoadSuccess={this.onDocumentLoadSuccess} noData={<h4></h4>}
                >
                  <Page pageNumber={pageNumber} />
                </Document>

                <p>
                  Page {pageNumber} of {numPages}
                </p>
                <button onClick={this.nextPage}>
                  Next page
                </button>
              </Grid.Column>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleeview} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>

        <Modal style={{ maxWidth: '900px', width: '100%' }} isOpen={this.state.modal1} toggle={this.toggle1}>
          <ModalHeader toggle={this.toggle1}>Document Upload-SCM </ModalHeader>
          <ModalBody>

            {this.state.user_type == 'SCM' || this.state.user_type == 'SE' ? (<>
              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col className="text-left">
                  <Form.Label className="name-label">Pre shipment invoice :</Form.Label>
                </Col>
                <Col></Col>
                <Col></Col>
                {this.state.psifile_url == true ? (<>
                  <Col >
                    <Form.Label className="name-label">{this.state.psi2file_name}</Form.Label>
                  </Col>
                  <Col>
                    <Fab size="small" color="primary" style={{ marginLeft: 20 }}>
                      <VisibilityIcon onClick={() => this.toggleview(this.state.psi2file_name)} size="small" />
                    </Fab>
                  </Col>
                </>) : (<></>)}





              </Row>

              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col>
                  <Form.Label className="name-label">Packing List:</Form.Label>
                </Col>
                <Col >
                </Col>

                <Col >
                </Col>


                {this.state.pkglistfile_url == true ? (
                  <>
                    <Col >
                      <Form.Label className="name-label">{this.state.pkglistfile_name}</Form.Label>
                    </Col>
                    <Col>
                      <Fab size="small" color="primary" style={{ marginLeft: 14 }}>
                        <VisibilityIcon onClick={() => this.toggleview(this.state.pkglistfile_name)} size="small" />
                      </Fab>
                    </Col>
                  </>
                ) : (<></>)}


              </Row>

              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col>
                  <Form.Label className="name-label">Rate List :</Form.Label>

                </Col>


                <Col >

                </Col>
                <Col >

                </Col>



                {this.state.rlfile_url == true ? (
                  <>
                    <Col style={{ width: 300 }}>
                      <Form.Label className="name-label">{this.state.rlfile_name}</Form.Label>
                    </Col>
                    <Col>
                      <Fab size="small" color="primary" style={{ marginLeft: 20 }} >
                        <VisibilityIcon onClick={() => this.toggleview(this.state.rlfile_name)} size="small" />
                      </Fab>
                    </Col>
                  </>
                ) : (<></>)}



              </Row>

              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col>
                  <Form.Label className="name-label">POP Invoice :</Form.Label>

                </Col>


                <Col >

                </Col>
                <Col >

                </Col>



                {this.state.popinvfile_url == true ? (
                  <> <Col style={{ width: 250 }}>
                    <Form.Label className="name-label" >{this.state.popinvfile_name}</Form.Label>
                  </Col>
                    <Col>
                      <Fab size="small" color="primary" style={{ marginLeft: 14 }} >
                        <VisibilityIcon onClick={() => this.toggleview(this.state.popinvfile_name)} size="small" />
                      </Fab>
                    </Col></>
                ) : (<></>)}



              </Row>

              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col>
                  <Form.Label className="name-label">POP Packing List</Form.Label>

                </Col>


                <Col >

                </Col>
                <Col >

                </Col>



                {this.state.poppkgfile_url == true ? (
                  <>
                    <Col style={{ width: 250 }}>
                      <Form.Label className="name-label">{this.state.poppkgfile_name}</Form.Label>

                    </Col>
                    <Col>
                      <Fab size="small" color="primary" style={{ marginLeft: 14 }}>
                        <VisibilityIcon onClick={() => this.toggleview(this.state.poppkgfile_name)} size="small" />
                      </Fab>
                    </Col>
                  </>
                ) : (<></>)}



              </Row>

              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col className="text-left">
                  <Form.Label className="name-label">Bill of Lading</Form.Label>
                  <input type="file" multiple
                    className="form-control" name="upload_file"
                    onChange={this.handleInputChange13}
                  />
                </Col>


                <Col className="text-left" >
                  <Button style={{ marginTop: "30px" }}
                    onClick={this.handleuploaddoc13}
                    color="primary"  >Upload</Button>
                </Col>

                <Col className="text-left" >
                  {this.state.blfile_url == true ? (
                    <Row>
                      <Col>
                        <a href={""}>{this.state.blfile_name}</a>
                      </Col>
                      <Col>
                        <Fab size="small" color="primary">
                          <VisibilityIcon onClick={() => this.toggleview(this.state.blfile_name)} size="small" />
                        </Fab>
                      </Col>

                    </Row>
                  ) : (<></>)}

                </Col>
              </Row>

              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col>
                  <Form.Label className="name-label">Certificate Of Origin</Form.Label>
                  <input type="file" multiple
                    className="form-control" name="upload_file"
                    onChange={this.handleInputChange5}
                  />
                </Col>


                <Col >
                  <Button style={{ marginTop: "30px" }} onClick={this.handleuploaddoc5} color="primary"  >Upload</Button>
                </Col>


                <Col>
                  {this.state.coofile_url == true ? (
                    <Row>
                      <Col>
                        <a href={""}>{this.state.coofile_name}</a>
                      </Col>
                      <Col>
                        <Fab size="small" color="primary">
                          <VisibilityIcon onClick={() => this.toggleview(this.state.coofile_name)} size="small" />
                        </Fab>
                      </Col>

                    </Row>
                  ) : (<></>)}
                  {/* <ul className="list-group list-group-flush">
                    {fileInfos5 &&
                      fileInfos5.map((file, index) => (
                        <li className="list-group-item" key={index}>
                          <Row>
                            <Col>
                              <a href={file.url}>{file.title}</a>
                            </Col>
                            <Col>
                              <Fab size="small" color="primary">
                                <VisibilityIcon onClick={() => this.toggleview(file)} size="small" />
                              </Fab>
                            </Col>
                            <Col>
                              <Fab size="small" color="primary" aria-label="add">
                                <DeleteIcon onClick={() => this.deletetoggleview5(file)} size="small" />
                              </Fab>
                            </Col>
                          </Row>


                        </li>
                      ))}
                  </ul> */}

                </Col>
              </Row>
            </>
            ) : (<></>

            )}

            {this.state.user_type == 'FIN' ? (<>

              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col className="text-left">
                  <Form.Label className="name-label">Commercial Invoice</Form.Label>

                </Col>

                <Col className="text-left" >
                </Col>


                {this.state.cifile_url == true ? (
                  <>
                    <Col>
                      <Form.Label className="name-label" style={{ marginLeft: 10 }}>{this.state.cifile_name}</Form.Label>
                    </Col>
                    <Col>
                      <Fab size="small" color="primary">
                        <VisibilityIcon onClick={() => this.toggleview(this.state.cifile_name)} size="small" />
                      </Fab>
                    </Col>
                  </>
                ) : (<></>)}




              </Row>

              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col>
                  <Form.Label className="name-label">Packing List</Form.Label>
                </Col>


                <Col >

                </Col>

                {this.state.pkglistfile_url == true ? (
                  <>
                    <Col >
                      <Form.Label className="name-label">{this.state.pkglistfile_name}</Form.Label>
                    </Col>
                    <Col>
                      <Fab size="small" color="primary" style={{ marginLeft: 14 }}>
                        <VisibilityIcon onClick={() => this.toggleview(this.state.pkglistfile_name)} size="small" />
                      </Fab>
                    </Col>
                  </>
                ) : (<></>)}

              </Row>

              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col>
                  <Form.Label className="name-label">POP Invoice</Form.Label>

                </Col>


                <Col >

                </Col>


                {this.state.popinvfile_url == true ? (
                  <> <Col style={{ width: 250 }}>
                    <Form.Label className="name-label" >{this.state.popinvfile_name}</Form.Label>
                  </Col>
                    <Col>
                      <Fab size="small" color="primary" style={{ marginLeft: 14 }} >
                        <VisibilityIcon onClick={() => this.toggleview(this.state.popinvfile_name)} size="small" />
                      </Fab>
                    </Col></>
                ) : (<></>)}
              </Row>

              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col>
                  <Form.Label className="name-label">POP Packing List</Form.Label>

                </Col>


                <Col >

                </Col>


                {this.state.poppkgfile_url == true ? (
                  <>
                    <Col style={{ width: 250 }}>
                      <Form.Label className="name-label">{this.state.poppkgfile_name}</Form.Label>

                    </Col>
                    <Col>
                      <Fab size="small" color="primary" style={{ marginLeft: 14 }}>
                        <VisibilityIcon onClick={() => this.toggleview(this.state.poppkgfile_name)} size="small" />
                      </Fab>
                    </Col>
                  </>
                ) : (<></>)}
              </Row>

              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col className="text-left">
                  <Form.Label className="name-label">Insurance</Form.Label>
                  <input type="file" multiple
                    className="form-control" name="upload_file"
                    onChange={this.handleInputChange14}
                  />
                </Col>


                <Col className="text-left" >
                  <Button style={{ marginTop: "30px" }}
                    onClick={this.handleuploaddoc14}
                    color="primary"  >Upload</Button>
                </Col>

                <Col className="text-left" >
                  <ul className="list-group list-group-flush">
                    {fileInfos14 &&
                      fileInfos14.map((file, index) => (
                        <li className="list-group-item" key={index}>
                          <Row>
                            <Col >
                              <a href={file.url}>{file.title}</a>
                            </Col>
                            <Col >
                              <Fab size="small" color="primary">
                                <VisibilityIcon onClick={() => this.toggleview(file)} size="small" />
                              </Fab>
                            </Col>
                            <Col>
                              <Fab size="small" color="primary" aria-label="add">
                                <DeleteIcon onClick={() => this.deletetoggleview14(file)} size="small" />
                              </Fab>
                            </Col>
                          </Row>
                        </li>
                      ))}
                  </ul>
                </Col>
              </Row>

            </>
            ) : (<></>

            )}




          </ModalBody>
          <ModalFooter>
            <Button onClick={this.togglee1} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>


        <Modal style={{ maxWidth: '1000px', width: '100%' }} isOpen={this.state.modal2} toggle={this.toggle2}>
          <ModalHeader toggle={this.toggle2}>Document View  </ModalHeader>
          <ModalBody>

            {this.state.user_type == 'FIN' || this.state.user_type == 'AM' || this.state.user_type == 'CUST' ? (<>
              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col className="text-left" >
                  <Form.Label className="name-label">Pre shipment invoice</Form.Label>
                </Col>
                <Col >
                  {this.state.psifile_url == true ? (<>
                    <Col >
                      <Form.Label className="name-label">{this.state.psi2file_name}</Form.Label>
                      <Fab size="small" color="primary" style={{ marginLeft: 40 }}>
                        <VisibilityIcon onClick={() => this.toggleview(this.state.psi2file_name)} size="small" />
                      </Fab>
                    </Col>

                  </>) : (<></>)}
                </Col>
              </Row>

              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>

                <Col className="text-left" >
                  <Form.Label className="name-label">Packing List</Form.Label>
                </Col>

                <Col  >
                  {this.state.pkglistfile_url == true ? (<>
                    <Col >
                      <Form.Label className="name-label">{this.state.pkglistfile_name}</Form.Label>
                      <Fab size="small" color="primary" style={{ marginLeft: 10 }} >
                        <VisibilityIcon onClick={() => this.toggleview(this.state.pkglistfile_name)} size="small" />
                      </Fab>
                    </Col>

                  </>) : (<></>)}

                </Col>
              </Row>

              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>

                <Col className="text-left" >
                  <Form.Label className="name-label">Commercial Invoice</Form.Label>
                </Col>

                <Col >

                  {this.state.cifile_url == true ? (
                    <Row>
                      <Col>
                        <Form.Label className="name-label" style={{ marginLeft: 10 }}>{this.state.cifile_name}</Form.Label>
                        <Fab size="small" color="primary" style={{ marginLeft: 60 }} >
                          <VisibilityIcon onClick={() => this.toggleview(this.state.cifile_name)} size="small" />
                        </Fab>
                      </Col>

                    </Row>
                  ) : (<></>)}
                </Col>
              </Row>

              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col className="text-left">
                  <Form.Label className="name-label">Rate List</Form.Label>
                </Col>

                <Col >
                  {this.state.rlfile_url == true ? (
                    <>
                      <Col>
                        <Form.Label className="name-label">{this.state.rlfile_name}</Form.Label>
                        <Fab size="small" color="primary" style={{ marginLeft: 50 }} >
                          <VisibilityIcon onClick={() => this.toggleview(this.state.rlfile_name)} size="small" />
                        </Fab>
                      </Col>

                    </>
                  ) : (<></>)}


                </Col>
              </Row>



              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col className="text-left">
                  <Form.Label className="name-label">BL</Form.Label>

                </Col>

                <Col className="text-left" >
                  {this.state.blfile_url == true ? (
                    <>
                      <Col>
                        <Form.Label className="name-label">{this.state.blfile_name}</Form.Label>
                        <Fab size="small" color="primary" style={{ marginLeft: 50 }} >
                          <VisibilityIcon onClick={() => this.toggleview(this.state.blfile_name)} size="small" />
                        </Fab>
                      </Col>

                    </>
                  ) : (<></>)}

                </Col>
              </Row>


              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col>
                  <Form.Label className="name-label">Certificate Of Origin</Form.Label>
                </Col>

                <Col>
                  {this.state.coofile_url == true ? (
                    <>
                      <Col>
                        <Form.Label className="name-label">{this.state.coofile_name}</Form.Label>
                        <Fab size="small" color="primary" style={{ marginLeft: 50 }} >
                          <VisibilityIcon onClick={() => this.toggleview(this.state.coofile_name)} size="small" />
                        </Fab>
                      </Col>

                    </>
                  ) : (<></>)}


                </Col>
              </Row>
            </>) : (<>
            </>)}

            {this.state.user_type == 'SCM' || this.state.user_type == 'SE' ? (<>

              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col className="text-left">
                  <Form.Label className="name-label">Commercial Invoice</Form.Label>
                </Col>


                <Col className="text-left" >

                  {this.state.cifile_url == true ? (
                    <Row>
                      <Col>
                        <Form.Label className="name-label" style={{ marginLeft: 10 }}>{this.state.cifile_name}</Form.Label>
                      </Col>
                      <Col>
                        <Fab size="small" color="primary">
                          <VisibilityIcon onClick={() => this.toggleview(this.state.cifile_name)} size="small" />
                        </Fab>
                      </Col>

                    </Row>
                  ) : (<></>)}
                </Col>


              </Row>



              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col className="text-left">
                  <Form.Label className="name-label">Insurance</Form.Label>

                </Col>


                <Col className="text-left" >
                  <ul className="list-group list-group-flush">
                    {fileInfos14 &&
                      fileInfos14.map((file, index) => (
                        <li className="list-group-item" key={index}>
                          <Row>
                            <Col >
                              <a href={file.url}>{file.title}</a>
                            </Col>
                            <Col >
                              <Fab size="small" color="primary">
                                <VisibilityIcon onClick={() => this.toggleview(file)} size="small" />
                              </Fab>
                            </Col>

                          </Row>
                        </li>
                      ))}
                  </ul>
                </Col>
              </Row>
            </>) : (<>
            </>)}


          </ModalBody>
          <ModalFooter>
            <Button onClick={this.togglee2} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>

        <Modal style={{ maxWidth: '1000px', width: '100%' }} isOpen={this.state.invoice_view} toggle={this.invoice}>
          <ModalHeader toggle={this.invoice}>Invoice  View </ModalHeader>
          <ModalBody>
            <div className="table" style={{ width: "100%", display: "inline-table", margin: "5px", backgroundColor: "#fff" }}>
              <Table striped bordered responsive="sm" >
                <thead style={{ backgroundColor: "#413c69", color: "#ffffff" }}>
                  <tr>
                    <th style={{ width: "20px" }}>#</th>
                    <th style={{ width: "20px" }}>Invoice Item</th>
                    <th style={{ width: "100px" }}>Product Code Name</th>
                    <th style={{ width: "60px" }}>Product Desc</th>
                    <th style={{ width: "120px" }}>Invoice Qty</th>
                    <th style={{ width: "100px" }}>Invoice Value</th>


                  </tr>
                </thead>
                <tbody>
                  {get_invoice_dtls.length ?
                    get_invoice_dtls.map((todo, id) => (
                      <tr key={id} >
                        <th>{id + 1}</th>
                        {todo.invoice_item != null ? (<th style={{ width: "100px" }}>{todo.invoice_item}</th>) : (<th>-</th>)}
                        {todo.product_code != null ? (<th style={{ width: "100px" }}> {todo.product_code}</th>) : (<th>-</th>)}
                        {todo.product_desc != null ? (<th style={{ width: "100px" }}>{todo.product_desc}</th>) : (<th>-</th>)}
                        {todo.invoice_qty != null ? (<th style={{ width: "100px" }}>{todo.invoice_qty}</th>) : (<th>-</th>)}
                        {todo.invoice_value != null ? (<th style={{ width: "100px" }}>{todo.invoice_value}</th>) : (<th>-</th>)}



                      </tr>
                    ))
                    :
                    (<tr>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>


                    </tr>)
                  }

                </tbody>
              </Table>
            </div>



          </ModalBody>
          <ModalFooter>
            <Button onClick={this.invoice_close} color="secondary" >Close</Button>
          </ModalFooter>
        </Modal>

        <Container fluid>
          <Card style={{ margin: "5px" }}>
            <Card.Body>
              <h4><b>Shipment Document</b></h4>
              <Row style={{ marginTop: "2px", marginBottom: "7px" }}>


                <Col className="text-left">
                  <Form.Label className="name-label">From  Date   </Form.Label>
                  <input className="form-control" required="required"
                    type="date" name="setup_name" onChange={this.handle_fromdate}
                    value={this.state.from_date} />

                </Col>

                <Col className="text-left">
                  <Form.Label className="name-label">To Date   </Form.Label>
                  <input className="form-control" required="required"
                    type="date" name="setup_name" onChange={this.handle_todate}
                    value={this.state.to_date} />
                </Col>

                <Col >
                  {localStorage.getItem('usertypecode') != 'CUST' ? (<>
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                    <div>
                      <Typeahead
                        id="exampleSelect"
                        style={{ width: "490px" }}
                        selected={this.selected}
                        onChange={this.handle_cust.bind(this)}
                        value={this.state.customer_name}
                        labelKey={option => `${option.customer_name} `}
                        options={this.state.cust_list}
                        placeholder="Select a customer..."
                      />
                    </div>
                  </>) : (<>
                    <div className="text-left">
                      <Form.Label className="name-label" >Customer Name</Form.Label>
                    </div>
                    <div>

                      <input className="form-control" required="required"
                        placeholder=''
                        style={{ width: "490px" }}
                        type="text" name="setup_name" readOnly={true} value={this.state.get_cust_name} />
                    </div>
                  </>)}



                </Col>

                <Col className="text-left">
                  <Form.Label className="name-label">Invoice No</Form.Label>
                  <input className="form-control" required="required"
                    type="number" name="setup_name" onChange={this.handle_invoice_no}
                    maxlength="7"
                    value={this.state.get_invoice_no} />
                </Col>
                <Col className="text-left">

                  <Button style={{ marginTop: 30 }} onClick={this.handle_show_invoice} color="primary"  >Display</Button>{' '}
                </Col>


              </Row>
            </Card.Body>
          </Card>
        </Container>
        {/* <div className="table" style={{ width: "110%", display: "inline-table", margin: "5px", backgroundColor: "#fff" }}>
        <BootstrapTable keyField='id' data={products} columns={columns} />
        </div> */}

        <div className="table p-2" style={{ width: "99%", display: "inline-table", margin: "5px", backgroundColor: "#fff" }}>
          <Row style={{ marginTop: "2px", marginBottom: "7px" }}>
            <Col className="text-left">

              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn btn-success mb-3"
                table="example"
                filename="Invoice List"
                sheet="tablexls"
                buttonText="Download as XLS"
              />

              {/* <ExportReactCSV   
                    csvHeaders={headers}
                    csvData={get_invoice_dtlss}
                    fileName="InvoiceShipment.csv"
                  /> */}
            </Col>
            <Col className="text-left">


            </Col>
            <Col className="text-left">
            </Col>
            <Col className="text-left">
            </Col>
            <Col className="text-left">
            </Col>
            <Col className="text-left">
              {this.state.total != null ? (<Form.Label className="name-label">Total : {this.state.total}   </Form.Label>)
                : (<Form.Label className="name-label">Total :    </Form.Label>)}
            </Col>
          </Row>



          <Table id={example} class="table table-hover table-bordered" striped bordered responsive="sm">
            <thead style={{ backgroundColor: "#413c69", color: "#ffffff" }}>
              <tr>
                <th style={{ width: "20px" }}>#</th>
                <th style={{ width: "100px" }}>Customer Name</th>
                <th style={{ width: "100px" }}>Invoice No</th>
                <th style={{ width: "100px" }}>Invoice Date</th>
                <th style={{ width: "100px" }}>Invoice Value</th>
                <th style={{ width: "100px" }}>Currency</th>
                <th style={{ width: "130px" }}>Container Name</th>
                <th style={{ width: "100px" }}>BL No</th>
                <th style={{ width: "100px" }}>BL Date</th>
                {/* <th style={{width: "80px"}}>Container Available</th> */}
                <th style={{ width: "80px" }}>Port of Shipment</th>
                <th style={{ width: "80px" }}>Port of Discharge</th>
                <th style={{ width: "80px" }}>Shipment Agent</th>
                <th style={{ width: "180px" }}>Shipment Document</th>
              </tr>
            </thead>
            <tbody>
              {get_cu_details.length ?
                get_cu_details.map((todo, id) => (
                  <tr key={id} >
                    <th>{id + 1}</th>
                    {todo.customer_name != null ? (<th style={{ width: "60px", height: "10px" }}>
                      <Form.Label onClick={() => this.invoice(todo)}>{todo.customer_name.trim()}</Form.Label>
                    </th>) : (<th>-</th>)}
                    {todo.invoice_doc_no != null ? (<th style={{ width: "60px" }}>{todo.invoice_doc_no.trim()}</th>) : (<th>-</th>)}
                    {todo.invoice_date != null ? (<th style={{ width: "150px" }}>{moment(todo.invoice_date).format('DD-MM-YYYY')}</th>) : (<th>-</th>)}
                    {todo.invoice_net_value != null ? (<th style={{ width: "150px" }}>{todo.invoice_net_value}</th>) : (<th>-</th>)}
                    {todo.currency != null ? (<th style={{ width: "150px" }}>{todo.currency}</th>) : (<th>-</th>)}
                    {todo.container_name != null ? (<th style={{ width: "150px" }}>{todo.container_name}</th>) : (<th>-</th>)}
                    {todo.bl_number != null ? (<th style={{ width: "60px" }}>{todo.bl_number.trim()}</th>) : (<th>-</th>)}
                    {todo.bl_doc_date != null ? (<th style={{ width: "150px" }}>{moment(todo.bl_doc_date).format('DD-MM-YYYY')}</th>) : (<th>-</th>)}
                    {/* {todo.container_available_date != null?(<th style={{width: "150px"}}>{moment(todo.container_available_date).format('DD-MM-YYYY')}</th>):(<th>-</th>)} */}
                    {todo.port_of_shipment != null ? (<th style={{ width: "250px" }}>{todo.port_of_shipment}</th>) : (<th>-</th>)}
                    {todo.port_of_discharge != null ? (<th>{todo.port_of_discharge}</th>) : (<th>-</th>)}
                    {todo.shipping_agent != null ? (<th>{todo.shipping_agent}</th>) : (<th>-</th>)}

                    {this.state.user_type == 'SCM' || this.state.user_type == 'FIN' || this.state.user_type == 'SE' ? (
                      <th style={{ width: "180px" }}>
                        <>
                          <Fab size="small" color="primary">
                            <BackupIcon onClick={() => this.toggle1(todo)} size="small" />
                          </Fab>
                          <Fab size="small" color="primary" style={{ marginLeft: 10 }}>
                            <VisibilityIcon onClick={() => this.toggle2(todo)} size="small" />
                          </Fab>
                          {/* <Button style={{marginRight:"2%"}} onClick={() => this.toggle1(todo)}>Upload </Button>
                                  <Button style={{marginRight:"2%"}} onClick={() => this.toggle2(todo)}>View </Button> */}
                        </>
                      </th>
                    ) : (

                      <th style={{ width: "180px" }}>
                        <Fab size="small" color="primary" style={{ marginLeft: 10 }}>
                          <VisibilityIcon onClick={() => this.toggle2(todo)} size="small" />
                        </Fab>
                        {/* <Button style={{marginRight:"2%"}} onClick={() => this.toggle2(todo)}>View </Button> */}
                      </th>

                    )}





                  </tr>
                ))
                :
                (<tr>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                  <th>-</th>
                </tr>)
              }


            </tbody>
          </Table>

          <div className='pagination' style={{ backgroundColor: "white", justifyContent: 'right' }}>
            <span onClick={() => this.handle_show_invoice1(1)}>&laquo;</span>
            {renderPageNumbers}
            <span onClick={() => this.handleNextPage(this.state.current_page)}>&raquo;</span>
          </div>

        </div>


        <div className="text-center" style={{ marginBottom: "50px" }}>
          <Button variant="success" onClick={() => { window.location.href = "/home" }}>Exit</Button>
        </div>


        <div><ToastContainer /></div>

      </div>
    )
  }

}
